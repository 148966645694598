import { AmbientesServiceProxy, AmbienteDto } from '@shared/service-proxies/service-proxies';
import {
    Component,
    Input,
    ViewEncapsulation,
    Injector,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CreateOrEditAmbienteModalComponent } from '../../create-or-edit-ambiente-modal/create-or-edit-ambiente-modal.component';

@Component({
    selector: 'card-item',
    templateUrl: './card-item.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    styleUrls: ['./card-item.component.css']
})
export class CardItemComponent extends AppComponentBase {
    @Input() nomeAmbiente: string;
    @Input() server: number;
    @Input() idAmbiente: string;
    @Input() mandante: string;

    @ViewChild('createOrEditAmbienteModal', { static: true })
    createOrEditAmbienteModal: CreateOrEditAmbienteModalComponent;

    @Output() openModal: EventEmitter<string> = new EventEmitter<string>();
    @Output() openModalHistory: EventEmitter<any> = new EventEmitter<any>();    

    constructor(
        injector: Injector,
        private _ambienteServiceProxy: AmbientesServiceProxy
    ) {
        super(injector);
    }

    emitEventOpenModal(idModal: string) {
        this.openModal.emit(this.idAmbiente);
    }

    emitEventOpenModalHistory(idAmbiente: string) {
        let objetoEmit = {
            idAmbiente: idAmbiente,
            nomeAmbiente: this.nomeAmbiente,
        };
        this.openModalHistory.emit(objetoEmit); 
    }

    removeAmbiente(idAmbiente: string) {
        this.message.confirm('','', isConfirmed => {
            if (isConfirmed) {
                this._ambienteServiceProxy.delete(idAmbiente).subscribe(() => {
                    this.notify.success(this.l('SuccessfullyDeleted'));
                });
            }
        });
    }
}
