<form (ngSubmit)="saveAll()" #changeForm="ngForm">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{"ChangeManager" | localize}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{"ChangeManagerInfo" | localize}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button class="btn btn-primary" type="submit"
                    [disabled]="changeForm.invalid && changeManagerSettings.isEnabled"><i class="la la-floppy-o"></i>
                    {{"SaveAll" | localize}}</button>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet">
            <p-progressSpinner *ngIf="loading; else portletBody"></p-progressSpinner>
            <ng-template #portletBody>
                <div class="kt-portlet__body">
                <label for="change-manager-enabled" class="kt-checkbox">
                    <input id="change-manager-enabled" type="checkbox" name="ChangeManagerIsEnabled"
                        [(ngModel)]="changeManagerSettings.isEnabled"> {{"EnableChangeManager" | localize}}
                    <span></span>
                </label>
                <tabset [hidden]="!changeManagerSettings.isEnabled">
                    <tab heading="{{'ChangeEndpoints' | localize}}">
                        <div class="kt-portlet__body">
                            <div class="form-group" [hidden]="!changeManagerSettings.isEnabled">
                                <label for="integration-user">{{"IntegrationUser" | localize}}</label>
                                <input id="integration-user" type="text" name="integrationUser" class="form-control"
                                    [(ngModel)]="changeManagerSettings.integrationUser" maxlength="50"
                                    #IntegrationUser="ngModel" [ngClass]="{
                                    'is-invalid': IntegrationUser.invalid,
                                    'is-valid': !IntegrationUser.invalid
                                }" required>
                            </div>
                            <div class="form-group" [hidden]="!changeManagerSettings.isEnabled">
                                <label for="password">{{"IntegrationPassword" | localize}}</label>
                                <input id="password" type="password" name="password" class="form-control"
                                    [(ngModel)]="changeManagerSettings.integrationPassword" autocomplete="new-password"
                                    #Password="ngModel" [ngClass]="{
                                    'is-invalid': Password.invalid,
                                    'is-valid': !Password.invalid
                                }" required>
                            </div>
                            <div class="form-group" [hidden]="!changeManagerSettings.isEnabled">
                                <label for="create-change">{{"CreateChange" | localize}}</label>
                                <input id="create-change" type="text" name="createChange" class="form-control"
                                    [(ngModel)]="changeManagerSettings.createChange" #CreateChange="ngModel" [ngClass]="{
                                    'is-invalid': CreateChange.invalid,
                                    'is-valid': !CreateChange.invalid
                                }" required>
                            </div>

                            <div class="form-group" [hidden]="!changeManagerSettings.isEnabled">
                                <label for="get-search-change">{{"GetSearchChange" | localize}}</label>
                                <input id="get-search-change" type="text" name="getSearchChange" class="form-control"
                                    [(ngModel)]="changeManagerSettings.searchForChange" #GetSearchChange="ngModel"
                                    [ngClass]="{
                                    'is-invalid': GetSearchChange.invalid,
                                    'is-valid': !GetSearchChange.invalid
                                }" required>
                            </div>
                            <div class="form-group" [hidden]="!changeManagerSettings.isEnabled">
                                <label for="get-group">{{"AssignmentGroup" | localize}}</label>
                                <input id="get-group" type="text" name="getGroup" class="form-control"
                                    [(ngModel)]="changeManagerSettings.searchAssignmentGroup" #GetGroup="ngModel"
                                    [ngClass]="{
                                    'is-invalid': GetGroup.invalid,
                                    'is-valid': !GetGroup.invalid
                                }" required>
                            </div>
                            <div class="form-group" [hidden]="!changeManagerSettings.isEnabled">
                                <label for="get-ic-sap">{{"ConfigurationItem" | localize}}</label>
                                <input id="get-ic-sap" type="text" name="getIcSap" class="form-control"
                                    [(ngModel)]="changeManagerSettings.searchConfigurationItem" #GetIcSap="ngModel"
                                    [ngClass]="{
                                    'is-invalid': GetIcSap.invalid,
                                    'is-valid': !GetIcSap.invalid
                                }" required>
                            </div>
                        </div>
                    </tab>
                    <tab heading="{{'ChangeJob' | localize}}">
                        <div class="form-group" [hidden]="!changeManagerSettings.isEnabled">
                            <label for="change-frequency">{{"ChangeFrequency" | localize}}</label>
                            <input id="change-frequency" type="number" name="changeFrequency" class="form-control"
                                [(ngModel)]="frequency" required min="0" #ChangeFrequency="ngModel" [ngClass]="{
                                    'is-invalid': ChangeFrequency.invalid,
                                    'is-valid': !ChangeFrequency.invalid
                                }" required>
                        </div>
                    </tab>
                </tabset>
            </div>
            </ng-template>
        </div>
    </div>
</form>