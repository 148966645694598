import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AvaliacoesDeQualidadesServiceProxy, HistoricoDeMensagensDto, EnumStatusDaDoItemDaAvaliacaoDeQualidade } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-historico-avaliacao-codigo',
  templateUrl: './historico-avaliacao-codigo.component.html',
  styleUrls: ['./historico-avaliacao-codigo.component.css']
})
export class HistoricoAvaliacaoCodigoComponent extends AppComponentBase {

  @ViewChild('historyModal', { static: true }) modal: ModalDirective;
  mensagens = [];
  constructor(injector: Injector,
    private _avaliacoesDeQualidadesServiceProxy: AvaliacoesDeQualidadesServiceProxy,

  ) {
    super(injector);
  }

  show(avaliacaoDeQualidadeId: string): void {
    this.mensagens = [];

    this._avaliacoesDeQualidadesServiceProxy.getMensagens(avaliacaoDeQualidadeId).subscribe(x => {
      this.mensagens = x;
    });
    this.modal.show();
  }
 

  close(): void {
    this.modal.hide();
  }

  mudarAcao(mensagem: HistoricoDeMensagensDto) {
    switch (mensagem.acao) {
      case EnumStatusDaDoItemDaAvaliacaoDeQualidade.Aprovado:
        return 'Aprovado';
      case EnumStatusDaDoItemDaAvaliacaoDeQualidade.Reprovado:
        return 'Reprovado';
      case EnumStatusDaDoItemDaAvaliacaoDeQualidade.Justificado:
        return 'Justificado';
      case EnumStatusDaDoItemDaAvaliacaoDeQualidade.FalsoPositivo:
        return 'FalsoPositivo';
      default:
        return '';
    }

  }

  iconeAcao(mensagem: HistoricoDeMensagensDto) {
    switch (mensagem.acao) {
      case EnumStatusDaDoItemDaAvaliacaoDeQualidade.Aprovado:
        return 'far fa-check-circle';
      case EnumStatusDaDoItemDaAvaliacaoDeQualidade.Reprovado:
        return 'far fa-times-circle';
      case EnumStatusDaDoItemDaAvaliacaoDeQualidade.EmAberto:
        return '';
      case EnumStatusDaDoItemDaAvaliacaoDeQualidade.Justificado:
        return 'far fa-edit';
      case EnumStatusDaDoItemDaAvaliacaoDeQualidade.FalsoPositivo:
        return 'far fa-handshake';
      default:
        return '';
    }
  }
}
