import { Component, Injector, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';
import { AvaliacoesDeQualidadesServiceProxy, AvaliacaoDeQualidadeDto, EnumStatusDaAvaliacaoDeQualidade } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';
import { LazyLoadEvent } from 'primeng/api';
import * as _ from 'lodash';
import * as moment from 'moment';
import { HistoricoAvaliacaoCodigoComponent } from '../historico-avaliacao-codigo/historico-avaliacao-codigo.component';

@Component({
    templateUrl: './avaliacoesDeQualidades.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class AvaliacoesDeQualidadesComponent extends AppComponentBase implements OnInit{

    @ViewChild('historicoAvaliacaoCodigo', { static: true }) historicoAvaliacaoCodigo: HistoricoAvaliacaoCodigoComponent;
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    advancedFiltersAreShown = false;
    filterText = '';
    statusFilter = -1;
    maxDataDaAvaliacaoFilter: moment.Moment;
    minDataDaAvaliacaoFilter: moment.Moment;
    demandaTituloFilter = '';
    userNameFilter = '';
    isDecisor = false;
    isDev = false;
    selectedDev = true;
    enumStatusDaAvaliacaoDeQualidade = EnumStatusDaAvaliacaoDeQualidade;



    constructor(
        injector: Injector,
        private _avaliacoesDeQualidadesServiceProxy: AvaliacoesDeQualidadesServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.isDev = this.permission.isGranted('Pages.AvaliacoesDeQualidades');
        this.isDecisor = this.permission.isGranted('Pages.AvaliacoesDecisor') && this.appSession.tenantId != null;
        this.selectedDev = true;
    }

    getAvaliacoesDeQualidades(event?: LazyLoadEvent) {
        if (!this.isDev && this.isDecisor) {
            this.getAvaliacoesDeQualidadesDec(event);
            return;
        }
        
        if (event && this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this._avaliacoesDeQualidadesServiceProxy.getAll(
            this.filterText,
            this.statusFilter,
            this.maxDataDaAvaliacaoFilter,
            this.minDataDaAvaliacaoFilter,
            this.demandaTituloFilter,
            this.userNameFilter,
            this.primengTableHelper.getSorting(this.dataTable),
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event)
        ).subscribe(result => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            this.primengTableHelper.records = result.items;
            this.primengTableHelper.hideLoadingIndicator();
        });
        this.selectedDev = true;


    }

    getAvaliacoesDeQualidadesDec(event?: LazyLoadEvent) {
        if (event && this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this._avaliacoesDeQualidadesServiceProxy.getAll4Decisor(
            this.filterText,
            this.statusFilter,
            this.maxDataDaAvaliacaoFilter,
            this.minDataDaAvaliacaoFilter,
            this.demandaTituloFilter,
            this.userNameFilter,
            this.primengTableHelper.getSorting(this.dataTable),
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event)
        ).subscribe(result => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            this.primengTableHelper.records = result.items;
            this.primengTableHelper.hideLoadingIndicator();
        });
        this.selectedDev = false;


    }

    recarregar(event?: LazyLoadEvent) {
        if (!this.selectedDev)
            this.getAvaliacoesDeQualidadesDec(event);
        else
            this.getAvaliacoesDeQualidades(event);

    }

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }
}
