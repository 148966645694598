<div id="justificationModal" bsModal #justificationModal="bs-modal" class="modal fade" tabindex="-1" 
  role="dialog" aria-labelledby="justificationModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-x">
    <div class="modal-content">
      <form #justitfyform="ngForm" (ngSubmit)="save()" autocomplete="off">
        <div class="modal-header">
          <h4 class="modal-title">
            <span>{{l("Justificativa")}}</span>
          </h4>
          <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <textarea *ngIf="!loading" name="justification" class="form-control" minlength="5" rows="15"
              maxlength="1000" [(ngModel)]="justification" #justify="ngModel" autofocus required>
            </textarea>
          </div>
          <div id="spinner-content" class="spinner-row">
            <div id="spinner-loading" class="spinner-loading">
              <p-progressSpinner *ngIf="loading"></p-progressSpinner>
            </div>
          </div>
        </div>        
        <div class="modal-footer">         
          <div>
            <button type="button" class="btn btn-default" (click)="close()">
              {{l("Cancel")}}
            </button>
            <button type="submit" class="btn btn-primary" [disabled]="justitfyform.invalid">
              <i class="fa fa-save"></i><span>{{l("Save")}}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>