import { Component, EventEmitter, Injector, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ReducaoCustosPerfilUsuarioServiceProxy } from "@shared/service-proxies/service-proxies";
@Component({
    selector: 'reducao-custo',
    templateUrl: './reducaoCusto.component.html',
    styleUrls: ['./reducaoCusto.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})



export class ReducaoCustoComponent extends AppComponentBase implements OnInit {
    reducao: string;


    @Output()  useAd: EventEmitter<void> = new EventEmitter<void>();


    constructor(
        injector: Injector,
        private _route: Router,
        private _ReducaoCustosPerfilUsuarioServiceProxy: ReducaoCustosPerfilUsuarioServiceProxy
    ) {
        super(injector);
    };

    ngOnInit(): void {
        this.getReduction();
    };

    getReduction(): void{
        this._ReducaoCustosPerfilUsuarioServiceProxy.getUserCostReductionByTenant(this.appSession.userId, this.appSession.tenantId).subscribe(result =>{
            this.reducao = result;
        });
    };
}