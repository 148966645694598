// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-contextmenu ul li a {
    text-decoration: none;
    color: #000;
}

.ui-contextmenu .ui-menuitem-text {
    font-family: Poppins !important;
    font-size: 13px !important;
}

.ui-contextmenu .ui-menuitem :hover {
    background-color: #f4f5f8;
}
`, "",{"version":3,"sources":["webpack://./src/assets/primeng/context-menu/css/primeng.context-menu.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,+BAA+B;IAC/B,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".ui-contextmenu ul li a {\n    text-decoration: none;\n    color: #000;\n}\n\n.ui-contextmenu .ui-menuitem-text {\n    font-family: Poppins !important;\n    font-size: 13px !important;\n}\n\n.ui-contextmenu .ui-menuitem :hover {\n    background-color: #f4f5f8;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
