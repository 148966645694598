<div id="schedule-deploy" bsModal #scheduleDeployModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="scheduleDeployModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form #scheduleDeployform="ngForm" (ngSubmit)="save()" autocomplete="off">
        <div class="modal-header">
          <h4 class="modal-title">
            <span>{{l("Cadastro de janela de transporte")}}</span>
          </h4>
          <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group day-week-content">
            <div>
              <label for="day-week">{{l('Dia da semana')}}</label>
              <select class="form-control" name="dayWeek" id="day-week" [(ngModel)]="dayWeek">
                  <option value="0">{{l("Domingo")}}</option>
                  <option value="1">{{l('Segunda-feira')}}</option>
                  <option value="2">{{l('Terça-feira')}}</option>
                  <option value="3">{{l('Quarta-feira')}}</option>
                  <option value="4">{{l('Quinta-feira')}}</option>
                  <option value="5">{{l('Sexta-feira')}}</option>
                  <option value="6">{{l('Sábado')}}</option>
              </select>
            </div>
            <div class="start-hour-content">
              <label for="start-hour">{{l('Horário inicial')}}</label>
              <p-calendar id="start-hour" name="starHour" [(ngModel)]="startHour" [timeOnly]="true"></p-calendar>
            </div>
            <div class="end-hour-content">
              <label for="end-hour">{{l('Horário final')}}</label>
              <p-calendar id="end-hour" name="endHour" [(ngModel)]="endHour" [timeOnly]="true"></p-calendar>
            </div>
          </div>
        </div> 
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
          <button type="submit" class="btn btn-primary" id="submitButton"><i class="fa fa-save"></i><span>{{l("Save")}}</span></button>
        </div>
      </form>
    </div>
  </div>
</div>
