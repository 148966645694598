<div [@routerTransition]>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{ l("Ambientes") }}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{ l("AmbientesHeaderInfo") }}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button (click)="exportToExcel()" class="btn btn-outline-success">
                    <i class="fa fa-file-excel"></i> {{ l("ExportToExcel") }}
                </button>
                <button *ngIf="isGranted('Pages.Administration.Ambientes.Create')" (click)="criarIntegracao()"
                    class="btn btn-outline-primary blue">
                    {{ l("Integrations") }}
                </button>
                <button *ngIf="isGranted('Pages.Administration.Ambientes.Create')" (click)="createAmbiente()"
                    class="btn btn-primary blue">
                    <i class="fa fa-plus"></i> {{ l("CreateNewAmbiente") }}
                </button>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <div class="row">
                    <div class="col-md-5"></div>
                    <div class="col-md-2">
                        <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                    </div>
                    <div class="col-md-5"></div>
                </div>
                <div *ngIf="!loading" class="stepwizard">
                    <div class="stepwizard-row">
                        <div class="row">
                            <div class="col-md-4">
                                <item-ambiente [listaAmbientes]="ambientesDesenvolvimento"
                                    [nomeBranch]="'Desenvolvimento'" [icone]="'fa fa-code'"
                                    (openModal)="openModalEdit($event,enumEstagios.Desenvolvimento)"
                                    (openModalHistory)="showHistory($event)"></item-ambiente>
                            </div>
                            <div class="col-md-4">
                                <item-ambiente [listaAmbientes]="ambientesQualidade" [nomeBranch]="'Qualidade'"
                                    [icone]="'fa fa-flask'" (openModal)="openModalEdit($event, enumEstagios.QA)"
                                    (openModalHistory)="showHistory($event)"></item-ambiente>
                            </div>
                            <div class="col-md-4">
                                <item-ambiente [listaAmbientes]="ambientesProducao" [nomeBranch]="'Producao'"
                                    icone="fa fa-rocket" (openModal)="openModalEdit($event, enumEstagios.Producao)"
                                    (openModalHistory)="showHistory($event)"></item-ambiente>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <createOrEditAmbienteModal #createOrEditAmbienteModal (modalSave)="getAmbientes()"></createOrEditAmbienteModal>
    <app-criar-integracao-modal #criarIntegracaoModal></app-criar-integracao-modal>
    <entityTypeHistoryModal #entityTypeHistoryModal></entityTypeHistoryModal>
</div>