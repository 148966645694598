<div id="object-datails-page" [@routerTransition] class="overflow-auto">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("EnvironmentEvaluation")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{l("ObjectInformation")}}
            </span>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <div id="object-header">
                    <div class="obj-breadcrumbs">
                        <ul class="breadcrumbs">
                            <li *ngFor="let item of items; let i = index" class="bread-item">
                                <span (click)="createBreadcrumbs(item, $event, i)">
                                    <strong>{{item.label}} -</strong> {{item.size}}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="last-update">
                        <span>{{l("LastUpdate")}}</span><span class="update-time">{{creationTime}}</span>
                    </div>
                </div>
                <div id="object-details">
                    <div class="object-info">
                        <h5>{{l("ObjectData")}}</h5>
                        <div class="object-info-data">
                            <form class="object-form">
                                <div class="object-type">
                                    <label>{{l("TypeLabel")}}</label>
                                    <span >{{objectName}}</span>
                                </div>
                                <div class="object-name">
                                    <label>{{l("NameLabel")}}</label>
                                    <span>{{objectSize}}</span>
                                </div>
                                <div class="object-tec-name">
                                    <label>{{l("TecNameLabel")}}</label>
                                    <span>{{objectTecName}}</span>
                                </div>
                                <div class="object-last-version">
                                    <label>{{l("LastUpdate")}}</label>
                                    <span>{{objectLastVer}}</span>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="object-status">
                        <div class="object-debts obj-item">
                            <div class="debts-qtd">
                                <i class="fas fa-sort-up" [ngClass]="debsValue > 0 ? 'negative' : 'positive'"></i>
                                <span>{{objectDebs}}</span>
                            </div>
                            <p class="obj-text">{{l("DebsLabel")}}</p>
                        </div>
                        <div class="object-demands obj-item">
                            <div class="demands-qtd">
                                <i class="fas fa-sort-up" [ngClass]="demandsValue > 0 ? 'negative' : 'positive'"></i>
                                <span>{{objectDemands}}</span>
                            </div>
                            <p class="obj-text">{{l("DemandsLabel")}}</p>
                        </div>
                        <div class="object-tec-debts obj-item" (click)="getTecDebs()" [ngClass]="objectFather ? 'isFather' : 'noFather'">
                            <div class="tec-debts-qtd">
                                <span>{{objectTecDebs}}</span>
                            </div>
                            <p class="obj-text">{{l("TecDebsLabel")}}</p>
                        </div>
                        <div class="object-solved-time obj-item" *ngIf="!objectFather">
                            <div class="obj-time">
                                <span>{{timeConvert(objectTime)}}</span>
                            </div>
                            <p class="obj-text">{{l("CorrectionTimeLabel")}}</p>
                        </div>
                    </div>
                </div>
                <div id="object-child-table">
                    <h5>{{l("EmbeddedObjects")}}</h5>

                    <p-treeTable #tt [value]="files" [columns]="cols" [paginator]="true" [rows]="10" [scrollable]="true" [lazy]="true" 
                        (onLazyLoad)="loadNodes($event)" [totalRecords]="totalRecords" [loading]="loading" (onNodeExpand)="onNodeExpand($event)"  
                        selectionMode="single" (onNodeSelect)="goToObjectPage($event)" dataKey="name">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                            <tr [ttSelectableRow]="rowNode">
                                <td *ngFor="let col of columns; let i = index">
                                    <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                                        {{rowData[col.field]}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <span>Total: {{totalRecords}}</span>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>        
                                <td [attr.colspan]="cols.length">{{l("NoDataFound")}}</td>
                            </tr>
                        </ng-template>
                    </p-treeTable>
                </div>
            </div>
        </div>
    </div>
</div>