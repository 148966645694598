import { IAjaxResponse } from 'abp-ng2-module/index';
import { TokenService } from 'abp-ng2-module';
import { Component, Injector, OnDestroy, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileServiceProxy, UpdateProfilePictureInput } from '@shared/service-proxies/service-proxies';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'changeProfilePictureModal',
    templateUrl: './change-profile-picture-modal.component.html'
})
export class ChangeProfilePictureModalComponent extends AppComponentBase {

    @ViewChild('changeProfilePictureModal', {static: true}) modal: ModalDirective;

    active = false;
    uploader: FileUploader;
    saving = false;
    imageChangedEvent: any = '';
    files: File[] = [];
    private maxProfilPictureBytesUserFriendlyValue = 5;
    private _uploaderOptions: FileUploaderOptions = {
        url: ''
    };

    constructor(
        injector: Injector,
        private messageService: MessageService,
        private _profileService: ProfileServiceProxy,
        private _tokenService: TokenService
    ) {
        super(injector);
    }

    initializeModal(): void {
        this.active = true;
        this.files = [];
        this.imageChangedEvent = '';
    } 

    show(): void {
        this.initializeModal();
        this.modal.show();
    }

    close(): void {
        this.active = false;
        this.imageChangedEvent = '';
        
        if (this.uploader != undefined)
            this.uploader.clearQueue();

        this.modal.hide();
    }

    fileChangeEvent(event: any): void {
        if (event.target.files[0].size > 5242880) { //5MB
            this.message.warn(this.l('ProfilePicture_Warn_SizeLimit', this.maxProfilPictureBytesUserFriendlyValue));
            return;
        }
        this.imageChangedEvent = event;
        this.onFileAdd(event);
    }

    imageCroppedFile(file: File) {
        let files: File[] = [file];
        this.uploader.clearQueue();
        this.uploader.addToQueue(files);
    }

    onFileAdd(event: any) {
        if(event != null){
            const input = event.target as HTMLInputElement;
            if (input.files) {
                for (let i = 0; i < input.files.length; i++) {
                    this.files.push(input.files[i]);
                }
            }
        }

        this.uploader = new FileUploader({
            url: AppConsts.remoteServiceBaseUrl + '/File/UploadProfilePicture'
        });

        this.uploader.clearQueue();

        if (this.files.length > 0) {
            this.uploader.addToQueue(this.files);
        }

        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
        this._uploaderOptions.url = AppConsts.remoteServiceBaseUrl + '/Profile/UploadProfilePicture';
    }

    guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    async save() {

        if (this.files.length > 0) {
            this.uploader.onAfterAddingFile = file => {
                file.withCredentials = false;
            };
    
            this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
                form.append('FileType', fileItem.file.type);
                form.append('FileName', 'ProfilePicture');
                form.append('FileToken', this.guid());
            };
            this.uploader.setOptions(this._uploaderOptions);
    
            await this.setMessages(1);            
            this.uploader.onSuccessItem = async (item, response, status) => {
                const resp = <IAjaxResponse>JSON.parse(response);
                if (resp.success) {
                    const input = new UpdateProfilePictureInput();
                    input.fileToken = resp.result.fileToken;
                    input.x = 0;
                    input.y = 0;
                    input.width = 0;
                    input.height = 0;
    
                    this.saving = true;
                    
                    this._profileService.updateProfilePicture(input)
                        .pipe(finalize(async () => {                     
                            this.saving = false; 
                        })).subscribe(() => {
                            abp.event.trigger('profilePictureChanged');
                            this.close();                           
                        });
                } else {
                    await this.setMessages(2, resp);                  
                }
            };                
            this.uploader.uploadAll();
    
            if (!this.saving) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Sucesso!',
                    detail: 'Foto do perfil atualizada com sucesso.',
                    life: 2000
                });
                await this.delay(2000);
                window.location.reload();
            }
        } else {
            await this.setMessages(3);
        }
    }

    async setMessages(index: number, resp?: any) {
        if (index == 1)
            this.messageService.add({
                severity: 'info',
                summary: 'Aguarde!',
                detail: 'Atualizando foto do perfil.',
                life: 2000
            });

        if (index == 2)
            this.messageService.add({
                severity: 'error',
                summary: 'Erro!',
                detail: resp.error.message,
                life: 2000
            });        

        if (index == 3)
            this.messageService.add({
                severity: 'warn',
                summary: 'Atenção!',
                detail: 'Adicione a foto do perfil.',
                life: 2000
            });

        await this.delay(2000);
    }

    async delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
}
