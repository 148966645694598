<iframe class="col-12" frameborder="0" height="500" allowtransparency [src]="urlQuestion1">
</iframe>

<iframe class="col-12 col-md-6 mt-4" frameborder="0" height="500" allowtransparency [src]="urlQuestion2">
</iframe>

<iframe class="col-12 col-md-6 mt-4" frameborder="0" height="500" allowtransparency [src]="urlQuestion3">
</iframe>

<iframe class="col-12 mt-4" frameborder="0" height="600" allowtransparency [src]="urlQuestion4">
</iframe>