import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { UrlHelper } from "@shared/helpers/UrlHelper";
import {
    AvaliacaoPortalServiceProxy,
    NotificacaoPortalServiceProxy,
    SubscriptionStartType,
    UserServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { ChatSignalrService } from "app/shared/layout/chat/chat-signalr.service";
import * as moment from "moment";
import { AppComponentBase } from "shared/common/app-component-base";
import { SignalRHelper } from "shared/helpers/SignalRHelper";
import { LoginAttemptsModalComponent } from "@app/shared/layout/login-attempts-modal.component";
import { ChangePasswordModalComponent } from "@app/shared/layout/profile/change-password-modal.component";
import { ChangeProfilePictureModalComponent } from "@app/shared/layout/profile/change-profile-picture-modal.component";
import { MySettingsModalComponent } from "@app/shared/layout/profile/my-settings-modal.component";
import { NotificationSettingsModalComponent } from "@app/shared/layout/notifications/notification-settings-modal.component";
import { UserNotificationHelper } from "@app/shared/layout/notifications/UserNotificationHelper";
import { NgSelectConfig } from "@ng-select/ng-select";
import { PortalEvaluationModal } from "./shared/common/portal-evaluation/portal-evaluation-modal.component";
import { PortalNotificationsModal } from "./shared/common/portal-notifications/portal-notifications-modal.component";
import { AppSessionService } from "@shared/common/session/app-session.service";
import { Location } from "@angular/common";
import { PrimeNGConfig } from "primeng/api";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.less"],
})
export class AppComponent extends AppComponentBase implements OnInit {
    subscriptionStartType = SubscriptionStartType;
    theme: string;
    installationMode = true;

    @ViewChild("loginAttemptsModal", { static: true })
    loginAttemptsModal: LoginAttemptsModalComponent;
    @ViewChild("changePasswordModal", { static: true })
    changePasswordModal: ChangePasswordModalComponent;
    @ViewChild("changeProfilePictureModal", { static: true })
    changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild("mySettingsModal", { static: true })
    mySettingsModal: MySettingsModalComponent;
    @ViewChild("notificationSettingsModal", { static: true })
    notificationSettingsModal: NotificationSettingsModalComponent;
    @ViewChild("chatBarComponent", { static: false }) chatBarComponent;
    @ViewChild("evaluationModal", { static: false })
    evaluationModal: PortalEvaluationModal;
    isQuickThemeSelectEnabled: boolean = this.setting.getBoolean(
        "App.UserManagement.IsQuickThemeSelectEnabled"
    );
    @ViewChild("portalNotificationsModal", { static: false })
    portalNotificationsModal: PortalNotificationsModal;

    public constructor(
        injector: Injector,
        private _chatSignalrService: ChatSignalrService,
        private _userNotificationHelper: UserNotificationHelper,
        private config: NgSelectConfig,
        private dateConfig: PrimeNGConfig,
        private avaliacaoPortalService: AvaliacaoPortalServiceProxy,
        private _userService: UserServiceProxy,
        private _notificacaoPortalServiceProxy: NotificacaoPortalServiceProxy,
        private _sessionService: AppSessionService,
        private _location: Location
    ) {
        super(injector);
        this.config.notFoundText = this.l("Select2NotFound");
        this.config.addTagText = this.l("Select2Adicionando");
    }

    ngOnInit(): void {
        this._userNotificationHelper.settingsModal =
            this.notificationSettingsModal;
        this.theme = abp.setting
            .get("App.UiManagement.Theme")
            .toLocaleLowerCase();
        this.installationMode = UrlHelper.isInstallUrl(location.href);

        this.registerModalOpenEvents();

        if (this.appSession.application) {
            SignalRHelper.initSignalR(() => {
                this._chatSignalrService.init();
            });
        }

        this.dateConfig.setTranslation({
            apply: "Aplicar",
            clear: "Limpar",
            accept: "Sim",
            reject: "Não",
            firstDayOfWeek: 0,
            dayNames: [
                "Domingo",
                "Segunda",
                "Terça",
                "Quarta",
                "Quinta",
                "Sexta",
                "Sábado",
            ],
            dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
            monthNames: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
            ],
            monthNamesShort: [
                "Jan",
                "Fev",
                "Mar",
                "Abr",
                "Mai",
                "Jun",
                "Jul",
                "Ago",
                "Set",
                "Out",
                "Nov",
                "Dez",
            ],
            today: "Hoje",
        });
    }

    ngAfterViewInit(): void {
        if (!this.appSession.tenantId) return;
        let url = this._location.path();
        if (url == "/app/main/myProfile/avaliacao") {
            this.evaluationModal.show();
        } else {
            this.avaliacaoPortalService
                .verificaAvaliacaoPortal(this.appSession.userId)
                .subscribe((res) => {
                    if (res) this.evaluationModal.show();
                });
        }
        this.getNotification();
    }

    subscriptionStatusBarVisible(): boolean {
        return (
            this.appSession.tenantId > 0 &&
            (this.appSession.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon())
        );
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            return (
                moment()
                    .utc()
                    .add(AppConsts.subscriptionExpireNootifyDayCount, "days") >=
                moment(this.appSession.tenant.subscriptionEndDateUtc)
            );
        }

        return false;
    }

    registerModalOpenEvents(): void {
        abp.event.on("app.show.loginAttemptsModal", () => {
            this.loginAttemptsModal.show();
        });

        abp.event.on("app.show.changePasswordModal", (arg: boolean) => {
            this.changePasswordModal.show(arg);
        });

        abp.event.on("app.show.changeProfilePictureModal", () => {
            this.changeProfilePictureModal.show();
        });

        abp.event.on("app.show.mySettingsModal", () => {
            this.mySettingsModal.show();
        });
    }

    getRecentlyLinkedUsers(): void {
        abp.event.trigger("app.getRecentlyLinkedUsers");
    }

    onMySettingsModalSaved(): void {
        abp.event.trigger("app.onMySettingsModalSaved");
    }

    getNotification(): void {
        this._notificacaoPortalServiceProxy
            .getComunicadosPortal(this._sessionService.userId)
            .subscribe((res) => {
                if (res.length == 0) return;

                this.portalNotificationsModal.listaMensagens = res;
                this.portalNotificationsModal.show();
            });
    }
}
