<div
    bsModal
    #createOrEditModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createOrEditModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{ l("CreateNewResponsavel") }}</span>
                </h4>
                <button
                    type="button"
                    class="close"
                    (click)="close()"
                    aria-label="Close"
                    [disabled]="saving"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group m-form__group align-items-center">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12">
                            <ng-select
                                [items]="responsibles$"
                                [multiple]="true"
                                [(ngModel)]="responsibleSelected"
                                name="responsibleSelected"
                            >
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    [disabled]="saving"
                    type="button"
                    class="btn btn-default"
                    (click)="close()"
                >
                    {{ l("Close") }}
                </button>
                <button type="submit" class="btn btn-primary" (click)="save()" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i> <span>{{"Save" | localize}}</span></button>
            </div>
        </div>
    </div>
</div>
