import { Component, ElementRef, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { GestaoRiscoDemandaServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-risk-status-modal',
  templateUrl: './risk-status-modal.component.html',
  styleUrls: ['./risk-status-modal.component.css']
})
export class RiskStatusModalComponent extends AppComponentBase implements OnInit {
  @ViewChild('riskStatusModal', { static: true }) modal: ModalDirective;
  @ViewChild('riskEvidence', { static: true }) riskEvidence: ElementRef;
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  loading: boolean = false;
  requestId: string;
  
  constructor(private _service: GestaoRiscoDemandaServiceProxy, injector: Injector) {
    super(injector);
  }
  
  ngOnInit() {
  }

  close(): void {
    this.modal.hide();
    this.closed.emit();
  }

  async show(requestId: string, isManualJustified: boolean, edit: boolean = false) {
    this.requestId = requestId;
    if (edit) {
      this.loading = true;
    }
    this.modal.show();
  }

  save() {
  }

  onPasteImage(event) {
    event.preventDefault();
    if (event.clipboardData.files.length > 0) {
      this.onFilesAdded(event.clipboardData.files);
    }
  }

  onFilesAdded(files: File[]) {
    if (files[0].type.split("/")[0] !== 'image') {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = () => {
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  onFileDrop(event) {
    event.preventDefault();
    if (!event.dataTransfer.items && event.dataTransfer.items[0].kind !== 'file' && event.dataTransfer.items[0].type.split("/")[0] !== 'image') {
      return;
    }
    const file = event.dataTransfer.items[0].getAsFile();
    this.onFilesAdded([file]);
  }

  clean(event) {
    if (event.keyCode === 8) {
      return;
    }
  }
}
