<div>
    <label for="Setting_MicrosoftIsEnabled" class="kt-checkbox">
        <input id="Setting_MicrosoftIsEnabled" type="checkbox" name="MicrosoftIsEnabled"
            [(ngModel)]="_microsoft.isEnabled"> {{"EnableMicrosoftAuthentication" | localize}}
        <span></span>
    </label>
    <div class="form-group" [hidden]="!_microsoft.isEnabled">
        <label for="MicrosoftClientId">{{"ConsumerKey" | localize}}</label>
        <input id="MicrosoftClientId" type="text" name="MicrosoftClientId" class="form-control"
            [(ngModel)]="_microsoft.consumerKey" maxlength="128">
    </div>
    <div class="form-group" [hidden]="!_microsoft.isEnabled">
        <label for="MicrosoftClientSecret">{{"ConsumerSecret" | localize}}</label>
        <input id="MicrosoftClientSecret" type="text" name="MicrosoftClientSecret" class="form-control"
            [(ngModel)]="_microsoft.consumerSecret" maxlength="128">
    </div>
    <div class="form-group" [hidden]="!_microsoft.isEnabled">
        <label for="MicrosoftUrl">{{"MicrosoftUrl" | localize}}</label>
        <input id="MicrosoftUrl" type="text" name="MicrosoftUrl" class="form-control"
            [(ngModel)]="_microsoft.url" maxlength="128">
    </div>
</div>
