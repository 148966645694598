<div class="form-group">
    <label [for]="fieldId"> {{label}}{{required? " *": ""}}</label>
    <select [id]="fieldId" #select="ngModel" type="text"
        [name]="fieldName" class="form-control"
        [(ngModel)]="selected" (change)="onChange($event.target.value)"
        [ngClass]="{'is-invalid': select.invalid, 'is-valid': !select.invalid}" [required]="required">
        <option [value]="null"> {{ "Selecione uma opção" | localize}} </option>
        <option *ngFor="let option of options" [value]="option[valueKey] || option"> {{ option[labelKey] || option}} </option>
    </select>
    <validation-messages [formCtrl]="select"></validation-messages>
</div>

