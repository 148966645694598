import { SapUsersServiceProxy, TaskSapDto, EnumStatusDemanda, AmbienteRequestDto, ConfigAvaliadorCodeReviewSelect2, ConfigAvaliadorCodeReviewDto, CodeReviewServiceProxy, ConfiguracaoCodeReviewServiceProxy, CodeReviewDemandaDto, CodeReviewAvaliadorDemandaDto, UsuarioNotificacaoHomologacaoSelect2, AvaliacoesDeQualidadesServiceProxy } from '../../../../../shared/service-proxies/service-proxies';
import { Component, ViewChild, Injector, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AmbientesServiceProxy, Select2ItemDto, DemandasServiceProxy, RequestDemandaViewDto } from '@shared/service-proxies/service-proxies';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TreeNode } from 'primeng/api';
@Component({
    selector: 'kanBanAmbienteModal',
    styleUrls: ['./kanban-ambiente-modal.component.less'],
    encapsulation: ViewEncapsulation.None,
    templateUrl: './kanban-ambiente-modal.component.html'
})
export class KanBanAmbienteModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    id: string;
    displayName: string;
    displayTabTasksSaps: boolean;
    ambientes: Select2ItemDto[];
    ambienteSelected: Select2ItemDto[];
    eventDrop: any;
    existeUsuarioSapCadastradoAoResponsavel: boolean;
    @Output() detalhesAdicionado: EventEmitter<any> = new EventEmitter<any>();
    usuariosSapsDisponiveis = new Array<Select2ItemDto>();
    usuariosSapBuffer = new Array<Select2ItemDto>();
    usuarioSapSelecionado: Select2ItemDto;
    bufferSize = 20;
    numberOfItemsFromEndBeforeFetchingMore = 20;
    treeData: TreeNode[];
    selectedFiles: TreeNode[] = [];
    tasksSaps = new Array<TaskSapDto>();
    tasksSapsParticipantesDaRequest = new Array<string>();
    environmentsEqualization: boolean = false;

    users: ConfigAvaliadorCodeReviewSelect2[] = [];
    usersSelected: ConfigAvaliadorCodeReviewSelect2[] = [];
    usuariosAprovadoresCodeReviewDto: ConfigAvaliadorCodeReviewDto[] = [];

    selectCodeReviewers: boolean = false;
    codeReview: boolean = false;
    codeReviewDemandaId: string;
    codeReviewSave: boolean = false;

    usersHml: UsuarioNotificacaoHomologacaoSelect2[] = [];
    usersSelectedHml: UsuarioNotificacaoHomologacaoSelect2[] = [];

    notifyAllUsersHml: boolean = false;
    isPermissionNotifyHml: boolean = false;
    isNotificationHml: boolean = false;

    demandIdNotification: string;

    saving: boolean = false;

    environmentsSkipQuality: boolean = false;
    configSkipQuality: boolean = false;

    get temAmbientes() {
        return this.ambienteSelected && this.ambienteSelected.length > 0;
    }

    constructor(
        injector: Injector,
        private _ambientesServiceProxy: AmbientesServiceProxy,
        private _demandaService: DemandasServiceProxy,
        private _usuariosSapService: SapUsersServiceProxy,
        private _codeReviewServiceProxy: CodeReviewServiceProxy,
        private _configCodeReviewServiceProxy: ConfiguracaoCodeReviewServiceProxy,
        private _avaliacaoQualidadeServiceProxy: AvaliacoesDeQualidadesServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.selectApprovalUser();
        this.validateConfigCodeReview();
    }

    selectApprovalUser(): void {
        this.usersSelected = [];
        this._codeReviewServiceProxy
        .getUsuariosAvaliadores().subscribe(x => {
            this.users = x;
        });
    }

    selectNotifyUserHml(demandaId: string): void {
        this.usersSelectedHml = [];
        this._demandaService
        .getUsuariosEnvolvidos(demandaId, this.appSession.userId).subscribe(y => {
            this.usersHml = y;
        });
    }

    selectAllUsersHml() {
        if(!this.notifyAllUsersHml){
            this.usersSelectedHml = this.usersHml;
        }else{
            this.usersSelectedHml = [];
        }
    }
    
    clear(item: ConfigAvaliadorCodeReviewSelect2) {
        this.usersSelected = [].concat(this.usersSelected.filter(x => x.value !== item.value));
    }

    clearUsersNotifyHml(item: UsuarioNotificacaoHomologacaoSelect2) {
        this.usersSelectedHml = [].concat(this.usersSelectedHml.filter(x => x.value !== item.value));
    }

    validateConfigCodeReview(){
        this._configCodeReviewServiceProxy.getConfiguracaoCodeReview().subscribe(res => {
            this.codeReview = res.ativo;
        });
    }

    show(eventDrop: any, statusDemanda: EnumStatusDemanda, usuarioId: number, demandaId: string, displayTabTasksSaps: boolean): void {
        this.treeData = [];
        this.displayTabTasksSaps = displayTabTasksSaps;
        this.eventDrop = eventDrop;
        this.ambientes = [];
        this.ambienteSelected = [];
        this.tasksSaps = [];
        this.tasksSapsParticipantesDaRequest = [];
        this.environmentsEqualization = false;
        this.codeReviewDemandaId = demandaId;
        this.notifyAllUsersHml = false;
        this.isPermissionNotifyHml = false;
        this.isNotificationHml = false;
        this.selectedFiles = [];
        this.environmentsSkipQuality = false;

        if(statusDemanda === EnumStatusDemanda.Homologacao && this.codeReview && !this.displayTabTasksSaps) {
            this.codeReviewSave = true;
            this.selectCodeReviewers = true;
        }

        if(statusDemanda === EnumStatusDemanda.Desenvolvimento && this.permission.isGranted('Pages.Demandas.NotificationHomol') && !this.displayTabTasksSaps) {
            this.isPermissionNotifyHml = true;
            this.isNotificationHml = true;
            this.demandIdNotification = demandaId;
            this.selectNotifyUserHml(demandaId);
        }

        if (statusDemanda === EnumStatusDemanda.Desenvolvimento){
            this._avaliacaoQualidadeServiceProxy.getParameters()
                .subscribe(res => {
                    if (res !== null && res !== undefined)
                        this.configSkipQuality = res.skipReview;
                });
        }

        this.usuarioSapSelecionado = null;
        if (usuarioId) {
            this._demandaService.existeUsuarioSapVinculado(usuarioId).subscribe(result => {
                this.existeUsuarioSapCadastradoAoResponsavel = result;
                if (!this.existeUsuarioSapCadastradoAoResponsavel) {
                    this._usuariosSapService.getAllSapUsers().subscribe(result => {
                        this.usuariosSapsDisponiveis = result;
                    });
                }
            });
        }

        ///regra temporaria, como não vai ter deploy em produção o sistema deve enviar
        ///o id de homolog quando for produção... remover quando for implementado prod
        let idEstagio = eventDrop.container.id >= 2 ? 2 : eventDrop.container.id;
        ///////

        this._ambientesServiceProxy
            .getByEstagioId(demandaId, undefined).subscribe(x => {
                this.ambientes = x;
                if (this.ambientes.length == 1) {
                    this.ambienteSelected = [this.ambientes[0]];
                }
                this.setTreeData(demandaId)
            });
        this.modal.show();
    }

    select(): void {
        if (this.ambienteSelected == null) {
            return null;
        }

        let listaDeAmbientes = [];
        let requestsAndTasks = this.createObjectRequestAndTask();
        this.ambienteSelected.forEach(element => {
            listaDeAmbientes.push(element.value);
        });
        let ret = {
            ambientes: listaDeAmbientes,
            eventDrop: this.eventDrop,
            usuarioSap: this.usuarioSapSelecionado,
            tasksSapsSelecionadas: requestsAndTasks.tasks,
            requestsSapsSelecionadas: requestsAndTasks.requests,
            equalizarAmbiente: this.environmentsEqualization,
            skipQuality: this.environmentsSkipQuality
        };
        
        if(this.codeReview && this.codeReviewSave){
            this.saving = true;

            let listaUsuariosAvaliadores: ConfigAvaliadorCodeReviewDto[] = [];
            let listaCodeReviewAvaliadorDemandaDto: CodeReviewAvaliadorDemandaDto[] = [];
            this.usersSelected.forEach(users => {
                let user = new ConfigAvaliadorCodeReviewDto();
                user.configAvaliadorCodeReviewId = users.value;
                listaUsuariosAvaliadores.push(user);
            });

            listaUsuariosAvaliadores.forEach(el => {
                let codeReviewAvaliadorDemandaDto = new CodeReviewAvaliadorDemandaDto();
                codeReviewAvaliadorDemandaDto.configAvaliadorCodeReviewId = el.configAvaliadorCodeReviewId;
                listaCodeReviewAvaliadorDemandaDto.push(codeReviewAvaliadorDemandaDto);
            });

            let codeReviewDto = new CodeReviewDemandaDto();
            codeReviewDto.demandaId = this.codeReviewDemandaId;
            codeReviewDto.listaCodeReviewAvaliadorDemandaDto = listaCodeReviewAvaliadorDemandaDto;
            this._codeReviewServiceProxy.inserirCodeReview(codeReviewDto).subscribe(() => {
                this.saving = false;
                this.usersSelected = [];
                this.selectCodeReviewers = false;
                this.codeReviewSave = false;
                this.detalhesAdicionado.emit(ret);
                this.selectedFiles = [];
                this.modal.hide();
            });
        }else
        {
            if(this.isNotificationHml){
                let usersIds = [];

                this.usersSelectedHml.forEach(user => {
                    usersIds.push(user.value);
                });

                this._demandaService.getEmailParaUsuariosEnvolvidos(usersIds, this.demandIdNotification)
                    .subscribe(() => { });
            }
            
            this.detalhesAdicionado.emit(ret);
            this.selectedFiles = [];
            this.modal.hide();
        }

    }

    close(): void {
        let ret = {
            ambientes: null,
            eventDrop: this.eventDrop,
            usuarioSap: null,
            tasksSapsSelcionadas: null
        };

        this.usersSelected = [];
        this.selectCodeReviewers = false;
        this.detalhesAdicionado.emit(ret);
        this.modal.hide();
    }

    onScroll({ end }) {
        if (this.usuariosSapsDisponiveis.length <= this.usuariosSapBuffer.length) {
            return;
        }

        if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.usuariosSapBuffer.length) {
            this.fetchMore();
        }
    }

    fetchMore() {
        const len = this.usuariosSapBuffer.length;
        const more = this.usuariosSapsDisponiveis.slice(len, this.bufferSize + len);
        this.usuariosSapBuffer = this.usuariosSapBuffer.concat(more);
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    AdicionarTaskCriacaoRequest(taskSap: TaskSapDto) {
        const index = this.tasksSapsParticipantesDaRequest.findIndex(r => r === taskSap.id);

        if (index === -1) {
            this.tasksSapsParticipantesDaRequest.push(taskSap.id);
        } else {
            this.tasksSapsParticipantesDaRequest.splice(index, 1);
        }
    }

    createObjectRequestAndTask() {
        if (!this.displayTabTasksSaps) this.selectedAll();

        let requests = [];
        let tasks = [];
        let retorno = { tasks: [], requests: [] };
        this.selectedFiles.filter(sf => sf.data.selectable)
            .forEach(sf => {
                if (sf.children != undefined) {
                    requests.push(sf.data.content);
                } else {
                    tasks.push(sf.data.content);
                }
            });
        retorno.tasks = tasks;
        retorno.requests = requests;
        return retorno;
    }

    setTreeData(demandaId) {
        let data = [];
        this._demandaService.obterRequestsAndTasksSaps(demandaId).subscribe((result: AmbienteRequestDto[]) => {
            this.treeData = this.createTree(result);
            this.expandAll();
        });
    }

    filterData(data: RequestDemandaViewDto[]) {
        return data.filter(d => d.status != "Liberado" && (d.tipo == "OrdemCustomizing" || d.tipo == "OrdemWorkbench"))
    }

    criarLevelAmbiente(ambiente: AmbienteRequestDto) {
        const mandantes = ambiente.requests.map(request => request.mandanteSapId).filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
        return this.createNode(
            ambiente.ambienteNome,
            ambiente.ambienteEstagio,
            this.criarLevelMandante(ambiente, mandantes)
        )
    }

    criarLevelMandante(ambiente: AmbienteRequestDto, mandantes: string[]) {
        const mandantesLista = []
        mandantes.forEach(mandanteSapId => {
            const mandante = ambiente.requests.find(item => item.mandanteSapId == mandanteSapId).mandante
            const nodes = this.criarLevelRequest(ambiente.requests, mandanteSapId)
            if (nodes && nodes.length > 0) {
                mandantesLista.push(this.createNode(
                    mandanteSapId ? `${this.l('Mandante')} ${mandante}` : this.l('SemMandante'),
                    mandanteSapId,
                    nodes
                ))
            }
        })
        return mandantesLista
    }

    criarLevelRequest(requests: RequestDemandaViewDto[], mandante: string) {
        let requestsFiltradas = requests.filter(item => item.mandanteSapId == mandante);
        requestsFiltradas = this.filterData(requestsFiltradas);
        return requestsFiltradas.map(request => this.createNode(
            `${request.nome} - ${this.l(request.tipo)} - ${request.descricaoRequest}`,
            request.requestId,
            request.tasks.map(this.criarLevelTask.bind(this)),
            true))
    }

    criarLevelTask(task: TaskSapDto) {
        return this.createNode(
            task.identificacaoSap + " - " + task.developer,
            task.id,
            [],
            true)
    }

    createTree(ambientes: AmbienteRequestDto[]) {
        const tree = ambientes.map(this.criarLevelAmbiente.bind(this))
        return tree;
    }

    createNode(nameLabel, data, children: TreeNode[], selectable: boolean = false) {
        let node: TreeNode = {};
        node.label = nameLabel;
        if (data != null) {
            node.data = {
                content: data,
                selectable
            };
        }
        if (children.length > 0) {
            node.children = children;
        }
        return node;
    }

    expandAll() {
        this.treeData.forEach((node) => {
            this.expandRecursive(node, true);
        });
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach((childNode) => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    selectedAll(treeData: TreeNode[] = this.treeData) {
        if (treeData) {
            treeData.forEach((el) => {
                if (el.children) {
                    this.selectedAll(el.children)
                }
                if (el.selectable) {
                    this.selectedFiles.push(el);
                }
            });
        }
    }

    getSelectedFile(label) {
        return this.selectedFiles.find(item => item ? item.label == label : false);
    }
}
