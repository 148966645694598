import { Component, Injector, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    DemandasServiceProxy,
    DemandTimelineMovementHistoryDto,
} from "@shared/service-proxies/service-proxies";

@Component({
    selector: "app-timeline-demanda",
    templateUrl: "./timeline-demanda.component.html",
    styleUrls: ["./timeline-demanda.component.less"],
})
export class TimelineDemandaComponent
    extends AppComponentBase
    implements OnInit
{
    @Input() tenantId: number = 0;
    urlQuestion1: SafeUrl;
    urlQuestion2: SafeUrl;
    urlQuestion3: SafeUrl;
    loading: boolean = false;
    isDemandHistory: boolean = false;
    currentDate: string = "";
    initDate: string = "";
    demandHistory = {} as DemandTimelineMovementHistoryDto;

    constructor(
        injector: Injector,
        private domSanitizer: DomSanitizer,
        private _demandasServiceProxy: DemandasServiceProxy
    ) {
        super(injector);
        this.getDates();
    }

    async getAverageTransportTime() {
        await this._demandasServiceProxy
            .getAverageTransportTime(this.appSession.tenantId)
            .subscribe(
                (res: DemandTimelineMovementHistoryDto) => {
                    this.demandHistory = res;
                    this.isDemandHistory = this.validIsDemandHistory(res);
                },
                (error: any) => {
                    this.loading = false;
                }
            )
            .add(() => (this.loading = true));
    }

    validIsDemandHistory(demandHistory: DemandTimelineMovementHistoryDto) {
        return (
            demandHistory.averageTransportDevelopment == "0" &&
            demandHistory.averageTransportFinish == "0" &&
            demandHistory.averageTransportHomologation == "0" &&
            demandHistory.averageTransportProduction == "0" &&
            demandHistory.averageTransportTotalTime == "0"
        );
    }

    async ngOnInit() {
        await this.getAverageTransportTime();
        // this.urlQuestion1 = this.domSanitizer.bypassSecurityTrustResourceUrl(
        //     `https://app1.qametrik.com/public/question/fb5c36e6-cb6e-4f25-858a-a4c05caee00b?TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
        // );
        this.urlQuestion1 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/9ac343b4-ec43-4853-b396-31df65c5adaa?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
        this.urlQuestion2 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/165f0c90-5348-468c-b944-644b9b9bd924?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
    }

    getDates() {
        var currentDate = new Date();
        var initDate = new Date();

        initDate.setDate(initDate.getDate() - 30);

        this.currentDate = currentDate.toISOString().slice(0, 10);
        this.initDate = initDate.toISOString().slice(0, 10);
    }
}
