<div [@routerTransition] id="approvalHistoryGmudNative" class="overflow-auto">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("ManageApprovals")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{"Histórico de aprovação de gestão de mudança"}}
            </span>
        </div>     
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable (onLazyLoad)="recarregar($event)"
                            [value]="primengTableHelper.records"
                            rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false" [lazy]="true"
                            [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                            [resizableColumns]="primengTableHelper.resizableColumns">
                            <ng-template pTemplate="header">
                                <tr class="table-header">                                  
                                    <th pSortableColumn="demanda">
                                        {{l("Demanda")}}
                                        <p-sortIcon field="demanda"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="solicitante">
                                        {{l("Solicitante")}}
                                        <p-sortIcon field="solicitante"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="departamento">
                                        {{"Departamento"}}
                                        <p-sortIcon field="departamento"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="dataHora">
                                        {{l("Date")}}
                                        <p-sortIcon field="dataHora"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="statusGmud">
                                        {{l("Status da GMUD")}}
                                        <p-sortIcon field="statusGmud"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="statusAprovador">
                                        {{l("Status do Aprovador")}}
                                        <p-sortIcon field="statusAprovador"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr class="table-body">                                  
                                    <td class="text-truncate">
                                        {{record.demandaSapId}}
                                    </td>
                                    <td class="text-truncate">
                                        {{record.solicitante}}
                                    </td>
                                    <td class="text-truncate">
                                        {{record.departamentHierarchy}}
                                    </td>
                                    <td>
                                        {{getLocaleDateHour(record.dataCriacao)}}
                                    </td>
                                    <td class="text-truncate">
                                        <p-tag [value]="record.statusGmud" [severity]="getStatusColor(record.statusGmud)"></p-tag>
                                    </td>
                                    <td class="text-truncate">
                                        <p-tag  [value]="record.statusAprovador" [severity]="getStatusColor(record.statusAprovador)"></p-tag>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{l('NoData')}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                                (onPageChange)="recarregar($event)"
                                [totalRecords]="primengTableHelper.totalRecordsCount"
                                [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </div>
            </div>
        </div>
    </div>
</div>