import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { MinhasSenhasServiceProxy, CreateOrEditMinhaSenhaDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { MinhaSenhaAmbienteLookupTableModalComponent } from '../../minhasSenhas/minhaSenha-ambiente-lookup-table/minhaSenha-ambiente-lookup-table-modal.component';
import { MinhaSenhaUsuarioSapLookupTableModalComponent } from '../../minhasSenhas/minhaSenha-usuarioSap-lookup-table/minhaSenha-usuarioSap-lookup-table-modal.component';


@Component({
    selector: 'createOrEditMinhaSenhaModal',
    templateUrl: './create-or-edit-minhaSenha-modal.component.html'
})
export class CreateOrEditMinhaSenhaModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @ViewChild('minhaSenhaAmbienteLookupTableModal', { static: true }) minhaSenhaAmbienteLookupTableModal: MinhaSenhaAmbienteLookupTableModalComponent;
    @ViewChild('minhaSenhaUsuarioSapLookupTableModal', { static: true }) minhaSenhaUsuarioSapLookupTableModal: MinhaSenhaUsuarioSapLookupTableModalComponent;


    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    minhaSenha: CreateOrEditMinhaSenhaDto = new CreateOrEditMinhaSenhaDto();

    ambienteNome = '';
    usuarioSapNome = '';


    constructor(
        injector: Injector,
        private _minhasSenhasServiceProxy: MinhasSenhasServiceProxy
    ) {
        super(injector);
    }

    show(minhaSenhaId?: string): void {

        if (!minhaSenhaId) {
            this.minhaSenha = new CreateOrEditMinhaSenhaDto();
            this.minhaSenha.id = minhaSenhaId;
            this.ambienteNome = '';
            this.usuarioSapNome = '';

            this.active = true;
            this.modal.show();
        } else {
            this._minhasSenhasServiceProxy.getMinhaSenhaForEdit(minhaSenhaId).subscribe(result => {
                this.minhaSenha = result.minhaSenha;

                this.ambienteNome = result.ambienteNome;
                this.usuarioSapNome = result.usuarioSapLogin;

                this.active = true;
                this.modal.show();
            });
        }
    }

    save(): void {
            this.saving = true;
            this._minhasSenhasServiceProxy.createOrEdit(this.minhaSenha)
             .pipe(finalize(() => { this.saving = false; }))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }

        openSelectAmbienteModal() {
        this.minhaSenhaAmbienteLookupTableModal.id = this.minhaSenha.ambienteId;
        this.minhaSenhaAmbienteLookupTableModal.displayName = this.ambienteNome;
        this.minhaSenhaAmbienteLookupTableModal.show();
    }
        openSelectUsuarioSapModal() {
        this.minhaSenhaUsuarioSapLookupTableModal.id = this.minhaSenha.usuarioSapId;
        this.minhaSenhaUsuarioSapLookupTableModal.displayName = this.usuarioSapNome;
        this.minhaSenhaUsuarioSapLookupTableModal.show();
    }


        setAmbienteIdNull() {
        this.minhaSenha.ambienteId = null;
        this.ambienteNome = '';
    }
        setUsuarioSapIdNull() {
        this.minhaSenha.usuarioSapId = null;
        this.usuarioSapNome = '';
    }


        getNewAmbienteName() {
        this.minhaSenha.ambienteId = this.minhaSenhaAmbienteLookupTableModal.id;
        this.ambienteNome = this.minhaSenhaAmbienteLookupTableModal.displayName;
    }
        getNewUsuarioSapName() {
        this.minhaSenha.usuarioSapId = this.minhaSenhaUsuarioSapLookupTableModal.id;
        this.usuarioSapNome = this.minhaSenhaUsuarioSapLookupTableModal.displayName;
    }


    close(): void {

        this.active = false;
        this.modal.hide();
    }
}
