<div bsModal #justificativaModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="justificativaModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form #form="ngForm" (ngSubmit)="save()" autocomplete="off">
        <div class="modal-header">
          <h4 class="modal-title">
            <span *ngIf="acao == 1">{{l("Justificativa")}} - {{l("Aprovar")}}</span>
            <span *ngIf="acao == 2">{{l("Justificativa")}} - {{l("Reprovar")}}</span>
            <span *ngIf="acao == 4">{{l("Justificativa")}}</span>
            <span *ngIf="acao == 5">{{l("Justificativa")}} - {{l("FalsoPositivo")}}</span>
          </h4>
          <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <textarea autofocus name="justificativa" class="form-control" required minlength="5" rows="15"
              maxlength="1000" [(ngModel)]="justificativa"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>


          <button [disabled]="!form.valid || saving" class="btn btn-outline-warning pull-right" *ngIf="acao == 5"
            style="margin-left: 5px;" type="submit">{{l("Save")}}</button>
          <button [disabled]="!form.valid || saving" class="btn btn-outline-danger pull-right" *ngIf="acao == 2"
            type="submit">{{l("Save")}}</button>
          <button [disabled]="!form.valid || saving" class="btn btn-outline-success pull-right" type="submit" *ngIf="acao == 1">
            {{l("Save")}}</button>


          <button *ngIf="acao == 4" type="submit" class="btn btn-primary" [disabled]="!form.valid || saving"><i class="fa fa-save"></i>
            <span>{{"Save" | localize}}</span></button>
        </div>
      </form>
    </div>
  </div>
</div>