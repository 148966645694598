<div bsModal #videoViewModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="videoViewModal"
    aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #trainingVideosForm="ngForm" autocomplete="off">
                <div class="modal-header">
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body-videos">
                    <div class="videoWrapper">
                        <iframe [src]="this.url" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
