import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { CodeReviewAprovacaoDto, CodeReviewServiceProxy } from "@shared/service-proxies/service-proxies";
import { ModalDirective } from "ngx-bootstrap/modal";
import { CodeReviewModalComponent } from "./code-review-modal/code-review-modal.component";

@Component({
    selector: 'avaliacoes-code-review',
    templateUrl: './avaliacoes.component.html',
    styleUrls: ['./avaliacoes.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class AvaliacoesCodeReviewComponent extends AppComponentBase implements OnInit {
    @ViewChild('codeReviewModal', { static: true }) codeReviewModal: CodeReviewModalComponent;
    @ViewChild('codeReviewHistoryModal', { static: true }) codeReviewHistoryModal: ModalDirective;

    listaCodeReviewSolicitadosDto : CodeReviewAprovacaoDto[] = [];
    loading: boolean;

    constructor(
        injector: Injector,
        private _codeReviewServiceProxy: CodeReviewServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.getAllAprovacoesPendentes();
    }

    showCodeReviewModal(codeReviewDemandaId: string) : void {
        this.codeReviewModal.show(codeReviewDemandaId);
    }

    showCodeReviewHistoryModal(): void {
        this.codeReviewHistoryModal.show();    
    }

    getAllAprovacoesPendentes(){
        this.loading = true;
        this._codeReviewServiceProxy.getAprovacoesPendentes()
            .subscribe(res => {
                this.loading = false;
                this.listaCodeReviewSolicitadosDto = res;
            });
    }

    reloadPage(ev: any) {
        this.getAllAprovacoesPendentes();
    }

}