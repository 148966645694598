<div class="kt-subheader kt-grid__item">
    <div class="kt-subheader__main">
        <h3 class="kt-subheader__title">
            <span>Projetos</span>
        </h3>
        <span class="kt-subheader__separator kt-subheader__separator--v"></span>
        <span class="kt-subheader__desc">
            Gerenciar projetos
        </span>
    </div>
</div>

<div>
    <tabs-configuration-projetos></tabs-configuration-projetos>
</div>


