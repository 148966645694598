<div bsModal #editModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editModal"
     aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #MoveTenantsForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"MoveTenantsOfEdition" | localize}}</span>
                    </h5>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <a [href]="appBaseUrl + '/app/admin/tenants?editionId=' + moveTenantsInput.sourceEditionId" target="_blank"
                            [routerLink]="['/app/admin/tenants']"
                           [queryParams]="{editionId: moveTenantsInput.sourceEditionId}">
                        {{"MoveTenantsOfEditionDescription" | localize: tenantCount}}
                        </a>
                    </div>
                    <div class="form-group">
                        <label>{{"Edition" | localize}}</label>
                        <select name="expiringEdition" class="form-control" required [(ngModel)]="targetEditionId">
                            <option value="">{{"Select" | localize}}</option>
                            <option *ngFor="let targetEdition of targetEditions" [value]="targetEdition.value">{{targetEdition.displayText}}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-secondary" (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary" [disabled]="!MoveTenantsForm.form.valid" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i> <span>{{l("Save")}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
