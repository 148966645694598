import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { CargosServiceProxy, CreateOrEditCargoDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';


@Component({
    selector: 'createOrEditCargoModal',
    templateUrl: './create-or-edit-cargo-modal.component.html'
})
export class CreateOrEditCargoModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;


    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    duplicado: boolean;
    

    cargo: CreateOrEditCargoDto = new CreateOrEditCargoDto();



    constructor(
        injector: Injector,
        private _cargosServiceProxy: CargosServiceProxy
    ) {
        super(injector);
    }

    show(cargoId?: string): void {
        this.duplicado = false;
        if (!cargoId) {
            this.cargo = new CreateOrEditCargoDto();
            this.cargo.id = cargoId;

            this.active = true;
            this.modal.show();
        } else {
            this._cargosServiceProxy.getCargoForEdit(cargoId).subscribe(result => {
                this.cargo = result.cargo;
                

                this.active = true;
                this.modal.show();
            });
        }
    }

    save(): void {
            this.saving = true;

			
            this._cargosServiceProxy.createOrEdit(this.cargo)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }

    ehDuplicado() : void {

        this.duplicado = false;

        this._cargosServiceProxy.isDuplicated(this.cargo.nome, this.cargo.id).subscribe(result => {
            this.duplicado = result;
        });

    }





    close(): void {

        this.active = false;
        this.modal.hide();
    }
}
