import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ScheduleDeployModalComponent } from "./scheduleDeploy/scheduleDeploy-modal.component";
import { AgendamentoDeployDto, ConfigDeployAutomaticoServiceProxy } from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'configurationTransportScheduling',
    templateUrl: './configurationTransport.component.html',
    styleUrls: ['./configurationTransport.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class ConfigurationTransportSchedulingComponent extends AppComponentBase implements OnInit {
    configurationTransportScheduling: AgendamentoDeployDto[] = [];
    agendamentoDeployDto : AgendamentoDeployDto;

    @ViewChild('scheduleDeployModal', { static: true }) scheduleDeployModal: ScheduleDeployModalComponent;

    constructor(
        injector: Injector,
        private _configDeployAutomaticoService: ConfigDeployAutomaticoServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getScheduleDeploy();
    }

    AddScheduleDeploy(): void {
        this.scheduleDeployModal.show(this.agendamentoDeployDto, false);
    }

    getScheduleDeploy() {
        this._configDeployAutomaticoService.getAgendamentos()
            .subscribe(res => {
                this.configurationTransportScheduling = res;
            });
    }

    getDayOfWeek(day: number) {
        switch(day) { 
            case 0: { return 'Domingo'; } 
            case 1: { return 'Segunda-feira'; } 
            case 2: { return 'Terça-feira'; } 
            case 3: { return 'Quarta-feira'; } 
            case 4: { return 'Quinta-feira'; } 
            case 5: { return 'Sexta-feira'; } 
            case 6: { return 'Sábado'; } 
        }
    }

    reloadPage(ev: any) {
        this.getScheduleDeploy();
    }

    editScheduleDeploy(agendamentoId : string){
        this._configDeployAutomaticoService.getAgendamento(agendamentoId)
            .subscribe(res => {
                this.agendamentoDeployDto = res;
                this.scheduleDeployModal.show(this.agendamentoDeployDto, true);
            });
    }

    

    deleteScheduleDeploy(i: number, agendamentoDeployDto : AgendamentoDeployDto){
        this._configDeployAutomaticoService.deleteAgendamento(agendamentoDeployDto.id)
            .subscribe(() => {
                this.getScheduleDeploy();
                this.notify.success(this.l('SuccessfullyRemoved'));
            });
    }
}