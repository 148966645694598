<div class="d-flex justify-content-between align-items-center custom-timepicker">
    <div class="d-flex flex-column">
        <button type="button" class="chevron-button" (click)="variateHour(1)" *ngIf="!disabled">
            <i class="fa fa-chevron-up"></i>
        </button>
        <input class="input-data form-control" type="text" [(ngModel)]="hours" maxlength="2" [disabled]="disabled">
        <button type="button" class="chevron-button" (click)="variateHour(-1)" *ngIf="!disabled">
            <i class="fa fa-chevron-down"></i>
        </button>
    </div>
    <span class="dot px-3">
        :
    </span>
    <div class="d-flex flex-column">
        <button type="button" class="chevron-button" (click)="variateMinute(1)" *ngIf="!disabled">
            <i class="fa fa-chevron-up"></i>
        </button>
        <input class="input-data form-control" type="text" [(ngModel)]="minutes" maxlength="2" [disabled]="disabled">
        <button type="button" class="chevron-button" (click)="variateMinute(-1)" *ngIf="!disabled">
            <i class="fa fa-chevron-down"></i>
        </button>
    </div>
</div>