import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { ModulosServiceProxy, CreateOrEditModuloDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';


@Component({
    selector: 'createOrEditModuloModal',
    templateUrl: './create-or-edit-modulo-modal.component.html'
})
export class CreateOrEditModuloModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;


    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    modulo: CreateOrEditModuloDto = new CreateOrEditModuloDto();



    constructor(
        injector: Injector,
        private _modulosServiceProxy: ModulosServiceProxy
    ) {
        super(injector);
    }

    show(moduloId?: string): void {

        if (!moduloId) {
            this.modulo = new CreateOrEditModuloDto();
            this.modulo.id = moduloId;

            this.active = true;
            this.modal.show();
        } else {
            this._modulosServiceProxy.getModuloForEdit(moduloId).subscribe(result => {
                this.modulo = result.modulo;


                this.active = true;
                this.modal.show();
            });
        }
    }

    save(): void {
            this.saving = true;

			
            this._modulosServiceProxy.createOrEdit(this.modulo)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }







    close(): void {

        this.active = false;
        this.modal.hide();
    }
}
