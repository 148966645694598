<div class="card p-4" style="overflow-y: scroll; height: 420px; background: #f8f8f8;">
    <div class="p-2" *ngIf="demandaForEdit">
        <p-card [header]="setData(demandaForEdit)">
            <div class="row align-items-center p-2">
                <div class="font-default" [ngClass]="innerWidth > 1399 ? 'col-3' : innerWidth < 720 ? 'col-12' : 'col-6'">
                    <div>
                        <strong>Título: </strong>{{demandaForEdit?.demanda?.titulo ? demandaForEdit?.demanda?.titulo : ''}}
                    </div>
                    <div>
                        <strong>Usuário: </strong>{{demandaForEdit?.userName}}
                    </div>
                    <div>
                        <strong>Código da Demanda: </strong>{{demandaForEdit?.demanda?.demandaSapId}}
                    </div>                   
                </div>
                <div class="font-default" [ngClass]="innerWidth > 1399 ? 'col-3' : innerWidth < 720 ? 'col-12' : 'col-6'">
                    <div>
                        <strong>Status: </strong>{{getStatusDemand(demandaForEdit?.demanda?.status)}}
                    </div>
                    <div>
                        <strong>Tipo: </strong>{{demandaForEdit?.demanda?.tipo}}
                    </div>
                    <div>
                        <strong>Previsão de Termino: </strong>{{demandaForEdit?.demanda?.previsaoTerminoDev | date:'dd/MM/yyyy'}}
                    </div>                    
                </div>
                <div [ngClass]="innerWidth > 1399 ? 'col-6' : 'col-12 mt-4'">
                    <textarea class="font-default" rows="5" cols="30" pInputTextarea [readonly]="true" [autoResize]="true"
                        [value]="demandaForEdit?.demanda?.descricao ? removeHtmlCod(demandaForEdit?.demanda?.descricao) : ''">
                    </textarea>
                </div>
            </div>

            <div class="p-2 col-12" [ngClass]="innerWidth < 1399 ? 'row justify-content-center' : ''">
                <p-timeline [value]="stepDemand" [layout]="innerWidth > 1399 ? 'horizontal' : 'vertical'" 
                    styleClass="customized-timeline" style="padding-left: 100px;">
                    <ng-template pTemplate="marker" let-step>
                        <span class="custom-marker status-steps shadow-2"
                            [style]="getStatusDemand(demandaForEdit?.demanda?.status) == step.description ? 'background: ' + step.color : 'background: #7A8899'">
                            <i [class]="step.icon"></i>
                        </span>
                    </ng-template>
                    <ng-template pTemplate="content" let-step>
                        <span [class]="getStatusDemand(demandaForEdit?.demanda?.status) == step.description ? 'text-bold-status text-status-timeline' : 'text-status-timeline'">
                            <strong>{{step?.description}}</strong><br />
                            {{step?.date | date:'dd/MM/yyyy HH:mm'}}<br />
                            {{step?.user}}
                        </span>
                    </ng-template>
                </p-timeline>
            </div>
        </p-card>
        <p-card class="mt-2" header="GMUD" *ngIf="demandaForEdit?.gestaoMudancaNativa">
            <div class="row align-items-center p-2">
                <div class="font-default" [ngClass]="innerWidth > 1399 ? 'col-3' : innerWidth < 720 ? 'col-12' : 'col-6'">
                    <div>
                        <strong>Solicitado Por: </strong>{{demandaForEdit?.gestaoMudancaNativa?.solicitadoPor}}
                    </div>
                    <div *ngIf="demandaForEdit?.gestaoMudancaNativa?.tituloDemanda">
                        <strong>Título: </strong>{{demandaForEdit?.gestaoMudancaNativa?.tituloDemanda}}
                    </div>
                    <div>
                        <strong>Risco Mudança: </strong>{{demandaForEdit?.gestaoMudancaNativa?.riscoMudanca}}
                    </div>                    
                </div>
                <div class="font-default" [ngClass]="innerWidth > 1399 ? 'col-3' : innerWidth < 720 ? 'col-12' : 'col-6'">
                    <div>
                        <strong>Status: </strong>{{demandaForEdit?.gestaoMudancaNativa?.status}}
                    </div>
                    <div>
                        <strong>Status Transporte: </strong>{{demandaForEdit?.gestaoMudancaNativa?.statusTransporte}}
                    </div>
                    <div>
                        <strong>Tipo de Transporte: </strong>{{demandaForEdit?.gestaoMudancaNativa?.tipoTransporte}}
                    </div>
                </div>
                <div class="col-6 p-2" *ngIf="innerWidth > 1399">
                    <p-timeline [value]="stepGmud" layout="horizontal" styleClass="customized-timeline" style="padding-left: 100px;">
                        <ng-template pTemplate="marker" let-gmud>
                            <span class="custom-marker status-steps shadow-2" [style]="'background: ' + gmud?.color">
                                <i [class]="gmud?.icon"></i>
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content" let-gmud>
                            <span class="text-status-timeline">
                                <strong>{{gmud?.description}}</strong><br />
                                {{gmud?.date | date:'dd/MM/yyyy HH:mm'}}<br />
                                {{gmud?.user}}
                            </span>
                        </ng-template>
                    </p-timeline>                   
                </div>
                <div class="col-12 mt-4">
                    <textarea class="font-default" rows="5" cols="30" pInputTextarea [readonly]="true" [autoResize]="true"
                        [value]="demandaForEdit?.gestaoMudancaNativa?.descricaoDemanda ? removeHtmlCod(demandaForEdit?.gestaoMudancaNativa?.descricaoDemanda) : ''">
                    </textarea>
                </div>
                <div class="p-2 col-12" *ngIf="innerWidth < 1399" [ngClass]="innerWidth < 720 ? 'row justify-content-center' : ''">
                    <p-timeline [value]="stepGmud" [layout]="innerWidth > 720 ? 'horizontal' : 'vertical'" 
                        styleClass="customized-timeline" style="padding-left: 100px;">
                        <ng-template pTemplate="marker" let-gmud>
                            <span class="custom-marker status-steps shadow-2" [style]="'background: ' + gmud?.color">
                                <i [class]="gmud?.icon"></i>
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content" let-gmud>
                            <span class="text-status-timeline">
                                <strong>{{gmud?.description}}<br /></strong>
                                {{gmud?.date | date:'dd/MM/yyyy HH:mm'}}<br />
                                {{gmud?.user}}
                            </span>
                        </ng-template>
                    </p-timeline>                   
                </div>
            </div>
        </p-card>
    </div>
</div>