import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AvaliacaoDeAmbienteServiceProxy, LinhaForViewDto } from "@shared/service-proxies/service-proxies";
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from "primeng/paginator";
import { Table } from "primeng/table";
import * as hljs from 'assets/prism/highlight.pack.js';

@Component({
    selector: 'avaliacao-ambiente-debitos-tecnicos-objeto',
    templateUrl: './debitosTecnicosDoObjeto.component.html',
    styleUrls: ['./debitosTecnicosDoObjeto.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class AvaliacaoAmbienteDebitosTecnicosDoObjetoComponent extends AppComponentBase implements OnInit {

    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    objectName: string;
    objectType: string;

    codigo = '';
    linhas: LinhaForViewDto[] = [];
    
    ngOnInit(): void {
        this.objectName = this.route.snapshot.paramMap.get('name');
        this.objectType = this.route.snapshot.paramMap.get('type');
    }

    constructor(
        injector: Injector,
        private _avaliacaoDeAmbienteService: AvaliacaoDeAmbienteServiceProxy,
        private router: Router,
        private route: ActivatedRoute
    ) {
        super(injector);
    }

    getTecDebs(event?: LazyLoadEvent) {   
        if (event && this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this. _avaliacaoDeAmbienteService.getDebitosTecnicos(
            this.objectName,
            this.objectType,
            this.primengTableHelper.getSorting(this.dataTable),
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event)
        ).subscribe(result => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            this.primengTableHelper.records = result.items;
            this.primengTableHelper.hideLoadingIndicator();
        });
    }

    recarregar(event?: LazyLoadEvent) {
        this.getTecDebs(event);
    }

    getLinesCode(id: string) {
        this.linhas = [];
        this._avaliacaoDeAmbienteService.getCodigoSap(id).subscribe(x => {
        this.codigo = x.codigo;
        this.linhas = x.linhas;

        document.getElementById('codigo').innerHTML = this.codigo;
            hljs.highlightBlock(document.getElementById('codigo'));
    });
    }
}