<form #mandanteForm="ngForm" novalidate (ngSubmit)="adicionarMandante()" autocomplete="off">
    <p-table [value]="listaMandantes">
        <ng-template pTemplate="body" let-record="$implicit">
            <tr>
                <td>
                    <i class="p-2 fa fa-trash text-danger clickable"
                        (click)="deleteMandante(record.mandanteCodigo)"></i>
                    <i class="p-2 fas fa-pen text-primary clickable" (click)="editarMandante(record)"></i>
                    <i class="p-2 fab fa-usb text-warning clickable" (click)="testarConexao(record.mandante)"></i>
                    {{ record.mandante }} - {{ 'Integration' | localize}} {{ integracaoLabel(record.integracaoId) }}
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div *ngIf="showForm" class="my-3">
        <div class="form-group">
            <label for="Mandante_Descricao">{{ l("Descricao") }} *</label>
            <input type="text" id="Mandante_Descricao" class="form-control" [(ngModel)]="mandanteModelo.descricao"
                name="MandanteDescricao" #Mandante_Descricao="ngModel" [ngClass]="{
                                        'is-invalid': Mandante_Descricao.invalid,
                                        'is-valid': !Mandante_Descricao.invalid
                                    }" required />
        </div>
        <app-enum-select class="form-group" sName="TipoMandante" sId="Mandante_TipoMandante" sClass="form-control"
            [label]="l('MandanteType')" [value]="mandanteModelo.tipoMandante"
            (valueChange)="mandanteModelo.tipoMandante = $event" [enumeration]="enumTypeMandante" required>
        </app-enum-select>
        <div class="form-group">
            <label for="Mandante_Codigo">{{ l("Mandante") }} *</label>
            <input type="text" id="Mandante_Codigo" class="form-control" [(ngModel)]="mandanteCodigo" name="Mandante"
                [disabled]="modoEdicao" #Mandante_Codigo="ngModel" maxlength="4" [ngClass]="{
                                    'is-invalid': Mandante_Codigo.invalid,
                                    'is-valid': !Mandante_Codigo.invalid
                                }" required />
        </div>

        <div class="form-group m-form__group">
            <label for="Mandante_TipoIntegracao">{{ l("IntegrationType") }} *</label>
            <div class="input-group">
                <select class="form-control" name="TipoIntegracao" id="Mandante_TipoIntegracao"
                    [(ngModel)]="integracaoId">
                    <option *ngFor="let integracao of integracoes" [value]="integracao.value">
                        {{ integracao.label }}</option>
                </select>

                <div class="input-group-append">
                    <button class="btn btn-primary blue" (click)="openIntegrationModal()" type="button"><i
                            class="fa fa-search text-white pl-2"></i></button>
                </div>
            </div>
        </div>
        <div *ngIf="mandanteModelo.integracaoId === null">
            <div class="form-group">
                <label for="Usuario_Integracao">{{ l("UsuarioIntegracao") }} *</label>
                <input type="text" id="Usuario_Integracao" class="form-control" [(ngModel)]="mandanteModelo.user"
                    name="UsuarioIntegracao" #Usuario_Integracao="ngModel" [ngClass]="{
                                            'is-invalid': Usuario_Integracao.invalid,
                                            'is-valid': !Usuario_Integracao.invalid
                                        }" required />
            </div>
            <div class="form-group">
                <label for="Usuario_Integracao_Senha">{{ l("Senha") }} *</label>
                <input type="text" id="Usuario_Integracao_Senha" class="form-control"
                    [(ngModel)]="mandanteModelo.password" name="UsuarioIntegracaoSenha"
                    #Usuario_Integracao_Senha="ngModel" [ngClass]="{
                                            'is-invalid': Usuario_Integracao_Senha.invalid,
                                            'is-valid': !Usuario_Integracao_Senha.invalid
                                        }" required />
            </div>
            <div class="form-group">
                <label for="Mandante_SapRouter">{{ l("SapRouter") }}</label>
                <input type="text" id="Mandante_SapRouter" class="form-control" [(ngModel)]="mandanteModelo.sapRouter"
                    name="sapRouter" #Mandante_SapRouter="ngModel" />
            </div>
        </div>

        <div *ngIf="nomeBranch != enumEstagios.Desenvolvimento" class="kt-checkbox-list">
            <label for="transporte_automatico" class="kt-checkbox">
                <input id="transporte_automatico" type="checkbox" name="TransporteAutomatico"
                    [(ngModel)]="mandanteModelo.importacaoStms">
                {{"TransporteAutomatico" | localize}}
                <span></span>
            </label>
        </div>
    </div>
    <div class="d-flex flex-row-reverse my-3">
        <button *ngIf="!showForm" type="button" class="btn btn-primary blue" (click)="showForm = true"> {{ 'NewMandante'
            | localize}} </button>
        <button *ngIf="showForm" type="submit" class="btn btn-primary blue" [disabled]="!mandanteForm.form.valid">
            {{ 'Save' | localize }}
        </button>
    </div>
</form>