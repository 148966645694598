<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{ l("SelectRequestsToCreate") }}</span>
                </h4>
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div id="spinner-content" class="spinner-row">
                    <div id="spinner-loading" class="spinner-loading">
                        <p-progressSpinner *ngIf="loading; else data"></p-progressSpinner>
                    </div>
                </div>
                <ng-template #data>
                    <div *ngFor="let item of environmentList" class="mb-3">
                        <h5> {{ item.ambiente }} </h5>
                        <p-table [value]="item.mandantes">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="text-align: justify;">{{'Mandantes' | localize}}</th>
                                    <th style="text-align: justify;">{{'OrdemWorkbench' | localize}}</th>
                                    <th style="text-align: justify;">{{'OrdemCustomizing' | localize}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td> {{ record.mandante }} </td>
                                    <td>
                                        <mat-checkbox [ngModel]="record.hasWorkbench || record.workbench"
                                            (change)="record.workbench = $event.checked"
                                            [disabled]="record.hasWorkbench" color="primary">
                                        </mat-checkbox>
                                    </td>
                                    <td>
                                        <mat-checkbox [ngModel]="record.hasCustomizing || record.customizing"
                                            (change)="record.customizing = $event.checked"
                                            [disabled]="record.hasCustomizing" color="primary">
                                        </mat-checkbox>

                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </ng-template>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary blue" (click)="select()">
                    {{ l("Save") }}
                </button>
            </div>
        </div>
    </div>
</div>