import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AvaliacoesDeQualidadesServiceProxy, ParameterSkipQualityDto } from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs";


@Component({
    selector: 'configuration-quality',
    templateUrl: './configuringQualityAssessment.component.html',
    styleUrls: ['./configuringQualityAssessment.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class ConfigurationQualityComponent extends AppComponentBase implements OnInit {
    appSession: AppSessionService;
    skipReview: boolean = false;

    loading = false;

    constructor(
        injector: Injector,
        private _avaliacoesQualidadeService: AvaliacoesDeQualidadesServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getParameters();
    }

    getParameters() {
        this._avaliacoesQualidadeService.getParameters()
            .subscribe(res => {
                this.skipReview = res.skipReview;
            });
    }

    saveParameters() {
        let parametersDto = new ParameterSkipQualityDto;
        parametersDto.skipReview = this.skipReview;
        this.loading = true;
        this._avaliacoesQualidadeService.insertUpdateParameters(parametersDto)
        .pipe(finalize(() => this.loading = false))
        .subscribe(x => { 
            this.getParameters();
            this.notify.success(this.l('SavedSuccessfully'));
        });  
    }
}