<div>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("AvaliacoesDeQualidades")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{l("ItensAvaliacoesDeQualidadesHeaderInfo")}}
            </span>
        </div>
    </div>
    <div class="spinner-row">
        <div class="spinner-loading">
            <p-progressSpinner *ngIf="loading"></p-progressSpinner>
        </div>
    </div>
    <div class="kt-content" *ngIf="!loading">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <div style="margin-bottom: 15px"> 
                    <i *ngIf="emAvaliacao" class="fas far fa-eye evaluation-eye pull-right" tooltip="{{'EvaluationByAnotheUser' | localize}}" ></i>
                    <div *ngIf="!emAvaliacao">
                        <div *ngIf="canEvaluate">
                            <button class="btn btn-outline-danger pull-right" [disabled]="guidsSelecionados.length <= 0"
                                (click)="justificativaAvaliacao.show(guidsSelecionados, 2, guidsJust);">{{l("Reprovar")}}</button>
                            <button class="btn btn-outline-success pull-right mr-2"
                                (click)="justificativaAvaliacao.show(guidsSelecionados, 1, guidsJust);"
                                [disabled]="guidsSelecionados.length <= 0">
                                {{l("Aprovar")}}</button>
                        </div>
                        
                        <button class="btn btn-outline-primary pull-right"
                            *ngIf="canJustify && !skipQuality"
                            (click)="justificativaAvaliacao.show(guidsSelecionados, 4, guidsJust); "
                            [disabled]="guidsSelecionados.length <= 0">
                            {{l("Justificar")}}
                        </button>

                        <button class="btn btn-outline-primary pull-right"
                            *ngIf="skipQuality" (click)="userAware()">
                            {{l("Ciente")}}
                        </button>

                        <button class="btn btn-outline-success pull-left" style="margin-left: 5px;"
                            *ngIf="enviarParaDesenvolvedor() && !skipQuality" (click)="enviarParaDev()"
                            [disabled]="temItemSemQa()"><i class="fas fa-user-alt"></i>{{ l("VoltarParaDesenvolvedor") }}
                        </button>
                        
                        <button class="btn btn-outline-success pull-left" style="margin-left: 5px;"
                        *ngIf="finalizaAvaliacao() && !skipQuality" (click)="enviarParaDev()"
                        [disabled]="temItemSemQa()"><i class="fas fa-user-alt"></i>{{ l("Finalizar") }}</button>
                        
                        <button class="btn btn-outline-success pull-left" style="margin-left: 5px;"
                            *ngIf="enviarParaGestor() && !skipQuality" (click)="enviarParaQaManager()"
                            [disabled]="temItemSemjustifiva()"><i class="fas fa-user-tie"></i>{{l("EnviarParaGestor")}}</button>

                        <i class="fas fa-exclamation-triangle icone-warning-length"
                            *ngIf="temItemSemjustifiva() && appSession.tenantId != null && !skipQuality"
                            tooltip="{{'TodosOsItensDevemSerSolucionados' | localize}}"></i>
                    </div>
                </div>
                <div *ngIf="solicitarDecisor && dataSolicitarDecisor ">
                    <mat-card style="background-color: lightyellow; margin-top: 2px;">
                        <mat-card-subtitle>{{dataSolicitarDecisor | momentFormat:'DD/MM/YYYY [às] HH:mm'}},
                            {{lblUserSolicitarDecisor}}</mat-card-subtitle>
                        <mat-expansion-panel (opened)="setOpenedJustificativa()" (closed)="setClosedJustificativa()">
                            <mat-expansion-panel-header>
                                <mat-panel-title *ngIf="!justificativaAberto">
                                    <strong>{{(isDecisor ? "ArgumentacaoDecisor" : "Click2SeeJustificativaDecisor" ) |
                                        localize}}:
                                    </strong> &nbsp;
                                    {{removeHtml(justificativaDecisorText) }}
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <strong>{{(isDecisor ? "ArgumentacaoDecisor" : "Click2SeeJustificativaDecisor" ) |
                                localize}}:</strong>
                            <div [innerHTML]="justificativaDecisorText"></div>
                        </mat-expansion-panel>
                    </mat-card>
                </div>
                <div *ngIf="isDecisor && dataOrientacaoDecisor">
                    <mat-card style="background-color: lightyellow; margin-top: 2px; margin-bottom: 2px;">
                        <mat-card-subtitle>{{dataOrientacaoDecisor | momentFormat:'DD/MM/YYYY [às] HH:mm'}},
                            {{lblUserOrientacaoDecisor}}</mat-card-subtitle>
                        <mat-expansion-panel (opened)="setOpenedOrientacao()" (closed)="setClosedOrientacao()">
                            <mat-expansion-panel-header>
                                <mat-panel-title *ngIf="!orientacaoAberto">
                                    <strong>{{"OrientacaoDecisor" | localize}}: </strong> &nbsp;
                                    {{removeHtml(orientacaoDecisorText) }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <strong>{{"OrientacaoDecisor" | localize}}:</strong>
                            <div [innerHTML]="orientacaoDecisorText"></div>
                        </mat-expansion-panel>
                    </mat-card>
                </div>
                <div class="row align-items-center">
                    <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable (onLazyLoad)="getAvaliacoesDeQualidades($event)"
                            [value]="primengTableHelper.records"
                            rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false" [lazy]="true"
                            [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                            [resizableColumns]="primengTableHelper.resizableColumns">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 50px">
                                        <mat-checkbox (change)="selecionarTodos()" [indeterminate]="indeterminate"
                                            [checked]="selectedAll">
                                        </mat-checkbox>
                                    </th>
                                    <th style="width: 100px" pSortableColumn="status">
                                        {{l('Situacao')}} <p-sortIcon field="status"></p-sortIcon>
                                    </th>
                                    <th style="width: 100px">
                                        {{l('Demanda')}}
                                    </th>
                                    <th style="width: 100px; text-align: center;" pSortableColumn="tipo">
                                        {{l('Tipo')}} <p-sortIcon field="tipo"></p-sortIcon>
                                    </th>

                                    <th style="width: 150px" pSortableColumn="objetoSap">
                                        {{l('Objeto')}} <p-sortIcon field="objetoSap"></p-sortIcon>
                                    </th>
                                    <th style="width: 100px" pSortableColumn="linha">
                                        {{l('Linha')}} <p-sortIcon field="linha"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="descricao">
                                        {{l('descricao')}} <p-sortIcon field="descricao"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px">
                                        {{l('Actions')}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td style="width:50px ">
                                        <mat-checkbox name="checkSelect" [(ngModel)]="record.check">
                                        </mat-checkbox>
                                    </td>
                                    <td style="width:100px">
                                        {{status2String(record.status)}}
                                    </td>
                                    <td style="width:100px;text-align: center;">
                                        {{demandaSapId}}
                                    </td>
                                    <td style="width:100px;text-align: center;">
                                        {{record.tipo}}
                                    </td>

                                    <td style="width:150px">
                                        {{record.objetoSap}}
                                    </td>
                                    <td style="width: 100px">
                                        {{record.linha}}
                                    </td>
                                    <td>
                                        <a class="documentation-link" *ngIf="record.documentacaoItemQualidadeId" href="javascript:;"
                                        (click)="documentacaoModal.show(record.documentacaoItemQualidadeId)">{{l(record.descricao)}}
                                        </a>
                                        <span  *ngIf="!record.documentacaoItemQualidadeId">{{l(record.descricao)}}</span>
                                    </td>

                                    <td style="width: 150px">
                                        <div class="btn-group dropdown" dropdown container="body" placement="top left">
                                            <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle>
                                                <i class="fa fa-cog"></i><span class="caret"></span> {{l("Actions")}}
                                            </button>
                                            <ul class="dropdown-menu" *dropdownMenu>
                                                <li>
                                                    <a href="javascript:;"
                                                        (click)="historicoAvaliacaoCodigo.show(record.id)">{{l('History')}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;"
                                                        (click)="codigoSap.show(record.id, avaliacaoId)">{{l('VerCodigo')}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data"
                            *ngIf="primengTableHelper.records && primengTableHelper.records.length == 0">
                            {{l('NoData')}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-historico-avaliacao-codigo #historicoAvaliacaoCodigo></app-historico-avaliacao-codigo>
    <app-codigo-sap #codigoSap></app-codigo-sap>
    <app-justificativa-avaliacao #justificativaAvaliacao (modalSave)="getAvaliacoesDeQualidades($event)">
    </app-justificativa-avaliacao>
    <app-justificativa-decisor #justificativaDecisor></app-justificativa-decisor>
    <app-orientacao-decisor #orientacaoDecisor></app-orientacao-decisor>
    <app-documentacao-item-qualidade-modal #documentacaoModal></app-documentacao-item-qualidade-modal>
</div>