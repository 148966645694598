import { Component, EventEmitter, Injector, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { CodeReviewCriterioDemandaDto, CodeReviewDemandaDto, CodeReviewInput, CodeReviewServiceProxy } from "@shared/service-proxies/service-proxies";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: 'code-review-modal',
    templateUrl: './code-review-modal.component.html',
    styleUrls: ['./code-review-modal.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class CodeReviewModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('codeReviewModal', { static: true }) codeReviewModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() saved: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() openCodeReviewModal: EventEmitter<any> = new EventEmitter<any>();
    
    codeReviewDemandaDto : CodeReviewDemandaDto = new CodeReviewDemandaDto();
    tituloDemanda : string
    listaCriteriosDto : CodeReviewCriterioDemandaDto[] = [];
    criterioDto : CodeReviewCriterioDemandaDto = new CodeReviewCriterioDemandaDto();
    codeReviewInput: CodeReviewInput = new CodeReviewInput();

    loading: boolean;
    saving: boolean = false;

    canSaveCodeReview : boolean;

    isInadequado : boolean = false;

    constructor(
        injector: Injector,
        private _codeReviewServiceProxy : CodeReviewServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        //OnInit
    }
    
    async show(codeReviewDemandaId:string) {
        this.codeReviewDemandaDto.id = codeReviewDemandaId
        this.getListaCriterios(codeReviewDemandaId);

        this.codeReviewModal.show();
    }

    getListaCriterios(codeReviewDemandaId:string){
        this.listaCriteriosDto = [];
        
        this._codeReviewServiceProxy.getCodeReviewDemanda(codeReviewDemandaId)
            .subscribe(res => {
                this.tituloDemanda = res.tituloDemanda;
                this.listaCriteriosDto = res.criteriosDemanda;
            });
    }
    
    close(): void {
        this.codeReviewModal.hide();
        this.closed.emit();
    }

    criterioAdequado(criterio: CodeReviewCriterioDemandaDto){ 
        criterio.adequado = true;
        criterio.inadequado = false;

        this.enableCodeReviewButton();
    }

    criterioInadequado(criterio: CodeReviewCriterioDemandaDto){
        criterio.inadequado = true;
        criterio.adequado = false;

        this.enableCodeReviewButton();
    }

    enableCodeReviewButton(){
        this.listaCriteriosDto = [...this.listaCriteriosDto];
        let verifyList = this.listaCriteriosDto.find(criterio => (!criterio.adequado && !criterio.inadequado));
        if(verifyList){
            this.canSaveCodeReview = false
        }else{
            this.canSaveCodeReview = true
        }
    }

    saveCriterios(){
        this.codeReviewInput.codeReviewDemandaId = this.codeReviewDemandaDto.id;
        this.codeReviewInput.criteriosRespondidos = this.listaCriteriosDto;

        let verifyJustificativas = this.listaCriteriosDto.find(criterio => criterio.inadequado && criterio.justificativa.length < 25);

        if(verifyJustificativas){
            this.notify.warn("Há justificativas pendentes");
        }else{
            this.loading = true;
            this.saving = true;
            this._codeReviewServiceProxy.inserirAvaliacaoCodeReview(this.codeReviewInput)
            .subscribe(() => {
                this.loading = false;
                this.saving = false;
                this.notify.success("Salvo com sucesso");
                this.close();
            });  
        }     
    }
}