import {
    ClienteFornecedorDto,
    ClientesFornecedoresServiceProxy
} from './../../../../../shared/service-proxies/service-proxies';
import { AppComponentBase } from 'shared/common/app-component-base';
import {
    Component,
    Injector,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';

import {
    FornecedoresServiceProxy,
    FornecedorDto
} from '@shared/service-proxies/service-proxies';

import { Paginator } from 'primeng/paginator';
import {  LazyLoadEvent } from 'primeng/api';

import { Table } from 'primeng/table';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-adicionar-fornecedores',
    templateUrl: './adicionar-fornecedores.component.html'
})
export class AdicionarFornecedoresComponent extends AppComponentBase {
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @ViewChild('paginacao', { static: true }) paginator: Paginator;
    @ViewChild('dataTable', { static: true }) dataTable: Table;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    filter = '';
    active = false;
    saving = false;

    fornecedorVinculadoTenant: ClienteFornecedorDto = new ClienteFornecedorDto();

    constructor(
        injector: Injector,
        private _fornecedoresServiceProxy: FornecedoresServiceProxy,
        private _clienteFornecedorTenantProxy: ClientesFornecedoresServiceProxy
    ) {
        super(injector);
    }

    getFornecedores(event?: LazyLoadEvent) {
        this.primengTableHelper.showLoadingIndicator();

        this._fornecedoresServiceProxy
            .getAllFornecedoresFilterByTenant(
                this.filter,
                this.primengTableHelper.getSorting(this.dataTable),
                this.primengTableHelper.getSkipCount(this.paginator, event),
                this.primengTableHelper.getMaxResultCount(this.paginator, event)
            )
            .pipe(
                finalize(() => this.primengTableHelper.hideLoadingIndicator())
            )
            .subscribe(result => {
                this.primengTableHelper.totalRecordsCount = result.totalCount;
                this.primengTableHelper.records = result.items;
                this.primengTableHelper.hideLoadingIndicator();
            });
    }

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }

    show(): void {
        this.getFornecedores();
        this.modal.show();
    }

    save(fornecedor: FornecedorDto) {
        this.fornecedorVinculadoTenant.fornecedorId = fornecedor.id;

        this._clienteFornecedorTenantProxy
            .create(this.fornecedorVinculadoTenant)
            .subscribe(() => {
                this.notify.success(this.l('FornecedorVinculadoSucesso'));
                this.getFornecedores();
            });
    }

    close() {
        this.modal.hide();
        this.modalSave.emit(null);
    }
}
