<div [@routerTransition]>

    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{"Roles" | localize}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{"RolesHeaderInfo" | localize}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button (click)="createRole()" *ngIf="'Pages.Administration.Roles.Create' | permission" class="btn btn-primary"><i class="fa fa-plus"></i> {{"CreateNewRole" | localize}}</button>
            </div>
        </div>
    </div>

    <div class="kt-content">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="kt-form">
                    <div class="row">
                        <div class="col-xl-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <permission-combo name="permissionFilter" [(ngModel)]="selectedPermission"></permission-combo>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6 col-sm-6 text-right">
                            <button name="RefreshButton" class="btn btn-primary" (click)="getRoles()"><i class="la la-refresh"></i> {{'Refresh' | localize}}</button>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div class="col primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable
                                 (onLazyLoad)="getRoles()"
                                 [value]="primengTableHelper.records"
                                 rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                 [paginator]="false"
                                 [lazy]="true"
                                 [responsive]="primengTableHelper.isResponsive">

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 130px" [hidden]="!isGrantedAny('Pages.Administration.Roles.Edit', 'Pages.Administration.Roles.Delete')">{{'Actions' | localize}}</th>
                                    <th>{{'RoleName' | localize}}</th>
                                    <th>{{'CreationTime' | localize}}</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td [hidden]="!isGrantedAny('Pages.Administration.Roles.Edit', 'Pages.Administration.Roles.Delete')">
                                        <div class="btn-group dropdown" dropdown container="body">
                                            <button dropdownToggle class="dropdown-toggle btn btn-primary btn-sm dropdown-toggle">
                                                <i class="fa fa-cog"></i><span class="caret"></span> {{"Actions" | localize}}
                                            </button>
                                            <ul class="dropdown-menu" *dropdownMenu>
                                                <li>
                                                    <a href="javascript:;" *ngIf="'Pages.Administration.Roles.Edit' | permission"
                                                       (click)="createOrEditRoleModal.show(record.id)">{{'Edit' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="!record.isStatic && ('Pages.Administration.Roles.Delete' | permission)"
                                                       (click)="deleteRole(record)">{{'Delete' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="entityHistoryEnabled"
                                                       (click)="showHistory(record)">{{'History' | localize}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        <span>
                                            {{record.displayName}}
                                            <span *ngIf="record.isStatic"
                                                  class="kt-badge kt-badge--brand kt-badge--inline"
                                                  data-toggle="tooltip"
                                                  title="{{'StaticRole_Tooltip' | localize}}"
                                                  data-placement="top">
                                                {{'Static' | localize}}
                                            </span>
                                            <span *ngIf="record.isDefault"
                                                  class="kt-badge kt-badge--dark kt-badge--inline"
                                                  data-toggle="tooltip"
                                                  title="{{'DefaultRole_Description' | localize}}"
                                                  data-placement="top">
                                                {{'Default' | localize}}
                                            </span>
                                        </span>
                                    </td>
                                    <td>
                                        {{record.creationTime | momentFormat:'L'}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{'NoData' | localize}}
                        </div>
                        <div class="ui-table-footer">
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </div>
            </div>
        </div>
    </div>

    <createOrEditRoleModal #createOrEditRoleModal (modalSave)="getRoles()"></createOrEditRoleModal>
    <entityTypeHistoryModal #entityTypeHistoryModal></entityTypeHistoryModal>
</div>
