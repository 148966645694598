import { Component, EventEmitter, HostListener, Injector, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { GerenciamentoVersaoSapDto, GerenciamentoVersaoSapRequestDto, GerenciamentoVersaoSapServiceProxy, GetGerenciamentoVersaoSapRequestArquivoDto } from "@shared/service-proxies/service-proxies";
import { ModalDirective } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { AppConsts } from "@shared/AppConsts";
import * as uuid from 'uuid';
import { IAjaxResponse, TokenService } from "abp-ng2-module";

@Component({
    selector: 'create-versionamento-modal',
    templateUrl: './create-versionamento-modal.component.html',
    styleUrls: ['./create-versionamento-modal.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class CreateVersionamentoComponent extends AppComponentBase implements OnInit {
    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
      }
    
      @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
      }
    
      @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        
        const files = event.dataTransfer?.files;
        if (files && files.length > 0) {
          this.onFilesAdded(null,files);
        }
      }
    @ViewChild('createVersionamentoModal', { static: true }) createVersionamentoModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleted: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();

    saving: boolean = false;
    loading: boolean = false;

    versionControlTitleInput: string;
    versionControlContentInput: string;
    versionControlDocument: string;
    versionControlAchivedInput: boolean;

    controlVersionDto: GerenciamentoVersaoSapDto = new GerenciamentoVersaoSapDto();

    requestList: any[] = [];
    listGerenciamentoVersaoSapRequestDto: GerenciamentoVersaoSapRequestDto[] = [];
    versionControlNumberRequest: string;
    versionControlDescriptionRequest: string;
    anexosAdicionados: GetGerenciamentoVersaoSapRequestArquivoDto[] = [];
    guidArquivoAnexo: string;

    gerenciamentoVersaoSapId: string;

    emProcessamento: boolean;

    public uploader: FileUploader;
    private _uploaderOptions: FileUploaderOptions = {
        url: ""
    };

    constructor(
        injector: Injector,
        private _gerenciamentoVersaoSapServiceProxy: GerenciamentoVersaoSapServiceProxy,
        private _tokenService: TokenService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
    }

    async show(gerenciamentoVersaoSapId: string) {
        if (gerenciamentoVersaoSapId != null) {
            this.gerenciamentoVersaoSapId = gerenciamentoVersaoSapId;
            this._gerenciamentoVersaoSapServiceProxy.getGerencimentoVersaoSap(gerenciamentoVersaoSapId)
                .subscribe(res => {
                    this.versionControlAchivedInput = res.arquivado;
                    this.versionControlTitleInput = res.titulo;
                    this.versionControlContentInput = res.cutover;
                    this.versionControlDocument = res.documentacao;
                    this.emProcessamento = res.emProcessamento;

                    res.listaRequests.forEach(element => {
                        this.requestList.push(
                            {
                                numberDescricao: element.numero + " - " + element.descricao,
                                number: element.numero,
                                description: element.descricao,
                                arquivos: element.arquivos,
                                id: element.id
                            });
                    });
                })
        } else {
            this.gerenciamentoVersaoSapId = '00000000-0000-0000-0000-000000000000';
        }

        this.createVersionamentoModal.show();
    }

    close() {
        this.clearInputs();
        this.createVersionamentoModal.hide();
        this.closed.emit();
        this.emProcessamento = false;
    }

    saveVersion() {
        this.saving = true;
        this.loading = true;

        if (!this.validateVersionControl()) {
            this.saving = false;
            this.loading = false;
            return;
        }

        this.controlVersionDto.titulo = this.versionControlTitleInput;
        this.controlVersionDto.cutover = this.versionControlContentInput;
        this.controlVersionDto.documentacao = this.versionControlDocument;
        this.controlVersionDto.arquivado = this.versionControlAchivedInput;
        this.controlVersionDto.id = this.gerenciamentoVersaoSapId;

        this.requestList.forEach(element => {
            let gerenciamentoVersaoSapRequestDto = new GerenciamentoVersaoSapRequestDto();
            gerenciamentoVersaoSapRequestDto.numero = element.number;
            gerenciamentoVersaoSapRequestDto.descricao = element.description;
            gerenciamentoVersaoSapRequestDto.arquivos = element.arquivos;
            gerenciamentoVersaoSapRequestDto.id = element.id;

            this.listGerenciamentoVersaoSapRequestDto.push(gerenciamentoVersaoSapRequestDto);
        });

        this.controlVersionDto.listaRequests = this.listGerenciamentoVersaoSapRequestDto;

        this._gerenciamentoVersaoSapServiceProxy.inserirGerenciamentoVersaoSap(this.controlVersionDto)
            .pipe(finalize(() => this.loading = false))
            .subscribe(result => {
                this.clearInputs();
                this.saving = false;
                this.close();
                this.notify.success(this.l("VersionControlSaved"));
            });
    }

    clearInputs() {
        this.controlVersionDto = new GerenciamentoVersaoSapDto();
        this.versionControlTitleInput = "";
        this.versionControlContentInput = "";
        this.versionControlDocument = "";
        this.versionControlAchivedInput = false;
        this.gerenciamentoVersaoSapId = '00000000-0000-0000-0000-000000000000';
        this.listGerenciamentoVersaoSapRequestDto = [];
        this.requestList = [];
        this.clearRegisterRequests();
    }

    validateVersionControl() {
        if (this.versionControlTitleInput == undefined || this.versionControlTitleInput == "") {
            this.notify.warn(this.l("VersionControlTitleInvalid"));
            return false;
        }

        if (this.requestList == undefined || this.requestList.length == 0) {
            this.notify.warn(this.l("VersionControlArchiveInvalid"));
            return false;
        }

        return true;
    }

    validateListRequests() {
        if (this.versionControlNumberRequest == undefined || this.versionControlNumberRequest == "") {
            this.notify.warn(this.l("VersionControlNumberInvalid"));
            return false;
        }

        if (this.versionControlDescriptionRequest == undefined || this.versionControlDescriptionRequest == "") {
            this.notify.warn(this.l("VersionControlDescriptionInvalid"));
            return false;
        }

        if (this.anexosAdicionados == undefined || this.anexosAdicionados.length == 0) {
            this.notify.warn(this.l("VersionControlDocumentInvalid"));
            return false;
        }

        return true;
    }

    createItemRequest() {
        if (!this.validateListRequests()) {
            return;
        }

        let numberDescricao = this.versionControlNumberRequest + " - " + this.versionControlDescriptionRequest;

        if ((this.versionControlNumberRequest != undefined && this.versionControlNumberRequest != "") && (this.versionControlDescriptionRequest != undefined && this.versionControlDescriptionRequest != "")) {
            this.requestList.push(
                {
                    numberDescricao: numberDescricao,
                    number: this.versionControlNumberRequest,
                    description: this.versionControlDescriptionRequest,
                    arquivos: this.anexosAdicionados
                });
        }

        this.notify.success(this.l("VersionControlRequestAdd"));
        this.clearRegisterRequests();
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    removerRequests(numberDescricao: string) {
        this.requestList = this.requestList.filter((x) => x.numberDescricao !== numberDescricao);
    }

    clearRegisterRequests() {
        this.versionControlNumberRequest = "";
        this.versionControlDescriptionRequest = "";
        this.anexosAdicionados = [];
    }

    onFilesAdded(event: any, fileList:FileList | null) {

        const files: File[] = [];

        if (fileList != null) {
            for (let i = 0; i < fileList.length; i++) {
                files.push(fileList[i]);
            }
        }

        if(event != null){
            const input = event.target as HTMLInputElement;
            if (input.files) {
                for (let i = 0; i < input.files.length; i++) {
                    files.push(input.files[i]);
                }
            }
        }
        this.uploader = new FileUploader({
            url: AppConsts.remoteServiceBaseUrl + '/File/UploadAttachment'
        });
        this.uploader.clearQueue();
        if (files.length > 0) {
            this.uploader.addToQueue(files);
        } 
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
        this._uploaderOptions.url = AppConsts.remoteServiceBaseUrl + '/File/UploadAttachment'

        files.forEach(file => {
            this.uploader.onAfterAddingFile = file => {
                file.withCredentials = false;
            };

            this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
                this.guidArquivoAnexo = uuid.v4();
                form.append('FileType', fileItem.file.type);
                form.append('FileName', fileItem.file.name);
                form.append('FileToken', this.guidArquivoAnexo);
            };

            this.uploader.setOptions(this._uploaderOptions);
            this.uploader.onSuccessItem = (item, response, status) => {
                const resp = <IAjaxResponse>JSON.parse(response);
                if (resp.success) {
                    let inputVincularAnexo: GetGerenciamentoVersaoSapRequestArquivoDto = new GetGerenciamentoVersaoSapRequestArquivoDto();
                    inputVincularAnexo.fileType = item.file.type;
                    inputVincularAnexo.nomeAnexo = item.file.name;
                    inputVincularAnexo.anexoId = this.guidArquivoAnexo;
                    this.anexosAdicionados.push(inputVincularAnexo);
                } else {
                    this.message.error(resp.error.message);
                }
            };

            this.uploader.uploadAll();
        });
    }

    removerAnexo(anexo: GetGerenciamentoVersaoSapRequestArquivoDto) {
        this.message.confirm('', this.l('RemoverAnexo'), isConfirmed => {
            if (isConfirmed) {
                this.anexosAdicionados = this.anexosAdicionados.filter((x) => x.nomeAnexo !== anexo.nomeAnexo);
            }
        });
    }
}