<div bsModal #createEditProjetoModal="bs-modal" id="crateEditProjetoModal" class="modal fade change-modal overflow-auto"
    tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-md">
        <div class="modal-content change-modal">
            <form #createEditProjetoForm="ngForm" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title" *ngIf="this.newProject">
                        Adicionar projeto
                    </h4>
                    <h4 class="modal-title" *ngIf="!this.newProject">
                        Editar projeto
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                    <div class="gmud-native-content" *ngIf="!loading">
                        <div class="form-group">
                            <input [(ngModel)]="projetoNome" id="required-departament" type="text" name="projetoNome"
                                #projetoName="ngModel" class="form-control" maxlength="50"
                                [ngClass]="{ 'is-invalid': projetoName.invalid, 'is-valid': !projetoName.invalid }"
                                required>
                            <validation-messages [formCtrl]="projetoName"></validation-messages>
                        </div>
                        <div class="row align-items-center" *ngIf="!newProject">
                            <mat-radio-group [(ngModel)]="projetoStatus" class="d-flex col-1" color="primary" name="projetoStatus">
                                <mat-radio-button *ngFor="let status of statusProject" [value]="status" class="mr-3">
                                    {{status}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>


                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default"
                        (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary blue" (click)="addProjeto()"
                        [disabled]="createEditProjetoForm.invalid" [buttonBusy]="saving"
                        [busyText]="'SavingWithThreeDot' | localize">
                        <i class="fa fa-save"></i>
                        <span>{{l("Save")}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>