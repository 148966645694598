import * as ngCommon from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { CommonModule } from '@shared/common/common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { ModalModule } from 'ngx-bootstrap/modal';
PaginatorModule
ProgressSpinnerModule
EditorModule
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EditorModule } from 'primeng/editor';

import { TableModule } from 'primeng/table';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ListChartComponent } from './list-chart/list-chart.component';
import { EnumSelectComponent } from './enum-select/enum-select.component';
import { CustomRangeDatepickerComponent } from './custom-range-datepicker/custom-range-datepicker.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CustomDaterangepickerComponent } from './custom-range-datepicker/custom-daterangepicker/custom-daterangepicker.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { CustomTimepickerComponent } from './custom-timepicker/custom-timepicker.component';
import { PortalEvaluationModal } from './portal-evaluation/portal-evaluation-modal.component'
import { PortalNotificationsModal } from './portal-notifications/portal-notifications-modal.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { LocalizePipe } from '@shared/common/pipes/localize.pipe';
import { AbpModule } from 'abp-ng2-module';

@NgModule({
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        AbpModule,
        CommonModule,
        TableModule,
        ProgressSpinnerModule,
        PaginatorModule,
        BsDatepickerModule.forRoot(),
        CarouselModule,
        EditorModule
    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        ListChartComponent,
        EnumSelectComponent,
        CustomRangeDatepickerComponent,
        CustomDaterangepickerComponent,
        CustomSelectComponent,
        CustomTimepickerComponent,
        PortalEvaluationModal,
        PortalNotificationsModal
    ],
    exports: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        DragDropModule,
        ListChartComponent,
        EnumSelectComponent,
        CustomRangeDatepickerComponent,
        CustomSelectComponent,
        CustomTimepickerComponent,
        PortalEvaluationModal,
        PortalNotificationsModal
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: AppCommonModule,
            providers: [
                AppAuthService,
                AppRouteGuard
            ]
        };
    }
}
