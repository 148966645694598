import { Component, EventEmitter, Injector, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ConvertDateService } from '@shared/convert-date.service';
import { AgendamentoDeployDto, ConfigDeployAutomaticoServiceProxy } from '@shared/service-proxies/service-proxies';
import moment from 'moment/moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-schedule-deploy-modal',
  templateUrl: './scheduleDeploy-modal.component.html',
  styleUrls: ['./scheduleDeploy-modal.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class ScheduleDeployModalComponent extends AppComponentBase {
  @ViewChild('scheduleDeployModal', { static: true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  dayWeek: number;
  startHour: Date;
  endHour: Date;
  loading: boolean = false;
  scheduleId: string = '00000000-0000-0000-0000-000000000000';

  constructor(
    injector: Injector,
    private convertDateService: ConvertDateService,
    private _configDeployAutomaticoService: ConfigDeployAutomaticoServiceProxy
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  async show(agendamentoDto : AgendamentoDeployDto, edit: boolean) {
    if (edit) {
      this.dayWeek = agendamentoDto.diaDaSemana;
      this.startHour = this.convertDateService.convert(agendamentoDto.horaInicial);
      this.endHour = this.convertDateService.convert(agendamentoDto.horaFinal);
      this.scheduleId = agendamentoDto.id;

      this.modal.show();

      return;
    }
    this.dayWeek = 0;
    this.cleanProperty();
    this.modal.show();
  }

  close(): void {
    this.modal.hide();
    this.closed.emit();
  }

  save() {
    const save = document.getElementById('submitButton') as HTMLButtonElement;
    save.disabled = true;
    if (this.startHour == null || this.startHour == undefined){
      this.notify.warn('Informe o horário inicial.');
      save.disabled = false;
      return;
    }

    if (this.endHour == null || this.endHour == undefined){
      this.notify.warn('Informe o horário final.');
      save.disabled = false;
      return;
    }

    if (this.startHour > this.endHour){
      this.notify.warn(this.l('Horário inicial não pode ser maior que o final.'));
      save.disabled = false;
      return;
    }

    let agendamentoDeployDto = new AgendamentoDeployDto;
    agendamentoDeployDto.diaDaSemana = Number(this.dayWeek);
    agendamentoDeployDto.horaInicial = moment(this.startHour);
    agendamentoDeployDto.horaFinal = moment(this.endHour);
    agendamentoDeployDto.id = this.scheduleId;

    this._configDeployAutomaticoService.insertUpdateAgendamento(agendamentoDeployDto)
        .pipe(finalize(() => save.disabled = false))
        .subscribe(x => { 
            this.notify.success(this.l('SavedSuccessfully'));
            this.cleanProperty();
            this.close();
            save.disabled = false;
        });  
  }

  cleanProperty() {
    this.startHour = null;
    this.endHour = null;
    this.loading = false;
    this.scheduleId = '00000000-0000-0000-0000-000000000000';
  }
}
