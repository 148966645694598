<div>
    <div class="kt-content scheduling">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <div class="form-group">
                    <h6 class="mb-4">{{l('NewsFeed')}}</h6>
                    <div id="news-feed-notification-table" class="notification-table">
                        <div id="news-feed-notification" *ngFor="let notification of notificationList.slice(0, exibirQuantidadeInicial); let i = index">
                            <div class="notification-photo">
                                <img class="p-1" [src]="appRootUrl() + 'assets/common/images/qa-devops.png'" alt="logo1" height="30"/>
                            </div>
                            <div id="notification-content" class="notification-text">
                                <h4 id="notification-title" class="notification-title">{{notification.tituloNotificacao}}</h4>
                                <div [ngClass]="{'expanded': notification.expanded}">
                                    <p-editor [(ngModel)]="notification.conteudo" 
                                    [ngStyle]="{'height': notification.expanded ? 'auto' : '100px', 'border': 'none','display':'flex' }"
                                    [readonly]="true"
                                              class="custom-editor"></p-editor>
                                </div>
                                <div class="notification-buttons">
                                    <a class="notification-link-expand-content" (click)="toggleItem(notification)">{{ notification.expanded ? 'Ler menos' : 'Ler mais' }}</a>
                                    <button type="button" class="notification-like" id="like-button" *ngIf="notification.like" (click)="notificationLike(notification.id)">
                                        <i class="fas fa-thumbs-up fa-lg like-green"></i>
                                    </button>
                                    <button type="button" class="notification-like" id="like-button" *ngIf="!notification.like" (click)="notificationLike(notification.id)">
                                        <i class="far fa-thumbs-up fa-lg dislike"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!exibirTodos" id="notification-box-view-more">
                            <a (click)="carregarMaisItens()" id="notification-link-view-more">Ver mais</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
