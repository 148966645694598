import { Component, EventEmitter, Injector, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { NpsPerfilUsuarioServiceProxy } from "@shared/service-proxies/service-proxies";
@Component({
    selector: 'nps',
    templateUrl: './nps.component.html',
    styleUrls: ['./nps.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})



export class NpsComponent extends AppComponentBase implements OnInit {

    averageTenantNPS: string;
    averageUserNPS: string;

    @Output()  useAd: EventEmitter<void> = new EventEmitter<void>();


    constructor(
        injector: Injector,
        private _NpsPerfilUsuarioServiceProxy: NpsPerfilUsuarioServiceProxy,
        private _route: Router,
    ) {
        super(injector);
    };

    ngOnInit(): void {
        this.getNps();
    };

    getNps(): void {
        this._NpsPerfilUsuarioServiceProxy
        .getTenantAndUserNps(this.appSession.tenantId, this.appSession.userId).subscribe(result => {
            if(result.averageUserNPS == 0) this.averageUserNPS = '-'
            else  this.averageUserNPS = result.averageUserNPS.toString();

            if(result.averageTenantNPS == 0) this.averageTenantNPS = '-';
            else this.averageTenantNPS = result.averageTenantNPS.toString();
        });
    };

    AvaliacaoModal(): void {
        let param = 'avaliacao'
        this._route.navigate(['app/main/myProfile/' + param]);
    };

    getId(): string {
        if (this.averageUserNPS == "9" || this.averageUserNPS == "10") return 'user-average-high';
        else if (this.averageUserNPS >= "7") return 'user-average-meddium';
        else if (this.averageUserNPS < "7" && this.averageUserNPS != '-') return 'user-average-low';
        else return '';
    }
}