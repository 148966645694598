<div [@routerTransition]>

    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{"Languages" | localize}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{"LanguagesHeaderInfo" | localize}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button *ngIf="('Pages.Administration.Languages.Create' | permission) && multiTenancySideIsHost" (click)="createOrEditLanguageModal.show()" class="btn btn-primary"><i class="fa fa-plus"></i> {{"CreateNewLanguage" | localize}}</button>
            </div>
        </div>
    </div>

    <div class="kt-content">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div class="col-12 primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable
                                 (onLazyLoad)="getLanguages()"
                                 [value]="primengTableHelper.records"
                                 rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                 [paginator]="false"
                                 [lazy]="true"
                                 [responsive]="primengTableHelper.isResponsive">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 130px"
                                        [hidden]="!isGrantedAny('Pages.Administration.Languages.Edit', 'Pages.Administration.Languages.ChangeTexts', 'Pages.Administration.Languages.Delete')">
                                        {{'Actions' | localize}}
                                    </th>
                                    <th>
                                        {{'Name' | localize}}
                                    </th>
                                    <th>
                                        {{'Code' | localize}}
                                    </th>
                                    <th *ngIf="appSession.tenantId ? true : false">
                                        {{'Default' | localize}} *
                                    </th>
                                    <th>
                                        {{'CreationTime' | localize}}
                                    </th>
                                    <th>
                                        {{'IsEnabled' | localize}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td style="width: 130px; text-align: center">
                                        <div class="btn-group dropdown" dropdown container="body">
                                            <button dropdownToggle class="dropdown-toggle btn btn-sm btn-primary">
                                                <i class="fa fa-cog"></i><span class="caret"></span> {{"Actions" | localize}}
                                            </button>
                                            <ul class="dropdown-menu" *dropdownMenu>
                                                <li>
                                                    <a href="javascript:;" *ngIf="('Pages.Administration.Languages.Edit' | permission) && record.tenantId === appSession.tenantId"
                                                       (click)="createOrEditLanguageModal.show(defaultLanguageName === record.name, record.id)">{{'Edit' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="'Pages.Administration.Languages.ChangeTexts' | permission"
                                                       (click)="changeTexts(record)">{{'ChangeTexts' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="'Pages.Administration.Languages.Edit' | permission"
                                                       (click)="setAsDefaultLanguage(record)">{{'SetAsDefaultLanguage' | localize}}</a>
                                                </li> 
                                                <li *ngIf="defaultLanguageName != record.name">
                                                    <a href="javascript:;" *ngIf="('Pages.Administration.Languages.Delete' | permission) && record.tenantId === appSession.tenantId"
                                                       (click)="deleteLanguage(record)">{{'Delete' | localize}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        <span [ngClass]="{'text-bold' : defaultLanguageName === record.name}">
                                            <i [ngClass]="record.icon" class="margin-right-5 d-inline-block"></i>
                                            {{record.displayName}} <span *ngIf="defaultLanguageName === record.name">({{'Default' | localize}})</span>
                                        </span>
                                    </td>
                                    <td>
                                        {{record.name}}
                                    </td>
                                    <td *ngIf="appSession.tenantId ? true : false">
                                        <span class="kt-badge kt-badge--success kt-badge--inline"
                                              *ngIf="record.tenantId !== appSession.tenantId">
                                            {{'Yes' | localize}}
                                        </span>
                                        <span class="kt-badge kt-badge--dark kt-badge--inline"
                                              *ngIf="record.tenantId === appSession.tenantId">
                                            {{'No' | localize}}
                                        </span>
                                    </td>
                                    <td>
                                        {{record.creationTime | momentFormat:'L'}}
                                    </td>
                                    <td>
                                        <span class="kt-badge kt-badge--success kt-badge--inline"
                                              *ngIf="!record.isDisabled">
                                            {{'Yes' | localize}}
                                        </span>
                                        <span class="kt-badge kt-badge--dark kt-badge--inline"
                                              *ngIf="record.isDisabled">
                                            {{'No' | localize}}
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{'NoData' | localize}}
                        </div>
                        <div class="ui-table-footer">
                            {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->

                    <p class="col-12 mt-2" *ngIf="appSession.tenantId">
                        * {{"CanNotEditOrDeleteDefaultLanguages" | localize}}
                    </p>

                </div>
            </div>
        </div>
    </div>

    <createOrEditLanguageModal #createOrEditLanguageModal (modalSave)="getLanguages()"></createOrEditLanguageModal>
</div>
