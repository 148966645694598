import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { NotificacaoPortalDto, NotificacaoPortalServiceProxy } from "@shared/service-proxies/service-proxies";
import { CreateNotificacaoModalComponent } from "./create-notificacao-modal/create-notificacao-modal.component";

@Component({
    selector: 'notificacao-portal',
    templateUrl: './notificacao-portal.component.html',
    styleUrls: ['./notificacao-portal.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class NotificacaoPortalComponent extends AppComponentBase implements OnInit {
    @ViewChild('createNotificacaoModal', { static: true }) createNotificacaoModal: CreateNotificacaoModalComponent;

    loading: boolean = false;

    notificacaoList: NotificacaoPortalDto[] = [];

    showNotification : boolean = false;

    constructor(
        injector: Injector,
        private _notificacaoPortalServiceProxy: NotificacaoPortalServiceProxy
    ) {
        super(injector);
    }
    
    ngOnInit(): void {
        this.getNotificacaoList();
    }

    createNotification(){
        this.createNotificacaoModal.show();
    }

    getNotificacaoList(){
        this.loading = true;
        this._notificacaoPortalServiceProxy.getNotificacoes()
            .subscribe(res =>{
                this.loading = false;
                this.notificacaoList = res;
            })
    }

    deleteNotificacao(i: number, notificacaoDto : NotificacaoPortalDto){
        this._notificacaoPortalServiceProxy.removerNotificacao(notificacaoDto.id)
            .subscribe(() => {
                this.notificacaoList.splice(i,1);
                this.getNotificacaoList();
                this.notify.success(this.l('SuccessfullyRemoved'));
            });
    }

    reloadPage(ev: any) {
        this.getNotificacaoList();
    }

    getLocaleDateHourNotificacao(dataNotificacao: moment.Moment) {
        const dateString = dataNotificacao ? dataNotificacao.toString() : "";
        return new Date(dateString).toLocaleString();
    }
}