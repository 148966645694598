<div [@routerTransition]>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("ListView")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <button *ngIf="isGranted('Pages.Demandas.Create')" routerLink="/app/main/demandas/kanban" class="btn btn-outline-success">
                <i class="fa fa-list"></i>{{l("KanbanView")}}
            </button>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button (click)="exportToExcel()" class="btn btn-outline-success btn-excel-demanda">
                    <i class="fa fa-file-excel"></i>{{l("ExportToExcel")}}
                </button>
                <button *ngIf="isGranted('Pages.Demandas.Create')" (click)="createDemanda()"
                    class="btn btn-primary blue"><i class="fa fa-plus"></i> {{l("CreateNewDemanda")}}
                </button>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body box-card">
                <form class="kt-form" autocomplete="off">
                    <div>
                        <div class="row align-items-center">
                            <div class="col-xl-12" style="height: 35px;">
                                <div class="form-group m-form__group align-items-center">
                                    <div class="input-group">
                                        <input [(ngModel)]="filterText" name="filterText" autoFocus
                                            class="form-control m-input" [placeholder]="l('TitleDescriptionSearch')" type="text">
                                        <span class="input-group-btn">                                            
                                            <button (click)="getDemandas()" class="btn btn-primary" type="submit">
                                                <i class="flaticon-search-1"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4 bg-white pl-3 pr-3 m-0" [hidden]="!advancedFiltersAreShown">
                            <div class="col-md-12">
                                <div class="kt-separator kt-separator--border-dashed"></div>
                            </div>

                            <div class="col-md-3">
                                <label for="TipoFilterSelect">{{l("Tipo")}}</label>
                                <select class="form-control" name="TipoFilter" id="TipoFilterSelect" [(ngModel)]="tipoFilter">
                                    <option value="-1">{{l("All")}}</option>
                                    <option value="0">{{l('Projeto')}}</option>
                                    <option value="1">{{l('Suporte')}}</option>
                                    <option value="2">{{l('Configuracao')}}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label for="ModuloFilter">{{l("Descricao")}}</label>
                                <input type="text" class="form-control" id="descricaoFilter" name="descricaoFilter"
                                    [(ngModel)]="descricaoFilter">
                            </div>
                         
                            <div class="col-md-3">
                                <label for="ReferenciasFilter">{{l("Referencias")}}</label>
                                <input type="text" class="form-control" id="ReferenciasFilter" name="referenciasFilter"
                                    [(ngModel)]="referenciasFilter">
                            </div>
                           
                            <div class="col-md-3">
                                <label for="StatusFilterSelect">{{l("Status")}}</label>
                                <select class="form-control" name="StatusFilter" id="StatusFilterSelect"
                                    [(ngModel)]="statusFilter">
                                    <option value="-1">{{l("All")}}</option>
                                    <option value="0">{{l('Backlog')}}</option>
                                    <option value="1">{{l('Desenvolvimento')}}</option>
                                    <option value="2">{{l('Homologacao')}}</option>
                                    <option value="3">{{l('Producao')}}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="UserNameFilter">{{l("IdDaDemanda")}}</label>
                                    <input type="text" class="form-control" id="DemandaIdFilter" name="demandaIdFilter"
                                        [(ngModel)]="demandaIdFilter" mask="99999999">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="MinPrevisaoTerminoHomologFilter">{{l("PrevisaoTerminoHomologRange")}}</label>
                                    <div class="input-group">
                                        <input class="form-control m-input" id="MinPrevisaoTerminoHomologFilter"
                                            name="MinPrevisaoTerminoHomologFilter" type="datetime" bsDatepicker
                                            placeholder="{{l('MinValue')}}" [(ngModel)]="minPrevisaoTerminoHomologFilter">
                                        <span style="line-height: 40px;padding: 0 15px 0 15px;"> &mdash; </span>
                                        <input class="form-control m-input" id="MaxPrevisaoTerminoHomologFilter"
                                            name="MaTIpoxPrevisaoTerminoHomologFilter" type="datetime" bsDatepicker
                                            placeholder="{{l('MaxValue')}}" [(ngModel)]="maxPrevisaoTerminoHomologFilter">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="UserNameFilter">{{l("Envolvidos")}}</label>
                                    <ng-select [items]="users" [multiple]="true" [(ngModel)]="usersSelected" name="usersSelected">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div class="p-2">
                                                <span class="ng-value-icon left" style="vertical-align: middle;"
                                                    (click)="clear(item)" aria-hidden="true">×
                                                </span>
                                                <span class="ng-value-label ng-star-inserted">
                                                    <b>{{item.label}}</b>
                                                    <div style="font-size: 12px;">{{item.cargo}}</div>
                                                    <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                                </span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                            <div class="p-2">
                                                <b>{{item.label}}</b>
                                                <div style="font-size: 12px;">{{item.cargo}}</div>
                                                <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="change-number">{{l("ChangeNumber")}}</label>
                                    <input type="text" class="form-control" id="change-number" name="ChangeNumber"
                                        [(ngModel)]="numeroGestaoMudanca">
                                </div>
                            </div>
                          
                        </div>
                        <div class="row mt-4 mb-4">
                            <div class="col-sm-12 mb-2 col-md-6 pt-1">
                                <span class="clickable-item text-muted" *ngIf="!advancedFiltersAreShown"
                                    (click)="advancedFiltersAreShown =! advancedFiltersAreShown">
                                    <i class="fa fa-angle-down"></i> {{l("ShowAdvancedFilters")}}
                                </span>
                                <span class="clickable-item text-muted" *ngIf="advancedFiltersAreShown"
                                    (click)="advancedFiltersAreShown =! advancedFiltersAreShown">
                                    <i class="fa fa-angle-up"></i> {{l("HideAdvancedFilters")}}
                                </span>
                            </div>
                            <div class="col-sm-12 col-md-6 text-right">
                                <button (click)="resetarFiltros()" name="clean" class="btn btn-secondary">
                                    {{'Reset' | localize}}
                                </button>    
                                <button (click)="getDemandas()" name="RefreshButton" class="btn btn-primary">
                                    <i class="fa fa-sync"></i> {{'Refresh' | localize}}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row align-items-center">
                    <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable (onLazyLoad)="getDemandas($event)" [value]="primengTableHelper.records"
                            rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false" [lazy]="true"
                            [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                            [resizableColumns]="primengTableHelper.resizableColumns">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 130px"
                                        [hidden]="!isGrantedAny('Pages.Demandas.Edit', 'Pages.Demandas.Delete')">
                                        {{l('Actions')}}
                                    </th>
                                    <th style="width:10px">
                                        {{'Demanda'}}
                                    </th>
                                    <th style="width: 150px" pSortableColumn="usuarioResponsavelFk.name">
                                        {{l('Responsavel')}}
                                        <p-sortIcon field="userName"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="titulo">
                                        {{l('Titulo')}}
                                        <p-sortIcon field="demanda.titulo"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="tipo">
                                        {{l('Tipo')}}
                                        <p-sortIcon field="demanda.tipo"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="previsaoTerminoHomolog">
                                        {{l('PrevisaoTerminoHomolog')}}
                                        <p-sortIcon field="demanda.previsaoTerminoHomolog"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px">
                                        {{l('Arquivada')}}
                                    </th>                                    
                                    <th style="width: 150px" pSortableColumn="status">
                                        {{l('Status')}}
                                        <p-sortIcon field="demanda.status"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td style="width: 130px" [hidden]="!isGrantedAny('Pages.Demandas.Edit', 'Pages.Demandas.Delete')">
                                        <div class="btn-group dropdown" dropdown container="body">
                                            <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle>
                                                <i class="fa fa-cog"></i><span class="caret"></span> {{l("Actions")}}
                                            </button>
                                            <ul class="dropdown-menu" *dropdownMenu>
                                                <li>
                                                    <a href="javascript:;" *ngIf="permission.isGranted('Pages.Demandas.Edit')"
                                                        (click)="createOrEditDemandaModal.show(record.demanda.id)">
                                                        {{l('Edit')}}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" (click)="archiveDemanda(record.demanda.id)"
                                                        *ngIf="permission.isGranted('Pages.Demandas.Archive') && allowedOps(record.demanda).includes('arquivar')">
                                                        {{ l('Archive') }}
                                                    </a>

                                                    <a href="javascript:;" (click)="unachiveDemanda(record.demanda.id)"
                                                        *ngIf="permission.isGranted('Pages.Demandas.Archive') && allowedOps(record.demanda).includes('des')">
                                                        {{l('Unarchive') }}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" (click)="deleteDemanda(record.demanda)"
                                                        *ngIf="permission.isGranted('Pages.Demandas.Delete') && allowedOps(record.demanda).includes('excluir')">
                                                        {{l('Delete')}}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="entityHistoryEnabled" (click)="showHistory(record.demanda)">
                                                        {{l('History')}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td style="width:10px">
                                        {{record.demanda.demandaSapId}}
                                    </td>                               
                                    <td style="width:150px">
                                        {{record.userName}}
                                    </td>
                                    <td style="width:150px">
                                        {{record.demanda.titulo}}
                                    </td>
                                    <td style="width:150px">
                                        {{l( enumTipoDemanda[record.demanda.tipo])}}
                                    </td>
                                    <td style="width:150px">
                                        <span *ngIf="record.demanda.previsaoTerminoHomolog">
                                            {{record.demanda.previsaoTerminoHomolog | momentFormat:'L'}}
                                        </span>
                                        <span *ngIf="!record.demanda.previsaoTerminoHomolog">-</span>
                                    </td>
                                    <td style="width:150px">
                                        <span class="kt-badge kt-badge--success kt-badge--inline" *ngIf="record.demanda.arquivado">{{'Yes' | localize}}</span>
                                        <span class="kt-badge kt-badge--dark kt-badge--inline" *ngIf="!record.demanda.arquivado">{{'No' | localize}}</span>                                        
                                    </td>
                                    <td style="width:150px">
                                        {{l( enumStatusDemanda[record.demanda.status])}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{l('NoData')}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator rows="{{primengTableHelper.defaultRecordsCountPerPage}}" #paginator
                                (onPageChange)="getDemandas($event)"
                                [totalRecords]="primengTableHelper.totalRecordsCount"
                                [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <createOrEditDemandaModal #createOrEditDemandaModal (modalSave)="getDemandas()"></createOrEditDemandaModal>
    <entityTypeHistoryModal #entityTypeHistoryModal></entityTypeHistoryModal>
</div>