<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{l("Details")}}</span>
                </h4>
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-6 mb-3">
                        <h6>{{l("Name")}} :</h6>
                        {{ item.modulo.nome }}
                    </div>
                    <div class="col-6 mb-3">
                        <h6>{{l("Descricao")}} :</h6>
                        {{ item.modulo.descricao }}
                    </div>


                </div>

            </div>
            <div class="modal-footer">
                <button [disabled]="saving" type="button" class="btn btn-primary blue" (click)="close()">{{l("Close")}}</button>
            </div>
        </div>
    </div>

</div>
