import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ChangeRequestFilesDto, ChangeRequestForApprovalDto, ChangeRequestServiceProxy, EnumRdmRiskLevel, EnumRdmTypeOfChange, RdmAttachedDocsDto, RdmClassificationDto, RdmUnavailabilityDto } from "@shared/service-proxies/service-proxies";
import { FileDownloadService } from "@shared/utils/file-download.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { JustificationModalComponent } from "./justificationOfTheAction/justification-modal.component";

interface EventItem { status?: string; icon?: string; }

@Component({
    selector: 'approval-change-request-modal',
    templateUrl: './approval-change-request-modal.component.html',
    styleUrls: ['./approval-change-request-modal.component.less']
})

export class ApprovalOrDisapproveChangeRequestModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('approvalOrDisapproveChangeRequestModal', { static: true }) approvalOrDisapproveChangeRequestModal: ModalDirective;
    @ViewChild('justificationModal', { static: true }) justificationModal: JustificationModalComponent;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() saved: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();
    
    loading: boolean = false;
    statusChangeRequest: EventItem[] = [];
    statusApproval: EventItem[] = [];
    changeRequestForApprovalDto: ChangeRequestForApprovalDto = new ChangeRequestForApprovalDto;
    unavailabilityEndDate: Date = new Date;
    unavailabilityInitialDate: Date = new Date;
    hoursDifference: string;
    rdmClassificationType: RdmClassificationDto[] = [];
    rdmClassificationTypeSelected: RdmClassificationDto[] = [];
    rdmUnavailabilityType: RdmUnavailabilityDto[] = [];
    rdmUnavailabilityTypeSelected: RdmUnavailabilityDto[] = [];
    rdmAttachedDocsType: RdmAttachedDocsDto[] = [];
    rdmAttachedDocsTypeSelected: RdmAttachedDocsDto[] = [];

    ngOnInit(): void {
        this.setStatusItems();
        this.selectRdmClassificationType();
        this.selectRdmUnavailabilityType();
        this.selectRdmAttachedDocsType();
    }

    constructor(
        injector: Injector,
        private _fileDownloadService: FileDownloadService,
        private _changeRequestAppService: ChangeRequestServiceProxy,
    ) {
        super(injector);
    }    
    
    async show(changeRequestForApprovalDto: ChangeRequestForApprovalDto) {
        this.rdmClassificationTypeSelected = [];
        this.rdmUnavailabilityTypeSelected = [];
        this.rdmAttachedDocsTypeSelected = [];

        this.changeRequestForApprovalDto = changeRequestForApprovalDto;
        this.unavailabilityInitialDate = changeRequestForApprovalDto.unavailabilityInitialDate.toDate();
        this.unavailabilityEndDate = changeRequestForApprovalDto.unavailabilityEndDate.toDate();

        this._changeRequestAppService.getSelectedClassificationTypes(changeRequestForApprovalDto.id)
            .subscribe(x => { this.rdmClassificationTypeSelected = x; });   

        this._changeRequestAppService.getSelectedUnavailabilityTypes(changeRequestForApprovalDto.id)
            .subscribe(x => { this.rdmUnavailabilityTypeSelected = x; });               

        this._changeRequestAppService.getSelectedAttachedDocsTypes(changeRequestForApprovalDto.id)
            .subscribe(x => { this.rdmAttachedDocsTypeSelected = x; }); 

        this.calculateHours();
        this.approvalOrDisapproveChangeRequestModal.show();
    }
    
    close(): void {
        this.approvalOrDisapproveChangeRequestModal.hide();
        this.closed.emit();
    }

    setColorsBackgroundStatus(status: string) {
        switch (status) {
            case 'Approved':
                return 'background-color: #299F00;';
            case 'UnderAnalysis':
                return 'background-color: #ffa500;';
            case 'Rejected':
                return 'background-color: #FF6464;';
        }
    }

    setColorsBackgroundStatusApproval(status: string) {
        switch (status) {
            case 'Aprovado':
                return 'background-color: #299F00;';
            case 'Aprovado automaticamente':
                return 'background-color: #299F00;';
            case 'Em análise':
                return 'background-color: #ffa500;';
            case 'Reprovado':
                return 'background-color: #FF6464;';
        }
    }

    setStatusItems() { 
        this.statusChangeRequest = [
            { status: 'Approved', icon: 'fa fa-solid fa-check' },
            { status: 'UnderAnalysis', icon: 'fa fa-solid fa-hourglass-start' },
            { status: 'Rejected', icon: 'fa fa-solid fa-times' },
        ];

        this.statusApproval = [
            { status: 'Aprovado', icon: 'fa fa-solid fa-check' },
            { status: 'Aprovado automaticamente', icon: 'fa fa-solid fa-user-check' },
            { status: 'Em análise', icon: 'fa fa-solid fa-hourglass-start' },
            { status: 'Reprovado', icon: 'fa fa-solid fa-times' }
        ];
    }

    searchForStatusTranslation (status: string) {
        switch (status) {
            case 'UnderAnalysis':
                return "Em análise";  
            case 'Approved':
                return "Aprovado";
            case 'Rejected':
                return "Reprovado";      
            default:
                break;
        }
    }

    downloadAnexo(anexo: ChangeRequestFilesDto) {
        this._fileDownloadService.downloadBinaryFile(
            anexo.fileStorageId,
            anexo.fileType,
            anexo.attachmentName,
            this.appSession.tenantId
        );
    }

    getEnumChangeType(typeOfChange: EnumRdmTypeOfChange) {
        switch (typeOfChange) {
            case EnumRdmTypeOfChange.Standard:
                return "Padrão";
            case EnumRdmTypeOfChange.Normal:
                return "Normal";         
            case EnumRdmTypeOfChange.Emergency:
                return "Emergencial";
            default:
                return "";
        }
    }

    getEnumRiskLevel(riskLevel: EnumRdmRiskLevel) {
        switch (riskLevel) {
            case EnumRdmRiskLevel.Low:
                return "Baixo";
            case EnumRdmRiskLevel.Average:
                return "Médio";         
            case EnumRdmRiskLevel.High:
                return "Alto";
            case EnumRdmRiskLevel.Critical:
                return "Crítico";
            default:
                return "";
        }
    }

    selectRdmClassificationType(): void {
        this.rdmClassificationTypeSelected = [];
        this._changeRequestAppService.getClassificationTypes()
            .subscribe(x => { this.rdmClassificationType = x; });
    }

    selectRdmUnavailabilityType(): void {
        this.rdmUnavailabilityTypeSelected = [];
        this._changeRequestAppService.getUnavailabilityTypes()
            .subscribe(x => { this.rdmUnavailabilityType = x; });
    }

    selectRdmAttachedDocsType(): void {
        this.rdmAttachedDocsTypeSelected = [];
        this._changeRequestAppService.getAttachedDocsTypes()
            .subscribe(x => { this.rdmAttachedDocsType = x; });
    }

    openModalJustify(approval: boolean, reapproval: boolean) {
        this.justificationModal.show(approval, reapproval, this.changeRequestForApprovalDto);
    }

    calculateHours() {
        if (this.unavailabilityInitialDate && this.unavailabilityEndDate) {
            const diff = this.unavailabilityEndDate.getTime() - this.unavailabilityInitialDate.getTime();
            const totalHours = Math.floor(diff / (1000 * 60 * 60)); 
            const totalMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)); 
    
            this.hoursDifference = `${totalHours}h ${totalMinutes}m`;
        } else {
            this.hoursDifference = null;
        }
    }
}