import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AlteracaoDeStatusDaDemandaDto, GestaoMudancaServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-manual-change-modal',
  templateUrl: './create-manual-change-modal.component.html',
  styleUrls: ['./create-manual-change-modal.component.css']
})
export class CreateManualChangeModalComponent extends AppComponentBase implements OnInit {

  @ViewChild('changeManagerModal', { static: true }) modal: ModalDirective;
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  saving: boolean = false;
  loading: boolean = false;
  changeNumber: string;
  demandaId: string;
  alteracaoDeStatusDaDemandaDto: AlteracaoDeStatusDaDemandaDto;

  constructor(injector: Injector, private _service: GestaoMudancaServiceProxy) {
    super(injector);
  }

  ngOnInit() {
  }

  close(): void {
    this.modal.hide();
    this.closed.emit();
  }

  async show(demandaId: string, alteracaoDeStatusDaDemandaDto: AlteracaoDeStatusDaDemandaDto) {
    this.demandaId = demandaId;
    this.alteracaoDeStatusDaDemandaDto = alteracaoDeStatusDaDemandaDto;
    this.modal.show();
  }

  save() {
    this.loading = true;
    this._service.insertManualChangeManager(this.changeNumber, this.demandaId)
    .pipe(finalize(() => this.loading = false))
    .subscribe(() => {
      this.saved.emit(this.alteracaoDeStatusDaDemandaDto);
      this.close()
    });
  }

}
