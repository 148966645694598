import { Component, Injector, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FornecedoresServiceProxy, FornecedorDto  } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { TokenAuthServiceProxy } from '@shared/service-proxies/service-proxies';
import { CreateOrEditFornecedorModalComponent } from './create-or-edit-fornecedor-modal/create-or-edit-fornecedor-modal.component';
import { ViewFornecedorModalComponent } from './view-fornecedor-modal/view-fornecedor-modal.component';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';
import { LazyLoadEvent } from 'primeng/api';
import { FileDownloadService } from '@shared/utils/file-download.service';
import * as _ from 'lodash';
import { EntityTypeHistoryModalComponent } from '@app/shared/common/entityHistory/entity-type-history-modal.component';
import { NotifyService } from 'abp-ng2-module';

@Component({
    templateUrl: './fornecedores.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class FornecedoresComponent extends AppComponentBase {

    @ViewChild('createOrEditFornecedorModal', { static: true }) createOrEditFornecedorModal: CreateOrEditFornecedorModalComponent;
    @ViewChild('entityTypeHistoryModal', { static: true }) entityTypeHistoryModal: EntityTypeHistoryModalComponent;
    @ViewChild('viewFornecedorModalComponent', { static: true }) viewFornecedorModal: ViewFornecedorModalComponent;
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    advancedFiltersAreShown = false;
    filterText = '';
    nomeFilter = '';
    cnpjFilter = '';
    cepFilter = '';
    estadoFilter = '';
    ativoFilter = -1;


    entityHistoryEnabled = true
    _entityTypeFullName = 'QAMetrik.Fornecedores.Fornecedor';


    constructor(
        injector: Injector,
        private _fornecedoresServiceProxy: FornecedoresServiceProxy,
        private _notifyService: NotifyService,
        private _tokenAuth: TokenAuthServiceProxy,
        private _activatedRoute: ActivatedRoute,
        private _fileDownloadService: FileDownloadService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.entityHistoryEnabled = this.setIsEntityHistoryEnabled();
    }

    showHistory(fornecedor: FornecedorDto): void {
        this.entityTypeHistoryModal.show({
            entityId: fornecedor.id.toString(),
            entityTypeFullName: this._entityTypeFullName,
            entityTypeDescription: fornecedor.nome
        });
    }

    private setIsEntityHistoryEnabled(): boolean {
        let customSettings = (abp as any).custom;
        return customSettings.EntityHistory && customSettings.EntityHistory.isEnabled && _.filter(customSettings.EntityHistory.enabledEntities, entityType => entityType === this._entityTypeFullName).length === 1;
    }    

    getFornecedores(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this._fornecedoresServiceProxy.getAll(
            this.filterText,
            this.nomeFilter,
            this.cnpjFilter,
            this.cepFilter,
            this.estadoFilter,
            this.ativoFilter,
            this.primengTableHelper.getSorting(this.dataTable),
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event)
        ).subscribe(result => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            this.primengTableHelper.records = result.items;
            this.primengTableHelper.hideLoadingIndicator();
        });
    }

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }

    createFornecedor(): void {
        this.createOrEditFornecedorModal.show();
    }

   
    deleteFornecedor(fornecedor: FornecedorDto): void {
        this._fornecedoresServiceProxy.getCanDelete(fornecedor.id).subscribe(res => {
            if(res)
            {
                this.message.error(this.l("ProviderHasClientsDeleteError"));
                return;
            }
        });
         this.message.confirm('',
            '',
            (isConfirmed) => {
                if (isConfirmed) {
                    this._fornecedoresServiceProxy.delete(fornecedor.id)
                        .subscribe(() => {
                            this.reloadPage();
                            this.notify.success(this.l('SuccessfullyDeleted'));
                        });
                }
            }
        ); 
    }

    exportToExcel(): void {
        this._fornecedoresServiceProxy.getFornecedoresToExcel(
        this.filterText,
            this.nomeFilter,
            this.cnpjFilter,
            this.cepFilter,
            this.estadoFilter,
            this.ativoFilter,
        )
        .subscribe(result => {
            this._fileDownloadService.downloadTempFile(result);
         });
    }
}
