import { Component, Injector, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';
import { AmbientesServiceProxy, AmbienteDto , EnumEstagios, GetAllAmbientesDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { TokenAuthServiceProxy } from '@shared/service-proxies/service-proxies';
import { CreateOrEditAmbienteModalComponent } from './create-or-edit-ambiente-modal/create-or-edit-ambiente-modal.component';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';
import { LazyLoadEvent } from 'primeng/api';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { EntityTypeHistoryModalComponent } from '@app/shared/common/entityHistory/entity-type-history-modal.component';
import { CriarIntegracaoModalComponent } from './criar-integracao-modal/criar-integracao-modal.component';
import * as _ from 'lodash';

@Component({
    templateUrl: './ambientes.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./ambientes.component.css'],
    animations: [appModuleAnimation()]
})
export class AmbientesComponent extends AppComponentBase implements OnInit {

    @ViewChild('createOrEditAmbienteModal', { static: true }) createOrEditAmbienteModal: CreateOrEditAmbienteModalComponent;
    @ViewChild('criarIntegracaoModal', { static: true }) criarIntegracaoModal: CriarIntegracaoModalComponent;
    @ViewChild('entityTypeHistoryModal', { static: true }) entityTypeHistoryModal: EntityTypeHistoryModalComponent;

    filterText = '';
    nomeFilter = '';
    maxServerFilter: number;
    maxServerFilterEmpty: number;
    minServerFilter: number;
    minServerFilterEmpty: number;
    estagioAtualFilter = -1;

    loading = true;

    ambientesDesenvolvimento: Array<AmbienteDto>;
    ambientesQualidade: Array<AmbienteDto>;
    ambientesProducao: Array<AmbienteDto>;

    idModal: string;

    _entityTypeFullName = 'QAMetrik.Ambientes.Ambiente';
    entityHistoryEnabled = false;

    get enumEstagios() {
        return EnumEstagios
    }

    constructor(
        injector: Injector,
        private _ambientesServiceProxy: AmbientesServiceProxy,
        private _fileDownloadService: FileDownloadService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.entityHistoryEnabled = this.setIsEntityHistoryEnabled();
        this.getAmbientes();
    }

    private setIsEntityHistoryEnabled(): boolean {
        let customSettings = (abp as any).custom;
        return customSettings.EntityHistory && customSettings.EntityHistory.isEnabled && _.filter(customSettings.EntityHistory.enabledEntities, entityType => entityType === this._entityTypeFullName).length === 1;
    }

    getAmbientes() {
        this._ambientesServiceProxy
        .getAll()
        .subscribe(result => {
            this.ambientesDesenvolvimento = result.ambientesDev;
            this.ambientesProducao = result.ambientesProd;
            this.ambientesQualidade = result.ambientesQa;
            
            this.loading = false;
        });
    }

    openModalEdit(id,nomeBranch) {
        this.createOrEditAmbienteModal.show(id,nomeBranch);
    }

    createAmbiente(): void {
        this.createOrEditAmbienteModal.show();
    }

    showHistory(idAmbiente: any): void {
        this.entityTypeHistoryModal.show({
            entityId: idAmbiente.idAmbiente.toString(),
            entityTypeFullName: this._entityTypeFullName,
            entityTypeDescription: idAmbiente.nomeAmbiente
        });
    }

    exportToExcel(): void {
        this._ambientesServiceProxy.getAmbientesToExcel(
        this.filterText,
            this.nomeFilter,
            this.maxServerFilter == null ? this.maxServerFilterEmpty: this.maxServerFilter,
            this.minServerFilter == null ? this.minServerFilterEmpty: this.minServerFilter,
            this.estagioAtualFilter,
        )
        .subscribe(result => {
            this._fileDownloadService.downloadTempFile(result);
         });
    }

    criarIntegracao() {
        this.criarIntegracaoModal.show();
    }
}
