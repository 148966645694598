import { Component, Input, OnInit } from "@angular/core";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-request",
    templateUrl: "./request.component.html",
    styleUrls: ["./request.component.less"],
})
export class RequestComponent implements OnInit {
    @Input() tenantId: number = 0;
    urlQuestion1: SafeUrl;
    currentDate: string = "";
    initDate180DaysAgo: string = "";

    constructor(private domSanitizer: DomSanitizer) {
        this.getDates();
    }

    ngOnInit() {
        this.urlQuestion1 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/fe20b88e-ed7b-4333-8f74-6dd9df609744?DataInicial=${this.initDate180DaysAgo}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
    }

    getDates() {
        var currentDate = new Date();
        var initDate = new Date();

        initDate.setDate(initDate.getDate() - 180);

        this.currentDate = currentDate.toISOString().slice(0, 10);
        this.initDate180DaysAgo = initDate.toISOString().slice(0, 10);
    }
}
