<div [@routerTransition]>

    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{"AuditLogs" | localize}}</span>
            </h3>
        </div>
    </div>

    <div class="kt-content">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <tabset>
                    <tab heading="{{'OperationLogs' | localize}}">
                        <div class="row mb-2">
                            <div class="col-sm-12 text-right">
                                <button type="button" class="btn btn-success" (click)="exportToExcelAuditLogs()"><i class="far fa-file-excel"></i> {{"ExportToExcel" | localize}}</button>
                                <button type="button" class="btn btn-primary" (click)="getAuditLogs()"><i class="fa fa-sync"></i> {{"Refresh" | localize}}</button>
                            </div>
                        </div>

                        <div class="kt-form">
                            <form #auditFilterForm="ngForm" (ngSubmit)="getAuditLogs()">
                                <div class="row align-items-center mb-2">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="StartEndRange">{{"DateRange" | localize}}</label>
                                            <input class="form-control"
                                                   id="StartEndRange"
                                                   name="StartEndRange"
                                                   #drp="bsDaterangepicker"
                                                   readonly="readonly"
                                                   bsDaterangepicker
                                                   dateRangePickerInitialValue
                                                   [(ngModel)]="dateRange">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="UsernameAuditLog">{{"UserName" | localize}}</label>
                                            <input id="UsernameAuditLog" type="text" name="UsernameAuditLog" class="form-control" [(ngModel)]="usernameAuditLog">
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="advancedFiltersAreShown" class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="ServiceName">{{"Service" | localize}}</label>
                                            <input id="ServiceName" name="ServiceName" type="text" class="form-control" [(ngModel)]="serviceName">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label style="display: block">{{"Duration" | localize}}</label>
                                        <div class="input-group" style="width: 250px !important;">
                                            <input type="text" name="MinExecutionDuration" only-number="" class="form-control" min="0" max="86400000" ng-minlength="0" ng-maxlength="8" [(ngModel)]="minExecutionDuration">
                                            <div class="input-group-append"><span class="input-group-text">---</span></div>
                                            <input type="text" name="MaxExecutionDuration" only-number="" class="form-control" min="0" max="86400000" ng-minlength="0" ng-maxlength="8" [(ngModel)]="maxExecutionDuration">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="MethodName">{{"Action" | localize}}</label>
                                            <input id="MethodName" type="text" name="MethodName" class="form-control" [(ngModel)]="methodName">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="HasException">{{"ErrorState" | localize}}</label>
                                            <select id="HasException" name="HasException" class="form-control" [(ngModel)]="hasException">
                                                <option value="">{{"All" | localize}}</option>
                                                <option value="false">{{"Success" | localize}}</option>
                                                <option value="true">{{"HasError" | localize}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="BrowserInfo">{{"Browser" | localize}}</label>
                                            <input id="BrowserInfo" type="text" name="BrowserInfo" class="form-control" [(ngModel)]="browserInfo">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-6">
                                        <span class="clickable-item text-muted" *ngIf="!advancedFiltersAreShown" (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i class="fa fa-angle-down"></i> {{"ShowAdvancedFilters" | localize}}</span>
                                        <span class="clickable-item text-muted" *ngIf="advancedFiltersAreShown" (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i class="fa fa-angle-up"></i> {{"HideAdvancedFilters" | localize}}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="row align-items-center">
                            <!--<Primeng-TurboTable-Start>-->
                            <div class="primeng-datatable-container" [busyIf]="primengTableHelperAuditLogs.isLoading">
                                <p-table #dataTableAuditLogs
                                         (onLazyLoad)="getAuditLogs($event)"
                                         [value]="primengTableHelperAuditLogs.records"
                                         rows="{{primengTableHelperAuditLogs.defaultRecordsCountPerPage}}"
                                         [paginator]="false"
                                         [lazy]="true"
                                         scrollable="true"
                                         ScrollWidth="100%"
                                         responsive="primengTableHelperAuditLogs.isResponsive"
                                         resizableColumns="primengTableHelperAuditLogs.resizableColumns">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width:75px"></th>
                                            <th style="width:75px"></th>
                                            <th style="width: 150px" pSortableColumn="executionTime">
                                                {{'Time' | localize}}
                                                <p-sortIcon field="executionTime"></p-sortIcon>
                                            </th>
                                            <th style="width: 150px" pSortableColumn="userName">
                                                {{'UserName' | localize}}
                                                <p-sortIcon field="userName"></p-sortIcon>
                                            </th>
                                            <th style="width: 250px">
                                                {{'Service' | localize}}
                                            </th>
                                            <th style="width: 250px">
                                                {{'Action' | localize}}
                                            </th>
                                            <th style="width: 100px" pSortableColumn="executionDuration">
                                                {{'Duration' | localize}}
                                                <p-sortIcon field="executionDuration"></p-sortIcon>
                                            </th>
                                            <th style="width: 150px">
                                                {{'IpAddress' | localize}}
                                            </th>
                                            <th style="width: 150px">
                                                {{'Client' | localize}}
                                            </th>
                                            <th style="width: 150px">
                                                {{'Browser' | localize}}
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-record="$implicit">
                                        <tr>
                                            <td style="width: 75px">
                                                <div class="text-center">
                                                    <button class="btn btn-outline-primary btn-sm btn-icon" (click)="showAuditLogDetails(record)"><i class="la la-search" [attr.aria-label]="l('Search')"></i></button>
                                                </div>
                                            </td>
                                            <td style="width: 75px">
                                                <div class="text-center">
                                                    <i *ngIf="record.exception" class="fa fa-exclamation-triangle kt-font-warning"></i>
                                                    <i *ngIf="!record.exception" class="fa fa-check-circle kt-font-success"></i>
                                                </div>
                                            </td>
                                            <td style="width: 150px">
                                                {{ record.executionTime | momentFormat:'YYYY-MM-DD HH:mm:ss'}}
                                            </td>
                                            <td style="width: 150px">
                                                {{record.userName}}
                                            </td>
                                            <td style="width: 250px">
                                                {{record.serviceName}}
                                            </td>
                                            <td style="width: 250px">
                                                {{record.methodName}}
                                            </td>
                                            <td style="width: 100px">
                                                {{record.executionDuration}}
                                            </td>
                                            <td style="width: 150px">
                                                {{record.clientIpAddress}}
                                            </td>
                                            <td style="width: 150px">
                                                {{record.clientName}}
                                            </td>
                                            <td style="width: 150px">
                                                <span title="{{record.browserInfo}}">{{truncateStringWithPostfix(record.browserInfo, 20)}}</span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div class="primeng-no-data" *ngIf="primengTableHelperAuditLogs.totalRecordsCount == 0">
                                    {{'NoData' | localize}}
                                </div>
                                <div class="primeng-paging-container">
                                    <p-paginator [rows]="primengTableHelperAuditLogs.defaultRecordsCountPerPage"
                                                 #paginatorAuditLogs
                                                 (onPageChange)="getAuditLogs($event)"
                                                 [totalRecords]="primengTableHelperAuditLogs.totalRecordsCount"
                                                 [rowsPerPageOptions]="primengTableHelperAuditLogs.predefinedRecordsCountPerPage">
                                    </p-paginator>
                                    <span class="total-records-count">
                                        {{'TotalRecordsCount' | localize:primengTableHelperAuditLogs.totalRecordsCount}}
                                    </span>
                                </div>
                            </div>
                            <!--<Primeng-TurboTable-End>-->
                        </div>
                    </tab>
                    <tab heading="{{'ChangeLogs' | localize}}">
                        <div class="row mb-2">
                            <div class="col-sm-12 text-right">
                                <button type="button" class="btn btn-success" (click)="exportToExcelEntityChanges()"><i class="far fa-file-excel"></i> {{"ExportToExcel" | localize}}</button>
                                <button type="button" class="btn btn-primary" (click)="getEntityChanges()"><i class="fa fa-sync"></i> {{"Refresh" | localize}}</button>
                            </div>
                        </div>
                        <div class="kt-form">
                            <form #entityChangeFilterForm="ngForm" (ngSubmit)="getEntityChanges()">
                                <div class="row align-items-center mb-2">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="StartEndRangeEntityChange">{{"DateRange" | localize}}</label>
                                            <input class="form-control" id="StartEndRangeEntityChange" name="StartEndRangeEntityChange" readonly="readonly" #drp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="dateRange">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="UserNameEntityChange">{{"UserName" | localize}}</label>
                                            <input id="UserNameEntityChange" type="text" name="UserNameEntityChange" class="form-control" [(ngModel)]="usernameEntityChange">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="EntityTypeFullName">{{"Object" | localize}}</label>

                                            <select id="EntityTypeFullName" name="EntityTypeFullName" class="form-control" [(ngModel)]="entityTypeFullName">
                                                <option value=undefined selected="selected">{{"All" | localize}}</option>
                                                <option *ngFor="let objectType of objectTypes" [value]="objectType.value">{{objectType.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="row align-items-center">
                            <!--<Primeng-TurboTable-Start>-->
                            <div class="primeng-datatable-container" [busyIf]="primengTableHelperEntityChanges.isLoading">
                                <p-table #dataTableEntityChanges
                                         (onLazyLoad)="getEntityChanges($event)"
                                         [value]="primengTableHelperEntityChanges.records"
                                         rows="{{primengTableHelperEntityChanges.defaultRecordsCountPerPage}}"
                                         [paginator]="false"
                                         [lazy]="true"
                                         scrollable="true"
                                         ScrollWidth="100%"
                                         responsive="primengTableHelperEntityChanges.isResponsive"
                                         resizableColumns="primengTableHelperEntityChanges.resizableColumns">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 40px">
                                            </th>
                                            <th style="width: 150px">
                                                {{'Action' | localize}}
                                            </th>
                                            <th style="width: 250px">
                                                {{'Object' | localize}}
                                            </th>
                                            <th style="width: 150px" pSortableColumn="userName">
                                                {{'UserName' | localize}}
                                                <p-sortIcon field="userName"></p-sortIcon>
                                            </th>
                                            <th style="width: 150px" pSortableColumn="changeTime">
                                                {{'Time' | localize}}
                                                <p-sortIcon field="changeTime"></p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-record="$implicit">
                                        <tr>
                                            <td style="width: 40px">
                                                <div class="text-center">
                                                    <button class="btn btn-outline-primary btn-sm btn-icon" (click)="showEntityChangeDetails(record)"><i class="la la-search" [attr.aria-label]="l('Search')"></i></button>
                                                </div>
                                            </td>
                                            <td style="width: 150px">
                                                <span title="{{record.changeTypeName}}">{{l(record.changeTypeName)}}</span>
                                            </td>
                                            <td style="width: 250px">
                                                {{record.entityTypeFullName}}
                                            </td>
                                            <td style="width: 150px">
                                                {{record.userName}}
                                            </td>
                                            <td style="width: 150px">
                                                {{ record.changeTime | momentFormat:'YYYY-MM-DD HH:mm:ss'}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div class="primeng-no-data" *ngIf="primengTableHelperEntityChanges.totalRecordsCount == 0">
                                    {{'NoData' | localize}}
                                </div>
                                <div class="primeng-paging-container">
                                    <p-paginator [rows]="primengTableHelperEntityChanges.defaultRecordsCountPerPage"
                                                 #paginatorEntityChanges
                                                 (onPageChange)="getEntityChanges($event)"
                                                 [totalRecords]="primengTableHelperEntityChanges.totalRecordsCount"
                                                 [rowsPerPageOptions]="primengTableHelperEntityChanges.predefinedRecordsCountPerPage">
                                    </p-paginator>
                                    <span class="total-records-count">
                                        {{'TotalRecordsCount' | localize:primengTableHelperEntityChanges.totalRecordsCount}}
                                    </span>
                                </div>
                            </div>
                            <!--<Primeng-TurboTable-End>-->
                        </div>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>

    <auditLogDetailModal #auditLogDetailModal></auditLogDetailModal>
    <entityChangeDetailModal #entityChangeDetailModal></entityChangeDetailModal>
</div>
