<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
    [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{'PortalEvaluationTitle' | localize}}
                </h4>
                <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="genericSatisfactionSurvey" *ngIf="!isGerente">
                    <div class="question-recomendation">
                        <h5 class="title-survey">{{ 'QAMetrikRecommendation' | localize}}</h5>
                        <div class="d-flex justify-content-between my-3">
                            <div *ngFor="let item of starsGeneric" [class]="item.fill? 'star-yellow' : 'star-grey'"
                                (click)="selectGeneric(item.position)">
                                <svg height="25" width="23" class="clickable" data-rating="1">
                                    <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78"
                                        style="fill-rule:nonzero;" />
                                </svg>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>{{'Noway' | localize}}</p>
                            <p>{{'VeryHigh' | localize}}</p>
                        </div>
                    </div>

                    <form>
                        <div class="genericFormSurvey">
                            <label class="form-evaluation-title">Deixe aqui seus comentários e/ou sugestões de melhorias (Opcional)</label>
                            <textarea class="QAMetrik-form-evaluation" name="textarea" [(ngModel)]="observacaoText"  minlength="150"></textarea>
                        </div>
                    </form>
                </div>

                <div class="managerSatisfactionSurvey" *ngIf="isGerente">
                    <div class="question-recomendation">
                        <h5 class="title-survey">{{ 'QAMetrikRecommendation' | localize}}</h5>
                        <div class="d-flex justify-content-between my-3">
                            <div *ngFor="let item of starsRecomendation" [class]="item.fill? 'star-yellow': 'star-grey'"
                                (click)="selectRecomendation(item.position)">
                                <svg height="25" width="23" class="clickable" data-rating="1">
                                    <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78"
                                        style="fill-rule:nonzero;" />
                                </svg>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>{{'Noway' | localize}}</p>
                            <p>{{'VeryHigh' | localize}}</p>
                        </div>
                    </div>

                    <div class="question-service">
                        <h5 class="title-survey">{{ 'Qual seu grau de satisfação com o atendimento prestado pela QAMetrik?' | localize}}</h5>
                        <div class="d-flex justify-content-between my-3">
                            <div *ngFor="let item of starsService" [class]="item.fill? 'star-yellow': 'star-grey'"
                            (click)="selectService(item.position)">
                                <svg height="25" width="23" class="clickable" data-rating="1">
                                    <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78"
                                        style="fill-rule:nonzero;" />
                                </svg>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>{{'Totalmente insatisfeito' | localize}}</p>
                            <p>{{'Completamente satisfeito' | localize}}</p>
                        </div>
                    </div>

                    <div class="question-service-solution">
                        <h5 class="title-survey">{{ 'Qual o grau de atendimento das suas expectativas quanto aos resultados obtidos com a ferramenta?' | localize}}</h5>
                        <div class="d-flex justify-content-between my-3">
                            <div *ngFor="let item of starsServiceSolution" [class]="item.fill? 'star-yellow': 'star-grey'"
                            (click)="selectServiceSolution(item.position)">
                                <svg height="25" width="23" class="clickable" data-rating="1">
                                    <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78"
                                        style="fill-rule:nonzero;" />
                                </svg>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>{{'Muito ruins' | localize}}</p>
                            <p>{{'Muito bons' | localize}}</p>
                        </div>
                    </div>

                    <form>
                        <div class="managerFormSurvey">
                            <label class="form-evaluation-title">Deixe aqui seus comentários e/ou sugestões de melhorias (Opcional)</label>
                            <textarea class="QAMetrik-form-evaluation" name="textarea" [(ngModel)]="observacaoText" minlength="150"></textarea>
                        </div>
                    </form>
                </div>
            </div>

            <div class="modal-footer">
                <button [disabled]="canSave" type="button" class="btn btn-primary" (click)="save()">{{"Send" | localize}}</button>
            </div>
        </div>
    </div>
</div>