import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment/moment';

@Component({
  selector: 'app-custom-timepicker',
  templateUrl: './custom-timepicker.component.html',
  styleUrls: ['./custom-timepicker.component.css']
})
export class CustomTimepickerComponent implements OnInit {

  @Input() disabled: Boolean;
  @Input() set value(value: moment.Moment) {
    this.date = moment.isMoment(value)? value: moment(value);
  }
  @Output() valueChange: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();

  date: moment.Moment = moment().utc().set({hours: 0, minutes: 0});

  get minutes() {
    const minutes = this.date? this.date.minute(): 0;
    return this.formatNumber(minutes);
  }

  set minutes(value: string) {
    const minutes = parseInt(value) > 60? 60: parseInt(value)
    this.date.minute(minutes)
    this.emitValue();
  }

  get hours() {
    const hours = this.date? this.date.hour(): 0;
    return this.formatNumber(hours);
  }

  set hours(value: string) {
    const hours = parseInt(value) >= 24? 0: parseInt(value)
    this.date.hour(hours)
    this.emitValue();
  }

  constructor() { }

  ngOnInit() {
  }

  formatNumber(value: number) {
    return value < 10? '0' + value: value.toString()
  }

  variateMinute(variation) {
    this.date.add(variation*5, "minutes")
    this.emitValue();
  }

  variateHour(variation) {
    this.date.add(variation, "hours")
    this.emitValue();
  }

  emitValue() {
    this.valueChange.emit(this.date);
  }
}
