import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AvaliacaoAmbienteServiceProxy } from "@shared/service-proxies/service-proxies"
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-upload-dashboard-file',
  templateUrl: './upload-dashboard-file.component.html',
  styleUrls: ['./upload-dashboard-file.component.css']
})
export class UploadDashboardFileComponent extends AppComponentBase implements OnInit {

  file: File;
  uploading: boolean = false;

  get fileName(): string {
    let file = this.file || { name: "" }
    return file.name || this.l("NoData");
  }

  constructor(
    private _service: AvaliacaoAmbienteServiceProxy,
    injector: Injector
    ) {
      super(injector);
    }

  ngOnInit() {
  }

  upload(data: { files: File }) {
    this.file = data.files[0];
  }

  save() {
    const formData: FormData = new FormData();
    formData.append('file', this.file, this.file.name);
    this.uploading = true
    this._service.uploadFileAvaliacaoAmbiente({ data: this.file, fileName: this.file.name })
      .pipe(finalize(() => this.uploading = false))
      .subscribe(() => {
        this.clear();
        this.notify.success(this.l('DashboardFileUploading'));

      });
  }

  clear() {
    this.file = null;
  }

}
