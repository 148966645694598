<div [@routerTransition]>

    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{"LanguageTexts" | localize}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{"LanguageTextsHeaderInfo" | localize}}
            </span>
        </div>
    </div>

    <div class="kt-content">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <form autocomplete="new-password" class="kt-form" id="TextsFilterForm">
                    <div>
                        <div class="row align-items-center m--margin-bottom-10">
                            <div class="col-sm-6 col-md-3">
                                <div class="form-group">
                                    <label for="TextBaseLanguageSelectionCombobox">{{"BaseLanguage" | localize}}</label>
                                    <select #baseLanguageNameCombobox
                                            name="baseLanguageName"
                                            class="form-control bs-select"
                                            [(ngModel)]="baseLanguageName">
                                        <option *ngFor="let language of languages" [value]="language.name" [attr.data-icon]="language.icon">{{language.displayName}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <div class="form-group">
                                    <label for="TextTargetLanguageSelectionCombobox">{{"TargetLanguage" | localize}}</label>
                                    <select #targetLanguageNameCombobox
                                            name="targetLanguageName"
                                            class="form-control bs-select"
                                            [(ngModel)]="targetLanguageName">
                                        <option *ngFor="let language of languages" [value]="language.name" [attr.data-icon]="language.icon">{{language.displayName}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <div class="form-group">
                                    <label for="TextSourceSelectionCombobox">{{"Source" | localize}}</label>
                                    <select #sourceNameCombobox
                                            name="sourceName"
                                            class="form-control bs-select"
                                            [(ngModel)]="sourceName">
                                        <option *ngFor="let sourceName of sourceNames" [value]="sourceName">{{sourceName}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <div class="form-group">
                                    <label for="TargetValueFilterSelectionCombobox">{{"TargetValue" | localize}}</label>
                                    <select #targetValueFilterCombobox
                                            name="targetValueFilter"
                                            class="form-control bs-select"
                                            [(ngModel)]="targetValueFilter">
                                        <option value="ALL">{{"All" | localize}}</option>
                                        <option value="EMPTY">{{"EmptyOnes" | localize}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center mb-4">
                            <div class="col-sm-12">
                                <div class="input-group">
                                    <input [(ngModel)]="filterText" name="filterText" autoFocus class="form-control" placeholder="{{'SearchWithThreeDot' | localize}}" type="text">
                                    <span class="input-group-btn">
                                        <button (click)="applyFilters()" class="btn btn-primary" type="submit"><i class="icon-refresh"></i> {{"Refresh" | localize}}</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div class="col primeng-datatable-container"
                         [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable
                                 (onLazyLoad)="getLanguageTexts($event)"
                                 [value]="primengTableHelper.records"
                                 rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                 [paginator]="false"
                                 [lazy]="true"
                                 [responsive]="primengTableHelper.isResponsive">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="key">
                                        {{'Key' | localize}}
                                        <p-sortIcon field="key"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="baseValue">
                                        {{'BaseValue' | localize}}
                                        <p-sortIcon field="baseValue"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="targetValue">
                                        {{'TargetValue' | localize}}
                                        <p-sortIcon field="targetValue"></p-sortIcon>
                                    </th>
                                    <th style="width: 100px">{{'Edit' | localize}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td>
                                        <span title="{{record.key}}">{{truncateString(record.key)}}</span>
                                    </td>
                                    <td>
                                        <span title="{{record.baseValue}}">{{truncateString(record.baseValue)}}</span>
                                    </td>
                                    <td>
                                        <span title="{{record.targetValue}}">{{truncateString(record.targetValue) }}</span>
                                    </td>
                                    <td>
                                        <button class="btn btn-outline-primary btn-sm btn-icon"
                                                (click)="editTextModal.show(baseLanguageName,targetLanguageName,sourceName,record.key,record.baseValue,record.targetValue)"
                                                title="{{'Edit' | localize}}">
                                            <i class="fa fa-edit" [attr.aria-label]="l('Edit')"></i>
                                        </button>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{'NoData' | localize}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                         #paginator
                                         (onPageChange)="getLanguageTexts($event)"
                                         [totalRecords]="primengTableHelper.totalRecordsCount"
                                         [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </div>
            </div>
        </div>
    </div>

    <editTextModal #editTextModal (modalSave)="refreshTextValueFromModal()"></editTextModal>
</div>
