import { EnumSituacaoDemanda } from "./service-proxies/service-proxies";

export const EnumDemandSituationLabel = new Map<string, string>([
    [EnumSituacaoDemanda.SemStatus, 'Sem Status'],
    [EnumSituacaoDemanda.EmAnalise, 'Em Análise'],
    [EnumSituacaoDemanda.AguardandoLiberacaoDeAmbiente, 'Aguardando Liberação de Ambiente'],
    [EnumSituacaoDemanda.Aprovado, 'Aprovado'],
    [EnumSituacaoDemanda.Reprovado, 'Reprovado'],
    [EnumSituacaoDemanda.RiscoDeTransporte, 'Risco de Transporte'],
    [EnumSituacaoDemanda.CodeReview, 'Code Review'],
    [EnumSituacaoDemanda.AguardandoAvaliacaoQualidade, 'Aguardando Avaliação Qualidade'],
    [EnumSituacaoDemanda.AguardandoAprovacaoGMUD, 'Aguardando Aprovação GMUD'],
    [EnumSituacaoDemanda.GMUDReprovada, 'GMUD Reprovada'],
    [EnumSituacaoDemanda.AguardandoGestaoRisco, 'Aguardando Gestão de Risco'],
    [EnumSituacaoDemanda.AguardandoTransporte, 'Aguardando Transporte'],
    [EnumSituacaoDemanda.TransporteReprovado, 'Transporte Reprovado'],
    [EnumSituacaoDemanda.FalhaNoTransporte, 'Falha no Transporte'],
    [EnumSituacaoDemanda.TransporteEmAndamento, 'Transporte em Andamento'],
    [EnumSituacaoDemanda.FalhaStms, 'Falha Stms'],
    [EnumSituacaoDemanda.AguardandoRisco, 'Aguardando Risco'],
    [EnumSituacaoDemanda.FalhaNoProcesso, 'Falha no Processo']
]);