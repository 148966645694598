import { Injectable, Injector } from '@angular/core';
import { AppComponentBase } from './common/app-component-base';

@Injectable({
  providedIn: 'root'
})
export class ConvertDateService extends AppComponentBase {

  constructor(injector: Injector) {
    super(injector);
  }

  convert(date: any) {
    const dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
    const match = date._i.match(dateRegex);
  
    if (!match) {
      this.notify.warn('O formato da data está inválido.');
    }
  
    const [, year, month, day, hours, minutes] = match.map(Number);
    return new Date(year, month - 1, day, hours, minutes);
  }
}
