<div class="kt-subheader kt-grid__item">
    <div class="kt-subheader__main">
        <h3 class="kt-subheader__title">
            <span>{{l("Deploy")}}</span>
        </h3>
        <span class="kt-subheader__separator kt-subheader__separator--v"></span>
        <span class="kt-subheader__desc">
            {{l("Configurações de deploy")}}
        </span>
    </div>
</div>

<div>
    <tabs-configuration></tabs-configuration>
</div>


