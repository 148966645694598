<div class="kt-login__form" [@routerTransition]>
    <div class="kt-login__title">
        <h3>
            {{"SignUp" | localize}}
        </h3>

        <form #registerForm="ngForm" class="kt-form" method="post" novalidate (ngSubmit)="save()">
            <div class="kt-login__options" *ngIf="_loginService.externalLoginProviders.length > 0">
                <a *ngFor="let provider of _loginService.externalLoginProviders | slice:1"
                    class="btn btn-outline-secondary kt-btn btn-sm" (click)="externalLogin(provider)"
                    title="{{provider.name}}">
                    <i class="fab fa-{{provider.icon}}"></i>
                    {{provider.name}}
                </a>
            </div>
            <div class="form-group">
                <input #nameInput="ngModel" class="form-control" autoFocus type="text"
                    placeholder="{{'Name' | localize}} *" [(ngModel)]="model.name" name="Name" required
                    maxlength="32" />
                <validation-messages [formCtrl]="nameInput"></validation-messages>
            </div>

            <div class="form-group">
                <input #surnameInput="ngModel" class="form-control" type="text" placeholder="{{'Surname' | localize}} *"
                    [(ngModel)]="model.surname" name="Surname" required maxlength="32" />
                <validation-messages [formCtrl]="surnameInput"></validation-messages>
            </div>

            <div class="form-group">
                <input #emailAddressInput="ngModel" class="form-control" type="email"
                    placeholder="{{'EmailAddress' | localize}} *" [(ngModel)]="model.emailAddress" name="EmailAddress"
                    required maxlength="256" pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$" />
                <validation-messages [formCtrl]="emailAddressInput"></validation-messages>
            </div>

            <div class="form-group">
                <input #userNameInput="ngModel" class="form-control" type="text" autocomplete="new-password"
                    placeholder="{{'UserName' | localize}} *" name="UserName" [(ngModel)]="model.userName" required
                    maxlength="32" />
                <validation-messages [formCtrl]="userNameInput"></validation-messages>
            </div>

            <div class="form-group">
                <input type="password" name="Password" class="form-control" [(ngModel)]="model.password"
                    #Password="ngModel" placeholder="{{'Password' | localize}}" validateEqual="PasswordRepeat"
                    reverse="true" [requireDigit]="passwordComplexitySetting.requireDigit"
                    [requireLowercase]="passwordComplexitySetting.requireLowercase"
                    [requireUppercase]="passwordComplexitySetting.requireUppercase"
                    [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                    [requiredLength]="passwordComplexitySetting.requiredLength"
                    [notAllowSequencing]="passwordComplexitySetting.notAllowSequencingOfNumbersAndLetters" required>
            </div>

            <div [hidden]="registerForm.form.valid || registerForm.form.pristine" class="form-group">
                <ul class="form-text text-danger" *ngIf="Password.errors">
                    <li [hidden]="!Password.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}
                    </li>
                    <li [hidden]="!Password.errors.requireLowercase">
                        {{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                    <li [hidden]="!Password.errors.requireUppercase">
                        {{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                    <li [hidden]="!Password.errors.requireNonAlphanumeric">
                        {{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                    <li [hidden]="!Password.errors.requiredLength">
                        {{"PasswordComplexity_RequiredLength_Hint" | localize:passwordComplexitySetting.requiredLength}}
                    </li>
                    <li [hidden]="!Password.errors.notAllowSequencing">
                        {{"NotAllowSequencingOfNumbersAndLettersHint" | localize}}
                    </li>
                </ul>
            </div>

            <div class="form-group">
                <input type="password" name="PasswordRepeat" class="form-control" [ngModel]="model.passwordRepeat"
                    #PasswordRepeat="ngModel" placeholder="{{'PasswordRepeat' | localize}}" validateEqual="Password"
                    reverse="false" [requireDigit]="passwordComplexitySetting.requireDigit"
                    [requireLowercase]="passwordComplexitySetting.requireLowercase"
                    [requireUppercase]="passwordComplexitySetting.requireUppercase"
                    [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                    [requiredLength]="passwordComplexitySetting.requiredLength"
                    [notAllowSequencing]="passwordComplexitySetting.notAllowSequencingOfNumbersAndLetters" required>
            </div>

            <div [hidden]="registerForm.form.valid || registerForm.form.pristine" class="form-group">
                <ul class="form-text text-danger" *ngIf="PasswordRepeat.errors">
                    <li [hidden]="!PasswordRepeat.errors.requireDigit">
                        {{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                    <li [hidden]="!PasswordRepeat.errors.requireLowercase">
                        {{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                    <li [hidden]="!PasswordRepeat.errors.requireUppercase">
                        {{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                    <li [hidden]="!PasswordRepeat.errors.requireNonAlphanumeric">
                        {{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                    <li [hidden]="!PasswordRepeat.errors.requiredLength">
                        {{"PasswordComplexity_RequiredLength_Hint" | localize:passwordComplexitySetting.requiredLength}}
                    </li>
                    <li [hidden]="!Password.errors.notAllowSequencing">
                        {{"NotAllowSequencingOfNumbersAndLettersHint" | localize}}
                    </li>
                    <li [hidden]="PasswordRepeat.valid">{{"PasswordsDontMatch" | localize}}</li>
                </ul>
            </div>

            <p *ngIf="useCaptcha" class="mt-2 form-text text-muted">
                {{"Captcha_Hint" | localize}}
            </p>

            <div class="form-group mt-2 captcha-container">
                <div #recaptcha></div>
            </div>


            <div class="kt-login__actions">
                <button [disabled]="saving" routerLink="/account/login" type="button"
                    class="btn btn-light btn-elevate kt-login__btn-secondary"><i class="fa fa-arrow-left"></i>
                    {{"Back" | localize}}</button>
                <button type="submit" class="btn btn-primary btn-elevate kt-login__btn-primary"
                    [disabled]="!registerForm.form.valid" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i
                        class="fa fa-check"></i> {{"Submit" | localize}}</button>
            </div>
        </form>
    </div>
</div>