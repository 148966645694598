<div id="card">
    <div id="top">
        <div id="title">
            <span>{{l("Rework")}}</span>
        </div>
    
        <div id="icon">
            <i class="fa fa-info-circle" tooltip="{{'ReworkTooltip' | localize}}"></i>
        </div>
    </div>   

    <div id="bot">
        <h3 id="company-average">
            {{ rework }}
        </h3>
    </div>
</div>