import { Component, EventEmitter, Injector, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { CodeReviewServiceProxy, HistoricoCodeReviewAprovadorDto } from "@shared/service-proxies/service-proxies";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: 'code-review-history-modal',
    templateUrl: './code-review-history-modal.component.html',
    styleUrls: ['./code-review-history-modal.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class CodeReviewHistoryModalComponent extends AppComponentBase implements OnInit {

    @ViewChild('codeReviewHistoryModal', { static: true }) codeReviewHistoryModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() saved: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();

    listaHistoricoCodeReviewDto : HistoricoCodeReviewAprovadorDto[] = [];
    dataAvaliacao: moment.Moment;

    constructor(
        injector: Injector,
        private _codeReviewServiceProxy : CodeReviewServiceProxy
    ) {
        super(injector);
    }
    
    ngOnInit(): void {
        //OnInit
    }

    async show(listaCodereviewHistoricoDto : HistoricoCodeReviewAprovadorDto[]) {
        this._codeReviewServiceProxy.getHistoricoAprovacaoCodeReview()
            .subscribe(res => {
                this.listaHistoricoCodeReviewDto = res;
            });
        this.codeReviewHistoryModal.show();
    }
    
    close(): void {
        this.codeReviewHistoryModal.hide();
        this.closed.emit();
    }

    getLocaleDateHourCodeReview(dataAvaliacao: moment.Moment) {
        const dateString = dataAvaliacao ? dataAvaliacao.toString() : "";
        return new Date(dateString).toLocaleString();
    }
}