import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AppSessionService } from '@shared/common/session/app-session.service';
import { RiskManagementConfigurationDto, RiskManagementConfigurationServiceProxy } from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs";


@Component({
    selector: 'configuration-risk',
    templateUrl: './configurationRiskManagement.component.html',
    styleUrls: ['./configurationRiskManagement.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class ConfigurationRiskManagementComponent extends AppComponentBase implements OnInit {
    appSession: AppSessionService;
    activeRisk: boolean = false;

    loading = false;

    constructor(
        injector: Injector,
        private _configureRiskManagementAppService: RiskManagementConfigurationServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getParameters();
    }

    getParameters() {
        this._configureRiskManagementAppService.getConfiguration()
            .subscribe(res => {
                this.activeRisk = res.activeRisk;
            });
    }

    saveParameters() {
        let parametersDto = new RiskManagementConfigurationDto;
        parametersDto.activeRisk = this.activeRisk;
        this.loading = true;
        this._configureRiskManagementAppService.insertUpdateConfiguration(parametersDto)
        .pipe(finalize(() => this.loading = false))
        .subscribe(x => { 
            this.getParameters();
            this.notify.success(this.l('SavedSuccessfully'));
        });  
    }
}