
import {Router} from "@angular/router"
import { Component, Injector, ViewChild, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { DemandaCardDto, ProfileServiceProxy, EnumTipoDemanda, DemandasServiceProxy, EnumSituacaoDemanda, EnumStatusDemanda } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { EntityTypeHistoryModalComponent } from '@app/shared/common/entityHistory/entity-type-history-modal.component';
import * as _ from 'lodash';

@Component({
    selector: 'boxKanban',
    templateUrl: './box-kanban.component.html',
    styleUrls: ['./box-kanban.component.less'],

})
export class BoxKanbanComponent extends AppComponentBase implements OnInit {
    @ViewChild('entityTypeHistoryModal', { static: true }) entityTypeHistoryModal: EntityTypeHistoryModalComponent;
    @Input() card: DemandaCardDto;
    @Output() openModal: EventEmitter<OpenModalCardDto> = new EventEmitter<OpenModalCardDto>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() buildTracking: EventEmitter<string> = new EventEmitter<string>();
    referenciaTratada = '';
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';

    _entityTypeFullName = 'QAMetrik.Demandas.Demanda';
    entityHistoryEnabled = true;

    hasDemandaEstagnada : boolean = false;
    listaDemandasEstagnadas : string[] = [];

    get enumSituacaoDemanda(): (key: string) => EnumSituacaoDemanda {
        return (key: string) => EnumSituacaoDemanda[key];
    }

    get enumStatusDemanda(): (key: string) => EnumStatusDemanda {
        return (key: string) => EnumStatusDemanda[key];
    }
    
    constructor(
        private injector: Injector,
        private _demandasServiceProxy: DemandasServiceProxy,
        private _profileServiceProxy: ProfileServiceProxy, 
        private router: Router

    ) {
        super(injector);
    }

    goToBuildTracking(id: string) {
        this.buildTracking.emit(id);
    }

    ngOnInit() {
        this.getProfilePicture();
        this.entityHistoryEnabled = this.setIsEntityHistoryEnabled();
        
    }

  

    showHistory(id: string): void {
        if (this.entityTypeHistoryModal != undefined) {
            this.entityTypeHistoryModal.show({
                entityId: id,
                entityTypeFullName: this._entityTypeFullName,
                entityTypeDescription: " "
            });
        }
        
    }

    getProfilePicture(): void {
        if (!this.card.userId) { return null; }

        this._profileServiceProxy.getPictureByUserId(this.card.userId).subscribe(result => {
            if (result && result.profilePicture) {
                this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    }

    emitEventOpenModal(tabId: string, ev: any) {
        ev.preventDefault();
        let dto = new OpenModalCardDto();
        dto.id = this.card.id;
        dto.tabId = tabId;
        if (tabId !== '9') {
            this.openModal.emit(dto);
        } else {
            dto.gerarVersaoTeste = this.card;
            this.openModal.emit(dto);
        }
    }

    getClassBox() {
        switch (this.card.tipo) {
            case EnumTipoDemanda.Suporte:
                return 'box box-bug';
            case EnumTipoDemanda.Configuracao:
                return 'box box-config';
            default:
                return 'box';
        }

    }

    archiveUnachiveDemanda(id: string) {
        this.message.confirm('',
            '',
            (isConfirmed) => {
                if (isConfirmed) {
                    this._demandasServiceProxy.arquivar(id)
                        .subscribe(() => {
                            this.reloadPage.emit(true);
                            this.notify.success(this.l('SuccessfullyArchived'));
                        });
                }
            }
        );
    }  

    atualizarObjetosDemanda(id: string) {
        this._demandasServiceProxy.updateObjectsDemanda(id, true)
                        .subscribe(() => {
                            this.reloadPage.emit(true);
                            this.notify.success(this.l('UpdatedDemandObjects'));
                        });
    }


    irParaAvaliacao(id: string){
        this._demandasServiceProxy.buscarAvaliacao(id)
                        .subscribe(result => {
                            if(result != null)
                            {
                                this.router.navigate(['/app/main/avaliacoes-qualidade/itens/', result]);
                            }
                            else{
                                this.message.warn(this.l("AvaliacaoIndisponivel"), this.l("AvaliacaoIndisponivelTitle"), true );
                            }
                        }, error  => { setTimeout(() => {
                            this.notify.error(error);
                            // And any other code that should run only after 5s
                          }, 1000); });
    }
    private setIsEntityHistoryEnabled(): boolean {
        let customSettings = (abp as any).custom;
        return customSettings.EntityHistory && customSettings.EntityHistory.isEnabled && _.filter(customSettings.EntityHistory.enabledEntities, entityType => entityType === this._entityTypeFullName).length === 1;
    }

    //Conclui a demanda selecionada
    concluirDemanda(id: string){
        this._demandasServiceProxy.concluirDemanda(id)
            .subscribe(() => {
                this.reloadPage.emit(true);
                this.notify.success("Demanda concluída com sucesso!");
            })
    }

    getTooltip(card: DemandaCardDto) {
        return card.demandaSapId + ' - ' + card.titulo;
    }

    requestRiskManagement(demandId: string){
        this._demandasServiceProxy.requestsRiskAssessment(demandId, true)
            .subscribe(() => {
                this.notify.success("Gestão de risco solicitada com sucesso!");
                location.reload();
            });
    }
}

export class OpenModalCardDto {

    id: string;
    tabId: string;
    gerarVersaoTeste: DemandaCardDto;

}
