<form (ngSubmit)="saveAll()" #changeForm="ngForm" id="changeRequest">
    <div [@routerTransition] class="overflow-auto">
        <div class="kt-subheader kt-grid__item">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    <span>{{l("Requisição de mudança")}}</span>
                </h3>
                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                <span class="kt-subheader__desc">
                    {{l("Gerenciamento de requisição de mudança")}}
                </span>
            </div>
        </div>
        <div class="kt-content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__body">
                    <div class="kt-subheader__toolbar">
                        <div class="kt-subheader__wrapper">
                            <button class="btn btn-primary" type="submit" [disabled]="changeForm.invalid">
                                <i class="fa fa-save"></i>
                                {{l("SaveAll")}}
                            </button>
                        </div>
                    </div>
                    <tabset>
                        <tab heading="Requisição de mudança">
                            <div class="form-group">
                                <label for="change-request-level" class="kt-checkbox">
                                    <input id="change-request-level" type="checkbox" name="ChangeRequestIsEnabledLevel"
                                        [(ngModel)]="rdmSettingsDto.approvalLevelsIsActive"> {{l('Utiliza níveis de aprovação')}}
                                    <span></span>
                                </label>
                                <br>
                                <div class="row">
                                    <div class="col-10">
                                        <label for="List_Email_Change_Request">Email para comunicação</label>
                                        <ng-select [items]="emailsForRdmCommunicationDto.userEmails" [multiple]="true"
                                            #List_Email_Change_Request="ngModel" [(ngModel)]="emailsForRdmCommunicationDto.userEmails"
                                            [addTag]="true" name="listEmailChangeRequest" 
                                            [ngClass]="{'is-invalid': List_Email_Change_Request.invalid, 'is-valid': !List_Email_Change_Request.invalid}"
                                            (change)="validarListEmails()">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <form #changeRequiredForm="ngForm">
                                <div class="required-approve-content">
                                    <div class="add-departament">
                                        <button class="btn btn-primary" type="submit" (click)="showDepartamentModal(true)">
                                            <i class="fa fa-plus"></i>
                                            {{l('Adicionar departamento')}}
                                        </button>
                                    </div>
                                    <div id="approver-list" *ngIf="this.rdmDepartamentListDto.length > 0; else emptyApprover">
                                        <div class="list-head list-row">
                                            <div class="list-item item-departament"><span>{{l('DepartamentNative')}}</span></div>
                                            <div class="list-item item-condition"><span>{{l('ConditionNative')}}</span></div>
                                            <div class="list-item item-action"><span>{{l('ActionNative')}}</span></div>
                                        </div>
                                        <div class="list-body list-row" *ngFor="let departament of rdmDepartamentListDto; let i = index">
                                            <div class="list-item item-departament"><span>{{departament.title}}</span></div>
                                            <div class="list-item item-condition">
                                                <span class="optional-condition" *ngIf="departament.isOptional == true">{{l('OptionalConditionNative')}}</span>
                                                <span class="required-condition" *ngIf="departament.isOptional == false">{{l('RequiredConditionNative')}}</span>
                                            </div>
                                            <div class="list-item item-action">
                                                <span class="edit-item" (click)="editDepartamentItem(departament.id)" tooltip="{{l('Edit')}}"><i class="fas fa-pen"></i></span>
                                                <span class="delete-item" (click)="deleteDepartamentItem(i, departament)" tooltip="{{l('Delete')}}"><i class="fas fa-trash"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #emptyApprover>
                                        <h4 class="empty">{{l('EmptyDepartaments')}}</h4>
                                    </ng-template>
                                </div>
                            </form>
                        </tab>
                        <tab heading="Níveis de Aprovação" [disabled]="!rdmSettingsDto.approvalLevelsIsActive && this.rdmDepartamentListDto.length > 0"
                            (selectTab)="getRdmApprovalLevels()">
                            <div class="form-group">
                                <div class="required-approve-content">
                                    <div id="level-list" class="form-group">
                                        <p-table [value]="rdmApprovalLevelsDto" dataKey="id">
                                            <ng-template pTemplate="header">
                                                <tr class="list-head list-row">
                                                    <th class="list-item item-condition">Departamento</th>
                                                    <th class="list-item item-level">Nível de Aprovação</th>
                                                    <th class="list-item item-action">Ação</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-approval let-editing="editing">
                                                <tr class="list-body list-rowResult">
                                                    <td class="list-item item-condition">{{ approval.rdmDepartamentName }}</td>
                                                    <td class="list-item item-level" [pEditableColumn]="approval.approvalLevel" pEditableColumnField="approvalLevel">
                                                        <p-cellEditor>
                                                            <ng-template pTemplate="input">
                                                                <input pInputText [(ngModel)]="approval.approvalLevel" />
                                                            </ng-template>
                                                            <ng-template pTemplate="output">
                                                                {{ approval.approvalLevel }}
                                                            </ng-template>
                                                        </p-cellEditor>
                                                    </td>
                                                    <td class="list-item item-action">
                                                        <span class="delete-item" (click)="deleteRdmApprovalLevelDto(approval)" tooltip="{{l('Delete')}}"><i class="fas fa-trash"></i></span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
        <create-edit-departament-modal #createEditDepartamentModal (closed)="reloadPage($event)"></create-edit-departament-modal>     
    </div>
</form>
