// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `topbar,
top-bar-menu,
theme4-brand {
  display: flex;
}
.dropdown-language .dropdown-menu {
  width: 250px !important;
}
.dropdown-language .dropdown-menu li.kt-nav__item span,
.dropdown-language .dropdown-menu li.kt-nav__item i {
  display: block;
}
.clickable-item {
  cursor: pointer;
}
.btn + .btn {
  margin-left: 5px;
}
.btn-block + .btn-block {
  margin-left: 0px !important;
}
.kt-badge + .kt-badge {
  margin-left: 5px;
}
.form-control-feedback {
  color: #fd397a;
  margin-top: 0.2rem;
  font-size: 0.85rem;
  text-align: left;
}
li.nav-item a.nav-link span.kt-badge {
  font-size: 11px;
  margin-left: 4px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > .dropdown-item:hover {
  background: #f4f5f8;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/layout.less"],"names":[],"mappings":"AAAA;;;EACI,aAAA;AAGJ;AAAA;EAEQ,uBAAA;AACR;AAHA;;EAMgB,cAAA;AAChB;AAKA;EACI,eAAA;AAHJ;AAMA;EACI,gBAAA;AAJJ;AAOA;EACI,2BAAA;AALJ;AAQA;EACI,gBAAA;AANJ;AASA;EACI,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AAPJ;AAUA;EAGY,eAAA;EACA,gBAAA;AAVZ;AAeA;;EACI,mBAAA;EACA,qBAAA;AAZJ","sourcesContent":["topbar, top-bar-menu, theme4-brand {\n    display: flex;\n}\n\n.dropdown-language {\n    .dropdown-menu {\n        width: 250px !important;\n\n        li.kt-nav__item {\n            span, i {\n                display: block;\n            }\n        }\n    }\n}\n\n.clickable-item {\n    cursor: pointer;\n}\n\n.btn + .btn {\n    margin-left: 5px;\n}\n\n.btn-block + .btn-block{\n    margin-left: 0px !important;\n}\n\n.kt-badge + .kt-badge{\n    margin-left: 5px;\n}\n\n.form-control-feedback {\n    color: #fd397a;\n    margin-top: 0.2rem;\n    font-size: .85rem;\n    text-align: left;\n}\n\nli.nav-item{\n    a.nav-link{\n        span.kt-badge{\n            font-size: 11px;\n            margin-left: 4px;\n        }\n    }\n}\n\n.dropdown-menu>li>a:hover, .dropdown-menu>.dropdown-item:hover{\n    background: #f4f5f8;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
