<div class="kt-content">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <h6 class="title-section">{{l("RecentDemands")}}</h6>
            <div id="last-demands">
                <div class="card-demandas-recentes" *ngFor="let card of cardList" (click)="redirectToDemand(card.idDemanda)">
                    <span class="demanda-card">
                        <div>{{ card.numeroDemanda }} | {{ card.tituloDemanda }} - {{ card.estagioDemanda }}</div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
