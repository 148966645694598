// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-autocomplete-input-token {
  padding: 0px;
}
.ui-autocomplete-multiple-container {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/assets/primeng/autocomplete/css/primeng.autocomplete.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,WAAW;AACb","sourcesContent":[".ui-autocomplete-input-token {\n  padding: 0px;\n}\n.ui-autocomplete-multiple-container {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
