import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import {
    PromptAIEnum,
    PromptAIEnumLabel,
} from "@app/shared/enums/prompt-ai-enum";
import {
    AIConversationHistoryDto,
    ChatTitlesDto,
    CodeRequest,
    FileParameter,
    GenerateCodeRequest,
    PromptAIConfigServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { MessageService, type SelectItem } from "primeng/api";
import { Dialog } from "primeng/dialog";
import { FeedbackRequest } from "./../../../../shared/service-proxies/service-proxies";
import * as moment from "moment";
import { SpinnerService } from "@shared/services/spinner.service";
import { AnthropicService } from "@shared/services/anthropic.service";
import { PdfReaderService } from "@shared/services/pdf-reader.service";
import { ResponseError } from "@app/shared/interfaces/response-error";

interface UploadEvent {
    originalEvent: Event;
    files: File[];
}

interface ChatTitle {
    date: Date;
    aiPromptType: number;
    chatTitle: string;
}

interface GroupedChatTitles {
    [date: string]: ChatTitle[];
}

@Component({
    selector: "app-prompt-chat",
    templateUrl: "./prompt-chat.component.html",
    styleUrls: ["./prompt-chat.component.less"],
})
export class PromptChatComponent implements OnInit {
    @ViewChild("dialog") dialog!: Dialog;
    @ViewChild("scroll") scrollContainer!: ElementRef;
    filename: string = "meuArquivo.txt";
    feedbackRequest = {} as FeedbackRequest;
    generateDateRequest = {} as GenerateCodeRequest;
    codeRequest = {} as CodeRequest;
    userInput: string = "";
    userFeedbackInput: string = "";
    chatDate: string = "";
    chatConversationHistory: AIConversationHistoryDto[] = [];
    chatConversation: AIConversationHistoryDto;
    promptNumber: number = 0;
    sidebarVisible: boolean = false;
    isOther: boolean = false;
    isCopy: boolean = false;
    loading: boolean = false;
    isSimpleOrComplete: boolean = false;
    loadingRegeneratePrompt: boolean = false;
    loadingRegenerate: boolean = false;
    satisfactoryAnswer: boolean | null = null;
    chatId: string = "";
    chatReloadId: string = "";
    chatTitle: string = "";
    file: File;
    textResponse: string = "";
    chatsTitles: ChatTitlesDto[] = [];
    chatsTitlesByDates: GroupedChatTitles = {};
    isPromptIA: boolean = false;
    visible: boolean = false;
    visibleModal: boolean = true;
    items: SelectItem[] = [];

    constructor(
        private messageService: MessageService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private anthropicService: AnthropicService,
        private pdfReaderService: PdfReaderService,
        public spinnerService: SpinnerService,
        private _promptAIConfigService: PromptAIConfigServiceProxy,
        private _activatedRoute: ActivatedRoute
    ) {
        this.promptNumber = +this._activatedRoute.snapshot.paramMap.get("id")!;
        this.promptNumber == PromptAIEnum.CodeGenerate
            ? (this.isPromptIA = true)
            : this.isPromptIA;
    }

    async ngOnInit() {
        await this.getTitlesSidebar();
    }

    scrollToBottom(): void {
        try {
            if (this.scrollContainer && this.scrollContainer.nativeElement) {
                this.scrollContainer.nativeElement.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
        } catch (err) {
            console.error("Erro ao rolar o chat:", err);
        }
    }

    maximizeDialog() {
        if (this.dialog) {
            this.dialog.maximize();
        }
    }

    onClose() {
        this.router.navigateByUrl(`/app/main/prompt-selection`);
    }

    onCloseFeedback() {
        this.isOther = false;
        this.userFeedbackInput == "";
    }

    async setFeedbackUser(chatId: string, satisfaction: boolean) {
        this.isOther = false;
        this.satisfactoryAnswer = satisfaction;
        this.chatId = chatId;

        if (!satisfaction) {
            this.visible = true;
        } else {
            await this.setResponseFeedback();
        }
    }

    sanitizeText(message: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(message);
    }

    clear() {
        this.file = null;
    }

    async onUploaded(data: { files: File }) {
        this.items = [];
        this.textResponse = '';
        this.file = data.files[0];

        this.textResponse = "";
        this.loading = true;
        this.spinnerService.show();
        event["currentFiles"] = [];
        const textExtract = await this.pdfReaderService.extractTextFromPdf(
            this.file
        );

        this.postClaudeAi(textExtract[0]);
    }

    async onUpload(event: UploadEvent) {
        this.spinnerService.show();
        // const formData: FormData = new FormData();
        // formData.append("file", event.files[0]);

        const postFile = {} as FileParameter;
        postFile.data = event.files[0];
        postFile.fileName = event.files[0].name;

        // const file = event.files[0];
        // const textExtract = await this.pdfReaderService.extractTextFromPdf(
        //     file
        // );
        // this.postClaudeAi(textExtract[0]);
        // return;

        await this._promptAIConfigService
            .readDocAndGenerateCode("Teste", postFile)
            .subscribe(
                (result) => {
                    this.userInput = result.response;
                    this.messageService.add({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: "Arquivo documentado com sucesso.",
                    });
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                        life: 3000,
                    });
                }
            )
            .add(() => {
                this.spinnerService.hide();
            });
    }

    setTitlesChatsByDates(chatsTitle: ChatTitlesDto[]) {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);

        function parseDateString(dateString: string): Date {
            const [day, month, yearAndTime] = dateString.split("/");
            const [year] = yearAndTime.split(" ");
            return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
        }

        function getDateGroup(parsedDate: Date): string {
            const todayZeroed = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate()
            );
            const yesterdayZeroed = new Date(
                yesterday.getFullYear(),
                yesterday.getMonth(),
                yesterday.getDate()
            );

            if (parsedDate >= todayZeroed) {
                return "Hoje";
            }
            if (parsedDate >= yesterdayZeroed && parsedDate < todayZeroed) {
                return "Ontem";
            }
            if (parsedDate >= thirtyDaysAgo) {
                return "Últimos 30 dias";
            }
            return "Últimos 30 dias";
        }

        var chatsTitlesByDates = chatsTitle.reduce(
            (acc: GroupedChatTitles, item: ChatTitlesDto) => {
                const parsedDate = parseDateString(
                    item.chatDate.format("DD/MM/YYYY")
                );
                const dateGroup = getDateGroup(parsedDate);

                if (!acc[dateGroup]) {
                    acc[dateGroup] = [];
                }
                const isDuplicate = acc[dateGroup].some(
                    (existingItem) => existingItem.chatTitle === item.chatTitle
                );

                if (!isDuplicate) {
                    acc[dateGroup].push({
                        date: item.chatDate.toDate(),
                        aiPromptType: item.aiPromptType,
                        chatTitle: item.chatTitle,
                    });
                }

                return acc;
            },
            {} as GroupedChatTitles
        );

        this.chatsTitlesByDates = chatsTitlesByDates;
    }

    async getChatHistory(chat: ChatTitle, date: moment.Moment) {
        this.chatDate = date.toISOString().split("T")[0];
        this.userFeedbackInput == "";
        this.loading = true;
        this.chatTitle = chat.chatTitle;
        await this._promptAIConfigService
            .getAiConversationHistory(chat.aiPromptType, date, chat.chatTitle)
            .subscribe(
                async (result) => {
                    this.chatConversationHistory = result;
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 0);
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                        life: 3000,
                    });
                }
            )
            .add(() => {
                this.userInput = "";
                this.loading = false;
            });
    }

    async getTitlesSidebar() {
        if (this.promptNumber == 0) {
            await this._promptAIConfigService
                .getChatTitle(this.promptNumber)
                .subscribe(
                    async (result) => {
                        if (result.length > 0) {
                            this.chatsTitles = result;
                            this.setTitlesChatsByDates(result);
                        }
                    },
                    (error: any) => {
                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                            life: 3000,
                        });
                    }
                )
                .add();
        }
    }

    async sendMessage() {
        this.spinnerService.show();
        this.loading = true;
        if (this.userInput == "") {
            this.messageService.add({
                severity: "warn",
                summary: "Atenção!",
                detail: `Preencha o campo com sua pergunta antes de enviar.`,
                life: 3000,
            });
            return;
        } else {
            if (this.chatTitle == "") this.chatTitle = this.userInput;

            this.generateDateRequest.question = this.userInput;
            this.generateDateRequest.chatTitle = this.chatTitle;
            this.generateDateRequest.chatDate = this.chatDate;
            this.generateDateRequest.isSimpleOrComplete =
                this.isSimpleOrComplete;

            await this._promptAIConfigService
                .generateCompleteCode(this.generateDateRequest)
                .subscribe(
                    (result) => {
                        this.chatTitle = result.chatTitle;
                        this.chatConversationHistory.push(result);

                        setTimeout(() => {
                            this.scrollToBottom();
                        }, 0);
                    },
                    (error: any) => {
                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                            life: 3000,
                        });
                    }
                )
                .add(() => {
                    this.userInput = "";
                    this.loading = false;
                    this.sidebarVisible = false;
                    this.isSimpleOrComplete = false;
                    this.getTitlesSidebar();
                    this.spinnerService.hide();
                });
        }
    }

    async sendMessageNoPrompt() {
        if (this.userInput == "") {
            this.messageService.add({
                severity: "warn",
                summary: "Atenção!",
                detail: `Adicione o código que deseja documentar.`,
                life: 3000,
            });
            return;
        } else {
            this.spinnerService.show();
            this.loadingRegenerate = true;
            this.codeRequest.question = this.userInput;

            if (this.promptNumber == 1) {
                await this._promptAIConfigService
                    .performCodeReview(this.codeRequest)
                    .subscribe(
                        async (result) => {
                            this.chatId = result.id;
                            this.chatConversation = result;
                            this.textResponse = result.response;
                        },
                        (error: any) => {
                            this.messageService.add({
                                severity: "error",
                                summary: "Erro!",
                                detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                                life: 3000,
                            });
                        }
                    )
                    .add(() => {
                        this.loadingRegenerate = false;
                        this.spinnerService.hide();
                    });
            } else {
                await this._promptAIConfigService
                    .generateCodeDocumentation(this.codeRequest)
                    .subscribe(
                        async (result) => {
                            this.chatId = result.id;
                            this.chatConversation = result;
                            this.textResponse = result.response;
                        },
                        (error: any) => {
                            this.messageService.add({
                                severity: "error",
                                summary: "Erro!",
                                detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                                life: 3000,
                            });
                        }
                    )
                    .add(() => {
                        this.loadingRegenerate = false;
                        this.spinnerService.hide();
                    });
            }
        }
    }

    regexFormattedHtml(responseChat: string): string {
        const responseRegex = responseChat
            .match(/<[^>]*>.*?<\/[^>]*>/g)
            .join("\n");

        return responseRegex;
    }

    closedSidebar() {
        this.sidebarVisible = !this.sidebarVisible;
    }

    resetChat() {
        this.chatConversationHistory = [];
        this.chatTitle = "";
        this.chatDate = "";
        this.sidebarVisible = false;
    }

    async openClosedSidebar() {
        this.sidebarVisible = !this.sidebarVisible;
        if (this.sidebarVisible) await this.getTitlesSidebar();
    }

    setPromptAISettings(promptAI: PromptAIEnum): string {
        return PromptAIEnumLabel.get(promptAI)!;
    }

    truncateText(text: string, maxLength: number = 28): string {
        if (text != null && text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    }

    copyHTMLContent(message: string, chatReloadId: string = ""): void {
        this.isCopy = true;
        this.chatReloadId =
            chatReloadId != "" ? chatReloadId : this.chatReloadId;
        const tempTextarea = document.createElement("textarea");
        tempTextarea.value = message;
        document.body.appendChild(tempTextarea);
        tempTextarea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextarea);

        setTimeout(() => {
            this.isCopy = false;
        }, 1000);
    }

    setFeedbackRequest(
        chatId: string,
        satisfactoryAnswer: boolean,
        userFeedbackInput: string
    ): FeedbackRequest {
        this.feedbackRequest.respondeId = chatId;
        this.feedbackRequest.satisfaction = satisfactoryAnswer;
        this.feedbackRequest.userFeedback = userFeedbackInput;

        return this.feedbackRequest;
    }

    async setResponseFeedback() {
        this.feedbackRequest = this.setFeedbackRequest(
            this.chatId,
            this.satisfactoryAnswer,
            this.userFeedbackInput
        );
        if (this.userFeedbackInput == "" && !this.satisfactoryAnswer) {
            this.messageService.add({
                severity: "warn",
                summary: "Atenção!",
                detail: "Informe a justificativa.",
            });
        } else {
            this.spinnerService.show();
            await this._promptAIConfigService
                .updateFeedback(this.feedbackRequest)
                .subscribe(
                    (result) => {
                        if (this.chatConversationHistory.length)
                            this.chatConversationHistory.filter((x) => {
                                if (x.id == this.chatId)
                                    x.satisfactoryAnswer =
                                        result.satisfactoryAnswer;
                                x.responseFeedback = result.responseFeedback;
                            });

                        this.messageService.add({
                            severity: "success",
                            summary: "Feedback Recebido!",
                            detail: "Obrigado pelo seu Feedback.",
                        });
                        this.userFeedbackInput = "";
                    },
                    (error: any) => {
                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                            life: 3000,
                        });
                        this.userFeedbackInput = "";
                    }
                )
                .add(() => {
                    this.visible = false;
                    this.userFeedbackInput == "";
                    this.spinnerService.hide();
                });
        }
    }

    async regenerateQuestionNoPrompt(message: string) {
        this.userInput = message;
        await this.regenerateQuestion(this.chatConversation, false);
    }

    async regenerateQuestion(
        chat: AIConversationHistoryDto,
        isPrompt: boolean
    ) {
        this.spinnerService.show();
        this.loadingRegeneratePrompt = isPrompt;
        this.loadingRegenerate = !isPrompt ? true : false;
        this.userInput = chat.question;
        this.chatTitle = chat.chatTitle;
        this.chatReloadId = chat.id;

        await this._promptAIConfigService
            .regenerateQuestion(chat.id)
            .subscribe(
                async (result) => {
                    if (isPrompt) {
                        this.chatConversationHistory =
                            this.chatConversationHistory.filter(
                                (x) => x.id != chat.id
                            );
                        this.chatConversationHistory.push(result);

                        setTimeout(() => {
                            this.scrollToBottom();
                        }, 0);
                    } else {
                        this.chatId = result.id;
                        this.chatConversation = result;
                        this.satisfactoryAnswer = result.satisfactoryAnswer;
                        this.textResponse = result.response;
                    }
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                        life: 3000,
                    });
                }
            )
            .add(() => {
                this.loadingRegenerate = false;
                this.loadingRegeneratePrompt = false;
                this.spinnerService.hide();
            });
    }

    async setFeedbackMessage(message: string) {
        this.userFeedbackInput = message;
        await this.setResponseFeedback();
    }

    async extractSections(text: string) {
        const sections: string[] = [];
        const interfaceRegex = /INTERFACE([\s\S]*?)ENDINTERFACE/g;
        let match;

        while ((match = interfaceRegex.exec(text)) !== null) {
            sections.push(match[0].trim());
        }

        const classRegex = /CLASS([\s\S]*?)ENDCLASS/g;
        while ((match = classRegex.exec(text)) !== null) {
            sections.push(match[0].trim());
        }

        this.textResponse = sections[0];
        this.items.push({
            value: sections[0],
            label: this.setItemsArchiveInterface(sections[0]),
        });

        const classSections = sections.filter((item) =>
            item.startsWith("CLASS")
        );

        Promise.all(
            classSections.map(async (text: string) => {
                // this.items.push({value: text, label: `Arquivo - Class`});
                await this.postClaudeAiClass(text);
            })
        );

        setTimeout(() => {
            this.spinnerService.hide();
            this.loading = false;
        }, 6000);
    }

    setItemsArchive(response: string) {
        let classText: string = "Class";

        const regex = /CLASS\s+(.*?)\s+DEFINITION/i;
        const match = regex.exec(response);

        if (match) {
            classText = match[1].trim();
        } else {
            const regex = /CLASS\s+(.*?)\s+IMPLEMENTATION/i;
            const match = regex.exec(response);

            if (match)
                classText = match[1].trim();
        }

        this.items.push({
            value: response,
            label: classText.replace("```", ""),
        });
    }

    setItemsArchiveInterface(response: string): string {
        const regex = /INTERFACE\s+(.*?)\s*\./i;
        const match = response.match(regex);
        let classText: string = "Interface";

        if (match) {
            classText = match[1];
        }

        return classText.replace("```", "");
    }

    async postClaudeAiClass(abapClass: string) {
        await this.anthropicService
            .sendMessageClass(abapClass)
            .subscribe(
                (response) => {
                    // this.textResponse += '\n' + abapClass + '\n';
                    this.textResponse += "\n" + response.content[0].text + "\n";
                    this.setItemsArchive(response.content[0].text);
                },
                (error: ResponseError) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Ocorreu um erro ao efetuar a sua requisição. Código: ${error.error.code} ${error.error.message}`,
                        life: 3000,
                    });
                }
            )
            .add();
    }

    async postClaudeAi(textExtract: string = "") {
        await this.anthropicService
            .sendMessage(textExtract)
            .subscribe(
                (response) => {
                    this.extractSections(response.content[0].text);
                },
                (error: ResponseError) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Ocorreu um erro ao efetuar a sua requisição. Código: ${error.error.code} ${error.error.message}`,
                        life: 3000,
                    });
                }
            )
            .add();
    }

    downloadTextFile(text: string) {
        const blob = new Blob([text], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = this.filename;
        link.click();

        window.URL.revokeObjectURL(url);
    }
}
