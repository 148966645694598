<div bsModal #deployCustomersModal="bs-modal" id="deployCustomersModal" class="modal fade overflow-auto" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div id="deploy-customers-modal-dialog" class="modal-dialog modal-lg">
        <div id="deploy-customers-control-content" class="modal-content deploy-customers-control-content">
            <div id="modal-header-content" class="modal-header">
                <h4 id="modal-title-content" class="modal-title">
                    {{l("VersionControlDeployOnClients")}}
                </h4>
                <button id="close-modal-content" type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                    <span id="times-icon" class="times-icon" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div id="modal-body-content" class="modal-body">
                <div id="deploy-customers-table" class="control-deploy-table">
                    <p-table #ct id="p-table-deploy-customer" class="p-table-deploy-customer" [value]="deployCustomersList" 
                                [rows]="5" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100,500]" [loading]="loading">
                        <ng-template pTemplate="header">
                            <tr id="table-header-row" class="table-header-row">
                                <th id="th-customer" class="th-customer">{{l("Client")}}</th>
                                <th id="th-status" class="th-status">{{l("VersionControlStatus")}}</th>
                                <th id="th-update-date" class="th-update-date">{{l("UltAtu")}}</th>
                                <th id="th-actions" class="th-actions">{{l("Actions")}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-deployCustomer let-i>
                            <tr id="table-body-row" class="table-body-row">
                                <td id="td-customer" class="td-customer">{{ deployCustomer.tenantName }}</td>
                                <td id="td-status" class="td-status">{{ mudarStatus(deployCustomer) }}</td>
                                <td id="td-update-date" class="td-update-date">{{ getLocaleDateHourNotificacao(deployCustomer.dataUltimaAtualizacao) }}</td>
                                <td id="td-actions" class="td-actions">
                                    <i class="fas fa-rocket" (click)="deploy(deployCustomer.gerenciamentoVersaoSapId, deployCustomer.tenantId)" tooltip="{{l('startDeploy')}}" *ngIf="deployCustomer.status == 'SemProcessamento'"></i>
                                    <i class="fas fa-user-edit" tooltip="{{l('DeployManual')}}" (click)="forcarDeploy(deployCustomer.id)" *ngIf="deployCustomer.status == 'EmProcessamento' || deployCustomer.status == 'Cancelado'"></i>
                                    <i class="far fa-comment-alt" tooltip="{{'Mensagem de erro:' + deployCustomer.mensagemErroAtualizacao}}" *ngIf="deployCustomer.status == 'Cancelado'"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div id="table-total-itens" class="flex align-items-center justify-content-between">
                                {{l("Total")}}: {{deployCustomersList ? deployCustomersList.length : 0 }}
                            </div>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <div id="modal-footer-content" class="modal-footer">
                <div id="send-button-content" class="send-button">
                    <button type="submit" class="btn btn-primary blue" (click)="reloadPage(null)">                    
                        <i class="flaticon2-refresh"></i>
                        <span>{{l("UpdateStatus")}}</span>
                    </button>
                </div>
            </div>  
        </div>
    </div>
</div>