import { Component, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ConfiguracaoAreaUsuarioServiceProxy, DadosParaPerfilUsuarioDto } from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'tabs-configuration-projetos',
    templateUrl: './tabsProjetoConfiguration.component.html',
    styleUrls: ['./tabsProjetoConfiguration.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class tabsProjetoConfigurationComponent extends AppComponentBase implements OnInit {
    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
    }
}