<div bsModal #historyModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="historyModal"
  aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form #form="ngForm" novalidate autocomplete="off">
        <div class="modal-header">
          <h4 class="modal-title">
            <span>{{l("HistoricoDeMensagens")}}</span>
          </h4>
          <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body margins-mensagens">
          <div class="row" [class.client]="!mensagem.server" [class.server]="mensagem.server"
            *ngFor="let mensagem of mensagens">
            <div class="col-md-9 col-10">
              <div class="tag nome-usuario" [class.tag-server]="mensagem.server" [class.tag-client]="!mensagem.server">
                {{ mensagem.autoReprove ?  l("ReprovacaoAutomatica"): mensagem.nome}} ({{mensagem.dataMensagem | date : 'dd/MM, hh:mm'}}):
              </div>
              <div [class.mensagem-client]="!mensagem.server" [class.mensagem-server]="mensagem.server">
                {{ mensagem.autoReprove ? l(mensagem.texto) : mensagem.texto }}
              </div>
              <div class="tag" [class.tag-server]="mensagem.server" [class.tag-client]="!mensagem.server">
                <i [class]="iconeAcao(mensagem)"></i> {{l(mudarAcao(mensagem))}}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
        </div>
      </form>
    </div>
  </div>
</div>