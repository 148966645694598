import { IAjaxResponse } from 'abp-ng2-module/index';
import { TokenService } from 'abp-ng2-module';
import { Component, Injector, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SettingScopes, SendTestEmailInput, TenantSettingsEditDto, TenantSettingsServiceProxy, AdUserServiceProxy, AdUserDto, PagedResultDtoOfGetAdUserForViewDto } from '@shared/service-proxies/service-proxies';
import { random } from 'lodash';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-external-integration',
  templateUrl: './external-integration.component.html',
  styleUrls: ['./external-integration.component.css']
})
export class ExternalIntegrationComponent extends AppComponentBase implements OnInit {
  ngOnInit(): void {
    this.testEmailAddress = this.appSession.user.emailAddress;
    this.getSettings();
  }

  usingDefaultTimeZone = false;
  initialTimeZone: string = null;
  testEmailAddress: string = undefined;

  isMultiTenancyEnabled: boolean = this.multiTenancy.isEnabled;
  showTimezoneSelection: boolean = abp.clock.provider.supportsMultipleTimezone;
  activeTabIndex: number = (abp.clock.provider.supportsMultipleTimezone) ? 0 : 1;
  loading = false;
  settings: TenantSettingsEditDto = undefined;

  logoUploader: FileUploader;
  customCssUploader: FileUploader;

  remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;

  defaultTimezoneScope: SettingScopes = SettingScopes.Tenant;

  constructor(
    injector: Injector,
    private _tenantSettingsService: TenantSettingsServiceProxy
  ) {
    super(injector);
  }



  saveAll(): void {
    this._tenantSettingsService.updateAllSettings(this.settings).subscribe(() => {
      this.notify.info(this.l('SavedSuccessfully'));

      if (abp.clock.provider.supportsMultipleTimezone && this.usingDefaultTimeZone && this.initialTimeZone !== this.settings.general.timezone) {
        this.message.info(this.l('TimeZoneSettingChangedRefreshPageNotification')).then(() => {
          window.location.reload();
        });
      }
    });
  }

  getSettings(): void {
    this.loading = true;
    this._tenantSettingsService.getAllSettings()
      .pipe(finalize(() => { this.loading = false; }))
      .subscribe((result: TenantSettingsEditDto) => {
        this.settings = result;
        if (this.settings.general) {
          this.initialTimeZone = this.settings.general.timezone;
          this.usingDefaultTimeZone = this.settings.general.timezoneForComparison === abp.setting.values['Abp.Timing.TimeZone'];
        }
      });
  }

}
