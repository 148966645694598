import { Component } from "@angular/core";
import { SpinnerService } from "@shared/services/spinner.service";

@Component({
    selector: "app-spinner",
    templateUrl: "./spinner.component.html",
    styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent {
    isLoading: boolean = false;

    constructor(private spinnerService: SpinnerService) {
        this.spinnerService.isLoading.subscribe((value) => {
            this.isLoading = value;
        });
    }
}
