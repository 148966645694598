import { Component, EventEmitter, Injector, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ConfigDeployAutomaticoServiceProxy, PeriodoDeployDto } from '@shared/service-proxies/service-proxies';
import moment from 'moment/moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-Or-Edit-Period-modal',
  templateUrl: './createOrEditPeriod-modal.component.html',
  styleUrls: ['./createOrEditPeriod-modal.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class CreateOrEditPeriodModalComponent extends AppComponentBase {
  @ViewChild('createOrEditPeriodModal', { static: true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  name: string;
  startDate: Date;
  endDate: Date;
  deployPeriodId: string = '00000000-0000-0000-0000-000000000000';
  loading: boolean = false;
  recurrent: boolean = false;

  constructor(injector: Injector,
    private _configDeployAutomaticoService: ConfigDeployAutomaticoServiceProxy
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  async show(deployPeriodDto : PeriodoDeployDto, edit: boolean) {
    if (edit) {
      this.name = deployPeriodDto.nomePeriodo;
      this.startDate = deployPeriodDto.dataInicial.toDate();
      this.endDate = deployPeriodDto.dataFinal.toDate();
      this.deployPeriodId = deployPeriodDto.id;
      this.recurrent = deployPeriodDto.recorrente;

      this.modal.show();

      return;
    }
    
    this.cleanProperty();
    this.modal.show();
  }

  close(): void {
    this.modal.hide();
    this.closed.emit();
  }

  save() {
    if (this.name == undefined || this.name.length === 0){
      this.notify.warn(this.l('Informe o nome do período.'));
      return;
    }

    if (this.startDate == null || this.startDate == undefined){
      this.notify.warn('Informe a data inicial.');
      //save.disabled = false;
      return;
    }

    if (this.endDate == null || this.endDate == undefined){
      this.notify.warn('Informe a data final.');
      //save.disabled = false;
      return;
    }

    if (this.startDate > this.endDate){
      this.notify.warn(this.l('Data inicial não pode ser maior que a final.'));
      //save.disabled = false;
      return;
    }

    let deployPeriodDto = new PeriodoDeployDto;
    deployPeriodDto.nomePeriodo = this.name;
    deployPeriodDto.dataInicial = moment(this.startDate);
    deployPeriodDto.dataFinal = moment(this.endDate);
    deployPeriodDto.recorrente = this.recurrent;
    deployPeriodDto.id = this.deployPeriodId;
    
    this._configDeployAutomaticoService.insertUpdatePeriodosDeploy(deployPeriodDto)
        .pipe(finalize(() => this.loading = false))
        .subscribe(x => { 
            this.notify.success(this.l('SavedSuccessfully'));
            this.cleanProperty();
            this.close();
        });  
  }

  cleanProperty() {
    this.name = null;
    this.startDate = null;
    this.endDate = null;
    this.loading = false;
    this.deployPeriodId = '00000000-0000-0000-0000-000000000000';
    this.recurrent = false;
  }
}
