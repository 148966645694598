<div [@routerTransition] id="technicalDebts" class="overflow-auto">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("EnvironmentEvaluation")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{l("ListTechnicalDebts")}}
            </span>
        </div>        
    </div>

    <div class="table-debts">
        <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
            <p-table #dataTable (onLazyLoad)="recarregar($event)"
                [value]="primengTableHelper.records"
                rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false" [lazy]="true"
                [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                [resizableColumns]="primengTableHelper.resizableColumns">
                <ng-template pTemplate="header">
                    <tr class="table-header">
                        <th pSortableColumn="objeto">
                            {{l("Object")}}
                        </th>
                        <th pSortableColumn="linha">
                            {{l("Row")}}
                        </th>
                        <th pSortableColumn="debitoTecnico">
                            {{l("TechnicalDebt")}}
                        </th>
                        <th pSortableColumn="tempoCorrecao">
                            {{l("CorrectionTime")}}
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-record="$implicit">
                    <tr class="table-body" (click)=" getLinesCode(record.id)">
                        <td>
                            {{record.objeto}}
                        </td>
                        <td>
                            {{record.linha}}
                        </td>
                        <td>
                            <a class="documentation-link" *ngIf="record.documentacaoId" href="javascript:;"
                            (click)="documentacaoModal.show(record.documentacaoId)">{{l(record.debitoTecnico)}}
                            </a>
                            <span *ngIf="!record.documentacaoId">{{l(record.debitoTecnico)}}</span>
                        </td>
                        <td>
                            {{record.tempoCorrecao}} <span>min</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                {{l('NoData')}}
            </div>
            <div class="primeng-paging-container">
                <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                    (onPageChange)="recarregar($event)"
                    [totalRecords]="primengTableHelper.totalRecordsCount"
                    [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                </p-paginator>
                <span class="total-records-count">
                    {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                </span>
            </div>
        </div>
    </div>

    <div class="code-debts">
        <h6 class="ml-4 mt-4">{{l('SourceCode')}}: <strong>{{objectName}}</strong></h6>
        
        <div class="row box-code">
            <div class="col-md-1 col-1  contador-linhas">
              <span *ngFor="let linha of linhas"><i *ngIf="linha && linha.erro" class="fas fa-exclamation-circle"></i>{{linha.linha}}<br /></span>
            </div>
            <div class="col-md-11 col-10">
              <pre><code class="abap" id="codigo"></code></pre>
            </div>
        </div>
    </div>

    <app-documentacao-item-qualidade-modal #documentacaoModal></app-documentacao-item-qualidade-modal>
</div>