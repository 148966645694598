<div bsModal #historysChangeRequestModal="bs-modal" id="historysChangeRequestModal" class="modal fade change-modal overflow-auto" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content change-modal">
            <form #historysChangeRequestModal="ngForm" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{l("RDM - Requisição de mudança")}}
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="form-group col-md-8">                              
                            <label for="change-status">Status</label>
                            <p-timeline [value]="statusChangeRequest" layout="horizontal" styleClass="customized-timeline">
                                <ng-template pTemplate="marker" let-change>
                                    <span class="custom-marker status-steps shadow-2" [style]="changeRequestForApprovalDto?.status === change?.status 
                                        ? setColorsBackgroundStatus(change?.status) : 'background-color: #607D8B;'">
                                        <i [ngClass]="change?.icon"></i>
                                    </span>                                   
                                </ng-template>
                                <ng-template pTemplate="content" let-change>
                                    <span [class]="changeRequestForApprovalDto?.status === change?.status ? 'text-bold-status text-size-status' : 'text-size-status'">
                                        {{searchForStatusTranslation(change?.status) | uppercase}}
                                    </span>
                                </ng-template>
                            </p-timeline>                                            
                        </div> 
                    </div>
                    <div id="gmud-content">
                        <div id="gmud-native-content">
                            <tabset>
                                <tab heading="{{l('GeneralInfo')}}">
                                    <div class="form-group d-flex content-numberTitle">
                                        <div class="content-number">
                                            <label for="change-number">Número</label>
                                            <input id="change-number" #changeNumber="ngModel" type="number" name="changeNumber"
                                                class="form-control" [(ngModel)]="changeRequestForApprovalDto.changeRequestNumber" [disabled]="true"/>
                                        </div>
                                        <div class="content-title">
                                            <label for="change-title">Título</label>
                                            <input id="change-title" #changeTitle="ngModel" type="text" name="ChangeTitle"
                                                class="form-control" [(ngModel)]="changeRequestForApprovalDto.title" [disabled]="true"/>
                                        </div>
                                    </div> 
                                    <div class="form-group">
                                        <label for="change-description">Descrição</label>
                                        <textarea id="change-description" #changeDescription="ngModel" type="text"
                                            name="ChangeDescription" class="form-control textarea"
                                            [(ngModel)]="changeRequestForApprovalDto.description" [disabled]="true">
                                        </textarea>
                                    </div>
                                    <div class="form-group">
                                        <label for="change-reasonForChange">Motivo</label>
                                        <textarea id="change-reasonForChange" #ReasonForChange="ngModel" type="text"
                                            name="ReasonForChange" class="form-control textarea"
                                            [(ngModel)]="changeRequestForApprovalDto.reasonForChange" [disabled]="true">
                                        </textarea>
                                    </div>
                                    <div class="form-group">
                                        <label for="change-expectedBenefits">Benefício esperado</label>
                                        <textarea id="change-expectedBenefits" #ExpectedBenefits="ngModel" type="text"
                                            name="ExpectedBenefits" class="form-control textarea"
                                            [(ngModel)]="changeRequestForApprovalDto.expectedBenefits" [disabled]="true">
                                        </textarea>
                                    </div>
                                    <div class="form-group checkboxGroup-change">
                                        <label for="change-affectProd" class="kt-checkbox checkbox-affectProd">
                                            <input id="change-affectProd" type="checkbox" name="changeAffectProd" 
                                            [(ngModel)]="changeRequestForApprovalDto.changeAffectProd" [disabled]="true"> Afeta produção?
                                            <span></span>
                                        </label>
                                        <label for="change-duringBusinessHours" class="kt-checkbox checkbox-duringBusinessHours">
                                            <input id="change-duringBusinessHours" type="checkbox" name="duringBusinessHours" 
                                            [(ngModel)]="changeRequestForApprovalDto.duringBusinessHours" [disabled]="true"> Será realizada em horário comercial?
                                            <span></span>
                                        </label>
                                    </div>
                                    <div class="form-group optional-approver approver-list">
                                        <label for="approver">Classificação</label>
                                        <ng-select [items]="rdmClassificationType" [multiple]="true" 
                                                   [(ngModel)]="rdmClassificationTypeSelected" 
                                                   name="rdmClassificationTypeSelected" 
                                                   #optionalApprover="ngModel"
                                                   [required]="rdmClassificationTypeSelected.length == 0"
                                                   [disabled]="true">
                                            <ng-template ng-label-tmp let-item="item">
                                                <div class="box-selected">
                                                    <span class="ng-value-label ng-star-inserted">
                                                        <b>{{item.classificationType}}</b>
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                                <div class="p-2">
                                                    <b>{{item.classificationType}}</b>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <div class="form-group dropdownGroup">
                                        <div class="column change-type">
                                            <label for="change-type">Tipo</label>
                                            <input id="change-type" type="text" name="ChangeType"
                                                   class="form-control" [value]="getEnumChangeType(changeRequestForApprovalDto.typeOfChange)" 
                                                   [disabled]="true"/>
                                        </div>                        
                                        <div class="column change-riskLevel">
                                            <label for="change-riskLevel">Categoria do risco para o negócio</label>
                                            <input id="change-riskLevel" type="text" name="ChangeType"
                                                   class="form-control" [value]="getEnumRiskLevel(changeRequestForApprovalDto.riskLevel)" 
                                                   [disabled]="true"/>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="change-risk">Risco caso não seja realizado</label>
                                        <input id="change-risk" #ChangeRisk="ngModel" type="text" name="ChangeRisk"
                                            class="form-control" [(ngModel)]="changeRequestForApprovalDto.risk" [disabled]="true"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="change-mainImpacts">Principais impactos operacionais</label>
                                        <textarea id="change-mainImpacts" #MainImpacts="ngModel" type="text"
                                            name="MainImpacts" class="form-control textarea"
                                            [(ngModel)]="changeRequestForApprovalDto.mainImpacts" [disabled]="true">
                                        </textarea>
                                    </div>
                                    <div class="form-group optional-approver approver-list">
                                        <label for="approver">Indisponibilidade</label>
                                        <ng-select [items]="rdmUnavailabilityType" [multiple]="true" 
                                                   [(ngModel)]="rdmUnavailabilityTypeSelected" 
                                                   name="rdmUnavailabilityTypeSelected" 
                                                   #optionalApprover="ngModel"
                                                   [required]="rdmUnavailabilityTypeSelected.length == 0"
                                                   [disabled]="true">
                                            <ng-template ng-label-tmp let-item="item">
                                                <div class="box-selected">
                                                    <span class="ng-value-label ng-star-inserted">
                                                        <b>{{item.unavailabilityType}}</b>
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                                <div class="p-2">
                                                    <b>{{item.unavailabilityType}}</b>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <div class="form-group">
                                        <label for="change-otherUnavailability">Outras indisponibilidades</label>
                                        <textarea id="change-otherUnavailability" #OtherUnavailability="ngModel" type="text"
                                            name="OtherUnavailability" class="form-control textarea"
                                            [(ngModel)]="changeRequestForApprovalDto.otherUnavailability" [disabled]="true">
                                        </textarea>
                                    </div>
                                    <div class="form-group unavailability-content">
                                        <div class="column pcalendar-unavailabilityInitialDate">
                                            <label for="change-unavailabilityInitialDate">Data e hora inicial</label>
                                            <p-calendar id="change-unavailabilityInitialDate" name="unavailabilityInitialDate" 
                                                        [(ngModel)]="unavailabilityInitialDate" 
                                                        [showTime]="true" dateFormat="dd/mm/yy" [showIcon]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>                            
                                        <div class="column pcalendar-unavailabilityEndDate">
                                            <label for="change-unavailabilityEndDate">Data e hora final</label>
                                            <p-calendar id="change-unavailabilityEndDate" name="unavailabilityEndDate" 
                                                        [(ngModel)]="unavailabilityEndDate" 
                                                        [showTime]="true" dateFormat="dd/mm/yy" [showIcon]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                        <div class="column hours-difference">
                                            <label for="hoursDifference">Tempo total de indisponibilidade</label>
                                            <input type="text" class="form-control" id="hoursDifference" [value]="hoursDifference"
                                                   [disabled]="true">
                                        </div>
                                    </div>
                                    <div class="form-group optional-approver approver-list">
                                        <label for="approver">Documentos anexos</label>
                                        <ng-select [items]="rdmAttachedDocsType" [multiple]="true" 
                                                   [(ngModel)]="rdmAttachedDocsTypeSelected" 
                                                   name="rdmAttachedDocsTypeSelected" 
                                                   #optionalApprover="ngModel"
                                                   [required]="rdmAttachedDocsTypeSelected.length == 0"
                                                   [disabled]="true">
                                            <ng-template ng-label-tmp let-item="item">
                                                <div class="box-selected">
                                                    <span class="ng-value-label ng-star-inserted">
                                                        <b>{{item.attachedDocsType}}</b>
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                                <div class="p-2">
                                                    <b>{{item.attachedDocsType}}</b>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                    <div class="form-group">
                                        <label for="change-othersAttachedDocs">Outras documentos</label>
                                        <textarea id="change-othersAttachedDocs" #OthersAttachedDocs="ngModel" type="text"
                                            name="OthersAttachedDocs" class="form-control textarea"
                                            [(ngModel)]="changeRequestForApprovalDto.othersAttachedDocs" [disabled]="true">
                                        </textarea>
                                    </div>
                                </tab>
                                <tab heading="{{l('Approvers')}}">
                                    <div class="form-group">
                                        <label for="user-approval">{{l("Approvers")}}</label>
                                        <div class="departament-content" *ngFor="let departament of changeRequestForApprovalDto.departamentsList">                                    
                                            <p-panel [header]="'DEP: ' +  departament?.title | uppercase" [toggleable]="true" [collapsed]="true">                                 
                                                <div class="user-content" *ngFor="let user of departament.mandatoryApproverUsers">
                                                    <span class="approver-name text-bold-status">{{ user?.userName}}  
                                                        <i class="far fa-comment-alt approve-status" *ngIf="user?.status !== l('NativeGmudStatus')" 
                                                            [tooltip]="user?.justification != null ? 'Comentário: ' + user?.justification : ''">
                                                        </i>
                                                    </span> 
                                                    <br/>
                                                    <p-timeline [value]="statusApproval" layout="horizontal" styleClass="customized-timeline">
                                                        <ng-template pTemplate="marker" let-approval>
                                                            <span class="custom-marker status-steps shadow-2" [style]="user?.status === approval?.status 
                                                                ? setColorsBackgroundStatusApproval(approval?.status) : 'background-color: #607D8B;'">
                                                                <i [ngClass]="approval?.icon"></i>
                                                            </span>
                                                        </ng-template>
                                                        <ng-template pTemplate="content" let-approval>
                                                            <span [class]="user?.status === approval?.status ? 'text-bold-status text-size-status' : 'text-size-status'">
                                                                {{approval?.status | uppercase}}
                                                            </span>
                                                            <br />
                                                        </ng-template>
                                                    </p-timeline>                                  
                                                </div>
                                            </p-panel>
                                        </div>
                                    </div>
                                </tab>
                                <tab heading="{{l('Anexos')}}">
                                    <div class="form-group">
                                        <div id="anexos-gmud">
                                            <div class="list-head list-row">
                                                <div class="list-item item"><span>Documento</span></div>
                                                <div class="list-item item-action"><span>Ação</span></div>
                                            </div>
                                            <div class="list-body list-row" *ngFor="let anexo of changeRequestForApprovalDto.files; let i = index">
                                                <div class="list-item item"><span>{{anexo.attachmentName}}</span></div>
                                                <div class="list-item item-action">
                                                    <span class="delete-item" (click)="downloadAnexo(anexo)"
                                                    tooltip="{{l('Download do anexo')}}"><i class="fa fa-download"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tab>
                            </tabset>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>