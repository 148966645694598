<div id="createOrEditPeriod" bsModal #createOrEditPeriodModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="createOrEditPeriodModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form #createOrEditPeriodform="ngForm" (ngSubmit)="save()" autocomplete="off">
        <div class="modal-header">
          <h4 class="modal-title">
            <span>{{l("Cadastro de período de freezing")}}</span>
          </h4>
          <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group name-content">
            <div>
              <label for="Name">{{l('Nome')}}</label>
              <input id="name" type="text" name="name" class="form-control" [(ngModel)]="name" #Name="ngModel" required>
            </div>
            <div class="start-date-content">
              <label for="start-date">{{l('Data inicial')}}</label>
              <p-calendar id="start-date" name="startDate" [(ngModel)]="startDate" dateFormat="dd/mm/yy"></p-calendar>
            </div>
            <div class="end-date-content">
              <label for="end-date">{{l('Data final')}}</label>
              <p-calendar id="end-date" name="endDate" [(ngModel)]="endDate" dateFormat="dd/mm/yy"></p-calendar>
            </div>
          </div>
          <div class="form-group">
            <label class="kt-checkbox">
                <input id="recurrent" type="checkbox" name="recurrent" [(ngModel)]="recurrent"> 
                    {{'Recorrente?'}}
                <span></span>
            </label>
        </div>
        </div> 
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
          <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i><span>{{l("Save")}}</span></button>
        </div>
      </form>
    </div>
  </div>
</div>
