import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponent } from './timeline.component';
import { CardModule } from 'primeng/card';
import { TimelineModule } from 'primeng/timeline';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    InputTextareaModule,
    TimelineModule
  ],
  declarations: [TimelineComponent],
  exports: [TimelineComponent]
})
export class TimelineDemandModule { }
