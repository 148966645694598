import { Component, OnInit, Input, Injector, Output, EventEmitter } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { LiberacaoUsuarioCardDto } from '@shared/service-proxies/service-proxies';
import { LinkedAccountService } from '@app/shared/layout/linked-account.service';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'app-card-liberacao-usuario',
  templateUrl: './card-liberacao-usuario.component.html',
  styleUrls: ['./card-liberacao-usuario.component.css']
})
export class CardLiberacaoUsuarioComponent extends AppComponentBase implements OnInit {

  @Input() card: LiberacaoUsuarioCardDto;
  @Output()  useAd: EventEmitter<void> = new EventEmitter<void>();
  defaultLogo = this.appRootUrl() + 'assets/common/images/qa-devops.png';
  remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;

  constructor(
    injector: Injector,
    private _linkedAccountService: LinkedAccountService
  ) {
    super(injector);
  }
  ngOnInit() {
  }
  switchToLinkedUser() {
    if (this.card.validarAd) {
      this.useAd.emit();
      return;
    }
    this._linkedAccountService.switchToAccount(this.card.userId, this.card.tenantId);
  }
}
