import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ChangeRequestForApprovalDto, ChangeRequestServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'justification-modal',
  templateUrl: './justification-modal.component.html',
  styleUrls: ['./justification-modal.component.css']
})

export class JustificationModalComponent extends AppComponentBase {
  @ViewChild('justificationModal', { static: true }) justificationModal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  changeRequestForApprovalDto: ChangeRequestForApprovalDto = new ChangeRequestForApprovalDto;
  approval: boolean;
  reapproval: boolean;
  loading: boolean = false;
  justification: string = '';
  
  constructor(injector: Injector,
    private _changeRequestAppService: ChangeRequestServiceProxy,
  ) {
    super(injector);
  }
  
  ngOnInit(): void { }

  async show(approval: boolean, reapproval: boolean, changeRequestForApprovalDto: ChangeRequestForApprovalDto) {
    this.approval = approval;
    this.reapproval = reapproval;
    this.changeRequestForApprovalDto = changeRequestForApprovalDto;
    this.justificationModal.show();
  }

  close(): void {
    this.justificationModal.hide();
  }

  save() {
    if (this.approval) this.approvalChange(); 
    if (this.reapproval) this.reproveChange();
  }

  approvalChange(): void {  
    this.loading = true;
    this._changeRequestAppService.approveOneOrAllRDMs(this.changeRequestForApprovalDto.changeRequestApprovalId, this.justification)
        .subscribe(res => {
          this.notify.success("RDM aprovada com sucesso.");
          location.reload();
          this.loading = false;
        });
  }

  validateEmptyString(text: string): boolean {
    return text === null || text === undefined || text.trim().length === 0;
  }

  reproveChange(): void {
    this.loading = true;
    this._changeRequestAppService.reproveChangeRequest(this.changeRequestForApprovalDto.changeRequestApprovalId, this.justification)
        .subscribe(res => {
          this.notify.success("RDM reprovada com sucesso.");
          location.reload();
          this.loading = false;
        });
  }
}
