import { Component, EventEmitter, Injectable, Injector, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { LinkedAccountService } from "@app/shared/layout/linked-account.service";
import { AppConsts } from "@shared/AppConsts";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { NpsPerfilUsuarioDto, NpsPerfilUsuarioServiceProxy } from "@shared/service-proxies/service-proxies";
@Component({
    selector: 'indicators',
    templateUrl: './indicators.component.html',
    styleUrls: ['./indicators.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})


export class IndicatorsComponent extends AppComponentBase implements OnInit {
    constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        
    };
}