import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { GestaoMudancaNativaServiceProxy, JanelaDeDeployServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-justify-modal',
  templateUrl: './justify-modal.component.html',
  styleUrls: ['./justify-modal.component.css']
})

export class JustifyModalComponent extends AppComponentBase {
  @ViewChild('justifyModal', { static: true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  justification: string = '';
  requestsGmudUpdates: string[] = [];
  messages: Message[] = [];
  loading: boolean = false;
  approvalOrRepproval: number = 0;
  gmudId: string = '';
  gmud: boolean = false;
  visible: boolean = false;
  isUpdates: boolean = false;
  
  constructor(injector: Injector,
    private route: ActivatedRoute,
    private _sessionGestaoMudancaNativa: GestaoMudancaNativaServiceProxy,
    private _sessionDeployService: JanelaDeDeployServiceProxy,
  ) {
    super(injector);
  }
  
  ngOnInit(): void {
    this.messages = [{ 
      severity: 'warn',
      summary: 'Ver Detalhes!',
      detail: `Houve modificações na demanda enquanto aguardava aprovação.`
    }];
  }
  
  showDialog() {
    this.visible = true;
  }

  async show(approvelOrRepprovel: number, gmudId: string, gmud: boolean) {
    this.gmud = gmud;
    this.approvalOrRepproval = approvelOrRepprovel;
    this.gmudId = gmudId;
    this.modal.show();
  }

  close(): void {
    this.modal.hide();
  }

  save() {
    if (this.approvalOrRepproval === 1)
      this.approvalChange(this.gmudId, false);
    
    if (this.approvalOrRepproval === 2)
      this.reproveChange(this.gmudId, false);
  }

  approvalChange(gmudId: string, index: boolean): void {  
    this.loading = true;
    if (this.gmud){
      this._sessionGestaoMudancaNativa.approveOneOrAll(gmudId, this.justification, index).subscribe(result => {
        if (this.validateEmptyString(result)) {
          location.reload();
        } else {
          this.isUpdates = true;
          let substrings = result.split(';');
          this.requestsGmudUpdates = [];
          substrings.forEach(substring => {
            this.requestsGmudUpdates.push(substring.trim());
          });  
        }
      }).add(() => this.loading = false);
    } else {
      this._sessionDeployService.approveTransport(gmudId).subscribe(result => {
        location.reload();
      }).add(() => this.loading = false);;
    }
  };

  validateEmptyString(text: string): boolean {
    return text === null || text === undefined || text.trim().length === 0;
  }

  reproveChange(gmudId: string, index: boolean): void {
    this.justification = index ? 'Gestão de Mudança reprovada por conta de modificações efetuadas na demanda após solicitação de aprovação da mesma.' : this.justification;
    this.loading = true;
    if (this.gmud){
      this._sessionGestaoMudancaNativa.reproveChangeManagement(gmudId, this.justification).subscribe(x => {
          location.reload();
      }).add(() => this.loading = false);
    } else {
      this._sessionDeployService.repproveTransport(gmudId).subscribe(x => {
          location.reload();
      }).add(() => this.loading = false);
    }
  }; 
}
