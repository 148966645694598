import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { GerenciamentoVersaoSapServiceProxy, GetGerenciamentoVersoesSapDto } from "@shared/service-proxies/service-proxies";
import { CreateVersionamentoComponent } from "./create-versionamento-modal/create-versionamento-modal.component";

@Component({
    selector: 'versionamento-sap',
    templateUrl: './versionamento-sap.component.html',
    styleUrls: ['./versionamento-sap.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class VersionamentoSapComponent extends AppComponentBase implements OnInit {
    @ViewChild('createVersionamentoModal', { static: true }) createVersionamentoModal: CreateVersionamentoComponent;

    loading: boolean = false;

    versionControlList: GetGerenciamentoVersoesSapDto[] = []; 

    constructor(
        injector: Injector,
        private _gerenciamentoVersaoSapServiceProxy: GerenciamentoVersaoSapServiceProxy
    ) {
        super(injector);
    }
    
    ngOnInit(): void {
        this.getVersionControlList();
    }

    createVersionControl(){
        this.createVersionamentoModal.show(null);
    }

    getVersionControlList(){
        this.loading = true;
        this._gerenciamentoVersaoSapServiceProxy.getGerencimentoVersoesSap()
            .subscribe(res => {
                this.loading = false;
                this.versionControlList = res;
            })
    }

    reloadPage(ev: any) {
        this.getVersionControlList();
    }

    getLocaleDateHourNotificacao(dataHora: moment.Moment) {
        const dateString = dataHora ? dataHora.toString() : "";
        return new Date(dateString).toLocaleString();
    }
}