import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { BasicDto, EnumAuthenticationType, CreateOrEditIntegracaoDto, IntegracoesServiceProxy, TokenDto, IntegracaoDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-criar-integracao-modal',
  templateUrl: './criar-integracao-modal.component.html',
  styleUrls: ['./criar-integracao-modal.component.css']
})
export class CriarIntegracaoModalComponent extends AppComponentBase implements OnInit {
  @ViewChild('criarIntegracaoModal', { static: true }) modal: ModalDirective;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  saving: boolean = false;
  integracaoModelo: CreateOrEditIntegracaoDto = new CreateOrEditIntegracaoDto()
  campoAdicionalModelo: { [key: string]: string; } = {}
  camposAdicionais: any = [];
  showForm: boolean = false;
  listaIntegracoes: IntegracaoDto[] = [];

  get enumAuthenticationType() {
    return EnumAuthenticationType;
  }

  get disableExtraFieldButton() {
    return !this.campoAdicionalModelo.label || !this.campoAdicionalModelo.value
  }

  constructor(injector: Injector, private service: IntegracoesServiceProxy) {
    super(injector);
  }

  ngOnInit() {
    this.resetValues();
    this.obterIntegracoes();
  }

  resetValues() {
    this.integracaoModelo = new CreateOrEditIntegracaoDto()
    this.integracaoModelo.basic = new BasicDto();
    this.integracaoModelo.token = new TokenDto();
    this.integracaoModelo.custom = {};
  }


  show(): void {
    this.modal.show();
    this.obterIntegracoes();
  }

  save() {
    this.criarOuEditarIntegracao(this.integracaoModelo);
    this.closed.emit();
  }

  close() {
    this.modal.hide();
    this.resetValues();
    this.showForm = false;
    this.closed.emit();
  }

  adicionarCampoAdicional() {
    const found = this.camposAdicionais.find(item => item.label == this.campoAdicionalModelo.label)
    if (found) {
      this.notify.error(this.l('CampoAdicionalRepetido'));
      return;
    }
    this.integracaoModelo.custom[this.campoAdicionalModelo.label] = this.campoAdicionalModelo.value;
    this.camposAdicionais.push(this.campoAdicionalModelo);
    this.campoAdicionalModelo = {};
  }

  deletarCampoAdicional(campoLabel) {
    delete this.integracaoModelo.custom[campoLabel]
    const found = this.camposAdicionais.find(item => item.label == campoLabel);
    const index = this.camposAdicionais.indexOf(found)
    this.camposAdicionais.splice(index, 1)
  }

  deleteIntegracao(id) {
    this.service.delete(id).subscribe(() => {
      this.obterIntegracoes();
    });
  }

  criarOuEditarIntegracao(integracao) {
    this.service.createOrEdit(integracao).subscribe(result => {
      this.resetValues();
      this.obterIntegracoes();
      this.showForm = false;
      this.notify.success(this.l('SuccessNewIntegration'));
    })
  }

  obterIntegracoes() {
    this.service.getAll().subscribe((result: IntegracaoDto[]) => {
      this.listaIntegracoes = result.map(item => {
        return new IntegracaoDto({
          ...item,
          basic: item.basic || new BasicDto(),
          token: item.token || new TokenDto(),
          custom: item.custom || {}
        })
      });
    });
  }

  setForm(record) {
    this.integracaoModelo = new CreateOrEditIntegracaoDto(record);
    this.showForm = true;
  }

}
