<div [@routerTransition]>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("Editions")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{"EditionsHeaderInfo" | localize}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button *ngIf="'Pages.Editions.Create' | permission" class="btn btn-primary"
                    (click)="createEdition()"><i class="fa fa-plus"></i> {{"CreateNewEdition" | localize}}</button>
            </div>
        </div>
    </div>

    <div class="kt-content">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div class="col primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable (onLazyLoad)="getEditions()" [value]="primengTableHelper.records"
                            rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false" [lazy]="true"
                            [responsive]="primengTableHelper.isResponsive">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 130px;"
                                        [hidden]="!isGrantedAny('Pages.Editions.Edit', 'Pages.Editions.Delete')">
                                        {{'Actions' | localize}}
                                    </th>
                                    <th>
                                        {{'EditionName' | localize}}
                                    </th>
                                    <th>
                                        {{'Price' | localize}}
                                    </th>
                                    <th>
                                        {{'IsTrialActive' | localize}}
                                    </th>
                                    <th>
                                        {{'WaitingDayAfterExpire' | localize}}
                                    </th>
                                    <th>
                                        {{'ExpiringEdition' | localize}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td style="width: 130px;text-align:center">
                                        <div class="btn-group dropdown" dropdown container="body">
                                            <button dropdownToggle class="dropdown-toggle btn btn-sm btn-primary">
                                                <i class="fa fa-cog"></i><span class="caret"></span> {{"Actions" |
                                                localize}}
                                            </button>
                                            <ul class="dropdown-menu" *dropdownMenu>
                                                <li>
                                                    <a href="javascript:;" *ngIf="'Pages.Editions.Edit' | permission"
                                                        (click)="editEdition(record.id)">{{'Edit' | localize}}</a>
                                                </li>

                                                <li>
                                                    <a href="javascript:;" *ngIf="'Pages.Editions.Delete' | permission"
                                                        (click)="deleteEdition(record)">{{'Delete' | localize}}</a>
                                                </li>

                                                <li>
                                                    <a href="javascript:;"
                                                        *ngIf="'Pages.Editions.MoveTenantsToAnotherEdition' | permission"
                                                        (click)="moveTenantsToAnother(record.id)">{{'MoveTenantsToAnotherEdition'
                                                        | localize}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        {{record.displayName}}
                                    </td>
                                    <td>
                                        <span *ngIf="record.monthlyPrice || record.annualPrice">
                                            $ {{record.monthlyPrice | number}} {{"Monthly" | localize }} / $
                                            {{record.annualPrice | number}} {{"Annual" | localize }}
                                        </span>
                                        <span *ngIf="!record.monthlyPrice && !record.annualPrice">
                                            {{"Free" | localize}}
                                        </span>
                                    </td>
                                    <td>
                                        <span *ngIf="record.trialDayCount">
                                            {{"Yes" | localize}}, {{record.trialDayCount}} {{"Days" | localize}}
                                        </span>
                                        <span *ngIf="!record.trialDayCount">
                                            {{"No" | localize}}
                                        </span>
                                    </td>
                                    <td>
                                        {{record.waitingDayAfterExpire}}
                                    </td>
                                    <td>
                                        {{record.expiringEditionDisplayName}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{'NoData' | localize}}
                        </div>
                        <div class="ui-table-footer">
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </div>
            </div>
        </div>
    </div>

    <createEditionModal #createEditionModal (modalSave)="getEditions()"></createEditionModal>
    <editEditionModal #editEditionModal (modalSave)="getEditions()"></editEditionModal>
    <moveTenantsToAnotherEditionModal #moveTenantsToAnotherEditionModal></moveTenantsToAnotherEditionModal>
</div>