import { Injectable, Injector, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class ChatSignalrService extends AppComponentBase {

    constructor(
        injector: Injector,
        public _zone: NgZone
    ) {
        super(injector);
    }

    chatHub: HubConnection;
    isChatConnected = false;

    configureConnection(connection: HubConnection): void {
        // Set the common hub
        this.chatHub = connection;

        // Reconnect loop
        function start() {
            return new Promise(function (resolve, reject) {
                connection.start()
                    .then(() => resolve(null))
                    .catch(() => {
                        setTimeout(() => {
                            start().then(resolve);
                        }, 5000);
                    });
            });
        }

        // Reconnect if hub disconnects
        connection.onclose(e => {
            this.isChatConnected = false;

            if (e) {
                abp.log.debug('Chat connection closed with error: ' + e);
            } else {
                abp.log.debug('Chat disconnected');
            }

            start().then(() => {
                this.isChatConnected = true;
            });
        });

        
        // Register to get notifications
        this.registerChatEvents(connection);
        connection.start().then(() => {
            abp.event.trigger('app.chat.connected');
            this.isChatConnected = true;
        }).catch((e) => {
            console.log(e);
        })
    }

    registerChatEvents(connection): void {
        connection.on('getChatMessage', message => {
            abp.event.trigger('app.chat.messageReceived', message);
        });

        connection.on('getAllFriends', friends => {
            abp.event.trigger('abp.chat.friendListChanged', friends);
        });

        connection.on('getFriendshipRequest', (friendData, isOwnRequest) => {
            abp.event.trigger('app.chat.friendshipRequestReceived', friendData, isOwnRequest);
        });

        connection.on('getUserConnectNotification', (friend, isConnected) => {
            abp.event.trigger('app.chat.userConnectionStateChanged',
                {
                    friend: friend,
                    isConnected: isConnected
                });
        });

        connection.on('getUserStateChange', (friend, state) => {
            abp.event.trigger('app.chat.userStateChanged',
                {
                    friend: friend,
                    state: state
                });
        });

        connection.on('getallUnreadMessagesOfUserRead', friend => {
            abp.event.trigger('app.chat.allUnreadMessagesOfUserRead',
                {
                    friend: friend
                });
        });

        connection.on('getReadStateChange', friend => {
            abp.event.trigger('app.chat.readStateChange',
                {
                    friend: friend
                });
        });
    }

    sendMessage(messageData, callback): void {
        if (!this.isChatConnected) {
            if (callback) {
                callback();
            }

            abp.notify.warn(this.l('ChatIsNotConnectedWarning'));
            return;
        }

        this.chatHub.invoke('sendMessage', messageData).then(result => {
            if (result) {
                abp.notify.warn(result);
            }

            if (callback) {
                callback();
            }
        }).catch(error => {
            abp.log.error(error);

            if (callback) {
                callback();
            }
        });
    }

    init(): void {
        this._zone.runOutsideAngular(() => {
            const connection = new HubConnectionBuilder()
                .withUrl(AppConsts.remoteServiceBaseUrl + '/signalr-chat')
                .build();
            this.configureConnection(connection);
        });
    }
}
