import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ChangeRequestConfigServiceProxy, RdmApproverUsersDto, RdmDepartamentDto, SelectRdmApproverUsers } from "@shared/service-proxies/service-proxies";
import { ModalDirective } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";
import * as uuid from 'uuid';

@Component({
    selector: 'create-edit-departament-modal',
    templateUrl: './create-or-edit-departament-modal.component.html',
    styleUrls: ['./create-or-edit-departament-modal.component.less']
})

export class CreateOrEditDepartamentModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('createEditDepartamentModal', { static: true }) createEditDepartamentModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() saved: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();

    loading: boolean = false;
    saving: boolean = false;
    departament: string;
    departamentId: string;
    users: SelectRdmApproverUsers[] = [];
    usersSelected: SelectRdmApproverUsers[] = [];
    usersApprovalsRdm: RdmApproverUsersDto[] = [];
    userApprovesEverything: boolean = false;

    ngOnInit(): void {
        this.selectApprovalUser();
    }

    constructor(
        injector: Injector,
        private _changeRequestConfigAppService: ChangeRequestConfigServiceProxy
    ) {
        super(injector);
    }    
    
    async show(departamentDto : RdmDepartamentDto, button: boolean) {
        if (button){
            this.departament = '';
            this.usersSelected = [];
            this.departamentId = null;
            departamentDto.id = '';
            this.usersApprovalsRdm = [];

            this.createEditDepartamentModal.show();

            return;
        }

        this.departament = departamentDto.title;
        this.departamentId = departamentDto.id;
        this.userApprovesEverything = departamentDto.userApprovesAll;        

        if (departamentDto.mandatoryApproverUsers != null && departamentDto.mandatoryApproverUsers.length > 0)
        {
            this._changeRequestConfigAppService.getSelectedMandatoryApproverUsers(departamentDto.id)
                .subscribe(x => { this.usersSelected = x; });                
        }

        this.createEditDepartamentModal.show();
    }
    
    close(): void {
        this.createEditDepartamentModal.hide();
        this.closed.emit();
        this.departament = '';
        this.usersApprovalsRdm = [];
        this.usersSelected = [];
        this.userApprovesEverything = false;
    }

    saveDepartament(){
        this.usersSelected.forEach(el => {
            let userApprovalDto = new RdmApproverUsersDto();
            userApprovalDto.id = uuid.v4();
            userApprovalDto.userId = parseInt(el.value);
            this.usersApprovalsRdm.push(userApprovalDto);
        });

        let rdmDepartamentDto = new RdmDepartamentDto();
        rdmDepartamentDto.title = this.departament;
        rdmDepartamentDto.isOptional = false;
        rdmDepartamentDto.userApprovesAll = false;
        rdmDepartamentDto.mandatoryApproverUsers = this.usersApprovalsRdm;
        rdmDepartamentDto.userApprovesAll = this.userApprovesEverything;
        rdmDepartamentDto.optionalApproverUsers = [];

        if (this.departamentId != null || this.departamentId != undefined)
            rdmDepartamentDto.id = this.departamentId;

        this._changeRequestConfigAppService.insertUpdateRdmDepartament(rdmDepartamentDto)
            .pipe(finalize(() => this.loading = false))
            .subscribe(x => {
                this.departament = '';
                this.close();
                this.notify.success(this.l('SavedSuccessfully'));
            });       
    }

    clear(item: SelectRdmApproverUsers) {
        this.usersSelected = [].concat(this.usersSelected.filter(x => x.value !== item.value));
    }

    selectApprovalUser(): void {
        this.usersSelected = [];
        this._changeRequestConfigAppService.getAllUsersForRdmDepartaments()
            .subscribe(x => { this.users = x; });
    }
}