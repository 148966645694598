import { Component, Input, OnInit } from "@angular/core";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-cost-reduction",
    templateUrl: "./cost-reduction.component.html",
    styleUrls: ["./cost-reduction.component.less"],
})
export class CostReductionComponent implements OnInit {
    @Input() tenantId: number = 0;
    urlQuestion1: SafeUrl;
    urlQuestion2: SafeUrl;
    urlQuestion3: SafeUrl;
    currentDate: string = "";
    initDate180DaysAgo: string = "";

    constructor(private domSanitizer: DomSanitizer) {
        this.getDates();
    }

    ngOnInit() {
        this.urlQuestion1 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/69cfc330-37e5-4f50-a2d8-3637588ef9f5?DataInicial=${this.initDate180DaysAgo}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
        this.urlQuestion2 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/28a8dc58-cf2d-4379-a24e-d530d012cbf4?DataInicial=${this.initDate180DaysAgo}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
        this.urlQuestion3 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/6a1aae6e-700c-4efc-9d4a-c548a1826a15?DataInicial=${this.initDate180DaysAgo}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
    }

    getDates() {
        var currentDate = new Date();
        var initDate = new Date();

        initDate.setDate(initDate.getDate() - 180);

        this.currentDate = currentDate.toISOString().slice(0, 10);
        this.initDate180DaysAgo = initDate.toISOString().slice(0, 10);
    }
}
