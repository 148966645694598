import { Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnInit, Output } from '@angular/core';
import { MicrosoftSettingsEditDto } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-microsoft',
  templateUrl: './microsoft.component.html',
  styleUrls: ['./microsoft.component.css']
})
export class MicrosoftComponent implements OnInit {

  @Input() microsoft: MicrosoftSettingsEditDto;
  @Output() microsoftChange: EventEmitter<any> = new EventEmitter();
  private _microsoftDiffer: KeyValueDiffer<string, any>;

  _microsoft: MicrosoftSettingsEditDto;

  constructor(
    private differs: KeyValueDiffers
  ) { }

  ngOnInit() {
    this._microsoft = this.microsoft;
    this._microsoftDiffer = this.differs.find(this._microsoft).create();
  }

  microsoftChanged() {
    this.microsoftChange.emit(this._microsoft)
  }

  ngDoCheck(): void {
    let changes = this._microsoftDiffer.diff(this._microsoft);
    if (changes) {
      this.microsoftChanged();
      return;
    }
    changes = this._microsoftDiffer.diff(this.microsoft);
    if (changes) {
      this._microsoft = this.microsoft;
    }
  }


}
