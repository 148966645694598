import { Component, Injector, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AccountServiceProxy, PasswordComplexitySetting, ProfileServiceProxy, RegisterOutput } from '@shared/service-proxies/service-proxies';
import { LoginService, ExternalLoginProvider } from '../login/login.service';
import { RegisterModel } from './register.model';
import { finalize, catchError } from 'rxjs/operators';



@Component({
    templateUrl: './register.component.html',
    animations: [accountModuleAnimation()]
})
export class RegisterComponent extends AppComponentBase implements OnInit {
    @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

    lang: string;

    model: RegisterModel = new RegisterModel();
    passwordComplexitySetting: PasswordComplexitySetting = new PasswordComplexitySetting();
    recaptchaSiteKey: string = AppConsts.recaptchaSiteKey;
    accessCode: string;

    saving = false;

    constructor(
        injector: Injector,
        private _accountService: AccountServiceProxy,
        private _router: Router,
        public readonly _loginService: LoginService,
        private _profileService: ProfileServiceProxy,
        private _route: ActivatedRoute,
    ) {
        super(injector);

    }


    ngOnInit() {
        this._profileService.getPasswordComplexitySetting().subscribe(result => {
            this.passwordComplexitySetting = result.setting;
        });

        this.addRecaptchaScript();
    }

    get useCaptcha(): boolean {
        return this.setting.getBoolean('App.UserManagement.UseCaptchaOnRegistration');
    }

    save(): void {
        if (this.useCaptcha && !this.model.captchaResponse) {
            this.message.warn(this.l('CaptchaCanNotBeEmpty'));
            return;
        }

        this.saving = true;
        this._accountService.register(this.model)
            .pipe(finalize(() => { this.saving = false; }))
            .pipe(catchError((err, caught): any => {
                //this.recaptchaRef.reset();
            }))
            .subscribe((result: RegisterOutput) => {
                if (!result.canLogin) {
                    this.notify.success(this.l('SuccessfullyRegistered'));
                    this._router.navigate(['account/login'], { state: { accessCode: this.accessCode } });
                    return;
                }

                //Autheticate
                this.saving = true;
                this._loginService.authenticateModel.userNameOrEmailAddress = this.model.userName;
                this._loginService.authenticateModel.password = this.model.password;
                this._loginService.authenticate(() => { this.saving = false; });
            });
    }

    captchaResolved(captchaResponse: string): void {
        this.model.captchaResponse = captchaResponse;
    }

    externalLogin(provider: ExternalLoginProvider) {
        this._loginService.externalAuthenticate(provider);
    }


    renderReCaptch() {
        window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
            'sitekey': this.recaptchaSiteKey,
            'callback': (response) => {
                this.model.captchaResponse = response;
            }
        });
    }

    addRecaptchaScript() {

        if (this.setting.getBoolean('App.UserManagement.UseCaptchaOnRegistration')) {
            window['grecaptchaCallback'] = () => {
                this.renderReCaptch();
            }

            (function (d, s, id, obj) {
                let lg = obj.localization.currentLanguage.name;
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { obj.renderReCaptch(); return; }
                js = d.createElement(s); js.id = id;
                js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit&hl=" + lg;
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'recaptcha-jssdk', this));
        }
    }
}
