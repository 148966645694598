import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    PromptAIEnum,
    PromptAIEnumLabel,
} from "@app/shared/enums/prompt-ai-enum";
import { Dialog } from "primeng/dialog";

@Component({
    selector: "app-documentation-history",
    templateUrl: "./documentation-history.component.html",
    styleUrls: ["./documentation-history.component.less"],
})
export class DocumentationHistoryComponent implements OnInit {
    @ViewChild("dialog") dialog!: Dialog;
    visibleModal: boolean = true;
    promptNumber: number = 0;

    constructor(
        private router: Router,
        private _activatedRoute: ActivatedRoute
    ) {
        this.promptNumber = +this._activatedRoute.snapshot.paramMap.get("id")!;
    }

    ngOnInit() {}

    onClose() {
        this.router.navigateByUrl(`/app/main/prompt-selection`);
    }

    setPromptAISettings(promptAI: PromptAIEnum): string {
        return PromptAIEnumLabel.get(promptAI)!;
    }

    maximizeDialog() {
        if (this.dialog) {
            this.dialog.maximize();
        }
    }
}
