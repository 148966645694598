<div id="justify-modal-nativo" bsModal #justifyModal="bs-modal" class="modal fade" tabindex="-1" 
  role="dialog" aria-labelledby="justifyModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-x">
    <div class="modal-content">
      <form #justitfyform="ngForm" (ngSubmit)="save()" autocomplete="off">
        <div class="modal-header">
          <h4 class="modal-title">
            <span>{{l("Justificativa")}}</span>
          </h4>
          <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-4" *ngIf="isUpdates" style="cursor: pointer">
            <p-messages [(value)]="messages" [enableService]="false" 
              [closable]="false" (click)="showDialog()">
            </p-messages>           
          </div>         
          <div class="row">
            <textarea *ngIf="!loading" name="justification" class="form-control" minlength="5" rows="15"
              maxlength="1000" [(ngModel)]="justification" #justify="ngModel" autofocus required>
            </textarea>
          </div>
          <div id="spinner-content" class="spinner-row">
            <div id="spinner-loading" class="spinner-loading">
              <p-progressSpinner *ngIf="loading"></p-progressSpinner>
            </div>
          </div>
        </div>        
        <div class="modal-footer">         
          <div>
            <button type="button" class="btn btn-default" (click)="close()">
              {{l("Cancel")}}
            </button>
            <button type="submit" class="btn btn-primary" [disabled]="justitfyform.invalid">
              <i class="fa fa-save"></i><span>{{l("Save")}}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<p-dialog header="Requests criadas/alteradas após a criação da GMUD" [(visible)]="visible" [style]="{ width: '40vw' }" 
  [modal]="true" [draggable]="false">
  <div class="flex justify-content-center"> 
    <p *ngFor="let requestsUpdates of requestsGmudUpdates">
      {{requestsUpdates}}
    </p>
  </div>
  <ng-template pTemplate="footer">
    <button class="btn btn-default" (click)="visible = false; reproveChange(gmudId, true)">
      <i class="fa fa-times"></i> Reprovar
    </button>
    <button class="btn btn-primary" (click)="visible = false; approvalChange(gmudId, true)" 
      [disabled]="justitfyform.invalid">
      <i class="fa fa-check"></i> Aprovar
    </button>
  </ng-template>
</p-dialog>