<div [ngClass]="{'subscription-bar-visible': subscriptionStatusBarVisible()}">
    <default-layout *ngIf="theme=='default'"></default-layout>
</div>

<loginAttemptsModal #loginAttemptsModal></loginAttemptsModal>
<changePasswordModal #changePasswordModal></changePasswordModal>
<changeProfilePictureModal #changeProfilePictureModal></changeProfilePictureModal>
<mySettingsModal #mySettingsModal (modalSave)="onMySettingsModalSaved()"></mySettingsModal>
<notificationSettingsModal #notificationSettingsModal></notificationSettingsModal>

<commonLookupModal #userLookupModal (itemSelected)="chatBarComponent.addFriendSelected($event)"></commonLookupModal>
<chat-bar #chatBarComponent *ngIf="!installationMode" [userLookupModal]="userLookupModal"></chat-bar>
<theme-selection-panel #themeSelectionPanelComponent *ngIf="!installationMode && isQuickThemeSelectEnabled"></theme-selection-panel>

<app-portal-evaluation-modal #evaluationModal></app-portal-evaluation-modal>

<app-portal-notifications-modal #portalNotificationsModal></app-portal-notifications-modal>