import { FornecedoresServiceProxy, ClientesFornecedoresServiceProxy } from './../../../../shared/service-proxies/service-proxies';
import { AdicionarFornecedoresComponent } from './adicionar-fornecedores/adicionar-fornecedores.component';
import { ViewResponsaveisComponent } from './view-responsaveis/view-responsaveis.component';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Component, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { TokenAuthServiceProxy, FornecedorDto } from '@shared/service-proxies/service-proxies';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { NotifyService } from 'abp-ng2-module';

@Component({
  selector: 'app-visualizar-fornecedores',
  templateUrl: './visualizar-fornecedores.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [appModuleAnimation()]
})
export class VisualizarFornecedoresComponent extends AppComponentBase {

  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('paginator', { static: true }) paginator: Paginator;
  @ViewChild('viewResponsaveis', { static: true }) viewResponsaveisComponent: ViewResponsaveisComponent;
  @ViewChild('adicionarFornecedor', { static: true }) adicionarFornecedoresComponent: AdicionarFornecedoresComponent;

  nomeFornecedorFiltro = '';
  nomeUsuarioFiltro = '';
  filtroFornecedoresCnpjNome = '';

  constructor(
    injector: Injector,
    private _fornecedoresService: FornecedoresServiceProxy,
    private _clienteFornecedorService: ClientesFornecedoresServiceProxy,
    private _notifyService: NotifyService,
    private _tokenAuth: TokenAuthServiceProxy,
    private _activatedRoute: ActivatedRoute
  ) {
    super(injector);
  }

  getFornecedores(event?: LazyLoadEvent) {
    if (this.primengTableHelper.shouldResetPaging(event)) {
      this.paginator.changePage(0);
      return;
    }

    this.primengTableHelper.showLoadingIndicator();

    this._fornecedoresService.getAllFornecedoresLinked(
      this.filtroFornecedoresCnpjNome,
      this.primengTableHelper.getSorting(this.dataTable),
      this.primengTableHelper.getSkipCount(this.paginator, event),
      this.primengTableHelper.getMaxResultCount(this.paginator, event)
    ).subscribe(result => {
      this.primengTableHelper.totalRecordsCount = result.totalCount;
      this.primengTableHelper.records = result.items;
      this.primengTableHelper.hideLoadingIndicator();
    });

  }

  reloadPage(): void {
    this.paginator.changePage(this.paginator.getPage());
  }

  linkNewProvider(): void {
    this.adicionarFornecedoresComponent.show();
  }

  linkResponsable(): void {

  }

  removeProvider(fornecedor: FornecedorDto): void {
    this.message.confirm('',
      this.l('RemoverFornecedorDialog'),
      (isConfirmed) => {
          if (isConfirmed) {
              this._clienteFornecedorService.delete(fornecedor.id)
                  .subscribe(() => {
                      this.reloadPage();
                      this.notify.success(this.l('Desvinculado'));
                  });
          }
      }
    );
  }
}
