import { Component, ElementRef, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CommonLookupServiceProxy, SubscribableEditionComboboxItemDto, TenantEditDto, TenantServiceProxy, Select2ItemDto, ResponsavelDto, UserServiceProxy } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { ValidatorsLegaisService } from '@shared/utils/validators-legais.service';


@Component({
    selector: 'editTenantModal',
    templateUrl: './edit-tenant-modal.component.html'
})
export class EditTenantModalComponent extends AppComponentBase {

    @ViewChild('nameInput', {static: true}) nameInput: ElementRef;
    @ViewChild('editModal', {static: true}) modal: ModalDirective;
    @ViewChild('SubscriptionEndDateUtc', {static: true}) subscriptionEndDateUtc: ElementRef;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    isUnlimited = false;
    subscriptionEndDateUtcIsValid = false;
    user$ : Select2ItemDto[];
    tenant: TenantEditDto = undefined;
    currentConnectionString: string;
    editions: SubscribableEditionComboboxItemDto[] = [];
    isSubscriptionFieldsVisible = false;
    userSelected: Select2ItemDto[];
    subscriptionEndDateUtcDate: Date;
    cnpjValido = true;
    cnpjUnico = true;
    oldCnpj = "";
    constructor(
        injector: Injector,
        private _tenantService: TenantServiceProxy,
        private _userService: UserServiceProxy,
        private _commonLookupService: CommonLookupServiceProxy,
        private _validatorsLegaisService: ValidatorsLegaisService
    ) {
        super(injector);
    }

    validarCNPJ(): void {
        this.cnpjValido = this._validatorsLegaisService.validarCNPJ(this.tenant.cnpj);
        if(this.tenant.cnpj != this.oldCnpj)
        {
            this._tenantService.isUniqueCNPJ(this.tenant.cnpj).subscribe(res => {
                this.cnpjUnico = res;
            });
        }
        else
            this.cnpjUnico = true;
    }

    show(tenantId: number): void {
         this._userService.getByName('', tenantId).subscribe(x =>
            this.user$ = x
           );
        this.active = true;

        this._commonLookupService.getEditionsForCombobox(false).subscribe(editionsResult => {
            this.editions = editionsResult.items;
            let notSelectedEdition = new SubscribableEditionComboboxItemDto();
            notSelectedEdition.displayText = this.l('NotAssigned');
            notSelectedEdition.value = '';
            this.editions.unshift(notSelectedEdition);

            this._tenantService.getTenantForEdit(tenantId).subscribe((tenantResult) => {
                this.tenant = tenantResult;
                this.currentConnectionString = tenantResult.connectionString;
                this.tenant.editionId = this.tenant.editionId || 0;
                this.isUnlimited = !this.tenant.subscriptionEndDateUtc;
                this.subscriptionEndDateUtcIsValid = this.isUnlimited || this.tenant.subscriptionEndDateUtc !== undefined;
                this.oldCnpj = this.tenant.cnpj;
                this._userService
                    .getByTenantId(tenantId)
                    .subscribe(x => this.userSelected = x
                    );
                    if (this.tenant.subscriptionEndDateUtc) {
                        this.subscriptionEndDateUtcDate = new Date(this.tenant.subscriptionEndDateUtc.year(), this.tenant.subscriptionEndDateUtc.month(), this.tenant.subscriptionEndDateUtc.date());
                    }

                this.modal.show();
                this.toggleSubscriptionFields();
            });
        });
    }

    onShown(): void {
        document.getElementById('Name').focus();

        if (this.tenant.subscriptionEndDateUtc && this.subscriptionEndDateUtc) {
            (this.subscriptionEndDateUtc.nativeElement as any).value = this.tenant.subscriptionEndDateUtc.format('L');
        }
    }

    subscriptionEndDateChange(e): void {
        this.subscriptionEndDateUtcIsValid = e && e.date !== false || this.isUnlimited;
    }

    selectedEditionIsFree(): boolean {
        if (!this.tenant.editionId) {
            return true;
        }

        let selectedEditions = _.filter(this.editions, { value: this.tenant.editionId + '' });
        if (selectedEditions.length !== 1) {
            return true;
        }

        let selectedEdition = selectedEditions[0];
        return selectedEdition.isFree;
    }

    save(): void {
        this.saving = true;

        for (let i = 0; i < this.userSelected.length; i++) {
            var responsavelDto = new ResponsavelDto();
            
            responsavelDto.userId = parseInt(this.userSelected[i].value);
            responsavelDto.tenantId = this.tenant.id;
            this.tenant.responsaveis.push(responsavelDto);
        }
        
        if (this.tenant.editionId === 0) {
            this.tenant.editionId = null;
        }

        if (this.subscriptionEndDateUtcDate) {
            this.tenant.subscriptionEndDateUtc = moment(this.subscriptionEndDateUtcDate);
        }

        //take selected date as UTC
        if (this.isUnlimited || !this.tenant.editionId) {
            this.tenant.subscriptionEndDateUtc = null;
        }



        this._tenantService.updateTenant(this.tenant)
            .pipe(finalize(() => this.saving = false))
            .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
            });
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    onEditionChange(): void {
        if (this.selectedEditionIsFree()) {
            this.tenant.isInTrialPeriod = false;
        }

        this.toggleSubscriptionFields();
    }

    onUnlimitedChange(): void {
        if (this.isUnlimited) {
            this.tenant.subscriptionEndDateUtc = null;
            this.subscriptionEndDateUtcIsValid = true;
        } else {
            if (!this.tenant.subscriptionEndDateUtc) {
                this.subscriptionEndDateUtcIsValid = false;
            }
        }
    }

    toggleSubscriptionFields() {
        if (this.tenant.editionId > 0) {
            this.isSubscriptionFieldsVisible = true;
        } else {
            this.isSubscriptionFieldsVisible = false;
        }
    }
}
