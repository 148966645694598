<div bsModal #buildTrackingModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="buildTrackingModal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{ l("BuildTracking") }}</span>
                </h4>
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <button (click)="getBuildTracking()" class="btn btn-primary blue">
                    <i *ngIf="loading" class="fas fa-sync-alt fa-spin"></i>
                    <i *ngIf="!loading" class="fas fa-sync-alt"></i>
                    {{l("Update")}}
                </button>
                <div *ngFor="let item of steps" class="d-flex justify-content-between align-items-center mt-3">
                    <p class="build-tracking-text col-5">{{getTextByEvent(item.evento) | localize}}</p>
                    <p class="build-tracking-text col-6">{{getLocaleDateHour(item.timestamp)}}</p>
                    <span
                        [class]="getColorByStatus(item.status) + ' rounded-circle d-flex justify-content-center align-items-center icon-circle'">
                            <i [class]="getIconByStatus(item.status) + ' fas text-white'" 
                               tooltip="{{item.errorMessage}}"></i>
                    </span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary blue" (click)="close()">
                    {{ l("Close") }}
                </button>
            </div>
        </div>
    </div>
</div>