import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ChangeRequestServiceProxy, RescheduleChangeRequestDto } from "@shared/service-proxies/service-proxies";
import moment from "moment";
import { ModalDirective } from "ngx-bootstrap/modal";


@Component({
    selector: 'reschedule-change-request-modal',
    templateUrl: './reschedule-change-request-modal.component.html',
    styleUrls: ['./reschedule-change-request-modal.component.less']
})

export class RescheduleChangeRequestModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('rescheduleChangeRequestModal', { static: true }) rescheduleChangeRequestModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() saved: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();

    loading: boolean = false;
    saving: boolean = false;
    rescheduleDate: Date = new Date;
    changeRequestId: string;

    ngOnInit(): void {}

    constructor(
        injector: Injector,
        private _changeRequestAppService: ChangeRequestServiceProxy,
    ) {
        super(injector);
    }    
    
    async show(changeRequestId: string) {
        this.loading = false;
        this.rescheduleDate = null;
        this.changeRequestId = changeRequestId;
        this.rescheduleChangeRequestModal.show();
    }
    
    close(): void {
        this.rescheduleDate = new Date;
        this.rescheduleChangeRequestModal.hide();
        this.closed.emit();
    }

    saveRescheduleChangeRequest() {
        this.loading = true;
        let rescheduleChangeRequestDto: RescheduleChangeRequestDto = new RescheduleChangeRequestDto;
        rescheduleChangeRequestDto.changeRequestId = this.changeRequestId;
        rescheduleChangeRequestDto.rescheduleDate = moment(this.rescheduleDate);

        this._changeRequestAppService.rescheduleChangeRequest(rescheduleChangeRequestDto)
            .subscribe(res => {
                if (res == ""){
                    this.notify.success('RDM salvo com sucesso.');
                    this.loading = false;
                    this.close();
                }else {
                    this.notify.warn(res);
                    this.close();
                }
            });
    }
}