import { Component, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ConfiguracaoAreaUsuarioServiceProxy, DadosParaPerfilUsuarioDto } from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'basic-information',
    templateUrl: './basicInformation.component.html',
    styleUrls: ['./basicInformation.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class BasicInformationComponent extends AppComponentBase implements OnInit {

    foto = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    dadosParaPerfilUsuario: DadosParaPerfilUsuarioDto = new DadosParaPerfilUsuarioDto;

    constructor(
        injector: Injector,
        private _ConfiguracaoAreaUsuarioServiceProxy: ConfiguracaoAreaUsuarioServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getDadosPerfilUsuario();
    }

    getDadosPerfilUsuario(): void {
        this._ConfiguracaoAreaUsuarioServiceProxy.getDadosParaPerfilUsuario(this.appSession.userId).subscribe(result => {
            if (result && result.foto.profilePicture) this.foto = 'data:image/jpeg;base64,' + result.foto.profilePicture;
            this.dadosParaPerfilUsuario.nomeUsuario = result.nomeUsuario;
            this.dadosParaPerfilUsuario.cargoUsuario = result.cargoUsuario == null ? "": result.cargoUsuario;
        });
    }
}