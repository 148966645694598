import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
//import { ItemAmbienteComponent } from '@app/admin/ambientes/ambientes/item-ambiente/item-ambiente.component';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AvaliacaoPortalServiceProxy, AvaliacaoPortalDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-portal-evaluation-modal',
    templateUrl: './portal-evaluation-modal.component.html',
    styleUrls: ['./portal-evaluation-modal.component.css'],
})
export class PortalEvaluationModal extends AppComponentBase implements OnInit {
    @ViewChild('modal', { static: true }) modal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    score: number;
    score2: number;
    score3: number;
    observacaoText: string = '';
    isGerente: boolean;

    starsGeneric: any[] = new Array(10).fill(0).map((item, index) => {
        return {
            position: index + 1,
            fill: false
        }
    });
    starsRecomendation: any[] = new Array(10).fill(0).map((item, index) => {
        return {
            position: index + 1,
            fill: false
        }
    });
    starsService: any[] = new Array(10).fill(0).map((item, index) => {
        return {
            position: index + 1,
            fill: false
        }
    });
    starsServiceSolution: any[] = new Array(10).fill(0).map((item, index) => {
        return {
            position: index + 1,
            fill: false
        }
    });
    isHover = false;

    get canSave() {
        return !this.starsGeneric.some((item) => item.fill) && (!this.starsRecomendation.some((item) => item.fill) || !this.starsService.some((item) => item.fill) || !this.starsServiceSolution.some((item) => item.fill));
    }

    constructor(injector: Injector, private _service: AvaliacaoPortalServiceProxy, private _router: Router) {
        super(injector);
    }

    ngOnInit(): void {
        this.verificaGerente(this.appSession.userId);
    }

    show(): void {
        this.modal.show();
    }

    verificaGerente(gerenteId: number){
        gerenteId = this.appSession.userId;
        this._service.getIdentificaGestor(gerenteId).subscribe(res => {
            this.isGerente = res;
            return this.isGerente;
        });
    }

    validaObservacao() {
        if (!this.observacaoText.length || this.observacaoText.length >= 150) {
            return true;
        }else {
            this.notify.warn(this.l("Campo de observação inválido. Digite no mínimo 150 caracteres."))
            return false;
        }
    }

    selectGeneric(position: number) {
        this.score = position;
        this.starsGeneric = this.starsGeneric.map(item => {
            return {
                position: item.position,
                fill: item.position <= position
            }
        });
    }

    selectRecomendation(position: number) {
        this.score = position;
        this.starsRecomendation = this.starsRecomendation.map(item => {
            return {
                position: item.position,
                fill: item.position <= position
            }
        });
    }

    selectService(position: number) {
        this.score2 = position;
        this.starsService = this.starsService.map(item => {
            return {
                position: item.position,
                fill: item.position <= position
            }
        });
    }

    selectServiceSolution(position: number) {
        this.score3 = position;
        this.starsServiceSolution = this.starsServiceSolution.map(item => {
            return {
                position: item.position,
                fill: item.position <= position
            }
        });
    }

    save() {
        const body: AvaliacaoPortalDto = new AvaliacaoPortalDto({
            nota: this.score,
            nota2: this.score2,
            nota3: this.score3,
            observacao: this.observacaoText,
            userId: this.appSession.userId
        });
        if (this.validaObservacao()) {
            this._service.addAvaliacaoPortal(body).subscribe(() => {
                this.modal.hide();
                this.notify.success(this.l("Avaliação enviada com sucesso."));
                this.observacaoText = ''
            });
                this._router.navigate(["/app/main/myProfile"]);
        }
    }

    close(): void {
        const body: AvaliacaoPortalDto = new AvaliacaoPortalDto({
            nota: 0,
            nota2: 0,
            nota3: 0,
            observacao: '',
            userId: this.appSession.userId
        });
            
        this._service.addAvaliacaoPortal(body).subscribe(() => {
                this.modal.hide();
            });
                
        this._router.navigate(["/app/main/myProfile"]);


        /*
        this.modal.hide();
        this.closed.emit();
        this._router.navigate(["/app/main/myProfile"]);
        */
    }
}