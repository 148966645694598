import { Component, Input, OnInit } from "@angular/core";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-gmud",
    templateUrl: "./gmud.component.html",
    styleUrls: ["./gmud.component.less"],
})
export class GmudComponent implements OnInit {
    @Input() tenantId: number = 0;
    urlQuestion1: SafeUrl;
    urlQuestion2: SafeUrl;
    currentDate: string = "";
    initDate180DaysAgo: string = "";

    constructor(private domSanitizer: DomSanitizer) {
        this.getDates();
    }

    ngOnInit() {
        if (this.tenantId != 5)
            this.urlQuestion1 =
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    `https://app1.qametrik.com/public/question/75b57837-5344-4c86-aadc-ce3a50e90564?DataInicial=${this.initDate180DaysAgo}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
                );
        else
            this.urlQuestion1 =
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    `https://app1.qametrik.com/public/question/9124c9d8-b665-4b88-9a78-b06738917252?DataInicial=${this.initDate180DaysAgo}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
                );

        this.urlQuestion2 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/07a80ae5-73c5-4a77-936e-c167ecefb7d8?DataInicial=${this.initDate180DaysAgo}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
    }

    getDates() {
        var currentDate = new Date();
        var initDate = new Date();

        initDate.setDate(initDate.getDate() - 180);

        this.currentDate = currentDate.toISOString().slice(0, 10);
        this.initDate180DaysAgo = initDate.toISOString().slice(0, 10);
    }
}
