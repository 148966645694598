<div [@routerTransition] class="overflow-auto">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{ l("KanbanView") }}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <button *ngIf="isGranted('Pages.Demandas.Create')" routerLink="/app/main/demandas/demandas"
                class="btn btn-outline-success">
                <i class="fa fa-list"></i> {{ l("ListView") }}
            </button>
            <span class="kt-subheader__separator kt-subheader__separator--v" *ngIf="connectionSap"></span>
            <span class="connection-sap clickable" tooltip="{{'Clique para testar a conexão.' | localize}}" 
                  *ngIf="connectionSap" (click)="getConnectionSap()"> {{ l("Ambiente SAP sem conexão!") }}
                <i class="fas fa-exclamation-circle"></i>
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button *ngIf="isGranted('Pages.Demandas.Create')" (click)="createDemanda()"
                    class="btn btn-primary blue">
                    <i class="fa fa-plus"></i> {{ l("CreateNewDemanda") }}
                </button>
            </div>
        </div>
    </div>

    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body box-card">
                <form class="kt-form" autocomplete="off" (submit)="getDemandas()">
                    <div>
                        <div class="row align-items-center">
                            <div class="col-xl-12" style="height: 35px;">
                                <div class="form-group m-form__group align-items-center">
                                    <div class="input-group">
                                        <input [(ngModel)]="filterText" name="filterText" autoFocus
                                            class="form-control m-input" [placeholder]="l('TitleDescriptionSearch')"
                                            type="text">
                                        <span class="input-group-btn">
                                            <button (click)="getDemandas()" class="btn btn-primary" type="submit">
                                                <i class="flaticon-search-1"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4 bg-white pl-3 pr-3 m-0" [hidden]="!advancedFiltersAreShown">
                            <div class="col-md-12">
                                <div class="kt-separator kt-separator--border-dashed"></div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="TipoFilterSelect">{{l("Tipo")}}</label>
                                            <select class="form-control" name="TipoFilter" id="TipoFilterSelect"
                                                [(ngModel)]="tipoFilter">
                                                <option value="-1">{{l("All")}}</option>
                                                <option value="0">{{l('Projeto')}}</option>
                                                <option value="1">{{l('Suporte')}}</option>
                                                <option value="2">{{l('Configuracao')}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="ModuloFilter">{{l("Descricao")}}</label>
                                            <input type="text" class="form-control" id="descricaoFilter"
                                                name="descricaoFilter" [(ngModel)]="descricaoFilter">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="ReferenciasFilter">{{l("Referencias")}}</label>
                                            <input type="text" class="form-control" id="ReferenciasFilter"
                                                name="referenciasFilter" [(ngModel)]="referenciasFilter">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="StatusFilterSelect">{{l("Status")}}</label>
                                            <select class="form-control" name="StatusFilter" id="StatusFilterSelect"
                                                [(ngModel)]="statusFilter">
                                                <option value="-1">{{l("All")}}</option>
                                                <option value="0">{{l('Backlog')}}</option>
                                                <option value="1">{{l('Desenvolvimento')}}</option>
                                                <option value="2">{{l('Homologacao')}}</option>
                                                <option value="3">{{l('Producao')}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 ">
                                        <div class="form-group">
                                            <label for="UserNameFilter">{{l("IdDaDemanda")}}</label>
                                            <input type="text" class="form-control" id="DemandaIdFilter"
                                                name="demandaIdFilter" [(ngModel)]="demandaIdFilter" mask="99999999">
                                        </div>
                                    </div>
                                    <div class="col-md-3 ">
                                        <div class="form-group">
                                            <label
                                                for="MinPrevisaoTerminoHomologFilter">{{l("PrevisaoTerminoHomologRange")}}</label>
                                            <div class="input-group">
                                                <input class="form-control m-input" id="MinPrevisaoTerminoHomologFilter"
                                                    name="MinPrevisaoTerminoHomologFilter" type="datetime" bsDatepicker
                                                    placeholder="{{l('MinValue')}}"
                                                    [(ngModel)]="minPrevisaoTerminoHomologFilter">
                                                <span style="line-height: 40px;padding: 0 15px 0 15px;"> &mdash; </span>
                                                <input class="form-control m-input" id="MaxPrevisaoTerminoHomologFilter"
                                                    name="MaTIpoxPrevisaoTerminoHomologFilter" type="datetime"
                                                    bsDatepicker placeholder="{{l('MaxValue')}}"
                                                    [(ngModel)]="maxPrevisaoTerminoHomologFilter">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 ">
                                        <div class="form-group">
                                            <label for="UserNameFilter">{{l("Envolvidos")}}</label>
                                            <ng-select [items]="users" [multiple]="true" [(ngModel)]="usersSelected"
                                                name="usersSelected">
                                                <ng-template ng-label-tmp let-item="item">
                                                    <div class="p-2">
                                                        <span class="ng-value-icon left" style="vertical-align: middle;"
                                                            (click)="clear(item)" aria-hidden="true">×</span>
                                                        <span class="ng-value-label ng-star-inserted">
                                                            <b>{{item.label}}</b>
                                                            <div style="font-size: 12px;">{{item.cargo}}</div>
                                                            <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                                        </span>
                                                    </div>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                                    <div class="p-2">
                                                        <b>{{item.label}}</b>
                                                        <div style="font-size: 12px;">{{item.cargo}}</div>
                                                        <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-3 ">
                                        <div class="form-group">
                                            <label for="change-number">{{l("ChangeNumber")}}</label>
                                            <input type="text" class="form-control" id="change-number"
                                                name="ChangeNumber" [(ngModel)]="numeroGestaoMudanca">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="filter-request">{{l("FilterRequestPrd")}}</label>
                                            <input type="text" class="form-control" id="filter-request"
                                                name="FilterRequest" [(ngModel)]="request">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="filter-moduloSap">Módulo sap</label>
                                            <ng-select [items]="moduloSap" [multiple]="true"
                                            [(ngModel)]="moduloSapSelected" name="moduloSapSelected">
                                            <ng-template ng-label-tmp let-item="item">
                                                <div class="p-2">
                                                    <span class="ng-value-icon left" style="vertical-align: middle;"
                                                        (click)="clearModuloSap(item)" aria-hidden="true">×</span>
                                                    <span class="ng-value-label ng-star-inserted">
                                                        <div style="font-size: 12px;">{{item.displayName}}</div>
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                                <div class="p-2">
                                                    <div style="font-size: 12px;">{{item.displayName}}</div>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="filter-projeto">Projeto</label>
                                            <ng-select [items]="project" [multiple]="true"
                                                [(ngModel)]="projectsSelected" name="projectsSelected">
                                                <ng-template ng-label-tmp let-item="item">
                                                    <div class="p-2">
                                                        <span class="ng-value-icon left" style="vertical-align: middle;"
                                                            (click)="clearProjects(item)" aria-hidden="true">×</span>
                                                        <span class="ng-value-label ng-star-inserted">
                                                            <div style="font-size: 12px;">{{item.nome}}</div>
                                                        </span>
                                                    </div>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                                    <div class="p-2">
                                                        <div style="font-size: 12px;">{{item.nome}}</div>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>


                                    <!-- <div class="col-md-3">

                            </div> -->
                                </div>
                            </div>

                        </div>
                        <div class="row mt-4 mb-4">
                            <div class="col-sm-12 mb-2 col-md-6 pt-1">
                                <span class="clickable-item text-muted" *ngIf="!advancedFiltersAreShown"
                                    (click)="advancedFiltersAreShown=!advancedFiltersAreShown">
                                    <i class="fa fa-angle-down"></i> {{l("ShowAdvancedFilters")}}
                                </span>
                                <span class="clickable-item text-muted" *ngIf="advancedFiltersAreShown"
                                    (click)="advancedFiltersAreShown=!advancedFiltersAreShown">
                                    <i class="fa fa-angle-up"></i> {{l("HideAdvancedFilters")}}
                                </span>
                            </div>
                            <div class="col-sm-12 col-md-6 text-right">
                                <button (click)="resetarFiltros()" name="clean" class="btn btn-secondary">
                                    {{'Reset' | localize}}
                                </button>
                                <button type="submit" name="RefreshButton" class="btn btn-primary">
                                    <i class="fa fa-sync"></i> {{'Refresh' | localize}}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row">
                    <div class="col-md-5"></div>
                    <div class="col-md-2">
                        <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                    </div>
                    <div class="col-md-5"></div>
                </div>
                <div cdkDropListGroup *ngIf="!loading" class="row m-0 bg-white p-3 pb-4">
                    <div class="col-md-3">
                        <div class="example-container-0">
                            <span class="head-line">{{l('Backlog')}}</span>
                            <div class="box-list" #backlogList="cdkDropList" (cdkDropListDropped)="drop($event)"
                                [cdkDropListConnectedTo]="[devList]" [cdkDropListData]="cardsBacklog" cdkDropList id="0">
                                <boxKanban *ngFor="let item of cardsBacklog" [card]="item" cdkDrag style="border-radius: 1rem;"
                                    [ngClass]="{'cdk-drag-disabled': item.disabled}" (reloadPage)="reloadPage()"
                                    (openModal)="emitEventOpenModal($event)" [cdkDragDisabled]="item.disabled">
                                </boxKanban>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="example-container-1">
                            <span class="head-line">{{l('Desenvolvimento')}}</span>
                            <div class="box-list" #devList="cdkDropList" (cdkDropListDropped)="drop($event)"
                                [cdkDropListConnectedTo]="[qaList]" [cdkDropListData]="cardsDesenvolvimento" id="1"
                                cdkDropList>
                                <boxKanban *ngFor="let item of cardsDesenvolvimento" [card]="item" style="border-radius: 1rem;"
                                    [ngClass]="{'cdk-drag-disabled': item.disabled}" [cdkDragDisabled]="item.disabled"
                                    cdkDrag (openModal)="emitEventOpenModal($event)" (reloadPage)="reloadPage()"
                                    (buildTracking)="goToBuildTracking($event)">
                                </boxKanban>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="example-container-2">
                            <span class="head-line">{{l('Homologacao')}}</span>
                            <div class="box-list" #qaList="cdkDropList" (cdkDropListDropped)="drop($event)"
                                [cdkDropListConnectedTo]="[prodList]" [cdkDropListData]="cardsQa" cdkDropList id="2">
                                <boxKanban *ngFor="let item of cardsQa" [card]="item" cdkDrag style="border-radius: 1rem;"
                                    [ngClass]="{'cdk-drag-disabled': item.disabled}" [cdkDragDisabled]="item.disabled"
                                    (openModal)="emitEventOpenModal($event)" (buildTracking)="goToBuildTracking($event)">
                                </boxKanban>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="example-container-3">
                            <span class="head-line">{{l('Producao')}}</span>
                            <div class="box-list" #prodList="cdkDropList" (cdkDropListDropped)="drop($event)"
                                [cdkDropListData]="cardsProducao" cdkDropList id="3">
                                <boxKanban *ngFor="let item of cardsProducao" [card]="item" style="border-radius: 1rem;"
                                    [ngClass]="{'cdk-drag-disabled': item.disabled}" [cdkDragDisabled]="item.disabled"
                                    cdkDrag (reloadPage)="reloadPage($event)" (openModal)="emitEventOpenModal($event)">
                                </boxKanban>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <createOrEditDemandaModal #createOrEditDemandaModal (modalSave)="getDemandas()"></createOrEditDemandaModal>
    <app-create-request-modal #createRequestModal
        (detalhesAdicionado)="createRequest($event)"></app-create-request-modal>
    <kanBanAmbienteModal #kanBanAmbienteModal (detalhesAdicionado)="detalhesAdicionado($event)"></kanBanAmbienteModal>
    <entityTypeHistoryModal #entityTypeHistoryModal></entityTypeHistoryModal>
    <create-change-manager-native-modal #createChangeManagerNative (saved)="alterarStatusDaDemanda($event, null)"
        (closed)="reloadPage($event)"></create-change-manager-native-modal>
    <app-create-change-manager-modal #createChangeManager (saved)="alterarStatusDaDemanda($event, null)"
        (closed)="reloadPage($event)" (manualChange)="openManualChangeModal($event)"></app-create-change-manager-modal>
    <app-create-manual-change-modal #createManualChange (saved)="alterarStatusDaDemanda($event, null)"
        (closed)="reloadPage($event)"></app-create-manual-change-modal>
    <app-build-tracking-modal #buildTrackingModal></app-build-tracking-modal>
</div>