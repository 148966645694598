import { VisualizarFornecedoresComponent } from './fornecedores/visualizar-fornecedores/visualizar-fornecedores.component';
import { NgModule } from '@angular/core';
import { MinhasSenhasComponent } from './minhasSenhas/minhasSenhas/minhasSenhas.component';
import { AmbientesComponent } from './ambientes/ambientes/ambientes.component';
import { ModulosComponent } from './modulos/modulos/modulos.component';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { FornecedoresComponent } from './fornecedores/fornecedores/fornecedores.component';
import { CargosComponent } from './cargos/cargos/cargos.component';
import { AuditLogsComponent } from './audit-logs/audit-logs.component';
import { HostDashboardComponent } from './dashboard/host-dashboard.component';
import { EditionsComponent } from './editions/editions.component';
import { InstallComponent } from './install/install.component';
import { LanguageTextsComponent } from './languages/language-texts/language-texts.component';
import { LanguagesComponent } from './languages/languages.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { RolesComponent } from './roles/roles.component';
import { HostSettingsComponent } from './settings/host-settings/host-settings.component';
import { TenantSettingsComponent } from './settings/tenant-settings/tenant-settings.component';
import { InvoiceComponent } from './subscription-management/invoice/invoice.component';
import { SubscriptionManagementComponent } from './subscription-management/subscription-management.component';
import { TenantsComponent } from './tenants/tenants.component';
import { UsersComponent } from './users/users.component';
import { TrainingVideosComponent } from './training-videos/training-videos.component';
import { SuporteComponent } from './suporte/suporte.component';
import { ExternalIntegrationComponent } from './external-integration/external-integration.component';
import { from } from 'rxjs';
import { UploadDashboardFileComponent } from './upload-dashboard-file/upload-dashboard-file.component';
import { ChangeManagerComponent } from './change-manager/change-manager.component';
import { DocumentationComponent } from "./documentation/documentation.component"
import { NotificacaoPortalComponent } from './notificacao-portal/notificacao-portal.component';
import { VersionamentoSapComponent } from './versionamento/versionamento-sap.component';
import { ConfigurationStmsImportComponent } from './stms-import/stms-import.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                children: [
                    { path: 'minhas-senhas/minhas-senhas', component: MinhasSenhasComponent, data: { permission: 'Pages.Administration.MinhasSenhas' }  },
                    { path: 'ambientes/ambientes', component: AmbientesComponent, data: { permission: 'Pages.Administration.Ambientes' }  },
                    { path: 'modulos/modulos', component: ModulosComponent, data: { permission: 'Pages.Administration.Modulos' }  },
                    { path: 'fornecedores/fornecedores', component: FornecedoresComponent, data: { permission: 'Pages.Administration.Fornecedores' }  },
                    { path: 'fornecedores/view-fornecedores', component: VisualizarFornecedoresComponent, data: { permission: 'Pages.Administration.Fornecedores.Tenant' }  },
                    { path: 'cargos/cargos', component: CargosComponent, data: { permission: 'Pages.Administration.Cargos' }  },
                    { path: 'users', component: UsersComponent, data: { permission: 'Pages.Administration.Users' } },
                    { path: 'roles', component: RolesComponent, data: { permission: 'Pages.Administration.Roles' } },
                    { path: 'auditLogs', component: AuditLogsComponent, data: { permission: 'Pages.Administration.AuditLogs' } },
                    { path: 'maintenance', component: MaintenanceComponent, data: { permission: 'Pages.Administration.Host.Maintenance' } },
                    { path: 'hostSettings', component: HostSettingsComponent, data: { permission: 'Pages.Administration.Host.Settings' } },
                    { path: 'editions', component: EditionsComponent, data: { permission: 'Pages.Editions' } },
                    { path: 'languages', component: LanguagesComponent, data: { permission: 'Pages.Administration.Languages' } },
                    { path: 'languages/:name/texts', component: LanguageTextsComponent, data: { permission: 'Pages.Administration.Languages.ChangeTexts' } },
                    { path: 'tenants', component: TenantsComponent, data: { permission: 'Pages.Tenants' } },
                    { path: 'subscription-management', component: SubscriptionManagementComponent, data: { permission: 'Pages.Administration.Tenant.SubscriptionManagement' } },
                    { path: 'invoice/:paymentId', component: InvoiceComponent, data: { permission: 'Pages.Administration.Tenant.SubscriptionManagement' } },
                    { path: 'tenantSettings', component: TenantSettingsComponent, data: { permission: 'Pages.Administration.Tenant.Settings' } },
                    //{ path: 'hostDashboard', component: HostDashboardComponent, data: { permission: 'Pages.Administration.Host.Dashboard' } },
                    { path: 'install', component: InstallComponent },
                    { path: 'training-videos/:playlist', component: TrainingVideosComponent, data: { permission: 'Pages.Administration.TrainingVideos' } },
                    { path: 'suporte', component: SuporteComponent, data: { permission: 'Pages.Administration.Support' } },
                    { path: 'external-intregrations', component: ExternalIntegrationComponent, data: { permission: 'Pages.Administration.Tenant.Settings' } },
                    { path: 'updload-dashboard-file', component: UploadDashboardFileComponent, data: { permission: 'Pages.Administration.Tenant.Settings' } },
                    { path: 'change-manager', component: ChangeManagerComponent, data: { permission: 'Pages.Administration.Tenant.Settings' } },
                    { path: 'documentation', component: DocumentationComponent, data: { permission: 'Pages.Administration.Host.Documentation' } },
                    { path: 'system-notifications', component: NotificacaoPortalComponent, data: { permission: 'Pages.Administration.Host.Documentation' } },
                    { path: 'system-version-control', component: VersionamentoSapComponent, data: { permission: 'Pages.Administration.Host.Documentation' } },
                    { path: 'stms-import', component: ConfigurationStmsImportComponent, data: { permission: 'Pages.Administration.Tenant.Settings' } }
                ]
            }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class AdminRoutingModule {

    constructor(
        private router: Router
    ) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                window.scroll(0, 0);
            }
        });
    }
}
