import { Component, OnInit } from "@angular/core";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-results-obtained",
    templateUrl: "./results-obtained.component.html",
    styleUrls: ["./results-obtained.component.less"],
})
export class ResultsObtainedComponent implements OnInit {
    urlPanel: SafeUrl;
    constructor(private readonly domSanitizer: DomSanitizer) {}

    ngOnInit() {
        this.urlPanel = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/dashboard/57f8313c-f09b-44e2-8865-a8531c9bf487#theme=transparent`
        );
    }
}
