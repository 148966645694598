<div class="kt-login__form" [@routerTransition]>
    <div class="kt-login__title">
        <h3 class="m-0">
            {{ 'ChangePassword' | localize }}
        </h3>
    </div>

    <form #resetPassForm="ngForm" class="kt-form" method="post" (ngSubmit)="save()">
        <p>
            {{"PleaseEnterYourNewPassword" | localize}}
        </p>

        <div class="form-group">
            <input type="password" 
                name="Password" 
                class="form-control placeholder-no-fix" 
                [(ngModel)]="model.password" 
                #Password="ngModel" 
                placeholder="{{'Password' | localize}}" 
                validateEqual="PasswordRepeat"
                reverse="true" 
                [notAllowSequencing]="passwordComplexitySetting.notAllowSequencingOfNumbersAndLetters"
                [requireDigit]="passwordComplexitySetting.requireDigit" 
                [requireLowercase]="passwordComplexitySetting.requireLowercase"
                [requireUppercase]="passwordComplexitySetting.requireUppercase" 
                [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric" 
                [requiredLength]="passwordComplexitySetting.requiredLength"
                [ngClass]="{'is-invalid': Password.invalid, 'is-valid': !Password.invalid}" 
                required>
                <validation-messages [formCtrl]="Password"></validation-messages>
        </div>
        <div [hidden]="resetPassForm.form.valid || resetPassForm.form.pristine" class="form-group">
            <ul class="form-text text-danger" *ngIf="Password.errors">
                <li [hidden]="!Password.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                <li [hidden]="!Password.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                <li [hidden]="!Password.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                <li [hidden]="!Password.errors.requireNonAlphanumeric">{{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                <li [hidden]="!Password.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" | localize:passwordComplexitySetting.requiredLength}}</li>
                <li [hidden]="!Password.errors.notAllowSequencing">
                    {{"NotAllowSequencingOfNumbersAndLettersHint" | localize}}
                </li>
            </ul>
        </div>

        <div class="form-group m-form__group">
            <input type="password" 
                name="PasswordRepeat" 
                class="form-control placeholder-no-fix" 
                [ngModel]="model.passwordRepeat" 
                #PasswordRepeat="ngModel" 
                placeholder="{{'PasswordRepeat' | localize}}" 
                validateEqual="Password"
                reverse="false" 
                [requireDigit]="passwordComplexitySetting.requireDigit" 
                [requireLowercase]="passwordComplexitySetting.requireLowercase"
                [requireUppercase]="passwordComplexitySetting.requireUppercase" 
                [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric" 
                [requiredLength]="passwordComplexitySetting.requiredLength"
                [ngClass]="{'is-invalid': PasswordRepeat.invalid, 'is-valid': !PasswordRepeat.invalid}" 
                required>
            <validation-messages [formCtrl]="PasswordRepeat"></validation-messages>
        </div>
        <div [hidden]="resetPassForm.form.valid || resetPassForm.form.pristine" class="form-group">
            <ul class="form-text text-danger" *ngIf="PasswordRepeat.errors">
                <li [hidden]="!PasswordRepeat.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                <li [hidden]="!PasswordRepeat.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                <li [hidden]="!PasswordRepeat.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                <li [hidden]="!PasswordRepeat.errors.requireNonAlphanumeric">{{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                <li [hidden]="!PasswordRepeat.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" | localize:passwordComplexitySetting.requiredLength}}</li>
                <li [hidden]="PasswordRepeat.valid">{{"PasswordsDontMatch" | localize}}</li>
            </ul>
        </div>

        <div class="kt-login__actions">
            <button [disabled]="saving" routerLink="/account/login" type="button" class="btn btn-light btn-elevate kt-login__btn-secondary"><i class="fa fa-arrow-left"></i> {{"Back" | localize}}</button>
            <button type="submit" class="btn btn-primary btn-elevate kt-login__btn-primary" [disabled]="!resetPassForm.form.valid" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-check"></i> {{"Submit" | localize}}</button>
        </div>

    </form>
</div>



