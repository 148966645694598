import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { RdmSettingsDto, ChangeRequestConfigServiceProxy, RdmDepartamentDto, RdmApprovalLevelsDto, EmailsForRdmCommunicationDto } from '@shared/service-proxies/service-proxies';
import { CreateOrEditDepartamentModalComponent } from './create-or-edit-departament-modal/create-or-edit-departament-modal.component';
import { NgModel } from '@angular/forms';

@Component({
    selector: 'change-request',
    templateUrl: './changeRequestConfig.component.html',
    styleUrls: ['./changeRequestConfig.component.less']
})

export class ChangeRequestConfigComponent extends AppComponentBase implements OnInit {
    @ViewChild('createEditDepartamentModal', { static: true }) createEditDepartamentModal: CreateOrEditDepartamentModalComponent;
    @ViewChild('List_Email_Change_Request', { static: true }) listEmailChangeRequestField: NgModel;

    rdmSettingsDto: RdmSettingsDto = new RdmSettingsDto();
    rdmDepartamentListDto: RdmDepartamentDto[] = [];
    loading = false;
    departamentDto: RdmDepartamentDto = new RdmDepartamentDto;
    rdmApprovalLevelsDto!: RdmApprovalLevelsDto[];
    emailsForRdmCommunicationDto: EmailsForRdmCommunicationDto = new EmailsForRdmCommunicationDto;

    constructor(
        injector: Injector,
        private _changeRequestConfigAppService: ChangeRequestConfigServiceProxy
    ) {
        super(injector);
    }
    ngOnInit() {
        this.getChangeRequestConfiguration();
        this.getEmailsForRdmCommunication();
        this.getAllDepartamentChangeRequestConfiguration();
    }

    getChangeRequestConfiguration() {
        this._changeRequestConfigAppService.getRdmSettings()
            .subscribe(res => { this.rdmSettingsDto = res });
    }

    getAllDepartamentChangeRequestConfiguration() {
        this._changeRequestConfigAppService.getAllRdmDepartaments()
            .subscribe(res => { this.rdmDepartamentListDto = res });
    }

    saveAll() {
        this.loading = true;      
        this._changeRequestConfigAppService.insertUpdateRdmSettings(this.rdmSettingsDto)
        .subscribe(() => {
            if (this.emailsForRdmCommunicationDto != null || this.emailsForRdmCommunicationDto != undefined && 
                this.emailsForRdmCommunicationDto.userEmails.length > 0){
                let emails = '';
                this.emailsForRdmCommunicationDto.userEmails.forEach(element => {
                    emails = emails + element + ';';
                });

                this._changeRequestConfigAppService.insertUpdateEmailsForCommunication(emails)
                    .subscribe(() => {});
            }

            if (!this.rdmSettingsDto.approvalLevelsIsActive){
                this.getChangeRequestConfiguration();
                this.notify.success(this.l('SavedSuccessfully'));
                return;
            }

            if (this.rdmApprovalLevelsDto !== null && this.rdmApprovalLevelsDto.length > 0) {
                const approvalLevels = this.rdmApprovalLevelsDto.map(item => item.approvalLevel);
                const hasDuplicates = new Set(approvalLevels).size !== approvalLevels.length;
            
                if (hasDuplicates) {
                    this.notify.error('Nível de Aprovação duplicado, verifique!');
                } else {
                    this._changeRequestConfigAppService.insertUpdateRdmApprovalLevels(this.rdmApprovalLevelsDto)
                        .subscribe(() => {
                            this.rdmApprovalLevelsDto = [];
                            this.getChangeRequestConfiguration();
                            this.getAllDepartamentChangeRequestConfiguration();
                            this.getRdmApprovalLevels();
                            this.notify.success(this.l('SavedSuccessfully'));
                        });
                }
            }
        });
    }

    showDepartamentModal(button: boolean): void {
        this.createEditDepartamentModal.show(this.departamentDto, button);
    }

    reloadPage(ev: any) {
        this.getAllDepartamentChangeRequestConfiguration();
    }

    editDepartamentItem(departamentoId: string) {
        this._changeRequestConfigAppService.getRdmDepartament(departamentoId)
            .subscribe(res => {
                this.departamentDto = res;
                this.createEditDepartamentModal.show(this.departamentDto, false);
            });
    }

    deleteDepartamentItem(i: number, departamentDto: RdmDepartamentDto) {
        this._changeRequestConfigAppService.deleteRdmDepartament(departamentDto.id)
            .subscribe(res => {
                this.rdmDepartamentListDto.splice(i, 1);
                this.notify.success(this.l('SuccessfullyRemoved'));
            });
    }

    getRdmApprovalLevels() {
        this._changeRequestConfigAppService.getRdmApprovalLevels()
            .subscribe(res => { this.rdmApprovalLevelsDto = res }); 
    }

    deleteRdmApprovalLevelDto(rdmApprovalLevelDto: RdmApprovalLevelsDto) {
        this._changeRequestConfigAppService.deleteRdmApprovalLevel(rdmApprovalLevelDto.id)
            .subscribe(() => {
                this.rdmApprovalLevelsDto = this.rdmApprovalLevelsDto.filter(item => item.id !== rdmApprovalLevelDto.id);
                this.getRdmApprovalLevels();
                this.notify.success(this.l('SuccessfullyRemoved'));
            });
    }

    validarListEmails() {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        this.emailsForRdmCommunicationDto.userEmails = this.emailsForRdmCommunicationDto.userEmails.filter(email => emailRegex.test(email));
    }

    getEmailsForRdmCommunication() {
        this._changeRequestConfigAppService.getEmailsForRdmCommunication()
            .subscribe(res => {
                this.emailsForRdmCommunicationDto.userEmails = res.userEmails.slice(0, -1);
            })
    }
}