<div [@routerTransition]>

    <div class="kt-subheader kt-grid__item">

        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title" *ngIf="isDev">
                <button [style.background-color]="selectedDev ? 'lightblue' :  '#5867dd' " (click)="getAvaliacoesDeQualidades()" class="btn btn-primary"
                type="submit">{{l("AvaliacoesDeQualidades")}}</button>
                
            </h3>
            <span *ngIf="isDecisor" class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span *ngIf="isDecisor" class="kt-subheader__desc" >
                <button [style.background-color]="!selectedDev ? 'lightblue' :  '#5867dd'" (click)="getAvaliacoesDeQualidadesDec()" class="btn btn-primary"
                type="submit">{{l("AvaliacoesDecisor")}}</button>
                
            </span>
            
        </div>
    </div>
    
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <form class="kt-form" autocomplete="off">
                    <div>
                        <div class="row align-items-center">
                            <div class="col-xl-12">
                                <div class="form-group m-form__group align-items-center">
                                    <div class="input-group">
                                        <input [(ngModel)]="filterText" name="filterText" autoFocus
                                            class="form-control m-input" [placeholder]="l('SearchWithThreeDot')"
                                            type="text">
                                        <span class="input-group-btn">
                                            <button (click)="recarregar()" class="btn btn-primary"
                                                type="submit"><i class="flaticon-search-1"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" [hidden]="!advancedFiltersAreShown">
                            <div class="col-md-12">
                                <div class="kt-separator kt-separator--border-dashed"></div>
                            </div>
                            <div class="col-md-3">
                                <label for="StatusFilterSelect">{{l("Status")}}</label>
                                <select class="form-control" name="StatusFilter" id="StatusFilterSelect"
                                    [(ngModel)]="statusFilter">
                                    <option value="-1">{{l("All")}}</option>
                                    <option *ngIf="appSession.tenantId == null" value="1">{{l('EmCorrecao')}}</option>
                                    <option value="3">{{l('Finalizada')}}</option>
                                    <option value="0">{{l('EmAnalise')}}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="MinDataDaAvaliacaoFilter">{{l("DataDaAvaliacaoRange")}}</label>
                                    <div class="input-group">
                                        <input class="form-control m-input" id="MinDataDaAvaliacaoFilter"
                                            name="MinDataDaAvaliacaoFilter" type="datetime" bsDatepicker
                                            placeholder="{{l('MinValue')}}" [(ngModel)]="minDataDaAvaliacaoFilter">
                                        <span style="line-height: 40px;padding: 0 15px 0 15px;"> &mdash; </span>
                                        <input class="form-control m-input" id="MaxDataDaAvaliacaoFilter"
                                            name="MaxDataDaAvaliacaoFilter" type="datetime" bsDatepicker
                                            placeholder="{{l('MaxValue')}}" [(ngModel)]="maxDataDaAvaliacaoFilter">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="DemandaTituloFilter">({{l("Demanda")}}) {{l("Titulo")}}</label>
                                    <input type="text" class="form-control" id="DemandaTituloFilter"
                                        name="demandaTituloFilter" [(ngModel)]="demandaTituloFilter">
                                </div>
                            </div>
                            <div class="col-md-3" *ngIf="!this.appSession.tenantId">
                                <div class="form-group">
                                    <label for="UserNameFilter">({{l("User")}}) {{l("Name")}}</label>
                                    <input type="text" class="form-control" id="UserNameFilter" name="userNameFilter"
                                        [(ngModel)]="userNameFilter">
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12">
                                <span class="clickable-item text-muted" *ngIf="!advancedFiltersAreShown"
                                    (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i
                                        class="fa fa-angle-down"></i> {{l("ShowAdvancedFilters")}}</span>
                                <span class="clickable-item text-muted" *ngIf="advancedFiltersAreShown"
                                    (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i
                                        class="fa fa-angle-up"></i> {{l("HideAdvancedFilters")}}</span>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-xl-12 text-right" style="text-align: right;">
                                <button (click)="recarregar()" name="RefreshButton"
                                    class="btn btn-primary"><i class="fa fa-sync"></i> {{'Refresh' | localize}}</button>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row align-items-center">
                    <!--<Primeng-Datatable-Start>-->
                    <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable (onLazyLoad)="recarregar($event)"
                            [value]="primengTableHelper.records"
                            rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false" [lazy]="true"
                            [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                            [resizableColumns]="primengTableHelper.resizableColumns">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 130px">
                                        {{l('Actions')}}
                                    </th>
                                    <th style="width: 150px" pSortableColumn="TenantName">
                                        {{l('Tenant')}}
                                        <p-sortIcon field="tenantName"></p-sortIcon>
                                    </th>
                                    <th style="width: 100px"  pSortableColumn="DemandaSapId">
                                        {{l('DemandaSapId')}}
                                        <p-sortIcon field="demandaSapId"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="Titulo">
                                        {{l('Demanda')}}
                                        <p-sortIcon field="demandaTitulo"></p-sortIcon>
                                    </th>
                                    <th *ngIf="appSession.tenantId == null" style="width: 150px" pSortableColumn="NomeFornecedor">
                                        {{l('Fornecedor')}}
                                        <p-sortIcon field="demandaTitulo"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="UserName">
                                        {{l('Solicitante')}}
                                        <p-sortIcon field="userName"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="Status">
                                        {{l('Status')}}
                                        <p-sortIcon field="avaliacaoDeQualidade.status"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="DataDaAvaliacao">
                                        {{l('DataHora')}}
                                        <p-sortIcon field="avaliacaoDeQualidade.dataDaAvaliacao"></p-sortIcon>
                                    </th>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td style="width: 130px">
                                        <div class="btn-group dropdown" dropdown container="body">
                                            <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle>
                                                <i class="fa fa-cog"></i><span class="caret"></span> {{l("Actions")}}
                                            </button>
                                            <ul class="dropdown-menu" *dropdownMenu>
                                                <li>
                                                    <a href="javascript:;"
                                                        [routerLink]="'/app/main/avaliacoes-qualidade/itens/' + record.avaliacaoDeQualidade.id">{{l('Details')}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td style="width:150px">
                                        {{record.tenantName}}
                                    </td>
                                    <td style="width:100px">
                                        {{record.avaliacaoDeQualidade.demandaSapId}}
                                    </td>
                                    <td style="width:150px">
                                        {{record.demandaTitulo}}
                                    </td>
                                    <td style="width:150px" *ngIf="appSession.tenantId == null">
                                        {{record.fornecedorNome}}
                                    </td>
                                    <td style="width:150px">
                                        {{record.userName}}
                                    </td>
                                    <td style="width:150px">
                                        {{l( enumStatusDaAvaliacaoDeQualidade[record.avaliacaoDeQualidade.status])}}
                                    </td>
                                    <td style="width:150px">
                                        <span *ngIf="record.avaliacaoDeQualidade.dataDaAvaliacao">
                                            {{record.avaliacaoDeQualidade.dataDaAvaliacao | momentFormat:'DD/MM/YYYY HH:mm'}}
                                        </span>
                                        <span *ngIf="!record.avaliacaoDeQualidade.dataDaAvaliacao">-</span>
                                    </td>

                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{l('NoData')}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                                (onPageChange)="recarregar($event)"
                                [totalRecords]="primengTableHelper.totalRecordsCount"
                                [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-Datatable-End>-->
                </div>
            </div>
        </div>
    </div>
    
    <app-historico-avaliacao-codigo #historicoAvaliacaoCodigo></app-historico-avaliacao-codigo>
</div>