<div bsModal #riskStatusModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form #riskStatusForm="ngForm" (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">

                    <h4 class="modal-title">
                        {{"RiskStatusHeader" | localize}}
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-footer">
                    <button [disabled]="loading" type="button" class="btn btn-default" (click)="close()">{{"Cancel"
                        |
                        localize}}</button>
                    <button type="submit" class="btn btn-primary blue" [buttonBusy]="loading"
                        [disabled]="riskStatusForm.invalid" [busyText]="'SavingWithThreeDot' | localize"><i
                            class="fa fa-save"></i>
                        <span>{{"Save" | localize}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>