import { Component, Injector, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LiberacoesUsuariosServiceProxy, LiberacaoUsuarioCardDto, UserLinkServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { LazyLoadEvent } from 'primeng/api';
import { EntityTypeHistoryModalComponent } from '@app/shared/common/entityHistory/entity-type-history-modal.component';
import * as _ from 'lodash';
import { ExternalLoginProvider, LoginService } from '@account/login/login.service';

@Component({
    templateUrl: './liberacoesUsuarios.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class LiberacoesUsuariosComponent extends AppComponentBase implements OnInit {

    @ViewChild('entityTypeHistoryModal', { static: true }) entityTypeHistoryModal: EntityTypeHistoryModalComponent;

    advancedFiltersAreShown = false;
    filterText = '';
    userNameFilter = '';
    accessCode: string;

    _entityTypeFullName = 'QAMetrik.LiberacoesUsuarios.LiberacaoUsuario';
    entityHistoryEnabled = false;
    liberacoes: LiberacaoUsuarioCardDto[] = [];

    loginMicrosoft: boolean = false;

    get microsoftProvider() {
        if (this.loginService.externalLoginProviders.length <= 1)
            return null;
        return this.loginService.externalLoginProviders.find(item => item.name == ExternalLoginProvider.MICROSOFT)
    }
    

    constructor(
        injector: Injector,
        private _liberacoesUsuariosServiceProxy: LiberacoesUsuariosServiceProxy,
        private _route: ActivatedRoute,
        private _router: Router,
        private _userLinkService: UserLinkServiceProxy,
        public loginService: LoginService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.entityHistoryEnabled = this.setIsEntityHistoryEnabled();
        this.getLiberacoesUsuarios();
        this.loginService.init()

        const chaveDeLiberacao = localStorage.getItem("chaveDeLiberacao");
        if (!chaveDeLiberacao) {
            return;
        }
        
        this._userLinkService.liberacaoDeAcesso(chaveDeLiberacao).subscribe(() => {
            localStorage.removeItem("chaveDeLiberacao");
            this.notify.info(this.l('SavedSuccessfully'));
            this.reload();
        });

    }

    private setIsEntityHistoryEnabled(): boolean {
        let customSettings = (abp as any).custom;
        return customSettings.EntityHistory && customSettings.EntityHistory.isEnabled && _.filter(customSettings.EntityHistory.enabledEntities, entityType => entityType === this._entityTypeFullName).length === 1;

    }

    getLiberacoesUsuarios(event?: LazyLoadEvent) {
        this.primengTableHelper.showLoadingIndicator();

        this._liberacoesUsuariosServiceProxy.getAllCards(this.appSession.userId, '', 0, 50000

        ).subscribe(result => {
            this.liberacoes = result;
        });
    }

    reload() {
        location.reload();
    }

    externalLogin(provider: ExternalLoginProvider) {
        this.loginService.externalAuthenticateTenant(provider, this.appSession.userId);
        this.loginMicrosoft = true;
    }

    externalLoginByCard(provider: ExternalLoginProvider, userId: number) {
        this.loginService.externalAuthenticateTenant(provider, userId);
    }
}
