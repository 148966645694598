<!-- begin::Quick Sidebar -->
<div ktOffcanvas [options]="offcanvasOptions" id="kt_theme_selection_panel" class="kt-demo-panel">
    <div class="kt-demo-panel__head">
        <h5 class="kt-demo-panel__title">{{'SelectATheme' | localize}}</h5>
        <a href="#" class="kt-demo-panel__close" id="kt-theme_selection_panel_close"><i class="flaticon2-delete"></i></a>
    </div>
    <div class="kt-demo-panel__body" ngx-scrollbar>
        <!-- <perfect-scrollbar #themeSelectionPanelScrollBar style="height: 88vh"> -->
            <ul class="nav nav-tabs kt-tabs-line kt-tabs-line--2x kt-tabs-line--primary" role="tablist">
                <li class="nav-item kt-tabs__item">
                    <a href="javascript:;" (click)="changeTheme('default')" data-theme="default" class="nav-link kt-tabs__link text-center theme-selection-link" [ngClass]="currentThemeName == 'default' ? 'active' : ''" data-toggle="tab" role="tab">
                        <img [src]="'/assets/common/images/metronic-themes/default.png'" />
                        <span class="theme-name">{{getLocalizedThemeName('default')}}</span>
                    </a>
                </li>
            </ul>
        <!-- </perfect-scrollbar> -->
    </div>
</div>
<!-- end::Quick Sidebar -->
