import { Component, OnInit, ViewChild, Injector, AfterViewInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AvaliacoesDeQualidadesServiceProxy, LinhaForViewDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as hljs from 'assets/prism/highlight.pack.js';

@Component({
  selector: 'app-codigo-sap',
  templateUrl: './codigo-sap.component.html',
  styleUrls: ['./codigo-sap.component.css']
})
export class CodigoSapComponent extends AppComponentBase   {
  @ViewChild('codigoModal', { static: true }) modal: ModalDirective;
  codigo = '';
  linhas: LinhaForViewDto[] = [];

  constructor(injector: Injector,
    private _avaliacoesDeQualidadesServiceProxy: AvaliacoesDeQualidadesServiceProxy,
  ) {
    super(injector);
  }

  show(itemAvaliacaoDeQualidadeId: string, avaliacaoDeQualidadeId: string): void {
    this.linhas = [];
    this._avaliacoesDeQualidadesServiceProxy.getCodigoComErro(itemAvaliacaoDeQualidadeId, avaliacaoDeQualidadeId).subscribe(x => {
      this.codigo = x.codigo;
      this.linhas = x.linhas;

      document.getElementById('codigo').innerHTML = this.codigo;
        hljs.highlightBlock(document.getElementById('codigo'));
    });
    this.modal.show();
  }

  close(): void {
    this.modal.hide();
  }

}
