<div bsModal id="codeReviewerCommentsModal" #codeReviewerCommentsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div id="modal-dialog" class="modal-dialog modal-xl">
        <div id="modal-content" class="modal-content">
            <div id="modal-header" class="modal-header">
                <h4 id="modal-title" class="modal-title">
                    <span id="modal-title-txt" class="modal-title-txt">Comentários do Avaliador</span> - <span>{{avaliador}}</span>
                </h4>
                <button id="close-button" type="button" class="close" (click)="close()" aria-label="Close">
                    <span id="times-icon" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div id="code-review-date" class="code-review-date">
                <span id="avaliado-txt" class="avaliado-txt">Avaliado em: </span><span id="avaliado-date" class="avaliado-date">{{getLocaleDateHourCodeReview(dataAvaliacao)}}</span>
            </div>
            <div id="modal-body" class="modal-body">
                <div id="criterio-list" class="criterio-list" *ngFor="let criterio of codeReviewHistoricoCriterioDto">
                    <div id="title-criteria-code-review" class="title-criteria-code-review" [ngClass]="{'isInadequado':!criterio.situacaoCriterio, 'isAdequado':criterio.situacaoCriterio}">
                        <p>{{criterio.criterio}}</p>
                        <span *ngIf="criterio.situacaoCriterio" id="adequate" class="adequate">Adequado</span>
                        <span *ngIf="!criterio.situacaoCriterio" id="inadequate" class="inadequate">Inadequado</span>
                    </div>
                    <div *ngIf="!criterio.situacaoCriterio" id="criteria-history-inadequate" class="criteria-history-inadequate">
                        <form id="justificativa-form" class="justificativa-form">
                            <label for="code-review-justify" id="justificativa-label" class="justificativa-label">Justificativa</label>
                            <textarea id="code-review-justify" class="code-review-justify" type="text" disabled>{{criterio.comentario}}</textarea>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>