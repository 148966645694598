<div id="approve-reprove-modal" #approveReproveChangeModal="bs-modal" class="modal fade overflow-auto" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}" bsModal>
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-md-8">                              
                        <label for="change-status">{{l("Status da GMUD")}}</label>
                        <p-timeline [value]="statusGmud" layout="horizontal" styleClass="customized-timeline">
                            <ng-template pTemplate="marker" let-gmud>
                                <span class="custom-marker status-steps shadow-2" [style]="changeManagerNativo?.status === gmud?.status 
                                    ? setColorsBackgroundStatus(gmud?.status) : 'background-color: #607D8B;'">
                                    <i [ngClass]="gmud?.icon"></i>
                                </span>                                   
                            </ng-template>
                            <ng-template pTemplate="content" let-gmud>
                                <span [class]="changeManagerNativo?.status === gmud?.status ? 'text-bold-status text-size-status' : 'text-size-status'">
                                    {{gmud?.status | uppercase}}
                                </span>
                            </ng-template>
                        </p-timeline>                                               
                    </div> 
                    <div class="form-group col-md-4">  
                        <div style="margin-bottom: 40px">
                            <form *ngIf="btnAprovaReprova" id="aprove-reprove-form" #approveReproveForm="ngForm">
                                <div class="button-content pull-right">
                                    <button class="btn btn-outline-success" (click)="openModalJustify(1)">{{l("Aprovar")}}</button>
                                    <button class="btn btn-outline-danger" (click)="openModalJustify(2)">{{l("Reprovar")}}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div id="gmud-content">
                    <div id="gmud-native-content">
                        <tabset>
                            <tab heading="{{l('GeneralInfo')}}">
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label for="change-start">{{l("ChangeStart")}}</label>
                                            <input id="change-start" type="datetime" name="ChangeStart" class="form-control"
                                            [value]="getLocaleDateHour(changeManagerNativo?.dataCriacao)" disabled/>
                                    </div>                                                    
                                </div>
                                <div class="form-group">
                                    <label for="change-requester">{{l("ChangeRequester")}}</label>
                                    <input id="change-requester" type="text"
                                        name="ChangeRequester" class="form-control" [(ngModel)]="changeManagerNativo.solicitante" disabled/>
                                </div>
                                <div class="form-group">
                                    <label for="change-title">{{l("ChangeTitle")}}</label>
                                    <input id="change-title" #changeTitle="ngModel" type="text" name="ChangeTitle"
                                        class="form-control" [(ngModel)]="changeManagerNativo.tituloDemanda" disabled/>
                                </div>
                                <div class="form-group">
                                    <label for="change-description">{{l("ChangeDescription")}}</label>
                                    <p-editor class="p-editor-demand" id="change-description" [(ngModel)]="changeManagerNativo.descricaoDemanda" 
                                              name="change-description" [style]="{'height':'320px'}" readonly="false">
                                    </p-editor>
                                </div>
                                <div class="form-group">
                                    <label for="change-type">{{l("ChangeType")}}</label>
                                    <input id="change-type" type="text" name="ChangeType"
                                        class="form-control" [value]="getEnumTipoMudanca(changeManagerNativo.tipoMudanca)" disabled/>
                                </div>
                                <div class="form-group">
                                    <label for="change-risk">{{l("ChangeRisk")}}</label>
                                    <input id="change-risk" type="text" name="ChangeRisk"
                                        class="form-control" [value]="getEnumRisco(changeManagerNativo.riscoMudanca)" disabled/>
                                </div>
                                <div class="form-group">
                                    <label for="mitigation-plan">{{l("MitigationPlanNative")}}</label>
                                    <input id="mitigation-plan" #mitigationPlan="ngModel" type="text" name="MitigationPlan"
                                        class="form-control" [(ngModel)]="changeManagerNativo.planoPrevencao" disabled/>
                                </div>
                                <div class="form-group">
                                    <label for="return-plan">{{l("ReturnPlanNative")}}</label>
                                    <input id="return-plan" #returnPlan="ngModel" type="text" name="ReturnPlan"
                                        class="form-control" [(ngModel)]="changeManagerNativo.planoRetorno" disabled/>
                                </div>
                                <div class="form-group">
                                    <label for="date-transp">{{l("Data e hora do transporte")}}</label>
                                    <input id="date-transp" type="text" name="DateTransp"
                                        class="form-control" [value]="getLocaleDateHour(changeManagerNativo?.dataHoraTransporte)" disabled/>
                                </div>
                                <div class="form-group">
                                    <label for="states-transp">{{l("Status do transporte")}}</label>
                                    <input id="states-transp" type="text" name="StatesTransp"
                                        class="form-control" [(ngModel)]="changeManagerNativo.statusTransporte" disabled/>
                                </div>
                                <div class="form-group">
                                    <label for="type-transp">{{l("Tipo do transporte")}}</label>
                                    <input id="type-transp" type="text" name="TypeTransp"
                                        class="form-control" [value]="getEnumTipoMudanca(changeManagerNativo.tipoMudanca)" disabled/>
                                </div>
                            </tab>
                            <tab heading="{{l('Approvers')}}">
                                <div class="form-group">
                                    <label for="user-approval">{{l("Approvers")}}</label>
                                    <div class="departament-content" *ngFor="let departament of changeManagerNativo.departamentos">                                    
                                        <p-panel [header]="'DEP: ' +  departament?.descricao | uppercase" [toggleable]="true" [collapsed]="true">                                 
                                            <div class="user-content" *ngFor="let user of departament.usuarios">
                                                <span class="approver-name text-bold-status">{{ user?.nome}}  
                                                    <i class="far fa-comment-alt approve-status" *ngIf="user?.status !== l('WaitingForApproval')" 
                                                        [tooltip]="user?.justificativa != null ? 'Comentário: ' + user?.justificativa : ''">
                                                    </i>
                                                </span> 
                                                <br/>
                                                <p-timeline [value]="statusAprovador" layout="horizontal" styleClass="customized-timeline">
                                                    <ng-template pTemplate="marker" let-aprovador>
                                                        <span class="custom-marker status-steps shadow-2" [style]="user?.status === aprovador?.status 
                                                            ? setColorsBackgroundStatus(aprovador?.status) : 'background-color: #607D8B;'">
                                                            <i [ngClass]="aprovador?.icon"></i>
                                                        </span>
                                                    </ng-template>
                                                    <ng-template pTemplate="content" let-aprovador>
                                                        <span [class]="user?.status === aprovador?.status ? 'text-bold-status text-size-status' : 'text-size-status'">
                                                            {{aprovador?.status | uppercase}}
                                                        </span>
                                                        <br />
                                                        <span *ngIf="user?.status === aprovador?.status && user?.status !== 'Aguardando aprovação'">
                                                            {{getLocaleDateHour(user?.dataAlteracao)}}
                                                        </span>
                                                    </ng-template>
                                                </p-timeline>                                    
                                            </div>
                                        </p-panel>
                                    </div>
                                </div>
                            </tab>
                            <tab heading="{{l('Anexos')}}">
                                <div class="form-group">
                                    <div id="anexos-gmud">
                                        <div class="list-head list-row">
                                            <div class="list-item item"><span>Documento</span></div>
                                            <div class="list-item item-action"><span>Ação</span></div>
                                        </div>
                                        <div class="list-body list-row" *ngFor="let anexo of changeManagerNativo.anexos; let i = index">
                                            <div class="list-item item"><span>{{anexo.nomeAnexo}}</span></div>
                                            <div class="list-item item-action">
                                                <span class="delete-item" (click)="downloadAnexo(anexo)"
                                                tooltip="{{l('Download do anexo')}}"><i class="fa fa-download"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tab>
                            <tab heading="{{l('Pós implementação')}}">
                                <div class="form-group">
                                    <label for="pos-implemention">{{l('Pós implementação')}}</label>
                                    <p-editor id="pos-implemention" [(ngModel)]="changeManagerNativo.posImplementacao" 
                                              name="pos-implemention" [style]="{'height':'320px'}" readonly="false">
                                    </p-editor>
                                </div>
                            </tab>
                        </tabset>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-justify-modal #justifyModal></app-justify-modal>
</div>