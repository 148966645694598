<div class="kt-login__form" [@routerTransition]>
    <div class="kt-login__title">
        <h3>
            {{"TenantSignUp" | localize}}
        </h3>

        <h4 *ngIf="model.edition" class="text-center">{{"Edition" | localize}}: {{model.edition.displayName}}</h4>
        <div class="kt-separator kt-separator--border-dashed"></div>

        <form #registerForm="ngForm" role="form" novalidate (submit)="save()" class="kt-form mt-2">
            <h5 class="mt-2">
                {{"TenantInformations" | localize}}
            </h5>

            <div class="form-group ">
                <input #tenancyName="ngModel" class="form-control" autoFocus type="text" placeholder="{{'TenancyName' | localize}} *" [(ngModel)]="model.tenancyName" name="tenancyName" required maxlength="64" pattern="^[a-zA-Z][a-zA-Z0-9_-]{1,}$" />
                <div *ngIf="!tenancyName.valid && !tenancyName.pristine">
                    <span class="form-text text-danger text-left">{{"TenantName_Regex_Description" | localize}}</span>
                </div>
            </div>

            <div class="form-group ">
                <input #nameInput="ngModel" class="form-control" type="text" placeholder="{{'TenantName' | localize}} *" [(ngModel)]="model.name" name="Name" required maxlength="128" />
                <validation-messages [formCtrl]="nameInput"></validation-messages>
            </div>

            <h5 class="mt-2">
                {{"AccountSettings" | localize}}
            </h5>

            <div class="form-group ">
                <input #adminEmailAddressInput="ngModel" class="form-control" type="email" placeholder="{{'AdminEmailAddress' | localize}} *" [(ngModel)]="model.adminEmailAddress" name="adminEmailAddress" required maxlength="256" email />
                <span class="form-text text-danger">
                    <validation-messages [formCtrl]="adminEmailAddressInput"></validation-messages>
                </span>
            </div>

            <div class="form-group ">
                <input type="password" name="Password" class="form-control" [(ngModel)]="model.adminPassword" #Password="ngModel" placeholder="{{'AdminPassword' | localize}}" validateEqual="PasswordRepeat"
                       reverse="true" [requireDigit]="passwordComplexitySetting.requireDigit" [requireLowercase]="passwordComplexitySetting.requireLowercase"
                       [requireUppercase]="passwordComplexitySetting.requireUppercase" [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric" [requiredLength]="passwordComplexitySetting.requiredLength"
                       [notAllowSequencing]="passwordComplexitySetting.notAllowSequencingOfNumbersAndLetters"
                       required>
                <div [hidden]="registerForm.form.valid || registerForm.form.pristine" class="form-group ">
                    <ul class="form-text text-danger" *ngIf="Password.errors">
                        <li [hidden]="!Password.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                        <li [hidden]="!Password.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                        <li [hidden]="!Password.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                        <li [hidden]="!Password.errors.requireNonAlphanumeric">{{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                        <li [hidden]="!Password.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" | localize:passwordComplexitySetting.requiredLength}}</li>
                        <li [hidden]="!Password.errors.notAllowSequencing">
                            {{"NotAllowSequencingOfNumbersAndLettersHint" | localize}}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="form-group ">
                <input type="password" name="PasswordRepeat" class="form-control" [ngModel]="model.passwordRepeat" #PasswordRepeat="ngModel" placeholder="{{'PasswordRepeat' | localize}}" validateEqual="Password"
                       reverse="false" [requireDigit]="passwordComplexitySetting.requireDigit" [requireLowercase]="passwordComplexitySetting.requireLowercase" [requireUppercase]="passwordComplexitySetting.requireUppercase"
                       [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric" [requiredLength]="passwordComplexitySetting.requiredLength"
                       [notAllowSequencing]="passwordComplexitySetting.notAllowSequencingOfNumbersAndLetters" required>
                <div [hidden]="registerForm.form.valid || registerForm.form.pristine" class="form-group ">
                    <ul class="form-text text-danger" *ngIf="PasswordRepeat.errors">
                        <li [hidden]="!PasswordRepeat.errors.requireDigit">{{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                        <li [hidden]="!PasswordRepeat.errors.requireLowercase">{{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                        <li [hidden]="!PasswordRepeat.errors.requireUppercase">{{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                        <li [hidden]="!PasswordRepeat.errors.requireNonAlphanumeric">{{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                        <li [hidden]="!PasswordRepeat.errors.requiredLength">{{"PasswordComplexity_RequiredLength_Hint" | localize:passwordComplexitySetting.requiredLength}}</li>
                        <li [hidden]="PasswordRepeat.valid">{{"PasswordsDontMatch" | localize}}</li>
                        <li [hidden]="!Password.errors.notAllowSequencing">
                            {{"NotAllowSequencingOfNumbersAndLettersHint" | localize}}
                        </li>
                    </ul>
                </div>
            </div>

            <p *ngIf="useCaptcha" class="mt-2 form-text text-muted">
                {{"Captcha_Hint" | localize}}
            </p>

            <div *ngIf="useCaptcha" class="form-group mt-2 text-center captcha-container">
                <re-captcha #recaptchaRef (resolved)="captchaResolved($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
            </div>

            <div class="kt-login__actions">
                <button [disabled]="saving" routerLink="/account/login" type="button" class="btn btn-light btn-elevate kt-login__btn-secondary"><i class="fa fa-arrow-left"></i> {{"Back" | localize}}</button>
                <button type="submit" class="btn btn-primary btn-elevate kt-login__btn-primary" [disabled]="!registerForm.form.valid" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-check"></i> {{"Submit" | localize}}</button>
            </div>
        </form>
    </div>
</div>
