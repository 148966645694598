<div bsModal #createVersionamentoModal="bs-modal" id="createVersionamentoModal" class="modal fade overflow-auto"
    tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div id="create-versionamento-modal-dialog" class="modal-dialog modal-lg">
        <div id="create-version-control-content" class="modal-content create-version-control-content">
            <form id="version-control-form" #systemVersionControlForm="ngForm" autocomplete="off">
                <div id="modal-header-content" class="modal-header">
                    <h4 id="modal-title-content" class="modal-title">
                        {{l("ManageVersion")}}
                    </h4>
                    <button id="close-modal-content" type="button" class="close" (click)="close()"
                        [attr.aria-label]="'Close' | localize">
                        <span id="times-icon" class="times-icon" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div id="modal-body-content" class="modal-body">
                    <tabset>
                        <tab heading="{{'VersionData' | localize}}">
                            <div id="version-control-title-arquivado" class="kt-checkbox">
                                <label for="arquivar" class="kt-checkbox">
                                    <input id="arquivar" type="checkbox" name="arquivar"
                                        [(ngModel)]="versionControlAchivedInput">{{l("ArchiveVersion")}}<span></span>
                                </label>
                            </div>
                            <div id="version-control-title-content" class="form-group">
                                <label id="version-control-title-label" class="label-title"
                                    for="version-control-title">{{l("VersionTitle")}}</label>
                                <input id="version-control-title" type="text" name="version-control-title"
                                    class="form-control" [(ngModel)]="versionControlTitleInput"
                                    placeholder="{{'PlaceHolderVersionTitle' | localize}}" required>
                            </div>
                            <div id="version-control-cutover-content" class="form-group">
                                <label id="version-control-content-content" class="label-title"
                                    for="version-control-cutover">{{l("VersionCutover")}}</label>
                                <textarea id="version-control-cutover" class="version-control-cutover" type="text"
                                    name="versionControlConteudo" class="form-control textarea"
                                    [(ngModel)]="versionControlContentInput"
                                    placeholder="{{'PlaceHolderVersionContent' | localize}}"></textarea>
                            </div>
                            <div id="version-control-document-content" class="form-group">
                                <label id="version-control-document-content" class="label-title"
                                    for="version-control-document">{{l("VersionDocumentation")}}</label>
                                <p-editor id="p-editor-version-control" class="version-control-document"
                                    [(ngModel)]="versionControlDocument" name="version-control-document"
                                    [style]="{'height':'400px'}"
                                    placeholder="{{'PlaceHolderVersionDocumentation' | localize}}"></p-editor>
                            </div>
                        </tab>
                        <tab heading="{{'VersionListOfRequest' | localize}}">
                            <p>{{l("RequestRegistrationRequest")}}</p>
                            <div id="version-control-request-register" class="form-group row">
                                <div id="version-control-number-request" class="col-md-4">
                                    <label id="version-control-number-label" class="label-number"
                                        for="version-control-number-input">{{l("Numero")}}</label>
                                    <input id="version-control-number-input" type="text"
                                        name="version-control-number-input" class="form-control"
                                        [(ngModel)]="versionControlNumberRequest" required [disabled]="emProcessamento">
                                </div>

                                <div id="version-control-description-request" class="col-md-7">
                                    <label id="version-control-description-label" class="label-description"
                                        for="version-control-description-input">{{l("Description")}}</label>
                                    <input id="version-control-description-input" type="text"
                                        name="version-control-description-input" class="form-control"
                                        [(ngModel)]="versionControlDescriptionRequest" required
                                        [disabled]="emProcessamento">
                                </div>

                                <button id="request-btn" class="btn btn-primary blue" type="submit"
                                    (click)="createItemRequest()" [disabled]="emProcessamento">
                                    <i id="plus-icon" class="fa fa-plus"></i>
                                </button>

                                <div id="anexo-request" class="form-group m-form__group col-md-12 mt-4"
                                    [busyIf]="primengTableHelper.isLoading">
                                    <div style="margin-bottom: 15px" class="row"
                                        *ngFor="let anexo of anexosAdicionados">
                                        <p class="item-anexo"> {{anexo.nomeAnexo}}
                                            <i class="fa fa-trash" (click)="removerAnexo(anexo)"></i>
                                        </p>
                                    </div>
                                    <!-- <ngx-dropzone  #dropzone [label]="'CofileDataFiles' | localize " [showPreviews]="false" 
                                                [accept]="'.CCD'" [multiple]="true" [preserveFiles]="false" 
                                                (filesAdded)="onFilesAdded($event)"  [disabled]="emProcessamento">
                                    </ngx-dropzone > -->
                                    <div class="dropzone" ng2FileDrop (click)="fileInput.click()"
                                        (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
                                        (drop)="onDrop($event)">
                                        Solte os arquivos(CCD) aqui (ou
                                        clique)
                                    </div>

                                    <input #fileInput ng2FileSelect type="file" [multiple]="true" ng2FileSelect
                                        [accept]="'.CCD'" style="display: none" (change)="onFilesAdded($event,null)" />


                                </div>
                            </div>

                            <div id="list-request" cdkDropList [cdkDropListData]="requestList"
                                (cdkDropListDropped)="drop($event)">
                                <span class="section-title">{{l("TransportSequence")}}</span>
                                <div class="input-group mb-3 mt-3" *ngFor="let item of requestList" cdkDrag>
                                    <div
                                        class="form-control form-control-width-request-list icone-movimentar-request col-md-1">
                                        <i class="flaticon-grid-menu-v2"></i>
                                    </div>
                                    <input [value]="item.numberDescricao" class="form-control" type="text" disabled>
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" type="button"
                                            (click)='removerRequests(item.numberDescricao)'
                                            [disabled]="emProcessamento">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div id="modal-footer-content" class="modal-footer">
                    <div id="send-button-content" class="send-button">
                        <button [disabled]="saving" type="button" class="btn btn-default"
                            (click)="close()">{{l("Cancel")}}</button>
                        <button type="submit" class="btn btn-primary blue" [buttonBusy]="saving" (click)="saveVersion()"
                            [busyText]="l('SavingWithThreeDot')">
                            <i class="fa fa-save"></i>
                            <span>{{l("Save")}}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>