<div bsModal #addTenantBinding="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="addTenantBinding" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <form #bindingForm="ngForm" (ngSubmit)="save()" autocomplete="off"
                (keydown.enter)="$event.preventDefault()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{ l("TenantAuthBinding") }}</span>
                    </h4>
                    <button type="button" class="close" (click)="close()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <app-custom-select *ngIf="newBinding" fieldId="tenant-binding" [label]="'Client' | localize" fieldName="TenantBinding"
                        [options]="tenants" valueKey="value" [required]="true" labelKey="label" [value]="binding.tenant"
                        (valueChange)="binding.tenant = $event">
                    </app-custom-select>
                    <div class="form-group">
                        <label for="user-binding">{{"User" | localize}}</label>
                        <input id="user-binding" #userBinding="ngModel" type="text" name="UserBinding"
                            class="form-control" [(ngModel)]="binding.user" required
                            [ngClass]="{'is-invalid': userBinding.invalid, 'is-valid': !userBinding.invalid}" />
                        <validation-messages [formCtrl]="userBinding"></validation-messages>
                    </div>

                    <div class="form-group">
                        <label for="password-binding">{{"Password" | localize}}</label>
                        <input id="password-binding" #passwordBinding="ngModel" type="text" name="PasswordBinding"
                            class="form-control" [(ngModel)]="binding.password" required
                            [ngClass]="{'is-invalid': passwordBinding.invalid, 'is-valid': !passwordBinding.invalid}" />
                        <validation-messages [formCtrl]="passwordBinding"></validation-messages>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary blue" [disabled]="bindingForm.invalid"><i
                            class="fa fa-save"></i>
                        <span>{{l("Save")}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>