
import './assets/FreezeUI/freeze-ui'
import './assets/abp-web-resources/abp.module'

import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Router, NavigationStart } from '@angular/router';
import { RootModule } from './root.module';
import { environment } from './environments/environment';
import { singleSpaAngular, getSingleSpaExtraProviders } from 'single-spa-angular';
import { singleSpaPropsSubject } from './single-spa/single-spa-props';

if (environment.production) {
  enableProdMode();
}

const lifecycles = singleSpaAngular({
  async bootstrapFunction(singleSpaProps) {
    singleSpaPropsSubject.next(singleSpaProps);
    
    const module = await platformBrowserDynamic(getSingleSpaExtraProviders()).bootstrapModule(RootModule);
    
    // Se necessário, você pode executar mais lógica após a inicialização do módulo.
    
    return module;
  },
  template: '<app-root />',
  Router,
  NgZone,
});



export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;