import { Component, Injector, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AvaliacoesDeQualidadesServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-justificativa-avaliacao',
  templateUrl: './justificativa-avaliacao.component.html',
  styleUrls: ['./justificativa-avaliacao.component.css']
})
export class JustificativaAvaliacaoComponent extends AppComponentBase {
  @ViewChild('justificativaModal', { static: true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  demandaId: string;
  justificativa = '';
  guidsSelecionados: Array<string> = [];
  acao: number;
  saving: boolean;

  constructor(injector: Injector,
    private _avaliacoesDeQualidadesServiceProxy: AvaliacoesDeQualidadesServiceProxy,
    private route: ActivatedRoute
  ) {
    super(injector);
  }

  show(guidsSelecionados: Array<string>, acao: number, just : string): void {
    if (guidsSelecionados.length <= 0) { return null; }
    this.saving = false;
    this.justificativa = just;
    this.guidsSelecionados = guidsSelecionados;
    this.acao = acao;
    this.demandaId = this.route.snapshot.params.id;
    this.modal.show();
  }

  save() {
    this.saving = true;
    switch (this.acao) {
      case 1:
        this.aprovar();
        break;
      case 2:
        this.reprovar();
        break;
      case 4:
        this.justificar();
        break;
      default:
        this.justificar();
        break;
    }
  }

  close(): void {
    this.modal.hide();
  }

  aprovar() {
    this._avaliacoesDeQualidadesServiceProxy.aprovar(this.justificativa, this.guidsSelecionados).subscribe(() => {
      this.saving = false;
      this.modalSave.emit(null);
      this.modal.hide();
    });
  }

  reprovar() {
    this._avaliacoesDeQualidadesServiceProxy.reprovar(this.justificativa, false, this.guidsSelecionados).subscribe(() => {
      this.saving = false;
      this.modalSave.emit(null);
      this.modal.hide();
    });
  }

  justificar() {
    this._avaliacoesDeQualidadesServiceProxy.justificar(this.justificativa, this.guidsSelecionados).subscribe(() => {
      this.saving = false;
      this.modalSave.emit(null);
      this.modal.hide();
    });
  }
}
