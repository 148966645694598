<div [@routerTransition] id="system-version-control" class="overflow-auto">
    <div id="subheader" class="kt-subheader kt-grid__item">
        <div id="subheader-main" class="kt-subheader__main">
            <h3 id="subheader-title" class="kt-subheader__title">
                <span id="subheader-txt" class="subheader-txt">{{l("ToolVersion")}}</span>
            </h3>
            <span id="subheader-separator" class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span id="subheader-desc" class="kt-subheader__desc">
                {{l("VersionList")}}
            </span>
        </div>        
    </div>
    <div id="system-version-control-content" class="kt-content system-version-control-content">
        <div id="portlet" class="kt-portlet kt-portlet--mobile">
            <div id="portlet-body" class="kt-portlet__body">
                <div id="create-version-control" class="create-version-control">
                    <button id="create-btn" class="btn btn-primary" type="submit" (click)="createVersionControl()">
                        <i id="plus-icon" class="fa fa-plus"></i>{{l("AddVersion")}}
                    </button>
                </div>     
                <div id="control-version-table" class="control-version-table">
                    <p-table #ct id="p-table-control-version" class="p-table-control-version" [value]="versionControlList" 
                             [rows]="5" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100,500]" [loading]="loading">
                        <ng-template pTemplate="header">
                            <tr id="table-header-row" class="table-header-row">
                                <th id="th-title" class="th-title">{{l("VersionTitle")}}</th>
                                <th id="th-responsable" class="th-requestBy">{{l("VersionControlResponsible")}}</th>
                                <th id="th-date" class="th-date">{{l("VersionControlDate")}}</th>
                                <th id="th-status" class="th-date">{{l("VersionControlStatus")}}</th>
                                <th id="th-actions" class="th-actions">{{l("VersionControlActions")}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-versionControl let-i>
                            <tr id="table-body-row" class="table-body-row">
                                <td id="td-title" class="td-title">{{versionControl.titulo}}</td>
                                <td id="td-requetBy" class="td-requestBy">{{versionControl.responsavel}}</td>
                                <td id="td-date" class="td-date">{{getLocaleDateHourNotificacao(versionControl.dataHora)}}</td>
                                <td id="td-status" class="td-status">{{versionControl.status}}</td>
                                <td id="td-actions" class="td-actions">
                                    <div class="btn-group dropdown" dropdown container="body">
                                        <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle>
                                            <i class="fa fa-cog"></i><span class="caret"></span> {{l("VersionControlActions")}}
                                        </button>
                                        <ul class="dropdown-menu versionamento-dropdown-menu" *dropdownMenu>
                                            <li>
                                                <a href="javascript:;"
                                                (click)="createVersionamentoModal.show(versionControl.id)">{{l('VersionControlEdit')}}</a>
                                            </li>
                                            <li>
                                                <a href="javascript:;"
                                                (click)="deployCustomersModal.show(versionControl.id)">{{l('VersionControlDeployOnClients')}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div id="table-total-itens" class="flex align-items-center justify-content-between">
                                {{l("Total")}}: {{versionControlList ? versionControlList.length : 0 }}
                            </div>
                        </ng-template>
                    </p-table>
                </div>    
            </div>
        </div>
    </div>
    <create-versionamento-modal #createVersionamentoModal (closed)="reloadPage($event)"></create-versionamento-modal>
    <deploy-customers-modal #deployCustomersModal (closed)="reloadPage($event)"></deploy-customers-modal>
</div>