import { Component, Injector, ViewEncapsulation, ViewChild, OnInit } from "@angular/core";
import { GestaoMudancaNativaServiceProxy } from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Table } from "primeng/table";
import { Paginator } from "primeng/paginator";
import { LazyLoadEvent } from "primeng/api";

@Component({
    templateUrl: './historicoAprovacoes.component.html',
    styleUrls: ['./historicoAprovacoes.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})

export class ApprovalHistoryComponent extends AppComponentBase implements OnInit {

    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    constructor(
        injector: Injector,
        private _serviceGestaoMudancaNativa: GestaoMudancaNativaServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void { }

    getStatusColor(status: string) {
        switch (status) {
            case 'Aprovado':
                return 'success';
            case 'Aprovado automaticamente':
                return 'success';
            case 'Aguardando aprovação':
                return 'warning';
            case 'Em análise':
                return 'warning';
            case 'Reprovado':
                return 'danger';
            case 'Aguardando análise':
                return 'warning';
            case 'Concluído':
                return 'success';
            default:  return 'info'; 
        }
    }
    
    resetarFiltros() {
        this.getAvaliacoesGestaoMudancaNativa();
    }

    getAvaliacoesGestaoMudancaNativa(event?: LazyLoadEvent) {    
        if (event && this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();
        this._serviceGestaoMudancaNativa.getListApprovalChangeManagementHistory(
            this.primengTableHelper.getSorting(this.dataTable),
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event)
            ).subscribe(result => {
                this.primengTableHelper.totalRecordsCount = result.totalCount;
                this.primengTableHelper.records = result.items;
                this.primengTableHelper.hideLoadingIndicator();
            });
    }

    getLocaleDateHour(date: moment.Moment) {
        const dateString = date ? date.toString() : "";
        return new Date(dateString).toLocaleString();
    }

    recarregar(event?: LazyLoadEvent) {
        this.getAvaliacoesGestaoMudancaNativa(event);
    }

    reloadPage(ev?: any) {
        location.reload();
    }
}