<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{l("PickAmbiente")}}</span>
                </h4>
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
                    <div class="kt-portlet">
                        <div class="kt-portlet__body">
                            <form class="kt-form" autocomplete="off">
                                <div>
                                    <div class="row align-items-center mb-4">
                                        <div class="col-xl-12">
                                            <div class="form-group m-form__group align-items-center">
                                                <div class="input-group">
                                                    <input [(ngModel)]="filterText" name="filterText" autoFocus class="form-control m-input" [placeholder]="l('SearchWithThreeDot')" type="text">
                                                    <span class="input-group-btn">
                                                        <button (click)="getAll()" class="btn btn-primary" type="submit"><i class="flaticon-search-1"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div class="row align-items-center">
                                <div class="primeng-datatable-container"
                                     [busyIf]="primengTableHelper.isLoading">
                                    <p-table #dataTable
                                                 (onLazyLoad)="getAll($event)"
                                                 [value]="primengTableHelper.records"
                                                 rows ="5"
                                                 [paginator]="false"
                                                 [lazy]="true"
											 	 [scrollable]="true"
												 ScrollWidth="100%"
												 [responsive]="primengTableHelper.isResponsive"
												 [resizableColumns]="primengTableHelper.resizableColumns">
									<ng-template pTemplate="header">
										<tr>
											<th style="width: 130px" >{{l('Actions')}}</th>
											<th style="width: 150px" pSortableColumn="displayName">
												{{l('Ambiente')}}
												<p-sortIcon field="displayName"></p-sortIcon>
											</th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-record="$implicit">
										<tr>
											<td style="width: 130px">
												<div class="btn-group dropdown" normalizePosition>
                                                    <button class="btn btn-sm btn-primary" type="button" (click)="setAndSave(record)">
                                                        {{l("Select")}}
                                                    </button>
                                                </div>
											</td>
											<td style="width:150px">
												{{record.displayName}}
											</td>
										</tr>
									</ng-template>
                                    </p-table>
									<div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
										{{l('NoData')}}
									</div>
                                    <div class="primeng-paging-container">
                                        <p-paginator rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                                     #paginator
                                                     styleClass="ambiente-lookup-table-paginator"
                                                     (onPageChange)="getAll($event)"
                                                     [totalRecords]="primengTableHelper.totalRecordsCount"
                                                     [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                                        </p-paginator>
                                        <span class="total-records-count">
                                            {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>

            <div class="modal-footer">
                <button [disabled]="saving" type="button" class="btn btn-primary blue" (click)="close()">{{l("Close")}}</button>
            </div>
        </div>
    </div>
</div>
