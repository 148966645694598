import { Component, EventEmitter, HostListener, Injector, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { ScheduleDeployModalComponent } from "@app/main/deploy/configurationTransportScheduling/scheduleDeploy/scheduleDeploy-modal.component";
import { AppComponentBase } from "@shared/common/app-component-base";
import { CreateOrEditGestaoDeMudancaNativaDto, GestaoMudancaNativaServiceProxy, TipoMudanca, RiscoMudanca, AlteracaoDeStatusDaDemandaDto, JanelaDeDeployServiceProxy, TransportDataDto, AgendamentoDeployDto, GetGestaoMudancaNativaArquivoDto } from "@shared/service-proxies/service-proxies";
import { rest } from "lodash";
import moment from "moment";
import { ModalDirective } from "ngx-bootstrap/modal";
import { TreeNode } from 'primeng/api';
import { InformationDeployModalComponent } from "./information-deploy/informationDeploy.component";
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { AppConsts } from "@shared/AppConsts";
import { IAjaxResponse, TokenService } from "abp-ng2-module";
import * as uuid from 'uuid';

interface Types {
  name: string;
  value: string;
}

@Component({
  selector: 'create-change-manager-native-modal',
  templateUrl: './create-change-manager-native-modal.component.html',
  styleUrls: ['./create-change-manager-native-modal.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class CreateChangeManagerNativeModalComponent extends AppComponentBase implements OnInit {
  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.onFilesAdded(null,files);
    }
  }

  @ViewChild('changeManagerNativeModal', { static: true }) modal: ModalDirective;
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('informationDeployModal', { static: true }) informationDeployModal: InformationDeployModalComponent;

  changeManager: CreateOrEditGestaoDeMudancaNativaDto = new CreateOrEditGestaoDeMudancaNativaDto();
  alteracaoDeStatusDaDemandaDto: AlteracaoDeStatusDaDemandaDto = new AlteracaoDeStatusDaDemandaDto();
  loading: boolean = false;
  saving: boolean = false;
  validations = { risk: false }
  changeRequester: string;
  treeDepartamentRequired: TreeNode[] = [];
  selectedtreeDepartamentRequired: TreeNode[] = [];
  treeDepartamentOptional: TreeNode[] = [];
  selectedtreeDepartamentOptional: TreeNode[] = [];
  datetimeDeploy: Date;
  plannedEnum: boolean;
  anexosAdicionados: GetGestaoMudancaNativaArquivoDto[] = [];
  public uploader: FileUploader;
  private _uploaderOptions: FileUploaderOptions = { url: "" };
  guidArquivoAnexo: string;
  schedulingCheck: boolean = false;
  windowsTransport: string = "";
  isWindowsTransport: boolean = false;
  isPlanned: boolean = false;
  isRisk: boolean = false;
  
  types: Types[] = [];
  typesRisk: Types[] = [];

  canSaveStatus: boolean = true;

  get enumTipoMudanca() {
    return TipoMudanca;
  }

  get enumRiscoMudanca() {
    return RiscoMudanca;
  }

  get isInvalid() {
    return Object.keys(this.validations).map(key => this.validations[key]).some(item => !item)
  }

  ngOnInit(): void {
    this.types = [
      { name: 'Manual', value: TipoMudanca.Manual },
      { name: 'Emergencial', value: TipoMudanca.Emergencial },
      { name: 'Janela de Transporte', value: TipoMudanca.JanelaDeTransporte },
      { name: 'Planejado', value: TipoMudanca.Planejado }
    ];

    this.typesRisk = [
      { name: 'Nenhum', value: RiscoMudanca.Nenhum },
      { name: 'Muito baixo', value: RiscoMudanca.MuitoBaixo },
      { name: 'Baixo', value: RiscoMudanca.Baixo },
      { name: 'Moderado', value: RiscoMudanca.Moderado },
      { name: 'Alto', value: RiscoMudanca.Alto },
      { name: 'Muito alto', value: RiscoMudanca.MuitoAlto }
    ];
  }

  constructor(injector: Injector,
    private _service: GestaoMudancaNativaServiceProxy,
    private _deployService: JanelaDeDeployServiceProxy,
    private _tokenService: TokenService) {
    super(injector);
  }

  close(): void {
    this.setValues();
    this.modal.hide();
    this.closed.emit();
  }

  async show(demandaId: string, alteracaoDeStatusDaDemandaDto: AlteracaoDeStatusDaDemandaDto) {
    this.loading = true;
    this.changeManager.demandaId = demandaId;
    this.alteracaoDeStatusDaDemandaDto = alteracaoDeStatusDaDemandaDto;
    this.treeDepartamentRequired = [];
    this.selectedtreeDepartamentRequired = [];
    this.treeDepartamentOptional = [];
    this.selectedtreeDepartamentOptional = [];
    this.getDemand();
    this.setValues();
    this.setTreeNodeRequired();
    this.setTreeNodeOptional();
    this.loading = false;
    this.modal.show();
  }

  save() {
    this.loading = true;
    if (!this.canSaveStatus){
      this.notify.warn('Todos os departamentos selecionados devem conter ao menos um aprovador.');
      this.loading = false;
      return;
    }

    if (this.selectedtreeDepartamentRequired.length <= 1 && this.selectedtreeDepartamentOptional.length <= 1) {
      this.notify.warn('Deve selecionar pelo menos um departamento e um aprovador.');
      this.loading = false;
      return;
    }

    if (this.changeManager.tipoMudanca == TipoMudanca.JanelaDeTransporte && !this.schedulingCheck){
      this.notify.warn('Não existe agendamento cadastrado, verifique o cadastro.');
      this.loading = false;
      return;
    }

    if (this.changeManager.descricaoDemanda.length < 25){
      this.notify.warn('Descrição deve conter 25 caracteres.');
      this.loading = false;
      return;
    }
    this.changeManager.arquivos = this.anexosAdicionados;

    var transportDataDto: TransportDataDto = new TransportDataDto;
    transportDataDto.transportType = this.changeManager.tipoMudanca;
    transportDataDto.demandaId = this.alteracaoDeStatusDaDemandaDto.demandaId;
    transportDataDto.id = "00000000-0000-0000-0000-000000000000";
    transportDataDto.dateTimeTransport = moment(this.datetimeDeploy);
    this._deployService.checkTypesOfTransport(transportDataDto)
      .subscribe(res => {
        let listIdsRequired: string[] = [];
        let listIdsOptional: string[] = [];
        this.selectedtreeDepartamentRequired.forEach(dep => { listIdsRequired.push(dep.data.id); });
        this.changeManager.listaObrigatorios = listIdsRequired;

        this.selectedtreeDepartamentOptional.forEach(depOp => { listIdsOptional.push(depOp.data.id); });
        this.changeManager.listaOpcional = listIdsOptional;

        if (res == "") {
          this._service.createOrUpdateGestaoMudancaNativa(this.changeManager).subscribe(() => {
            this.setValues();
            this.loading = false;
            this.modal.hide();
            this.saved.emit(this.alteracaoDeStatusDaDemandaDto);
          });
        } else {
          this.informationDeployModal.show(res, this.changeManager, null, this.alteracaoDeStatusDaDemandaDto, false, moment(this.datetimeDeploy), false);
        }
      });
  }

  setValues() {
    let changeManager = new CreateOrEditGestaoDeMudancaNativaDto();
    this.changeRequester = this.appSession.user.name;
    changeManager.demandaId = this.alteracaoDeStatusDaDemandaDto.demandaId;
    changeManager.riscoMudanca = this.enumRiscoMudanca.Nenhum;
    changeManager.tipoMudanca = this.enumTipoMudanca.Manual;
    this.changeManager = changeManager;
    this.isWindowsTransport = false;
    this.isPlanned = false;
    this.changeManager.riscoMudanca = RiscoMudanca.Nenhum;
    this.changeManager.tipoMudanca = TipoMudanca.Manual;
    this.isRisk = false;
  }

  getDemand() {
    this._service.getDemanda(this.alteracaoDeStatusDaDemandaDto.demandaId).subscribe(result => {
      this.changeManager.tituloDemanda = result.titulo;
    });
  }

  setTreeNodeRequired() {
    this._service.getDepartamentosObrigatorios(this.alteracaoDeStatusDaDemandaDto.demandaId).subscribe(departaments => {
      departaments.forEach(departament => {
        let childrenList: TreeNode[] = [];

        //Preenche o filho - usuários obrigatórios
        departament.usuariosAprovadores.forEach(user => {
          let childrenRequired: TreeNode = {};
          childrenRequired.label = user.nomeUsuario;
          childrenRequired.data = user;
          childrenRequired.selectable = false;
          this.selectedtreeDepartamentRequired.push(childrenRequired);
          childrenList.push(childrenRequired);
        });
        
        //Preenche o filho - usuários opcionais
        departament.usuariosAprovadoresOpcionais.forEach(userOp => {
          let children: TreeNode = {};
          children.label = userOp.nomeUsuario;
          children.data = userOp;
          
          //if (departament.levelApproval){
             this.selectedtreeDepartamentRequired.push(children);
          //}

          childrenList.push(children);
        });

        //Preenche o Pai
        let tree: TreeNode = {};
        tree.label = departament.descricao;
        tree.data = departament;
        tree.children = childrenList;
        tree.selectable = false;
        this.selectedtreeDepartamentRequired.push(tree);
        this.treeDepartamentRequired.push(tree);
      });
    });
  }

  setTreeNodeOptional() {
    this._service.getDepartamentosOpcionais(this.alteracaoDeStatusDaDemandaDto.demandaId).subscribe(departaments => {
      departaments.forEach(departament => {
        let childrenList: TreeNode[] = [];

        //Preenche o filho - usuários obrigatórios
        departament.usuariosAprovadores.forEach(user => {
          let childrenRequired: TreeNode = {};
          childrenRequired.label = user.nomeUsuario;
          childrenRequired.data = user;
          childrenRequired.selectable = false;
          this.selectedtreeDepartamentRequired.push(childrenRequired);
          childrenList.push(childrenRequired);
        });
        
        //Preenche o filho - usuários opcionais
        departament.usuariosAprovadoresOpcionais.forEach(userOp => {
          let children: TreeNode = {};
          children.label = userOp.nomeUsuario;
          children.data = userOp;

          childrenList.push(children);
        });

        //Preenche o Pai
        let tree: TreeNode = {};
        tree.label = departament.descricao;
        tree.data = departament;
        tree.children = childrenList;

        this.treeDepartamentRequired.push(tree);
      });
    });
  }

  nodeSelect(event) {
    if (event.node.children === undefined) {
      this.selectedtreeDepartamentOptional.push(event.node);
      this.selectedtreeDepartamentOptional.push(event.node.parent);
    } else {
      event.node.children.forEach(user => {
        this.selectedtreeDepartamentOptional.push(user);
      });
    }
  }

  nodeUnselect(event) {
    if (event.node.children === undefined) {
      let index = this.selectedtreeDepartamentOptional.findIndex(s => event.node === s);
      this.selectedtreeDepartamentOptional.splice(index, 1);
    } else {
      this.selectedtreeDepartamentOptional = [];
    }
  }

  nodeSelectRequired(event) {
    this.updateCanSaveStatus();
  }

  nodeUnselectRequired(event) {
    if (event.node.children === undefined) {
      let index = this.selectedtreeDepartamentRequired.findIndex(s => event.node === s);
      
      if (index !== -1) {
        this.selectedtreeDepartamentRequired.splice(index, 1);
      }
    } else {
      this.selectedtreeDepartamentRequired = [];
    }

    this.updateCanSaveStatus();
  }

  updateCanSaveStatus() {
    this.canSaveStatus = this.treeDepartamentRequired.every(node => 
      node.children && node.children.some(child => 
        this.selectedtreeDepartamentRequired.some(selectedNode => selectedNode === child)
      )
    );
  }

  canSave() {
    return this.canSaveStatus;
  }
  
  removerAnexo(anexo: GetGestaoMudancaNativaArquivoDto) {
    this.message.confirm('', this.l('RemoverAnexo'), isConfirmed => {
        if (isConfirmed) {
            this.anexosAdicionados = this.anexosAdicionados.filter((x) => x.anexoId !== anexo.anexoId);
        }
    });
  }

  onFilesAdded(event: any, fileList:FileList | null) {

    const files: File[] = [];

    if (fileList != null) {
        for (let i = 0; i < fileList.length; i++) {
            files.push(fileList[i]);
        }
    }

    if(event != null){
        const input = event.target as HTMLInputElement;
        if (input.files) {
            for (let i = 0; i < input.files.length; i++) {
                files.push(input.files[i]);
            }
        }
    }

    this.uploader = new FileUploader({
        url: AppConsts.remoteServiceBaseUrl + '/File/UploadAttachment'
    });

    this.uploader.clearQueue();
    if (files.length > 0) {
        this.uploader.addToQueue(files);
    } 

    this._uploaderOptions.autoUpload = false;
    this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
    this._uploaderOptions.removeAfterUpload = true;
    this._uploaderOptions.url = AppConsts.remoteServiceBaseUrl + '/File/UploadAttachment'

    files.forEach(file => {
        this.uploader.onAfterAddingFile = file => {
            file.withCredentials = false;
        };

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            this.guidArquivoAnexo = uuid.v4();
            form.append('FileType', fileItem.file.type);
            form.append('FileName', fileItem.file.name);
            form.append('FileToken', this.guidArquivoAnexo);
        };

        this.uploader.setOptions(this._uploaderOptions);
        this.uploader.onSuccessItem = (item, response, status) => {
            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                let inputVincularAnexo: GetGestaoMudancaNativaArquivoDto = new GetGestaoMudancaNativaArquivoDto();
                inputVincularAnexo.fileType = item.file.type;
                inputVincularAnexo.nomeAnexo = item.file.name;
                inputVincularAnexo.anexoId = this.guidArquivoAnexo;
                this.anexosAdicionados.push(inputVincularAnexo);
            } else {
                this.message.error(resp.error.message);
            }
        };

        this.uploader.uploadAll();
    });
  }

  getSchedule() {
    if (this.changeManager.tipoMudanca == TipoMudanca.JanelaDeTransporte){
      this._deployService.schedulingCheck()
          .subscribe(res => { this.schedulingCheck = res });
    }
  }

  onValueChange(newValue: any) {
    this.changeManager.tipoMudanca = newValue.value;
    this.getSchedule();

    if (newValue.value == TipoMudanca.JanelaDeTransporte){
      this._deployService.getScheduling()
          .subscribe(res => { this.windowsTransport = res.dateTimeTransport });
    }
    
    this.isWindowsTransport = newValue.value == TipoMudanca.JanelaDeTransporte ? true : false;
    this.isPlanned = newValue.value == TipoMudanca.Planejado ? true : false;
  }

  onValueChangeRisk(newValue: any) {
    this.changeManager.riscoMudanca = newValue.value;    
    this.isRisk = newValue.value != RiscoMudanca.Nenhum ? true : false;
  }

  closeInformation() {
    this.loading = false;
  }

  finalizeDemand() {
    this.setValues();
    this.loading = false;
    this.modal.hide();
    this.saved.emit(this.alteracaoDeStatusDaDemandaDto);
  }
}