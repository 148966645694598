import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ConfigAvaliadorCodeReviewDto, ConfigAvaliadorCodeReviewSelect2, ConfigCodeReviewDto, ConfigCriterioCodeReviewDto, ConfiguracaoCodeReviewServiceProxy } from "@shared/service-proxies/service-proxies";
import { Paginator } from "primeng/paginator";
import { Table } from "primeng/table";

@Component({
    selector: 'code-review-config',
    templateUrl: './codeReview.component.html',
    styleUrls: ['./codeReview.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class CodeReviewComponent extends AppComponentBase implements OnInit {

    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    constructor(
        injector: Injector,
        private _configuracaoCodeReviewServiceProxy: ConfiguracaoCodeReviewServiceProxy,
    ) {
        super(injector);
    }

    loading = false;

    checked: boolean;
    listaCriterio : ConfigCriterioCodeReviewDto[] = [];
    
    listaFiltrada : ConfigCriterioCodeReviewDto[] = [];
    
    criterioId : ConfigCriterioCodeReviewDto;

    users: ConfigAvaliadorCodeReviewSelect2[] = [];
    usersSelected: ConfigAvaliadorCodeReviewSelect2[] = [];
    usuariosAprovadorCodeReviewDto: ConfigAvaliadorCodeReviewDto[] = [];

    criteriaTitle: string;
    codeReviewConfiguration: ConfigCodeReviewDto = new ConfigCodeReviewDto();

    isEditEnabled : boolean = false;

    criterioEditadoIndex !: any;
    
    ngOnInit(): void {
        this.selectAvaliadorUser();
        this.getConfig();
    }

    saveConfig(){
        this.loading = true;

        this.usuariosAprovadorCodeReviewDto = [];
        this.usersSelected.forEach(el => {
            let usuarioAvaliadorDto = new ConfigAvaliadorCodeReviewDto();
            usuarioAvaliadorDto.usuarioId = parseInt(el.value);
            this.usuariosAprovadorCodeReviewDto.push(usuarioAvaliadorDto);
        });
        this.codeReviewConfiguration.ativo = this.checked;
        this.codeReviewConfiguration.listaConfigAvaliadorCodeReview = this.usuariosAprovadorCodeReviewDto;
        this.codeReviewConfiguration.listaConfigCriterioCodeReview = this.listaCriterio;

        this._configuracaoCodeReviewServiceProxy.inserirConfiguracaoCodeReview(this.codeReviewConfiguration)
            .subscribe(() => {
                this.codeReviewConfiguration.listaConfigAvaliadorCodeReview = [];
                this.codeReviewConfiguration.listaConfigCriterioCodeReview = [];
                this.usersSelected = [];
                this.listaCriterio = [];
                this.getConfig();
                this.notify.success(this.l('SuccessfullySaved'));
            })
    }

    getConfig(){
        this.loading = true;
        this._configuracaoCodeReviewServiceProxy.getConfiguracaoCodeReview()
            .subscribe(res => {
                if(res.configCodeReviewId != "00000000-0000-0000-0000-000000000000"){
                    this.loading = false;
                    this.checked = res.ativo;
                    this.usuariosAprovadorCodeReviewDto = res.listaConfigAvaliadorCodeReview;
                    this.listaCriterio = res.listaConfigCriterioCodeReview;
                    this.getUsuariosSelecionados(res.id);
                    this.listaFiltrada = this.listaCriterio.filter(s=> !s.removido);
                }
            });
    }

    getUsuariosSelecionados(id:string){
        this._configuracaoCodeReviewServiceProxy.getUsuariosSelecionados(id)
            .subscribe(res => {
                this.usersSelected = res;
            })
    }

    selectAvaliadorUser(): void {
        this._configuracaoCodeReviewServiceProxy
        .getAllUsers().subscribe(x => {
            this.users = x;
        });
    }

    clear(item: ConfigAvaliadorCodeReviewSelect2) {
        this.usersSelected = [].concat(this.usersSelected.filter(x => x.value !== item.value));
    }

    addCriteria(){
        let criterio = new ConfigCriterioCodeReviewDto;

        criterio.titulo = this.criteriaTitle;
        
        this.listaCriterio.push(criterio);
        this.listaFiltrada.push(criterio);  
        this.listaFiltrada = this.listaCriterio.filter(s=> !s.removido);
        
        this.criteriaTitle = "";
        this.saveConfig();
        
        this.notify.success(this.l('SuccessfullyAdded'));
    }

    onEditCriterio(criterio: string, i:number){
        this.isEditEnabled = true;
        let editCriterio = this.listaCriterio.find(s => s.id == criterio);

        this.criteriaTitle = editCriterio.titulo;

        this.criterioEditadoIndex = i
    }

    updateCriterio() {
        this.isEditEnabled = false;

        let criterioEditado = this.criterioEditadoIndex;
        criterioEditado.removido = true;

        //Cria um novo critério para substituir o editado e atualiza a lista para ele não aparecer nas listas de avaliação e historicos
        let criterioNovo = new ConfigCriterioCodeReviewDto();
        criterioNovo.titulo = this.criteriaTitle;
        criterioNovo.removido = false;
        criterioNovo.sequencia = criterioEditado.sequencia;
        
        let indexCriterioEditado = this.findIndexById(criterioEditado.id);
        this.listaFiltrada[indexCriterioEditado] = criterioNovo;
        this.listaCriterio.push(criterioNovo);
        
        this.listaCriterio = [...this.listaCriterio];
        this.listaFiltrada = this.listaCriterio.filter(s=> !s.removido);

        this.criterioEditadoIndex = undefined;
        this.criteriaTitle = "";
        this.saveConfig();
    }

    cancelUpdateCriterio() {
        this.criteriaTitle = "";
        this.isEditEnabled = false;
    }

    deleteCriterio(criterio: ConfigCriterioCodeReviewDto){
        let criterioDeletado = this.listaCriterio.find(s=> s.id == criterio.id);

        criterioDeletado.removido = true;

        this.listaCriterio = [...this.listaCriterio];

        this.listaFiltrada = this.listaCriterio.filter(s=> !s.removido);

        if(!this.isEditEnabled){
            this.notify.success(this.l('SuccessfullyDeleted'));
        }
        this.saveConfig();
    }

    //Encontra a posição index do elemento
    findIndexById(id: string): number {
        let index = 0;
        for (let i = 0; i < this.listaCriterio.length; i++) {
            if (this.listaCriterio[i].titulo === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    /*getTotalListaCriterios(){
        let lista = this.listaCriterio.filter(s=> s.removido === false);
        return lista.length;
    }*/
}