import { UserServiceProxy, SavesResponsiblesProvidersInput, EntityDto } from '@shared/service-proxies/service-proxies';
import { ResponsaveisServiceProxy, FornecedorDto, Select2ItemDto } from './../../../../../shared/service-proxies/service-proxies';
import { AppComponentBase } from 'shared/common/app-component-base';
import { Component, Injector, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-view-responsaveis',
    templateUrl: './view-responsaveis.component.html'
})
export class ViewResponsaveisComponent extends AppComponentBase {
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @ViewChild('paginacao', { static: true }) paginator: Paginator;
    @ViewChild('dataTable', { static: true }) dataTable: Table;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    clientFornecedorId: string;

    responsibles$ = new Array<Select2ItemDto>();
    responsibleSelected = new Array<Select2ItemDto>();
    responsiblesToSave: SavesResponsiblesProvidersInput = new SavesResponsiblesProvidersInput();

    filter = '';
    active = false;
    saving = false;


    constructor(
        injector: Injector,
        private _responsaveisProxy: ResponsaveisServiceProxy,
        private _usersProxy: UserServiceProxy
    ) {
        super(injector);
    }

    show(clienteFornecedorId?: string) {
        this.responsibleSelected = new Array<Select2ItemDto>();
        this.clientFornecedorId = clienteFornecedorId;
        this.getAllUser();
        this.getAllUserResponsibles();
        this.modal.show();
    }

    close() {
        this.modal.hide();
        this.modalSave.emit(null);
    }

    save() {
        this.responsiblesToSave.clienteFornecedorId = this.clientFornecedorId;
        this.responsiblesToSave.listNewResponsibles = [];
        this.responsibleSelected.forEach(el => {
            this.responsiblesToSave.listNewResponsibles.push(el.value);
        });

        this._responsaveisProxy.saveResponsibles(this.responsiblesToSave)
            .subscribe(() => {
                this.getAllUser();
                this.responsibleSelected = new Array<Select2ItemDto>();
                this.notify.success(this.l('ResponsaveisSalvosSucesso'));
                this.close();
            });
    }

    remove(responsavelId: string) {
        this._responsaveisProxy.delete(responsavelId)
            .subscribe(() => {
                this.notify.success(this.l('ResponsavelRemovido'), this.l('Responsvel'));
                this.getAllUser();
            });
    }

    getAllUser() {
        this._usersProxy
            .getAllUser()
            .subscribe(result => {
                this.responsibles$ = result;
            }, err => { this.close();});
    }

    getAllUserResponsibles() {
        this._responsaveisProxy
            .getAllByClienteFornecedorId(this.clientFornecedorId)
            .subscribe(result => {
                this.responsibleSelected = result;
            });
    }
}
