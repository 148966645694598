import { Component, Injector, ViewEncapsulation, ViewChild, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';
import { LazyLoadEvent } from 'primeng/api';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CreateOrEditChangeRequestModalComponent } from "./create-or-edit-change-request-modal/create-or-edit-change-request-modal.component";
import { ChangeRequestServiceProxy, CreateChangeRequestDto, EnumRdmStatus } from "@shared/service-proxies/service-proxies";
import { RescheduleChangeRequestModalComponent } from "./rescheduleChangeRequest/reschedule-change-request-modal.component";

@Component({
    templateUrl: './list-change-request.component.html',
    styleUrls: ['./list-change-request.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})

export class CreateOrEditChangeRequestComponent extends AppComponentBase implements OnInit {

    @ViewChild('createEditChangeRequestModal', { static: true }) createEditChangeRequestModal: CreateOrEditChangeRequestModalComponent;
    @ViewChild('rescheduleChangeRequestModal', { static: true }) rescheduleChangeRequestModal: RescheduleChangeRequestModalComponent;
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    titleFilter: string = '';
    statusFilter: number = -1;
    maxDatetimeFilter: moment.Moment;
    minDatetimeFilter:moment.Moment;
    createChangeRequestDto: CreateChangeRequestDto = new CreateChangeRequestDto;

    constructor(
        injector: Injector,
        private _changeRequestAppService: ChangeRequestServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void { }
    
    resetarFiltros() {
    }

    getLocaleDateHour(date: moment.Moment) {
        if (date === undefined) return "";

        const dateString = date ? date.toString() : "";
        return new Date(dateString).toLocaleString();
    }

    reloadGrid(event?: LazyLoadEvent) {
        this.getAllChangeRequests(event);
    }

    getAllChangeRequests(event?: LazyLoadEvent) {    
        if (event && this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this._changeRequestAppService.getAllChangeRequests(
            this.titleFilter,
            this.statusFilter,
            this.maxDatetimeFilter,
            this.minDatetimeFilter,
            this.primengTableHelper.getSorting(this.dataTable),
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event)
        ).subscribe(result => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            this.primengTableHelper.records = result.items;
            this.primengTableHelper.hideLoadingIndicator();
        });
    }

    getStatusColor(status: string) {
        switch (status) {
            case 'Aprovado':
                return 'success';
            case 'Em análise':
                return 'warning';
            case 'Reagendado':
                return 'warning';
            case 'Reprovado':
                return 'danger';
            case 'Cancelado':
                return 'danger';
            default:  return 'info'; 
        }
    }

    reloadPage(ev: any) {
        this.reloadGrid(ev);
    }

    showCreateRequestModal(button: boolean): void {
        this.createEditChangeRequestModal.show(this.createChangeRequestDto, button);
    }

    sendToApproversChangeRequest(changeRequestId: string) {
        this._changeRequestAppService.sentToApprovers(changeRequestId)
            .subscribe(res => {
                this.reloadGrid();
                this.notify.success('RDM enviada para aprovação.');
            })
    }

    editChangeRequest(changeRequestId: string) {
        this._changeRequestAppService.getChangeRequestData(changeRequestId)
            .subscribe(res => {
                this.createChangeRequestDto = res;
                this.createEditChangeRequestModal.show(this.createChangeRequestDto, false);
            });
    }

    cancelChangeRequest(changeRequestId: string) {
        this._changeRequestAppService.cancelChangeRequest(changeRequestId)
            .subscribe(res => {
                this.reloadGrid();
                this.notify.success('RDM cancelada com sucesso.');
            })
    }

    rescheduleChangeRequest(changeRequestId: string) {
        this.rescheduleChangeRequestModal.show(changeRequestId);
    }

    getStatesSendToApproval(status: EnumRdmStatus): Boolean {
        switch (status.toString()) {
            case "Criado":
                return true;   
            default:
                return false;
        }
    }

    getStatesEditChange(status: EnumRdmStatus): Boolean {
        switch (status.toString()) {
            case "Criado":
                return true;     
            default:
                return false;
        }
    }

    getStatesCanceledChange(status: EnumRdmStatus): Boolean {
        switch (status.toString()) {
            case "Criado":
                return true; 
            case "Reprovado":
                return true;      
            default:
                return false;
        }
    }

    getStatesRescheduleChange(status: EnumRdmStatus): Boolean {
        switch (status.toString()) {
            case "Em análise":
                return true;      
            default:
                return false;
        }
    }
}