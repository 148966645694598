import { Component, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ConfigDeployAutomaticoServiceProxy, UsuariosAprovadoresDeployDto, UsuariosAprovadoresDeploySelect2 } from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs/operators";

@Component({
    selector: 'appprovalUserDeploy',
    templateUrl: './appprovalUserDeploy.component.html',
    styleUrls: ['./appprovalUserDeploy.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class AppprovalUserDeployComponent extends AppComponentBase implements OnInit {

    usersApproval: UsuariosAprovadoresDeploySelect2[] = [];
    usersApprovalSelected: UsuariosAprovadoresDeploySelect2[] = [];
    loading: boolean = false;

    constructor(
        injector: Injector,
        private _configDeployAutomaticoService: ConfigDeployAutomaticoServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.selectApprovalUser();
        this.getApprovalUsers();
    }

    clearUser(item: any) {
        this.usersApprovalSelected = [].concat(this.usersApprovalSelected.filter(x => x.value !== item.value));
    }

    selectApprovalUser(): void {
        this.usersApprovalSelected = [];
        this._configDeployAutomaticoService.getAllUsersDeploy().subscribe(x => { this.usersApproval = x; });
    }

    addApprovalUser() {
        let usuariosAprovadoresDeployDto = new UsuariosAprovadoresDeployDto();
        usuariosAprovadoresDeployDto.usuariosId = [];
        
        this.usersApprovalSelected.forEach(user => { usuariosAprovadoresDeployDto.usuariosId.push(Number(user.value)); });

        this._configDeployAutomaticoService.insertUpdateAprovadoresDeploy(usuariosAprovadoresDeployDto)
            .pipe(finalize(() => this.loading = false))
            .subscribe(x => {
                this.notify.success('Usuário salvo com sucesso!');
            })

    }

    getApprovalUsers() {
        this._configDeployAutomaticoService.getUsuariosAprovadores()
            .subscribe(res => { this.usersApprovalSelected = res });
    }
}