<div id="card" (click)="AvaliacaoModal()">
    <div id="top">
        <div id="title">
            <span>{{l("NPS")}}</span>
        </div>
    
        <div id="icon">
            <i class="fa fa-info-circle" tooltip="{{'NpsUserTenant' | localize}}"></i>
        </div>
    </div>   
    <div id="bot">
        <h3 [id]="getId()">
            {{averageUserNPS}}        
        </h3>
        <h3 id="company-average">
            /{{averageTenantNPS}}
        </h3>
    </div>
</div>