<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
	aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<form *ngIf="active" #fornecedorForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
				<div class="modal-header">
					<h4 class="modal-title">
						<span *ngIf="fornecedor.id">{{l("EditFornecedor")}}</span>
						<span *ngIf="!fornecedor.id">{{l("CreateNewFornecedor")}}</span>
					</h4>
					<button type="button" class="close" (click)="close()" aria-label="Close" [disabled]="saving">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<label for="Fornecedor_Nome">{{l("Name")}} *</label>
						<input type="text" id="Fornecedor_Nome" class="form-control" [(ngModel)]="fornecedor.nome"
							name="Nome" minlength="5" maxlength="200" #Fornecedor_Nome="ngModel"
							[ngClass]="{'is-invalid': Fornecedor_Nome.invalid, 'is-valid': !Fornecedor_Nome.invalid}"
							required />
						<validation-messages [formCtrl]="Fornecedor_Nome"></validation-messages>
					</div>

					<div class="form-group">
						<label for="Fornecedor_Cnpj">{{l("Cnpj")}} *</label>
						<input [readonly]="!isNew" type="text" id="Fornecedor_Cnpj" class="form-control" name="Cnpj" (keyup)="validarCNPJ($event)"  mask="00.000.000/0000-00" required
							#Fornecedor_Cnpj="ngModel" [(ngModel)]="fornecedor.cnpj"
							[ngClass]="{'is-invalid': (Fornecedor_Cnpj.invalid || !cnpjValido), 'is-valid': !Fornecedor_Cnpj.invalid}" />
						<validation-messages [formCtrl]="Fornecedor_Cnpj"></validation-messages>
						<label class="text-danger" *ngIf="!cnpjValido"> {{l("CnpjInvalido")}}</label>
						<label class="text-danger" *ngIf="!cnpjUnico && cnpjValido && isNew"> {{l("CnpjJaUtilizado")}}</label>
					</div>


					<div class="form-group">
						<label for="Fornecedor_Cep">{{l("Cep")}} *</label>
						<input type="text" id="Fornecedor_Cep" class="form-control" [(ngModel)]="fornecedor.cep"
							mask="00000-000" name="Cep" #Fornecedor_Cep="ngModel"
							[ngClass]="{'is-invalid': Fornecedor_Cep.invalid, 'is-valid': !Fornecedor_Cep.invalid}"
							required />
						<validation-messages [formCtrl]="Fornecedor_Cep"></validation-messages>
					</div>

					<div class="form-group">
						<label for="Fornecedor_Endereco">{{l("Endereco")}}</label>
						<input type="text" id="Fornecedor_Endereco" class="form-control"
							[(ngModel)]="fornecedor.endereco" name="Endereco" />
					</div>

					<div class="form-group">
						<label for="Fornecedor_Numero">{{l("Numero")}}</label>
						<input type="number" id="Fornecedor_Numero" class="form-control" [(ngModel)]="fornecedor.numero"
							name="Numero" />
					</div>

					<div class="form-group">
						<label for="Fornecedor_Complemento">{{l("Complemento")}}</label>
						<input type="text" id="Fornecedor_Complemento" class="form-control"
							[(ngModel)]="fornecedor.complemento" name="Complemento" />
					</div>
					<div class="form-group">
						<label for="Fornecedor_Bairro">{{l("Bairro")}}</label>
						<input type="text" id="Fornecedor_Bairro" class="form-control" [(ngModel)]="fornecedor.bairro"
							name="Bairro" />
					</div>

					<div class="form-group">
						<label for="Fornecedor_Cidade">{{l("Cidade")}} *</label>
						<input type="text" id="Fornecedor_Cidade" class="form-control" [(ngModel)]="fornecedor.cidade"
							name="Cidade" #Fornecedor_Cidade="ngModel"
							[ngClass]="{'is-invalid': Fornecedor_Cidade.invalid, 'is-valid': !Fornecedor_Cidade.invalid}"
							required />
						<validation-messages [formCtrl]="Fornecedor_Cidade"></validation-messages>
					</div>



					<div class="form-group">
						<label for="Fornecedor_Estado">{{l("Estado")}} *</label>
						<input type="text" id="Fornecedor_Estado" class="form-control" [(ngModel)]="fornecedor.estado"
							name="Estado" minlength="2" maxlength="2" #Fornecedor_Estado="ngModel"
							[ngClass]="{'is-invalid': Fornecedor_Estado.invalid, 'is-valid': !Fornecedor_Estado.invalid}"
							required />
						<validation-messages [formCtrl]="Fornecedor_Estado"></validation-messages>
					</div>

					<br />
					<div class="m-checkbox-list">
						<label for="Fornecedor_Ativo" class="kt-checkbox">
							<input id="Fornecedor_Ativo" type="checkbox" name="Ativo" [(ngModel)]="fornecedor.ativo">
							{{l("Ativo")}}
							<span></span>
						</label>
					</div>
				</div>
				<div class="modal-footer">
					<button [disabled]="saving" type="button" class="btn btn-default"
						(click)="close()">{{l("Cancel")}}</button>
					<button type="submit" class="btn btn-primary blue" [disabled]="!fornecedorForm.form.valid || !cnpjValido || (!cnpjUnico && isNew)"
						[buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i>
						<span>{{l("Save")}}</span></button>
				</div>
			</form>
		</div>
	</div>

</div>