import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-graphics',
  templateUrl: './graphics.component.html',
  styleUrls: ['./graphics.component.less']
})
export class GraphicsHostComponent implements OnInit {

  urlReport1: SafeUrl;
  urlReport2: SafeUrl;
  urlReport3: SafeUrl;
  urlReport4: SafeUrl;
  currentDate: string = '';
  initDate: string = '';

  constructor(
    private domSanitizer : DomSanitizer
  ) { 
    this.getDates();
  }

  ngOnInit() {
    this.urlReport1 = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://app1.qametrik.com/public/question/96926301-cff1-4534-9fa7-0c656e9c3bc8?DataInicial=${this.initDate}&DataFinal=${this.currentDate}#theme=transparent`);
    this.urlReport2 = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://app1.qametrik.com/public/question/04fa3ab4-4314-49c1-95c6-4184f1e9d3a6?DataInicial=${this.initDate}&DataFinal=${this.currentDate}#theme=transparent`);
    this.urlReport3 = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://app1.qametrik.com/public/question/73bc7861-bfbe-4669-b720-918ba9c21c24?DataInicial=${this.initDate}&DataFinal=${this.currentDate}#theme=transparent`);
    this.urlReport4 = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://app1.qametrik.com/public/question/510d31f6-5c4a-4e0e-bfdd-31f74446e048?DataInicial=${this.initDate}&DataFinal=${this.currentDate}#theme=transparent`);
  }

  getDates() {
    var currentDate = new Date();
    var initDate = new Date();
    
    initDate.setDate(initDate.getDate() - 30);

    this.currentDate = currentDate.toISOString().slice(0, 10);
    this.initDate = initDate.toISOString().slice(0, 10);
  }
}
