<div [@routerTransition]>

    <div class="kt-subheader kt-grid__item">

        <div class="kt-subheader__main w-100 d-flex justify-content-between">
            <div class="d-flex">
                <h3 class="kt-subheader__title">
                    <span>{{l("LiberacoesUsuarios")}}</span>
                </h3>
                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                <span class="kt-subheader__desc">
                    {{l("LiberacoesUsuariosHeaderInfo")}}
                </span>
            </div>
            <div class="flex">
                <button *ngIf="microsoftProvider" class="btn btn-primary kt-btn btn-sm botao-redes text-white" (click)="externalLogin(microsoftProvider)" title="{{microsoftProvider.name}}" [disabled]="loginMicrosoft">
                    <i class="fab fa-{{microsoftProvider.icon}}"></i>
                    {{microsoftProvider.name}}
                </button>
                <button *ngIf="false" type="button" class="btn btn-primary blue"
                    (click)="tenantBindingModal.show(true)">
                    {{l("AddTenantBinding")}}
                </button>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <div class="row align-items-center">
                    <div class="col-md-6 col-sm-12 col-lg-4 col-xl-4" *ngFor="let item of liberacoes">
                        <app-card-liberacao-usuario [card]="item" (binding)="tenantBindingModal.show(false)" (useAd)="externalLoginByCard(microsoftProvider, item.userId)">
                        </app-card-liberacao-usuario>
                    </div>
                </div>
                <h3 class="kt-subheader__title text-center" *ngIf="liberacoes.length == 0">
                    <span>{{l("NenhumaLiberacaoDeAcesso")}}</span>
                </h3>
            </div>
        </div>
    </div>
</div>

<app-add-tenant-biding-modal #tenantBindingModal></app-add-tenant-biding-modal>