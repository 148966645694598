<div [@routerTransition] (window:resize)="onResize($event)">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{"Maintenance" | localize}}</span>
            </h3>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <tabset>
                    <tab heading=" {{'Caches' | localize}}">
                        <div class="row mb-2">
                            <div class="col-xl-6">
                                <p>{{"CachesHeaderInfo" | localize}}</p>
                            </div>
                            <div class="col-xl-6 text-right">
                                <button class="btn btn-primary" (click)="clearAllCaches()"><i class="fa fa-recycle"></i>
                                    {{"ClearAll" | localize}}</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <table class="table table-striped table-hover table-bordered">
                                    <tbody>
                                        <tr *ngFor="let cache of caches">
                                            <td>
                                                <span>{{cache.name}}</span>
                                                <button class="btn btn-primary btn-sm pull-right"
                                                    (click)="clearCache(cache.name);">
                                                    {{"Clear" | localize}}
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </tab>
                    <tab heading="{{'WebSiteLogs' | localize}}">
                        <div *ngIf="false" class="row align-items-center">
                            <div class="col-xl-12">
                                <div class="form-group m-form__group align-items-center">
                                    <div class="input-group">
                                        <input [(ngModel)]="filters.filterText" name="filterText" autoFocus
                                            class="form-control m-input" [placeholder]="l('TitleDescriptionSearch')"
                                            type="text">
                                        <span class="input-group-btn">
                                            <button (click)="getWebLogs()" class="btn btn-primary" type="submit">
                                                <i class="flaticon-search-1"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="false" class="row align-items-center" [hidden]="!advancedFiltersAreShown">
                            <div class="col-md-12">
                                <div class="kt-separator kt-separator--border-dashed"></div>
                            </div>
                            <div class="col-4">
                                <label for="tenant-filter">{{"Tenant" | localize}}</label>
                                <input type="text" class="form-control" id="tenant-filter" name="tenant-filter"
                                    [(ngModel)]="filters.tenant">
                            </div>
                            <div class="col-4">
                                <label for="class-filter">{{"Class" | localize}}</label>
                                <input type="text" class="form-control" id="class-filter" name="class-filter"
                                    [(ngModel)]="filters.class">
                            </div>
                            <div class="col-4">
                                <label for="endpoint-filter">{{"Endpoint" | localize}}</label>
                                <input type="text" class="form-control" id="endpoint-filter" name="endpoint-filter"
                                    [(ngModel)]="filters.endpoint">
                            </div>
                            <div class="col-6 d-flex align-items-center">
                                <div class="form-group col-10">
                                    <label for="initial-date-filter">{{"InitialDate" | localize}}</label>
                                    <input type="text" id="initial-date-filter" name="initial-date-filter"
                                        class="form-control" bsDatepicker [(ngModel)]="filters.initialDate">
                                </div>
                                <timepicker class="ml-1 col-2" [(ngModel)]="filters.initialDate" [showMeridian]="false">
                                </timepicker>
                            </div>
                            <div class="col-6 d-flex align-items-center">
                                <div class="form-group col-10">
                                    <label for="final-date-filter">{{"FinalDate" | localize}}</label>
                                    <input type="text" id="final-date-filter" name="final-date-filter" class="form-control"
                                        bsDatepicker [(ngModel)]="filters.finalDate">
                                </div>
                                <timepicker class="ml-1 col-2" [(ngModel)]="filters.finalDate" [showMeridian]="false"></timepicker>
                            </div>
                        </div>
                        <div class="row mb-2" *ngIf="false">
                            <div class="col-sm-12">
                                <span class="clickable-item text-muted" *ngIf="!advancedFiltersAreShown"
                                    (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i
                                        class="fa fa-angle-down"></i> {{l("ShowAdvancedFilters")}}</span>
                                <span class="clickable-item text-muted" *ngIf="advancedFiltersAreShown"
                                    (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i
                                        class="fa fa-angle-up"></i> {{l("HideAdvancedFilters")}}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-xl-6">
                                <p>{{"WebSiteLogsHeaderInfo" | localize}}</p>
                            </div>
                            <div class="col-xl-6 text-right">
                                <button class="btn btn-secondary" (click)="downloadWebLogs()"><i
                                        class="fa fa-download"></i> {{"DownloadAll" | localize}}</button>
                                <button class="btn btn-primary" (click)="getWebLogs()"><i class="fa fa-sync"></i>
                                    {{"Atualizar" | localize}}</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="web-log-view full-height">
                                    <span class="log-line" *ngFor="let log of logs">
                                        {{log}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>