import { Component, Injector, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UserServiceProxy, MaquinaDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'maquinasUserModalComponent',
    templateUrl: './maquinas-user-modal.component.html'
})
export class MaquinasUserModalComponent extends AppComponentBase {

    @ViewChild('modal', {static: true}) modal: ModalDirective;

    saving = false;

    userId: number;
    userName: string;
    maquinas: MaquinaDto[];

    constructor(
        injector: Injector,
        private _userService: UserServiceProxy
    ) {
        super(injector);
    }

    show(userId: number, userName?: string): void {
        this.userId = userId;
        this.userName = userName;

        this._userService.getMaquinasDoUsuario(userId).subscribe(result => {
            this.maquinas = result;
            this.modal.show();
        });
    }

    close(): void {
        this.modal.hide();
    }
}
