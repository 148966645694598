<div [@routerTransition] id="code-review-config" class="overflow-auto">
    <div id="subheader" class="kt-subheader kt-grid__item">
        <div id="subheader-main" class="kt-subheader__main">
            <h3 id="subheader-title" class="kt-subheader__title">
                <span id="subheader-title-text">{{l("Code Review")}}</span>
            </h3>
            <span id="subheader-separator" class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span id="subheader-desc" class="kt-subheader__desc">
                {{l("Configuração de avaliadores e critérios de avaliação")}}
            </span>
        </div>        
    </div>
    <div id="code-review-content" class="kt-content code-review-content">
        <div id="portlet-mobile" class="kt-portlet kt-portlet--mobile">
            <div id="portlet-body" class="kt-portlet__body">
                <div id="main-config">
                    <div id="enable-config">
                        <div id="checkbox-content" class="checkbox-content">
                            <input id="code-review-enable" type="checkbox" name="code-review-config" [(ngModel)]="checked"/><span class="checkbox-description">Habilitar configuração de <b>Code Review</b></span>
                        </div>
                        <div id="save-config" class="save-config">
                            <button id="save-button" class="btn btn-primary" (click)="saveConfig()">
                                <i id="save-icon" class="fa fa-save"></i>{{l("Salvar Configuração")}}
                            </button>
                        </div>  
                    </div>
                </div>
                <div id="spinner-content" class="spinner-row">
                    <div id="spinner-loading" class="spinner-loading">
                        <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                    </div>
                </div>
                <div id="config-content" class="config-content" *ngIf="checked">
                    <div id="loading-content" class="loading-content" *ngIf="!loading">
                        <div id="code-review-approvers" class="code-review-approvers">
                            <label for="approvers" id="approvers-label" class="approvers-label">{{l("Avaliador(es)")}}</label>
                            <ng-select [items]="users" [multiple]="true" [(ngModel)]="usersSelected" name="usersSelected">
                                <ng-template ng-label-tmp let-item="item">
                                    <div id="box-selected" class="box-selected">
                                        <span id="value-label" class="ng-value-label ng-star-inserted">
                                            <b id="item-label" class="item-label">{{item.label}}</b>
                                            <div id="box-content" class="box-content">
                                                <div id="item-cargo" class="content-item item-cargo">{{item.cargo}}</div>
                                                <div id="item-fornecedor" class="content-item item-fornecedor">{{item.fornecedor}}</div>
                                            </div>
                                        </span>
                                        <span id="delete-box" class="delete-box" (click)="clear(item)" aria-hidden="true"><i id="time-icon" class="fa fa-times"></i></span>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                    <div id="searchTerm-content" class="searchTerm-content p-2">
                                        <b id="searchTerm-label" class="searchTerm-label">{{item.label}}</b>
                                        <div id="searchTerm-cargo" class="searchTerm-cargo" style="font-size: 12px;">{{item.cargo}}</div>
                                        <div id="searchTerm-fornecedor" id="searchTerm-fornecedor" style="font-size: 10px;">{{item.fornecedor}}</div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div id="code-review-criteria" class="code-review-criteria">
                            <form id="criteria-form" class="criteria-form" #codeReviewCriteriaForm="ngForm" autocomplete="off">
                                <div id="form-group" class="form-group">
                                    <div id="criteria-title-block" class="criteria-title-block">
                                        <label for="criteria-title" id="criteria-label" class="criteria-label">{{l("Título do critério de avaliação")}}</label>
                                        <input id="criteria-title" type="text" name="criteria" class="form-control" minlength="10" placeholder="Ex: Clean Code" [(ngModel)]="criteriaTitle" required>
                                    </div>
                                    <div id="criteria-button" class="criteria-button">
                                        <button *ngIf="!isEditEnabled" id="add-button" class="btn btn-primary" type="submit" [disabled]="codeReviewCriteriaForm.invalid" (click)="addCriteria()">
                                            <i id="plus-icon" class="fa fa-plus"></i>{{l("Adicionar critério de avaliação")}}
                                        </button>
                                        <button *ngIf="isEditEnabled" id="update-button" class="btn btn-primary" type="submit" [disabled]="codeReviewCriteriaForm.invalid" (click)="updateCriterio()">
                                            <i id="sync-icon" class="fa fa-sync"></i>{{l("Atualizar critério")}}
                                        </button>
                                        <button *ngIf="isEditEnabled" id="cancel-button" class="btn btn-outline-danger" type="submit" (click)="cancelUpdateCriterio()">
                                            <i class="fa fa-times"></i>{{l("Cancel")}}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="criteria-table" class="criteria-table">
                            <p-table #dt [value]="listaFiltrada" [rows]="5" [paginator]="true" dataKey="id" [rowsPerPageOptions]="[5,10,25,50,100,500]">
                                <ng-template pTemplate="header">
                                    <tr id="header-row" class="header-row">
                                        <th scope="col">{{l("Título")}}</th>
                                        <th scope="col" style="text-align: right;">{{l("Ações")}}</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-criterio let-i>
                                    <tr id="body-row" class="body-row" *ngIf="!criterio.removido">
                                        <td id="criterio-titulo" class="criterio-titulo">{{criterio.titulo}}</td>
                                        <td id="list-item-action" class="list-item-action" style="text-align: right;">
                                            <div class="list-item item-action">
                                                <span id="edit-item" class="edit-item" tooltip="{{l('Edit')}}" (click)="onEditCriterio(criterio.id, i)"><i id="pen-icon" class="fas fa-pen"></i></span>
                                                <span id="delete-item" class="delete-item"  tooltip="{{l('Delete')}}" (click)="deleteCriterio(criterio);"><i id="trash-icon" class="fas fa-trash"></i></span>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="summary">
                                    <div id="table-sumary" class="flex align-items-center justify-content-between">
                                        Total: {{listaFiltrada ? listaFiltrada.length : 0}}
                                    </div>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>      
            </div>
        </div>
    </div>
</div>