import { Component, EventEmitter, Injector, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { LinkedAccountService } from "@app/shared/layout/linked-account.service";
import { AppConsts } from "@shared/AppConsts";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ConfiguracaoAreaUsuarioServiceProxy, UltimosAcessosUsuarioDto } from "@shared/service-proxies/service-proxies";
@Component({
    selector: 'last-accessed-clients',
    templateUrl: './lastAccessedClients.component.html',
    styleUrls: ['./lastAccessedClients.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class LastAccessedClientsComponent extends AppComponentBase implements OnInit {

    cardList: UltimosAcessosUsuarioDto[] = [];
    defaultLogo = this.appRootUrl() + 'assets/common/images/qa-devops.png';
    remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
    @Output()  useAd: EventEmitter<void> = new EventEmitter<void>();


    constructor(
        injector: Injector,
        private _ConfiguracaoAreaUsuarioServiceProxy: ConfiguracaoAreaUsuarioServiceProxy,
        private _linkedAccountService: LinkedAccountService

    ) {
        super(injector);
    }

    ngOnInit() {
        this.getAcessosRecentes();
    }

    getAcessosRecentes(): void {
        this._ConfiguracaoAreaUsuarioServiceProxy.getAcessosRecentes(this.appSession.userId).subscribe(result => {
           this.cardList = result;
        });
    }

    switchToLinkedUser(acessoUsuarioTentant: UltimosAcessosUsuarioDto) {
        if (acessoUsuarioTentant.validarAd) {
          this.useAd.emit();
          return;
        }
        this._linkedAccountService.switchToAccount(acessoUsuarioTentant.userId, acessoUsuarioTentant.tenantId);
      }
}