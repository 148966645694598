<p-dialog #dialog header="Header" [modal]="true" [(visible)]="visibleModal" [style]="{width: '50vw'}"
    (onShow)="maximizeDialog()" (onHide)="onClose()">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">
            <i class="fa fa-laptop-code mr-2"></i> QIAMetrik | {{ setPromptAISettings(promptNumber) }}
        </span>
    </ng-template>
    <div class="kt-login__form">
        <div class="d-flex flex-row" style="height: 90dvh;"></div>
    </div>
</p-dialog>