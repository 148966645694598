<div>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{"TechnicalDocumentation" | localize}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{"TechnicalDocumentationInfo" | localize}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button (click)="openCreateOrEditModal()" *ngIf="'Pages.Administration.Host.Settings' | permission" class="btn btn-primary"><i class="fa fa-plus"></i> {{"CreateDocumentation" | localize}}</button>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet">

            <div class="kt-portlet__body">
                <div class="col-xl-12">
                    <div class="form-group m-form__group align-items-center">
                        <div class="input-group">
                            <input [(ngModel)]="filter" name="filterText" autoFocus
                                class="form-control m-input" [placeholder]="l('TitleDescriptionSearch')"
                                type="text">
                            <span class="input-group-btn">
                                <button (click)="getDocumentation()" class="btn btn-primary" type="submit">
                                    <i class="flaticon-search-1"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="primeng-datatable-container col-12">
                    <p-table [value]="documentedItems" [paginator]="true" [rows]="maxResultCount"
                        [totalRecords]="totalCount" [loading]="loading" [lazy]="true"
                        (onLazyLoad)="getDocumentation($event)" [paginator]="true" [scrollable]="true"
                        [responsive]="true" [rowsPerPageOptions]="[10,25,50]"
                        [globalFilterFields]="['id','employeedId', 'name', 'login', 'domain']">

                        <ng-template pTemplate="header">
                            <tr class="d-flex">
                                <th pResizableColumn class="col-2">
                                    {{'Actions' | localize}}</th>
                                <th pResizableColumn class="col-6">
                                    {{'DocumentacaoItemQualidadeDebitoTecnico' | localize}}</th>
                                <th pResizableColumn class="col-2">
                                    {{'DocumentacaoItemQualidadeClass' | localize}}</th>
                                <th pResizableColumn class="col-2">
                                    {{'DocumentacaoItemQualidadeCode' | localize}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-record="$implicit">
                            <tr class="d-flex">
                                <td class="col-2">
                                    <div class="btn-group dropdown" dropdown container="body" placement="top left">
                                        <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle>
                                            <i class="fa fa-cog"></i><span class="caret"></span> {{l("Actions")}}
                                        </button>
                                        <ul class="dropdown-menu" *dropdownMenu>
                                            <li>
                                                <a href="javascript:;" (click)="openCreateOrEditModal(record.value, $event)">{{'Edit' | localize}} </a>
                                            </li>
                                            <li>
                                                <a href="javascript:;" (click)="deleteDocumentation(record.value, $event)">{{'Delete' | localize}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>

                                <td class="col-6">
                                    {{record.label}}
                                </td>
                                <td class="col-2">
                                    {{record.classe}}
                                </td>
                                <td class="col-2">
                                    {{record.codigo}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<app-create-or-edit-documentation #createOrEditDocumentationModal (modalSave)="reload()"></app-create-or-edit-documentation>
