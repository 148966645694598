<div [@routerTransition]>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{ l("Fornecedores") }}</span>
            </h3>
            <span
                class="kt-subheader__separator kt-subheader__separator--v"
            ></span>
            <span class="kt-subheader__desc">
                {{ l("FornecedoresHeaderInfo") }}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button
                    *ngIf="isGranted('Pages.Administration.Fornecedores.Tenant.Create')"
                    (click)="linkNewProvider()"
                    class="btn btn-primary blue"
                >
                    <i class="fa fa-plus"></i> {{ l("AdicionarFornecedor") }}
                </button>
            </div>
        </div>
    </div>
    <!-- Table -->
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <form class="kt-form" autocomplete="off">
                    <div>
                        <div class="row align-items-center">
                            <div class="col-xl-12">
                                <div
                                    class="form-group m-form__group align-items-center"
                                >
                                    <div class="input-group">
                                        <input
                                            [(ngModel)]="
                                                filtroFornecedoresCnpjNome
                                            "
                                            name="filterText"
                                            autoFocus
                                            class="form-control m-input"
                                            [placeholder]="
                                                l('PesquisarFornecedorFiltro')
                                            "
                                            type="text"
                                        />
                                        <span class="input-group-btn">
                                            <button
                                                (click)="getFornecedores()"
                                                class="btn btn-primary"
                                                type="submit"
                                            >
                                                <i
                                                    class="flaticon-search-1"
                                                ></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row align-items-center">
                    <!--<Primeng-Datatable-Start>-->
                    <div
                        class="primeng-datatable-container col-12"
                        [busyIf]="primengTableHelper.isLoading"
                    >
                        <p-table
                            #dataTable
                            (onLazyLoad)="getFornecedores($event)"
                            [value]="primengTableHelper.records"
                            rows="{{
                                primengTableHelper.defaultRecordsCountPerPage
                            }}"
                            [paginator]="false"
                            [lazy]="true"
                            [scrollable]="true"
                            ScrollWidth="100%"
                            [responsive]="primengTableHelper.isResponsive"
                            [resizableColumns]="
                                primengTableHelper.resizableColumns
                            "
                        >
                            <ng-template pTemplate="header">
                                <tr>
                                    <th
                                        style="width: 130px"
                                        [hidden]="
                                            !isGrantedAny(
                                                'Pages.Administration.Fornecedores.Tenant.Delete'
                                            )
                                        "
                                    >
                                        {{ l("Actions") }}
                                    </th>
                                    <th
                                        style="width: 150px"
                                        pSortableColumn="nome"
                                    >
                                        {{ l("Name") }}
                                        <p-sortIcon
                                            field="fornecedor.nome"
                                        ></p-sortIcon>
                                    </th>
                                    <th
                                        style="width: 150px"
                                        pSortableColumn="cnpj"
                                    >
                                        {{ l("Cnpj") }}
                                        <p-sortIcon
                                            field="fornecedor.cnpj"
                                        ></p-sortIcon>
                                    </th>
                                    <th
                                        style="width: 150px"
                                        pSortableColumn="cep"
                                    >
                                        {{ l("Cep") }}
                                        <p-sortIcon
                                            field="fornecedor.cep"
                                        ></p-sortIcon>
                                    </th>
                                    <th
                                        style="width: 150px"
                                        pSortableColumn="estado"
                                    >
                                        {{ l("Estado") }}
                                        <p-sortIcon
                                            field="fornecedor.estado"
                                        ></p-sortIcon>
                                    </th>
                                    <th
                                        style="width: 150px"
                                        pSortableColumn="cidade"
                                    >
                                        {{ l("Cidade") }}
                                        <p-sortIcon
                                            field="fornecedor.cidade"
                                        ></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template
                                pTemplate="body"
                                let-record="$implicit"
                            >
                                <tr>
                                    <td
                                        style="width: 130px"
                                        [hidden]="
                                            !isGrantedAny(
                                                'Pages.Administration.Fornecedores.Tenant.Delete'
                                            )
                                        "
                                    >
                                        <div
                                            class="btn-group dropdown"
                                            dropdown
                                            container="body"
                                        >
                                            <button
                                                class="dropdown-toggle btn btn-sm btn-primary"
                                                dropdownToggle
                                            >
                                                <i class="fa fa-cog"></i
                                                ><span class="caret"></span>
                                                {{ l("Actions") }}
                                            </button>
                                            <ul
                                                class="dropdown-menu"
                                                *dropdownMenu
                                            >
                                                <li>
                                                    <a
                                                        href="javascript:;"
                                                        (click)="
                                                            viewResponsaveisComponent.show(
                                                                record.clienteFornecedorId
                                                            )
                                                        "
                                                        >{{
                                                            l(
                                                                "VisualizarResponsaveis"
                                                            )
                                                        }}</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        href="javascript:;"
                                                        (click)="
                                                            removeProvider(
                                                                record.fornecedor
                                                            )
                                                        "
                                                        >{{
                                                            l(
                                                                "RemoverFornecedor"
                                                            )
                                                        }}</a
                                                    >
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td style="width:150px">
                                        {{ record.fornecedor.nome }}
                                    </td>
                                    <td style="width:150px">
                                        {{ record.fornecedor.cnpj }}
                                    </td>
                                    <td style="width:150px">
                                        {{ record.fornecedor.cep }}
                                    </td>
                                    <td style="width:150px">
                                        {{ record.fornecedor.estado }}
                                    </td>
                                    <td style="width:150px">
                                        {{ record.fornecedor.cidade }}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div
                            class="primeng-no-data"
                            *ngIf="primengTableHelper.totalRecordsCount == 0"
                        >
                            {{ l("NoData") }}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator
                                rows="{{
                                    primengTableHelper.defaultRecordsCountPerPage
                                }}"
                                #paginator
                                (onPageChange)="getFornecedores($event)"
                                [totalRecords]="
                                    primengTableHelper.totalRecordsCount
                                "
                                [rowsPerPageOptions]="
                                    primengTableHelper.predefinedRecordsCountPerPage
                                "
                            >
                            </p-paginator>
                            <span class="total-records-count">
                                {{
                                    l(
                                        "TotalRecordsCount",
                                        primengTableHelper.totalRecordsCount
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-Datatable-End>-->
                </div>
            </div>
        </div>
        <app-view-responsaveis #viewResponsaveis (modalSave)="getFornecedores()"></app-view-responsaveis>
        <app-adicionar-fornecedores #adicionarFornecedor (modalSave)="getFornecedores()"></app-adicionar-fornecedores>
    </div>
</div>
