import { Component, Injector, ViewEncapsulation, ViewChild, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';
import { LazyLoadEvent } from 'primeng/api';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AllChangeRequestsForApproval, ChangeRequestForApprovalDto, ChangeRequestServiceProxy } from "@shared/service-proxies/service-proxies";
import { ApprovalOrDisapproveChangeRequestModalComponent } from "./approval-change-request-modal/approval-change-request-modal.component";

@Component({
    templateUrl: './list-approval-change.component.html',
    styleUrls: ['./list-approval-change.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})

export class ApprovalChangeRequestComponent extends AppComponentBase implements OnInit {
    @ViewChild('approvalOrDisapproveChangeRequestModal', { static: true }) approvalOrDisapproveChangeRequestModal: ApprovalOrDisapproveChangeRequestModalComponent;
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    
    titleFilter: string = '';
    statusFilter: number = -1;
    maxDatetimeFilter: moment.Moment;
    minDatetimeFilter:moment.Moment;
    allChangeRequestsForApproval: AllChangeRequestsForApproval = new AllChangeRequestsForApproval;
    changeRequestForApprovalDto: ChangeRequestForApprovalDto = new ChangeRequestForApprovalDto;

    constructor(
        injector: Injector,
        private _changeRequestAppService: ChangeRequestServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void { }
    
    resetarFiltros() {
    /*
        let momentDate: moment.Moment;
        this.filterText = '';
        this.maxDataDaAvaliacaoFilter = momentDate;
        this.minDataDaAvaliacaoFilter = momentDate;
        this.statusGmudFilter = -1;
        this.statusAprovadorFilter = -1;
        this.userNameFilter = '';
        this.demandaTituloFilter  = '';
        this.selectedGmud = true;
        this.getAvaliacoesGestaoMudancaNativa();
    */
    }

    getLocaleDateHour(date: moment.Moment) {
        if (date === undefined) return "";

        const dateString = date ? date.toString() : "";
        return new Date(dateString).toLocaleString();
    }

    reloadGrid(event?: LazyLoadEvent) {
        this.getAllApprovalChangeRequests(event);
    }

    getAllApprovalChangeRequests(event?: LazyLoadEvent) {    
        if (event && this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this._changeRequestAppService.getAllChangeRequestForApproval(
            this.titleFilter,
            this.statusFilter,
            this.maxDatetimeFilter,
            this.minDatetimeFilter,
            this.primengTableHelper.getSorting(this.dataTable),
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event)
        ).subscribe(result => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            this.primengTableHelper.records = result.items;
            this.primengTableHelper.hideLoadingIndicator();
        });
    }

    getStatusColor(status: string) {
        switch (status) {
            case 'Aprovado':
                return 'success';
            case 'Em análise':
                return 'warning';
            case 'Reagendado':
                return 'warning';
            case 'Reprovado':
                return 'danger';
            case 'Cancelado':
                return 'danger';
            default:  return 'info'; 
        }
    }

    reloadPage(ev: any) {
        this.reloadGrid(ev);
    }

    showApprovalChangeRequest(changeRequestApprovalId: string): void {
        this._changeRequestAppService.getChangeRequestForApproval(changeRequestApprovalId)
            .subscribe(res => { 
                this.changeRequestForApprovalDto = res;
                this.approvalOrDisapproveChangeRequestModal.show(this.changeRequestForApprovalDto);
            }); 
    }
}