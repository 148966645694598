<iframe class="col-12 col-md-4" frameborder="0" height="500" allowtransparency
    src="https://app1.qametrik.com/public/question/69c73dbf-a35b-42f2-9971-05b3d83488e7#theme=transparent">
</iframe>

<iframe class="col-12 col-md-8" frameborder="0" height="500" allowtransparency
    src="https://app1.qametrik.com/public/question/893c1f80-d1df-4a8d-a17f-46eb7881f324#theme=transparent">
</iframe>

<iframe class="col-12 col-md-4 mt-4" frameborder="0" height="500" allowtransparency
    src="https://app1.qametrik.com/public/question/c7e3f0cb-2684-42af-a768-919ae3f26c31#theme=transparent">
</iframe>

<iframe class="col-12 col-md-8 mt-4" frameborder="0" height="500" allowtransparency
    src="https://app1.qametrik.com/public/question/5b567648-46b5-47b4-916f-bb82908e65d2#theme=transparent">
</iframe>

<iframe class="col-12 col-md-6 mt-4" frameborder="0" height="500" allowtransparency
    src="https://app1.qametrik.com/public/question/5d6c46dc-08b5-4f36-98dc-df74ee68ef36#theme=transparent">
</iframe>

<iframe class="col-12 col-md-6 mt-4" frameborder="0" height="500" allowtransparency
    src="https://app1.qametrik.com/public/question/2cded2b4-222c-4144-87be-bcfa8922222f#theme=transparent">
</iframe>

<iframe class="col-12 mt-4" frameborder="0" height="600" allowtransparency
    [src]="urlReport1">
</iframe>

<iframe class="col-12 mt-4" frameborder="0" height="600" allowtransparency
    [src]="urlReport2">
</iframe>

<iframe class="col-6 mt-4" frameborder="0" height="600" allowtransparency
    [src]="urlReport3">
</iframe>

<iframe class="col-6 mt-4" frameborder="0" height="600" allowtransparency
    [src]="urlReport4">
</iframe>