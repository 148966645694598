<div>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{"DashboardFileSettings" | localize}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{"DashboardSettingsHeaderInfo" | localize}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button class="btn btn-primary" (click)="save()" [disabled]="!file"><i class="la la-floppy-o"></i>
                    {{"Save" | localize}}</button>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <p-progressSpinner *ngIf="uploading"></p-progressSpinner>
        <div class="kt-portlet" *ngIf="!uploading">
            <form class="kt-portlet__body">
                <p-fileUpload [customUpload]="true" (onSelect)="upload($event)" id="dashboard-uploader"
                    (onClear)="clear()" name="DashboardUploader" maxFileSize="1000000000" accept="text/plain"
                    [showUploadButton]="false" chooseLabel="{{'DashboardFile' | localize}}" mode="advanced"
                    (onRemove)="clear()" [cancelLabel]="'Clean' | localize">
                </p-fileUpload>
            </form>
        </div>
    </div>
</div>