import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ConfigurationStmsImportDto, ImportStmsServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'stms-import',
  templateUrl: './stms-import.component.html',
  styleUrls: ['./stms-import.component.css']
})
export class ConfigurationStmsImportComponent extends AppComponentBase implements OnInit {

  activeStmsImport: ConfigurationStmsImportDto = new ConfigurationStmsImportDto();
  loading = false;

  constructor(
    injector: Injector,
    private _configurationStmsImportService: ImportStmsServiceProxy
  ) {
    super(injector);
  }
  ngOnInit() {
    this.getSettings();
  }

  getSettings() {
    this.loading = true;
    this._configurationStmsImportService.getConfiguration()
      .pipe(finalize(() => { this.loading = false; }))
      .subscribe((result: ConfigurationStmsImportDto) => this.activeStmsImport = result);
  }

  saveAll() { 
    this.loading = true;
    this._configurationStmsImportService.insertOrUpdate(this.activeStmsImport)
      .pipe(finalize(() => this.loading = false))
      .subscribe();
  }
}
