<div bsModal #createEditChangeRequestModal="bs-modal" id="createEditChangeRequestModal" class="modal fade change-modal overflow-auto" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content change-modal">
            <form #createEditChangeRequestModalForm="ngForm" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{l("RDM - Requisição de mudança")}}
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="spinner-content" class="spinner-row">
                        <div id="spinner-loading" class="spinner-loading">
                          <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                        </div>
                    </div>
                    <div class="gmud-native-content" *ngIf="!loading">
                        <tabset>
                            <tab heading="{{l('GeneralInfo')}}">
                                <div class="form-group d-flex content-numberTitle">
                                    <div class="content-number" *ngIf="isEdit">
                                        <label for="change-number">Número</label>
                                        <input id="change-number" #changeNumber="ngModel" type="number" name="changeNumber"
                                            class="form-control" [(ngModel)]="createChangeRequestDto.changeRequestNumber" disabled/>
                                    </div>
                                    <div class="content-title">
                                        <label for="change-title">Título</label>
                                        <input id="change-title" #changeTitle="ngModel" type="text" name="ChangeTitle"
                                            class="form-control" [(ngModel)]="createChangeRequestDto.title"/>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="change-description">Descrição</label>
                                    <textarea id="change-description" #changeDescription="ngModel" type="text"
                                        name="ChangeDescription" class="form-control textarea"
                                        [(ngModel)]="createChangeRequestDto.description"></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="change-reasonForChange">Motivo</label>
                                    <textarea id="change-reasonForChange" #ReasonForChange="ngModel" type="text"
                                        name="ReasonForChange" class="form-control textarea"
                                        [(ngModel)]="createChangeRequestDto.reasonForChange"></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="change-expectedBenefits">Benefício esperado</label>
                                    <textarea id="change-expectedBenefits" #ExpectedBenefits="ngModel" type="text"
                                        name="ExpectedBenefits" class="form-control textarea"
                                        [(ngModel)]="createChangeRequestDto.expectedBenefits"></textarea>
                                </div>
                                <div class="form-group checkboxGroup-change">
                                    <label for="change-affectProd" class="kt-checkbox checkbox-affectProd">
                                        <input id="change-affectProd" type="checkbox" name="changeAffectProd" 
                                        [(ngModel)]="createChangeRequestDto.changeAffectProd"> Afeta produção?
                                        <span></span>
                                    </label>
                                    <label for="change-duringBusinessHours" class="kt-checkbox checkbox-duringBusinessHours">
                                        <input id="change-duringBusinessHours" type="checkbox" name="duringBusinessHours" 
                                        [(ngModel)]="createChangeRequestDto.duringBusinessHours"> Será realizada em horário comercial?
                                        <span></span>
                                    </label>
                                </div>
                                <div class="form-group optional-approver approver-list">
                                    <label for="approver">Classificação</label>
                                    <ng-select [items]="rdmClassificationType" [multiple]="true" 
                                               [(ngModel)]="rdmClassificationTypeSelected" 
                                               name="rdmClassificationTypeSelected" 
                                               #optionalApprover="ngModel"
                                               [required]="rdmClassificationTypeSelected.length == 0">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div class="box-selected">
                                                <span class="ng-value-label ng-star-inserted">
                                                    <b>{{item.classificationType}}</b>
                                                </span>
                                                <span class="delete-box" (click)="clearRdmClassificationType(item)" aria-hidden="true">×</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                            <div class="p-2">
                                                <b>{{item.classificationType}}</b>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                                <div class="form-group dropdownGroup">
                                    <div class="column change-type">
                                        <label for="change-type">Tipo</label>
                                        <p-dropdown id="change-type" 
                                                    class="form-group changeType-content"
                                                    [options]="typesChange"
                                                    (onChange)="onValueChangeType($event)"
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    [formControl]="formGroup.get('typeOfChange')">
                                         </p-dropdown>

                                    </div>                        
                                    <div class="column change-riskLevel">
                                        <label for="change-riskLevel">Categoria do risco para o negócio</label>
                                        <p-dropdown id="change-riskLevel"
                                                    class="form-group changeType-content"
                                                    [options]="riskLevel"
                                                    (onChange)="onValueRiskLevel($event)"
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    [formControl]="formGroup.get('riskLevel')">
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="isEmergency">
                                    <label for="change-risk">Risco caso não seja realizado</label>
                                    <input id="change-risk" #ChangeRisk="ngModel" type="text" name="ChangeRisk"
                                        class="form-control" [(ngModel)]="createChangeRequestDto.risk"/>
                                </div>
                                <div class="form-group">
                                    <label for="change-mainImpacts">Principais impactos operacionais</label>
                                    <textarea id="change-mainImpacts" #MainImpacts="ngModel" type="text"
                                        name="MainImpacts" class="form-control textarea"
                                        [(ngModel)]="createChangeRequestDto.mainImpacts"></textarea>
                                </div>
                                <div class="form-group optional-approver approver-list">
                                    <label for="approver">Indisponibilidade</label>
                                    <ng-select [items]="rdmUnavailabilityType" [multiple]="true" 
                                               [(ngModel)]="rdmUnavailabilityTypeSelected" 
                                               name="rdmUnavailabilityTypeSelected" 
                                               #optionalApprover="ngModel"
                                               [required]="rdmUnavailabilityTypeSelected.length == 0">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div class="box-selected">
                                                <span class="ng-value-label ng-star-inserted">
                                                    <b>{{item.unavailabilityType}}</b>
                                                </span>
                                                <span class="delete-box" (click)="clearUnavailabilityType(item)" aria-hidden="true">×</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                            <div class="p-2">
                                                <b>{{item.unavailabilityType}}</b>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                                <div class="form-group">
                                    <label for="change-otherUnavailability">Outras indisponibilidades</label>
                                    <textarea id="change-otherUnavailability" #OtherUnavailability="ngModel" type="text"
                                        name="OtherUnavailability" class="form-control textarea"
                                        [(ngModel)]="createChangeRequestDto.otherUnavailability"></textarea>
                                </div>
                                <div class="form-group unavailability-content">
                                    <div class="column pcalendar-unavailabilityInitialDate">
                                        <label for="change-unavailabilityInitialDate">Data e hora inicial</label>
                                        <p-calendar id="change-unavailabilityInitialDate" name="unavailabilityInitialDate" 
                                                    [(ngModel)]="unavailabilityInitialDate" 
                                                    (onSelect)="calculateHours()" [showTime]="true" dateFormat="dd/mm/yy" [showIcon]="true">
                                        </p-calendar>
                                    </div>                            
                                    <div class="column pcalendar-unavailabilityEndDate">
                                        <label for="change-unavailabilityEndDate">Data e hora final</label>
                                        <p-calendar id="change-unavailabilityEndDate" name="unavailabilityEndDate" 
                                                    [(ngModel)]="unavailabilityEndDate" 
                                                    (onSelect)="calculateHours()" [showTime]="true" dateFormat="dd/mm/yy" [showIcon]="true">
                                        </p-calendar>
                                    </div>
                                    <div class="column hours-difference">
                                        <label for="hoursDifference">Tempo total de indisponibilidade</label>
                                        <input type="text" class="form-control" id="hoursDifference" [value]="hoursDifference" disabled>
                                    </div>
                                </div>
                                <div class="form-group optional-approver approver-list">
                                    <label for="approver">Documentos anexos</label>
                                    <ng-select [items]="rdmAttachedDocsType" [multiple]="true" 
                                               [(ngModel)]="rdmAttachedDocsTypeSelected" 
                                               name="rdmAttachedDocsTypeSelected" 
                                               #optionalApprover="ngModel"
                                               [required]="rdmAttachedDocsTypeSelected.length == 0">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div class="box-selected">
                                                <span class="ng-value-label ng-star-inserted">
                                                    <b>{{item.attachedDocsType}}</b>
                                                </span>
                                                <span class="delete-box" (click)="clearRdmAttachedDocsType(item)" aria-hidden="true">×</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                            <div class="p-2">
                                                <b>{{item.attachedDocsType}}</b>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                                <div class="form-group">
                                    <label for="change-othersAttachedDocs">Outras documentos</label>
                                    <textarea id="change-othersAttachedDocs" #OthersAttachedDocs="ngModel" type="text"
                                        name="OthersAttachedDocs" class="form-control textarea"
                                        [(ngModel)]="createChangeRequestDto.othersAttachedDocs">
                                    </textarea>
                                </div>
                            </tab>
                            <tab heading="{{l('Approvers')}}">
                                <div class="form-group">
                                    <label for="changeApprovers">{{l('SelectApprovers')}}</label>
                                    <p-tree [value]="usersApproval" selectionMode="checkbox" [(selection)]="usersApprovalSelected" 
                                            (selectionChange)="onSelectionChange($event)">
                                        <ng-template let-node pTemplate="default">
                                          {{ node.label }}
                                        </ng-template>
                                      </p-tree>  
                                </div>
                            </tab>
                            <tab heading="{{l('Anexos')}}">
                                <div class="form-group">
                                    <div class="form-group m-form__group" [busyIf]="primengTableHelper.isLoading">
                                        <div id="anexos-gmud">
                                            <div class="list-head list-row">
                                                <div class="list-item item"><span>Documento</span></div>
                                                <div class="list-item item-action"><span>Ação</span></div>
                                            </div>
                                            <div class="list-body list-row" *ngFor="let attachment of addedAttachments; let i = index">
                                                <div class="list-item item"><span>{{attachment.attachmentName}}</span></div>
                                                <div class="list-item item-action">
                                                    <span class="delete-item" (click)="removerAttachment(attachment)"
                                                    tooltip="{{l('Download do anexo')}}"><i class="fa fa-trash"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dropzone" ng2FileDrop (click)="fileInput.click()"
                                            (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
                                            (drop)="onDrop($event)">
                                             Solte os arquivos(doc, docx, pdf, xml, txt, xls, xlsx, jpg, png) aqui (ou clique) 
                                        </div>
                                        <input #fileInput ng2FileSelect [uploader]="uploader" type="file" [showPreviews]="false"
                                            [multiple]="true" [preserveFiles]="false" ng2FileSelect
                                            [accept]="'application/xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/excel, application/vnd.ms-excel.sheet.macroEnabled.12, text/plain, image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                            style="display: none" (change)="onFilesAdded($event,null)" />
                                    </div>
                                </div>
                            </tab>
                        </tabset>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l('Cancel')}}</button>
                    <button type="submit" class="btn btn-primary blue" [buttonBusy]="saving"
                        [disabled]="createEditChangeRequestModal.invalid"
                        [busyText]="l('SavingWithThreeDot')"
                        (click)="saveChangeRequest()"><i class="fa fa-save"></i>
                        <span>{{l('Save')}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>