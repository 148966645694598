import { Component, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DateTimeService } from "@app/shared/common/timing/date-time.service";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AvaliacaoDeAmbienteServiceProxy, ObjetosViewDto, ThemeSettingsDto } from "@shared/service-proxies/service-proxies";
import moment from "moment/moment";
import { TreeNode } from "primeng/api";

@Component({
    selector: 'avaliacao-ambiente-inventario-objetos',
    templateUrl: './inventarioObjetos.component.html',
    styleUrls: ['./inventarioObjetos.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class AvaliacaoAmbienteInventarioObjetosComponent extends AppComponentBase implements OnInit {
    
    files: TreeNode[];
    filesResult: ObjetosViewDto[];
    filesResultChildren: ObjetosViewDto[];
    cols: any[];
    loading: Boolean;
    totalRecords: number;
    creationTime: string;
    searchInput: string;

    constructor(
        injector: Injector,
        private _avaliacaoDeAmbienteService: AvaliacaoDeAmbienteServiceProxy,
        private router: Router,
        private route: ActivatedRoute
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.cols = [
            { field: 'name', header: 'Tipo' },
            { field: 'size', header: 'Objeto' },
            { field: 'type', header: 'Débitos técnicos' }
        ];    
        
        this.getCreationTime();
        this.getObjectUpper();
    }

    loadNodes(event) {
        this.loading = true;

        setTimeout(() => {
            this.loading = false;
            this.files = [];

            if(event.rows == undefined){
                event.rows = 10;
                event.first = 0;
            }
        
            for(let i = 0; i < event.rows; i++) {
                let node = {
                    data: {  
                        name: this.filesResult[event.first + i].tipoObjeto,
                        size: this.filesResult[event.first + i].nomeObjeto,
                        type: this.filesResult[event.first + i].debitosTecnicos
                    },
                    leaf: false
                };

                this.files.push(node);
            }
        }, 10000);
    }

    async onNodeExpand(event) {
        this.loading = true;
        this.filesResultChildren = [];
        await this.getObjectChildren(event.node.data.size, event.node.data.name);
        setTimeout(() => {
            const node = event.node;
            let childrens: any[] = [];
            for(let i = 0; i < this.filesResultChildren.length; i++) {
                let node = {
                    data: {  
                        name: this.filesResultChildren[i].tipoObjeto,
                        size: this.filesResultChildren[i].nomeObjeto,
                        type: this.filesResultChildren[i].debitosTecnicos
                    },
                    leaf: false
                };
                childrens.push(node);
            }

            //Cria camadas de filhos de cada objeto aberto na árvore da tabela
            node.children = childrens;
            this.loading = false;

            this.files = [...this.files];
        }, 10000);
    }

    getObjectUpper() {
        try {
             this._avaliacaoDeAmbienteService.getObjetosSuperiores('','','','',0, 99999).subscribe(result => {
                this.filesResult = result.items;
                this.totalRecords = result.items.length;
            });
        } catch (error) {
            console.log(error);
        }
    }

    async getObjectChildren(FatherObjectName: string, FatherObjectType: string) {
        try {
                return this._avaliacaoDeAmbienteService.getObjetosIncorporados('', FatherObjectName, FatherObjectType, '', 0, 99999).toPromise().then(result => {
                this.filesResultChildren = result.items;
            });
        } catch (error) {
            console.log(error);
        }
    }

    getCreationTime(){
        this._avaliacaoDeAmbienteService.getUltimaAtualizacaoAgendamento().subscribe(result => {
            this.creationTime = this.getLocaleDateHour(result.ultimaAtualizacao);
        })
    }

    getLocaleDateHour(date: moment.Moment) {
        const dateString = date ? date.toString() : "";
        return new Date(dateString).toLocaleString();
    }

    goToObjectPage(event){
        this.router.navigate(['/app/main/avaliacaoAmbiente/inventario/informacaoObjeto/' + event.node.data.name + '/' + event.node.data.size]);
    }

    searchObject(event){
        this.getObjectUpper();
        let filter = this.searchInput.toUpperCase();
        let filterList;

        if(filter !== ""){
            filterList = this.files.filter(el => el.data.name.includes(filter) || el.data.size.includes(filter) || el.data.type.includes(filter));
            this.files = filterList;
            
        }else{
            this.files = [];
            this.getObjectUpper();
            this.loadNodes(event);
        }
    }
}