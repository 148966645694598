import { Component, Injector, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    AvaliacaoDeAmbienteServiceProxy,
    DashTenantUsersDto,
} from "@shared/service-proxies/service-proxies";
import { MenuItem, SelectItem } from "primeng/api";

@Component({
    selector: "app-reports-host-tenant",
    templateUrl: "./reports-host-tenant.component.html",
    styleUrls: ["./reports-host-tenant.component.less"],
})
export class ReportsHostTenantComponent
    extends AppComponentBase
    implements OnInit
{
    datesOptions: SelectItem[] = [];
    selectedDate: string = "";
    items: MenuItem[] = [];
    isCard: boolean = true;
    currentDate: string = "";
    initDate: string = "";
    urlReport: SafeUrl | undefined;
    noDashs: boolean = false;
    isObjects: boolean = false;
    index: number = 0;
    extractionDates: string[] = [];

    constructor(
        injector: Injector,
        private domSanitizer: DomSanitizer,
        private avaliacaoDeAmbienteService: AvaliacaoDeAmbienteServiceProxy
    ) {
        super(injector);
        this.getDates();
        this.loadTenantDates();
    }

    ngOnInit() {
        this.showPanel(0);
        this.createMenu();
        this.validateDashs(this.appSession.configDashs);
    }

    private loadTenantDates() {
        if (this.appSession.tenantId != null) {
            this.getDatesExtracts(this.appSession.tenantId);
        }
    }

    async getDatesExtracts(tenantId: number) {
        const resp = await this.avaliacaoDeAmbienteService
            .getDatesObjectsExtracts(tenantId)
            .toPromise();
        this.selectedDate = resp[0];
        this.datesOptions = resp.map((date) => ({
            label: this.convertDate(date),
            value: date,
        }));
    }

    private convertDate(date: string): string {
        const [year, month] = date.split("-");
        return `${month}-${year}`;
    }

    setDateDrop() {
        const urls = [
            `https://app1.qametrik.com/public/question/e0721218-45d9-41b2-a1c0-cc08c9831c4f`,
            `https://app1.qametrik.com/public/question/82f725b8-3ea4-43f6-8d64-18a18c9dfc3e`,
            `https://app1.qametrik.com/public/question/ae95df68-cf3c-4176-850c-9c66ff6d66ce`,
            `https://app1.qametrik.com/public/question/1dff5ddf-9653-4ad1-b39c-b6e438bd7675`,
        ];

        const url = urls[this.index - 6];
        this.urlReport = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `${url}?DataExtracao=${this.selectedDate}&TenantId=${this.appSession.tenantId}&DebitoTecnico=Informe%20o%20débito#hide_parameters=TenantId,DataExtracao&theme=transparent`
        );
    }

    viewDropDate(): boolean {
        return this.isObjects && !this.noDashs;
    }

    private getDates() {
        const currentDate = new Date();
        const initDate = new Date();
        initDate.setDate(initDate.getDate() - 30);

        this.currentDate = currentDate.toISOString().slice(0, 10);
        this.initDate = initDate.toISOString().slice(0, 10);
    }

    showPanel(index: number) {
        this.index = index;
        const tenantId = this.appSession.tenantId;

        if (tenantId != null) {
            this.isObjects = false;
            this.setTenantUrl(index, tenantId);
        } else {
            this.setNoTenantUrl(index);
        }
    }

    private setTenantUrl(index: number, tenantId: number) {
        const urls = [
            `https://app1.qametrik.com/public/question/c1a96ad5-05e0-4187-997a-13aa84a3ef1c`,
            `https://app1.qametrik.com/public/question/ce2b4c2a-58fe-4e7e-8b30-d4b6be511f41`,
            `https://app1.qametrik.com/public/question/965ad535-1957-4c0b-8965-654a6091deb8`,
            `https://app1.qametrik.com/public/question/20c67f35-8a46-4f51-9062-40e714a18f9b`,
            `https://app1.qametrik.com/public/question/93b3a291-b6d7-4cf8-8270-58307ca29619`,
            `https://app1.qametrik.com/public/question/5c3d1e68-39e0-484d-bb28-37856125a4ed`,
        ];

        if (index >= 0 && index <= 5) {
            this.urlReport = this.domSanitizer.bypassSecurityTrustResourceUrl(
                `${urls[index]}?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&TenantId=${tenantId}#hide_parameters=TenantId&theme=transparent`
            );
        } else if (index >= 6 && index <= 9) {
            this.isObjects = true;
            this.setDateDrop();
        } else if (index == 10) {
            this.urlReport = this.domSanitizer.bypassSecurityTrustResourceUrl(
                `https://app1.qametrik.com/public/question/7d7eda8a-4c31-4d68-bc7c-33d1a9213e8c#hide_parameters=TenantId&theme=transparent`
            );
        }
    }

    private setNoTenantUrl(index: number) {
        const urls = [
            `https://app1.qametrik.com/public/question/2edf6cc1-df82-4ef0-9dce-6f4041012a59`,
            `https://app1.qametrik.com/public/question/10d93c6d-9561-4cce-8035-0ef7f669e1b6`,
            `https://app1.qametrik.com/public/question/8d2eb8b0-9e94-488c-9d36-3a58499c2add`,
            `https://app1.qametrik.com/public/question/cd3b9899-459c-4146-9f33-3ec32cea8a68`,
            `https://app1.qametrik.com/public/question/17e4ca62-6bb1-4f57-8a96-6257cc55b79d`,
        ];

        if (index >= 0 && index <= 4) {
            this.urlReport = this.domSanitizer.bypassSecurityTrustResourceUrl(
                `${urls[index]}?DataInicial=${this.initDate}&DataFinal=${this.currentDate}&UsuarioCliente=Informe%20o%20nome#theme=transparent`
            );
        }
    }

    private createMenu() {
        const tenantId = this.appSession.tenantId;
        const configDashs = this.appSession.configDashs;

        if (tenantId != null) {
            this.items = [
                {
                    label: "Retrabalho",
                    disabled: !configDashs.isRework,
                    items: [
                        {
                            label: "Liberado Fora do Processo",
                            command: () => this.showPanel(0),
                        },
                        {
                            label: "Liberado Dentro do Processo",
                            command: () => this.showPanel(1),
                        },
                    ],
                },
                {
                    label: "Não Conformidade",
                    disabled: !configDashs.isNonCompliance,
                    items: [
                        {
                            label: "Request Liberado Fora do Processo",
                            command: () => this.showPanel(2),
                        },
                    ],
                },
                {
                    label: `Gmud's`,
                    disabled: !configDashs.isGmud,
                    items: [
                        {
                            label: `Gmud's Criadas Por Tipo`,
                            command: () => this.showPanel(3),
                        },
                    ],
                },
                {
                    label: `Request's`,
                    disabled: !configDashs.isRequest,
                    items: [
                        {
                            label: `Request's Transportadas`,
                            command: () => this.showPanel(4),
                        },
                    ],
                },
                {
                    label: `Timeline Demandas`,
                    disabled: !configDashs.isTimeline,
                    items: [
                        {
                            label: `Timeline Demandas Por Ambiente`,
                            command: () => this.showPanel(5),
                        },
                    ],
                },
                {
                    label: `Avaliação de Ambiente`,
                    disabled: !configDashs.isEnvironmentalAssessment,
                    items: [
                        {
                            label: `Objetos Criados`,
                            command: () => this.showPanel(6),
                        },
                        {
                            label: `Objetos Criados com Débitos Técnicos`,
                            command: () => this.showPanel(7),
                        },
                        {
                            label: `Frequência de Débitos Técnicos`,
                            command: () => this.showPanel(8),
                        },
                        {
                            label: `Objetos Criados com Débitos Técnicos Sumarizados`,
                            command: () => this.showPanel(9),
                        },
                    ],
                },
            ];

            if (this.appSession.tenantId == 5) {
                const gmudsMenu = this.items.find(
                    (item) => item.label === `Gmud's`
                );

                gmudsMenu.items.push({
                    label: `Gmud's Freezing`,
                    command: () => this.showPanel(10),
                });
            }
        } else {
            this.items = [
                {
                    label: "Avaliações de Usuários",
                    items: [
                        {
                            label: `Avaliações de Usuários`,
                            command: () => this.showPanel(0),
                        },
                        {
                            label: `Avaliações de Gestores`,
                            command: () => this.showPanel(1),
                        },
                    ],
                },
                {
                    label: "Uso da Ferramenta",
                    command: () => this.showPanel(2),
                },
                {
                    label: `Gmud's`,
                    items: [
                        {
                            label: `Gmud's Por Ambiente`,
                            command: () => this.showPanel(3),
                        },
                        {
                            label: `Gmud's Por Status`,
                            command: () => this.showPanel(4),
                        },
                    ],
                },
            ];
        }
    }

    private validateDashs(configDashs: DashTenantUsersDto) {
        this.noDashs =
            !configDashs.isRework &&
            !configDashs.isNonCompliance &&
            !configDashs.isGmud &&
            !configDashs.isRequest &&
            !configDashs.isTimeline &&
            !configDashs.isEnvironmentalAssessment;
    }
}
