<div bsModal #codeReviewModal="bs-modal" id="codeReviewModal" class="modal fade overflow-auto" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content code-review-modal">
            <form #codeReviewForm="ngForm" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title" [(ngModel)]="tituloDemanda">
                        Code Review - {{tituloDemanda}}
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="spinner-row">
                        <div class="spinner-loading">
                            <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                        </div>
                    </div>
                    <div *ngIf="!loading" id="criteria-list-content">
                        <div *ngFor="let criterio of listaCriteriosDto; let i = index" class="criteria">
                            <div id="criteria-evaluate">
                                <div class="criteria-title">
                                    <h4 class="title">{{criterio.criterio}}</h4>
                                </div>
                                <div class="criteria-buttons">
                                    <button type="button" id="adequado-button" class="btn btn-outline-success" [ngClass]="{'isAdequado':criterio.adequado}" (click)="criterioAdequado(criterio)">{{l("Adequado")}}</button>
                                    <button type="button" id="inadequado-button" class="btn btn-outline-danger" [ngClass]="{'isInadequado':criterio.inadequado}" (click)="criterioInadequado(criterio)">{{l("Inadequado")}}</button>
                                </div>
                            </div>
                            <form #criteriaEvaluateForm="ngForm" autocomplete="off">
                                <div id="justificativa" class="criteria-justify" *ngIf="criterio.inadequado">
                                    <textarea name="justificativa" id="textarea" type="text" minlength="25" placeholder="Comente o motivo da reprovação com no mínimo 25 caracteres" [(ngModel)]="criterio.justificativa" required></textarea>
                                </div>
                            </form>
                         </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary blue" [disabled]="!canSaveCodeReview || saving" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')" (click)="saveCriterios()">
                        <i class="fa fa-save"></i>
                        <span>{{l("Salvar Code Review")}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>