import { Component, EventEmitter, Injector, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { NotificacaoPortalDto, NotificacaoPortalServiceProxy } from "@shared/service-proxies/service-proxies";
import { AbpSessionService } from "abp-ng2-module";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: 'app-portal-notifications-modal',
    templateUrl: './portal-notifications-modal.component.html',
    styleUrls: ['./portal-notifications-modal.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class PortalNotificationsModal extends AppComponentBase implements OnInit {
    @ViewChild('portalNotificationsModal', { static: true }) portalNotificationsModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    
    listaMensagens: NotificacaoPortalDto[] = [];

    constructor(
        injector: Injector,
        private _notificacaoPortalServiceProxy: NotificacaoPortalServiceProxy,
        private _abpSession: AbpSessionService) {
        super(injector);
    }

    ngOnInit(): void {

    }

    show(): void {
        this.portalNotificationsModal.show();
    }
    
    close(): void {
        this.portalNotificationsModal.hide();
        this.closed.emit();
    }

    check() {
        this._notificacaoPortalServiceProxy.updateComunicadoPortal(this._abpSession.userId)
            .subscribe(() => {
                this.close();
            })
    }
}