<div [@routerTransition]>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("Cargos")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{l("CargosHeaderInfo")}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
				<button (click)="exportToExcel()" class="btn btn-outline-success"><i class="fa fa-file-excel"></i> {{l("ExportToExcel")}}</button>
                <button *ngIf="isGranted('Pages.Administration.Cargos.Create')" (click)="createCargo()"
			class="btn btn-primary blue"><i class="fa fa-plus"></i> {{l("CreateNewCargo")}}</button>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">

                <form class="kt-form" autocomplete="off">
                    <div>
                        <div class="row align-items-center">
                            <div class="col-xl-12">
                                <div class="form-group m-form__group align-items-center">
                                    <div class="input-group">
                                        <input [(ngModel)]="filterText" name="filterText" autoFocus class="form-control m-input" [placeholder]="l('SearchWithThreeDot')" type="text">
                                        <span class="input-group-btn">
                                            <button (click)="getCargos()" class="btn btn-primary" type="submit"><i class="flaticon-search-1"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row align-items-center">
                    <!--<Primeng-Datatable-Start>-->
                    <div class="primeng-datatable-container col-12"
                         [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable
                                     (onLazyLoad)="getCargos($event)"
                                     [value]="primengTableHelper.records"
                                     rows="{{primengTableHelper.defaultRecordsCountPerPage}}" 
                                     [paginator]="false"
                                     [lazy]="true"
                                     [scrollable]="true"
                                     ScrollWidth="100%"
                                     [responsive]="primengTableHelper.isResponsive"
                                     [resizableColumns]="primengTableHelper.resizableColumns">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 130px" [hidden]="!isGrantedAny('Pages.Administration.Cargos.Edit', 'Pages.Administration.Cargos.Delete')">{{l('Actions')}}</th>
                                    <th style="width: 150px" pSortableColumn="nome">
                                        {{l('Name')}}
                                        <p-sortIcon field="cargo.nome"></p-sortIcon>
                                    </th>

                                </tr>
                            </ng-template>
							<ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td style="width: 130px"
                                        [hidden]="!isGrantedAny('Pages.Administration.Cargos.Edit', 'Pages.Administration.Cargos.Delete')">
                                       <div class="btn-group dropdown" dropdown container="body">
                                            <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle>
                                                <i class="fa fa-cog"></i><span class="caret"></span> {{l("Actions")}}
                                            </button>
												<ul class="dropdown-menu"  *dropdownMenu>

												<li *ngIf="record.cargo.tenantId == appSession.tenantId">
													<a href="javascript:;" *ngIf="permission.isGranted('Pages.Administration.Cargos.Edit')"
														(click)="createOrEditCargoModal.show(record.cargo.id)">{{l('Edit')}}</a>
												</li>
												<li *ngIf="record.cargo.tenantId == appSession.tenantId ">
											        <a href="javascript:;" *ngIf="permission.isGranted('Pages.Administration.Cargos.Delete')"
														(click)="deleteCargo(record.cargo)">{{l('Delete')}}</a>
												</li>
                                                <li >
                                                    <a href="javascript:;" *ngIf="entityHistoryEnabled"
                                                       (click)="showHistory(record.cargo)">{{l('History')}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td style="width:150px">
											{{record.cargo.nome}}
										</td>

                                </tr>
                            </ng-template>
                        </p-table>
						<div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{l('NoData')}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                         #paginator
                                         (onPageChange)="getCargos($event)"
                                         [totalRecords]="primengTableHelper.totalRecordsCount"
                                         [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">

                            </p-paginator>
                            <span class="total-records-count">
                                {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-Datatable-End>-->
                </div>
            </div>
        </div>
    </div>

    <createOrEditCargoModal #createOrEditCargoModal (modalSave)="getCargos()"></createOrEditCargoModal>

     <entityTypeHistoryModal #entityTypeHistoryModal></entityTypeHistoryModal>
</div>
