<div bsModal #justificativaModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="justificativaModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form #form="ngForm" (ngSubmit)="save()" autocomplete="off">
        <div class="modal-header">
          <h4 class="modal-title">
            <span>{{l("JustificativaDecisorHeader")}} </span>
          </h4>
          <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
         
          
        </div>
        <div class="modal-body">
          <div class="row">
            <span>{{l("JustificativaDecisorSub")}}<br> </span> 
          </div>
          <div class="row">
           
            <angular-editor  [(ngModel)]="justificativa" name="just" [config]="config"></angular-editor>
          </div>
        </div>
        <div class="modal-footer">
          <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>


          
          <button [disabled]="!form.valid || saving" class="btn btn-outline-success pull-right" type="submit" >
            {{l("Save")}}</button>


         
        </div>
      </form>
    </div>
  </div>
</div>