import { Component, EventEmitter, Injector, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ModalDirective } from "ngx-bootstrap/modal";
import { GerenciamentoVersaoSapServiceProxy, GerenciamentoVersaoSapAtualizacaoDto, GerenciamentoVersaoSapAtualizacaoInput, EnumStatusGerenciamentoVersaoSap } from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'deploy-customers-modal',
    templateUrl: './deploy-customers-modal.component.html',
    styleUrls: ['./deploy-customers-modal.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class DeployCustomersComponent extends AppComponentBase implements OnInit {
    @ViewChild('deployCustomersModal', { static: true }) deployCustomersModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();

    loading: boolean = false;

    deployCustomersList: GerenciamentoVersaoSapAtualizacaoDto[] = [];
    gerenciamentoVersaoSapId: string;

    mensagemErro: string;

    constructor(
        injector: Injector,
        private _gerenciamentoVersaoSapServiceProxy: GerenciamentoVersaoSapServiceProxy
    ) {
        super(injector);
    }
    
    ngOnInit(): void {
        
    }

    async show(id: string) {
        this.loading = true;
        this.gerenciamentoVersaoSapId = id;
        this.getDeployCustomersList();
    }

    getDeployCustomersList(){
        this.loading = true;
        this._gerenciamentoVersaoSapServiceProxy.getAtualizacoesSap(this.gerenciamentoVersaoSapId)
            .subscribe(res => {
                this.loading = false;
                this.deployCustomersList = res;

                this.deployCustomersModal.show();
            })
    }

    close() {
        this.deployCustomersModal.hide();
        this.closed.emit();
        this.loading = true;
    }

    deploy(gerenciamentoVersaoSapId: string, tenantId: number) {
        let gerenciamentoVersaoSapAtualizacaoInput = new GerenciamentoVersaoSapAtualizacaoInput();
        gerenciamentoVersaoSapAtualizacaoInput.gerenciamentoVersaoSapId = gerenciamentoVersaoSapId;
        gerenciamentoVersaoSapAtualizacaoInput.tenantId = tenantId;
        this._gerenciamentoVersaoSapServiceProxy.solicitarAtualizacaoSap(gerenciamentoVersaoSapAtualizacaoInput)
            .subscribe(() => {
                this.reloadPage(null)
            });
    }

    forcarDeploy(gerenciamentoVersaoSapAtualizacaoId: string) {
        this._gerenciamentoVersaoSapServiceProxy.forcarGerenciamentoVersaoSap(gerenciamentoVersaoSapAtualizacaoId)
            .subscribe(() => {
                this.reloadPage(null)
            });
    }

    reloadPage(ev: any) {
        this.getDeployCustomersList();
    }

    mudarStatus(deploy: GerenciamentoVersaoSapAtualizacaoDto) {
        switch (deploy.status) {
          case EnumStatusGerenciamentoVersaoSap.SemProcessamento:
            return '-';
          case EnumStatusGerenciamentoVersaoSap.EmProcessamento:
            return 'Em processamento';
          case EnumStatusGerenciamentoVersaoSap.Cancelado:
            return 'Cancelado';
          case EnumStatusGerenciamentoVersaoSap.Atualizado:
            return 'Atualizado';
          default:
            return '';
        }
    }

    getLocaleDateHourNotificacao(dataHora: moment.Moment) {
        if(dataHora == undefined) return "";
        const dateString = dataHora ? dataHora.toString() : "";
        return new Date(dateString).toLocaleString();
    }
}