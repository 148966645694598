<div [@routerTransition] id="code-review-config" class="overflow-auto">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("Code Review")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{l("Lista de avaliação")}}
            </span>
        </div>        
    </div>
    <div class="kt-content code-review-content">
        <div id="code-review-history" class="code-review-history">
            <button class="btn btn-primary" type="submit" (click)="showCodeReviewHistoryModal()">
                <i class="fa fa-history"></i>{{l("Histórico")}}
            </button>
        </div>
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <div id="evaluate-table">
                    <p-table #dt [value]="listaCodeReviewSolicitadosDto" [rows]="5" [paginator]="true" dataKey="id" [rowsPerPageOptions]="[5,10,25,50,100,500]" [lazy]="true" [loading]="loading">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="th-demand" scope="col">{{l("Demanda")}}</th>
                                <th class="th-user-request" scope="col">{{l("Solicitante")}}</th>
                                <th class="th-status" scope="col">{{l("Situação")}}</th>
                                <th class="th-actions" scope="col">{{l("Ações")}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-solicitacao>
                            <tr>
                                <td class="td-demand">{{solicitacao.tituloDemanda}}</td>
                                <td class="td-user-request">{{solicitacao.solicitante}}</td>
                                <td class="td-status">{{solicitacao.situacao}}</td>
                                <td class="td-actions">
                                    <button class="btn btn-primary" type="submit" (click)="showCodeReviewModal(solicitacao.codeReviewDemandaId)">
                                        <i class="far fa-file-code"></i>{{l("Efetuar Code Review")}}
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="flex align-items-center justify-content-between">
                                Total: {{listaCodeReviewSolicitadosDto ? listaCodeReviewSolicitadosDto.length : 0 }}
                            </div>
                        </ng-template>
                    </p-table>
                </div>      
            </div>
        </div>
    </div>
    <code-review-history-modal #codeReviewHistoryModal (closed)="reloadPage($event)"></code-review-history-modal>
    <code-review-modal #codeReviewModal (closed)="reloadPage($event)"></code-review-modal>
</div>