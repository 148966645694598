<div [@routerTransition]>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{"Subscription" | localize}}</span>
            </h3>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <tabset>
                    <tab heading="{{'SubscriptionInformation' | localize}}">
                        <form class="kt-form">
                            <input type="hidden" Name="PaymentPeriodType" value="@tenant.PaymentPeriodType" />
                            <div class="form-group row">
                                <label class="col-sm-4 control-label font-weight-bold text-right">{{"Edition" | localize}}</label>
                                <div class="col-sm-8">
                                    <p *ngIf="tenant.edition">
                                        {{tenant.edition.displayName}}
                                        <span *ngIf="tenant.edition.isFree"
                                            class="kt-badge kt-badge--success kt-badge--inline">{{"Free" | localize}}</span>
                                        <span *ngIf="tenant.isInTrialPeriod"
                                            class="kt-badge kt-badge--warning kt-badge--inline">{{"Trial" | localize}}</span>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-4 control-label font-weight-bold text-right">{{"SubscriptionStartDate" | localize}}</label>
                                <div class="col-sm-8">
                                    <p>{{ tenant.creationTimeString }}</p>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-sm-4 control-label font-weight-bold text-right">{{"SubscriptionEndDate" | localize}}</label>
                                <div class="col-sm-8">
                                    <p *ngIf="tenant.edition">
                                        <span>{{tenant.subscriptionDateString}}</span>
                                    </p>
                                </div>
                            </div>

                            <div class="form-group row"
                                [hidden]="tenant.subscriptionPaymentType != subscriptionPaymentType.RecurringAutomatic">
                                <label class="col-md-4 control-label"></label>
                                <div class="col-md-8">
                                    <button class="btn btn-secondary" (click)="disableRecurringPayments()">
                                        {{"DisableRecurringPayments" | localize}}
                                    </button>
                                </div>
                            </div>

                            <div class="form-group row"
                                [hidden]="tenant.subscriptionPaymentType != subscriptionPaymentType.RecurringManual">
                                <label class="col-md-4 control-label"></label>
                                <div class="col-md-8">
                                    <button class="btn btn-info" (click)="enableRecurringPayments()">
                                        {{"EnableRecurringPayments" | localize}}
                                    </button>
                                </div>
                            </div>

                        </form>
                    </tab>
                    <!-- <tab heading="{{'PaymentHistory' | localize}}">
                        <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                            <p-table #dataTable (onLazyLoad)="getPaymentHistory($event)"
                                [value]="primengTableHelper.records"
                                rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false"
                                [lazy]="true" [scrollable]="true" ScrollWidth="100%"
                                [responsive]="primengTableHelper.isResponsive">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 130px">{{'Invoice' | localize}}</th>
                                        <th style="width: 150px" pSortableColumn="creationTime">
                                            {{'ProcessTime' | localize}}
                                            <p-sortIcon field="creationTime"></p-sortIcon>
                                        </th>
                                        <th style="width: 150px" pSortableColumn="editionDisplayName">
                                            {{'Edition' | localize}}
                                            <p-sortIcon field="editionDisplayName"></p-sortIcon>
                                        </th>
                                        <th style="width: 150px" pSortableColumn="gateway">
                                            {{'Gateway' | localize}}
                                            <p-sortIcon field="gateway"></p-sortIcon>
                                        </th>
                                        <th style="width: 100px" pSortableColumn="amount">
                                            {{'Amount' | localize}}
                                            <p-sortIcon field="amount"></p-sortIcon>
                                        </th>
                                        <th style="width: 150px" pSortableColumn="status">
                                            {{'Status' | localize}}
                                            <p-sortIcon field="status"></p-sortIcon>
                                        </th>
                                        <th style="width: 150px" pSortableColumn="paymentPeriodType">
                                            {{'Period' | localize}}
                                            <p-sortIcon field="paymentPeriodType"></p-sortIcon>
                                        </th>
                                        <th style="width: 100px">{{'DayCount' | localize}}</th>
                                        <th style="width: 250px;">{{'PaymentId' | localize}}</th>
                                        <th style="width: 150px" pSortableColumn="invoiceNo">
                                            {{'InvoiceNo' | localize}}
                                            <p-sortIcon field="invoiceNo"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-record="$implicit">
                                    <tr>
                                        <td style="width: 130px">
                                            <button type="button" class="btn btn-sm btn-primary"
                                                (click)="createOrShowInvoice(record.id, record.invoiceNo)">{{'ShowInvoice' | localize}}</button>
                                        </td>
                                        <td style="width: 150px">
                                            <span *ngIf="record.creationTime">{{record.creationTime | momentFormat:'L'}}</span>
                                            <span *ngIf="!record.creationTime">-</span>
                                        </td>
                                        <td style="width: 150px">
                                            {{record.editionDisplayName}}
                                        </td>
                                        <td style="width: 150px">
                                            {{'SubscriptionPaymentGatewayType_' + record.gateway | localize}}
                                        </td>
                                        <td style="width: 100px">
                                            {{appSession.application.currencySign}} {{record.amount | number : '1.2-2'}}
                                        </td>
                                        <td style="width: 150px">
                                            {{'SubscriptionPaymentStatus_' + record.status | localize}}
                                        </td>
                                        <td style="width: 150px">
                                            {{'PaymentPeriodType_' + record.paymentPeriodType | localize}}
                                        </td>
                                        <td style="width: 100px">
                                            {{record.dayCount}}
                                        </td>
                                        <td style="width: 250px;">
                                            {{record.paymentId}}
                                        </td>
                                        <td style="width: 150px">
                                            {{record.invoiceNo}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                                {{'NoData' | localize}}
                            </div>
                            <div class="primeng-paging-container">
                                <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                                    (onPageChange)="getPaymentHistory($event)"
                                    [totalRecords]="primengTableHelper.totalRecordsCount"
                                    [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                                </p-paginator>
                                <span class="total-records-count">
                                    {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                                </span>
                            </div>
                        </div>
                    </tab> -->
                </tabset>
            </div>
        </div>
    </div>
</div>