<p-toast></p-toast>
<div [@routerTransition] id="schedEnvirEvaluation" class="overflow-auto">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("EnvironmentEvaluation")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{l("Scheduling")}}
            </span>
        </div>
    </div>

    <div class="kt-content scheduling">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <div class="spinner-row">
                    <div class="spinner-loading">
                        <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                    </div>
                </div>

                <div class="form-group" *ngIf="!loading">
                    <p-tabView styleClass="tabview-custom">
                        <p-tabPanel header="Configuração">
                            <h6 class="mb-5">{{l('EnvironmentSetting')}}</h6>
                            <div class="form-check form-check-inline">
                                <div class="box-all-settings">
                                    <input type="checkbox" id="scheduling-all" value="schedulingAll"
                                        [indeterminate]="!checked" [(ngModel)]="checked" />
                                    <label class="ml-1" for="scheduling-all">{{l('AllEnvironment')}}</label>
                                </div>

                                <div class="box-obj-struct-settings">
                                    <input class="ml-5" type="checkbox" id="scheduling-obj-struct"
                                        value="schedulingObjStruct" checked />
                                    <label class="ml-1" for="scheduling-obj-struct">{{l('ObjectsStructure')}}</label>
                                </div>

                                <div class="box-qual-eval-settings">
                                    <input class="ml-5" type="checkbox" id="scheduling-quality-evaluation"
                                        value="schedulingQualEval" [(ngModel)]="checked" />
                                    <label class="ml-1"
                                        for="scheduling-quality-evaluation">{{l('QualityAssessment')}}</label>
                                </div>
                            </div>
                            <form #configScheduling="ngForm" (ngSubmit)="agendamento()">
                                <div class="box-scheduling mt-5">
                                    <div class="box-title-sheduling">
                                        <label for="title-scheduling">{{l('EvaluationTitle')}}</label>
                                        <input #titleScheduling="ngModel" type="text" name="titleScheduling"
                                            id="title-scheduling" placeholder="{{l('TitleForThisEvaluation')}}"
                                            [(ngModel)]="descricao" required />
                                    </div>

                                    <div class="box-button-sheduling">
                                        <button type="submit" [disabled]="configScheduling.invalid"
                                            id="button-scheduling" class="btn btn-primary btn-sm ml-3">
                                            <i class="fas fa-clock"></i>{{l('ToSchedule')}}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </p-tabPanel>
                        <!-- <p-tabPanel header="Exceções">
                            <div class="flex flex-row justify-content-between">
                                <div class="objects">
                                    <h6 class="mb-4">Informe o Tipo de Objeto que deseja eliminar</h6>
                                    <div class="p-inputgroup mb-4">
                                        <input type="text" [(ngModel)]="objectName" pInputText
                                            placeholder="Digite o Objeto" />
                                        <p-button icon="fa fa-check" (click)="setObjectName(objectName)"></p-button>
                                    </div>
                                    <div class="flex justify-content-center gap-2 mt-4">
                                        <p-tag class="mr-2" icon="fa fa-times" severity="danger" [value]="object"
                                            *ngFor="let object of objectsList" [rounded]="true"
                                            (click)="removeObjectName(object)">
                                        </p-tag>
                                    </div>
                                </div>
                                <div class="programs">
                                    <h6 class="mb-4">Informe o Tipo de Programa que deseja eliminar</h6>
                                    <div class="p-inputgroup mb-4">
                                        <input type="text" [(ngModel)]="programName" pInputText
                                            placeholder="Digite o Programa" />
                                        <p-button icon="fa fa-check" (click)="setProgramName(programName)"></p-button>
                                    </div>
                                    <div class="flex justify-content-center gap-2 mt-4">
                                        <p-tag class="mr-2" icon="fa fa-times" severity="danger" [value]="program"
                                            *ngFor="let program of notProgramsNames" [rounded]="true"
                                            (click)="removeProgramName(program)">
                                        </p-tag>
                                    </div>
                                </div>
                            </div>
                        </p-tabPanel> -->
                    </p-tabView>
                </div>
            </div>
        </div>
    </div>

    <div class="kt-content scheduling-list">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable (onLazyLoad)="recarregar($event)" [value]="primengTableHelper.records"
                            [loading]="loadingTable" rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                            [paginator]="false" [lazy]="true" [loading]="loading" [scrollable]="true" ScrollWidth="100%"
                            [responsive]="primengTableHelper.isResponsive"
                            [resizableColumns]="primengTableHelper.resizableColumns">
                            <ng-template pTemplate="header">
                                <tr class="table-header">
                                    <th pSortableColumn="evaluationTitle">
                                        {{l("EvaluationTitle")}}
                                    </th>
                                    <th pSortableColumn="responsible">
                                        {{l("Responsible")}}
                                    </th>
                                    <th pSortableColumn="time">
                                        {{l("Time")}}
                                    </th>
                                    <th pSortableColumn="objectsStructure">
                                        {{l("ObjectsStructure")}}
                                    </th>
                                    <th pSortableColumn="qualityAssessment">
                                        {{l("QualityAssessment")}}
                                    </th>
                                    <th>{{l("Actions")}}</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr class="table-body">
                                    <td>
                                        {{record.tituloAgendamento}}
                                    </td>
                                    <td>
                                        {{record.nomeResponsavel}}
                                    </td>
                                    <td>
                                        {{getLocaleDateHour(record.dataAgendamento)}}
                                    </td>
                                    <td>
                                        {{record.quantidadeEstruturaObjetoRecebida}}/{{record.quantidadeEstruturaObjeto}}
                                    </td>
                                    <td>
                                        {{record.quantidadeAvaliacaoQualidadeRecebida}}/{{record.quantidadeAvaliacaoQualidade}}
                                    </td>
                                    <td style="width: 130px;">
                                        <div class="btn-group dropdown" dropdown container="body">
                                            <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle>
                                                <i class="fa fa-cog"></i><span class="caret"></span> {{l("Actions")}}
                                            </button>
                                            <ul class="dropdown-menu" *dropdownMenu
                                                style="min-width: 10rem !important;">
                                                <li>
                                                    <a href="javascript:;"
                                                        (click)="updateScheduling(record.id)">{{l('Update')}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;"
                                                        (click)="synchronizeEnvironment(record.id)">{{l('Sincronizar')}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{l('NoData')}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                                (onPageChange)="recarregar($event)"
                                [totalRecords]="primengTableHelper.totalRecordsCount"
                                [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>