<div [@routerTransition]>

    <div class="kt-subheader kt-grid__item">

        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("MinhasSenhas")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{l("MinhasSenhasHeaderInfo")}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
				<button (click)="exportToExcel()" class="btn btn-outline-success"><i class="fa fa-file-excel"></i> {{l("ExportToExcel")}}</button>
                <button *ngIf="isGranted('Pages.Administration.MinhasSenhas.Create')" (click)="createMinhaSenha()"
			class="btn btn-primary blue"><i class="fa fa-plus"></i> {{l("CreateNewMinhaSenha")}}</button>
            </div>
        </div>

    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <form class="kt-form" autocomplete="off">
                    <div>
                        <div class="row align-items-center">
                            <div class="col-xl-12">
                                <div class="form-group m-form__group align-items-center">
                                    <div class="input-group">
                                        <input [(ngModel)]="filterText" name="filterText" autoFocus class="form-control m-input" [placeholder]="l('SearchWithThreeDot')" type="text">
                                        <span class="input-group-btn">
                                            <button (click)="getMinhasSenhas()" class="btn btn-primary" type="submit"><i class="flaticon-search-1"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
						 <div class="row" [hidden]="!advancedFiltersAreShown">
						 	<div class="col-md-12">
						         <div class="kt-separator kt-separator--border-dashed"></div>
						     </div>


							<div class="col-md-3">
								<div class="form-group">
									<label for="AmbienteTenantIdFilter">{{l("Ambiente")}}</label>
									<input type="text" class="form-control" id="AmbienteTenantIdFilter" name="ambienteTenantIdFilter" [(ngModel)]="ambienteTenantIdFilter">
                                </div>
                            </div>
							<div class="col-md-3">
								<div class="form-group">
									<label for="UsuarioSapTenantIdFilter">{{l("UsuarioSap")}}</label>
									<input type="text" class="form-control" id="UsuarioSapTenantIdFilter" name="usuarioSapTenantIdFilter" [(ngModel)]="usuarioSapTenantIdFilter">
                                </div>
                            </div>

                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12">
                                <span class="clickable-item text-muted" *ngIf="!advancedFiltersAreShown" (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i class="fa fa-angle-down"></i> {{l("ShowAdvancedFilters")}}</span>
                                <span class="clickable-item text-muted" *ngIf="advancedFiltersAreShown" (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i class="fa fa-angle-up"></i> {{l("HideAdvancedFilters")}}</span>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row align-items-center">
                    <!--<Primeng-Datatable-Start>-->
                    <div class="primeng-datatable-container col-12"
                         [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable
                                     (onLazyLoad)="getMinhasSenhas($event)"
                                     [value]="primengTableHelper.records"
                                     rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                     [paginator]="false"
                                     [lazy]="true"
                                     [scrollable]="true"
                                     ScrollWidth="100%"
                                     [responsive]="primengTableHelper.isResponsive"
                                     [resizableColumns]="primengTableHelper.resizableColumns">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 130px" [hidden]="!isGrantedAny('Pages.Administration.MinhasSenhas.Edit', 'Pages.Administration.MinhasSenhas.Delete')">{{l('Actions')}}</th>
                                    <th style="width: 150px" pSortableColumn="ambienteFk.tenantId">
                                        {{l('Ambiente')}}
                                        <p-sortIcon field="ambienteTenantId"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="usuarioSapFk.tenantId">
                                        {{l('UsuarioSap')}}
                                        <p-sortIcon field="usuarioSapTenantId"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="senhas">
                                        {{l('Senha')}}
                                        <p-sortIcon field="minhaSenha.senhas"></p-sortIcon>
                                    </th>

                                </tr>
                            </ng-template>
							<ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td style="width: 130px"
                                        [hidden]="!isGrantedAny('Pages.Administration.MinhasSenhas.Edit', 'Pages.Administration.MinhasSenhas.Delete')">
                                       <div class="btn-group dropdown" dropdown container="body">
                                            <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle>
                                                <i class="fa fa-cog"></i><span class="caret"></span> {{l("Actions")}}
                                            </button>
												<ul class="dropdown-menu"  *dropdownMenu>
												<li>
													<a href="javascript:;" *ngIf="permission.isGranted('Pages.Administration.MinhasSenhas.Edit')"
														(click)="createOrEditMinhaSenhaModal.show(record.minhaSenha.id)">{{l('Edit')}}</a>
												</li>
												<li>
											        <a href="javascript:;" *ngIf="permission.isGranted('Pages.Administration.MinhasSenhas.Delete')"
														(click)="deleteMinhaSenha(record.minhaSenha)">{{l('Delete')}}</a>
												</li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="entityHistoryEnabled"
                                                       (click)="showHistory(record)">{{l('History')}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td style="width:150px">
												{{record.nomeAmbiente}}
											</td>
                                    <td style="width:150px">
												{{record.nomeUsuarioSap}}
											</td>
                                    <td style="width:150px">
											{{record.minhaSenha.senhas}}
										</td>

                                </tr>
                            </ng-template>
                        </p-table>
						<div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{l('NoData')}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                         #paginator
                                         (onPageChange)="getMinhasSenhas($event)"
                                         [totalRecords]="primengTableHelper.totalRecordsCount"
                                         [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-Datatable-End>-->
                </div>
            </div>
        </div>
    </div>

    <createOrEditMinhaSenhaModal #createOrEditMinhaSenhaModal (modalSave)="getMinhasSenhas()"></createOrEditMinhaSenhaModal>
     <entityTypeHistoryModal #entityTypeHistoryModal></entityTypeHistoryModal>

</div>
