import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ConfiguracaoAreaUsuarioServiceProxy, GetNotificacaoPortalDto } from "@shared/service-proxies/service-proxies";
@Component({
    selector: 'news-feed',
    templateUrl: './newsFeed.component.html',
    styleUrls: ['./newsFeed.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class NewsFeedComponent extends AppComponentBase implements OnInit {
    notificationList: GetNotificacaoPortalDto[] = [];
    exibirQuantidadeInicial = 5;
    quantidadePorVez = 5;
    totalItens: number;
    exibirTodos: boolean = false;

    constructor(
        injector: Injector,
        private _ConfiguracaoAreaUsuarioService: ConfiguracaoAreaUsuarioServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.carregarItens();
    }

    carregarItens() {
        this._ConfiguracaoAreaUsuarioService.getNotificacaoPortal(this.appSession.userId)
            .subscribe(res => {
                this.notificationList = res.slice(0, this.exibirQuantidadeInicial);
                this.totalItens = res.length;
            
                if (this.exibirQuantidadeInicial >= this.totalItens) {
                  this.exibirTodos = true;
                }
            });
    }

    carregarMaisItens() {
        this.exibirQuantidadeInicial += this.quantidadePorVez;
        this.carregarItens();
      }

    notificationLike(id: string) {
        var notification = this.notificationList.filter(x => x.id == id);
        this._ConfiguracaoAreaUsuarioService.updateNotification(id, this.appSession.userId)
            .subscribe(() => {
                notification[0].like = !notification[0].like;
            });
    }

    toggleItem(item: GetNotificacaoPortalDto) {
        item.expanded = !item.expanded;
      }
}