<div id="tabs-configuration">
    <div class="kt-content">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <tabset>
                    <tab heading="{{'Parâmetros'}}">
                        <tabs-parameters></tabs-parameters>
                    </tab>
                    <tab heading="{{'Janela de transporte'}}">
                        <tabs-schedulingException></tabs-schedulingException>
                    </tab>
                    <tab heading="{{'Freezing'}}">
                        <appprovalUserDeploy></appprovalUserDeploy>
                        <recurringPeriods></recurringPeriods>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>
