<div [@routerTransition]>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("Fornecedores")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{l("FornecedoresHeaderInfo")}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
				<button (click)="exportToExcel()" class="btn btn-outline-success"><i class="fa fa-file-excel"></i> {{l("ExportToExcel")}}</button>
                <button *ngIf="isGranted('Pages.Administration.Fornecedores.Create')" (click)="createFornecedor()" 
			class="btn btn-primary blue"><i class="fa fa-plus"></i> {{l("CreateNewFornecedor")}}</button>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
			
                <form class="kt-form" autocomplete="off">
                    <div>
                        <div class="row align-items-center">
                            <div class="col-xl-12">
                                <div class="form-group m-form__group align-items-center">
                                    <div class="input-group">
                                        <input [(ngModel)]="filterText" name="filterText" autoFocus class="form-control m-input" [placeholder]="l('SearchWithThreeDot')" type="text">
                                        <span class="input-group-btn">
                                            <button (click)="getFornecedores()" class="btn btn-primary" type="submit"><i class="flaticon-search-1"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
						 <div class="row" [hidden]="!advancedFiltersAreShown">
						 	<div class="col-md-12">
						         <div class="kt-separator kt-separator--border-dashed"></div>
						     </div>


							<div class="col-md-3">
								<div class="form-group">
									<label for="NomeFilter">{{l("Name")}}</label>
									<input type="text" class="form-control" id="NomeFilter" name="nomeFilter" [(ngModel)]="nomeFilter">
								</div>
                            </div>
							<div class="col-md-3">
								<div class="form-group">
									<label for="CnpjFilter">{{l("Cnpj")}}</label>
									<input type="text" class="form-control" id="CnpjFilter" name="cnpjFilter" [(ngModel)]="cnpjFilter">
								</div>
                            </div>
							<div class="col-md-3">
								<div class="form-group">
									<label for="CepFilter">{{l("Cep")}}</label>
									<input type="text" class="form-control" id="CepFilter" name="cepFilter" [(ngModel)]="cepFilter">
								</div>
                            </div>
							<div class="col-md-3">
								<div class="form-group">
									<label for="EstadoFilter">{{l("Estado")}}</label>
									<input type="text" class="form-control" id="EstadoFilter" name="estadoFilter" [(ngModel)]="estadoFilter">
								</div>
                            </div>	
							<div class="col-md-3">
								<label for="AtivoFilterSelect">{{l("Ativo")}}</label>
								<select class="form-control" name="AtivoFilter" id="AtivoFilterSelect" [(ngModel)]="ativoFilter">
								<option value="-1">{{l("All")}}</option>
								<option value="0">{{l("False")}}</option>
								<option value="1">{{l("True")}}</option>
								</select>
                            </div>

                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12">
                                <span class="clickable-item text-muted" *ngIf="!advancedFiltersAreShown" (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i class="fa fa-angle-down"></i> {{l("ShowAdvancedFilters")}}</span>
                                <span class="clickable-item text-muted" *ngIf="advancedFiltersAreShown" (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i class="fa fa-angle-up"></i> {{l("HideAdvancedFilters")}}</span>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row align-items-center">
                    <!--<Primeng-Datatable-Start>-->
                    <div class="primeng-datatable-container col-12"
                         [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable
                                     (onLazyLoad)="getFornecedores($event)"
                                     [value]="primengTableHelper.records"
                                     rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                     [paginator]="false"
                                     [lazy]="true"
                                     [scrollable]="true"
                                     ScrollWidth="100%"
                                     [responsive]="primengTableHelper.isResponsive"
                                     [resizableColumns]="primengTableHelper.resizableColumns">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 130px" [hidden]="!isGrantedAny('Pages.Administration.Fornecedores.Edit', 'Pages.Administration.Fornecedores.Delete')">{{l('Actions')}}</th>
                                    <th style="width: 150px" pSortableColumn="nome">
                                        {{l('Name')}}
                                        <p-sortIcon field="fornecedor.nome"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="cnpj">
                                        {{l('Cnpj')}}
                                        <p-sortIcon field="fornecedor.cnpj"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="cep">
                                        {{l('Cep')}}
                                        <p-sortIcon field="fornecedor.cep"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="estado">
                                        {{l('Estado')}}
                                        <p-sortIcon field="fornecedor.estado"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="cidade">
                                        {{l('Cidade')}}
                                        <p-sortIcon field="fornecedor.cidade"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="ativo">
                                        {{l('Ativo')}}
                                        <p-sortIcon field="fornecedor.ativo"></p-sortIcon>
                                    </th>

                                </tr>
                            </ng-template>                           
							<ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td style="width: 130px"
                                        [hidden]="!isGrantedAny('Pages.Administration.Fornecedores.Edit', 'Pages.Administration.Fornecedores.Delete')">
                                       <div class="btn-group dropdown" dropdown container="body">
                                            <button class="dropdown-toggle btn btn-sm btn-primary" dropdownToggle>
                                                <i class="fa fa-cog"></i><span class="caret"></span> {{l("Actions")}}
                                            </button>
												<ul class="dropdown-menu"  *dropdownMenu>
												<li>
													<a href="javascript:;"
														(click)="viewFornecedorModal.show(record)">{{l('View')}}</a>
												</li>
												<li>
													<a href="javascript:;" *ngIf="permission.isGranted('Pages.Administration.Fornecedores.Edit')"
														(click)="createOrEditFornecedorModal.show(record.fornecedor.id)">{{l('Edit')}}</a>
												</li>
												<li>
											        <a href="javascript:;" *ngIf="permission.isGranted('Pages.Administration.Fornecedores.Delete')"
														(click)="deleteFornecedor(record.fornecedor)">{{l('Delete')}}</a>
                                                </li>
                                                
                                                <li >
                                                    <a href="javascript:;" *ngIf="entityHistoryEnabled"
                                                       (click)="showHistory(record.fornecedor)">{{l('History')}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td style="width:150px">
											{{record.fornecedor.nome}}
										</td>
                                    <td style="width:150px">
                                            {{ record.fornecedor.cnpj | mask: '00.000.000/0000-000' }}
										</td>
                                    <td style="width:150px">
                                            {{record.fornecedor.cep | mask: '00000-000'}}
										</td>
                                    <td style="width:150px">
											{{record.fornecedor.estado}}
										</td>
                                    <td style="width:150px">
											{{record.fornecedor.cidade}}
										</td>
                                    <td style="width:150px">
                                        <div *ngIf="record.fornecedor.ativo" class="text-center"><i class="fa fa-check-circle kt--font-success" title="True"></i></div>
										<div *ngIf="!record.fornecedor.ativo" class="text-center"><i class="fa fa-times-circle" title="False"></i></div>
                                    </td>

                                </tr>
                            </ng-template>
                        </p-table>
						<div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{l('NoData')}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                         #paginator
                                         (onPageChange)="getFornecedores($event)"
                                         [totalRecords]="primengTableHelper.totalRecordsCount"
                                         [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-Datatable-End>-->
                </div>
            </div>
        </div>
    </div>

    <createOrEditFornecedorModal #createOrEditFornecedorModal (modalSave)="getFornecedores()"></createOrEditFornecedorModal>
    <viewFornecedorModal #viewFornecedorModal></viewFornecedorModal>

    <entityTypeHistoryModal #entityTypeHistoryModal></entityTypeHistoryModal>
    
</div>