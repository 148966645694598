<div id="configurationTransportScheduling">
    <div class="form-group">
        <h6 class="mb-5">{{l('Configuração para janela de transporte')}}</h6>
        <label for="configurationTransportScheduling-title" id="configurationTransportScheduling-label" 
               class="criteria-label">{{l("Cadastrar janela de transporte")}}</label>
        <button id="btn-scheduling" class="btn btn-primary btn-scheduling" type="submit" (click)="AddScheduleDeploy()">
            <i id="plus-icon" class="fa fa-plus"></i>{{l("Novo")}}
        </button>  
    </div>
    <div class="form-group">
        <div id="configurationTransport-table" class="configurationTransport-table">
            <p-table #dt [value]="configurationTransportScheduling" [rows]="5" [paginator]="true" 
                     dataKey="id" [rowsPerPageOptions]="[5,10,25,50,100,500]">
                <ng-template pTemplate="header">
                    <tr id="header-row" class="header-row">
                        <th scope="col">{{l("Dia da semana")}}</th>
                        <th scope="col">{{l("Horário inicial")}}</th>
                        <th scope="col">{{l("Horário final")}}</th>
                        <th scope="col">{{l("Ações")}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-configuration let-i>
                    <tr id="body-row" class="body-row">
                        <td id="configuration-day" class="configuration-day">{{getDayOfWeek(configuration.diaDaSemana)}}</td>
                        <td id="configuration-day" class="configuration-startHour">{{configuration.horaInicial | date: 'HH:mm'}}</td>
                        <td id="configuration-day" class="configuration-endHour">{{configuration.horaFinal | date: 'HH:mm'}}</td>
                        <td id="list-item-action" class="list-item-action">
                            <div class="list-item item-action">
                                <span id="edit-item" (click)="editScheduleDeploy(configuration.id)" class="edit-item" tooltip="{{l('Edit')}}">
                                    <i id="pen-icon" class="fas fa-pen"></i>
                                </span>
                                <span id="delete-item" (click)="deleteScheduleDeploy(i, configuration)" class="delete-item" tooltip="{{l('Delete')}}">
                                    <i id="trash-icon" class="fas fa-trash"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div id="table-sumary" class="flex align-items-center justify-content-between">
                        Total: {{configurationTransportScheduling ? configurationTransportScheduling.length : 0}}
                    </div>
                </ng-template>
            </p-table>
        </div>
    </div>
    <app-schedule-deploy-modal #scheduleDeployModal (closed)="reloadPage($event)"></app-schedule-deploy-modal>
</div>
