import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AvaliacoesDeQualidadesServiceProxy, DocumentacaoItemQualidadeServiceProxy, EnumStatusDaDoItemDaAvaliacaoDeQualidade, GetDocumentationItemQualidadeForViewDto } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-documentacao-item-qualidade-modal',
  templateUrl: './documentacao-item-qualidade-modal.component.html',
  styleUrls: ['./documentacao-item-qualidade-modal.component.css']
})
export class DocumentacaoItemQualidadeModalComponent extends AppComponentBase {

  @ViewChild('DocumentacaoItemQualidadeModal', { static: true }) modal: ModalDirective;
  documentacao = new GetDocumentationItemQualidadeForViewDto();
  constructor(injector: Injector,
    private _service: DocumentacaoItemQualidadeServiceProxy
  ) {
    super(injector);
  }

  show(id: string): void {
    this.documentacao = new GetDocumentationItemQualidadeForViewDto();

    this._service.getDocumentationItemQualidadeForView(id).subscribe(x => {
      this.documentacao = x;
    });
    this.modal.show();
  }
 

  close(): void {
    this.modal.hide();
  }

}
