<div [class]="getClassBox()" dropdown>

    <i class="fas fa-exclamation-triangle situacao-risco" tooltip="{{'Sua request possui risco de transporte, verifique a aba de gestão de risco na demanda' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('RiscoDeTransporte') && !card.alertarStatusConcluido">
    </i>
    <i class="fas fa-clock situacao-analisando" tooltip="{{'AguardandoQaManager' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('EmAnalise') && !card.alertarStatusConcluido">
    </i>
    
    <a *ngIf="card.situacao == enumSituacaoDemanda('AguardandoAvaliacaoQualidade') && !card.alertarStatusConcluido"
       (click)="goToBuildTracking(card.id)">
       <i class="fas fa-code-branch situacao-aguardando-qualidade clickable"
            tooltip="{{'Aguardando avaliação de qualidade' | localize}}">
        </i>
    </a>

    <a *ngIf="card.situacao == enumSituacaoDemanda('AguardandoLiberacaoDeAmbiente') && !card.alertarStatusConcluido"
        (click)="goToBuildTracking(card.id)">
        <i class="fas fa-sync-alt fa-spin situacao-analisando clickable"
            tooltip="{{'AguardandoLiberacaoAmbiente' | localize}}">
        </i>
    </a>

    <i class="fas fa-check situacao-aprovado clickable" tooltip="{{'Aprovado' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('Aprovado') && !card.alertarStatusConcluido"
        (click)="goToBuildTracking(card.id)">
    </i>
    <i class="fas fa-solid fa-flag situacao-estagnado" tooltip="{{'Demanda estagnada. Clique no ícone para concluí-la' | localize}}"
        *ngIf="card.alertarStatusConcluido" (click)="concluirDemanda(card.id)">
    </i>
    <a (click)="irParaAvaliacao(card.id)">
        <i class="fas fa-exclamation-triangle situacao-erro" tooltip="{{'SAP_EVALUATE_QUALITY_TITLE_ERROR' | localize}}"
            *ngIf="card.situacao == enumSituacaoDemanda('Reprovado')">
        </i>
    </a>

    <i class="far fa-file-code situacao-code-review" tooltip="{{'Em processo de Code Review. Aguardando retorno dos avaliadores.' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('CodeReview')">
    </i>

    <i class="fas fa-clock situacao-gmud-nativa" tooltip="{{'Em processo de Gestão de mudança. Aguardando retorno dos avaliadores. Verifique a aba de Gestão de risco da demanda, lá encontrará as requests que apresentam risco no transporte.' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('AguardandoAprovacaoGMUD')">
    </i>

    <i class="fas fa-ban situacao-gmud-reprovada" tooltip="{{'Gestão de mudança reprovada. Verifique com os avaliadores.' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('GMUDReprovada')">
    </i>

    <i class="fas fa-ambulance situacao-risco-aguardando" tooltip="{{'Aguardando gestão de risco.' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('AguardandoGestaoRisco')">
    </i>

    <i class="fas fa-truck aguardando-aprovacao-transporte" tooltip="{{'Aguardando aprovação do transporte em período de freezing' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('AguardandoTransporte')"></i>
    
    <i class="fas fa-truck transporte-reprovado" tooltip="{{'Transporte reprovado' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('TransporteReprovado')">
    </i>

    <i class="fas fa-times falha-transporte" tooltip="{{'Falha no transporte' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('FalhaNoTransporte')">
    </i>

    <i class="fas fa-truck andamento-transporte" tooltip="{{'Transporte em andamento' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('TransporteEmAndamento')">
    </i>

    <i class="fas fa-times falha-transporte" tooltip="{{'Falha na importação de STMS' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('FalhaStms')">
    </i>

    <i class="fas fa-stopwatch aguardando-risco" tooltip="{{'Aguardando gestão de risco' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('AguardandoRisco')">
    </i>

    <i class="fas fa-times falha-transporte clickable" tooltip="{{'Falha no processo, clique no icone para abrir a tela de acompanhamento de build' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('FalhaNoProcesso')"
        (click)="goToBuildTracking(card.id)">
    </i>

    <i class="fas fa-hourglass-half await-stms clickable" tooltip="{{'Aguardando STMS' | localize}}"
        *ngIf="card.situacao == enumSituacaoDemanda('AguardandoStms')"
        (click)="goToBuildTracking(card.id)">
    </i>

    <i class="flaticon-more handle" dropdownToggle></i>
    <div class="dropdown">
        <ul class="dropdown-menu drop-down-ambiente" *dropdownMenu>
            <li class="item-menu-dropdown" (click)="emitEventOpenModal('0', $event)">
                <a class="icon-mouse">{{ l("Details") }}</a>
            </li>

            <li class="item-menu-dropdown" (click)="emitEventOpenModal('1', $event)">
                <a class="icon-mouse">{{ l("Atividades") }}</a>
            </li>

            <li class="item-menu-dropdown" (click)="emitEventOpenModal('2', $event)">
                <a class="icon-mouse">{{ l("AdicionarMembro") }}</a>
            </li>

            <li class="item-menu-dropdown" (click)="emitEventOpenModal('3', $event)">
                <a class="icon-mouse">{{ l("AvaliacoesDeQualidades") }}</a>
            </li>

            <li class="item-menu-dropdown" (click)="emitEventOpenModal('9', $event)"
                *ngIf="(card.status != enumStatusDemanda('Producao') && card.status != enumStatusDemanda('Backlog')) 
                    && (card.situacao != enumSituacaoDemanda('EmAnalise') && card.situacao != enumSituacaoDemanda('AguardandoAprovacaoGMUD'))">
                <a class="icon-mouse">{{ l("GerarVersaoParaTeste") }}</a>
            </li>

            <li class="item-menu-dropdown" *ngIf="card.status != enumStatusDemanda('Homologacao') && card.status != enumStatusDemanda('Producao')"
                (click)="archiveUnachiveDemanda(card.id)">
                <a class="icon-mouse">{{ l("Archive") }}</a>
            </li>

            <li class="item-menu-dropdown" (click)="atualizarObjetosDemanda(card.id)">
                <a class="icon-mouse">{{ l("UpdateDemandObjects") }}</a>
            </li>

            <li class="item-menu-dropdown" *ngIf="card.status == enumStatusDemanda('Homologacao')" (click)="requestRiskManagement(card.id)">
                <a class="icon-mouse">{{ "Solicitar gestão de risco" }}</a>
            </li>
        </ul>
    </div>

    <span class="titulo-card" (click)="emitEventOpenModal('0', $event)">
        <span style="font-weight: 700;" >{{ card.demandaSapId || "" }}</span>
        <a href="#" [pTooltip]="getTooltip(card)">
            <span style="font-weight: 600;">
                {{ card.titulo }}
            </span>                  
        </a>
    </span>

    <span class="subtitulo-card">
        {{ card.referencia ? card.referencia.split("|").join(", ") : "" }}
    </span>

    <span class="titulo-moduloSap">
        {{ card.moduloSAP}}
    </span>

    <img class="header-profile-picture m--img-rounded m--marginless m--img-centered ng-star-inserted foto-usuario"
        [src]="profilePicture">

    <span class="nome-usuario">
        {{card.responsavel}}
    </span>

    <span class="peso">
        {{card.estimativa}}
    </span>

    <div class="progress barra-progresso">
        <div role="progressbar" [class]="card.percentual + ' progress-bar bg-success'" 
            aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>

    <entityTypeHistoryModal #entityTypeHistoryModal></entityTypeHistoryModal>
</div>