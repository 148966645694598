<div>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{"Integrations" | localize}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{"IntegrationsHeaderInfo" | localize}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button class="btn btn-primary" (click)="saveAll()"><i class="la la-floppy-o"></i>
                    {{"SaveAll" | localize}}</button>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <tabset *ngIf="settings">
                    <tab heading="{{'LdapSettings' | localize}}">
                        <app-ldap [ldap]="settings.ldap" (ldapChange)="settings.ldap = $event"></app-ldap>
                    </tab>
                    <tab heading="{{'MicrosoftSettings' | localize}}">
                        <app-microsoft [microsoft]="settings.microsoft" (microsoftChange)="settings.microsoft = $event"></app-microsoft>
                    </tab>
                    <tab [heading]="l('MicrosofAdtSettings')">
                        <app-microsoft-ad [microsoftAd]="settings.otherSettings" (microsoftAdChange)="settings.otherSettings = $event"></app-microsoft-ad>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>