import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AppSessionService } from '@shared/common/session/app-session.service';


@Component({
    selector: 'configuration-deploy',
    templateUrl: './configurationDeploy.component.html',
    styleUrls: ['./configurationDeploy.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class ConfigurationDeployComponent extends AppComponentBase implements OnInit {

    appSession: AppSessionService;

    constructor(
        injector: Injector,
    ) {
        super(injector);
    }
    ngOnInit() {
    }
}