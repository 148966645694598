import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ConfiguracaoGMUDNativaServiceProxy, DepartamentosGmudDto, ModulosSapGmudDto, ModulosSapGmudSelect2, UsuariosAprovadoresGmudDto, UsuariosAprovadoresGmudSelect2 } from "@shared/service-proxies/service-proxies";
import { ModalDirective } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";
import * as uuid from 'uuid';

@Component({
    selector: 'create-edit-approver-modal',
    templateUrl: './create-or-edit-approver-modal.component.html',
    styleUrls: ['./create-or-edit-approver-modal.component.less']
})

export class CreateOrEditApproverModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('createEditApproverModal', { static: true }) createEditApproverModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() saved: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();

    loading: boolean = false;
    saving: boolean = false;
    departament: string;
    departamentId: string;
    
    departamentList = [];

    selectRequired: boolean;
    selectOptional: boolean = true;

    select: 'Optional' | 'Required' = 'Optional';

    users: UsuariosAprovadoresGmudSelect2[] = [];
    usersSelected: UsuariosAprovadoresGmudSelect2[] = [];

    usersOptional: UsuariosAprovadoresGmudSelect2[] = [];
    usersOptionalSelected: UsuariosAprovadoresGmudSelect2[] = [];

    departamentosGmudDto: DepartamentosGmudDto = new DepartamentosGmudDto;
    usuariosAprovadoresGmudDto: UsuariosAprovadoresGmudDto[] = [];
    usuariosAprovadoresOpcionaisGmudDto: UsuariosAprovadoresGmudDto[] = [];

    userApprovesEverything: boolean = false;
    disableSaved: boolean = true;

    modules: ModulosSapGmudSelect2[] = [];
    modulesSelected: ModulosSapGmudSelect2[] = [];
    modulosSapGmudDto: ModulosSapGmudDto[] = [];

    ngOnInit(): void {
        this.selectApprovalUser();
        this.selectApprovalUserOptional();
        this.selectModules();
    }

    constructor(
        injector: Injector,
        private _configuracaoGMUDNativaServiceProxy: ConfiguracaoGMUDNativaServiceProxy
    ) {
        super(injector);
    }    
    
    async show(departamentoDto : DepartamentosGmudDto, button: boolean) {
        if (button){
            this.departament = '';
            this.usersSelected = [];
            this.departamentId = null;
            this.usersOptionalSelected = [];
            departamentoDto.id = '';
            this.usuariosAprovadoresGmudDto = [];
            this.usuariosAprovadoresOpcionaisGmudDto = [];
            this.selectOptional = true;
            this.modulesSelected = [];

            this.createEditApproverModal.show();

            return;
        }

        this.departament = departamentoDto.descricao;
        this.departamentId = departamentoDto.id;
        this.selectOptional = departamentoDto.opcional;
        
        if(departamentoDto.opcional != null || departamentoDto.opcional != undefined){
            this.select = departamentoDto.opcional ? 'Optional' : 'Required';
        }

        if (departamentoDto.usuariosAprovadores != null && departamentoDto.usuariosAprovadores.length > 0)
        {
            this._configuracaoGMUDNativaServiceProxy
            .getUsuariosAprovadoresSelecionados(departamentoDto.id).subscribe(x => {
                this.usersSelected = x;
            });                
        }

        if (departamentoDto.usuariosAprovadoresOpcionais != null && departamentoDto.usuariosAprovadoresOpcionais.length > 0)
        {
            this._configuracaoGMUDNativaServiceProxy
            .getUsuariosAprovadoresOpcionaisSelecionados(departamentoDto.id).subscribe(x => {
                this.usersOptionalSelected = x;
            });                
        }

        if (departamentoDto.modulosSapGmud != null && departamentoDto.modulosSapGmud.length > 0)
        {
            this._configuracaoGMUDNativaServiceProxy.getModulosSelecionados(departamentoDto.id)
                .subscribe(x => { this.modulesSelected = x; });                
        }

        this.userApprovesEverything = departamentoDto.usuarioAprovaTudo;

        this.createEditApproverModal.show();
    }
    
    close(): void {
        this.createEditApproverModal.hide();
        this.closed.emit();
        this.select = 'Optional';
        this.usersOptionalSelected = [];
        this.modulesSelected = [];
    }

    selectRadioRequired() {
        this.selectOptional = false;
        this.modules = [];
        this.modulesSelected = [];
        this.modulosSapGmudDto = [];
        this.selectModules();
    }

    selectRadioOptional() {
        this.selectOptional = true;
        this.selectModules();
    }

    selectApprovalUser(): void {
        this.usersSelected = [];
        this._configuracaoGMUDNativaServiceProxy
        .getAllUsers().subscribe(x => {
            this.users = x;
        });
    }

    clear(item: UsuariosAprovadoresGmudSelect2) {
       this.usersSelected = [].concat(this.usersSelected.filter(x => x.value !== item.value));
    }

    selectApprovalUserOptional(): void {
       this.usersOptionalSelected = [];
       this._configuracaoGMUDNativaServiceProxy
       .getAllUsers().subscribe(x => {
           this.usersOptional = x;
       });
    }

    clearOptional(item: UsuariosAprovadoresGmudSelect2) {
       this.usersOptionalSelected = [].concat(this.usersOptionalSelected.filter(x => x.value !== item.value));
    }
    
    addDepartament(){
        let namesRequired = '';
        let namesOptional = '';

        this.usersSelected.forEach(el => {
            namesRequired = namesRequired + el.label + ', ';

            //Preencher o usuariosAprovadoresGmudDto
            let userApprovalDto = new UsuariosAprovadoresGmudDto();
            userApprovalDto.id = uuid.v4();
            userApprovalDto.userId = parseInt(el.value);
            this.usuariosAprovadoresGmudDto.push(userApprovalDto);
        });

        this.usersOptionalSelected.forEach(el => {
            namesOptional = namesOptional + el.label + ', ';

            //Preencher o usuariosAprovadoresOpcionaisGmudDto
            let userApprovalOptionalDto = new UsuariosAprovadoresGmudDto();
            userApprovalOptionalDto.id = uuid.v4();
            userApprovalOptionalDto.userId = parseInt(el.value);
            this.usuariosAprovadoresOpcionaisGmudDto.push(userApprovalOptionalDto);
        });

        this.modulesSelected.forEach(el => {
            //Preencher o modulosSapGmudDto
            let modulosSapGmudDto = new ModulosSapGmudDto();
            modulosSapGmudDto.id = uuid.v4();
            modulosSapGmudDto.moduloId = el.value;
            this.modulosSapGmudDto.push(modulosSapGmudDto);
        });

        //Preencher o departamentosGmudDto
        let departamentoDto = new DepartamentosGmudDto();
        departamentoDto.descricao = this.departament;
        departamentoDto.opcional = this.selectOptional;

        departamentoDto.usuariosAprovadores = this.usuariosAprovadoresGmudDto;
        departamentoDto.usuariosAprovadoresOpcionais = this.usuariosAprovadoresOpcionaisGmudDto;
        departamentoDto.modulosSapGmud = this.modulosSapGmudDto;

        if (this.departamentId != null || this.departamentId != undefined)
            departamentoDto.id = this.departamentId;

        departamentoDto.usuarioAprovaTudo = this.userApprovesEverything;

        //Envia o Dto preenchido para o backend
        this._configuracaoGMUDNativaServiceProxy.createOrEditDepartamento(departamentoDto)
            .pipe(finalize(() => this.loading = false))
            .subscribe(x => {
                this.select = 'Optional';
                this.departament = '';
                this.usersSelected = [];
                this.usersOptionalSelected = [];
                departamentoDto.id = '';
                this.usuariosAprovadoresGmudDto = [];
                this.usuariosAprovadoresOpcionaisGmudDto = [];
                this.modulosSapGmudDto = [];
                this.close();
                this.notify.success(this.l('SavedSuccessfully'));
            })
    }

    selectModules(): void {
        this.modulesSelected = [];
        this._configuracaoGMUDNativaServiceProxy.getAllModulosSap()
            .subscribe(x => { this.modules = x; });
    }

    clearModules(item: ModulosSapGmudSelect2) {
        this.modulesSelected = [].concat(this.modulesSelected.filter(x => x.value !== item.value));
    }
}