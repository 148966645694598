import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SapUsersServiceProxy, Select2ItemDto, UsuarioEnvolvidoDemandaSelect2, CreateOrUpdateSapUserDto, AmbientesServiceProxy, AmbienteMandanteToSelectDto } from '@shared/service-proxies/service-proxies';
import { AbpSessionService } from 'abp-ng2-module';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-create-sap-user-modal',
  templateUrl: './create-sap-user-modal.component.html',
  styleUrls: ['./create-sap-user-modal.component.css']
})
export class CreateSapUserModalComponent extends AppComponentBase implements OnInit {
  @ViewChild('createModal', { static: true }) modal: ModalDirective;
  surnameIsRequired = true;

  user: CreateOrUpdateSapUserDto = new CreateOrUpdateSapUserDto();
  saving = false;
  users: Select2ItemDto[];
  usersSelected: Select2ItemDto[];
  usuariosJaExistentes: any[];
  devopsUsers: any[];
  bufferSize = 20;
  sapUserModel: Select2ItemDto;
  mandanteModelo: any = {
    ambiente: "",
    mandante: ""
  };
  mandantes: any[] = [];
  mandantesAmbiente: AmbienteMandanteToSelectDto[] = [];

  constructor(
    private injector: Injector,
    private _SapUsersServiceProxy: SapUsersServiceProxy,
    private _sessionService: AbpSessionService,
    private _ambienteService: AmbientesServiceProxy

  ) {
    super(injector);
  }

  ngOnInit() {
    this.getUsers();
    this.getDevopsUser();
    this.surnameValidator();
    this.getMandantesAmbientes();
  }

  show() {
    this.modal.show();
  }

  getMandantesAmbientes() {
    if (!this.appSession.tenantId) {
      return;
    }
    this._ambienteService.getAmbienteMandanteToSelect().subscribe(result => {
      this.mandantesAmbiente = result.filter(item => item.mandantes.length > 0);
    })
  }

  save() {
    this.user.mandanteSapIds = this.mandantes.map(item => item.mandante);
    this._SapUsersServiceProxy
      .createOrUpdateSapUser(this.user)
      .subscribe((result) => {
        this.notify.success(this.l(result));
        this.close();
      }, (e) => {
        const { error } = JSON.parse(e.response)
        this.notify.error(`${error.message}: ${error.details}`)
      });
  }

  getUsers() {
    this._SapUsersServiceProxy
      .getAllSapUsers()
      .subscribe(result => {
        this.users = result;
      });
  }

  getDevopsUser() {
    this._SapUsersServiceProxy
      .getAllUsers()
      .subscribe(result => {
        this.devopsUsers = result;
      });
  }

  close(): void {
    this.user = new CreateOrUpdateSapUserDto();
    this.sapUserModel = null;
    this.usersSelected = [];
    this.mandanteModelo = {};
    this.mandantes = [];
    this.modal.hide();
  }

  clear(selectedItem: Select2ItemDto) {
    this.usersSelected = [].concat(this.usersSelected.filter(item => item.value !== selectedItem.value));
    this.user.usersIds = this.usersSelected.map(item => parseInt(item.value));
    this.surnameValidator();
  }

  changeUsersSelected(ev: any[]) {
    this.user.usersIds = ev.map(item => parseInt(item.value));
    this.surnameValidator();
  }

  changeUserModel(ev) {
    this.user.userModel = ev ? ev.label : ev;
  }

  surnameValidator() {
    if (!this.user || !this.user.usersIds) {
      this.surnameIsRequired = true
      return;
    }

    if (this.user.usersIds.length == 1) {
      this.surnameIsRequired = false;
      return;
    }

    this.surnameIsRequired = true
  }

  adicionarMandante() {
    const found = this.mandantes.find(item => item.mandante == this.mandanteModelo.mandante && item.ambiente == this.mandanteModelo.ambiente)
    if (found) {
      this.notify.error(this.l('MandanteRepetido'));
      return;
    }
    this.mandantes.push({ ...this.mandanteModelo })
    this.mandanteModelo = {};
  }

  deletarMandante(mandante, ambiente) {
    const found = this.mandantes.find(item => item.mandante == mandante && item.ambiente == ambiente);
    const index = this.mandantes.indexOf(found)
    this.mandantes.splice(index, 1)
  }
  
  getMandantesLista(ambienteId): Select2ItemDto[] {
    const ambiente = this.mandantesAmbiente.find(item => item.ambienteId == ambienteId) || { mandantes: [] };
    return ambiente.mandantes
  }

  getLabel(id: string, list: any[], prop: string, label: string) {
    const found = list.find(item => item[prop] == id) || { [label]: "" };
    return found[label]
  }

}
