<div class="kt-widget__item" (click)="selectChatFriend.emit($event)">
        <span class="kt-userpic kt-userpic--circle">
            <friend-profile-picture [profilePictureId]="friend.friendProfilePictureId" [userId]="friend.friendUserId" [tenantId]="friend.friendTenantId"></friend-profile-picture>
        </span>
        <div class="kt-widget__info">
            <div class="kt-widget__section">
                <a href="javascript:;" class="kt-widget__username">{{friend.friendUserName}}</a>
                <span *ngIf="friend.isOnline" class="kt-badge kt-badge--online kt-badge--dot"></span>
                <span *ngIf="!friend.isOnline" class="kt-badge kt-badge--offline kt-badge--dot"></span>
            </div>
            <span class="kt-widget__desc">
                <span *ngIf='friend.friendTenancyName'>{{friend.friendTenancyName}}</span>
                <span *ngIf='!friend.friendTenancyName'>QAMetrik</span>
            </span>
        </div>
        <div class="kt-widget__action">
            <span *ngIf="friend.unreadMessageCount" class="kt-badge kt-badge--warning">
                {{friend.unreadMessageCount}}
            </span>
            <span *ngIf="!friend.unreadMessageCount" class="kt-badge kt-badge--warning">
                {{friend.unreadMessageCount}}
            </span>
        </div>
    </div>