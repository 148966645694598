<div class="kt-content last-accessed-clients">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body last-accessed-body">
            <h6 class="title-section">{{l("LastAccessedClients")}}</h6>
            <div id="last-accessed">
                <div id="card-acesso-perfil" *ngFor="let card of cardList" (click)="switchToLinkedUser(card)">
                    <span class="fornecedor-card-perfil">
                        {{card.nomeTenant || l("SemFornecedor")}}
                    </span>
                    <img class="logo-tennant-perfil p-2" [style]="card.nomeTenant.toLowerCase().includes('qametrik') ? 'background-color: #26425B;' : ''"
                        [src]="card.nomeTenant.toLowerCase().includes('qametrik') ? defaultLogo : remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=dark&tenantId=' + card.tenantId">
                </div>
            </div>
        </div>
    </div>
</div>
