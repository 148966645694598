import { Injectable } from '@angular/core';

@Injectable()
export class ValidatorsLegaisService {

  constructor() { }

  validarCNPJ(ObjCnpj: any): boolean {
    if (!ObjCnpj) return false;

    var cnpj = ObjCnpj;
    var valida = new Array(6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2);
    var dig1 = 0;
    var dig2 = 0;

    var exp = /\.|\-|\//g
    cnpj = cnpj.toString().replace(exp, "");
    
    if (cnpj == "00000000000000") return false
    
    var digito = cnpj.charAt(12) + cnpj.charAt(13);

    for (let i = 0; i < valida.length; i++) {
      dig1 += (i > 0 ? (cnpj.charAt(i - 1) * valida[i]) : 0);
      dig2 += cnpj.charAt(i) * valida[i];
    }
    dig1 = (((dig1 % 11) < 2) ? 0 : (11 - (dig1 % 11)));
    dig2 = (((dig2 % 11) < 2) ? 0 : (11 - (dig2 % 11)));

    if (((dig1 * 10) + dig2) != digito) {
      return false;
    }

    return true;
  }


  ValidaCpf(controle: any): boolean {
    const cpf = controle;

    let soma: number = 0;
    let resto: number;
    let valido: boolean;

    const regex = new RegExp('[0-9]{11}');

    if (
      cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999' ||
      !regex.test(cpf)
    )
      return false;
    else {
      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(cpf.substring(9, 10))) return false;

      soma = 0;
      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(cpf.substring(10, 11))) return false;
      return true;
    }

    return valido;
  }


}
