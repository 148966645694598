<div id="object-inventory-page" [@routerTransition] class="overflow-auto">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("EnvironmentEvaluation")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{l("ObjectsInventory")}}
            </span>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">

                <p-treeTable #tt [value]="files" [columns]="cols" [paginator]="true" [rows]="10" [scrollable]="true" [lazy]="true" 
                (onLazyLoad)="loadNodes($event)" [totalRecords]="totalRecords" [loading]="loading" (onNodeExpand)="onNodeExpand($event)" 
                selectionMode="single" dataKey="name" (onNodeSelect)="goToObjectPage($event)">
                    <ng-template pTemplate="caption">
                        <div id="treeTable-header">
                            <div class="filter-content">
                                <div class="search-bar">
                                    <label for="searh-input" class="search-label">{{l("ObjectSearch")}}</label>
                                    <input class="search-input" type="text" size="50" placeholder="Pesquisar nome do objeto" [(ngModel)]="searchInput" (input)="searchObject($event)">
                                </div>
                            </div>
                            <div class="last-update">
                                <span>{{l("LastUpdate")}}</span><span class="update-time">{{creationTime}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                        <tr [ttSelectableRow]="rowNode">
                            <td *ngFor="let col of columns; let i = index">
                                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                                    {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <span>Total: {{totalRecords}}</span>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>        
                            <td [attr.colspan]="cols.length">{{l("NoDataFound")}}</td>
                        </tr>
                    </ng-template>
                </p-treeTable>

            </div>
        </div>
    </div>
</div>        