<div bsModal #codigoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="codigoModal"
  aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form #form="ngForm" novalidate autocomplete="off">
        <div class="modal-header">
          <h4 class="modal-title">
            <span>{{l("Code")}}</span>
          </h4>
          <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{"QualityEvaluationSeeCodeMessage" | localize}}</p>
          <div class="row" style="background: #2b2b2b;">
            <div class="col-md-1 col-1  contador-linhas">
              <span *ngFor="let linha of linhas"><i *ngIf="linha && linha.erro" class="fas fa-exclamation-circle" style="color: #fd397a;"></i> {{linha.linha}}<br /></span>
            </div>
            <div class="col-md-11 col-10">
              <pre style="width: 100%;"><code class="abap" id="codigo" ></code></pre>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
        </div>
      </form>
    </div>
  </div>  
</div>