<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #cargoForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span *ngIf="cargo.id">{{l("EditCargo")}}</span>
                        <span *ngIf="!cargo.id">{{l("CreateNewCargo")}}</span>
                    </h4>
                    <button type="button" class="close" (click)="close()" aria-label="Close" [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="Cargo_Nome">{{l("Name")}} *</label>
                        <input type="text" 
                            id="Cargo_Nome" 
                            class="form-control" 
                            [(ngModel)]="cargo.nome" 
                            name="Nome"   
                            minlength="2"  
                            maxlength="200"
                            (keyup)="ehDuplicado($event)"  
                            #Cargo_Nome="ngModel" 
                            [ngClass]="{'is-invalid': Cargo_Nome.invalid, 'is-valid': !Cargo_Nome.invalid}" 
                            required />
                        <validation-messages [formCtrl]="Cargo_Nome"></validation-messages>
                        <label class="text-danger" *ngIf="duplicado"> {{l("CargoDuplicado")}}</label>

                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary blue" [disabled]="!cargoForm.form.valid || duplicado" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i> <span>{{l("Save")}}</span></button>
                </div>
            </form>
        </div>
    </div>

</div>