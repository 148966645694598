<div class="stepwizard-step">
    <button type="button" class="btn btn-circle cor-botao-qa">
        <i style="margin-left: 5px; color: white"class={{icone}}></i>
    </button>
    <p class="text-cor-qa">
        {{ l(nomeBranch) }}
    </p>
    <div *ngFor="let ambiente of listaAmbientes">
        <card-item [nomeAmbiente]="ambiente.nome" [server]="ambiente.server" [idAmbiente]="ambiente.id" mandante="" (openModal)="emitEventRefresh($event)" (openModalHistory)="emitEventOpenModalHistory($event)"></card-item>
    </div>
</div>
