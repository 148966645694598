<div [@routerTransition] id="system-notifications" class="overflow-auto">
    <div id="subheader" class="kt-subheader kt-grid__item">
        <div id="subheader-main" class="kt-subheader__main">
            <h3 id="subheader-title" class="kt-subheader__title">
                <span id="subheader-txt" class="subheader-txt">{{l("Notificações no Portal")}}</span>
            </h3>
            <span id="subheader-separator" class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span id="subheader-desc" class="kt-subheader__desc">
                {{l("Configuração")}}
            </span>
        </div>        
    </div>
    <div id="system-notifications-content" class="kt-content system-notifications-content">
        <div id="portlet" class="kt-portlet kt-portlet--mobile">
            <div id="portlet-body" class="kt-portlet__body">
                <div id="create-notification" class="create-notification">
                    <button id="create-btn" class="btn btn-primary" type="submit" (click)="createNotification()">
                        <i id="plus-icon" class="fa fa-plus"></i>{{l("Criar notificação")}}
                    </button>
                </div>
                <div id="notification-table" class="notification-table">
                    <p-table #nt id="p-table-notification" class="p-table-notification" [value]="notificacaoList" [rows]="5" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100,500]" [loading]="loading">
                        <ng-template pTemplate="header">
                            <tr id="table-header-row" class="table-header-row">
                                <th id="th-title" class="th-title">{{l("Título")}}</th>
                                <th id="th-requestBy" class="th-requestBy">{{l("Criado por")}}</th>
                                <th id="th-date" class="th-date">{{l("Data/hora")}}</th>
                                <th id="th-actions" class="th-actions">{{l("Ações")}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-notificacao let-i>
                            <tr id="table-body-row" class="table-body-row">
                                <td id="td-title" class="td-title">{{notificacao.titulo}}</td>
                                <td id="td-requetBy" class="td-requestBy">{{notificacao.usuarioNome}}</td>
                                <td id="td-date" class="td-date">{{getLocaleDateHourNotificacao(notificacao.dataCriacao)}}</td>
                                <td id="td-actions" class="td-actions">
                                    <div id="actions-content" class="list-item item-action">
                                        <span id="delete-item" class="delete-item" tooltip="{{l('Delete')}}" (click)="deleteNotificacao(i, notificacao);"><i id="trash-icon" class="fas fa-trash"></i></span>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div id="table-total-itens" class="flex align-items-center justify-content-between">
                                Total: {{notificacaoList ? notificacaoList.length : 0 }}
                            </div>
                        </ng-template>
                    </p-table>
                </div>      
            </div>
        </div>
    </div>
    <create-notificacao-modal #createNotificacaoModal (closed)="reloadPage($event)"></create-notificacao-modal>
</div>