import { EnumStatusDemanda, AmbienteMandanteDto, AlteracaoDeStatusDaDemandaDto, MandanteSapDto } from './../../../../../shared/service-proxies/service-proxies';
import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AmbientesServiceProxy, DemandasServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-request-modal',
  templateUrl: './create-request-modal.component.html',
  styleUrls: ['./create-request-modal.component.css']
})
export class CreateRequestModalComponent extends AppComponentBase {

  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
  @Output() detalhesAdicionado: EventEmitter<any> = new EventEmitter<any>();
  environmentList: any[] = [];
  eventDrop: any;
  statusDemanda: EnumStatusDemanda;
  usuarioId: number;
  demandaId: string;
  idEstagio: number;
  loading: boolean;

  constructor(
    injector: Injector,
    private _ambientesServiceProxy: AmbientesServiceProxy,
    private _demandaService: DemandasServiceProxy,
  ) {
    super(injector);

  }

  show(eventDrop: any, statusDemanda: EnumStatusDemanda, usuarioId: number, demandaId: string): void {
    this.loading = true;
    this.eventDrop = eventDrop;
    this.statusDemanda = statusDemanda;
    this.usuarioId = usuarioId;
    this.demandaId = demandaId;

    this.idEstagio = eventDrop.container.id >= 2 ? 2 : parseInt(eventDrop.container.id);
    this._ambientesServiceProxy.getAmbienteMandante(this.idEstagio)
      .pipe(finalize(() => this.loading = false))
      .subscribe(result => {
        this.environmentList = result;
      })
    this.modal.show();
  }

  showToCreateRequest(demandaId: string, damandaStatus: EnumStatusDemanda) {
    this.loading = true;
    const estagios = {
      [EnumStatusDemanda.Backlog]: 1,
      [EnumStatusDemanda.Desenvolvimento]: 2,
      [EnumStatusDemanda.Homologacao]: 3,
      [EnumStatusDemanda.Producao]: 4
    }
    this.demandaId = demandaId;
    this.idEstagio = estagios[damandaStatus];

    this._demandaService.getRequestTypeByDemandId(demandaId)
      .pipe(finalize(() => this.loading = false))
      .subscribe((result: AmbienteMandanteDto[]) => {
        this.environmentList = result.map((item: AmbienteMandanteDto) => {
          return {
            ...item,
            mandantes: item.mandantes.map((item2: any) => {
              return {
                ...item2,
                hasWorkbench: item2.workbench,
                hasCustomizing: item2.customizing,
                workbench: false,
                customizing: false,
              }
            }),
          }
        });
      })
    this.modal.show();
  }

  getFormatedEnviromentList() {
    return this.environmentList.map((item: AmbienteMandanteDto) => {
      const ambienteMandante = new AmbienteMandanteDto(item);
      ambienteMandante.mandantes = item.mandantes.map((item2: MandanteSapDto) => {
        const mandante = new MandanteSapDto(item2);
        mandante.customizing = item2.customizing;
        mandante.workbench = item2.workbench;
        return mandante
      })
      return ambienteMandante
    });
  }

  select(): void {
    const dto = {
      environmentList: this.getFormatedEnviromentList(),
      idEstagio: this.idEstagio,
      demandaId: this.demandaId,
    }
    
    let validado: boolean = false;
    dto.environmentList.forEach(element => {
        if (element.mandantes.some(m => m.customizing || m.workbench)){
          validado = true;
        }
    });
    
    if (!validado){
      this.notify.warn("Deve-se selecionar ao menos um mandante!");
      return;
    }
    
    this.detalhesAdicionado.emit(dto);
    this.modal.hide();
  }

  close(): void {
    this.modal.hide();
  }
}
