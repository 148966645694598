import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AvaliacoesDeQualidadesServiceProxy, DemandasServiceProxy, EnumStatusDaDoItemDaAvaliacaoDeQualidade, GetDemandaForEditOutput, EnumStatusDaAvaliacaoDeQualidade } from '@shared/service-proxies/service-proxies';
import { HistoricoAvaliacaoCodigoComponent } from '../historico-avaliacao-codigo/historico-avaliacao-codigo.component';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CodigoSapComponent } from '../codigo-sap/codigo-sap.component';
import { JustificativaDecisorComponent } from '../justificativa-decisor/justificativa-decisor.component';
import { OrientacaoDecisorComponent } from '../orientacao-decisor/orientacao-decisor.component';
import { delay, has, result } from 'lodash';
import { switchMap } from 'rxjs/operators';
import { empty, of } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';
@Component({
  selector: 'app-itens-avaliados',
  templateUrl: './itens-avaliados.component.html',
  styleUrls: ['./itens-avaliados.component.css']
})
export class ItensAvaliadosComponent extends AppComponentBase {

  @ViewChild('historicoAvaliacaoCodigo', { static: true }) historicoAvaliacaoCodigo: HistoricoAvaliacaoCodigoComponent;
  @ViewChild('codigoSap', { static: true }) codigoSap: CodigoSapComponent;
  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('paginator', { static: true }) paginator: Paginator;
  @ViewChild('justificativaDecisor', { static: true }) justificativaDecisorcmp: JustificativaDecisorComponent;
  @ViewChild('orientacaoDecisor', { static: true }) orientacaoDecisorcmp: OrientacaoDecisorComponent;

  avaliacaoId: string;
  itensARevisar: boolean;
  demandaId: string;
  solicitarDecisor: boolean;
  justificativaDecisorText: string;
  lblUserSolicitarDecisor: string | undefined;
  dataSolicitarDecisor: moment.Moment | undefined;
  orientacaoDecisorText: string | undefined;
  lblUserOrientacaoDecisor: string | undefined;
  dataOrientacaoDecisor: moment.Moment | undefined;
  isDecisor = false;
  usuarioDemanda: number = 0;

  orientacaoAberto = false;
  justificativaAberto = false;
  demandaSapId: number =  0;

  loading = false;
  semJust = true;
  emAnalise: boolean = true;
  usuarioAnalisador: number;
  skipQuality: boolean = false;

  statusAvaliacao: EnumStatusDaAvaliacaoDeQualidade;

  get todosItemAprovados() {
    return !this.primengTableHelper.records.find(item => item.status != EnumStatusDaDoItemDaAvaliacaoDeQualidade.Aprovado)
  }

  get ehUsuarioDemanda() {
    return this.usuarioDemanda == this.appSession.user.id;
  }

  get emAvaliacao(){
    return this.usuarioAnalisador != 0 && this.appSession.userId != this.usuarioAnalisador;
  }

  get canEvaluate() {
    if (this.primengTableHelper.records[0].statusAvaliacao == EnumStatusDaAvaliacaoDeQualidade.EmCorrecao){
      return false;
    }

    const hasDecisor = this.isDecisor;
    const canEvaluate = !this.emAnalise;
    return hasDecisor || !canEvaluate;
  }

  get canJustify() {
    if (this.primengTableHelper.records[0].statusAvaliacao == EnumStatusDaAvaliacaoDeQualidade.EmCorrecao){
      return true;
    }

    const hasDecisor = this.isDecisor;
    return !hasDecisor;
  }

  constructor(
    injector: Injector,
    private _avaliacoesDeQualidadesServiceProxy: AvaliacoesDeQualidadesServiceProxy,
    private router: Router,
    private route: ActivatedRoute,
    private _demandaService: DemandasServiceProxy
  ) {
    super(injector);
    this.isDecisor = this.permission.isGranted('Pages.AvaliacoesDecisor') && this.appSession.tenantId > 0;
  }

  validateStatus(value1, value2, sortOrder) {
    if (value1 == EnumStatusDaDoItemDaAvaliacaoDeQualidade.Justificado) {
      return -sortOrder
    }
    if (value2 == EnumStatusDaDoItemDaAvaliacaoDeQualidade.Justificado) {
      return sortOrder
    }
    return (value1 < value2) ? -sortOrder : (value1 > value2) ? sortOrder : 0;
  }

  getAvaliacoesDeQualidades(event?: LazyLoadEvent) {
    this.avaliacaoId = this.route.snapshot.paramMap.get('id');
    this.primengTableHelper.showLoadingIndicator();
    this._avaliacoesDeQualidadesServiceProxy.getItensDaAvaliacao(this.avaliacaoId
    ).pipe(switchMap(result => {
      this.demandaSapId = result.demandaSapId;
      let records = result.itens;
      this.emAnalise = result.emAnalise;
      this.usuarioAnalisador = result.usuarioAnalisador;
      
      for (let i = 0; i < records.length; i++) {
        if (!this.primengTableHelper.records || !this.primengTableHelper.records[i]) {
          break;
        }
        records[i].check = this.primengTableHelper.records[i].check;
        this.statusAvaliacao = records[i].statusAvaliacao;
      }

      const sortField = event && event.sortField ? event.sortField : 'status';
      const sortOrder = event && event.sortOrder ? event.sortOrder : 1;
      if (this.hasRecords) {
        records.forEach(item => {
          const found = this.primengTableHelper.records.find(item2 => item2.id == item.id);
          item.check = found ? found.check : false
        })
      }
      this.primengTableHelper.records = records.sort((data1, data2) => {
        let value1 = data1[sortField];
        let value2 = data2[sortField];
        if (sortField != 'status') {
          return (value1 < value2) ? -sortOrder : (value1 > value2) ? sortOrder : 0;
        }
        return this.validateStatus(value1, value2, sortOrder)
      });

      if (result.solicitarDecisor) {
        this.solicitarDecisor = result.solicitarDecisor;
      }
      this.justificativaDecisorText = result.justificativaDecisor;
      this.demandaId = result.demandaId;
      this.itensARevisar = result.contemItensASerRevisado;
      this.dataSolicitarDecisor = result.dataSolicitarDecisor;
      this.lblUserSolicitarDecisor = result.lblUserSolicitarDecisor;
      this.orientacaoDecisorText = result.orientacaoDecisor;

      this.lblUserOrientacaoDecisor = result.lblUserOrientacaoDecisor;
      this.dataOrientacaoDecisor = result.dataOrientacaoDecisor;
      this.skipQuality = result.skipQuality;

      this.primengTableHelper.hideLoadingIndicator();
      this.temItemSemjustifiva();
      if (!this.appSession.tenantId) {
        return of({ demanda: { usuarioResponsavelId: null } })
      }
      return this._demandaService.getDemandaForEdit(result.demandaId)
    })).subscribe((result: GetDemandaForEditOutput) => {
      this.usuarioDemanda = result.demanda.usuarioResponsavelId;
    });


  }

  decisorClick(event) {
    this.solicitarDecisor = !this.solicitarDecisor;
  }

  get guidsSelecionados() {
    if (!this.primengTableHelper.records) { return []; }
    let lista = this.primengTableHelper.records.filter(x => x.check);
    let guids = [];
    lista.forEach(element => {
      guids.push(element.id);
    });
    return guids;
  }

  get guidsJust() {
    if (!this.primengTableHelper.records) { return []; }
    let lista = this.primengTableHelper.records.filter(x => x.check);
    let retorno = '';
    return retorno;
  }

  get hasRecords() {
    return !!this.primengTableHelper.records && this.primengTableHelper.records.length > 0;

  }

  get indeterminate() {
    return this.hasRecords && this.primengTableHelper.records.some(item => item.check) && this.primengTableHelper.records.some(item => !item.check)
  }

  get selectedAll() {
    return this.hasRecords && this.primengTableHelper.records.filter(item => item.check).length == this.primengTableHelper.records.length;
  }

  enviarParaQaManager() {
      this.loading = true;
      this._avaliacoesDeQualidadesServiceProxy.enviarParaAnalise(this.avaliacaoId, this.demandaId)
        .subscribe(x => {
          this.router.navigate(['/app/main/avaliacoes-qualidade']);
        }, x => {
          this.loading = false;
        });
  }

  enviarParaDev() {
      this.loading = true;
      this._avaliacoesDeQualidadesServiceProxy.enviarParaDesenvolvedor(this.avaliacaoId, this.demandaId).subscribe(x => {
        this.router.navigate(['/app/main/avaliacoes-qualidade']);
      });
  }

  selecionarTodos() {
    const hasSelected = !!this.primengTableHelper.records.find(item => !item.check)
    this.primengTableHelper.records.forEach(element => {
      element.check = hasSelected;
    });
  }

  removeHtml(texto: string): string {
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = texto;
    // Retrieve the text property of the element (cross-browser support)
    let ret = temporalDivElement.textContent.trim();
    let i = 50;
    while (i < ret.length) {
      if (ret.substring(i, i + 1) == ' ')
        break;
      i++;
    }
    return ret.substring(0, i) + "...";

  }

  temItemSemjustifiva(): boolean {
    if (!this.primengTableHelper.records
      || this.primengTableHelper.records.length == 0
      || this.primengTableHelper.records.some(item => item.status != EnumStatusDaDoItemDaAvaliacaoDeQualidade.Justificado)) {
      return true;
    }
    return false;
  }

  temItemReprovado(): boolean {
    if (this.primengTableHelper.records) {
      if (this.primengTableHelper.records.some(item => item.status == EnumStatusDaDoItemDaAvaliacaoDeQualidade.Reprovado))
        return true;

    }
    return false;
  }

  temItemSemQa(): boolean {
    if (this.primengTableHelper.records) {
      if (this.primengTableHelper.records.some(item => item.status == EnumStatusDaDoItemDaAvaliacaoDeQualidade.Justificado))
        return true;
    }
    return false;
  }

  status2String(st: string): string {
    if (EnumStatusDaDoItemDaAvaliacaoDeQualidade[st] == EnumStatusDaDoItemDaAvaliacaoDeQualidade.Aprovado) return "Aprovado";
    if (EnumStatusDaDoItemDaAvaliacaoDeQualidade[st] == EnumStatusDaDoItemDaAvaliacaoDeQualidade.Reprovado) return "Reprovado";
    if (EnumStatusDaDoItemDaAvaliacaoDeQualidade[st] == EnumStatusDaDoItemDaAvaliacaoDeQualidade.EmAberto) return "Em aberto";
    if (EnumStatusDaDoItemDaAvaliacaoDeQualidade[st] == EnumStatusDaDoItemDaAvaliacaoDeQualidade.Justificado) return "Justificado";
    if (EnumStatusDaDoItemDaAvaliacaoDeQualidade[st] == EnumStatusDaDoItemDaAvaliacaoDeQualidade.FalsoPositivo) return "Falso positivo";
    if (EnumStatusDaDoItemDaAvaliacaoDeQualidade[st] == EnumStatusDaDoItemDaAvaliacaoDeQualidade.Corrigido) return "Corrigido";
    return "";
  }

  setOpenedOrientacao() {
    this.orientacaoAberto = true;
  }

  setClosedOrientacao() {
    this.orientacaoAberto = false;
  }

  setOpenedJustificativa() {
    this.justificativaAberto = true;
  }
  setClosedJustificativa() {
    this.justificativaAberto = false;
  }

  enviarParaDesenvolvedor(): boolean {
    if (this.primengTableHelper.records) {
      if (this.primengTableHelper.records.some(item => item.status == EnumStatusDaDoItemDaAvaliacaoDeQualidade.Reprovado) &&
          this.primengTableHelper.records[0].statusAvaliacao != EnumStatusDaAvaliacaoDeQualidade.EmCorrecao)
        return true;
    }
    return false;
  }

  finalizaAvaliacao(): boolean {
    if (this.primengTableHelper.records) {
      if (this.primengTableHelper.records.every(item => item.status == EnumStatusDaDoItemDaAvaliacaoDeQualidade.Aprovado))
        return true;
    }
    return false;
  }

  enviarParaGestor(): boolean {
    if (this.primengTableHelper.records) {
      if (this.primengTableHelper.records[0].statusAvaliacao == EnumStatusDaAvaliacaoDeQualidade.EmCorrecao)
        return true;
    }
    return false;
  }

  userAware() {
    this.enviarParaDev();
  }
}
