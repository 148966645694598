import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DocumentacaoItemQualidadeServiceProxy, Select2ItemDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LazyLoadEvent } from 'primeng/api';
import { CreateOrEditdocumentationModalComponent } from './create-or-edit-documentation-modal/create-or-editdocumentation-modal.component';

@Component({
  selector: 'app-change-manager',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent extends AppComponentBase implements OnInit {
  @ViewChild('createOrEditDocumentationModal', {static: true}) createOrEditModal: CreateOrEditdocumentationModalComponent;
  
  maxResultCount: number = 10;
  documentedItems: Select2ItemDto[] = [];
  totalCount: number = 0;
  loading: boolean = false;
  filter: string = "";
  page: number = 1;

  constructor(
    injector: Injector,
    private _service: DocumentacaoItemQualidadeServiceProxy
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  getDocumentation(event?: LazyLoadEvent) {
    this.documentedItems = [];
    this.page = event?.first >= 0? event?.first: this.page;
    this._service.getAllDocumentation(this.maxResultCount, this.page, this.filter).subscribe(res => {
      this.documentedItems = res.items;
      this.totalCount = res.totalCount;
    })
  }

  openCreateOrEditModal(id: string, event: any) {
    event?.preventDefault();
    this.createOrEditModal.show(id);
  }

  deleteDocumentation(id: string, event: any) {
    event?.preventDefault();
    this._service.delete(id).subscribe(() => this.getDocumentation())
  }

  reload() {
    this.getDocumentation();
  }

}
