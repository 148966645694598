// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-tree {
    width: 100%;
}

body .ui-widget-content {
    border: none !important;
}

span.ui-treenode-label {
    font-family: Poppins !important;
    line-height: 24px !important;
    font-size: 14px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

span.ui-treenode-icon {
    line-height: 24px !important;
    font-size: 1.2rem !important;
}

.ui-tree .ui-chkbox .ui-chkbox-icon {
    margin-left: 0px;
}

.ui-tree .ui-treenode-children {
    padding-left: 20px !important;
}

.hidden-tree-node {
    display: none;
}

p-tree[selectionmode="checkbox"] .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
    background-color: transparent !important;
    color: #333 !important;
    font-weight: 300 !important;
}

span.ui-treenode-label {
    line-height: 18px !important;
    font-size: 13px !important;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-chkbox-icon {
    margin: -1px 0 0 -1px;
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
    font-size: 16px;
}

body .ui-chkbox .ui-chkbox-box {
    width: 16px;
    height: 16px;
}

body .ui-chkbox {
    width: 16px;
    height: 16px;
}

body .ui-tree .ui-tree-container .ui-treenode {
    padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/assets/primeng/tree/css/primeng.tree.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,+BAA+B;IAC/B,4BAA4B;IAC5B,0BAA0B;IAC1B,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;IAC5B,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,wCAAwC;IACxC,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,4BAA4B;IAC5B,0BAA0B;AAC9B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".ui-tree {\n    width: 100%;\n}\n\nbody .ui-widget-content {\n    border: none !important;\n}\n\nspan.ui-treenode-label {\n    font-family: Poppins !important;\n    line-height: 24px !important;\n    font-size: 14px !important;\n    padding-left: 5px !important;\n    padding-right: 5px !important;\n}\n\nspan.ui-treenode-icon {\n    line-height: 24px !important;\n    font-size: 1.2rem !important;\n}\n\n.ui-tree .ui-chkbox .ui-chkbox-icon {\n    margin-left: 0px;\n}\n\n.ui-tree .ui-treenode-children {\n    padding-left: 20px !important;\n}\n\n.hidden-tree-node {\n    display: none;\n}\n\np-tree[selectionmode=\"checkbox\"] .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {\n    background-color: transparent !important;\n    color: #333 !important;\n    font-weight: 300 !important;\n}\n\nspan.ui-treenode-label {\n    line-height: 18px !important;\n    font-size: 13px !important;\n}\n\nbody .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-chkbox-icon {\n    margin: -1px 0 0 -1px;\n}\n\nbody .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {\n    font-size: 16px;\n}\n\nbody .ui-chkbox .ui-chkbox-box {\n    width: 16px;\n    height: 16px;\n}\n\nbody .ui-chkbox {\n    width: 16px;\n    height: 16px;\n}\n\nbody .ui-tree .ui-tree-container .ui-treenode {\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
