// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin {
    0% {
        transform: translateZ(0) rotate(0)
    }

    100% {
        transform: translateZ(0) rotate(360deg)
    }
}

.freeze-ui {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999999;
    background-color: #fff;
    opacity: .8;
    transition: opacity .25s
}

    .freeze-ui.is-unfreezing {
        opacity: 0
    }

    .freeze-ui:after {
        content: attr(data-text);
        display: block;
        max-width: 125px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 20px;
        font-family: sans-serif;
        color: #343a40;
        text-align: center;
        text-transform: uppercase
    }

    .freeze-ui:before {
        content: "";
        display: block;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
        border-color: transparent #228ae6 #228ae6;
        position: absolute;
        top: calc(50% - 75px);
        left: calc(50% - 75px);
        will-change: transform;
        animation: spin .75s infinite ease-in-out
    }
`, "",{"version":3,"sources":["webpack://./src/assets/FreezeUI/freeze-ui.min.css"],"names":[],"mappings":"AAAA;IACI;QACI;IACJ;;IAEA;QACI;IACJ;AACJ;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX;AACJ;;IAEI;QACI;IACJ;;IAEA;QACI,wBAAwB;QACxB,cAAc;QACd,gBAAgB;QAChB,kBAAkB;QAClB,QAAQ;QACR,SAAS;QACT,+BAA+B;QAC/B,eAAe;QACf,uBAAuB;QACvB,cAAc;QACd,kBAAkB;QAClB;IACJ;;IAEA;QACI,WAAW;QACX,cAAc;QACd,YAAY;QACZ,aAAa;QACb,kBAAkB;QAClB,iBAAiB;QACjB,mBAAmB;QACnB,yCAAyC;QACzC,kBAAkB;QAClB,qBAAqB;QACrB,sBAAsB;QACtB,sBAAsB;QACtB;IACJ","sourcesContent":["@keyframes spin {\n    0% {\n        transform: translateZ(0) rotate(0)\n    }\n\n    100% {\n        transform: translateZ(0) rotate(360deg)\n    }\n}\n\n.freeze-ui {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 999999999;\n    background-color: #fff;\n    opacity: .8;\n    transition: opacity .25s\n}\n\n    .freeze-ui.is-unfreezing {\n        opacity: 0\n    }\n\n    .freeze-ui:after {\n        content: attr(data-text);\n        display: block;\n        max-width: 125px;\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%,-50%);\n        font-size: 20px;\n        font-family: sans-serif;\n        color: #343a40;\n        text-align: center;\n        text-transform: uppercase\n    }\n\n    .freeze-ui:before {\n        content: \"\";\n        display: block;\n        width: 150px;\n        height: 150px;\n        border-radius: 50%;\n        border-width: 2px;\n        border-style: solid;\n        border-color: transparent #228ae6 #228ae6;\n        position: absolute;\n        top: calc(50% - 75px);\n        left: calc(50% - 75px);\n        will-change: transform;\n        animation: spin .75s infinite ease-in-out\n    }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
