import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ChangeManagerSettingsEditDto, TenantSettingsEditDto, TenantSettingsServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-change-manager',
  templateUrl: './change-manager.component.html',
  styleUrls: ['./change-manager.component.css']
})
export class ChangeManagerComponent extends AppComponentBase implements OnInit {

  settings: TenantSettingsEditDto = new TenantSettingsEditDto();
  loading = false;

  get changeManagerSettings(): ChangeManagerSettingsEditDto {
    return this.settings.changeManager || new ChangeManagerSettingsEditDto();
  }

  get frequency(): number {
    const frequency = parseInt(this.changeManagerSettings.frequency) / 60 / 1000;
    return frequency
  }

  set frequency(value: number) {
    const numberValue = Math.abs(value);
    const frequency = numberValue * 60 * 1000;
    this.changeManagerSettings.frequency = frequency.toString()
  }

  constructor(
    injector: Injector,
    private _tenantSettingsService: TenantSettingsServiceProxy
  ) {
    super(injector);
  }
  ngOnInit() {
    this.getSettings();
  }

  getSettings() {
    this.loading = true;
    this._tenantSettingsService.getAllSettings()
      .pipe(finalize(() => { this.loading = false; }))
      .subscribe((result: TenantSettingsEditDto) => this.settings = result);
  }

  saveAll() {
    this.loading = true;
    this._tenantSettingsService.updateAllSettings(this.settings)
      .pipe(finalize(() => this.loading = false))
      .subscribe()
  }

}
