<div>
    <div *ngIf="isMultiTenancyEnabled && ldap.isModuleEnabled">
        <label for="Setting_LdapIsEnabled" class="kt-checkbox">
            <input id="Setting_LdapIsEnabled" type="checkbox" name="LdapIsEnabled"
                [(ngModel)]="ldap.isEnabled"> {{"EnableLdapAuthentication" | localize}}
            <span></span>
        </label>
        <div class="form-group" [hidden]="!ldap.isEnabled">
            <label for="LdapDomainName">{{"DomainName" | localize}}</label>
            <input id="LdapDomainName" type="text" name="LdapDomainName" class="form-control"
                [(ngModel)]="ldap.domain" maxlength="128">
        </div>
        <div class="form-group" [hidden]="!ldap.isEnabled">
            <label for="LdapUserName">{{"UserName" | localize}}</label>
            <input id="LdapUserName" type="text" name="LdapUserName" class="form-control"
                [(ngModel)]="ldap.userName" maxlength="128">
        </div>
        <div class="form-group" [hidden]="!ldap.isEnabled">
            <label for="LdapPassword">{{"Password" | localize}}</label>
            <input id="LdapPassword" type="password" name="LdapPassword" class="form-control"
                [(ngModel)]="ldap.password" maxlength="128">
        </div>
        <app-enum-select class="form-group" [hidden]="!ldap.isEnabled"
            sName="LdapContextType" sId="LdapContextType" sClass="form-control"  
            [label]="l('Tipo')" [value]="ldap.contextType" (valueChange)="ldap.contextType = $event"
            [enumeration]="contextType">
        </app-enum-select>
        <div class="form-group" [hidden]="!ldap.isEnabled">
            <label for="LdapContainer">{{"LdapContainer" | localize}}</label>
            <input id="LdapContainer" type="text" name="LdapContainer" class="form-control"
                [(ngModel)]="ldap.container" maxlength="128">
        </div>
        <div *ngIf="ldap.isEnabled">
            <button type="button" class="btn btn-default" (click)="searchAdUsers($event)">
                {{'SearchAdUsers' | localize}}
            </button>
            <button *ngIf="hasUsersSelected" type="button" class="btn btn-primary"
                (click)="sendAdUsers($event)">
                {{'SaveAdUsers' | localize}}
            </button>

        </div>
    </div>
    <div *ngIf="ldap.isEnabled" class="mt-3">
        <p-progressSpinner *ngIf="loading"></p-progressSpinner>
        <ng-template #noData>
            <div class="bg-light p-5 border">
                <p class="no-ad-users"> {{'noAdUsersToShow' | localize}} </p>
            </div>
        </ng-template>
        <p-table *ngIf="adUsers && !loading" [value]="usersToShow" [paginator]="true" [rows]="10"
            [totalRecords]="totalCount" [loading]="loading"
            [globalFilterFields]="['id','employeedId', 'name', 'login', 'domain']">

            <ng-template pTemplate="caption">
                <div class="d-flex justify-content-end">
                    <input pInputText type="text"
                        [(ngModel)]="filter"
                        [placeholder]="l('Search')" />
                    <div>
                        <i class="flaticon-search ml-1"></i>
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr *ngIf="hasUsers; else noData">
                    <th style="width: 50px">
                        <mat-checkbox [(ngModel)]="selectAll">
                        </mat-checkbox>
                    </th>
                    <th pResizableColumn>
                        {{'IsEnabled' | localize}}
                    </th>
                    <th pResizableColumn pSortableColumn="id">
                        {{'Id' | localize}}</th>
                    <th pResizableColumn pSortableColumn="employeed-id">
                        {{'AdEmployeedId' | localize}}
                    </th>
                    <th pResizableColumn pSortableColumn="name">
                        {{'Name' | localize}}
                    </th>
                    <th pResizableColumn>
                        {{'Surname' | localize}}
                    </th>
                    <th pResizableColumn pSortableColumn="login">
                        {{'Login' | localize}}
                    </th>
                    <th pResizableColumn>
                        {{'EmailAddress' | localize}}
                    </th>
                    <th pResizableColumn pSortableColumn="domain">
                        {{'DomainName' | localize}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record="$implicit">
                <tr>
                    <td style="width:50px ">
                        <mat-checkbox name="checkSelect" [(ngModel)]="record.check">
                        </mat-checkbox>
                    </td>
                    <td class="text-center" [ngClass]="{
                        'flaticon2-check-mark text-success': record.enabled,
                        'flaticon2-cross text-danger': !record.enabled
                    }"> </td>
                    <td>
                        {{record.id}}
                    </td>
                    <td class="text-break">
                        {{record.employeeId}}
                    </td>
                    <td>
                        {{record.name}} {{record.middleName}}
                    </td>
                    <td>
                        {{record.surname}}
                    </td>
                    <td class="text-break">
                        {{record.samAccountName}}
                    </td>
                    <td class="text-break">
                        {{record.emailAddress}}
                    </td>
                    <td>
                        {{record.domain}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>