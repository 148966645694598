import { Component, Injector, ViewEncapsulation, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CodeReviewHistoricoCriterioDto, CodeReviewServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'code-reviewer-comments-modal',
    templateUrl: './code-reviewer-comments-modal.component.html',
    styleUrls: ['./code-reviewer-comments-modal.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})

export class CodeReviewCommentsModalComponent extends AppComponentBase implements OnInit {
    

    @ViewChild('codeReviewerCommentsModal', { static: true}) codeReviewerCommentsModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() saved: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();

    codeReviewHistoricoCriterioDto: CodeReviewHistoricoCriterioDto[] = []
    situacao: string;
    avaliador: string;
    dataAvaliacao: moment.Moment;

    constructor(
        injector: Injector,
        private _codeReviewServiceProxy: CodeReviewServiceProxy    
    ) {
        super(injector);
    }

    ngOnInit(): void {
       //OnInit
    }

    show(codeReviewDemandaId: string, avaliador: string, dataAvaliacao: moment.Moment) {
        this.avaliador = avaliador;
        this.dataAvaliacao = dataAvaliacao;
        this._codeReviewServiceProxy.getCriteriosCodeReview(codeReviewDemandaId).subscribe(res => {
            this.codeReviewHistoricoCriterioDto = res;
            this.codeReviewerCommentsModal.show();
        });
    }

    close(): void {
        this.codeReviewerCommentsModal.hide();
    }

    getLocaleDateHourCodeReview(dataAvaliacao: moment.Moment) {
        const dateString = dataAvaliacao ? dataAvaliacao.toString() : "";
        return new Date(dateString).toLocaleString();
    }
}