<div bsModal #changeManagerNativeModal="bs-modal" id="changeManagerNativeModal" class="modal fade change-modal overflow-auto" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content change-modal">
            <form #changeManagerNativeForm="ngForm" (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{l('CreateChangeManagerHeader')}}
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="spinner-content" class="spinner-row">
                        <div id="spinner-loading" class="spinner-loading">
                          <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                        </div>
                    </div>
                    <div class="gmud-native-content" *ngIf="!loading">
                        <tabset>
                            <tab heading="{{l('GeneralInfo')}}">
                                <div class="form-group">
                                    <label for="change-requester">{{l('ChangeRequester')}}</label>
                                    <input id="change-requester" type="text"
                                        name="ChangeRequester" class="form-control" [(ngModel)]="changeRequester" disabled/>
                                </div>
                                <div class="form-group">
                                    <label for="change-title">{{l('ChangeTitle')}}</label>
                                    <input id="change-title" #changeTitle="ngModel" type="text" name="ChangeTitle"
                                        class="form-control" [(ngModel)]="changeManager.tituloDemanda"
                                        minlength="10"
                                        [ngClass]="{
                                            'is-invalid': changeTitle.invalid,
                                            'is-valid': !changeTitle.invalid
                                        }" required/>
                                        <div>
                                            O texto deve conter no mínimo 15 caracteres.
                                        </div>
                                </div>
                                <div class="form-group">
                                    <label for="change-description">{{l('ChangeDescription')}}</label>
                                    <p-editor id="change-description" [(ngModel)]="changeManager.descricaoDemanda" name="change-description" [style]="{'height':'320px'}" required></p-editor>
                                    <div>
                                        O texto deve conter no mínimo 25 caracteres.
                                    </div>
                                </div>
                                <div class="content-planned">
                                    <label for="changeType">{{l('Tipo de transporte')}}</label>
                                    <p-dropdown Id="changeType" class="form-group changeType-content" [options]="types" 
                                                (onChange)="onValueChange($event)" [autoDisplayFirst]="true"
                                                optionLabel="name" optionValue="value">
                                    </p-dropdown>
                                    <div class="datetimeDeploy-content mb-2" *ngIf="isPlanned">
                                        <label for="change-dateTimeDeploy">{{l('Data e hora')}}</label>
                                        <p-calendar id="change-dateTimeDeploy" name="datetimeDeploy" [(ngModel)]="datetimeDeploy"
                                                    [showTime]="true" dateFormat="dd/mm/yy" [showIcon]="true">
                                        </p-calendar>
                                    </div>
                                    <div class="form-group" *ngIf="isWindowsTransport">
                                        <label for="windows-transp">{{l('Data da janela de transporte')}}</label>
                                        <input id="windows-transp" #changeTitle="ngModel" type="text" name="WindowsTransport"
                                            class="form-control" [(ngModel)]="windowsTransport" disabled/>
                                        <br>
                                    </div>
                                </div>
                                <div class="content-risk">
                                    <label for="changeRisk">{{l('ChangeRisk')}}</label>
                                    <p-dropdown Id="changeRisk" class="form-group changeType-content" [options]="typesRisk" 
                                                (onChange)="onValueChangeRisk($event)" [autoDisplayFirst]="true"
                                                optionLabel="name" optionValue="value">
                                    </p-dropdown>
                                    <div *ngIf="isRisk" class="form-group">
                                        <label for="mitigation-plan">{{l('MitigationPlanNative')}}</label>
                                        <input id="mitigation-plan" #mitigationPlan="ngModel" type="text" name="MitigationPlan"
                                            class="form-control" [(ngModel)]="changeManager.planoPrevencao"
                                            minlength="15"
                                            [ngClass]="{
                                                'is-invalid': mitigationPlan.invalid,
                                                'is-valid': !mitigationPlan.invalid
                                            }" required/>
                                            <div>
                                                O texto deve conter no mínimo 15 caracteres.
                                            </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="return-plan">{{l('ReturnPlanNative')}}</label>
                                        <input id="return-plan" #returnPlan="ngModel" type="text" name="ReturnPlan"
                                            class="form-control" [(ngModel)]="changeManager.planoRetorno"
                                            minlength="15"
                                            [ngClass]="{
                                                'is-invalid': returnPlan.invalid,
                                                'is-valid': !returnPlan.invalid
                                            }" required/>
                                            <div>
                                                O texto deve conter no mínimo 15 caracteres.
                                            </div>
                                    </div>
                                </div>
                            </tab>
                            <tab heading="{{l('Approvers')}}">
                                <div class="form-group">
                                    <label for="changeApprovers">{{l('SelectApprovers')}}</label>
                                    <p-tree *ngIf="treeDepartamentRequired.length > 0" [value]="treeDepartamentRequired" selectionMode="checkbox" [(selection)]="selectedtreeDepartamentRequired" 
                                            [propagateSelectionUp]="false" [propagateSelectionDown]="false" (onNodeUnselect)="nodeUnselectRequired($event)"
                                            (onNodeSelect)="nodeSelectRequired($event)">
                                        <ng-template let-node pTemplate="default">
                                            {{ node.label }}
                                        </ng-template>    
                                    </p-tree>    
                                    <p-tree *ngIf="treeDepartamentOptional.length > 0" [value]="treeDepartamentOptional" selectionMode="checkbox" [(selection)]="selectedtreeDepartamentOptional" 
                                            [propagateSelectionUp]="false" [propagateSelectionDown]="false" (onNodeSelect)="nodeSelect($event)"
                                            (onNodeUnselect)="nodeUnselect($event)">
                                        <ng-template let-node pTemplate="default">
                                            {{ node.label }}
                                        </ng-template>  
                                    </p-tree>
                                </div>
                            </tab>
                            <tab heading="{{l('Anexos')}}">
                                <div class="form-group">
                                    <div class="form-group m-form__group" [busyIf]="primengTableHelper.isLoading">
                                        <div id="anexos-gmud">
                                            <div class="list-head list-row">
                                                <div class="list-item item"><span>Documento</span></div>
                                                <div class="list-item item-action"><span>Ação</span></div>
                                            </div>
                                            <div class="list-body list-row" *ngFor="let anexo of anexosAdicionados; let i = index">
                                                <div class="list-item item"><span>{{anexo.nomeAnexo}}</span></div>
                                                <div class="list-item item-action">
                                                    <span class="delete-item" (click)="removerAnexo(anexo)"
                                                    tooltip="{{l('Download do anexo')}}"><i class="fa fa-trash"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dropzone" ng2FileDrop (click)="fileInput.click()"
                                            (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
                                            (drop)="onDrop($event)">
                                             Solte os arquivos(doc, docx, pdf, xml, txt, xls, xlsx, jpg, png) aqui (ou clique) 
                                        </div>
                                        <input #fileInput ng2FileSelect [uploader]="uploader" type="file" [showPreviews]="false"
                                            [multiple]="true" [preserveFiles]="false" ng2FileSelect
                                            [accept]="'application/xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/excel, application/vnd.ms-excel.sheet.macroEnabled.12, text/plain, image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                            style="display: none" (change)="onFilesAdded($event,null)" />
                                    </div>
                                </div>
                            </tab>
                            <tab heading="{{l('Pós implementação')}}">
                                <div class="form-group">
                                    <label for="pos-implemention">{{l('Pós implementação')}}</label>
                                    <p-editor id="pos-implemention" [(ngModel)]="changeManager.posImplementacao" 
                                              name="pos-implemention" [style]="{'height':'320px'}">
                                    </p-editor>
                                </div>
                            </tab>
                        </tabset>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l('Cancel')}}</button>
                    <button type="submit" class="btn btn-primary blue" [buttonBusy]="saving"
                        [disabled]="changeManagerNativeForm.invalid"
                        [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i>
                        <span>{{l('Save')}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <app-information-deploy-modal #informationDeployModal (closed)="closeInformation()" (modalSave)="finalizeDemand()"></app-information-deploy-modal>
</div>