<div bsModal #editModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editModal"
     aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">

        <div class="modal-content">

            <form #tenantEditForm="ngForm" role="form" novalidate class="form-validation" (submit)="save()" *ngIf="tenant && active">

                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"EditTenant" | localize}}: {{tenant.tenancyName}}</span>
                    </h5>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')" [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <tabset>
                        <tab heading="{{'TenantInformations' | localize}}">
                            <ng-template tabHeading > 
                                <i *ngIf="Cnpj.invalid || nameInput.invalid " 
                                    class="fas fa-exclamation-circle exclamation-tab"></i>
                            </ng-template>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Name">{{"TenantName" | localize}} *</label>
                                        <input id="Name" 
                                            #nameInput="ngModel" 
                                            type="text" 
                                            name="Name" 
                                            class="form-control" 
                                            [ngClass]="{'is-invalid': nameInput.invalid, 'is-valid': !nameInput.invalid}" 
                                            [(ngModel)]="tenant.name" 
                                            required 
                                            maxlength="128">
                                        <validation-messages [formCtrl]="nameInput"></validation-messages>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Cnpj">{{l("Cnpj")}} *</label>
                                        <input type="text" 
                                            #Cnpj="ngModel" 
                                            id="Tenant_Cnpj" 
                                            class="form-control" 
                                            [(ngModel)]="tenant.cnpj" 
                                            (keyup)="validarCNPJ($event)"
                                            name="Cnpj"  
                                            mask="00.000.000/0000-00"
                                            pattern="\d{2,3}.?\d{3}.?\d{3}/?\d{4}-?\d{2}"    
                                            [ngClass]="{'is-invalid': !cnpjValido, 'is-valid': cnpjValido}"
                                            required />
                                            <label class="text-danger" *ngIf="!cnpjValido"> {{l("CnpjInvalido")}}</label>
                                            <label class="text-danger" *ngIf="!cnpjUnico && cnpjValido"> {{l("CnpjJaUtilizado")}}</label>
                                        </div>
                                </div>                                
                                <div class="col-md-12">
                                    <div class="form-group" *ngIf="currentConnectionString">
                                        <label for="DatabaseConnectionString">{{"DatabaseConnectionString" | localize}} *</label>
                                        <input id="DatabaseConnectionString" 
                                            #connectionStringInput="ngModel" 
                                            type="text" 
                                            name="ConnectionString" 
                                            class="form-control" [(ngModel)]="tenant.connectionString" required maxlength="1024">
                                        <validation-messages [formCtrl]="connectionStringInput"></validation-messages>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div *ngIf="currentConnectionString">
                                        <span class="help-block text-warning">{{"TenantDatabaseConnectionStringChangeWarningMessage" | localize}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                    <label for="edition">{{"Edition" | localize}}</label>
                                        <select id="edition" name="edition" class="form-control" [(ngModel)]="tenant.editionId" (change)="onEditionChange($event)">
                                            <option *ngFor="let edition of editions" [value]="edition.value">{{edition.displayText}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="EmailTeams">{{"Email de integração com o Teams" | localize}}</label>
                                        <input id="EmailTeams" #emailTeamsInput="ngModel" type="email"
                                            name="EmailTeams" class="form-control"
                                            [(ngModel)]="tenant.emailTeams">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="ClientIdTiflux">{{"Cliente Id Tiflux" | localize}}</label>
                                        <input id="ClientIdTiflux" #clientIdTifluxInput="ngModel" type="number"
                                            name="ClientIdTiflux" class="form-control"
                                            [(ngModel)]="tenant.clientIdTiflux">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div [hidden]="!isSubscriptionFieldsVisible" class="kt-checkbox-list">
                                        <label class="kt-checkbox">
                                            <input id="CreateTenant_IsUnlimited" type="checkbox" name="IsUnlimited" [(ngModel)]="isUnlimited" (ngModelChange)="onUnlimitedChange()"/>
                                            {{"UnlimitedTimeSubscription" | localize}}
                                            <span></span>
                                        </label>
                                    </div>    
                                </div>  
                                <div class="col-md-12">
                                    <div [hidden]="isUnlimited || !isSubscriptionFieldsVisible" class="form-group" [ngClass]="{'has-error': !subscriptionEndDateUtcIsValid }">
                                        <label for="SubscriptionEndDateUtc">{{"SubscriptionEndDateUtc" | localize}}</label>
                                        <input id="SubscriptionEndDateUtc" type="datetime" #SubscriptionEndDateUtc name="SubscriptionEndDateUtc" class="form-control"
                                                [ngClass]="{'edited':tenant.subscriptionEndDateUtc}"
                                                (bsValueChange)="subscriptionEndDateChange($event)"
                                                bsDatepicker
                                                [(ngModel)]="subscriptionEndDateUtcDate"
                                                [required]="!isUnlimited">
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div [hidden]="!isSubscriptionFieldsVisible" class="kt-checkbox-list">
                                        <label class="kt-checkbox">
                                            <input id="CreateTenant_IsInTrialPeriod" type="checkbox" name="IsInTrialPeriod" [disabled]="selectedEditionIsFree()" [(ngModel)]="tenant.isInTrialPeriod">
                                            {{"IsInTrialPeriod" | localize}}
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
            
                                <!-- <div class="col-md-12">
                                    <div class="kt-checkbox-list">
                                        <label class="kt-checkbox">
                                            <input id="EditTenant_IsActive" type="checkbox" name="IsActive" [(ngModel)]="tenant.isActive">
                                            {{"Active" | localize}}
                                            <span></span>
                                        </label>
                                    </div>
                                </div> -->
                            </div>
                        </tab>
                        <tab heading="{{'Endereco' | localize}}">
                            <ng-template tabHeading > 
                                <i *ngIf="Cep.invalid || Cidade.invalid " 
                                    class="fas fa-exclamation-circle exclamation-tab"></i>
                            </ng-template>
        
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_postalCode">{{l("Cep")}} *</label>
                                        <input type="text" 
                                            id="Tenant_postalCode" 
                                            class="form-control" 
                                            [(ngModel)]="tenant.postalCode" 
                                            name="postalCode" 
                                            required 
                                            #Cep="ngModel" 
                                            [ngClass]="{'is-invalid': Cep.invalid, 'is-valid': !Cep.invalid}" 
                                            maxlength="8"/>
                                        <validation-messages [formCtrl]="Cep"></validation-messages>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Endereco">{{l("Endereco")}}</label>
                                        <input type="text" id="Tenant_Endereco" class="form-control" [(ngModel)]="tenant.endereco" name="Endereco" maxlength="200"/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Numero">{{l("Numero")}}</label>
                                        <input type="number" id="Tenant_Numero" class="form-control" [(ngModel)]="tenant.numero" name="Numero" maxlength="128"/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Complemento">{{l("Complemento")}}</label>
                                        <input type="text" id="Tenant_Complemento" class="form-control" [(ngModel)]="tenant.complemento" name="Complemento" maxlength="200"/>
                                    </div>
                                </div>
                                
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Bairro">{{l("Bairro")}}</label>
                                        <input type="text" id="Tenant_Bairro" class="form-control" [(ngModel)]="tenant.bairro" name="Bairro" maxlength="200"/>
                                    </div>
                                </div>
                        
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Cidade">{{l("Cidade")}} *</label>
                                        <input type="text" 
                                        id="Tenant_Cidade" 
                                        class="form-control" 
                                        [(ngModel)]="tenant.cidade" 
                                        name="Cidade" 
                                        required 
                                        #Cidade="ngModel" 
                                        [ngClass]="{'is-invalid': Cidade.invalid, 'is-valid': !Cidade.invalid}" 
                                        maxlength="200"/>
                                        <validation-messages [formCtrl]="Cidade"></validation-messages>
                                    </div>
                                </div>
                                
                        
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Estado">{{l("Estado")}} *</label>
                                        <input type="text" 
                                        id="Tenant_Estado"
                                         class="form-control" 
                                         [(ngModel)]="tenant.estado" 
                                         name="Estado"   
                                         minlength="2" 
                                         maxlength="2" 
                                         #Estado="ngModel" 
                                         [ngClass]="{'is-invalid': Estado.invalid, 'is-valid': !Estado.invalid}" 
                                         required/>
                                        <validation-messages [formCtrl]="Estado"></validation-messages>
                                    </div>
                                </div>
                        
                        
                            </div>
                        </tab>
                        <tab heading="{{'Responsaveis' | localize}}" >
                            <div class="row">
                                <div class="col-md-12">
                                    <ng-select 
                                        [items]="user$"
                                        [multiple]="true"
                                        [(ngModel)]="userSelected"
                                        name="userSelected">
                                    </ng-select>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button type="button" [disabled]="saving" class="btn btn-secondary" (click)="close()">{{"Cancel" | localize}}</button>
                    <button type="submit" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')" class="btn btn-primary" [disabled]="!tenantEditForm.form.valid || !cnpjValido || !cnpjUnico || saving || !subscriptionEndDateUtcIsValid"><i class="fa fa-save"></i> <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
