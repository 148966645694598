<div id="documentacaoItemQualidadeModal" bsModal #DocumentacaoItemQualidadeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="DocumentacaoItemQualidadeModal"
  aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            <span>{{documentacao.problema}}</span>
          </h4>
          <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" [innerHTML]="documentacao.documentacao">
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="close()">{{l("Close")}}</button>
        </div>
    </div>
  </div>
</div>