import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { FornecedoresServiceProxy, CreateOrEditFornecedorDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ValidatorsLegaisService } from '@shared/utils/validators-legais.service';


@Component({
    selector: 'createOrEditFornecedorModal',
    templateUrl: './create-or-edit-fornecedor-modal.component.html'
})
export class CreateOrEditFornecedorModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;


    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    cnpjValido = true;
    cnpjUnico = true;
    isNew = false;

    fornecedor: CreateOrEditFornecedorDto = new CreateOrEditFornecedorDto();

    constructor(
        injector: Injector,
        private _fornecedoresServiceProxy: FornecedoresServiceProxy,
        private _validatorsLegaisService: ValidatorsLegaisService
    ) {
        super(injector);
    }

    show(fornecedorId?: string): void {

        this.isNew = true;
        if (!fornecedorId) {
            this.fornecedor = new CreateOrEditFornecedorDto();
            this.fornecedor.id = fornecedorId;
            this.active = true;
            this.modal.show();
        } else {
            this._fornecedoresServiceProxy.getFornecedorForEdit(fornecedorId).subscribe(result => {
                this.fornecedor = result.fornecedor;
                this.active = true;
                this.modal.show();
                this.isNew = false;
            });
        }
    }

    validarCNPJ(): void {
        this.cnpjValido = this._validatorsLegaisService.validarCNPJ(this.fornecedor.cnpj);

        this._fornecedoresServiceProxy.isUniqueCNPJ(this.fornecedor.cnpj).subscribe(res => {
            this.cnpjUnico = res;
        });
    }

    save(): void {
        this.saving = true;


        this._fornecedoresServiceProxy.createOrEdit(this.fornecedor)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
            });
    }

    close(): void {

        this.active = false;
        this.modal.hide();
    }



}
