import { Component, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { LinkedAccountService } from "@app/shared/layout/linked-account.service";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ConfiguracaoAreaUsuarioServiceProxy, DemandasRecentesUsuarioDto, DemandasServiceProxy } from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'recent-demands',
    templateUrl: './recentDemands.component.html',
    styleUrls: ['./recentDemands.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class RecentDemandsComponent extends AppComponentBase implements OnInit {

    cardList: DemandasRecentesUsuarioDto[] = [];
    contador = 0;

    constructor(
        private router: Router,
        injector: Injector,
        private _ConfiguracaoAreaUsuarioServiceProxy: ConfiguracaoAreaUsuarioServiceProxy,
        private _demandasServiceProxy: DemandasServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getDemandasRecentes();
    }

    getDemandasRecentes(){
        this._ConfiguracaoAreaUsuarioServiceProxy.getDemandasRecentes(this.appSession.userId, this.appSession.tenantId).subscribe(result => {
            result.forEach(demanda => {
                if(demanda.estagioDemanda == 'Homologacao') demanda.estagioDemanda = 'Homologação';
                else if(demanda.estagioDemanda == 'Producao') demanda.estagioDemanda = 'Produção';
                this.cardList.push(demanda);
            });
         });
    }

    redirectToDemand(demandaId){
        this._demandasServiceProxy.getDemandaForEdit(demandaId)
        .subscribe((result) =>{
            this.router.navigate(['/app/main/demandas/kanban/', result.demanda.id]);
        });
    }
}