<div [@routerTransition] id="aprovalChangeRequest" class="overflow-auto">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("Requisição de mudança")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{l("Gerenciamento de RDM - Requisição de mudança")}}
            </span>
        </div> 
        <!--   
        <div class="kt-subheader__main">
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc" >
                <button class="btn btn-primary" type="submit" (click)="showCreateRequestModal(true)">
                    <i class="fa fa-plus"></i>
                    Criar RDM
                </button>                  
            </span> 
        </div> 
        -->   
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <!--
                <form class="kt-form" autocomplete="off">
                    <div>
                        <div class="row align-items-center">
                            <div class="col-xl-12">
                                <div class="form-group m-form__group align-items-center">
                                    <label for="DemandaIdFilter">{{l("IdDaDemanda")}} ou {{l("Solicitante")}}</label>
                                    <div class="input-group">
                                        <input [(ngModel)]="filterText" name="filterText" autoFocus
                                            class="form-control m-input" [placeholder]="l('SearchWithThreeDot')"
                                            type="text">
                                        <span class="input-group-btn">
                                            <button (click)="recarregar()" class="btn btn-primary"
                                                type="submit"><i class="flaticon-search-1"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" [hidden]="!advancedFiltersAreShown">
                            <div class="col-md-12">
                                <div class="kt-separator kt-separator--border-dashed"></div>
                            </div>
                            <div class="col-md-2">
                                <label for="StatusGmudFilterSelect">{{l("Status da GMUD")}}</label>
                                <select class="form-control" name="StatusGmudFilter" id="StatusGmudFilterSelect"
                                    [(ngModel)]="statusGmudFilter" (ngModelChange)="recarregar()">
                                    <option value="-1">{{l("All")}}</option>
                                    <option value="0">{{l('Em Análise')}}</option>
                                    <option value="1">{{l('Aprovado')}}</option>
                                    <option value="2">{{l('Reprovado')}}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label for="StatusAprovadorFilterSelect">{{l("Status do Aprovador")}}</label>
                                <select class="form-control" name="StatusAprovadorFilter" id="StatusAprovadorFilterSelect"
                                    [(ngModel)]="statusAprovadorFilter" (ngModelChange)="recarregar()">
                                    <option value="-1">{{l("All")}}</option>
                                    <option value="0">{{l('Aguardando aprovação')}}</option>
                                    <option value="1">{{l('Aprovado')}}</option>
                                    <option value="2">{{l('Aprovado automaticamente')}}</option>
                                    <option value="3">{{l('Reprovado')}}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="MinDataDaAvaliacaoFilter">{{l("Intervalo de data da GMUD")}}</label>
                                    <div class="input-group">
                                        <input class="form-control m-input" id="MinDataDaAvaliacaoFilter"
                                            name="MinDataDaAvaliacaoFilter" type="datetime" bsDatepicker
                                            placeholder="{{l('MinValue')}}" [(ngModel)]="minDataDaAvaliacaoFilter">
                                        <span style="line-height: 40px;padding: 0 15px 0 15px;"> &mdash;</span>
                                        <input class="form-control m-input" id="MaxDataDaAvaliacaoFilter"
                                            name="MaxDataDaAvaliacaoFilter" type="datetime" bsDatepicker
                                            placeholder="{{l('MaxValue')}}" [(ngModel)]="maxDataDaAvaliacaoFilter">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-12">
                                <span class="clickable-item text-muted" *ngIf="!advancedFiltersAreShown"
                                    (click)="advancedFiltersAreShown =! advancedFiltersAreShown"><i
                                        class="fa fa-angle-down"></i> {{l("ShowAdvancedFilters")}}
                                </span>
                                <span class="clickable-item text-muted" *ngIf="advancedFiltersAreShown"
                                    (click)="advancedFiltersAreShown =! advancedFiltersAreShown"><i
                                        class="fa fa-angle-up"></i> {{l("HideAdvancedFilters")}}
                                </span>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-xl-12 text-right">
                                <button (click)="resetarFiltros()" name="clean" class="btn btn-secondary">
                                    {{'Reset' | localize}}
                                </button>
                                <button (click)="recarregar()" name="RefreshButton"
                                    class="btn btn-primary"><i class="fa fa-sync"></i> {{'Refresh' | localize}}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                -->                
                <div class="row align-items-center">
                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable (onLazyLoad)="reloadGrid($event)"
                            [value]="primengTableHelper.records"
                            rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false" [lazy]="true"
                            [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                            [resizableColumns]="primengTableHelper.resizableColumns">
                            <ng-template pTemplate="header">
                                <tr class="table-header">
                                    <th pSortableColumn="number">
                                        Número
                                        <p-sortIcon field="number"></p-sortIcon>
                                    </th>                                  
                                    <th pSortableColumn="title">
                                        Título
                                        <p-sortIcon field="title"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="solicitante">
                                        Solicitante
                                        <p-sortIcon field="solicitante"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="dataHora">
                                        Data solicitação
                                        <p-sortIcon field="dataHora"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="rescheduleDate">
                                        Data do reagendamento
                                        <p-sortIcon field="rescheduleDate"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="status">
                                        Status da Mudança
                                        <p-sortIcon field="status"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="approvalStatus">
                                        Status da Aprovação
                                        <p-sortIcon field="status"></p-sortIcon>
                                    </th>
                                    <th class="action-table">Ação</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr class="table-body">   
                                    <td class="text-truncate">
                                        {{record.changeRequestNumber}}
                                    </td>                                 
                                    <td class="text-truncate">
                                        {{record.title}}
                                    </td>
                                    <td class="text-truncate">
                                        {{record.creatorUser}}
                                    </td>
                                    <td>
                                        {{getLocaleDateHour(record.creationTime)}}
                                    </td>
                                    <td>
                                        {{getLocaleDateHour(record.rescheduleDate)}}
                                    </td>
                                    <td class="text-truncate">
                                        <p-tag [value]="record.changeRequestStatus" [severity]="getStatusColor(record.changeRequestStatus)"></p-tag>
                                    </td>
                                    <td class="text-truncate">
                                        <p-tag [value]="record.approvalStatus" [severity]="getStatusColor(record.approvalStatus)"></p-tag>
                                    </td>
                                    <td>
                                        <div class="list-item item-action">
                                            <span class="send-item" tooltip="Visualizar RDM para aprovação"
                                                  (click)="showApprovalChangeRequest(record.id)">
                                                <i class="fas fa-eye"></i>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{l('NoData')}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                                (onPageChange)="reloadGrid($event)"
                                [totalRecords]="primengTableHelper.totalRecordsCount"
                                [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <approval-change-request-modal #approvalOrDisapproveChangeRequestModal (closed)="reloadPage($event)"></approval-change-request-modal>
</div>