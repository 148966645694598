<div bsModal #changeManagerModal="bs-modal" class="modal fade change-modal" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content change-modal">
            <form #changeManagerForm="ngForm" (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{"CreateChangeManagerHeader" | localize}}
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                    <div *ngIf="!loading">
                        <div class="form-group">
                            <label for="change-title">{{"ChangeTitle" | localize}} *</label>
                            <input id="change-title" #changeTitle="ngModel" type="text" name="ChangeTitle"
                                class="form-control" [(ngModel)]="changeNumber" required
                                [ngClass]="{'is-invalid': changeTitle.invalid, 'is-valid': !changeTitle.invalid}" />
                            <validation-messages [formCtrl]="changeTitle"></validation-messages>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{"Cancel" |
                        localize}}</button>
                    <button type="submit" class="btn btn-primary blue" [buttonBusy]="saving"
                        [disabled]="changeManagerForm.invalid" [busyText]="'SavingWithThreeDot' | localize"><i
                            class="fa fa-save"></i>
                        <span>{{"Save" | localize}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
