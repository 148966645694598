<div bsModal #criarIntegracaoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="criarIntegracaoModal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #integracaoForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{ 'CreateNewIntegration' | localize}}
                    </h4>
                    <button type="button" class="close" (click)="close()" aria-label="Close" [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p-table [value]="listaIntegracoes">
                        <ng-template pTemplate="body" let-record="$implicit">
                            <tr>
                                <td>
                                    <i class="p-2 fa fa-trash text-danger clickable"
                                        (click)="deleteIntegracao(record.id)"></i>
                                    <i class="p-2 fas fa-pen text-primary clickable"
                                        (click)="setForm(record)"></i>
                                    {{record.description}} - {{'Authentication' | localize}} {{ record.authType | localize }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div *ngIf="showForm" class="my-3">
                        <div class="form-group">
                            <label for="Integracao_Descricao">{{ l("Descricao") }} *</label>
                            <input type="text" id="Integracao_Descricao" class="form-control"
                                [(ngModel)]="integracaoModelo.description" name="Descricao"
                                #Integracao_Descricao="ngModel" [ngClass]="{
                                'is-invalid': Integracao_Descricao.invalid,
                                'is-valid': !Integracao_Descricao.invalid
                            }" required />
                        </div>
                        <div class="form-group">
                            <label for="Integracao_Endpoint">{{ l("Endpoint") }} *</label>
                            <input type="text" id="Integracao_Endpoint" class="form-control"
                                [(ngModel)]="integracaoModelo.integrationEndpoint" name="Endpoint"
                                #Integracao_Endpoint="ngModel" [ngClass]="{
                                'is-invalid': Integracao_Endpoint.invalid,
                                'is-valid': !Integracao_Endpoint.invalid
                            }" required />
                        </div>

                        <app-enum-select class="form-group" sName="Auth" sId="Integracao_Auth" sClass="form-control"
                            [label]="l('AuthType')" [value]="integracaoModelo.authType"
                            (valueChange)="integracaoModelo.authType = $event" [enumeration]="enumAuthenticationType">
                        </app-enum-select>

                        <div *ngIf="integracaoModelo.authType == enumAuthenticationType.BASIC">
                            <div class="form-group">
                                <label for="Integracao_Usuario">{{ l("Usuario") }} *</label>
                                <input type="text" id="Usuario_Integracao" class="form-control"
                                    [(ngModel)]="integracaoModelo.basic.user" name="IntegracaoUsuario"
                                    #Integracao_Usuario="ngModel" [ngClass]="{
                                    'is-invalid': Integracao_Usuario.invalid,
                                    'is-valid': !Integracao_Usuario.invalid
                                }" required />
                            </div>

                            <div class="form-group">
                                <label for="Integracao_Senha">{{ l("Senha") }} *</label>
                                <input type="text" id="Integracao_Senha" class="form-control"
                                    [(ngModel)]="integracaoModelo.basic.password" name="IntegracaoSenha"
                                    #Integracao_Senha="ngModel" [ngClass]="{
                                    'is-invalid': Integracao_Senha.invalid,
                                    'is-valid': !Integracao_Senha.invalid
                                }" required />
                            </div>
                        </div>

                        <div *ngIf="integracaoModelo.authType == enumAuthenticationType.TOKEN">
                            <div class="form-group">
                                <label for="Integracao_Url">{{ l("Url") }} *</label>
                                <input type="text" id="Integracao_Url" class="form-control"
                                    [(ngModel)]="integracaoModelo.token.url" name="Url" #Integracao_Url="ngModel"
                                    [ngClass]="{
                                    'is-invalid': Integracao_Url.invalid,
                                    'is-valid': !Integracao_Url.invalid
                                }" required />
                            </div>
                            <div class="form-group">
                                <label for="Integracao_RequestType">{{ l("RequestType") }} *</label>
                                <input type="text" id="Integracao_RequestType" class="form-control"
                                    [(ngModel)]="integracaoModelo.token.requestType" name="RequestType"
                                    #Integracao_RequestType="ngModel" [ngClass]="{
                                    'is-invalid': Integracao_RequestType.invalid,
                                    'is-valid': !Integracao_RequestType.invalid
                                }" required />
                            </div>
                            <div class="form-group">
                                <label for="Integracao_TokenType">{{ l("TokenType") }} *</label>
                                <input type="text" id="Integracao_TokenType" class="form-control"
                                    [(ngModel)]="integracaoModelo.token.tokenType" name="TokenType"
                                    #Integracao_TokenType="ngModel" [ngClass]="{
                                    'is-invalid': Integracao_TokenType.invalid,
                                    'is-valid': !Integracao_TokenType.invalid
                                }" required />
                            </div>
                            <div class="form-group">
                                <label for="Integracao_AccessToken">{{ l("AccessToken") }} *</label>
                                <input type="text" id="Integracao_AccessToken" class="form-control"
                                    [(ngModel)]="integracaoModelo.token.accessToken" name="AccessToken"
                                    #Integracao_AccessToken="ngModel" [ngClass]="{
                                    'is-invalid': Integracao_AccessToken.invalid,
                                    'is-valid': !Integracao_AccessToken.invalid
                                }" required />
                            </div>
                            <div class="form-group">
                                <label for="Integracao_ContentType">{{ l("ContentType") }} *</label>
                                <input type="text" id="Integracao_ContentType" class="form-control"
                                    [(ngModel)]="integracaoModelo.token.contentType" name="ContentType"
                                    #Integracao_ContentType="ngModel" [ngClass]="{
                                    'is-invalid': Integracao_ContentType.invalid,
                                    'is-valid': !Integracao_ContentType.invalid
                                }" required />
                            </div>
                            <div class="form-group">
                                <label for="Integracao_grantType">{{ l("GrantType") }} *</label>
                                <input type="text" id="Integracao_grantType" class="form-control"
                                    [(ngModel)]="integracaoModelo.token.grantType" name="GrantType"
                                    #Integracao_grantType="ngModel" [ngClass]="{
                                    'is-invalid': Integracao_grantType.invalid,
                                    'is-valid': !Integracao_grantType.invalid
                                }" required />
                            </div>
                        </div>

                        <div *ngIf="integracaoModelo.authType == enumAuthenticationType.CUSTOMIZED">
                            <h6>{{ 'ExtraFields' | localize}}</h6>
                            <div class="d-flex align-items-end ">
                                <div class="form-group col-5">
                                    <label for="CampoAdicional_Label">{{ l("Field") }} </label>
                                    <input type="text" id="CampoAdicional_Label" class="form-control"
                                        [(ngModel)]="campoAdicionalModelo.label" name="Label"
                                        #CampoAdicional_Label="ngModel" />
                                </div>
                                <div class="form-group col-5">
                                    <label for="CampoAdicional_Valor">{{ l("Value") }} </label>
                                    <input type="text" id="CampoAdicional_Valor" class="form-control"
                                        [(ngModel)]="campoAdicionalModelo.value" name="Valor"
                                        #CampoAdicional_Valor="ngModel" />
                                </div>
                                <button [disabled]="disableExtraFieldButton" type="button" class="col-2 btn btn-primary blue customized-button"
                                    (click)="adicionarCampoAdicional()"> {{ l("Save") }} </button>
                            </div>
                            <p-table [value]="camposAdicionais">
                                <ng-template pTemplate="body" let-record="$implicit">
                                    <tr class="d-flex">
                                        <td class="col-1">
                                            <i class="p-2 fa fa-trash text-danger clickable"
                                                (click)="deletarCampoAdicional(record.label)"></i>
                                        </td>
                                        <td class="col-4">
                                            {{ record.label }}
                                        </td>
                                        <td class="col-7">
                                            {{ record.value }}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>

                    <div class="d-flex flex-row-reverse my-3">
                        <button *ngIf="!showForm" type="button" class="btn btn-primary blue" (click)="showForm = true">
                            {{'NewIntegration' | localize}}
                        </button>

                        <button *ngIf="showForm" type="submit" class="btn btn-primary blue" [disabled]="!integracaoForm.form.valid"
                            [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')">
                            <i class="fa fa-save"></i> <span>{{ l("Save") }}</span>
                        </button>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-default" (click)="close()">
                        {{ l("Close") }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>