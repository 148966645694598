import {
    Component,
    Input,
    Injector,
    Output,
    EventEmitter
} from '@angular/core';
import { AmbienteDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'item-ambiente',
    templateUrl: './item-ambiente.component.html',
    styleUrls: ['./item-ambiente.component.css']
})
export class ItemAmbienteComponent extends AppComponentBase {
    @Input() listaAmbientes: Array<AmbienteDto>;
    @Input() nomeBranch: string;
    @Input() icone: string;

    @Output() openModal: EventEmitter<string> = new EventEmitter<string>();
    @Output() openModalHistory: EventEmitter<any> = new EventEmitter<any>();

    constructor(injector: Injector) {
        super(injector);
    }

    emitEventRefresh(idAmbiente) {
        this.openModal.emit(idAmbiente);
    }

    emitEventOpenModalHistory(idAmbiente) {
        this.openModalHistory.emit(idAmbiente);
    }
}
