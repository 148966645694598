import {
    Component,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import {
    AmbientesServiceProxy,
    CreateOrEditAmbienteDto,
    EnumEstagios,
    EnumIntegrationType,
    MandanteSapDto,
    Select2ItemDto,
    VinculosAmbienteDto
} from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CriarIntegracaoModalComponent } from '../criar-integracao-modal/criar-integracao-modal.component';
import { timeStamp } from 'console';

@Component({
    selector: 'createOrEditAmbienteModal',
    templateUrl: './create-or-edit-ambiente-modal.component.html'
})
export class CreateOrEditAmbienteModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @ViewChild('criarIntegracaoModal', { static: true }) criarIntegracaoModal: CriarIntegracaoModalComponent;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() detalhesAdicionado: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    nomeBranch = '';
    ambiente: CreateOrEditAmbienteDto = new CreateOrEditAmbienteDto({
        id: null,
        nome: null,
        estagioAtual: null,
        instanceNumber: null,
        listaMandantes: [],
        server: null,
        systemId: null,
        listaVinculos: []
    });

    ambientes: Select2ItemDto[];
    ambienteSelected: Select2ItemDto[];
    idAmbiente: string;

    get hasMandante() {
        return this.ambiente.listaMandantes && this.ambiente.listaMandantes.length > 0;
    }

    get enumIntegrationType() {
        return EnumIntegrationType;
    }

    get enumEstagios() {
        return EnumEstagios;
    }

    set estagio(value: EnumEstagios) {
        this.nomeBranch = value;
        this.ambiente.estagioAtual = value;
    }

    constructor(
        injector: Injector,
        private _ambientesServiceProxy: AmbientesServiceProxy
    ) {
        super(injector);
    }
    ngOnInit(): void {
    }

    show(ambienteId?: string, nomeBranch?: string): void {
        this.nomeBranch = nomeBranch;
        if (!ambienteId) {
            this.ambiente = new CreateOrEditAmbienteDto({
                id: null,
                nome: null,
                estagioAtual: null,
                instanceNumber: null,
                listaMandantes: [],
                server: null,
                systemId: null,
                listaVinculos: []
            });
            this.active = true;
            this.selectEnvironment();
            this.modal.show();
            return;
        }
        this.idAmbiente = ambienteId;
        this._ambientesServiceProxy
            .getAmbienteForEdit(ambienteId)
            .subscribe(result => {
                this.ambiente = result.ambiente;
                this.active = true;
                this.selectEnvironment();
                this.selectEnvironmentView();
                this.modal.show();
            });
    }

    save(): void {
        this.saving = true;
        let listaAmbientesVinculados = [];
        if (this.ambienteSelected != null && this.ambienteSelected.length > 0) {

            this.ambienteSelected.forEach(element => {
                let ret = new VinculosAmbienteDto();
                ret.ambienteEntradaId = this.ambiente.id;
                ret.ambienteSaidaId = element.value;
                listaAmbientesVinculados.push(ret);
            });
            this.ambiente.listaVinculos = listaAmbientesVinculados;
        }

        this._ambientesServiceProxy.createOrEdit(this.ambiente)
            .pipe(finalize(() => {
                this.saving = false;
            }))
            .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
            });
    }

    removeAmbiente() {
        this.message.confirm('', '', isConfirmed => {
            if (isConfirmed) {
                this._ambientesServiceProxy
                    .delete(this.idAmbiente)
                    .subscribe(() => {
                        this.notify.info(this.l('SuccessfullyDeleted'));
                        this.modalSave.emit(null);
                        this.close();
                    });
            }
        });
    }

    close(): void {
        this.ambienteSelected = [];
        this.active = false;
        this.modal.hide();
    }

    manipularMandantes(mandantes: MandanteSapDto[]) {
        this.ambiente.listaMandantes = mandantes;
    }

    openIntegrationModal() {
        this.criarIntegracaoModal.show();
        this.modal.hide()
    }
    closeIntegrationModal() {
        this.modal.show()
    }

    selectEnvironment(): void {
        this.ambienteSelected = [];
        var estagioAtual: string | undefined;
        var demandaId: string | undefined;
        demandaId = '00000000-0000-0000-0000-000000000000';
        estagioAtual = this.ambiente.estagioAtual == null ? '' : this.ambiente.estagioAtual.toString();
        this._ambientesServiceProxy
            .getByEstagioId(demandaId, estagioAtual).subscribe(x => {
                this.ambientes = x;
                if (this.ambientes.length == 1) {
                    this.ambienteSelected = [this.ambientes[0]];
                }
            });
    }

    selectEnvironmentView() {
        if (this.ambiente.listaVinculos != null && this.ambiente.listaVinculos.length > 0) {
            this._ambientesServiceProxy
                .getAmbientesVinculados(this.ambiente.id).subscribe(x => {
                    this.ambienteSelected = x;
                });
        }
    }
}
