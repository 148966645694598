import { ThemeHelper } from "@app/shared/layout/themes/ThemeHelper";
import { BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { NgxBootstrapLocaleMappingService } from "./ngx-bootstrap-locale-mapping.service";
import { defineLocale } from "ngx-bootstrap/chronos";
import { ConfigCodeReviewDto } from "@shared/service-proxies/service-proxies";

export class NgxBootstrapDatePickerConfigService {

    static getDaterangepickerConfig(): BsDaterangepickerConfig {
        return Object.assign(new BsDaterangepickerConfig(), {
            containerClass: 'theme-' + NgxBootstrapDatePickerConfigService.getTheme()
        });
    }

    static getDatepickerConfig(): BsDatepickerConfig {
        return Object.assign(new BsDatepickerConfig(), {
            containerClass: 'theme-' + NgxBootstrapDatePickerConfigService.getTheme()
        });
    }

    static getTheme(): string {
        return ThemeHelper.getTheme();
    }

    static getDatepickerLocale(): BsLocaleService {
        let localeService = new BsLocaleService();
        localeService.use(abp.localization.currentLanguage.name);
        return localeService;
    }

    static registerNgxBootstrapDatePickerLocales(): Promise<boolean> {
        if (abp.localization.currentLanguage.name === 'en') {
            return Promise.resolve(true);
        }
    
        const supportedLocale = new NgxBootstrapLocaleMappingService().map(abp.localization.currentLanguage.name).toLowerCase();
        const moduleLocaleName = new NgxBootstrapLocaleMappingService().getModuleName(abp.localization.currentLanguage.name);
    
        try {
            const module = require(`../../../node_modules/ngx-bootstrap/chronos/esm2022/i18n/${supportedLocale}.mjs`);
            defineLocale(abp.localization.currentLanguage.name.toLowerCase(), module[`${moduleLocaleName}Locale`]);
            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(error);
        }
    }
}
