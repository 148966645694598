import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
    selector: "app-non-compliance",
    templateUrl: "./non-compliance.component.html",
    styleUrls: ["./non-compliance.component.less"],
})
export class NonComplianceComponent implements OnInit {
    @Input() tenantId: number = 0;
    urlQuestion1: SafeUrl;
    urlQuestion2: SafeUrl;
    urlQuestion3: SafeUrl;
    urlQuestion4: SafeUrl;
    currentDate: string = "";
    initDate180DaysAgo: string = "";

    constructor(private domSanitizer: DomSanitizer) {
        this.getDates();
    }

    ngOnInit() {
        this.urlQuestion1 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/076d2fc2-3fb4-442f-b6e0-5eb89470ddcd?DataInicial=${this.initDate180DaysAgo}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
        this.urlQuestion2 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/0c48229c-369a-474f-a57e-67d361821700?DataInicial=${this.initDate180DaysAgo}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
        this.urlQuestion3 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/c0b1bd74-d8c3-4088-ae51-bf3f04866949?DataInicial=${this.initDate180DaysAgo}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
        this.urlQuestion4 = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `https://app1.qametrik.com/public/question/27c8963f-df3f-4aab-ad9b-ff1afc02af2a?DataInicial=${this.initDate180DaysAgo}&DataFinal=${this.currentDate}&TenantId=${this.tenantId}#hide_parameters=TenantId&theme=transparent`
        );
    }

    getDates() {
        var currentDate = new Date();
        var initDate = new Date();

        initDate.setDate(initDate.getDate() - 180);

        this.currentDate = currentDate.toISOString().slice(0, 10);
        this.initDate180DaysAgo = initDate.toISOString().slice(0, 10);
    }
}
