<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="createOrEditModal"
     aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #editForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span *ngIf="language.id">{{"EditLanguage" | localize}}: {{language.name}}</span>
                        <span *ngIf="!language.id">{{"CreateNewLanguage" | localize}}</span>
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')" [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <select  [disabled]="!newLanguage" #languageCombobox
                                name="languageName"
                                class="form-control"
                                [(ngModel)]="language.name"
                                required>
                            <option value="">{{emptyText}}</option>
                            <option *ngFor="let languageName of languageNames" [value]="languageName.value">{{languageName.displayText}}</option>
                        </select>
                        <validation-messages [formCtrl]="languageCombobox"></validation-messages>
                    </div>
                    <div class="form-group">
                        <select #iconCombobox
                                name="languageIcon"
                                class="form-control"
                                [(ngModel)]="language.icon">
                            <option value="">{{emptyText}}</option>
                            <option *ngFor="let flag of flags" [value]="flag.value" [attr.data-icon]="flag.value">{{flag.displayText}}</option>
                        </select>
                    </div>
                    <div *ngIf="!ehDefault" class="kt-checkbox-list">
                        <label for="EditLanguage_IsEnabled" class="kt-checkbox">
                            <input id="EditLanguage_IsEnabled" type="checkbox" name="languageIsEnabled"
                                   [(ngModel)]="language.isEnabled">
                            {{"IsEnabled" | localize}}
                            <span></span>
                        </label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary" [disabled]="!editForm.form.valid" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i> <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
