import { Component, Injector, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AvaliacoesDeQualidadesServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-justificativa-decisor',
  templateUrl: './justificativa-decisor.component.html',
  styleUrls: ['./justificativa-decisor.component.css']
})
export class JustificativaDecisorComponent extends AppComponentBase {
  @ViewChild('justificativaModal', { static: true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  idJustificativa: string;
  demandaId: string;
  justificativa = '';
  guidsSelecionados: Array<string> = [];
  acao: number;
  saving: boolean;
  avaliacaoId: string;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };


  constructor(injector: Injector,
    private _avaliacoesDeQualidadesServiceProxy: AvaliacoesDeQualidadesServiceProxy,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  show(id: string, demId: string) {
    this.idJustificativa = id;
    this.avaliacaoId = id;
    this.demandaId = demId;
    this.modal.show();
  }

  close(): void {
    this.modal.hide();
  }

  save() {
    this.saving = true;
   
    this._avaliacoesDeQualidadesServiceProxy.marcarDecisor(this.idJustificativa, this.justificativa, this.demandaId).subscribe(() => {
      this.saving = false;


      this.modal.hide();
      this.router.navigate(['/app/main/avaliacoes-qualidade']);
    });
  }
}
