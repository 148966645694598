<div bsModal #codeReviewHistoryModal="bs-modal" id="codeReviewHistoryModal" class="modal fade overflow-auto" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content code-review-history-modal">
            <form #codeReviewForm="ngForm" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{l("Histórico de Code Review")}}
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="history-table">
                        <p-table #ht [value]="listaHistoricoCodeReviewDto" [rows]="5" [paginator]="true" dataKey="id" [rowsPerPageOptions]="[5,10,25,50,100,500]">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="th-demand" scope="col">{{l("Demanda")}}</th>
                                    <th class="th-user-request" scope="col">{{l("Solicitante")}}</th>
                                    <th class="th-user-date" scope="col">{{l("Data/Hora")}}</th>
                                    <th class="th-status" scope="col">{{l("Situação")}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-avaliacao>
                                <tr>
                                    <td class="td-demand">{{avaliacao.demandaTitulo}}</td>
                                    <td class="td-user-request">{{avaliacao.nomeSolicitante}}</td>
                                    <td class="td-user-date">{{getLocaleDateHourCodeReview(avaliacao.dataCriacao)}}</td>
                                    <td class="td-status">{{avaliacao.status}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                <div class="flex align-items-center justify-content-between">
                                    Total: {{listaHistoricoCodeReviewDto ? listaHistoricoCodeReviewDto.length : 0 }}
                                </div>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>