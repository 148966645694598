<div
    bsModal
    #createOrEditModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createOrEditModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form
                *ngIf="active"
                #minhaSenhaForm="ngForm"
                novalidate
                (ngSubmit)="save()"
                autocomplete="off"
            >
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span *ngIf="minhaSenha.id">{{
                            l("EditMinhaSenha")
                        }}</span>
                        <span *ngIf="!minhaSenha.id">{{
                            l("CreateNewMinhaSenha")
                        }}</span>
                    </h4>
                    <button
                        type="button"
                        class="close"
                        (click)="close()"
                        aria-label="Close"
                        [disabled]="saving"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group m-form__group">
                        <label for="AmbienteTenantId">{{
                            l("Ambiente")
                        }} *</label>
                        <div class="input-group">
                            <input
                                class="form-control"
                                id="AmbienteNome"
                                name="ambienteNome"
                                [(ngModel)]="ambienteNome"
                                type="text"
                                disabled
                            />
                            <div class="input-group-append">
                                <button
                                    class="btn btn-primary blue"
                                    (click)="openSelectAmbienteModal()"
                                    type="button"
                                >
                                    <i class="fa fa-search"></i> {{ l("Pick") }}
                                </button>
                            </div>
                            <div class="input-group-prepend">
                                <button
                                    class="btn btn-danger btn-icon"
                                    type="button"
                                    (click)="setAmbienteIdNull()"
                                >
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <input
                        class="form-control"
                        name="minhaSenha.ambienteId"
                        [(ngModel)]="minhaSenha.ambienteId"
                        type="text"
                        required
                        hidden
                    />

                    <div class="form-group m-form__group">
                        <label for="UsuarioSapTenantId">{{
                            l("UsuarioSap")
                        }} *</label>
                        <div class="input-group">
                            <input
                                class="form-control"
                                id="UsuarioSapNome"
                                name="usuarioSapNome"
                                [(ngModel)]="usuarioSapNome"
                                type="text"
                                disabled
                            />
                            <div class="input-group-append">
                                <button
                                    class="btn btn-primary blue"
                                    (click)="openSelectUsuarioSapModal()"
                                    type="button"
                                >
                                    <i class="fa fa-search"></i> {{ l("Pick") }}
                                </button>
                            </div>
                            <div class="input-group-prepend">
                                <button
                                    class="btn btn-danger btn-icon"
                                    type="button"
                                    (click)="setUsuarioSapIdNull()"
                                >
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <input
                        class="form-control"
                        name="minhaSenha.usuarioSapId"
                        [(ngModel)]="minhaSenha.usuarioSapId"
                        type="text"
                        required
                        hidden
                    />

                    <div class="form-group">
                        <label for="MinhaSenha_senhas"
                            >{{ l("Senha") }} *</label
                        >
                        <input
                            type="text"
                            id="MinhaSenha_senhas"
                            class="form-control"
                            [(ngModel)]="minhaSenha.senhas"
                            name="senhas"
                            required
                        />
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        [disabled]="saving"
                        type="button"
                        class="btn btn-default"
                        (click)="close()"
                    >
                        {{ l("Cancel") }}
                    </button>
                    <button
                        type="submit"
                        class="btn btn-primary blue"
                        [disabled]="!minhaSenhaForm.form.valid"
                        [buttonBusy]="saving"
                        [busyText]="l('SavingWithThreeDot')"
                    >
                        <i class="fa fa-save"></i> <span>{{ l("Save") }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <minhaSenhaAmbienteLookupTableModal
        #minhaSenhaAmbienteLookupTableModal
        (modalSave)="getNewAmbienteName()"
    ></minhaSenhaAmbienteLookupTableModal>
    <minhaSenhaUsuarioSapLookupTableModal
        #minhaSenhaUsuarioSapLookupTableModal
        (modalSave)="getNewUsuarioSapName()"
    ></minhaSenhaUsuarioSapLookupTableModal>
</div>
