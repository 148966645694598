<div [@routerTransition]>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader kt-grid__item">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    <span>{{l("TrainingVideos")}}</span>
                </h3>
                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                <span class="kt-subheader__desc">
                    {{l("TrainingVideosHeaderInfo")}}
                </span>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <div class="row">
                    <div class="col-12">
                        <div *ngFor="let video of videos" class="row cursor-pointer"
                        (click)="videoView(video.snippet.resourceId.videoId)">
                            <div class="col-2">
                                <div class="col-12 mb-4">
                                    <img [src]="video.snippet.thumbnails.default.url" class="card-img-top" alt="...">
                                </div>
                            </div>
                            <div class="col-10">
                                <div class="col-12">
                                    <h5 class="card-title mb-1">{{video.snippet.title}}</h5>
                                </div>
                                <div class="col-12">
                                    <p class="card-description-video" >{{video.snippet.description}}</p>
                                </div>
                            </div>
                        <div class="col-12 mb-4 separator-video"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <videoViewModal #videoViewModal></videoViewModal>
</div>
