import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CreateOrEditDocumentacaoItemQualidadeDto, DocumentacaoItemQualidadeServiceProxy, Select2ItemDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LazyLoadEvent } from 'primeng/api';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-create-or-edit-documentation',
    templateUrl: './create-or-editdocumentation-modal.component.html',
    styleUrls: ['./create-or-editdocumentation-modal.component.css']
})
export class CreateOrEditdocumentationModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    documentation = new CreateOrEditDocumentacaoItemQualidadeDto();
    saving: boolean;

    constructor(
        injector: Injector,
        private _service: DocumentacaoItemQualidadeServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
    }

    show(id: string) {
        this.documentation = new CreateOrEditDocumentacaoItemQualidadeDto();
        if (id) this._service.getDocumentationItemQualidadeForEdit(id).subscribe(res => {
            this.documentation = res;
        })
        this.modal.show();
    }

    save() {
        this._service.createOrEditDocumentatacao(this.documentation)
            .subscribe(() => {
                this.close();
                this.documentation = new CreateOrEditDocumentacaoItemQualidadeDto();
                this.modalSave.emit();
            });
    }

    close() {
        this.modal.hide();
    }



}