window.abp = abp || { ui: {} };
(function () {
    abp.ui.setBusy = function (element, text, freezeDelay) {
        window.FreezeUI({ element: element, text: text ? text : ' ', freezeDelay: freezeDelay });
    };

    abp.ui.clearBusy = function (element, freezeDelay) {
        window.UnFreezeUI({ element: element, freezeDelay: freezeDelay });
    };

})();
