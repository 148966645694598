<div [@routerTransition] id="historyChangeRequest" class="overflow-auto">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("Requisição de mudança")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{l("Histórico de RDM - Requisição de mudança")}}
            </span>
        </div>   
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">              
                <div class="row align-items-center">
                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable (onLazyLoad)="reloadGrid($event)"
                            [value]="primengTableHelper.records"
                            rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false" [lazy]="true"
                            [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                            [resizableColumns]="primengTableHelper.resizableColumns">
                            <ng-template pTemplate="header">
                                <tr class="table-header">    
                                    <th pSortableColumn="number">
                                        Número
                                        <p-sortIcon field="number"></p-sortIcon>
                                    </th>                                
                                    <th pSortableColumn="title">
                                        Título
                                        <p-sortIcon field="title"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="solicitante">
                                        Solicitante
                                        <p-sortIcon field="solicitante"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="dataHora">
                                        Data solicitação
                                        <p-sortIcon field="dataHora"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="status">
                                        Status
                                        <p-sortIcon field="status"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="rescheduleDate">
                                        Data do reagendamento
                                        <p-sortIcon field="rescheduleDate"></p-sortIcon>
                                    </th>
                                    <th class="action-table">Ação</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr class="table-body"> 
                                    <td class="text-truncate">
                                        {{record.changeRequestNumber}}
                                    </td>                                   
                                    <td class="text-truncate">
                                        {{record.title}}
                                    </td>
                                    <td class="text-truncate">
                                        {{record.creatorUser}}
                                    </td>
                                    <td>
                                        {{getLocaleDateHour(record.creationTime)}}
                                    </td>
                                    <td class="text-truncate">
                                        <p-tag [value]="record.status" [severity]="getStatusColor(record.status)"></p-tag>
                                    </td>
                                    <td>
                                        {{getLocaleDateHour(record.rescheduleDate)}}
                                    </td>
                                    <td>
                                        <div class="list-item item-action">
                                            <span class="send-item" tooltip="Visualizar RDM"
                                                  (click)="showChangeRequest(record.id)">
                                                <i class="fas fa-eye"></i>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{l('NoData')}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                                (onPageChange)="reloadGrid($event)"
                                [totalRecords]="primengTableHelper.totalRecordsCount"
                                [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <history-change-request-modal #historysChangeRequestModal (closed)="reloadPage($event)"></history-change-request-modal>
</div>