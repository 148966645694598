<div class="kt-subheader kt-grid__item">
    <div class="kt-subheader__main">
        <h3 class="kt-subheader__title">
            <span>{{l("Configurações de gestão de risco")}}</span>
        </h3>
        <span class="kt-subheader__separator kt-subheader__separator--v"></span>
        <span class="kt-subheader__desc">
            {{l("Configurações de gestão de risco")}}
        </span>
    </div>
</div>
<div class="kt-content">
    <div class="kt-portlet">
        <div class="kt-portlet__body">
            <tabset>
                <tab heading="{{'Parâmetros'}}">
                    <div class="form-group">
                        <label class="kt-checkbox" tooltip="Quando ativado a demanda ficará travada em homologação até a verificação dos riscos.">
                            <input id="active-risk" type="checkbox" name="activeRisk"
                            [(ngModel)]="activeRisk"> 
                                {{'Ativar gestão de risco'}}
                            <span></span>
                        </label>
                    </div>
                </tab>
                <button type="submit" class="btn btn-primary blue" (click)="saveParameters()">
                    <i class="fa fa-save"></i>
                    <span>{{"Save" | localize}}</span>
                </button>
            </tabset>          
        </div>
    </div>
</div>