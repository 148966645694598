import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { Select2ItemDto } from "@shared/service-proxies/service-proxies";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: 'app-add-tenant-biding-modal',
    templateUrl: './add-tenant-biding-modal.component.html',
    styleUrls: ['./add-tenant-biding-modal.component.css']
})
export class AddTenantBinding extends AppComponentBase implements OnInit {
    @ViewChild('addTenantBinding', { static: true }) modal: ModalDirective;

    newBinding: boolean = false;
    tenants: Select2ItemDto[] = [
        new Select2ItemDto({
            active: false,
            label: "Cliente 1",
            value: "00000000-0000-0000-0000-000000000000"
        }),
        new Select2ItemDto({
            active: false,
            label: "Cliente 2",
            value: "00000000-0000-0000-0000-000000000001"
        }),
    ];
    binding: any = {};

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
    }

    show(newBinding) {
        this.newBinding = newBinding;
        this.modal.show();
    }

    close() {
        this.modal.hide();
    }

    save() {
        alert("salvar")
        this.modal.hide();
    }

}