import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.css']
})
export class CustomSelectComponent implements OnInit {

  @Input() set value(value: any) {
    this.selected = value;
  };
  @Input() fieldId: string;
  @Input() label: string;
  @Input() fieldName: string;
  @Input() options: Array<any>;
  @Input() valueKey: string;
  @Input() labelKey: string;
  @Input() required: boolean;
  
  @Output() valueChange: EventEmitter<any> = new EventEmitter();


  selected: any;

  constructor() { }

  ngOnInit() {
  }

  onChange(event: any) {
    this.valueChange.emit(event);
    this.selected = event;
  }

}
