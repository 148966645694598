<div id="recurringPeriods">
    <div class="form-group">
        <label for="recurringPeriods-title" id="recurringPeriods-label" 
               class="recurringPeriods-label">{{l("Cadastrar período")}}</label>
        <button id="btn-recurringPeriods" class="btn btn-primary btn-recurringPeriods" type="submit" (click)="AddPeriod()">
            <i id="plus-icon" class="fa fa-plus"></i>{{l("Novo")}}
        </button>  
    </div>
    <div class="row-configuration">        
        <div class="form-group">
            <label for="recurringPeriods-title" id="recurringPeriods-label" 
            class="recurringPeriods-label">{{l("Listagem dos períodos recorrentes")}}</label>
            <div id="recurringPeriods-table" class="recurringPeriods-table">
                <p-table #dt [value]="recurringPeriods" [rows]="5" [paginator]="true" dataKey="id" [rowsPerPageOptions]="[5,10,25,50,100,500]">
                    <ng-template pTemplate="header">
                        <tr id="header-row" class="header-row">
                            <th scope="col">{{l("Nome")}}</th>
                            <th scope="col">{{l("Data inicial")}}</th>
                            <th scope="col">{{l("Data final")}}</th>
                            <th scope="col">{{l("Ações")}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-recurringPeriod let-i>
                        <tr id="body-row" class="body-row">
                            <td id="recurringPeriods-name" class="recurringPeriods-day">{{recurringPeriod.nomePeriodo}}</td>
                            <td id="recurringPeriods-startDate" class="recurringPeriods-startHour">{{getLocaleMonthDay(recurringPeriod.dataInicial)}}</td>
                            <td id="recurringPeriods-endDate" class="recurringPeriods-endHour">{{getLocaleMonthDay(recurringPeriod.dataFinal)}}</td>
                            <td id="list-item-action" class="list-item-action">
                                <div class="list-item item-action">
                                    <span id="edit-item" (click)="editDeployPeriod(recurringPeriod.id)" class="edit-item" tooltip="{{l('Edit')}}">
                                        <i id="pen-icon" class="fas fa-pen"></i>
                                    </span>
                                    <span id="delete-item" (click)="deleteDeployPeriod(recurringPeriod)" class="delete-item" tooltip="{{l('Delete')}}">
                                        <i id="trash-icon" class="fas fa-trash"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div id="table-sumary" class="flex align-items-center justify-content-between">
                            Total: {{recurringPeriods ? recurringPeriods.length : 0}}
                        </div>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="form-group content-not-recurring-periods">
            <label for="notRecurringPeriods-title" id="notRecurringPeriods-label" 
            class="notRecurringPeriods-label">{{l("Listagem dos períodos não recorrentes")}}</label>
            <div id="notRecurringPeriods-table" class="notRecurringPeriods-table">
                <p-table #dt [value]="notRecurringPeriods" [rows]="5" [paginator]="true" dataKey="id" [rowsPerPageOptions]="[5,10,25,50,100,500]">
                    <ng-template pTemplate="header">
                        <tr id="header-row" class="header-row">
                            <th scope="col">{{l("Nome")}}</th>
                            <th scope="col">{{l("Data inicial")}}</th>
                            <th scope="col">{{l("Data final")}}</th>
                            <th scope="col">{{l("Ações")}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-notRecurringPeriod let-i2>
                        <tr id="body-row" class="body-row">
                            <td id="notRecurringPeriods-name" class="notRecurringPeriod-day">{{notRecurringPeriod.nomePeriodo}}</td>
                            <td id="notRecurringPeriods-startDate" class="notRecurringPeriod-startHour">{{getLocaleDate(notRecurringPeriod.dataInicial)}}</td>
                            <td id="notRecurringPeriods-endDate" class="notRecurringPeriod-endHour">{{getLocaleDate(notRecurringPeriod.dataFinal)}}</td>
                            <td id="list-item-action" class="list-item-action">
                                <div class="list-item item-action">
                                    <span id="edit-item" (click)="editDeployPeriodsNotRecurring(notRecurringPeriod.id)" class="edit-item" tooltip="{{l('Edit')}}">
                                        <i id="pen-icon" class="fas fa-pen"></i>
                                    </span>
                                    <span id="delete-item" (click)="deleteDeployPeriodsNotRecurring(i2, notRecurringPeriod)" class="delete-item" tooltip="{{l('Delete')}}">
                                        <i id="trash-icon" class="fas fa-trash"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div id="table-sumary" class="flex align-items-center justify-content-between">
                            Total: {{notRecurringPeriods ? notRecurringPeriods.length : 0}}
                        </div>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <app-create-Or-Edit-Period-modal #createOrEditPeriodModal (closed)="reloadPage($event)"></app-create-Or-Edit-Period-modal>
</div>
