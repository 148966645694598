<div
    bsModal
    #createOrEditModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createOrEditModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{ l("AdicionarFornecedor") }}</span>
                </h4>
                <button
                    type="button"
                    class="close"
                    (click)="close()"
                    aria-label="Close"
                    [disabled]="saving"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group m-form__group align-items-center">
                    <div class="input-group">
                        <input
                            [(ngModel)]="filter"
                            name="filter"
                            autoFocus
                            class="form-control m-input"
                            [placeholder]="l('PesquisarFornecedorFiltro')"
                            type="text"
                        />
                        <span class="input-group-btn">
                            <button
                                (click)="getFornecedores()"
                                class="btn btn-primary"
                                type="submit"
                            >
                                <i class="flaticon-search-1"></i>
                            </button>
                        </span>
                    </div>
                </div>
                <div class="row align-items-center">
                    <!--<Primeng-Datatable-Start>-->
                    <div
                        class="primeng-datatable-container col-12"
                        [busyIf]="primengTableHelper.isLoading"
                    >
                        <p-table
                            #dataTable
                            (onLazyLoad)="getFornecedores($event)"
                            [value]="primengTableHelper.records"
                            rows="{{
                                primengTableHelper.defaultRecordsCountPerPage
                            }}"
                            [paginator]="false"
                            [lazy]="true"
                            [scrollable]="true"
                            ScrollWidth="100%"
                            [responsive]="primengTableHelper.isResponsive"
                            [resizableColumns]="
                                primengTableHelper.resizableColumns
                            "
                        >
                            <ng-template pTemplate="header">
                                <tr>
                                    <th
                                        style="width: 150px"
                                        [hidden]="
                                            !isGrantedAny(
                                                'Pages.Administration.Fornecedores.Tenant.List'
                                            )
                                        "
                                    >
                                        {{ l("Actions") }}
                                    </th>
                                    <th
                                        style="width: 150px"
                                        pSortableColumn="nome"
                                    >
                                        {{ l("Name") }}
                                        <p-sortIcon
                                            field="fornecedor.nome"
                                        ></p-sortIcon>
                                    </th>
                                    <th
                                        style="width: 150px"
                                        pSortableColumn="cnpj"
                                    >
                                        {{ l("Cnpj") }}
                                        <p-sortIcon
                                            field="fornecedor.cnpj"
                                        ></p-sortIcon>
                                    </th>
                                    <th
                                        style="width: 150px"
                                        pSortableColumn="cep"
                                    >
                                        {{ l("Cep") }}
                                        <p-sortIcon
                                            field="fornecedor.cep"
                                        ></p-sortIcon>
                                    </th>
                                    <th
                                        style="width: 150px"
                                        pSortableColumn="estado"
                                    >
                                        {{ l("Estado") }}
                                        <p-sortIcon
                                            field="fornecedor.estado"
                                        ></p-sortIcon>
                                    </th>
                                    <th
                                        style="width: 150px"
                                        pSortableColumn="cidade"
                                    >
                                        {{ l("Cidade") }}
                                        <p-sortIcon
                                            field="fornecedor.cidade"
                                        ></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template
                                pTemplate="body"
                                let-record="$implicit"
                            >
                                <tr>
                                    <td
                                        style="width: 150px"
                                        [hidden]="
                                            !isGrantedAny(
                                                'Pages.Administration.Fornecedores.Tenant.Delete'
                                            )
                                        "
                                    >
                                        <div
                                            class="btn-group dropdown"
                                            dropdown
                                            container="body"
                                            (click)="save(record.fornecedor)"
                                        >
                                            <button
                                                class="btn btn-sm btn-primary"
                                            >
                                                <i class="flaticon2-add-1"></i>
                                                {{ l("Vincular") }}
                                            </button>
                                        </div>
                                    </td>
                                    <td style="width:150px">
                                        {{ record.fornecedor.nome }}
                                    </td>
                                    <td style="width:150px">
                                        {{ record.fornecedor.cnpj }}
                                    </td>
                                    <td style="width:150px">
                                        {{ record.fornecedor.cep }}
                                    </td>
                                    <td style="width:150px">
                                        {{ record.fornecedor.estado }}
                                    </td>
                                    <td style="width:150px">
                                        {{ record.fornecedor.cidade }}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div
                            class="primeng-no-data"
                            *ngIf="primengTableHelper.totalRecordsCount == 0"
                        >
                            {{ l("NoData") }}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator
                                rows="{{
                                    primengTableHelper.defaultRecordsCountPerPage
                                }}"
                                #paginacao
                                (onPageChange)="getFornecedores($event)"
                                [totalRecords]="
                                    primengTableHelper.totalRecordsCount
                                "
                                [rowsPerPageOptions]="
                                    primengTableHelper.predefinedRecordsCountPerPage
                                "
                            >
                            </p-paginator>
                            <span class="total-records-count">
                                {{
                                    l(
                                        "TotalRecordsCount",
                                        primengTableHelper.totalRecordsCount
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    [disabled]="saving"
                    type="button"
                    class="btn btn-default"
                    (click)="close()"
                >
                    {{ l("Close") }}
                </button>
            </div>
        </div>
    </div>
</div>
