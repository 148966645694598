// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-fileupload .ui-corner-top {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.ui-fileupload .ui-corner-bottom {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ui-fileupload .ui-button {
  border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/assets/primeng/file-upload/css/primeng.file-upload.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,4BAA4B;AAC9B;AACA;EACE,8BAA8B;EAC9B,+BAA+B;AACjC;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".ui-fileupload .ui-corner-top {\n  border-top-left-radius: 2px;\n  border-top-right-radius: 2px;\n}\n.ui-fileupload .ui-corner-bottom {\n  border-bottom-left-radius: 2px;\n  border-bottom-right-radius: 2px;\n}\n.ui-fileupload .ui-button {\n  border-radius: 2px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
