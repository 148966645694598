<div bsModal #createModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="createModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">

        <div class="modal-content">

            <form #tenantCreateForm="ngForm" role="form" novalidate class="form-validation" *ngIf="active"
                (submit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"CreateNewTenant" | localize}}</span>
                    </h5>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')"
                        [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <tabset>
                        <tab heading="{{'TenantInformations' | localize}}">

                            <ng-template tabHeading>
                                <i *ngIf="tenancyName.invalid || Cnpj.invalid || nameInput.invalid || adminEmailAddressInput.invalid "
                                    class="fas fa-exclamation-circle exclamation-tab"></i>
                            </ng-template>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="TenancyName">{{"TenancyName" | localize}} * </label>
                                        <input id="TenancyName" #tenancyNameInput="ngModel"
                                            [ngClass]="{'is-invalid': tenancyName.invalid, 'is-valid': !tenancyName.invalid}"
                                            class="form-control" type="text" name="tenancyName"
                                            [(ngModel)]="tenant.tenancyName" #tenancyName="ngModel" required
                                            maxlength="64" pattern="^[a-zA-Z][a-zA-Z0-9_-]{1,}$">
                                        <validation-messages [formCtrl]="tenancyNameInput"></validation-messages>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Cnpj">{{l("Cnpj")}} *</label>
                                        <input type="text" id="Tenant_Cnpj" class="form-control"
                                            (keyup)="validarCNPJ($event)"
                                            [ngClass]="{'is-invalid': !cnpjValido, 'is-valid': cnpjValido}"
                                            [(ngModel)]="tenant.cnpj" name="Cnpj" #Cnpj="ngModel"
                                            mask="00.000.000/0000-00" pattern="\d{2,3}.?\d{3}.?\d{3}/?\d{4}-?\d{2}"
                                            required />

                                        <label class="text-danger" *ngIf="!cnpjValido"> {{l("CnpjInvalido")}}</label>
                                        <label class="text-danger" *ngIf="!cnpjUnico && cnpjValido">
                                            {{l("CnpjJaUtilizado")}}</label>

                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div>
                                        <span class="form-control-feedback"
                                            *ngIf="!Cnpj.valid && !Cnpj.pristine">{{"TenantName_Regex_Description" |
                                            localize}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Name">{{"TenantName" | localize}} *</label>
                                        <input id="Name" #nameInput="ngModel" type="text" name="Name"
                                            [ngClass]="{'is-invalid': nameInput.invalid, 'is-valid': !nameInput.invalid}"
                                            class="form-control" [(ngModel)]="tenant.name" required maxlength="128">
                                        <validation-messages [formCtrl]="nameInput"></validation-messages>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="kt-checkbox-list">
                                        <label class="kt-checkbox">
                                            <input id="CreateTenant_UseHostDb" type="checkbox" name="UseHostDb"
                                                [(ngModel)]="useHostDb">
                                            {{"UseHostDatabase" | localize}}
                                            <span></span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group" *ngIf="!useHostDb">
                                        <label for="DatabaseConnectionString">{{"DatabaseConnectionString" | localize}}
                                            *</label>
                                        <input id="DatabaseConnectionString" #connectionStringInput="ngModel"
                                            type="text" name="ConnectionString" class="form-control"
                                            [(ngModel)]="tenant.connectionString"
                                            [ngClass]="{'is-invalid': connectionStringInput.invalid, 'is-valid': !connectionStringInput.invalid}"
                                            required maxlength="1024">
                                        <validation-messages [formCtrl]="connectionStringInput"></validation-messages>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="AdminEmailAddress">{{"AdminEmailAddress" | localize}} *</label>
                                        <input id="AdminEmailAddress" #adminEmailAddressInput="ngModel" type="email"
                                            name="AdminEmailAddress" class="form-control"
                                            [(ngModel)]="tenant.adminEmailAddress"
                                            [ngClass]="{'is-invalid': adminEmailAddressInput.invalid, 'is-valid': !adminEmailAddressInput.invalid}"
                                            required pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
                                            maxlength="256">
                                        <validation-messages [formCtrl]="adminEmailAddressInput"></validation-messages>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="kt-checkbox-list">
                                        <label class="kt-checkbox">
                                            <input id="CreateTenant_SetRandomPassword" type="checkbox"
                                                name="SetRandomPassword" [(ngModel)]="setRandomPassword">
                                            {{"SetRandomPassword" | localize}}
                                            <span></span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group" *ngIf="!setRandomPassword">
                                        <label for="AdminPassword">{{"AdminPassword" | localize}}</label>
                                        <input id="AdminPassword" type="password" name="adminPassword"
                                            class="form-control" id="adminPassword" [(ngModel)]="tenant.adminPassword"
                                            [ngClass]="{'edited':tenant.adminPassword}" [required]="!setRandomPassword"
                                            #adminPassword="ngModel" validateEqual="adminPasswordRepeat" reverse="true"
                                            maxlength="32" [requireDigit]="passwordComplexitySetting.requireDigit"
                                            [requireLowercase]="passwordComplexitySetting.requireLowercase"
                                            [requireUppercase]="passwordComplexitySetting.requireUppercase"
                                            [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                                            [requiredLength]="passwordComplexitySetting.requiredLength"
                                            [notAllowSequencing]="passwordComplexitySetting.notAllowSequencingOfNumbersAndLetters">
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div [hidden]="tenantCreateForm.form.valid || tenantCreateForm.form.pristine">
                                        <ul class="help-block text-danger"
                                            *ngIf="tenantCreateForm.controls['adminPassword'] && tenantCreateForm.controls['adminPassword'].errors">
                                            <li
                                                [hidden]="!tenantCreateForm.controls['adminPassword'].errors.requireDigit">
                                                {{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                                            <li
                                                [hidden]="!tenantCreateForm.controls['adminPassword'].errors.requireLowercase">
                                                {{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                                            <li
                                                [hidden]="!tenantCreateForm.controls['adminPassword'].errors.requireUppercase">
                                                {{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                                            <li
                                                [hidden]="!tenantCreateForm.controls['adminPassword'].errors.requireNonAlphanumeric">
                                                {{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                                            <li
                                                [hidden]="!tenantCreateForm.controls['adminPassword'].errors.requiredLength">
                                                {{"PasswordComplexity_RequiredLength_Hint" |
                                                localize:passwordComplexitySetting.requiredLength}}
                                            </li>
                                            <li [hidden]="!tenantCreateForm.controls['adminPassword'].errors.notAllowSequencing">
                                                {{"NotAllowSequencingOfNumbersAndLettersHint" | localize}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group" *ngIf="!setRandomPassword">
                                        <label for="AdminPasswordRepeat">{{"AdminPasswordRepeat" | localize}}</label>
                                        <input id="AdminPasswordRepeat" type="password" name="adminPasswordRepeat"
                                            class="form-control" [(ngModel)]="tenant.adminPasswordRepeat"
                                            [ngClass]="{'edited':tenant.adminPasswordRepeat}"
                                            [required]="!setRandomPassword" #adminPasswordRepeat="ngModel"
                                            [requireDigit]="passwordComplexitySetting.requireDigit"
                                            [requireLowercase]="passwordComplexitySetting.requireLowercase"
                                            [requireUppercase]="passwordComplexitySetting.requireUppercase"
                                            [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                                            [requiredLength]="passwordComplexitySetting.requiredLength"
                                            [notAllowSequencing]="passwordComplexitySetting.notAllowSequencingOfNumbersAndLetters"
                                            validateEqual="adminPassword" maxlength="32">
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div [hidden]="tenantCreateForm.form.valid || tenantCreateForm.form.pristine">
                                        <ul class="help-block text-danger"
                                            *ngIf="tenantCreateForm.controls['adminPasswordRepeat'] && tenantCreateForm.controls['adminPasswordRepeat'].errors">
                                            <li
                                                [hidden]="!tenantCreateForm.controls['adminPasswordRepeat'].errors.requireDigit">
                                                {{"PasswordComplexity_RequireDigit_Hint" | localize}}</li>
                                            <li
                                                [hidden]="!tenantCreateForm.controls['adminPasswordRepeat'].errors.requireLowercase">
                                                {{"PasswordComplexity_RequireLowercase_Hint" | localize}}</li>
                                            <li
                                                [hidden]="!tenantCreateForm.controls['adminPasswordRepeat'].errors.requireUppercase">
                                                {{"PasswordComplexity_RequireUppercase_Hint" | localize}}</li>
                                            <li
                                                [hidden]="!tenantCreateForm.controls['adminPasswordRepeat'].errors.requireNonAlphanumeric">
                                                {{"PasswordComplexity_RequireNonAlphanumeric_Hint" | localize}}</li>
                                            <li
                                                [hidden]="!tenantCreateForm.controls['adminPasswordRepeat'].errors.requiredLength">
                                                {{"PasswordComplexity_RequiredLength_Hint" |
                                                localize:passwordComplexitySetting.requiredLength}}
                                            </li>
                                            <li [hidden]="tenantCreateForm.controls['adminPasswordRepeat'].valid">
                                                {{"PasswordsDontMatch" | localize}}</li>

                                            <li [hidden]="!tenantCreateForm.controls['adminPasswordRepeat'].errors.notAllowSequencing">
                                                {{"NotAllowSequencingOfNumbersAndLettersHint" | localize}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="edition">{{"Edition" | localize}}</label>
                                        <select id="edition" name="edition" class="form-control"
                                            [(ngModel)]="tenant.editionId" (change)="onEditionChange($event)">
                                            <option *ngFor="let edition of editions" [value]="edition.value">
                                                {{edition.displayText}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div [hidden]="!isSubscriptionFieldsVisible" class="kt-checkbox-list">
                                        <label for="CreateTenant_IsUnlimited" class="kt-checkbox">
                                            <input id="CreateTenant_IsUnlimited" type="checkbox" name="IsUnlimited"
                                                [(ngModel)]="isUnlimited" />
                                            {{"UnlimitedTimeSubscription" | localize}}
                                            <span></span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div [hidden]="isUnlimited || !isSubscriptionFieldsVisible" class="form-group"
                                        [ngClass]="{'has-error': !subscriptionEndDateIsValid()}">
                                        <label for="SubscriptionEndDate">{{"SubscriptionEndDate" | localize}}</label>
                                        <input id="SubscriptionEndDate" type="text" #SubscriptionEndDateUtc
                                            name="SubscriptionEndDateUtc" class="form-control" bsDatepicker
                                            [(ngModel)]="tenant.subscriptionEndDateUtc" autocomplete="new-password">
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div [hidden]="!isSubscriptionFieldsVisible" class="kt-checkbox-list">
                                        <label for="CreateTenant_IsInTrialPeriod" class="kt-checkbox">
                                            <input id="CreateTenant_IsInTrialPeriod" type="checkbox"
                                                name="IsInTrialPeriod" [disabled]="isSelectedEditionFree"
                                                [(ngModel)]="tenant.isInTrialPeriod">
                                            {{"IsInTrialPeriod" | localize}}
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="EmailTeams">{{"Email de integração com o Teams" | localize}}</label>
                                        <input id="EmailTeams" #emailTeamsInput="ngModel" type="email"
                                            name="EmailTeams" class="form-control"
                                            [(ngModel)]="tenant.emailTeams">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="ClientIdTiflux">{{"Cliente Id Tiflux" | localize}}</label>
                                        <input id="ClientIdTiflux" #clientIdTifluxInput="ngModel" type="number"
                                            name="ClientIdTiflux" class="form-control"
                                            [(ngModel)]="tenant.clientIdTiflux">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="kt-checkbox-list">
                                        <label for="CreateTenant_ShouldChangePasswordOnNextLogin" class="kt-checkbox">
                                            <input id="CreateTenant_ShouldChangePasswordOnNextLogin" type="checkbox"
                                                name="ShouldChangePasswordOnNextLogin"
                                                [(ngModel)]="tenant.shouldChangePasswordOnNextLogin">
                                            {{"ShouldChangePasswordOnNextLogin" | localize}}
                                            <span></span>
                                        </label>
                                        <label for="CreateTenant_SendActivationEmail" class="kt-checkbox">
                                            <input id="CreateTenant_SendActivationEmail" type="checkbox"
                                                name="SendActivationEmail" [(ngModel)]="tenant.sendActivationEmail">
                                            {{"SendActivationEmail" | localize}}
                                            <span></span>
                                        </label>
                                        <!--  <label for="CreateTenant_IsActive" class="kt-checkbox">
                                            <input id="CreateTenant_IsActive" type="checkbox" name="IsActive"
                                                [(ngModel)]="tenant.isActive">
                                            {{"Active" | localize}}
                                            <span></span>
                                        </label> -->
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="{{'Endereco' | localize}}">

                            <ng-template tabHeading>
                                <i *ngIf="Cep.invalid || Estado.invalid || Cidade.invalid "
                                    class="fas fa-exclamation-circle exclamation-tab"></i>
                            </ng-template>

                            <div class="row">


                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Cep">{{l("Cep")}} *</label>
                                        <input type="text" id="Tenant_Cep" class="form-control"
                                            [(ngModel)]="tenant.postalCode" name="PostalCode" required #Cep="ngModel"
                                            [ngClass]="{'is-invalid': Cep.invalid, 'is-valid': !Cep.invalid}"
                                            maxlength="8" />
                                        <validation-messages [formCtrl]="Cep"></validation-messages>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Endereco">{{l("Endereco")}}</label>
                                        <input type="text" id="Tenant_Endereco" class="form-control"
                                            [(ngModel)]="tenant.endereco" name="Endereco" maxlength="200" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Numero">{{l("Numero")}}</label>
                                        <input type="number" id="Tenant_Numero" class="form-control"
                                            [(ngModel)]="tenant.numero" name="Numero" maxlength="128" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Complemento">{{l("Complemento")}}</label>
                                        <input type="text" id="Tenant_Complemento" class="form-control"
                                            [(ngModel)]="tenant.complemento" name="Complemento" maxlength="200" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Bairro">{{l("Bairro")}}</label>
                                        <input type="text" id="Tenant_Bairro" class="form-control"
                                            [(ngModel)]="tenant.bairro" name="Bairro" maxlength="200" />
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Cidade">{{l("Cidade")}} *</label>
                                        <input type="text" id="Tenant_Cidade" class="form-control"
                                            [(ngModel)]="tenant.cidade" name="Cidade" required #Cidade="ngModel"
                                            [ngClass]="{'is-invalid': Cidade.invalid, 'is-valid': !Cidade.invalid}"
                                            maxlength="200" />
                                        <validation-messages [formCtrl]="Cidade"></validation-messages>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Tenant_Estado">{{l("Estado")}} *</label>
                                        <input type="text" id="Tenant_Estado" class="form-control"
                                            [(ngModel)]="tenant.estado" name="Estado" minlength="2" maxlength="2"
                                            #Estado="ngModel"
                                            [ngClass]="{'is-invalid': Estado.invalid, 'is-valid': !Estado.invalid}"
                                            required />
                                        <validation-messages [formCtrl]="Estado"></validation-messages>
                                    </div>
                                </div>




                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button type="button" [disabled]="saving" class="btn btn-secondary" (click)="close()">{{"Cancel" |
                        localize}}</button>
                    <button type="submit" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"
                        class="btn btn-primary"
                        [disabled]="!tenantCreateForm.form.valid || !cnpjUnico || !cnpjValido || saving || !subscriptionEndDateIsValid()"><i
                            class="fa fa-save"></i> <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>