<div>
    <div>
        <h6 class="mb-5">{{l('Configuração para períodos de freezing')}}</h6>
    </div>
    <form id="formUser">
        <div class="form-group">
            <label for="usersApprovalSelected" id="approvers-label" class="approvers-label">{{l("Selecione o aprovador")}}</label>
            <div class="row">
                <ng-select class="col-12 col-md-10" [items]="usersApproval" [multiple]="true" [(ngModel)]="usersApprovalSelected" name="usersApprovalSelected">
                    <ng-template ng-label-tmp let-item="item">
                        <div class="box-selected">
                            <span class="ng-value-label ng-star-inserted">
                                <b>{{item.label}}</b>
                                <div class="box-content">
                                    <div class="content-item item-cargo">{{item.cargo}}</div>
                                    <div class="content-item item-fornecedor">{{item.fornecedor}}</div>
                                </div>
                            </span>
                            <span class="delete-box" (click)="clearUser(item)" aria-hidden="true">×</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        <div class="p-2">
                            <b>{{item.label}}</b>
                            <div style="font-size: 12px;">{{item.cargo}}</div>
                            <div style="font-size: 10px;">{{item.fornecedor}}</div>
                        </div>
                    </ng-template>
                </ng-select> 
                <div class="col-12 col-md-2 button-content">
                    <button id="btn-user" type="submit" class="btn btn-primary btn-user" (click)="addApprovalUser()">
                        <i class="fa fa-save"></i><span>{{l("Save")}}</span>
                    </button>
                </div>
            </div>
            <label class="mt-1"><strong>*Atenção: </strong>Apenas uma aprovação é necessária para dar continuidade no processo de Deploy.</label>
        </div>
    </form>
</div>
