<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #moduloForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span *ngIf="modulo.id">{{l("EditModulo")}}</span>
                        <span *ngIf="!modulo.id">{{l("CreateNewModulo")}}</span>
                    </h4>
                    <button type="button" class="close" (click)="close()" aria-label="Close" [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                 


								<div class="form-group">
									<label for="Modulo_Nome">{{l("Name")}} *</label>
									<input type="text" id="Modulo_Nome" class="form-control" [(ngModel)]="modulo.nome" name="Nome"   minlength="2"  maxlength="200"  required/>
								</div>
					
								<div class="form-group">
									<label for="Modulo_Descricao">{{l("Descricao")}}</label>
									<input type="text" id="Modulo_Descricao" class="form-control" [(ngModel)]="modulo.descricao" name="Descricao"   minlength="0"  maxlength="500" />
								</div>
					

                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary blue" [disabled]="!moduloForm.form.valid" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i> <span>{{l("Save")}}</span></button>
                </div>
            </form>
        </div>
    </div>

</div>