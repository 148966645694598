<div id="tabs-parameters">
    <div class="form-group">
        <label class="kt-checkbox">
            <input id="email-deploy-production" type="checkbox" name="emailDeployProduction"
            [(ngModel)]="emailDeployProduction"> 
                {{'Enviar e-mail para deploy em produção'}}
            <span></span>
        </label>
    </div>

    <!--
    <div class="form-group">
        <label class="kt-checkbox">
            <input id="risk-management" type="checkbox" name="riskManagement"
            [(ngModel)]="riskManagement"> 
                {{'Ativar avaliação de risco'}}
            <span></span>
        </label>
    </div>
    -->

    <button type="submit" class="btn btn-primary blue" (click)="saveParameters()">
        <i class="fa fa-save"></i>
        <span>{{"Save" | localize}}</span>
    </button>
    
</div>
