import * as ngCommon from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { ChatSignalrService } from "@app/shared/layout/chat/chat-signalr.service";
import { LoginAttemptsModalComponent } from "@app/shared/layout/login-attempts-modal.component";
import { ChangePasswordModalComponent } from "@app/shared/layout/profile/change-password-modal.component";
import { ChangeProfilePictureModalComponent } from "@app/shared/layout/profile/change-profile-picture-modal.component";
import { MySettingsModalComponent } from "@app/shared/layout/profile/my-settings-modal.component";
import { SmsVerificationModalComponent } from "@app/shared/layout/profile/sms-verification-modal.component";
import { ServiceProxyModule } from "@shared/service-proxies/service-proxy.module";
import { UtilsModule } from "@shared/utils/utils.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TableModule } from "primeng/table";
import { ImpersonationService } from "./admin/users/impersonation.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DefaultLayoutComponent } from "./shared/layout/themes/default/default-layout.component";
import { AppCommonModule } from "./shared/common/app-common.module";
import { ChatBarComponent } from "./shared/layout/chat/chat-bar.component";
import { ThemeSelectionPanelComponent } from "./shared/layout/theme-selection/theme-selection-panel.component";
import { ChatFriendListItemComponent } from "./shared/layout/chat/chat-friend-list-item.component";
import { ChatMessageComponent } from "./shared/layout/chat/chat-message.component";
import { FooterComponent } from "./shared/layout/footer.component";
import { StackedFooterComponent } from "./shared/layout/stacked-footer.component";
import { LinkedAccountService } from "./shared/layout/linked-account.service";
import { SideBarMenuComponent } from "./shared/layout/nav/side-bar-menu.component";
import { TopBarMenuComponent } from "./shared/layout/nav/top-bar-menu.component";
import { TopBarComponent } from "./shared/layout/topbar.component";
import { DefaultBrandComponent } from "./shared/layout/themes/default/default-brand.component";
import { UserNotificationHelper } from "./shared/layout/notifications/UserNotificationHelper";
import { HeaderNotificationsComponent } from "./shared/layout/notifications/header-notifications.component";
import { NotificationSettingsModalComponent } from "./shared/layout/notifications/notification-settings-modal.component";
import { NotificationsComponent } from "./shared/layout/notifications/notifications.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgSelectModule } from "@ng-select/ng-select";
import { IConfig, provideNgxMask } from "ngx-mask";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { HomePageComponent } from "./home-page/home-page.component";
import { TreeTableModule } from "primeng/treetable";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
export var options: Partial<IConfig> | (() => Partial<IConfig>) = {};

// // Metronic
// import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//     // suppressScrollX: true
// };

import { CoreModule } from "@metronic/app/core/core.module";
import { ProgressBarModule } from "primeng/progressbar";
import { PaginatorModule } from "primeng/paginator";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { AbpModule } from "abp-ng2-module";
import { MatBadgeModule } from "@angular/material/badge";
import { FileUploadModule } from "primeng/fileupload";
import { OverlayscrollbarsModule } from "overlayscrollbars-ngx";
import { APP_BASE_HREF } from "@angular/common";

@NgModule({
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        HeaderNotificationsComponent,
        SideBarMenuComponent,
        TopBarMenuComponent,
        FooterComponent,
        StackedFooterComponent,
        LoginAttemptsModalComponent,
        ChangePasswordModalComponent,
        ChangeProfilePictureModalComponent,
        MySettingsModalComponent,
        SmsVerificationModalComponent,
        NotificationsComponent,
        ChatBarComponent,
        ThemeSelectionPanelComponent,
        ChatFriendListItemComponent,
        NotificationSettingsModalComponent,
        ChatMessageComponent,
        TopBarComponent,
        DefaultBrandComponent,
        HomePageComponent,
    ],
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        FileUploadModule,
        AbpModule,
        AppRoutingModule,
        UtilsModule,
        ToastModule,
        AppCommonModule.forRoot(),
        ServiceProxyModule,
        TableModule,
        PaginatorModule,
        ProgressSpinnerModule,
        ProgressBarModule,
        CoreModule,
        NgxChartsModule,
        ImageCropperModule,
        NgSelectModule,
        MatBadgeModule,
        AngularEditorModule,
        TreeTableModule,
        OverlayscrollbarsModule,
    ],
    providers: [
        ImpersonationService,
        LinkedAccountService,
        MessageService,
        UserNotificationHelper,
        ChatSignalrService,
        provideNgxMask(),
        { provide: APP_BASE_HREF, useValue: "/" },

        // {
        //     provide: PERFECT_SCROLLBAR_CONFIG,
        //     useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        // }
    ],
})
export class AppModule {}
