export enum PromptAIEnum {
    CodeGenerate,
    CodeReview,
    Documentation,
    ClassDiagramReading,
    UnitTestGeneration,
    DocumentationHistory,
}

export const PromptAIEnumLabel = new Map<number, string>([
    [PromptAIEnum.CodeGenerate, "Gerar Código"],
    [PromptAIEnum.CodeReview, "Gerar Revisão de código"],
    [PromptAIEnum.Documentation, "Gerar Documentação"],
    [PromptAIEnum.ClassDiagramReading, "Documentação Funcional"],
    [PromptAIEnum.UnitTestGeneration, "Gerar Documentação"],
    [PromptAIEnum.DocumentationHistory, "Histórico de Documentação"],
]);
