<div bsModal #rescheduleChangeRequestModal="bs-modal" id="rescheduleChangeRequestModal" class="modal fade change-modal overflow-auto" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content change-modal">
            <form #rescheduleChangeRequestModal="ngForm" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{l("Reagendar Requisição de mudança")}}
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="spinner-content" class="spinner-row">
                        <div id="spinner-loading" class="spinner-loading">
                          <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                        </div>
                    </div>
                    <div class="gmud-native-content" *ngIf="!loading">
                        <div class="p-calendar-rescheduleDate">
                            <label for="change-reschedule">Data</label>
                            <p-calendar id="change-reschedule" name="rescheduleDate" 
                                        [(ngModel)]="rescheduleDate"[showTime]="true" dateFormat="dd/mm/yy" [showIcon]="true">
                            </p-calendar>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l('Cancel')}}</button>
                    <button type="submit" class="btn btn-primary blue" [buttonBusy]="saving"
                        [disabled]="rescheduleChangeRequestModal.invalid"
                        [busyText]="l('SavingWithThreeDot')"
                        (click)="saveRescheduleChangeRequest()"><i class="fa fa-save"></i>
                        <span>{{l('Save')}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>