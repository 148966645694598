<div class="box-ambiente">
    <div class="dropdown drop-down-ambientes-icone" dropdown>
        <i class="handle flaticon-more" dropdownToggle></i>
        <ul class="dropdown-menu drop-down-ambiente" *dropdownMenu>
            <li
                class="item-menu-dropdown"
                (click)="emitEventOpenModal(idAmbiente)"
            >
                <a class="icon-mouse">{{ l("Edit") }}</a>
            </li>
            <li class="item-menu-dropdown">
                <a class="icon-mouse" (click)="emitEventOpenModalHistory(idAmbiente)">{{l('History')}}</a>
            </li>
        </ul>
    </div>

    <div class="row">
        <label class="titulo-card-abiente"> {{ nomeAmbiente }}</label>
        <label class="subtitulo-card-abiente"> {{ server }} </label>
        <label class="mandante-card"> {{ mandante }} </label>
    </div>
</div>
