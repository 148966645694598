import { Component, HostListener, Input, OnInit } from "@angular/core";
import { EnumDemandSituationLabel } from "@shared/map-number-string";
import {
    EnumSituacaoDemanda,
    EnumStatusDemanda,
    GetDemandaForEditOutput,
} from "@shared/service-proxies/service-proxies";

interface StepDemandItem {
    description?: string;
    icon?: string;
    color?: string;
    date?: any;
    user?: string;
}

interface StepGmudItem {
    description?: string;
    icon?: string;
    color?: string;
    date?: any;
    user?: string;
}

@Component({
    selector: "app-timeline",
    templateUrl: "./timeline.component.html",
    styleUrls: ["./timeline.component.scss"],
})
export class TimelineComponent implements OnInit {
    @Input() demandaForEdit: GetDemandaForEditOutput;
    stepDemand: StepDemandItem[];
    stepGmud: StepGmudItem[];
    innerWidth: number = 0;
    
    constructor() {
        this.onResize();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.innerWidth = window.innerWidth;
    }

    get getInnerWidth() {
        return this.innerWidth;
    }

    ngOnInit() {}

    removeHtmlCod(description: string) {
        return description.replace(/<[^>]*>/g, '');
    }

    getStatusDemand(enumStatusDemanda: EnumStatusDemanda): string {
        if (enumStatusDemanda == EnumStatusDemanda.Producao)
            return "Em Transporte";
        if (enumStatusDemanda == EnumStatusDemanda.Homologacao)
            return "Homologação";
        if (enumStatusDemanda == EnumStatusDemanda.Concluido)
            return "Concluído";
        if (enumStatusDemanda == EnumStatusDemanda.Desenvolvimento)
            return "Desenvolvimento";
        if (enumStatusDemanda == EnumStatusDemanda.Backlog) return "Backlog";

        return enumStatusDemanda;
    }

    getDemandSituationLabel(demandSituation: EnumSituacaoDemanda) {
        return EnumDemandSituationLabel.get(demandSituation);
    }
 
    setData(demandaForEdit: GetDemandaForEditOutput): string {
        this.stepGmud = [
            { 
                description: 'Criação', 
                color: '#2D76D1', 
                icon: 'fa fa-solid fa-user-edit',
                date: demandaForEdit?.gestaoMudancaNativa?.creationTime,
                user: demandaForEdit?.gestaoMudancaNativa?.userCreatedName, 
            }           
        ];

        if (demandaForEdit?.gestaoMudancaNativa?.status == 'Reprovado') {
            this.stepGmud.push({ 
                description: 'Reprovação', 
                color: '#C3160C', 
                icon: 'fa fa-times',
                date: demandaForEdit?.gestaoMudancaNativa?.lastModificationTime,
                user: demandaForEdit?.gestaoMudancaNativa?.userApprovedName, 
            });
        } else {
            this.stepGmud.push({ 
                description: 'Aprovação', 
                color: '#4AC30C', 
                icon: 'fa fa-solid fa-thumbs-up',
                date: demandaForEdit?.gestaoMudancaNativa?.lastModificationTime,
                user: demandaForEdit?.gestaoMudancaNativa?.userApprovedName, 
            });
        }
        
        this.stepDemand = [
            {
                description: "Backlog",
                icon: "fa fa-solid fa-code-branch",
                color: "#2D76D1",
                date: demandaForEdit?.demanda?.creationTime,
                user: demandaForEdit?.demanda?.demandMovementHistoryDtos?.userMovimentacaoBacklog,
            },
            {
                description: "Desenvolvimento",
                icon: "fa fa-solid fa-code",
                color: "#03B17E",
                date: demandaForEdit?.demanda?.demandMovementHistoryDtos?.dataMovimentacaoDev,
                user: demandaForEdit?.demanda?.demandMovementHistoryDtos?.userMovimentacaoDeV,
            },
            {
                description: "Homologação",
                icon: "fa fa-solid fa-user-cog",
                color: "#E7682B",
                date: demandaForEdit?.demanda?.demandMovementHistoryDtos?.dataMovimentacaoHml,
                user: demandaForEdit?.demanda?.demandMovementHistoryDtos?.userMovimentacaoHml,
            },
            {
                description: "Em Transporte",
                icon: "fa fa-solid fa-truck",
                color: "#FFB822",
                date: demandaForEdit?.demanda?.demandMovementHistoryDtos?.dataMovimentacaoPrd,
                user: demandaForEdit?.demanda?.demandMovementHistoryDtos?.userMovimentacaoPrd,
            },
            {
                description: "Concluído",
                icon: "fa fa-solid fa-check",
                color: "#4AC30C",
                date: demandaForEdit?.demanda?.demandMovementHistoryDtos?.dataMovimentacaoFinish,
                user: demandaForEdit?.demanda?.demandMovementHistoryDtos?.userMovimentacaoFinish,
            },
        ];

        return 'Detalhes';
    }
}
