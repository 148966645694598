<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <form #documentationForm="ngForm" (ngSubmit)="save()" autocomplete="off"
                (keydown.enter)="$event.preventDefault()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{ l("CreateOrEditDocumentation") }}</span>
                    </h4>
                    <button type="button" class="close" (click)="close()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="documentation-item-qualidade-problem">{{"DocumentacaoItemQualidadeDebitoTecnico" |
                            localize}}</label>
                        <input id="documentation-item-qualidade-problem" type="text"
                            name="documentacaoItemQualidadeProblem" class="form-control"
                            [(ngModel)]="documentation.problema" #DocumentacaoItemQualidadeProblem="ngModel" [ngClass]="{
                            'is-invalid': DocumentacaoItemQualidadeProblem.invalid,
                            'is-valid': !DocumentacaoItemQualidadeProblem.invalid
                        }" required>
                        <validation-messages [formCtrl]="DocumentacaoItemQualidadeProblem"></validation-messages>
                    </div>
                    <div class="form-group">
                        <label for="documentation-item-qualidade-class">{{"DocumentacaoItemQualidadeClass" |
                            localize}}</label>
                        <input id="documentation-item-qualidade-class" type="text" name="documentacaoItemQualidadeClass"
                            class="form-control" [(ngModel)]="documentation.classe"
                            #DocumentacaoItemQualidadeClass="ngModel" [ngClass]="{
                            'is-invalid': DocumentacaoItemQualidadeClass.invalid,
                            'is-valid': !DocumentacaoItemQualidadeClass.invalid
                        }" required>
                        <validation-messages [formCtrl]="DocumentacaoItemQualidadeClass"></validation-messages>
                    </div>
                    <div class="form-group">
                        <label for="documentation-item-qualidade-code">{{"DocumentacaoItemQualidadeCode" |
                            localize}}</label>
                        <input id="documentation-item-qualidade-code" type="text" name="documentacaoItemQualidadeCode"
                            class="form-control" [(ngModel)]="documentation.codigo"
                            #DocumentacaoItemQualidadeCode="ngModel" [ngClass]="{
                            'is-invalid': DocumentacaoItemQualidadeCode.invalid,
                            'is-valid': !DocumentacaoItemQualidadeCode.invalid
                        }" required>
                        <validation-messages [formCtrl]="DocumentacaoItemQualidadeCode"></validation-messages>
                    </div>
                    <div class="form-group">
                        <label for="documentation-item-qualidade-documentation">{{"DocumentacaoItemQualidadeDocumentation" | localize}}</label>
                        <p-editor [(ngModel)]="documentation.documentacao" name="documentacaoItemQualidadeDocumentation"
                            [style]="{'height':'300px'}"></p-editor>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default"
                        (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary blue" [buttonBusy]="saving"
                        [disabled]="!documentationForm.form.valid  || !documentation.documentacao || saving" [busyText]="l('SavingWithThreeDot')"><i
                            class="fa fa-save"></i>
                        <span>{{l("Save")}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>