import { Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnInit, Output } from '@angular/core';
import { OtherSettingsEditDto } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-microsoft-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.css']
})
export class AdComponent implements OnInit {

  @Input() microsoftAd: OtherSettingsEditDto;
  @Output() microsoftAdChange: EventEmitter<any> = new EventEmitter();
  private _microsoftAdDiffer: KeyValueDiffer<string, any>;

  _microsoftAd: OtherSettingsEditDto;

  constructor(
    private differs: KeyValueDiffers
  ) { }

  ngOnInit() {
    this._microsoftAd = this.microsoftAd;
    this._microsoftAdDiffer = this.differs.find(this._microsoftAd).create();
  }

  microsoftChanged() {
    this.microsoftAdChange.emit(this._microsoftAd)
  }

  ngDoCheck(): void {
    let changes = this._microsoftAdDiffer.diff(this._microsoftAd);
    if (changes) {
      this.microsoftChanged();
      return;
    }
    changes = this._microsoftAdDiffer.diff(this.microsoftAd);
    if (changes) {
      this._microsoftAd = this.microsoftAd;
    }
  }


}
