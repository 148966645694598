<div [@routerTransition]>
    <div class="kt-subheader kt-grid__item">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    <span>{{"Tenants" | localize}}</span>
                </h3>
                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                <span class="kt-subheader__desc">
                    {{"TenantsHeaderInfo" | localize}}
                </span>
            </div>
            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper">
                    <button *ngIf="'Pages.Tenants.Create' | permission" class="btn btn-primary" (click)="createTenant()"><i class="fa fa-plus"></i>{{"CreateNewTenant" | localize}}</button>
                </div>
            </div>
        </div>
    <div class="kt-content">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="kt-form">
                    <form (submit)="getTenants()" autocomplete="new-password">
                        <div class="form-body">

                            <div class="row mb-4">
                                <div class="col-xl-6">
                                    <label for="TenantNameOrTenancyCode">{{'TenantNameOrTenancyCode' | localize}}</label>
                                    <div class="input-group">
                                        <input id="TenantNameOrTenancyCode" [(ngModel)]="filters.filterText" name="filterText" class="form-control" placeholder="{{'SearchWithThreeDot' | localize}}" type="text">
                                        <span class="input-group-btn">
                                            <button class="btn btn-primary" type="submit"><i class="flaticon-search-1" [attr.aria-label]="l('Search')"></i></button>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <label for="EditionDropdown">{{'Edition' | localize}}</label>
                                    <edition-combo [(selectedEdition)]="filters.selectedEditionId"></edition-combo>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-xl-6">
                                    <div class="kt-checkbox-list">
                                        <label class="kt-checkbox">
                                            <input type="checkbox"
                                                   [(ngModel)]="filters.subscriptionEndDateRangeActive"
                                                   id="TenantsTable_SubscriptionEndDateRangeActive"
                                                   name="SubscriptionEndDateRangeActive"
                                                   value="true">
                                            {{'SubscriptionEndDate' | localize}}
                                            <span></span>
                                        </label>
                                    </div>
                                    <input class="form-control"
                                           name="SubscriptionEndDateRange"
                                           [attr.disabled]="!filters.subscriptionEndDateRangeActive ? 'disabled' : null"
                                           #drpSubscriptionEndDateRange="bsDaterangepicker"
                                           bsDaterangepicker
                                           dateRangePickerInitialValue
                                           readonly="readonly"
                                           [(ngModel)]="subscriptionDateRange">
                                </div>
                                <div class="col-xl-6">
                                    <div class="kt-checkbox-list">
                                        <label class="kt-checkbox">
                                            <input type="checkbox"
                                                   [(ngModel)]="filters.creationDateRangeActive"
                                                   id="TenantsTable_CreationDateRangeActive"
                                                   name="CreationDateRangeActive"
                                                   value="true">
                                            {{'CreationTime' | localize}}
                                            <span></span>
                                        </label>
                                    </div>
                                    <input class="form-control"
                                           name="CreationDateRange"
                                           [attr.disabled]="!filters.creationDateRangeActive ? 'disabled' : null"
                                           #drpCreationDateRange="bsDaterangepicker"
                                           bsDaterangepicker
                                           dateRangePickerInitialValue
                                           readonly="readonly"
                                           [(ngModel)]="creationDateRange">
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-xl-12 text-right">
                                    <button name="RefreshButton" class="btn btn-primary"><i class="fa fa-sync"></i> {{'Refresh' | localize}}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div class="col primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable
                                 (onLazyLoad)="getTenants($event)"
                                 [value]="primengTableHelper.records"
                                 rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                 [paginator]="false"
                                 [lazy]="true"
                                 [responsive]="primengTableHelper.isResponsive">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:130px">{{'Actions' | localize}}</th>
                                    <th pSortableColumn="tenancyName">
                                        {{'TenancyCodeName' | localize}}
                                        <p-sortIcon field="tenancyName"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="name">
                                        {{'TenantName' | localize}}
                                        <p-sortIcon field="name"></p-sortIcon>
                                    </th>
                                    <th>{{'Edition' | localize}}</th>
                                    <th pSortableColumn="subscriptionEndDateUtc">
                                        {{'SubscriptionEndDateUtc' | localize}}
                                        <p-sortIcon field="subscriptionEndDateUtc"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="isActive">
                                        {{'Active' | localize}}
                                        <p-sortIcon field="isActive"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="creationTime">
                                        {{'CreationTime' | localize}}
                                        <p-sortIcon field="creationTime"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td style="width: 130px">
                                        <div class="btn-group dropdown" dropdown container="body">
                                            <button dropdownToggle class="dropdown-toggle btn btn-sm btn-primary">
                                                <i class="fa fa-cog"></i><span class="caret"></span> {{"Actions" | localize}}
                                            </button>
                                            <ul class="dropdown-menu" *dropdownMenu>
                                                <li *ngIf="record.isActive">
                                                    <a href="javascript:;" *ngIf="'Pages.Tenants.Impersonation' | permission"
                                                       (click)="record.isActive && showUserImpersonateLookUpModal(record)">{{'LoginAsThisTenant' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="'Pages.Tenants.Edit' | permission"
                                                       (click)="editTenantModal.show(record.id)">{{'Edit' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="'Pages.Tenants.ChangeFeatures' | permission"
                                                       (click)="tenantFeaturesModal.show(record.id, record.name)">{{'Features' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="'Pages.Tenants.Delete' | permission"
                                                       (click)="deleteTenant(record)">{{'Delete' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="!record.isActive" (click)="bloquearLiberar(record.id, false)">{{'Activate' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="record.isActive" (click)="bloquearLiberar(record.id, true)">{{'Deactivate' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="entityHistoryEnabled"
                                                       (click)="showHistory(record)">{{'History' | localize}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        <i *ngIf="record.connectionString" class="fa fa-database" title="{{'HasOwnDatabase' | localize}}"></i>
                                        {{record.tenancyName}}
                                    </td>
                                    <td>
                                        {{record.name}}
                                    </td>
                                    <td>
                                        {{record.editionDisplayName}}
                                    </td>
                                    <td>
                                        {{ record.subscriptionEndDateUtc ? (record.subscriptionEndDateUtc | momentFormat:'L') : '-'}}
                                    </td>
                                    <td>
                                        <span *ngIf="record.isActive" class="kt-badge kt-badge--success kt-badge--inline">{{'Yes' | localize}}</span>
                                        <span *ngIf="!record.isActive" class="kt-badge kt-badge--dark kt-badge--inline">{{'No' | localize}}</span>
                                    </td>
                                    <td>
                                        {{record.creationTime | momentFormat:'L'}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{'NoData' | localize}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                         #paginator
                                         (onPageChange)="getTenants($event)"
                                         [totalRecords]="primengTableHelper.totalRecordsCount"
                                         [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </div>
            </div>
        </div>
    </div>

    <createTenantModal #createTenantModal (modalSave)="getTenants()"></createTenantModal>
    <editTenantModal #editTenantModal (modalSave)="getTenants()"></editTenantModal>
    <tenantFeaturesModal #tenantFeaturesModal></tenantFeaturesModal>
    <commonLookupModal #impersonateUserLookupModal (itemSelected)="impersonateUser($event)"></commonLookupModal>
    <entityTypeHistoryModal #entityTypeHistoryModal></entityTypeHistoryModal>
</div>
