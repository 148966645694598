import { Component, Injector, ViewEncapsulation, ViewChild, OnInit } from "@angular/core";
import { GestaoMudancaNativaServiceProxy, JanelaDeDeployServiceProxy } from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';
import { LazyLoadEvent } from 'primeng/api';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ApproveReproveChangeModalComponent } from "./approve-reprove-change-modal/approve-reprove-change-modal.component";

@Component({
    templateUrl: './aprovacoes.component.html',
    styleUrls: ['./aprovacoes.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})

export class AprovacoesComponent extends AppComponentBase implements OnInit {

    @ViewChild('approveReproveChange', { static: true }) approveReproveChange: ApproveReproveChangeModalComponent;
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    advancedFiltersAreShown: boolean = false;
    filterText: string = '';
    statusGmudFilter: number = -1;
    statusAprovadorFilter: number = -1;
    maxDataDaAvaliacaoFilter: moment.Moment;
    minDataDaAvaliacaoFilter: moment.Moment;
    demandaTituloFilter : string = '';
    userNameFilter: string = '';  
    selectedGmud: boolean = true;

    constructor(
        injector: Injector,
        private _serviceGestaoMudancaNativa: GestaoMudancaNativaServiceProxy,
        private _serviceJanelaTransporte: JanelaDeDeployServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void { }

    getStatusColor(status: string) {
        switch (status) {
            case 'Aprovado':
                return 'success';
            case 'Aprovado automaticamente':
                return 'success';
            case 'Aguardando aprovação':
                return 'warning';
            case 'Em análise':
                return 'warning';
            case 'Reprovado':
                return 'danger';
            case 'Aguardando análise':
                return 'warning';
            case 'Concluído':
                return 'success';
            default:  return 'info'; 
        }
    }
    
    resetarFiltros() {
        let momentDate: moment.Moment;
        this.filterText = '';
        this.maxDataDaAvaliacaoFilter = momentDate;
        this.minDataDaAvaliacaoFilter = momentDate;
        this.statusGmudFilter = -1;
        this.statusAprovadorFilter = -1;
        this.userNameFilter = '';
        this.demandaTituloFilter  = '';
        this.selectedGmud = true;
        this.getAvaliacoesGestaoMudancaNativa();
    }

    getAvaliacoesGestaoMudancaNativa(event?: LazyLoadEvent) {    
        if (event && this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this._serviceGestaoMudancaNativa.getListApprovalChangeManagement(
            this.filterText,
            this.statusGmudFilter,
            this.statusAprovadorFilter,
            this.maxDataDaAvaliacaoFilter,
            this.minDataDaAvaliacaoFilter,
            "",
            this.userNameFilter,
            this.primengTableHelper.getSorting(this.dataTable),
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event)
        ).subscribe(result => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            this.primengTableHelper.records = result.items;
            this.primengTableHelper.hideLoadingIndicator();
        });

        this.selectedGmud = true;
    }

    getAvaliacaoExcessao(event?: LazyLoadEvent) {      
        if (event && this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this._serviceJanelaTransporte.getTransportListForApproval(
            this.filterText,
            this.statusGmudFilter,
            this.maxDataDaAvaliacaoFilter,
            this.minDataDaAvaliacaoFilter,
            '',
            this.demandaTituloFilter ,
            this.primengTableHelper.getSorting(this.dataTable),
            this.primengTableHelper.getSkipCount(this.paginator, event),
            this.primengTableHelper.getMaxResultCount(this.paginator, event)
        ).subscribe(result => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
            this.primengTableHelper.records = result.items;
            this.primengTableHelper.hideLoadingIndicator();
        });

        this.selectedGmud = false;
    }

    getLocaleDateHour(date: moment.Moment) {
        const dateString = date ? date.toString() : "";
        return new Date(dateString).toLocaleString();
    }

    recarregar(event?: LazyLoadEvent) {
        if (!this.selectedGmud)
            this.getAvaliacoesGestaoMudancaNativa(event);
        else
            this.getAvaliacoesGestaoMudancaNativa(event);
    }

    reloadPage(ev?: any) {
        location.reload();
    }

    viewChange(id:string) {
        this.approveReproveChange.show(id, this.selectedGmud);
    }
}