import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AccountServiceProxy, ActivateEmailInput, ResolveTenantIdInput, ActivateEmailOutput } from '@shared/service-proxies/service-proxies';

@Component({
    template: `<div class="kt-login__form"><div class="alert alert-success text-center" role="alert"><div class="alert-text">{{waitMessage}}</div></div></div>`
})
export class ConfirmEmailComponent extends AppComponentBase implements OnInit {

    waitMessage: string;

    model: ActivateEmailInput = new ActivateEmailInput();
    language: string;
    constructor(
        injector: Injector,
        private _accountService: AccountServiceProxy,
        private _router: Router,
        private _activatedRoute: ActivatedRoute
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.waitMessage = this.l('PleaseWaitToConfirmYourEmailMessage');

        if (this._activatedRoute.snapshot.queryParams['Language'] != null) {
            this.language = this._activatedRoute.snapshot.queryParams['Language'];
            this.changeLanguage(this.language);
        }

        this.model.c = this._activatedRoute.snapshot.queryParams['c'];

        this._accountService.resolveTenantId(new ResolveTenantIdInput({ c: this.model.c })).subscribe((tenantId) => {
            this._accountService.activateEmail(this.model)
                .subscribe((result: ActivateEmailOutput) => {
                    if (!!result.chaveDeLiberacao) {
                        localStorage.setItem("chaveDeLiberacao", result.chaveDeLiberacao);
                    }
                    this.notify.success(this.l('YourEmailIsConfirmedMessage'), '',
                        {
                            onClose: () => {
                                this._router.navigate(['account/login']);
                            }
                        });
                }, err => this._router.navigate(['account/login']));
        });
    }

    parseTenantId(tenantIdAsStr?: string): number {
        let tenantId = !tenantIdAsStr ? undefined : parseInt(tenantIdAsStr, 10);
        if (Number.isNaN(tenantId)) {
            tenantId = undefined;
        }

        return tenantId;
    }


    changeLanguage(languageName: string): void {
        if (abp.localization.currentLanguage.name != languageName) {
            abp.utils.setCookieValue(
                'Abp.Localization.CultureName',
                languageName,
                new Date(new Date().getTime() + 5 * 365 * 86400000), // 5 year
                abp.appPath
            );
            window.location.reload();
        }
    }
}
