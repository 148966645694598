import { Component, Injector, Input, OnInit, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    AcompanhamentoBuildDto,
    AcompanhamentoBuildServiceProxy,
    Evento,
    StatusBuild,
} from "@shared/service-proxies/service-proxies";
import moment from "moment/moment";
import { ModalDirective } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";

@Component({
    selector: "app-build-tracking-modal",
    templateUrl: "./build-tracking-modal.component.html",
    styleUrls: ["./build-tracking-modal.component.css"],
})
export class BuildTrackingModalComponent
    extends AppComponentBase
    implements OnInit
{
    @Input() demandaId: string;
    @ViewChild("buildTrackingModal", { static: true }) modal: ModalDirective;
    loading: boolean = false;
    steps: AcompanhamentoBuildDto[] = [];
    interval: NodeJS.Timeout;
    constructor(
        private service: AcompanhamentoBuildServiceProxy,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {}

    show(id) {
        this.demandaId = id;
        this.getBuildTracking();
        this.interval = setInterval(this.getBuildTracking.bind(this), 5000);
        this.modal.show();
    }

    getBuildTracking() {
        this.loading = true;
        this.service
            .getAcompanhamentoBuild(this.demandaId)
            .subscribe((res: AcompanhamentoBuildDto[]) => {
                this.steps = res;
                this.loading = false;
            });
    }

    close(): void {
        clearInterval(this.interval);
        this.modal.hide();
    }

    getIconByStatus(status: StatusBuild) {
        const icons = {
            [StatusBuild.FINALIZADO]: "fa-check",
            [StatusBuild.FALHA]: "fa-times",
            [StatusBuild.PROCESSAMENTO]: "fa-sync-alt",
        };

        return icons[status];
    }

    getColorByStatus(status: StatusBuild) {
        const colors = {
            [StatusBuild.FINALIZADO]: "bg-success",
            [StatusBuild.FALHA]: "bg-danger",
            [StatusBuild.PROCESSAMENTO]: "bg-primary",
        };

        return colors[status];
    }

    getTextByEvent(step: Evento) {
        return Object.keys(Evento).find((item) => Evento[item] == step);
    }

    getLocaleDateHour(date: moment.Moment) {
        const dateString = date ? date.toString() : "";
        return new Date(dateString).toLocaleString();
    }
}
