import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ProjectsServiceProxy, ProjetoDto } from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs/operators";
import { CreateOrEditProjetoModalComponent } from "../create-or-edit-projeto-modal/create-or-edit-projeto-modal.component";
interface Project {
    id: string;
    nome: string;
    status: boolean;
}
@Component({
    selector: 'tabs-parameters-gerenciar-projetos',
    templateUrl: './tabGerenciarProjetos.component.html',
    styleUrls: ['./tabGerenciarProjetos.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})


export class tabGerenciarProjetosComponent extends AppComponentBase implements OnInit {
    @ViewChild('createEditProjetoModal', { static: true }) createEditProjetoModal: CreateOrEditProjetoModalComponent;

    emailDeployProduction: boolean = false;
    riskManagement: boolean = false;
    loading = false;
    projetostList: ProjetoDto[] = [];
    projetoDto: ProjetoDto



    constructor(
        injector: Injector,
        private _projectsServiceProxy: ProjectsServiceProxy

    ) {

        super(injector);
    }

    ngOnInit() {
        this.getParameters();
    }

    getParameters() {
        this._projectsServiceProxy.getProjectAll()
            .subscribe(res => {
                this.projetostList = res;
            });
    }


    editProjetoItem(projeto: ProjetoDto) {
        this.projetoDto = projeto;
        this.createEditProjetoModal.show(this.projetoDto, false, false);

    }

    deleteProjetoItem(projetoId: string) {
        this.message.confirm('','', isConfirmed => {
            if (isConfirmed) {
                this._projectsServiceProxy.deleteProject(projetoId)
                    .subscribe(() => {
                        this.getParameters();
                        this.notify.success(this.l('SuccessfullyRemoved'));
                    });
            }

        })
    }

    showProjetoModal(button: boolean): void {
        this.createEditProjetoModal.show(this.projetoDto, button, true);
    }

    reloadPage(ev: any) {
        this.getParameters();
    }

}