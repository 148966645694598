<form #changeRequiredForm="ngForm">
    <div class="required-approve-content">
        <div class="add-departament">
            <button class="btn btn-primary" type="submit" (click)="showProjetoModal(true)">
                <i class="fa fa-plus"></i>
                Adicionar Projeto
            </button>
        </div>
        <div id="approver-list" *ngIf="this.projetostList.length > 0; else emptyApprover">
            <div class="list-head list-row">
                <div class="list-item item"><span>Projetos</span></div>
                <div class="list-item item"><span>Data criação</span></div>
                <div class="list-item item"><span>Usuário criador</span></div>
                <div class="list-item item"><span>Status</span></div>
                <div class="list-item item-action"><span>Ação</span></div>
            </div>
            <div class="list-body list-row" *ngFor="let projeto of projetostList; let i = index">
                <div class="list-item item"><span>{{projeto.nome}}</span></div>
                <div class="list-item item">
                    <span>{{ projeto.dataCriacao | date: 'dd/MM/yyyy HH:mm' }}</span>
                </div>
                <div class="list-item item"><span>{{projeto.usuarioCriador}}</span></div>

                <div class="list-item item">
                    <span class="optional-item">{{projeto.status}}</span>
                </div>
                <div class="list-item item-action">
                    <span class="edit-item" (click)="editProjetoItem(projeto)" tooltip="{{l('Edit')}}"><i
                            class="fas fa-pen"></i></span>
                    <span class="delete-item" (click)="deleteProjetoItem(projeto.id)"
                        tooltip="{{l('Delete')}}"><i class="fas fa-trash"></i></span>
                </div>
            </div>
        </div>
        <ng-template #emptyApprover>
            <h4 class="empty">Não há projetos cadastrados</h4>
        </ng-template>
        <create-edit-projeto-modal #createEditProjetoModal (closed)="reloadPage($event)"></create-edit-projeto-modal>

    </div>
</form>