import {
    Component,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    SecurityContext,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { AppComponentBase } from "@shared/common/app-component-base";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: "videoViewModal",
    templateUrl: "./video-viewer-modal.component.html",
})
export class videoViewModalComponent extends AppComponentBase {
    @ViewChild("videoViewModal", { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    public idVideo: any;
    public url: SafeUrl;

    constructor(injector: Injector, private domSanitizer : DomSanitizer) {
        super(injector);
    }

    show(idVideo: any): void {
        this.active = true;
        this.idVideo = idVideo;
        this.url = this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + idVideo);
        this.modal.show();
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
