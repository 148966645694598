<div bsModal #portalNotificationsModal="bs-modal" id="portalNotificationsModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
    [config]="{backdrop: 'static', keyboard: false}">
    <div id="modal-block" class="modal-dialog modal-xl">
        <div id="modal-content" class="modal-content">
            <div id="modal-header-content" class="modal-header">
                <h4 id="modal-title" class="modal-title">
                    {{l("Comunicados")}}
                </h4>
            </div>
            <div id="modal-body-content" class="modal-body">
                <div id="messages-body" class="messages-body">
                    <carousel [interval]="false">
                        <slide *ngFor="let mensagem of listaMensagens; let index=index">
                            <div id="slide-content" class="slide-content">
                                <h4 id="message-title" class="message-title">{{mensagem.titulo}}</h4>
                                <p-editor [(ngModel)]="mensagem.conteudo" [style]="{'border':'none'}" readonly="false">
                                    <p-header [style]="{'border':'none'}"></p-header>
                                </p-editor>
                            </div>
                        </slide>
                    </carousel>
                </div>
            </div>
            <div id="modal-footer-content" class="modal-footer">
                <button type="button" id="close-button" class="btn btn-primary" (click)="check()">{{l("Estou Ciente")}}</button>
            </div>
        </div>
    </div>
</div>