<div class="kt-login__form" [@routerTransition]>
    <div class="kt-login__title">
        <h3 class="m-0">
            {{ 'ForgotPassword' | localize }}
        </h3>
    </div>

    <form class="kt-form" #forgotPassForm="ngForm" method="post" novalidate (ngSubmit)="save()">
        <p>
            {{"SendPasswordResetLink_Information" | localize}}
        </p>

        <div class="form-group">
            <input #emailAddressInput="ngModel" [(ngModel)]="model.emailAddress" autoFocus class="form-control placeholder-no-fix" type="text" autocomplete="new-password" placeholder="{{'EmailAddress' | localize}} *" name="emailAddress" required maxlength="256" email />
            <validation-messages [formCtrl]="emailAddressInput"></validation-messages>
        </div>

        <div class="kt-login__actions">
            <button [disabled]="saving" routerLink="/account/login" type="button" class="btn btn-light btn-elevate kt-login__btn-secondary"><i class="fa fa-arrow-left"></i> {{"Back" | localize}}</button>
            <button type="submit" class="btn btn-primary btn-elevate kt-login__btn-primary" [disabled]="!forgotPassForm.form.valid" [buttonBusy]="saving" [busyText]="l('Enviando')"><i class="fa fa-check"></i> {{"Submit" | localize}}</button>
        </div>
    </form>
</div>
