import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { CreateOrEditPeriodModalComponent } from "../createOrEditPeriod/createOrEditPeriod-modal.component";
import { ConfigDeployAutomaticoServiceProxy, PeriodoDeployDto } from "@shared/service-proxies/service-proxies";


@Component({
    selector: 'recurringPeriods',
    templateUrl: './recurringPeriods.component.html',
    styleUrls: ['./recurringPeriods.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class RecurringPeriodsComponent extends AppComponentBase implements OnInit {
    recurringPeriods: PeriodoDeployDto[] = [];
    recurringPeriod: PeriodoDeployDto;

    notRecurringPeriods: PeriodoDeployDto[] = [];
    notRecurringPeriod: PeriodoDeployDto;
    
    @ViewChild('createOrEditPeriodModal', { static: true }) createOrEditPeriodModal: CreateOrEditPeriodModalComponent;

    constructor(
        injector: Injector,
        private _configDeployAutomaticoService: ConfigDeployAutomaticoServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getDeployPeriods();
        this.getDeployPeriodsNotRecurring();
    }

    AddPeriod(): void {
        this.createOrEditPeriodModal.show(this.recurringPeriod, false);
    }

    reloadPage(ev: any) {
        this.getDeployPeriods();
        this.getDeployPeriodsNotRecurring();
    }

    getDeployPeriods() {
        this._configDeployAutomaticoService.getPeriodosDeploy()
            .subscribe(res => {
                this.recurringPeriods = res.filter(s => s.recorrente);
            });
    }

    getDeployPeriodsNotRecurring() {
        this._configDeployAutomaticoService.getPeriodosDeploy()
            .subscribe(res => {
                this.notRecurringPeriods = res.filter(s => !s.recorrente);
            });
    }

    getLocaleMonthDay(date) {
        const day = date ? date.date() : 1; 
        const month = date ? date.month() + 1 : 1; 
        
        const formattedDay = day.toString().padStart(2, '0');
        const formattedMonth = month.toString().padStart(2, '0');
        
        return `${formattedDay}/${formattedMonth}`;
    }

    getLocaleDate(date: moment.Moment) {
        if (date) {
            return date.format('DD/MM/YYYY');
        }
        return "";
    }

    editDeployPeriod(deployPeriodId : string) {
        this._configDeployAutomaticoService.getPeriodoDeploy(deployPeriodId)
            .subscribe(res => {
                this.recurringPeriod = res;
                this.createOrEditPeriodModal.show(this.recurringPeriod, true);
            });
    }

    deleteDeployPeriod(deployPeriod : PeriodoDeployDto) {
        this._configDeployAutomaticoService.deletePeriodoDeploy(deployPeriod.id)
            .subscribe(() => {
                this.notify.success(this.l('SuccessfullyRemoved'));
                this.reloadPage("");
            });
    }

    editDeployPeriodsNotRecurring(deployPeriodId : string) {
        this._configDeployAutomaticoService.getPeriodoDeploy(deployPeriodId)
            .subscribe(res => {
                this.notRecurringPeriod = res;
                this.createOrEditPeriodModal.show(this.notRecurringPeriod, true);
            });
    }

    deleteDeployPeriodsNotRecurring(i: number, notRecurringPeriod : PeriodoDeployDto) {
        this._configDeployAutomaticoService.deletePeriodoDeploy(notRecurringPeriod.id)
            .subscribe(() => {
                this.notify.success(this.l('SuccessfullyRemoved'));
                this.reloadPage("");
            });
    }
}