import { Component, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ConfigDeployAutomaticoServiceProxy, ParametrosDeployDto } from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs/operators";

@Component({
    selector: 'tabs-parameters',
    templateUrl: './tabParameters.component.html',
    styleUrls: ['./tabParameters.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class TabParametersComponent extends AppComponentBase implements OnInit {
    emailDeployProduction: boolean = false;
    riskManagement: boolean = false;
    loading = false;

    constructor(
        injector: Injector,
        private _configDeployAutomaticoService: ConfigDeployAutomaticoServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getParameters();
    }

    getParameters() {
        this._configDeployAutomaticoService.getParametros()
            .subscribe(res => {
                this.emailDeployProduction = res.enviarEmailNoDeploy;
                this.riskManagement = res.ativarAvaliacaoDeRisco;
            });
    }

    saveParameters() {
        let parametrosDeployDto = new ParametrosDeployDto;
        parametrosDeployDto.ativarAvaliacaoDeRisco = this.riskManagement;
        parametrosDeployDto.enviarEmailNoDeploy = this.emailDeployProduction;

        this.loading = true;
        this._configDeployAutomaticoService.insertUpdateParametros(parametrosDeployDto)
        .pipe(finalize(() => this.loading = false))
        .subscribe(x => { 
            this.getParameters();
            this.notify.success(this.l('SavedSuccessfully'));
        });  
    }
}