<div bsModal #createModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="createModal"
    aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-backdrop fade in show" style="z-index: 1051"></div>
    <div class="modal-dialog modal-lg" style="z-index: 1052">
        <div class="modal-content">
            <form #userForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{"CreateNewSapUser" | localize}}
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="UserId">{{"SapUserId" | localize}} *</label>
                        <input id="id" #userId="ngModel" type="text" name="UserId" class="form-control"
                            [(ngModel)]="user.usuario" required maxlength="12"
                            [ngClass]="{'is-invalid': userId.invalid, 'is-valid': !userId.invalid}" />
                        <validation-messages [formCtrl]="userId"></validation-messages>
                    </div>
                    <div class="form-group">

                        <label for="UserModel">{{"SapUserModel" | localize}} *</label>
                        <ng-select string [items]="users" [multiple]="false" required [(ngModel)]="sapUserModel"
                            name="UserModel" #userModel="ngModel" (change)="changeUserModel($event)"
                            [ngClass]="{'is-invalid': userModel.invalid, 'is-valid': !userModel.invalid}">

                            <ng-template ng-label-tmp let-item="item">
                                <div class="p-xs">
                                    <span class="ng-value-label ng-star-inserted">
                                        {{item.label}}
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                <div>{{item.label}}</div>
                            </ng-template>
                        </ng-select>
                        <validation-messages [formCtrl]="userModel"></validation-messages>

                    </div>
                    <div class="form-group">
                        <label for="DeveloperKey">{{"DeveloperKey" | localize}}</label>
                        <input id="key" #developerKey="ngModel" type="text" name="DeveloperKey" class="form-control"
                            [(ngModel)]="user.developerKey"
                            [ngClass]="{'is-invalid': developerKey.invalid, 'is-valid': !developerKey.invalid}" />
                        <validation-messages [formCtrl]="developerKey"></validation-messages>
                    </div>
                    <div class="form-group">
                        <label for="InitialPassword">{{"InitialPassword" | localize}} *</label>
                        <input id="password" #initialPassword="ngModel" type="password" name="InitialPassword"
                            class="form-control" [(ngModel)]="user.password" required
                            [ngClass]="{'is-invalid': initialPassword.invalid, 'is-valid': !initialPassword.invalid}" />
                        <validation-messages [formCtrl]="initialPassword"></validation-messages>
                    </div>
                    <div class="form-group">
                        <label for="DevopsUserLink">{{"DevopsUserLink" | localize}}</label>
                        <ng-select [(ngModel)]="usersSelected" [items]="devopsUsers" [multiple]="true"
                            name="DevopsUserLink" #devopsUserLink="ngModel" (change)="changeUsersSelected($event)">
                            <ng-template ng-label-tmp let-item="item">
                                <div class="p-xs">
                                    <span class="ng-value-icon left" class="align-center" (click)="clear(item)"
                                        aria-hidden="true">×</span>
                                    <span class="ng-value-label ng-star-inserted">
                                        <div class="text-sm">{{item.label}}</div>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                <div class="text-sm">{{item.label}}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <label for="Name">{{"Name" | localize}}</label>
                        <input id="name" #name="ngModel" type="text" name="Name" class="form-control"
                            [(ngModel)]="user.nome" />
                    </div>
                    <div class="form-group">
                        <label for="Surname">{{"Surname" | localize}}</label>
                        <input id="surname" #surname="ngModel" type="text" name="Surname" [required]="surnameIsRequired"
                            class="form-control" [(ngModel)]="user.sobrenome"
                            [ngClass]="{'is-invalid': surname.invalid, 'is-valid': !surname.invalid}" />
                        <validation-messages [formCtrl]="surname"></validation-messages>
                    </div>
                    <div class="form-group">
                        <label for="Email">{{"Email" | localize}}</label>
                        <input id="email" #email="ngModel" type="text" name="Email" class="form-control"
                            [(ngModel)]="user.email" />
                    </div>
                    <div>
                        <h6>{{ 'Mandantes' | localize}} *</h6>
                        <div class="d-flex align-items-center ">
                            <div class="form-group col-5">
                                <label for="ambiente-usuario-sap">{{ l("Ambiente") }} </label>
                                <select class="form-control" name="AmbienteUsuarioSap" id="ambiente-usuario-sap"
                                    [(ngModel)]="mandanteModelo.ambiente">
                                    <option *ngFor="let ambiente of mandantesAmbiente" [value]="ambiente.ambienteId">
                                        {{ ambiente.ambiente }}</option>
                                </select>
                            </div>
                            <div class="form-group col-5">
                                <label for="mandante-usuario-sap">{{ l("Mandante") }} </label>
                                <select [disabled]="!mandanteModelo.ambiente" class="form-control"
                                    name="mandanteUsuarioSap" id="mandante-usuario-sap"
                                    [(ngModel)]="mandanteModelo.mandante">
                                    <option *ngFor="let mandante of getMandantesLista(mandanteModelo.ambiente)"
                                        [value]="mandante.value">
                                        {{ mandante.label }}</option>
                                </select>
                            </div>
                            <button type="button" class="col-2 btn btn-primary blue customized-button"
                                (click)="adicionarMandante()" [disabled]="!mandanteModelo.mandante || !mandanteModelo.ambiente"> {{ l("Save") }} </button>
                        </div>
                        <p-table [value]="mandantes">
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr class="d-flex">
                                    <td class="col-1">
                                        <i class="p-2 fa fa-trash text-danger clickable"
                                            (click)="deletarMandante(record.mandante, record.ambiente)"></i>
                                    </td>
                                    <td class="col-7">
                                        {{ getLabel(record.ambiente, mandantesAmbiente, 'ambienteId', 'ambiente') }}
                                    </td>
                                    <td class="col-4">
                                        {{'Mandante' | localize}} {{ getLabel(record.mandante,
                                        getMandantesLista(record.ambiente), 'value', 'label') }}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{"Cancel" |
                        localize}}</button>
                    <button type="submit" class="btn btn-primary" [disabled]="!userForm.form.valid || mandantes.length == 0"
                        [buttonBusy]="saving" [busyText]="'SavingWithThreeDot' | localize"><i class="fa fa-save"></i>
                        <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>