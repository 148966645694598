import { Component, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AvaliacaoDeAmbienteServiceProxy, ObjetosViewDto } from "@shared/service-proxies/service-proxies";
import { TreeNode } from "primeng/api";

@Component({
    selector: 'avaliacao-ambiente-informacao-objeto',
    templateUrl: './informacaoObjeto.component.html',
    styleUrls: ['./informacaoObjeto.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})

export class AvaliacaoAmbienteInformacaoObjetoComponent extends AppComponentBase implements OnInit {

    objectName: string;
    objectSize: string;
    objectTecName: string;
    objectLastVer: number;

    objectDebs: number;
    objectDemands: number;
    objectTecDebs: number;
    objectTime: number;

    creationTime: string;

    files: TreeNode[];
    filesResult: ObjetosViewDto[];
    filesResultChildren: ObjetosViewDto[];
    cols: any[];
    loading: boolean;
    totalRecords: number;

    debsValue : number;
    demandsValue : number;

    items: any[] = [];

    days: number;
    restDays: number;
    hours: number;
    minutes: number;

    objectFather: boolean;

    constructor(
        injector: Injector,
        private _avaliacaoDeAmbienteService: AvaliacaoDeAmbienteServiceProxy,
        private router: Router,
        private route: ActivatedRoute
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.objectName = this.route.snapshot.paramMap.get('name');
        this.objectSize = this.route.snapshot.paramMap.get('size');

        this.items.push(
            {label: this.objectName, size: this.objectSize}
        );

        this.objectFather = false;
        this.getObjectDetails();
        this.getCreationTime();

        this.cols = [
            { field: 'name', header: 'Tipo' },
            { field: 'size', header: 'Objeto' },
            { field: 'type', header: 'Débitos técnicos' }
        ];

        this.getObjectChildrenToTable();
    }

    //Cria o menu de navegação de pastas dos objetos
    createBreadcrumbs(item: any, event, i: number){
        this.router.navigate(['/app/main/avaliacaoAmbiente/inventario/informacaoObjeto/' + item.label + '/' + item.size]);
        this.objectName = item.label;
        this.objectSize = item.size;
        this.getObjectDetails();
        this.getObjectChildrenToTable();
        this.loadNodes(event);

        for (let index = i; index < this.items.length; index++) {
            this.items.pop();    
        }
     }

    //Cria os detalhes dos objetos para preencher os campos
    getObjectDetails(){
        setTimeout(() => {
            this._avaliacaoDeAmbienteService.getDetalhesObjeto(this.objectSize, this.objectName).subscribe(result => {
                //Preenche os dados do objeto
                this.objectName = result.tipo
                this.objectSize = result.nome
                this.objectTecName = result.nomeTecnico;
                this.objectLastVer = result.ultimaVersao;
    
                //Preenche o status do objeto
    
                //Traz o valor positivo ou negativo para manipular a classe no html
                this.debsValue = result.debitos;
                this.demandsValue = result.demandas;
    
                this.objectDebs = Math.abs(result.debitos);
                this.objectDemands = Math.abs(result.demandas);
                this.objectTecDebs = result.quantidadeDebitos;
                this.objectTime = result.tempoCorrecao;
                this.objectFather = result.objetoPai;
            });
        }, 10000);
    }

    //Carrega os objetos filhos na tabela de objetos incorporados
    loadNodes(event) {
        this.loading = true;

        setTimeout(() => {
            this.loading = false;
            this.files = [];
        
            if(event.rows == undefined){
                event.rows = 10;
                event.first = 0;
            }

            for(let i = 0; i < event.rows; i++) {
                let node = {
                    data: {  
                        name: this.filesResult[event.first + i].tipoObjeto,
                        size: this.filesResult[event.first + i].nomeObjeto,
                        type: this.filesResult[event.first + i].debitosTecnicos
                    },
                    leaf: false
                };

                this.files.push(node);
            }
        }, 10000);
    }

    //Expande um objeto da lista de objetos filhos
    async onNodeExpand(event) {
        this.loading = true;
        this.filesResultChildren = [];
        await this.getObjectChildren(event.node.data.size, event.node.data.name);
        setTimeout(() => {
            const node = event.node;
            let childrens: any[] = [];
            for(let i = 0; i < this.filesResultChildren.length; i++) {
                let node = {
                    data: {  
                        name: this.filesResultChildren[i].tipoObjeto,
                        size: this.filesResultChildren[i].nomeObjeto,
                        type: this.filesResultChildren[i].debitosTecnicos
                    },
                    leaf: false
                };
                childrens.push(node);
            }

            //Cria camadas de filhos de cada objeto aberto na árvore da tabela
            node.children = childrens;
            this.loading = false;

            this.files = [...this.files];
        }, 10000);
    }

    //Seta os items da tabela de objetos incorporados como pai para poder carregar seus respectivos filhos
    getObjectUpper() {
        try {
             this._avaliacaoDeAmbienteService.getObjetosSuperiores('','','','',0, 99999).subscribe(result => {
                this.filesResult = result.items;
                this.totalRecords = result.items.length;
            });
        } catch (error) {
            console.log(error);
        }
    }

    //Preenche a tabela com os items incorporados do objeto selecionado da página de inventário
    getObjectChildrenToTable() {
        try {
             this._avaliacaoDeAmbienteService.getObjetosIncorporados('', this.objectSize, this.objectName, '', 0, 99999).subscribe(result => {
                this.filesResult = result.items;
                this.totalRecords = result.items.length;
            });
        } catch (error) {
            console.log(error);
        }
    }

    //Carrega a próxima camada de itens incorporados da tabela quando um objeto é selecionado
    async getObjectChildren(FatherObjectName: string, FatherObjectType: string) {
        try {
                return this._avaliacaoDeAmbienteService.getObjetosIncorporados('', FatherObjectName, FatherObjectType, '', 0, 99999).toPromise().then(result => {
                this.filesResultChildren = result.items;
            });
        } catch (error) {
            console.log(error);
        }
    }

    //Atualiza a página com as informações do objeto selecionado e a tabela com seus itens incorporados
    goToObjectPage(event){
        this.router.navigate(['/app/main/avaliacaoAmbiente/inventario/informacaoObjeto/' + event.node.data.name + '/' + event.node.data.size]);

        this.objectName = event.node.data.name;
        this.objectSize = event.node.data.size;

        this.items.push(
            {label: this.objectName, size: this.objectSize}
        );

        this.cols = [
            { field: 'name', header: 'Tipo' },
            { field: 'size', header: 'Objeto' },
            { field: 'type', header: 'Débitos técnicos' }
        ];
        this.getObjectDetails();
        this.getObjectChildrenToTable();
        this.loadNodes(event)
    }

    getCreationTime(){
        this._avaliacaoDeAmbienteService.getUltimaAtualizacaoAgendamento().subscribe(result => {
            this.creationTime = this.getLocaleDateHour(result.ultimaAtualizacao);
        })
    }

    getLocaleDateHour(date: moment.Moment) {
        const dateString = date ? date.toString() : "";
        return new Date(dateString).toLocaleString();
    }

    getTecDebs() {
        if(!this.objectFather){
            this.router.navigate(['/app/main/avaliacaoAmbiente/inventario/informacaoObjeto/debitosTecnicos/' + this.objectName + '/' + this.objectSize]);
        }
    }

    timeConvert(num) { 
        num = this.objectTime;
        
        let days = Math.floor(num / 1440);
        let restDays = Math.floor(num % 1440);
        let hours = Math.floor(restDays / 60);
        let minutes = Math.floor(num % 60);

        if(days > 0 && hours < 24 && minutes < 60) {
            return days + "d" + hours + "h" + minutes + "min";
        }
        
        if(days < 1 && hours > 0 && hours < 24 && minutes < 60){
            return hours + "h" + minutes + "min";
        }
        
        return minutes + "min";
    }
}