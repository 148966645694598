import { Component, EventEmitter, Injector, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/common/app-component-base";
import { RetrabalhoPerfilUsuarioServiceProxy } from "@shared/service-proxies/service-proxies";
@Component({
    selector: 'retrabalho',
    templateUrl: './retrabalho.component.html',
    styleUrls: ['./retrabalho.component.less'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None
})



export class RetrablhoComponent extends AppComponentBase implements OnInit {
    rework: string;


    @Output()  useAd: EventEmitter<void> = new EventEmitter<void>();


    constructor(
        injector: Injector,
        private _route: Router,
        private _RetrabalhoPerfilUsuarioServiceProxy: RetrabalhoPerfilUsuarioServiceProxy
    ) {
        super(injector);
    };

    ngOnInit(): void {
        this.getReduction();
    };

    getReduction(): void{
        this._RetrabalhoPerfilUsuarioServiceProxy.getUserReworkByTenant(this.appSession.userId, this.appSession.tenantId).subscribe(result =>{
            this.rework = result;
        });
    };
}