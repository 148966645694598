<div bsModal #createEditApproverModal="bs-modal" id="crateEditApproverModal" class="modal fade change-modal overflow-auto" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content change-modal">
            <form #createEditApproverForm="ngForm" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{l("ManageApproversNative")}}
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                    <div class="gmud-native-content" *ngIf="!loading">
                        <div class="form-group">
                            <div class="label-departament">
                                <label for="required">{{l("DepartamentNative")}}</label>
                                <span class="radio-box"><input id="required-radio" type="radio" name="required" (click)="selectRadioRequired()" [(ngModel)]="select" value="Required"><span>{{l("RequiredConditionNative")}}</span></span>
                                <span class="radio-box"><input id="optional-radio" type="radio" name="optional" (click)="selectRadioOptional()" [(ngModel)]="select" value="Optional"><span>{{l("OptionalConditionNative")}}</span></span>
                            </div>
                            <input [(ngModel)]="departament" id="required-departament" type="text" name="departament" 
                                   #departamentName="ngModel" class="form-control" maxlength="50" 
                                   [ngClass]="{ 'is-invalid': departamentName.invalid, 'is-valid': !departamentName.invalid }" required>
                                   <validation-messages [formCtrl]="departamentName"></validation-messages>
                        </div>
                        <div class="form-group optional-approver approver-list">
                            <label for="approver">{{l("Módulo SAP")}}</label>
                            <ng-select [items]="modules" [multiple]="true" [(ngModel)]="modulesSelected" name="modulesSelected" 
                                       #optionalApprover="ngModel">
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="box-selected">
                                        <span class="ng-value-label ng-star-inserted">
                                            <b>{{item.label}}</b>
                                        </span>
                                        <span class="delete-box" (click)="clearModules(item)" aria-hidden="true">×</span>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                    <div class="p-2">
                                        <b>{{item.label}}</b>
                                        <div style="font-size: 12px;">{{item.cargo}}</div>
                                        <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                    </div>
                                </ng-template>
                            </ng-select>
                            <div>
                                Caso seja informado o módulo SAP, o mesmo será vinculado na aprovação da demanda.
                            </div>
                        </div>
                        <div class="form-group required-approver approver-list">
                            <label for="approver">{{l("RequiredApproversNative")}}</label>
                            <ng-select [items]="users" [multiple]="true" [(ngModel)]="usersSelected" name="usersSelected" 
                                       #requiredApprover="ngModel"
                                       [required]="usersOptionalSelected.length == 0">
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="box-selected">
                                        <span class="ng-value-label ng-star-inserted">
                                            <b>{{item.label}}</b>
                                            <div class="box-content">
                                                <div class="content-item item-cargo">{{item.cargo}}</div>
                                                <div class="content-item item-fornecedor">{{item.fornecedor}}</div>
                                            </div>
                                        </span>
                                        <span class="delete-box" (click)="clear(item)" aria-hidden="true">×</span>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                    <div class="p-2">
                                        <b>{{item.label}}</b>
                                        <div style="font-size: 12px;">{{item.cargo}}</div>
                                        <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="form-group optional-approver approver-list">
                            <label for="approver">{{l("OptionalApproversNative")}}</label>
                            <ng-select [items]="users" [multiple]="true" [(ngModel)]="usersOptionalSelected" name="usersOptionalSelected" 
                                       #optionalApprover="ngModel"
                                       [required]="usersSelected.length == 0">
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="box-selected">
                                        <span class="ng-value-label ng-star-inserted">
                                            <b>{{item.label}}</b>
                                            <div class="box-content">
                                                <div class="content-item item-cargo">{{item.cargo}}</div>
                                                <div class="content-item item-fornecedor">{{item.fornecedor}}</div>
                                            </div>
                                        </span>
                                        <span class="delete-box" (click)="clearOptional(item)" aria-hidden="true">×</span>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                    <div class="p-2">
                                        <b>{{item.label}}</b>
                                        <div style="font-size: 12px;">{{item.cargo}}</div>
                                        <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="form-group">
                            <label class="kt-checkbox">
                                <input id="change-manager-native-enabled" type="checkbox" name="userApprovesEverything"
                                [(ngModel)]="userApprovesEverything"> 
                                    {{'Um usuário da lista aprova para todos os opcionais'}}
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary blue"
                        (click)="addDepartament()"
                        [disabled]="createEditApproverForm.invalid"
                        [buttonBusy]="saving"
                        [busyText]="'SavingWithThreeDot' | localize">
                        <i class="fa fa-save"></i>
                        <span>{{l("Save")}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>