import { Component, EventEmitter, Injector, Input, OnInit, Output, KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { AdUserDto, AdUserServiceProxy, ContextType, LdapSettingsEditDto, PagedResultDtoOfGetAdUserForViewDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { finalize } from 'rxjs/operators';
import { TokenService } from 'abp-ng2-module';

@Component({
  selector: 'app-ldap',
  templateUrl: './ldap.component.html',
  styleUrls: ['./ldap.component.css']
})
export class LdapComponent extends AppComponentBase implements OnInit {

  @Input() ldap: LdapSettingsEditDto;
  @Output() ldapChange: EventEmitter<LdapSettingsEditDto> = new EventEmitter();
  private _ldapDiffer: KeyValueDiffer<string, any>;

  
  _ldap: LdapSettingsEditDto;

  totalCount: number = 0;
  isMultiTenancyEnabled: boolean = this.multiTenancy.isEnabled;
  adUsers: any[];
  filter = "";
  loading = false;

  get contextType() {
    return ContextType;
  }

  get hasUsersSelected(): boolean {
    return this.usersToSend && this.usersToSend.length > 0
  }

  get usersToSend(): AdUserDto[] {
    const adUsers = this.adUsers || [];
    return adUsers.filter(item => item.check);
  }

  get usersToShow() {
    const filtred = this.adUsers.filter(item => {
      let values = [item.id.toString(),item.employeedId, item.name, item.surname, item.samAccountName, item.domain, item.emailAddress];
      return values.some(word => !!word? word.toLowerCase().includes(this.filter.toLowerCase()): word)
      
    })
    return filtred;
  }

  get hasUsers(): boolean {
    return this.adUsers && this.adUsers.length > 0
  }

  set selectAll(value: boolean) {
    this.adUsers.forEach(element => {
      element.check = value;
    });
  }

  constructor(
    injector: Injector,
    private _tokenService: TokenService,
    private _adUserServiceProxy: AdUserServiceProxy,
    private differs: KeyValueDiffers
  ) {
    super(injector);
  }

  ngOnInit() {
    this._ldap = this.ldap;
    this._ldapDiffer = this.differs.find(this._ldap).create();
  }

  ldapChanged(changes: KeyValueChanges<string, any>) {
    this.ldapChange.emit(this._ldap)
  }

  ngDoCheck(): void {
    let changes = this._ldapDiffer.diff(this._ldap);
    if (changes) {
      this.ldapChanged(changes);
      return;
    }
    changes = this._ldapDiffer.diff(this.ldap);
    if (changes) {
      this._ldap = this.ldap;
    }
  }

  searchAdUsers(filter: string | undefined, sorting: string | undefined, skipCount: number | undefined) {

    const { domain, userName, password, contextType, container } = this.ldap;

    this.loading = true;
    this._adUserServiceProxy.getAll(filter, domain, userName, password, contextType || "", container || "", sorting, skipCount, 10)
      .pipe(finalize(() => { this.loading = false; }))
      .subscribe((result: PagedResultDtoOfGetAdUserForViewDto) => {
        this.adUsers = result.items.map(item => {
          return { ...item.adUserDto, check: false }
        });
        this.totalCount = result.totalCount;
      });
  }

  sendAdUsers() {
    if (!this.hasUsersSelected) return
    this._adUserServiceProxy.createOrEdit(this.usersToSend).subscribe()
  }
  
}
