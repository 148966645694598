<form (ngSubmit)="saveAll()" #changeForm="ngForm" id="changeManager">
    <div [@routerTransition] class="overflow-auto">
        <div class="kt-subheader kt-grid__item">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    <span>{{l("ChangeManagerTitle")}}</span>
                </h3>
                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                <span class="kt-subheader__desc">
                    {{l("ChangeManagerInfo")}}
                </span>
            </div>
        </div>
        <div class="kt-content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__body">
                    <div class="kt-subheader__toolbar">
                        <div class="kt-subheader__wrapper">
                            <button class="btn btn-primary" type="submit" [disabled]="changeForm.invalid">
                                <i class="fa fa-save"></i>
                                {{l("SaveAll")}}
                            </button>
                        </div>
                    </div>
                    <tabset>
                        <tab heading="{{l('GmudQametrikNative')}}">
                            <div class="form-group">
                                <label for="change-manager-native-enabled" class="kt-checkbox">
                                    <input id="change-manager-native-enabled" type="checkbox" name="ChangeManagerIsEnabledNative"
                                        [(ngModel)]="changeManagerSettings.isEnabledNative" [disabled]="changeManagerSettings.isEnabled"> {{l('DefaultGmudQametrikNative')}}
                                    <span></span>
                                </label>
                                <br>
                                <div class="form-group" *ngIf="changeManagerSettings.isEnabledNative">
                                    <label for="Lista_Email_Gmud_Nativa">Email para comunicação</label>
                                    <ng-select [items]="changeManagerSettings.emailsCommunication" [multiple]="true"
                                      #Lista_Email_Gmud_Nativa="ngModel" [(ngModel)]="changeManagerSettings.emailsCommunication"
                                      [addTag]="true" name="listaEmailGmudNativa" 
                                      [ngClass]="{'is-invalid': Lista_Email_Gmud_Nativa.invalid, 'is-valid': !Lista_Email_Gmud_Nativa.invalid}"
                                      (change)="validarListaEmails()">
                                    </ng-select>
                                </div>
                                <!-- Solicitado para comentar pelo Lucas - Desenvolvimento em testes ainda do backend
                                <div class="row" *ngIf="changeManagerSettings.isEnabledNative">
                                    <div class="col-10">
                                        <label for="EmailApprovalPending">Email para aviso de aprovação pendente - GMUD</label>
                                        <ng-select [items]="changeManagerSettings.emailsCommunication" [multiple]="true"
                                        #EmailApprovalPending="ngModel" [(ngModel)]="getDadosGmudNativaPendenteDto.usersEmails"
                                        [addTag]="true" name="emailApprovalPending" 
                                        [ngClass]="{'is-invalid': EmailApprovalPending.invalid, 'is-valid': !EmailApprovalPending.invalid}"
                                        (change)="validarListaEmails()">
                                        </ng-select>
                                        <div>
                                            Informe os emails que serão avisados sobre as aprovações pendentes da GMUD
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <label for="change-hour">Intervalo de tempo (horas)</label>
                                        <input id="change-hour" type="number" name="changeHour" class="form-control"
                                            [(ngModel)]="getDadosGmudNativaPendenteDto.timeInterval" #ChangeHour="ngModel" [ngClass]="{
                                                'is-invalid': ChangeHour.invalid,
                                                'is-valid': !ChangeHour.invalid
                                            }">
                                    </div>
                                </div>
                                -->
                            </div>
                            <form #changeRequiredForm="ngForm">
                                <div class="required-approve-content" *ngIf="changeManagerSettings.isEnabledNative">
                                    <div class="add-departament">
                                        <button class="btn btn-primary" type="submit" (click)="showDepartamentModal(true)">
                                            <i class="fa fa-plus"></i>
                                            {{l('AddDepartamentNative')}}
                                        </button>
                                    </div>
                                    <div id="approver-list" *ngIf="this.departamentList.length > 0; else emptyApprover">
                                        <div class="list-head list-row">
                                            <div class="list-item item-departament"><span>{{l('DepartamentNative')}}</span></div>
                                            <div class="list-item item-condition"><span>{{l('ConditionNative')}}</span></div>
                                            <div class="list-item item-action"><span>{{l('ActionNative')}}</span></div>
                                        </div>
                                        <div class="list-body list-row" *ngFor="let departament of departamentList; let i = index">
                                            <div class="list-item item-departament"><span>{{departament.descricao}}</span></div>
                                            <div class="list-item item-condition">
                                                <span class="optional-condition" *ngIf="departament.opcional == true">{{l('OptionalConditionNative')}}</span>
                                                <span class="required-condition" *ngIf="departament.opcional == false">{{l('RequiredConditionNative')}}</span>
                                            </div>
                                            <div class="list-item item-action">
                                                <span class="edit-item" (click)="editDepartamentItem(departament.id)" tooltip="{{l('Edit')}}"><i class="fas fa-pen"></i></span>
                                                <span class="delete-item" (click)="deleteDepartamentItem(i, departament)" tooltip="{{l('Delete')}}"><i class="fas fa-trash"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #emptyApprover>
                                        <h4 class="empty">{{l('EmptyDepartaments')}}</h4>
                                    </ng-template>
                                </div>
                            </form>
                        </tab>
                        <tab heading="Níveis de Aprovação" [disabled]="!changeManagerSettings.isEnabledNative">
                            <div class="form-group">
                                <div class="required-approve-content">
                                    <div class="form-group col-6">
                                        <label for="modules">{{"Módulo SAP"}}</label>
                                        <div class="d-flex align-items-center">
                                            <ng-select [items]="modules" [multiple]="false" [(ngModel)]="modulesSelected" name="modulesSelected" 
                                                       #optionalApprover="ngModel" class="flex-grow-1" (ngModelChange)="getDepartments($event)">
                                                <ng-template ng-label-tmp let-item="item">
                                                    <div class="box-selected">
                                                        <span class="ng-value-label ng-star-inserted module">
                                                            <b>{{item.label}}</b>
                                                        </span>
                                                    </div>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                                    <div class="p-2 module">
                                                        <b>{{item.label}}</b>
                                                        <div style="font-size: 12px;">{{item.cargo}}</div>
                                                        <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div id="level-list" class="form-group">
                                        <p-table [value]="approvalHierarchy" dataKey="id">
                                            <ng-template pTemplate="header">
                                                <tr class="list-head list-row">
                                                    <th class="list-item item-departament">Módulo</th>
                                                    <th class="list-item item-condition">Departamento</th>
                                                    <th class="list-item item-level">Nível de Aprovação</th>
                                                    <th class="list-item item-action">Ação</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-approval let-editing="editing">
                                                <tr class="list-body list-rowResult">
                                                    <td class="list-item item-departament">{{ approval.nameModulo }}</td>
                                                    <td class="list-item item-condition">{{ approval.nameDepartment }}</td>
                                                    <td class="list-item item-level" [pEditableColumn]="approval.approvalLevel" pEditableColumnField="approvalLevel">
                                                        <p-cellEditor>
                                                            <ng-template pTemplate="input">
                                                                <input pInputText [(ngModel)]="approval.approvalLevel" />
                                                            </ng-template>
                                                            <ng-template pTemplate="output">
                                                                {{ approval.approvalLevel }}
                                                            </ng-template>
                                                        </p-cellEditor>
                                                    </td>
                                                    <td class="list-item item-action">
                                                        <span class="delete-item" (click)="deleteApprovalHierarchy(approval)" tooltip="{{l('Delete')}}"><i class="fas fa-trash"></i></span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="GMUD - Externa" [disabled]="changeManagerSettings.isEnabledNative">
                            <div class="form-group">
                                <label for="change-manager-external-enabled" class="kt-checkbox">
                                    <input id="change-manager-external-enabled" type="checkbox" name="ChangeManagerIsEnabledExternal"
                                        [(ngModel)]="externalGmudDto.active" [disabled]="changeManagerSettings.isEnabledNative"> {{l('Definir GMUD Externa como padrão')}}
                                    <span></span>
                                </label>
                                <br>
                                <div *ngIf="externalGmudDto.active">
                                    <div class="form-group">
                                        <label for="typeIntegration">{{l('Tipo de integração externa')}}</label>
                                        <p-dropdown Id="typeIntegration" class="form-group typeIntegration-content" [options]="types" 
                                                    (onChange)="onValueChange($event)" [autoDisplayFirst]="false" [showClear]="true"
                                                    optionLabel="name" optionValue="value" [(ngModel)]="externalGmudDto.typeExternalApproval">
                                        </p-dropdown>
                                    </div>
                                    <div class="form-group">
                                        <label for="ComunicationEmail">Email para comunicação</label>
                                        <ng-select [items]="comunicationEmail" [multiple]="true"
                                        #EmailApprovalPending="ngModel" [(ngModel)]="comunicationEmail"
                                        [addTag]="true" name="comunicationEmail">
                                        </ng-select>
                                    </div>
                                    <div *ngIf="isServiceNow">
                                        <div class="form-group">
                                            <label for="integration-user">{{l('IntegrationUser')}}</label>
                                            <input id="integration-user" type="text" name="integrationUser" class="form-control"
                                                [(ngModel)]="externalGmudDto.userIntegration" maxlength="50">
                                        </div>
                                        <div class="form-group">
                                            <label for="password">{{l('IntegrationPassword')}}</label>
                                            <input id="password" type="password" name="password" class="form-control"
                                                [(ngModel)]="externalGmudDto.passwordIntegration" autocomplete="new-password"
                                                #Password="ngModel">
                                        </div>
                                        <div class="form-group">
                                            <label for="create-change">{{l('CreateChange')}}</label>
                                            <input id="create-change" type="text" name="createChange" class="form-control"
                                                [(ngModel)]="externalGmudDto.createChange">
                                        </div>
    
                                        <div class="form-group">
                                            <label for="get-search-change">{{l('GetSearchChange')}}</label>
                                            <input id="get-search-change" type="text" name="getSearchChange" class="form-control"
                                                [(ngModel)]="externalGmudDto.createTask">
                                        </div>
                                        <div class="form-group">
                                            <label for="get-group">{{l('AssignmentGroup')}}</label>
                                            <input id="get-group" type="text" name="getGroup" class="form-control"
                                                [(ngModel)]="externalGmudDto.updateStatesChange">
                                        </div>
                                        <div class="form-group">
                                            <label for="get-ic-sap">{{l('ConfigurationItem')}}</label>
                                            <input id="get-ic-sap" type="text" name="getIcSap" class="form-control"
                                                [(ngModel)]="externalGmudDto.updateStatesTask">
                                        </div>
                                        <div class="form-group">
                                            <label for="change-integration-approval" class="kt-checkbox">
                                                <input id="change-integration-approval" type="checkbox" name="ChangeManagerIsIntegrationApproval"
                                                    [(ngModel)]="externalGmudDto.activeApprovers"> {{l('Utiliza aprovadores externos')}}
                                                <span></span>
                                            </label>
                                        </div>
                                        <div *ngIf="externalGmudDto.activeApprovers">
                                            <div class="form-group">
                                                <label for="change-frequency">{{l('ChangeFrequency')}}</label>
                                                <input id="change-frequency" type="number" name="changeFrequency" class="form-control"
                                                    [(ngModel)]="externalGmudDto.monitoringInterval" required min="0">
                                            </div>
                                            <div class="form-group">
                                                <label for="get-approval">{{l('Buscar usuários aprovadores')}}</label>
                                                <input id="get-approval" type="text" name="getIcSap" class="form-control"
                                                    [(ngModel)]="externalGmudDto.searchApprovers">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
        <create-edit-approver-modal #createEditApproverModal (closed)="reloadPage($event)"></create-edit-approver-modal>            
    </div>
</form>
