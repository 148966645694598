import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-enum-select',
  templateUrl: './enum-select.component.html',
  styleUrls: ['./enum-select.component.css']
})
export class EnumSelectComponent implements OnInit {
  @ViewChild('field', { static: true }) field: NgModel;

  @Input() enumeration: any;
  @Input() value: any;
  @Input() sName: string;
  @Input() sId: string;
  @Input() sClass: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() sDisabled?: boolean = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

  selected: any;

  get options() {
    return Object.keys(this.enumeration).map(key => {
      return {
        value: this.enumeration[key],
        label: this.formatKey(key)
      }
    })
  }

  get hasValidation() {
    return this.required;
  }
  
  constructor() { }

  ngOnInit() {
  }

  formatKey(key: string): string {
    const sentence = key.replace(/([A-Z])/g, " $1" );
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }

  onChange(event) {
    this.valueChange.emit(this.enumeration[event]);
    this.valid.emit(this.field.valid);
  }


}
