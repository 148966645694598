<div bsModal id="demand-movement-modal" #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{l("PullRequest")}}</h4>
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div id="select-environments" class="col-md-12">
                        <h4 class="title-sections">{{l("Environments")}}</h4>
                        <label for="Demanda_Referencias">{{l("SelectEnvironments")}}</label>
                        <ng-select [items]="ambientes" [multiple]="true"
                            [(ngModel)]="ambienteSelected" name="listaDeambientes">
                        </ng-select>
                        <div class="kt-checkbox-list mt-3">
                            <label for="environments-equalization" class="kt-checkbox">
                                <input id="environments-equalization" type="checkbox"
                                name="EnvironmentsEqualization" [(ngModel)]="environmentsEqualization">
                                    {{l("EnvironmentsEqualization")}}
                                    <span></span>
                            </label>
                        </div>
                    </div>
                    <div id="select-code-reviewer" class="col-md-12" *ngIf="selectCodeReviewers">
                        <h4 class="title-sections">{{l("CodeReview")}}</h4>
                        <label for="code-reviewer" class="code-reviewer-label">{{l("SelectCodeReviewer")}}</label>
                        <ng-select [items]="users" [multiple]="true" [(ngModel)]="usersSelected" name="usersSelected">
                            <ng-template ng-label-tmp let-item="item">
                                <div class="box-selected">
                                    <span class="ng-value-label ng-star-inserted">
                                        <b>{{item.label}}</b>
                                        <div class="box-content">
                                            <div class="content-item item-cargo">{{item.cargo}}</div>
                                            <div class="content-item item-fornecedor">{{item.fornecedor}}</div>
                                        </div>
                                    </span>
                                    <span class="delete-box" (click)="clear(item)" aria-hidden="true"><i class="fa fa-times"></i></span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                <div class="p-2">
                                    <b>{{item.label}}</b>
                                    <div style="font-size: 12px;">{{item.cargo}}</div>
                                    <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div id="select-notify-users-hml" class="col-md-12" *ngIf="isPermissionNotifyHml">
                        <h4 class="title-sections">{{l("HomologationNotification")}}</h4>
                        <label for="select-users-hml" class="select-users-hml">{{l("SelectUsers")}}</label>
                        <ng-select [items]="usersHml" [multiple]="true" [(ngModel)]="usersSelectedHml" name="usersSelectedHml"
                        [disabled]="notifyAllUsersHml">
                            <ng-template ng-label-tmp let-item="item">
                                <div class="box-selected" >
                                    <span class="ng-value-label ng-star-inserted">
                                        <b>{{item.label}}</b>
                                        <div class="box-content">
                                            <div class="content-item item-cargo">{{item.cargo}}</div>
                                            <div class="content-item item-fornecedor">{{item.fornecedor}}</div>
                                        </div>
                                    </span>
                                    <span class="delete-box" (click)="clearUsersNotifyHml(item)" aria-hidden="true" *ngIf="!notifyAllUsersHml"><i class="fa fa-times"></i></span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                <div class="p-2">
                                    <b>{{item.label}}</b>
                                    <div style="font-size: 12px;">{{item.cargo}}</div>
                                    <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                </div>
                            </ng-template>
                        </ng-select>

                        <div class="kt-checkbox-list mt-3">
                            <label for="select-all-users-notify-hml" class="kt-checkbox">
                                <input id="select-all-users-notify-hml" type="checkbox"
                                name="SelectAllUsersNotifyHml" [(ngModel)]="notifyAllUsersHml" (click)="selectAllUsersHml()">
                                    {{l("SelectAllUsers")}}
                                    <span></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!existeUsuarioSapCadastradoAoResponsavel">
                    <div class="col-md-12 espacamento-labels">
                        <label for="Demanda_Referencias">{{l("UsuarioSap")}}</label>
                        <ng-select [items]="usuariosSapsDisponiveis" [multiple]="false"
                            [(ngModel)]="usuarioSapSelecionado" name="listaUsuariosSaps" [virtualScroll]=true
                            (scroll)="onScroll($event)">
                        </ng-select>
                    </div>
                </div>
                <div id="version-for-test" *ngIf="displayTabTasksSaps">
                    <h4 class="title-sections">{{l("VersionForTest")}}</h4>
                    <p class="sub-title-task">{{l("SelectRequestTask")}}</p>
                    <p-tree [value]=treeData selectionMode="checkbox" [(selection)]="selectedFiles" 
                        [propagateSelectionUp]="false" [propagateSelectionDown]="true"></p-tree>
                </div>
                <div class="kt-checkbox-list mt-3">
                    <label for="environments-skip-qualiy" class="kt-checkbox" *ngIf="displayTabTasksSaps && configSkipQuality">
                        <input id="environments-skip-qualiy" type="checkbox"
                        name="EnvironmentsSkipQuality" [(ngModel)]="environmentsSkipQuality">
                            Ativar função "Ciente" na avaliação qualidade. (Beta)
                            <span></span>
                    </label>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary blue" (click)="select()"
                [disabled]="(!existeUsuarioSapCadastradoAoResponsavel && !usuarioSapSelecionado) || !temAmbientes || (displayTabTasksSaps && selectedFiles.length == 0) || saving" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')">
                    <i class="fa fa-save"></i>
                    {{l("Save")}}
                </button>
            </div>
        </div>
    </div>
</div>