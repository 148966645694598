<div class="kt-content">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <h6 class="title-section title-indicators">{{l("YourIndicators")}}</h6>
            <div id="indicators">
                <nps class="indicator"></nps>
                <reducao-custo class="indicator"></reducao-custo>
                <retrabalho class="indicator"></retrabalho>
            </div>
        </div>
    </div>
</div>