<div id="tabs-configuration">
    <div class="kt-content">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <tabset>
                    <tab heading="Cadastro de projetos">
                        <tabs-parameters-gerenciar-projetos></tabs-parameters-gerenciar-projetos>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>
