import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EnumEstagios, EnumIntegrationType, IntegracoesServiceProxy, MandanteSapDto, Select2ItemDto, EnumTypeMandante, AmbientesServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-mandante-integracao',
  templateUrl: './mandante-integracao.component.html',
  styleUrls: []
})
export class MandanteIntegracaoComponent extends AppComponentBase implements OnInit, OnDestroy {

  @Input() nomeBranch: string;
  @Input() listaMandantes: MandanteSapDto[] = [];
  @Output() mandanteAdicionado: EventEmitter<any> = new EventEmitter<any>();
  @Output() openIntegrations: EventEmitter<any> = new EventEmitter<any>();


  showForm: boolean = false;
  integracoes: Select2ItemDto[] = [];
  mandanteModelo: MandanteSapDto = new MandanteSapDto();
  modoEdicao: boolean = false;
  tipoMandanteLista: Select2ItemDto[] = []

  get enumTypeMandante() {
    return EnumTypeMandante
  }

  get integracaoId() {
    return this.mandanteModelo.integracaoId
  }

  set integracaoId(integracaoId) {
    this.mandanteModelo.integracaoId = integracaoId == 'null' ? null : integracaoId
  }


  get mandanteCodigo() {
    return this.mandanteModelo.mandante;
  }

  set mandanteCodigo(mandanteCodigo) {
    this.mandanteModelo.mandante = mandanteCodigo.toString();
  }

  get enumEstagios() {
    return EnumEstagios;
  }

  constructor(injector: Injector, private service: IntegracoesServiceProxy, private _ambienteServiceProxy: AmbientesServiceProxy) {

    super(injector);
  }
  ngOnDestroy(): void {
    this.showForm = false;
  }

  ngOnInit() {
    this.getIntegrations();
  }

  testarConexao(mandante: string) {
    this.notify.success(this.l("Wait"));
    this._ambienteServiceProxy.connectionTester(mandante).subscribe((result) => {
      alert(result);
    });
  }

  getIntegrations(callback = () => { }) {
    this.service.getIntegrationToSelect().subscribe(result => {
      this.integracoes = result;
      this.integracaoId = null
    })
  }

  adicionarMandante() {
    const mandantes = [...this.listaMandantes];
    let found = mandantes.find(item => item.mandante == this.mandanteModelo.mandante);
    if (this.modoEdicao) {
      found = mandantes.find(item => item.mandante == this.mandanteModelo.mandante);
      const index = mandantes.indexOf(found);
      mandantes[index] = this.mandanteModelo;
    }
    if (found && !this.modoEdicao) {
      this.notify.error(this.l('DuplicatedClient'));
      return;
    }
    if (!this.modoEdicao) {
      this.mandanteModelo.integrationType = this.mandanteModelo.integracaoId === null ? EnumIntegrationType.RFC : EnumIntegrationType.CUSTOMIZED;
      mandantes.push(this.mandanteModelo);
    }
    this.mandanteModelo = new MandanteSapDto();
    this.modoEdicao = false;
    this.showForm = false;
    this.mandanteAdicionado.emit(mandantes);
  }

  integracaoLabel(integracao) {
    const found = this.integracoes.find(item => integracao == item.value) || { label: '' };
    return found.label;
  }

  deleteMandante(mandanteCodigo) {
    const mandantes = [...this.listaMandantes];
    const found = mandantes.find(item => mandanteCodigo == item.mandante);
    const index = mandantes.indexOf(found);
    mandantes.splice(index, 1);
    this.mandanteAdicionado.emit(mandantes);
  }

  editarMandante(mandante) {
    this.modoEdicao = true;
    this.showForm = true;
    this.mandanteModelo = mandante
  }

  openIntegrationModal() {
    this.openIntegrations.emit();
  }

}
