import { Component, EventEmitter, Injector, OnInit, Optional, Output, ViewChild } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ConfiguracaoGMUDNativaServiceProxy, CreateProjectDto, DepartamentosGmudDto, EnumStatusProjeto, ProjectsServiceProxy, UpdateProjectDto, UsuariosAprovadoresGmudDto, UsuariosAprovadoresGmudSelect2 } from "@shared/service-proxies/service-proxies";
import { ModalDirective } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";
import * as uuid from 'uuid';

@Component({
    selector: 'create-edit-projeto-modal',
    templateUrl: './create-or-edit-projeto-modal.component.html',
    styleUrls: ['./create-or-edit-projeto-modal.component.less']
})

export class CreateOrEditProjetoModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('createEditProjetoModal', { static: true }) createEditProjetoModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() saved: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();

    loading: boolean = false;
    saving: boolean = false;
    newProject: boolean = false;

    projetoNome: string;
    projetoId: string;
    projetoStatus: EnumStatusProjeto;


    projetoList = [];

    disableSaved: boolean = true;

    ngOnInit(): void {

    }

    constructor(
        injector: Injector,
        private _projectsServiceProxy: ProjectsServiceProxy
    ) {
        super(injector);
    }

    get statusProject() {
        return Object.keys(EnumStatusProjeto);
    }

    async show(projetoDto: any, button: boolean, newProject: boolean) {
        this.newProject = newProject;
        if (button) {
            this.projetoNome = '';
            this.projetoId = null;
            this.createEditProjetoModal.show();
            return;
        }
        this.projetoNome = projetoDto.nome;
        this.projetoId = projetoDto.id;
        this.projetoStatus = projetoDto.status;
        this.createEditProjetoModal.show();
    }

    close(): void {
        this.createEditProjetoModal.hide();
        this.closed.emit();
    }


    addProjeto() {
        if (this.newProject) {
            let projectDto = new CreateProjectDto();
            projectDto.nome = this.projetoNome;
            this._projectsServiceProxy.createProject(projectDto)
                .pipe(finalize(() => this.loading = false))
                .subscribe(x => {
                    this.close();
                    this.notify.success(this.l('SavedSuccessfully'));
                })
        }
        else{
            let projectDto = new UpdateProjectDto();
            projectDto.nome = this.projetoNome;
            projectDto.id = this.projetoId;
            projectDto.status = this.projetoStatus
            this._projectsServiceProxy.updateProject(projectDto)
                .pipe(finalize(() => this.loading = false))
                .subscribe(x => {
                    this.close();
                    this.notify.success(this.l('SavedSuccessfully'));
                })
        }
    }


}