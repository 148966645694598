<div bsModal #createNotificacaoModal="bs-modal" id="createNotificacaoModal" class="modal fade overflow-auto" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div id="create-notification-modal-dialog" class="modal-dialog modal-xl">
        <div id="create-notification-content" class="modal-content notification-content">
            <form id="notification-form" #systemNotificationsForm="ngForm" autocomplete="off">
                <div id="modal-header-content" class="modal-header">
                    <h4 id="modal-title-content" class="modal-title">
                        {{l("Criar Notificação")}}
                    </h4>
                    <button id="close-modal-content" type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span id="times-icon" class="times-icon" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div id="modal-body-content" class="modal-body">
                    <div id="send-to" class="form-group">
                        <label for="send-to" class="send-to-label">{{l("Enviar para")}}</label>
                        <ng-select [items]="users" [multiple]="true" [(ngModel)]="usersSelected" name="usersSelected" required>
                            <ng-template ng-label-tmp let-item="item">
                                <div id="box-selected" class="box-selected">
                                    <span id="selected-content" class="ng-value-label ng-star-inserted">
                                        <b id="user-selected-label" class="user-selected-label">{{item.label}}</b>
                                    </span>
                                    <span id="delete-box-content" class="delete-box" (click)="clear(item)" aria-hidden="true"><i id="times-icon" class="fa fa-times"></i></span>
                                </div>
                            </ng-template>
                            <ng-template ng-header-tmp>
                                <div id="user-content-all" class="user-content p-2" (click)="selectAllClientes()">
                                    <b id="user-label-all" class="user-selected-label">Todos</b>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                <div id="user-content" class="user-content p-2">
                                    <b id="user-label" class="user-selected-label">{{item.label}}</b>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div id="notification-title-content" class="form-group">
                        <label id="notification-title-label" class="label-title" for="notification-title">{{l("Título da notificação")}}</label>
                        <input id="notification-title" type="text" name="notification-title" class="form-control" minlength="10" [(ngModel)]="notificationTitleInput" required>
                    </div>
                    <div id="notification-content-content" class="form-group">
                        <label id="notification-content-content" class="label-title" for="notification-content">{{l("Conteúdo")}}</label>
                        <p-editor id="p-editor-notification" class="p-editor-notification" [(ngModel)]="notificationContent" name="notification-content" [style]="{'height':'400px'}" required></p-editor>
                    </div>
                </div>
                <div id="modal-footer-content" class="modal-footer">
                    <div id="send-button-content" class="send-button">
                        <button id="send-btn" class="btn btn-primary" type="submit" [disabled]="systemNotificationsForm.invalid || saving" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')" (click)="sendNotification()">
                            <i id="paper-plane-icon" class="fa fa-paper-plane"></i>{{l("Enviar")}}
                        </button>
                    </div>
                </div>  
            </form>
        </div>
    </div>
</div>