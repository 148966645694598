<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" >
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{"Maquinas" | localize}}<span *ngIf="userName"> - {{userName}}</span></span>
                </h4>
                <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group"  *ngFor="let maquina of maquinas" >
                    <input class="form-control" [value]="maquina.nome" disabled/>
                </div>
                <div class="form-group text-center" *ngIf="!maquinas || maquinas.length == 0">
                    <span >{{"MaquinasZeroData" | localize}}</span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="close()">{{"Cancel" | localize}}</button>
            </div>
        </div>
    </div>
</div>
