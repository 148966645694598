<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #ambienteForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span *ngIf="ambiente.id">{{ l("EditAmbiente") }}</span>
                        <span *ngIf="!ambiente.id">{{
                            l("CreateNewAmbiente")
                            }}</span>
                    </h4>
                    <button type="button" class="close" (click)="close()" aria-label="Close" [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <tabset>
                        <tab heading="{{'Ambiente' | localize}}">
                            <div class="form-group">
                                <label for="Ambiente_Nome">{{ l("Descricao") }} *</label>
                                <input type="text" id="Ambiente_Nome" class="form-control" [(ngModel)]="ambiente.nome"
                                    name="Nome" #Ambiente_Nome="ngModel" [ngClass]="{
                                        'is-invalid': Ambiente_Nome.invalid,
                                        'is-valid': !Ambiente_Nome.invalid
                                    }" required />
                            </div>

                            <app-enum-select class="form-group" sName="Auth" sId="Integracao_Auth" sClass="form-control"
                                [label]="l('EstagioAtual')" [value]="ambiente.estagioAtual"
                                (valueChange)="estagio = $event" [enumeration]="enumEstagios" (change)="selectEnvironment($event)"
                                required>
                            </app-enum-select>
                            <div class="form-group">
                                <label for="Ambiente_Server">{{ l("Server") }} *</label>
                                <input type="text" id="Ambiente_Server" class="form-control" required
                                    [(ngModel)]="ambiente.server" #Ambiente_Server="ngModel" min="1" [ngClass]="{
                                'is-invalid': Ambiente_Server.invalid,
                                'is-valid': !Ambiente_Server.invalid
                            }" name="Server" />
                        </div>

                            <div class="form-group">
                                <label for="Ambiente_InstanceNumber">{{ l("InstanceNumber") }} *</label>
                                <input type="text" id="Ambiente_InstanceNumber" class="form-control"
                                    [(ngModel)]="ambiente.instanceNumber" #Ambiente_Instance="ngModel" [ngClass]="{
                                'is-invalid': Ambiente_Instance.invalid,
                                'is-valid': !Ambiente_Instance.invalid
                            }" name="InstanceNumber" required />
                        </div>

                            <div class="form-group">
                                <label for="Ambiente_SystemId">{{ l("SystemId") }} *</label>
                                <input type="text" id="Ambiente_SystemId" class="form-control"
                                    [(ngModel)]="ambiente.systemId" name="SystemId" #Ambiente_System="ngModel"
                                    [ngClass]="{
                                'is-invalid': Ambiente_System.invalid,
                                'is-valid': !Ambiente_System.invalid
                            }" required />
                            </div>
                        </tab>
                        <tab heading="{{'Mandante' | localize}}">
                            <app-mandante-integracao [nomeBranch]="nomeBranch"
                                [listaMandantes]="this.ambiente.listaMandantes"
                                (mandanteAdicionado)="manipularMandantes($event)"
                                (openIntegrations)="openIntegrationModal()">
                            </app-mandante-integracao>
                        </tab>
                        <tab heading="{{'Ambientes Relacionados' | localize}}">
                            <div class="col-md-12">
                                <label for="Ambiente_Referencias">{{
                                    l("Ambientes")
                                    }}</label>
                                <ng-select [items]="ambientes" [multiple]="true" [(ngModel)]="ambienteSelected"
                                    name="listaDeambientes">
                                </ng-select>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button *ngIf="idAmbiente" type="button" [disabled]="saving" class="btn btn-default" (click)="removeAmbiente()">
                        <i class="fa fa-trash"></i>
                        <span>{{ l("Remove") }}</span>
                    </button>
                    <button type="button" class="btn btn-default" (click)="close()">
                        {{ l("Cancel") }}
                    </button>
                    <button type="submit" class="btn btn-primary blue"
                        [disabled]="!ambienteForm.form.valid || !hasMandante" [buttonBusy]="saving"
                        [busyText]="l('SavingWithThreeDot')">
                        <i class="fa fa-save"></i> <span>{{ l("Save") }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<app-criar-integracao-modal #criarIntegracaoModal (closed)="closeIntegrationModal()"></app-criar-integracao-modal>
