import { Component, Injector, ViewChild, ViewEncapsulation } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import * as _ from "lodash";
import { XmlHttpRequestHelper } from "@shared/helpers/XmlHttpRequestHelper";
import { videoViewModalComponent } from "./video-viewer-modal/video-viewer-modal.component";
import { AppConsts } from "@shared/AppConsts";
import { ActivatedRoute, Router } from "@angular/router";
import playlistTraining from "./playlist-training";

@Component({
    selector: "app-training-videos",
    templateUrl: "./training-videos.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./training-videos.component.css'],
    animations: [appModuleAnimation()],
})
export class TrainingVideosComponent extends AppComponentBase {
    @ViewChild('videoViewModal', { static: true }) videoViewModal: videoViewModalComponent;
    
    videos: any[];
    public url: any;

    _entityTypeFullName = "QAMetrik.TrainingVideos.TrainingVideos";
    entityHistoryEnabled = false;

    constructor(
        injector: Injector, 
        private route: ActivatedRoute,
        private router: Router
    ) {
        super(injector);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }

    ngOnInit(): void {
        this.getVideosForChanel();
    }

    getVideosForChanel(): any {
        const playlistKey = this.route.snapshot.paramMap.get('playlist')
        const {playlist} = playlistTraining.find(pt => pt.key == playlistKey) || playlistTraining[0]
        let url = AppConsts.urlYouTube +
        "&playlistId=" + 
        playlist +
        "&key=" +
        AppConsts.youTubeKey;

        XmlHttpRequestHelper.ajax("GET", url, null, null, (response) => {
            this.videos = response.items;
        });
    }

    videoView(idVideo: any): void {
        this.videoViewModal.show(idVideo);
    }
}
