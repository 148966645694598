import { Component, EventEmitter, Injector, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { NotificacaoPortalDto, NotificacaoPortalServiceProxy, UsuariosNotificacaoSelect2 } from "@shared/service-proxies/service-proxies";
import { ModalDirective } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";

@Component({
    selector: 'create-notificacao-modal',
    templateUrl: './create-notificacao-modal.component.html',
    styleUrls: ['./create-notificacao-modal.component.less'],
    encapsulation: ViewEncapsulation.None
})

export class CreateNotificacaoModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('createNotificacaoModal', { static: true }) createNotificacaoModal: ModalDirective;
    @Output() closed: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleted: EventEmitter<any> = new EventEmitter<any>();
    @Output() reloadPage: EventEmitter<boolean> = new EventEmitter<boolean>();

    saving: boolean = false
    loading: boolean = false;

    notificationId: string;
    notificationTitleInput: string;
    notificationContent: string;

    users: UsuariosNotificacaoSelect2 [] = [];
    usersSelected: UsuariosNotificacaoSelect2[] = [];

    tennantList : number[] = [];

    notificacaoDto : NotificacaoPortalDto = new NotificacaoPortalDto();
    
    constructor(
        injector: Injector,
        private _notificacaoPortalServiceProxy: NotificacaoPortalServiceProxy
    ) {
        super(injector);
    }
    
    ngOnInit(): void {
        this.getClientes();
    }

    async show() {
        this.createNotificacaoModal.show();
    }
    
    close(): void {
        this.notificationTitleInput = '';
        this.notificationContent = '';
        this.usersSelected = [];
        
        this.createNotificacaoModal.hide();
        this.closed.emit();
    }

    getClientes(): void {
        this._notificacaoPortalServiceProxy
            .getAllTenants()
            .subscribe(x => {
                this.users = x;
            });
    }

    getClientesSelecionados(id:string){
        this._notificacaoPortalServiceProxy
            .getTenantsNotificados(id)
            .subscribe(res => {
                this.usersSelected = res;
            })
    }

    selectAllClientes(id:string){
        this._notificacaoPortalServiceProxy
            .getTenantsNotificados(id)
            .subscribe(() => {
                this.usersSelected = this.users;
            })
    }

    clear(item: UsuariosNotificacaoSelect2) {
        this.usersSelected = [].concat(this.usersSelected.filter(x => x.value !== item.value));
    }

    sendNotification(){
        this.saving = true;
        this.usersSelected.forEach(el => {
            //Preenche a lista de tennants
            let tennantId = parseInt(el.value);
            this.tennantList.push(tennantId);
        });

        this.notificacaoDto.titulo = this.notificationTitleInput;
        this.notificacaoDto.conteudo = this.notificationContent;
        this.notificacaoDto.tenantsEnvolvidos = this.tennantList;

        if (this.notificationId != null || this.notificationId != undefined)
            this.notificacaoDto.id = this.notificationId;

        //Envia o Dto preenchido para o backend
        this._notificacaoPortalServiceProxy.enviarNotificacao(this.notificacaoDto)
            .pipe(finalize(() => this.loading = false))
            .subscribe(x => {
                //this.notificacaoDto.id = '';
                //this.notificationId = '';
                this.saving = false;
                this.tennantList = [];
                this.usersSelected = [];
                this.notificationTitleInput = '';
                this.notificationContent = '';
                this.close();
                this.notify.success("Notificação enviada com sucesso!");
            })
    }
}