import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ChangeManagerSettingsEditDto, TenantSettingsEditDto, TenantSettingsServiceProxy, ConfiguracaoGMUDNativaServiceProxy, UsuariosAprovadoresGmudSelect2, UsuariosAprovadoresGmudDto, DepartamentosGmudDto, GetDadosGmudNativaPendenteDto, ConfigGmudNativaPendenteDto, ModulosSapGmudSelect2, GmudApprovalHierarchyServiceProxy, GmudApprovalHierarchyDto, TypeExternalApproval, ExternalGMUDConfigurationDto, ExternalGMUDConfigurationServiceProxy } from '@shared/service-proxies/service-proxies';
import { timeStamp } from 'console';
import { parseInt } from 'lodash';
import { finalize } from 'rxjs/operators';
import { CreateOrEditApproverModalComponent } from './create-or-edit-approver-modal/create-or-edit-approver-modal.component';
import { NgModel } from '@angular/forms';

interface Types {
    name: string;
    value: TypeExternalApproval;
  }

@Component({
    selector: 'change-manager',
    templateUrl: './gestaoMudancas.component.html',
    styleUrls: ['./gestaoMudancas.component.less']
})

export class GestaoMudancasComponent extends AppComponentBase implements OnInit {
    @ViewChild('createEditApproverModal', { static: true }) createEditApproverModal: CreateOrEditApproverModalComponent;
    @ViewChild('Lista_Email_Gmud_Nativa', { static: true }) listaEmailGmudNativaField: NgModel;

    departamentoDto: DepartamentosGmudDto = new DepartamentosGmudDto;
    departamentList: DepartamentosGmudDto[] = [];

    approverRequiredList = [];
    approverOptionalList = [];

    userApprove: UsuariosAprovadoresGmudDto[] = [];

    users: UsuariosAprovadoresGmudSelect2[] = [];
    usersSelected: UsuariosAprovadoresGmudSelect2[] = [];

    usersOptional: UsuariosAprovadoresGmudSelect2[] = [];
    usersOptionalSelected: UsuariosAprovadoresGmudSelect2[] = [];

    departamentosGmudDto: DepartamentosGmudDto[] = [];
    usuariosAprovadoresGmudDto: UsuariosAprovadoresGmudDto[] = [];

    departamentosGmudOpcionaisDto: DepartamentosGmudDto[] = [];
    usuariosAprovadoresOpcionaisGmudDto: UsuariosAprovadoresGmudDto[] = [];

    settings: TenantSettingsEditDto = new TenantSettingsEditDto();
    loading = false;

    getDadosGmudNativaPendenteDto: GetDadosGmudNativaPendenteDto = new GetDadosGmudNativaPendenteDto();

    modules: ModulosSapGmudSelect2[] = [];
    modulesSelected: ModulosSapGmudSelect2;

    approvalHierarchy!: GmudApprovalHierarchyDto[];
    
    externalGmudDto: ExternalGMUDConfigurationDto = new ExternalGMUDConfigurationDto();
    comunicationEmail!: string[] | undefined;
    types: Types[] = [];
    isServiceNow: boolean = false;
    isIntegrationApproval: boolean = false;

    get changeManagerSettings(): ChangeManagerSettingsEditDto {
        return this.settings.changeManager || new ChangeManagerSettingsEditDto();
    }

    get frequency(): number {
        const frequency = parseInt(this.changeManagerSettings.frequency) / 60 / 1000;
        return frequency
    }

    set frequency(value: number) {
        const numberValue = Math.abs(value);
        const frequency = numberValue * 60 * 1000;
        this.changeManagerSettings.frequency = frequency.toString()
    }

    constructor(
        injector: Injector,
        private _tenantSettingsService: TenantSettingsServiceProxy,
        private _configuracaoGMUDNativaServiceProxy: ConfiguracaoGMUDNativaServiceProxy,
        private _gmudApprovalHierarchyServiceProxy: GmudApprovalHierarchyServiceProxy,
        private _ExternalGMUDConfigurationService: ExternalGMUDConfigurationServiceProxy
    ) {
        super(injector);
    }
    ngOnInit() {
        this.getSettings();
        this.getDepartamentList();
        this.getModulesSap();
        this.getExternalGmudConfiguration();

        this.types = [
            { name: 'Service Now', value: TypeExternalApproval.ServiceNow },
            { name: 'TOP Desk', value: TypeExternalApproval.TopDesk },
            { name: 'ZEN Desk', value: TypeExternalApproval.ZenDesk },
          ];
    }

    getSettings() {
        this.loading = true;
        this._tenantSettingsService.getAllSettings()
            .pipe(finalize(() => { this.loading = false; }))
            .subscribe((result: TenantSettingsEditDto) => this.settings = result);

        this._configuracaoGMUDNativaServiceProxy.getDadosGmudpendente()
            .subscribe((res: GetDadosGmudNativaPendenteDto) => {
                this.getDadosGmudNativaPendenteDto = res;
            });
        
        this._ExternalGMUDConfigurationService.getConfiguration()
            .subscribe((res: ExternalGMUDConfigurationDto) => {
                this.externalGmudDto = res;

                if (res.comunicationEmail != null){
                    let emailsSeparados: string[] = res.comunicationEmail.split(';');
                    let emailsLimpos: string[] = emailsSeparados.map(email => email.trim());
                    this.comunicationEmail = emailsLimpos;
                }

                this.isServiceNow = res.typeExternalApproval == TypeExternalApproval.ServiceNow ? true : false
            });
        
    }

    showDepartamentModal(button: boolean): void {
        this.createEditApproverModal.show(this.departamentoDto, button);
    }

    getDepartamentList() {
        this._configuracaoGMUDNativaServiceProxy.getAllDepartamentos()
            .subscribe(res => {
                this.departamentList = res;
            })
    }

    editDepartamentItem(departamentoId: string) {
        this._configuracaoGMUDNativaServiceProxy.getDepartamento(departamentoId)
            .subscribe(res => {
                this.departamentoDto = res;
                this.createEditApproverModal.show(this.departamentoDto, false);
            });
    }

    deleteDepartamentItem(i: number, departamentoDto: DepartamentosGmudDto) {
        this._configuracaoGMUDNativaServiceProxy.deleteDepartamento(departamentoDto.id)
            .subscribe(res => {
                if (!res) {
                    this.notify.warn('Há mudanças vinculadas para este departamento. não será possível a exclusão.');
                    return;
                }
                this.departamentList.splice(i, 1);
                this.departamentosGmudDto.splice(i, 1);
                this.notify.success(this.l('SuccessfullyRemoved'));
            });
    }

    saveAll() {
        this.loading = true;
        let configGmudNativaPendenteDto = new ConfigGmudNativaPendenteDto();
        if (this.getDadosGmudNativaPendenteDto.usersEmails != undefined && this.getDadosGmudNativaPendenteDto.usersEmails.length > 0){
            configGmudNativaPendenteDto.usersEmails = this.getDadosGmudNativaPendenteDto.usersEmails.join(";"); 
            configGmudNativaPendenteDto.timeInterval = this.getDadosGmudNativaPendenteDto.timeInterval;
        }

        if (this.externalGmudDto.active){
            if (this.externalGmudDto.comunicationEmail != undefined && this.externalGmudDto.comunicationEmail.length > 0){
                this.externalGmudDto.comunicationEmail = this.comunicationEmail.join(";");
            }
            
            if (this.externalGmudDto.typeExternalApproval == null) 
                this.externalGmudDto.typeExternalApproval = undefined;
        }

        this._tenantSettingsService.updateAllSettings(this.settings)
            .pipe(finalize(() => this.loading = false))
            .subscribe(x => {
                this._configuracaoGMUDNativaServiceProxy.insertUpdateDadosGmudPendente(configGmudNativaPendenteDto)
                    .subscribe(() => {
                        if (this.externalGmudDto.active){
                            this._ExternalGMUDConfigurationService.insertUpdateConfiguration(this.externalGmudDto)
                                .subscribe(x => {
                                    this.getSettings();
                                    this.notify.success(this.l('SavedSuccessfully'));
                                })
                        }else{
                            if (this.approvalHierarchy !== null && this.approvalHierarchy.length > 0) {
                                const approvalLevels = this.approvalHierarchy.map(item => item.approvalLevel);
                                const hasDuplicates = new Set(approvalLevels).size !== approvalLevels.length;
                            
                                if (hasDuplicates) {
                                    this.notify.error('Nível de Aprovação duplicado, verifique!');
                                } else {
                                    this._gmudApprovalHierarchyServiceProxy.createOrEdit(this.approvalHierarchy)
                                        .subscribe(() => {
                                            this.modulesSelected = null;
                                            this.approvalHierarchy = [];
                                            this.notify.success(this.l('SavedSuccessfully'));
                                        });
                                }
                            } else {
                                this.notify.success(this.l('SavedSuccessfully'));
                            }
                        }
                    });
            });
    }

    reloadPage(ev: any) {
        this.getDepartamentList();
    }


    validarListaEmails() {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        this.changeManagerSettings.emailsCommunication = this.changeManagerSettings.emailsCommunication.filter(email => emailRegex.test(email));
    }

    getModulesSap() {
        this._gmudApprovalHierarchyServiceProxy.getAllSapModules()
            .subscribe(res => { this.modules = res; });
    }

    getDepartments(moduleSelect: any) {
        if (moduleSelect === null || moduleSelect === undefined) {
            this.approvalHierarchy = [];
            return;
        }

        this._gmudApprovalHierarchyServiceProxy.getAllDepartment(moduleSelect.value)
            .subscribe(res => { this.approvalHierarchy = res });
    }

    deleteApprovalHierarchy(approvalHierarchyDto: GmudApprovalHierarchyDto) {
        this._gmudApprovalHierarchyServiceProxy.deleteApprovalHierarchy(approvalHierarchyDto.id)
            .subscribe(() => {
                this.approvalHierarchy = this.approvalHierarchy.filter(item => item.id !== approvalHierarchyDto.id);
                this.getDepartments(this.modulesSelected);
                this.notify.success(this.l('SuccessfullyRemoved'));
            });
    }

    onValueChange(newValue: any) {
        this.externalGmudDto.typeExternalApproval = newValue.value;
        if (newValue.value === TypeExternalApproval.ServiceNow){
            this.isServiceNow = true;
        }else{
            this.isServiceNow = false;
        }
    }

    getExternalGmudConfiguration() {
        this._ExternalGMUDConfigurationService.getConfiguration()
            .subscribe(res => { this.externalGmudDto = res });
    }
}