<div bsModal #editModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #userForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{"Permissions" | localize}}<span *ngIf="userName"> - {{userName}}</span></span>
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')" [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <permission-tree #permissionTree></permission-tree>

                    <div class="alert alert-warning" style="margin-bottom: 0; margin-top: 15px">
                        <em>{{'Note_RefreshPageForPermissionChanges' | localize}}</em>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" [buttonBusy]="resettingPermissions" [busyText]="l('SavingWithThreeDot')" type="button" class="btn btn-default pull-left" (click)="resetPermissions()" [tooltip]="l('ResetPermissionsTooltip')" placement="top"><span>{{"ResetSpecialPermissions" | localize}}</span></button>
                    <button [disabled]="saving || resettingPermissions" type="button" class="btn btn-default" (click)="close()">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary" [disabled]="!userForm.form.valid || resettingPermissions" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i> <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
