import { Component, EventEmitter, Injector, Output, ViewChild, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    CurrentUserProfileEditDto,
    SettingScopes,
    ProfileServiceProxy,
    UpdateGoogleAuthenticatorKeyOutput,
    SendVerificationSmsInputDto,
    SapUsersServiceProxy,
    Select2ItemDto,
    CargosServiceProxy,
    FornecedoresServiceProxy
} from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SmsVerificationModalComponent } from './sms-verification-modal.component';
import { finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'mySettingsModal',
    templateUrl: './my-settings-modal.component.html'
})
export class MySettingsModalComponent extends AppComponentBase implements OnInit {

    @ViewChild('mySettingsModal', {static: true}) modal: ModalDirective;
    @ViewChild('smsVerificationModal', {static: false}) smsVerificationModal: SmsVerificationModalComponent;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public active = false;
    public saving = false;
    public isGoogleAuthenticatorEnabled = false;
    public isPhoneNumberConfirmed: boolean;
    public smsEnabled: boolean;
    public user: CurrentUserProfileEditDto;
    public showTimezoneSelection: boolean = abp.clock.provider.supportsMultipleTimezone;
    public canChangeUserName: boolean;
    public defaultTimezoneScope: SettingScopes = SettingScopes.User;
    private _initialTimezone: string = undefined;
    public savedPhoneNumber: string;
    public newPhoneNumber: string;
    isMultiTenancyEnabled: boolean = this.multiTenancy.isEnabled;
    isTwoFactorLoginEnabledForApplication = false;
    cpfModified = false;
    public cellphoneMask: Subject<string> = new Subject();
    sapUsers:Select2ItemDto[]=[]
    offices: Select2ItemDto[]=[]
    providers: Select2ItemDto[]=[]
    
    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy,
        private _userService: SapUsersServiceProxy,
        private _cargosService: CargosServiceProxy,
        private _providerService: FornecedoresServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.isTwoFactorLoginEnabledForApplication =
            abp.setting.getBoolean('Abp.Zero.UserManagement.TwoFactorLogin.IsEnabled');
        if (this.appSession.tenantId) {
            this._userService.getAllSapUsers().subscribe(res => { this.sapUsers=res});
            this._cargosService.getAllCargos().subscribe(res => this.offices = res);
            this._providerService.getByName("").subscribe(res => this.providers = res)
        }
    }

    show(): void {
        this.active = true;
        this._profileService.getCurrentUserProfileForEdit().subscribe((result) => {
            this.smsEnabled = this.setting.getBoolean('App.UserManagement.SmsVerificationEnabled');
            this.user = result;
            this._initialTimezone = result.timezone;
            this.canChangeUserName = this.user.userName !== AppConsts.userManagement.defaultAdminUserName;
            this.modal.show();
            this.isGoogleAuthenticatorEnabled = result.isGoogleAuthenticatorEnabled;
            this.isPhoneNumberConfirmed = result.isPhoneNumberConfirmed;
            this.savedPhoneNumber = result.phoneNumber;
            this.cpfModified = this.user.cpf != null;
        });
    }

    updateQrCodeSetupImageUrl(): void {
        this._profileService.updateGoogleAuthenticatorKey().subscribe((result: UpdateGoogleAuthenticatorKeyOutput) => {
            this.user.qrCodeSetupImageUrl = result.qrCodeSetupImageUrl;
            this.isGoogleAuthenticatorEnabled = true;
        });
    }

    smsVerify(): void {
        let input = new SendVerificationSmsInputDto();
        input.phoneNumber = this.user.phoneNumber;
        this._profileService.sendVerificationSms(input)
            .subscribe(() => {
                this.smsVerificationModal.show();
            });
    }

    changePhoneNumberToVerified(): void {
        this.isPhoneNumberConfirmed = true;
        this.savedPhoneNumber = this.user.phoneNumber;
    }

    onShown(): void {
        document.getElementById('Name').focus();

        if (this.user?.phoneNumber?.length <= 10) {
            this.cellphoneMask.next('(00) 0000-00000');
          } else {
            this.cellphoneMask.next('(00) 00000-0000');
          }
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        if (!this.appSession.tenantId) {
            this.user.naoTrocarCpf = true;
            this.user.usuarioSapId = '00000000-0000-0000-0000-000000000000'
        }
        if(this.cpfModified) {
            this.user.cpf = null;
        }

        this.saving = true;

        this._profileService.updateCurrentUserProfile(this.user)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(() => {
                this.appSession.user.name = this.user.name;
                this.appSession.user.cpf = this.user.cpf;
                this.appSession.user.surname = this.user.surname;
                this.appSession.user.userName = this.user.userName;
                this.appSession.user.emailAddress = this.user.emailAddress;
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
                if (!this.cpfModified && this.user.cpf != null) {
                        window.location.reload();
                }

                if (abp.clock.provider.supportsMultipleTimezone && this._initialTimezone !== this.user.timezone) {
                    this.message.info(this.l('TimeZoneSettingChangedRefreshPageNotification')).then(() => {
                        window.location.reload();
                    });
                }
            });
    }

    onChangeCellphone(event) {
        if (event.length <= 10) {
          this.cellphoneMask.next('(00) 0000-00000');
        } else {
          this.cellphoneMask.next('(00) 00000-0000');
        }
      }
}
