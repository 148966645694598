<div id="createOrEditModal" bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <form #demandaForm="ngForm" (ngSubmit)="save()" autocomplete="off"
                (keydown.enter)="$event.preventDefault()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span *ngIf="demanda.id">{{l("EditDemanda")}} : {{demanda.demandaSapId}} </span>
                        <span *ngIf="!demanda.id">{{l("CreateNewDemanda")}}</span>
                    </h4>
                    <button type="button" class="close" (click)="close()" aria-label="Close" [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <tabset>
                        <tab heading="{{'DadosGerais' | localize}}" [active]="tabShow == '0'">
                            <ng-template tabHeading>
                                <i *ngIf="Demanda_Titulo.invalid " class="fas fa-exclamation-circle exclamation-tab"></i>
                            </ng-template>

                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group m-form__group">
                                        <label for="UserName">{{l("Responsavel")}}</label>
                                        <div class="input-group">
                                            <input class="form-control" id="UserName" name="userName" [value]="userName"
                                                type="text" disabled>
                                            <div class="input-group-append">
                                                <button class="btn btn-primary blue" (click)="openSelectUserModal()"
                                                    [disabled]="!podeAlterarResponsavel || !podeSalvar" type="button">
                                                    <i class="fa fa-search"></i> {{l("Pick")}}
                                                </button>
                                            </div>
                                            <div class="input-group-prepend">
                                                <button class="btn btn-danger btn-icon" type="button" [disabled]="true"
                                                    (click)="setUsuarioResponsavelIdNull()">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="demand-status">{{l("Status")}}</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="demand-status"
                                                name="DemandStatus" [value]="status | localize" disabled />
                                            <div class="input-group-append">
                                                <button class="btn btn-primary blue" (click)="updateDemandStatus()"
                                                    type="button" [disabled]="!podeSalvar" placement="auto"
                                                    tooltip="{{'ChangeStatusConcluded' | localize}}">
                                                    <i class="fa fa-arrow-right text-white"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div [ngClass]="columnSizeClassModuloSap">
                                    <div class="form-group m-form__group">
                                        <label for="ModuloSap">{{l("Modulo")}} *</label>
                                        <div class="input-group">
                                            <input class="form-control" id="moduloSap" name="moduloSap"
                                                [value]="moduloSap" type="text" disabled>
                                            <div class="input-group-append">
                                                <button class="btn btn-primary blue" (click)="openSelectModuleModal()"
                                                    type="button" [disabled]="!podeSalvar">
                                                    <i class="fa fa-search"></i> {{l("Pick")}}
                                                </button>
                                            </div>
                                            <div class="input-group-prepend">
                                                <button class="btn btn-danger btn-icon" type="button"
                                                    (click)="setModuloIdNull()" [disabled]="!podeSalvar">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div [ngClass]="columnSizeClassTipo">
                                    <app-enum-select class="form-group" sName="Tipo" sId="Demanda_Tipo"
                                        sClass="form-control" [label]="l('Tipo')" [value]="demanda.tipo"
                                        (valueChange)="changeType($event)" (valueChange)="demanda.tipo = $event"
                                        [enumeration]="enumTipoDemanda" [sDisabled]="!podeSalvar">
                                    </app-enum-select>
                                </div>
                                <div class="col-md-3" *ngIf="demanda.tipo == enumTipoDemanda.Projeto">
                                    <label for="Projetos">Projetos</label>
                                    <ng-select [(ngModel)]="demanda.projetoId" name="projetoSelecionado" [disabled]="!podeSalvar">
                                        <ng-option *ngFor="let projeto of projetostList" [value]="projeto.id" >
                                            {{ projeto.nome }}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label for="Demanda_Titulo">{{l("Titulo")}} *</label>
                                        <input #Demanda_Titulo="ngModel" [(ngModel)]="demanda.titulo" [disabled]="!podeSalvar"
                                            [ngClass]="{'is-invalid': Demanda_Titulo.invalid, 'is-valid': !Demanda_Titulo.invalid}"
                                            class="form-control" id="Demanda_Titulo" maxlength="200" minlength="2"
                                            name="Titulo" required type="text" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="Demanda_Estimativa">{{l("Estimativa")}}</label>
                                        <input type="number" id="Demanda_Estimativa" class="form-control"
                                            ui-number-mask="2" #Demanda_Estimativa="ngModel" [disabled]="!podeSalvar"
                                            [(ngModel)]="demanda.estimativa" name="Estimativa" />
                                        <label class="text-danger" *ngIf="(demanda.estimativa <= 0) && !(demanda.estimativa == null)">
                                            {{l("EstimativaInvalida")}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="Demanda_PrevisaoTerminoDev">{{l("PrevisaoTerminoDev")}}</label>
                                        <input [(ngModel)]="previsaoTerminoDev" [minDate]="minDatePrevisaoTerminoDev"
                                            bsDatepicker class="form-control m-input" id="Demanda_PrevisaoTerminoDev"
                                            [bsConfig]="{ adaptivePosition: true }" name="Demanda_PrevisaoTerminoDev"
                                            readonly type="datetime" (ngModelChange)="alteradoDataDeDev($event)" 
                                            [disabled]="!podeSalvar">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label
                                            for="Demanda_PrevisaoTerminoHomolog">{{l("PrevisaoTerminoHomolog")}}</label>
                                        <input [(ngModel)]="previsaoTerminoHomolog"
                                            [minDate]="minDatePrevisaoTerminoHomolog" bsDatepicker readonly
                                            class="form-control m-input" id="Demanda_PrevisaoTerminoHomolog"
                                            [bsConfig]="{ adaptivePosition: true }" [disabled]="!podeSalvar"
                                            name="Demanda_PrevisaoTerminoHomolog" type="datetime">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="Demanda_Referencias">{{l("Referencias")}}</label>
                                        <ng-select [items]="listaDeReferencias" [multiple]="true" [disabled]="!podeSalvar"
                                            #Demanda_Referencias="ngModel" [(ngModel)]="listaDeReferencias"
                                            [addTag]="true" name="listaDeReferencias" required
                                            [ngClass]="{'is-invalid': Demanda_Referencias.invalid, 'is-valid': !Demanda_Referencias.invalid}">
                                        </ng-select>
                                        <validation-messages [formCtrl]="Demanda_Referencias"></validation-messages>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="Demanda_CreationTime">{{l("CreationTimeDemand")}}</label>
                                        <input [(ngModel)]="creationTime" bsDatepicker class="form-control m-input"
                                            id="Demanda_CreationTime" name="Demanda_CreationTime" readonly
                                            type="datetime" disabled>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group" *ngIf="demanda.status == enumStatusDemanda.Producao">
                                        <label for="change-number">{{l("ChangeNumber")}}</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="change-number"
                                                name="ChangeNumber" [(ngModel)]="demanda.statusGestaoMudanca"
                                                disabled />
                                            <!--
                                            <div class="input-group-append">
                                                <button class="btn btn-primary blue" [disabled]="!podeSalvar"
                                                    (click)="updateChangeManagementStatus()" type="button">
                                                    <i class="fa fa-sync-alt text-white" [ngClass]="{'fa-spin': updateChange}"></i>
                                                </button>
                                            </div>
                                            -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <input class="form-control" name="demanda.usuarioResponsavelId"
                                [(ngModel)]="demanda.usuarioResponsavelId" type="text" hidden>  

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="Demanda_Descricao">{{l("Descricao")}}</label>
                                        <p-editor [(ngModel)]="demanda.descricao" name="DescricaoInput"
                                            [style]="{'height':'300px'}" [readonly]="!podeSalvar">
                                        </p-editor>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="{{'Envolvidos' | localize}}" [active]="tabShow == '2'"
                            [ngClass]="{'disableDiv': demanda.status == enumStatusDemanda.Producao}">
                            <div class="form-group m-form__group">
                                <label for="UserName">{{l("Users")}}</label>
                                <ng-select [items]="users" [multiple]="true" [(ngModel)]="usersSelected" [disabled]="!podeSalvar"
                                    (remove)="onRemove($event)" name="usersSelected" (change)="changeUsersSelected($event)">
                                    <ng-template ng-label-tmp let-item="item">
                                        <div class="p-2">
                                            <span *ngIf="exibirExcluirUsuario(item) " class="ng-value-icon left"
                                                style="vertical-align: middle;" (click)="clear(item)" aria-hidden="true">
                                                ×
                                            </span>
                                            <span class="ng-value-label ng-star-inserted">
                                                <b>{{item.label}}</b>
                                                <div style="font-size: 12px;">{{item.cargo}}</div>
                                                <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                        <div class="p-2">
                                            <b>{{item.label}}</b>
                                            <div style="font-size: 12px;">{{item.cargo}}</div>
                                            <div style="font-size: 10px;">{{item.fornecedor}}</div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="form-group m-form__group">
                                <div class="row">
                                    <table class="table">
                                        <tr>
                                            <th>{{'Members' | localize}}</th>
                                            <th>{{'UsuariosSaps' | localize}} *</th>
                                        </tr>
                                        <tr *ngFor="let usuarioDevops of usersSelected2">
                                            <td>
                                                <div>
                                                    <b>{{usuarioDevops.label}}</b>
                                                    <div style="font-size: 12px;">{{usuarioDevops.cargo}}</div>
                                                    <div style="font-size: 10px;">{{usuarioDevops.fornecedor}}</div>
                                                </div>
                                            </td>
                                            <td class="d-flex align-items-center">
                                                <button [disabled]="!usuarioDevops.usuarioSapPadrao"
                                                    (click)="defaultSapUser(usuarioDevops, $event)" 
                                                    href="" class="flat-button">
                                                    <i class="fas fa-user-tag text-dark icon-size"></i>
                                                </button>
                                                <ng-select [(ngModel)]="usuarioDevops.usuariosSAPSelecionados"
                                                    [items]="usuariosSapBuffer" [virtualScroll]=true class="col-11"
                                                    [disabled]="!exibirExcluirUsuario(usuarioDevops) || !podeSalvar"
                                                    (scroll)="onScroll($event)" [multiple]="true" [name]="usuarioDevops.value">
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <div class="p-2">
                                                            <span *ngIf="exibirExcluirUsuario(usuarioDevops) "
                                                                class="ng-value-icon left" style="vertical-align: 50%;"
                                                                (click)="clearSap(item, usuarioDevops)" aria-hidden="true">
                                                                ×
                                                            </span>
                                                            <span class="ng-value-label ng-star-inserted">
                                                                <b>{{item.label}}</b>
                                                                <div style="font-size: 12px;">{{item.ambiente}}</div>
                                                            </span>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                                        <div class="p-2">
                                                            <b>{{item.label}}</b>
                                                            <div style="font-size: 12px;">{{item.ambiente}}</div>
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </tab>
                        <tab heading="{{'Anexos' | localize}}" *ngIf="!add">
                            <div class="form-group m-form__group" [busyIf]="primengTableHelper.isLoading">
                                <label for="Anexos">{{l('AnexosAdicionados')}}</label>
                                <div style="margin-bottom: 15px" class="row" *ngFor="let anexo of anexosAdicionados">
                                    <p class="item-anexo"> {{anexo.nomeAnexo}}
                                        <i class=" text-danger fa fa-trash icone-acoes"
                                            (click)="!podeSalvar ? '' : removerAnexo(anexo) ">
                                        </i>
                                        <i class="text-success fa fa-download download-anexo icone-acoes"
                                            (click)="downloadAnexo(anexo)">
                                        </i>
                                    </p>
                                </div>

                                <div class="dropzone" ng2FileDrop (click)="fileInput.click()"
                                    (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
                                    (drop)="onDrop($event)">
                                     Solte os arquivos(doc, docx, pdf, xml, txt, xls, xlsx, jpg, png) aqui (ou clique) 
                                </div>

                                <input #fileInput ng2FileSelect [uploader]="uploader" type="file" [showPreviews]="false"
                                    [multiple]="true" [preserveFiles]="false" ng2FileSelect
                                    [accept]="'application/xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/excel, application/vnd.ms-excel.sheet.macroEnabled.12, text/plain, image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                    style="display: none" (change)="onFilesAdded($event,null)" />

                            </div>
                        </tab>
                        <tab heading="Requests" *ngIf="!add">
                            <div class="d-flex flex-row-reverse mb-3">
                                <button type="button" *ngIf="hasCreateRequest" class="btn btn-primary btn-sm"
                                    (click)="openRequestModal($event)" [disabled]="!podeSalvar">
                                    {{ 'CreateRequest' | localize }}
                                </button>
                                <button class="btn btn-sm btn-primary mr-2" *ngIf="showDeployButton"
                                    (click)="ProductiveDeploy()" type="button"
                                    [disabled]="disabledDeployButton || loading.deploy">
                                    {{"ProductiveDeploy" | localize}}
                                </button>
                            </div>
                            <div class="d-flex justify-content-center">
                                <p-progressSpinner *ngIf="loading.deploy"></p-progressSpinner>
                            </div>
                            <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" *ngIf="!loading.deploy">
                                <ngb-panel [(id)]="item.nome" *ngFor="let item of requestDemandaViewDto">
                                    <ng-template ngbPanelHeader>
                                        <div style="width: 100%;" ngbPanelToggle>
                                            <table class="table">
                                                <tr class="d-flex">
                                                    <th class="col-2"> Request </th>
                                                    <th class="col-2"> {{'Mandante' | localize}} </th>
                                                    <th class="col-2">{{'Tipo' | localize}}</th>
                                                    <th class="col-2">{{'Status' | localize}}</th>
                                                    <th class="col-4">{{'Descricao' | localize}}</th>
                                                </tr>
                                                <tr class="d-flex">
                                                    <td class="col-2">
                                                        {{item.nome}}
                                                    </td>
                                                    <td class="col-2">
                                                        {{item.mandante}}
                                                    </td>
                                                    <td class="col-2">{{item.tipo | localize}}</td>
                                                    <td class="col-2">{{item.status | localize}}</td>
                                                    <td class="col-4">{{item.descricaoRequest | localize}}</td>
                                                </tr>
                                                <tr class="d-flex">
                                                    <div class="col-12">
                                                        <button *ngIf="item.tipo == 'TransporteDeCopias'"
                                                            class="object-link" ngbPanelToggle
                                                            (click)="getObjects(item.requestId)">
                                                            <strong>{{'Objetos' | localize}}</strong>
                                                        </button>
                                                        <div class="object-link"
                                                            *ngIf="item.tipo != 'TransporteDeCopias'"
                                                            (click)="getObjectsForTask(item.requestId, item)">
                                                            <strong>{{'Objetos' | localize}}</strong>
                                                        </div>
                                                        <div *ngIf="item.listaObjetos">
                                                            <div>
                                                                <h6 *ngFor="let obj of item.listaObjetos"
                                                                    style="float: left; margin-right: 5px;"> <span
                                                                        class="badge badge-secondary">{{obj}}</span>
                                                                </h6>
                                                            </div>

                                                            <div class="col-md-12"
                                                                *ngIf="item.listaObjetos.length <= 0 ">
                                                                {{'NenhumObjeto' | localize}}</div>
                                                        </div>
                                                    </div>
                                                </tr>
                                            </table>
                                            <div class="d-flex">
                                                <button ngbPanelToggle *ngIf="item.tipo != 'TransporteDeCopias'"
                                                    class="btn btn-sm btn-outline-primary ml-2"
                                                    (click)="requestPanelContent = 0">
                                                    {{'Details' | localize}}
                                                </button>
                                                <button ngbPanelToggle class="btn btn-sm btn-outline-primary ml-2"
                                                    (click)="openLogForRequest(item.requestId)">
                                                    {{"Log STMS" | localize}}
                                                </button>
                                                <button *ngIf="item.status == 'Liberado' && !item.importacaoStms"
                                                    class="btn btn-sm btn-outline-primary ml-2" type="button"
                                                    (click)="importStms(item.requestId)">
                                                    {{"ImportSTMS" | localize}}
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <p-progressSpinner *ngIf="requestPanelContent == -1"></p-progressSpinner>
                                        <div *ngIf="requestPanelContent == 0">
                                            <div *ngFor="let task of item.tasks">
                                                <div class="row">
                                                    <div *ngIf="task.identificacaoSap" class="col-md-4">
                                                        <strong>{{'TaskSap' | localize}}</strong>
                                                    </div>
                                                    <div *ngIf="task.identificacaoSap" class="col-md-4">
                                                        <strong>{{'UsuarioSap' | localize}}</strong>
                                                    </div>
                                                    <div *ngIf="task.identificacaoSap" class="col-md-4">
                                                        <strong>{{'Status' | localize}}</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div *ngIf="task.identificacaoSap" class="col-md-4">
                                                        {{task.identificacaoSap}}</div>
                                                    <div *ngIf="task.developer" class="col-md-4">{{task.developer}}
                                                    </div>
                                                    <div *ngIf="task.status" class="col-md-4">{{task.status | localize}}
                                                    </div>
                                                    <div class="col-md-12 object-link"
                                                        (click)="getObjectsForTask(item.requestId, task, task.id)">
                                                        <strong>{{'Objetos' | localize}}</strong>
                                                    </div>
                                                    <div *ngIf="task.listaDeObjetos">
                                                        <div class="col-md-12">
                                                            <h6 *ngFor="let obj of task.listaDeObjetos"
                                                                style="float: left; margin-right: 5px;"> <span
                                                                    class="badge badge-secondary">{{obj}}</span></h6>
                                                        </div>
                                                        <div class="col-md-12" *ngIf="task.listaDeObjetos.length <= 0 ">
                                                            {{'NenhumObjeto' | localize}}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                        <div *ngIf="requestPanelContent == 1">
                                            <div *ngIf="hasLogRequest else logNoData">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <span class="text-primary font-weight-bold la-lg">
                                                        {{ "StmsRequestLog" | localize }}</span>
                                                    <button type="button" class="btn btn-primary"
                                                        (click)="refreshLog()">
                                                        {{ "Update" | localize}}
                                                    </button>
                                                </div>
                                                <p-tree [value]="logRequest">
                                                    <ng-template let-node pTemplate="default">
                                                        <span ngClass="d-flex request-tree">
                                                            <span class="col-3 order-1">{{ node.label[0] }}</span>
                                                            <span
                                                                [ngClass]="'order-2 col-3 font-weight-normal text-' + node.label[2]">
                                                                {{ node.label[1] }}
                                                            </span>
                                                            <span class="col-3 order-3">{{ node.label[3] }}</span>
                                                            <span *ngIf="node.label[4]" class="col-3 order-4"
                                                                (click)="downloadLogRequest(node.key)">
                                                                <i
                                                                    [ngClass]="{'fa fa-file-download clickable-item': !getDownloading(node.key), 'fas fa-sync-alt fa-spin': !!getDownloading(node.key), 'la-lg': true}"></i>
                                                            </span>
                                                        </span>
                                                    </ng-template>
                                                </p-tree>
                                            </div>
                                            <ng-template #logNoData>
                                                {{ "NoData" | localize}}
                                            </ng-template>
                                        </div>
                                        <div *ngIf="requestPanelContent == 2">
                                            <div class="d-flex">
                                                <div>
                                                    <div><strong>{{'Objetos' | localize}}</strong></div>
                                                    <h6 *ngFor="let obj of currentsObjects"
                                                        style="float: left; margin-right: 5px;">
                                                        <span class="badge badge-secondary">{{obj}}</span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </tab>
                        <tab heading="{{'Atividades' | localize}}" [active]="tabShow == '1'">
                            <div class="form-group m-form__group">
                                <div class="input-group mb-3">
                                    <input [(ngModel)]="textoNovaTarefa" aria-label="NewTask" class="form-control"
                                        id="inputTextoNovaTarefa" name="textoNovaTarefa" [disabled]="!podeSalvar"
                                        placeholder="{{'NewTask' | localize}}" (keydown.enter)='NovaAtividade()'
                                        type="text">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)='NovaAtividade()' 
                                            type="button" [disabled]="!podeSalvar">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div cdkDropList [cdkDropListData]="atividades" (cdkDropListDropped)="drop($event)">
                                    <div class="input-group mb-3" *ngFor="let item of atividades" cdkDrag>
                                        <div class="form-control form-control-width icone-movimentar-tarefas col-md-1">
                                            <i class="flaticon-grid-menu-v2"></i>
                                        </div>
                                        <div class="form-control form-control-width col-md-1">
                                            <mat-checkbox [(checked)]="item.concluida" name="concluida"
                                                (change)="ResolverAtividade(item.id)"></mat-checkbox>
                                        </div>
                                        <input [value]="item.nome" aria-label="NewTask"
                                            [class]="item.concluida ? 'form-control task-concuida' : 'form-control'"
                                            disabled placeholder="NewTask" type="text">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" type="button"
                                                [disabled]="item.concluida" (click)='RemoverAtividade(item.id)'><i
                                                    class="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab *ngIf="demandaId" heading="{{'AvaliacoesDeQualidades' | localize}}"
                            [active]="tabShow == '3'" (selectTab)="getAvaliacaoQualidade()">
                            <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                                <table class="qe-table">
                                    <thead class="qe-head">
                                        <tr class="w-100 d-flex">
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(4) + ' text-align: center;'">
                                                {{l('Tipo')}}
                                            </th>
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(4)">
                                                {{l('Object')}}
                                            </th>
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(4)">
                                                {{l('Linha')}}
                                            </th>
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(4)">
                                                {{l('UltAtu')}}
                                            </th>
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(8)">
                                                {{l('Descricao')}}
                                            </th>
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(3)">
                                                {{l('TempoMelhoria')}}
                                            </th>
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(4)">
                                                {{l('UltStatus')}}
                                            </th>
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(4)">
                                                {{l('Actions')}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="qe-body">
                                        <tr *ngFor="let record of primengTableHelper.records" class="d-flex">
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(4) + ' text-align: center;'">
                                                {{record.TypeCoverage}}
                                            </td>
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(4)"
                                                tooltip="{{record.objectName}}" class="text-truncate">
                                                {{record.objectName}}
                                            </td>
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(4)">
                                                {{record.errorLineNo}}
                                            </td>
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(4)">
                                                {{l(record.lastUpdate) | momentFormat: 'DD/MM HH:mm'}}
                                            </td>
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(8)">
                                                <a class="documentation-link" *ngIf="record.documentacaoItemQualidadeId"
                                                    href="javascript:;"
                                                    (click)="documentacaoModal.show(record.documentacaoItemQualidadeId)">{{l(record.errorDescription)}}
                                                </a>
                                                <span
                                                    *ngIf="!record.documentacaoItemQualidadeId">{{l(record.errorDescription)}}</span>
                                            </td>
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(3) + ' text-align: right;'">
                                                {{l(record.estimatedTime2Solve)}} min
                                            </td>
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(4)">
                                                {{l(record.lastStatus)}}
                                            </td>
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(4)">
                                                <div class="dropdown dropdown-inline" dropdown>
                                                    <button dropdownToggle type="button"
                                                        class="dropdown-toggle btn btn-sm btn-primary"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        {{l("Actions")}}
                                                    </button>
                                                    <ul class="dropdown-menu" *dropdownMenu>
                                                        <li>
                                                            <a href="javascript:;"
                                                                (click)="codigoSap.show(record.idItem.toString(), record.idAvaliacao)">
                                                                <span class="fas" aria-hidden="true"></span>
                                                                {{"SourceCodeView" | localize}}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="javascript:;"
                                                                (click)="historicoAvaliacaoCodigo.show(record.idItem.toString())">
                                                                <span class="fas" aria-hidden="true"></span>
                                                                {{"AvaliationHistory" | localize}}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </tab>
                        <tab *ngIf="demandaId && demanda.status == enumStatusDemanda.Desenvolvimento || demanda.status == enumStatusDemanda.Homologacao || demanda.status == enumStatusDemanda.Producao"
                            heading="{{l('Cobertura código(%)')}}" [active]="tabShow == '4'"
                            (selectTab)="getPercentualQualidade()">
                            <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                                <table class="qe-table">
                                    <thead class="qe-head">
                                        <tr class="w-100 d-flex">
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(2) + ' text-align: center;'">
                                                {{l('Tipo')}}
                                            </th>
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(10)">
                                                {{l('Objeto')}}
                                            </th>
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(3)+ ' text-align: center;'">
                                                {{l('Objeto (%)')}}
                                            </th>
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(5)">
                                                {{l('Pacote')}}
                                            </th>
                                            <th [style]="getavaliacaoQualidadeTabelaColuna(3)+ ' text-align: center;'">
                                                {{l('Pacote (%)')}}
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody class="qe-body">
                                        <tr *ngFor="let record of this.percentualQualidade" class="d-flex">
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(2) + ' text-align: center;'">
                                                {{record.typeCoverage}}
                                            </td>
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(10)"
                                                tooltip="{{record.objectCoverage}}" class="text-truncate">
                                                {{record.objectCoverage}}
                                            </td>
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(3) + ' text-align: center;'"
                                                tooltip="{{record.objectPerc}}" class="text-truncate">
                                                {{record.objectPerc}}
                                            </td>
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(5)"
                                                tooltip="{{record.objectPackage}}" class="text-truncate">
                                                {{record.objectPackage}}
                                            </td>
                                            <td [style]="getavaliacaoQualidadeTabelaColuna(3)+ ' text-align: center;'"
                                                tooltip="{{record.packagePerc}}" class="text-truncate">
                                                {{record.packagePerc}}
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </tab>
                        <tab heading="{{l('Code Review')}}"
                            *ngIf="demanda.status == enumStatusDemanda.Homologacao || demanda.status == enumStatusDemanda.Producao">
                            <h5>{{l("Histórico de registros de Code Review")}}</h5>
                            <div id="code-review-history-table">
                                <p-table #dt [value]="codeReviewHistoricoDto" [rows]="5" [paginator]="true" dataKey="id"
                                    [rowsPerPageOptions]="[5,10,25,50,100,500]">
                                    <ng-template pTemplate="header">
                                        <tr id="table-row-header">
                                            <th class="th-requester" scope="col">{{l("Solicitante")}}</th>
                                            <th class="th-evaluator" scope="col">{{l("Avaliador")}}</th>
                                            <th class="th-dataTime" scope="col">{{l("Data/Hora")}}</th>
                                            <th class="th-status" scope="col">{{l("Situação")}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-historico>
                                        <tr id="table-row-body">
                                            <td class="td-requester">{{historico.nomeSolicitante}}</td>
                                            <td class="td-evaluator">{{historico.nomeAvaliador}}</td>
                                            <td class="td-dataTime">
                                                {{getLocaleDateHourCodeReview(historico.dataAvaliacao)}}</td>
                                            <td class="td-status">
                                                <span class="is-inadequate"
                                                    *ngIf="historico.situacao == enumStatusCodeReview('Reprovado')"
                                                    (click)="inadequate(historico.codeReviewDemandaId, historico.nomeAvaliador, historico.dataAvaliacao)">
                                                    Inadequado<i class="far fa-comment-alt"></i>
                                                </span>

                                                <span class="is-adequate"
                                                    *ngIf="historico.situacao == enumStatusCodeReview('Aprovado')"
                                                    (click)="inadequate(historico.codeReviewDemandaId, historico.nomeAvaliador, historico.dataAvaliacao)">
                                                    Adequado
                                                </span>

                                                <span class="is-new"
                                                    *ngIf="historico.situacao == enumStatusCodeReview('Novo')">
                                                    Pendente
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="summary">
                                        <div class="flex align-items-center justify-content-between">
                                            Total: {{codeReviewHistoricoDto ? codeReviewHistoricoDto.length : 0 }}
                                        </div>
                                    </ng-template>
                                </p-table>
                            </div>
                        </tab>
                        <tab [heading]="'GestaoRisco' | localize" *ngIf="demanda.status == enumStatusDemanda.Homologacao || 
                                                                         demanda.status == enumStatusDemanda.Producao">
                            
                           <div class="form-group"> 
                                <button class="btn btn-primary blue" *ngIf="riskManagementConfirmed" (click)="userAware()">
                                    {{l("Ciente")}}
                                </button>
                           </div>
                           <div class="form-group">
                                <span *ngIf="riskManagementConfirmed">
                                    Se a Gestão de Mudança estiver ativa, somente será exibido os aprovadores após clicar no botão "Ciente".  Caso contrário a demanda será movimentada.
                                </span>
                           </div>
                           <div class="form-group">
                                <div class="row align-items-center">
                                    <!--<Primeng-TurboTable-Start>-->
                                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                                        <p-table #dataTable (onLazyLoad)="recarregar($event)"
                                            [value]="primengTableHelper.records"
                                            rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false" [lazy]="true"
                                            [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                                            [resizableColumns]="primengTableHelper.resizableColumns">
                                            <ng-template pTemplate="header">
                                                <tr class="table-header">                                  
                                                    <th pSortableColumn="type">
                                                        {{l("Tipo")}}
                                                        <p-sortIcon field="type"></p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="objectType">
                                                        {{l("Tipo do objeto")}}
                                                        <p-sortIcon field="objectType"></p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="object">
                                                        {{l("Objeto")}}
                                                        <p-sortIcon field="object"></p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="request">
                                                        {{l("Request")}}
                                                        <p-sortIcon field="request"></p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="typeRef">
                                                        {{l("Tipo da referência")}}
                                                        <p-sortIcon field="typeRef"></p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="objectRef">
                                                        {{l("Objeto de referência")}}
                                                        <p-sortIcon field="objectRef"></p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="requestRef">
                                                        {{l("Request de referência")}}
                                                        <p-sortIcon field="requestRef"></p-sortIcon>
                                                    </th>
                                                </tr>
                                            </ng-template>
                
                                            <ng-template pTemplate="body" let-record="$implicit">
                                                <tr class="table-body">                                  
                                                    <td class="text-truncate">
                                                        {{record.type}}
                                                    </td>
                                                    <td class="text-truncate">
                                                        {{record.objectType}}
                                                    </td>
                                                    <td class="text-truncate">
                                                        {{record.object}}
                                                    </td>
                                                    <td class="text-truncate">
                                                        {{record.request}}
                                                    </td>
                                                    <td class="text-truncate">
                                                        {{record.typeRef}}
                                                    </td>
                                                    <td class="text-truncate">
                                                        {{record.objectRef}}
                                                    </td>
                                                    <td class="text-truncate">
                                                        {{record.requestRef}}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                                            {{l('NoData')}}
                                        </div>
                                    </div>
                                    <!--<Primeng-TurboTable-End>-->
                                </div>
                            </div>
                        </tab>
                        <tab [heading]="'GestaoMudanca' | localize"
                            *ngIf="changeManagerTabView() || changeManagerNativeTabView()">
                            <div class="d-flex flex-wrap align-items-center" *ngIf="changeManagerTabView()">
                                <div class="form-group col-6">
                                    <label for="change-number">{{"ChangeNumber" | localize}}</label>
                                    <input id="change-number" #changeNumber="ngModel" type="text" name="ChangeNumber"
                                        class="form-control" [(ngModel)]="demanda.statusGestaoMudanca" disabled />
                                </div>
                                <div class="form-group col-6">
                                    <label for="change-title">{{"ChangeTitle" | localize}}</label>
                                    <input id="change-title" #changeTitle="ngModel" type="text" name="ChangeTitle"
                                        class="form-control" [(ngModel)]="gestaoMudanca.titulo" disabled />
                                </div>
                                <div class="form-group col-6">
                                    <label for="change-requester">{{"ChangeRequester" | localize}} *</label>
                                    <input id="change-requester" #changeRequester="ngModel" type="text"
                                        name="ChangeRequester" class="form-control"
                                        [(ngModel)]="gestaoMudanca.solicitante" disabled />
                                </div>
                                <div class="form-group col-3">
                                    <label for="change-type">{{l("ChangeType")}}</label>
                                    <input id="change-type" type="text" name="ChangeType" class="form-control"
                                        [value]="getEnumTipoMudanca(gestaoMudanca.tipoMudancaNativa)" disabled />
                                </div>
                                <div class="form-group col-3">
                                    <label for="transp-date">{{l("Data e hora do transporte")}}</label>
                                    <input id="transp-date" type="text" name="TranspDate" class="form-control"
                                           [value]="getLocaleDateHour(gestaoMudanca.transpDate)" disabled />
                                </div>
                                <div class="form-group col-6">
                                    <label for="change-category">{{"ChangeCategory" | localize}} *</label>
                                    <input id="change-category" #changeCategory="ngModel" type="text"
                                        name="ChangeCategory" class="form-control" [(ngModel)]="gestaoMudanca.categoria"
                                        disabled />
                                </div>
                                <div class="form-group col-3">
                                    <label for="change-start">{{"ChangeStart" | localize}}</label>
                                    <input id="change-start" type="text" name="ChangeStart" class="form-control"
                                        [value]="getLocaleDate(gestaoMudanca.dataInicio)" disabled />
                                </div>
                                <app-custom-timepicker class="col-3" [value]="gestaoMudanca.dataInicio"
                                    [disabled]="true"></app-custom-timepicker>
                                <div class="form-group col-3">
                                    <label for="change-end">{{"ChangeEnd" | localize}}</label>
                                    <input id="change-end" type="text" name="ChangeEnd" class="form-control"
                                        [value]="getLocaleDate(gestaoMudanca.dataFim)" disabled />
                                </div>
                                <app-custom-timepicker class="col-3" [value]="gestaoMudanca.dataFim" [disabled]="true">
                                </app-custom-timepicker>
                                <div class="form-group col-6">
                                    <label for="designed-group">{{"DesignedGroup" | localize}}</label>
                                    <input id="designed-group" #designedGroup="ngModel" type="text" name="DesignedGroup"
                                        class="form-control" [(ngModel)]="gestaoMudanca.grupoAtribuicao" disabled>
                                </div>
                                <div class="form-group col-6">
                                    <label for="configuration-item">{{"ConfigurationItemField" | localize}} *</label>
                                    <input id="configuration-item" #configurationItem="ngModel" type="text" disabled
                                        name="ConfigurationItem" class="form-control"
                                        [(ngModel)]="gestaoMudanca.itemConfiguracao" />
                                </div>
                                <div class="form-group col-6">
                                    <label for="change-base">{{"ChangeBase" | localize}} *</label>
                                    <input id="change-base" #changeBase="ngModel" type="text" name="ChangeBase"
                                        class="form-control" [(ngModel)]="gestaoMudanca.acessoBase" disabled />
                                </div>
                                <div class="form-group col-6">
                                    <label for="change-justification">{{"ChangeJustification" | localize}} *</label>
                                    <input id="change-base" #changeJustification="ngModel" type="text"
                                        name="ChangeJustification" class="form-control"
                                        [(ngModel)]="gestaoMudanca.justificativa" disabled />
                                </div>
                                <div class="form-group col-6">
                                    <label for="change-risk">{{"ChangeRisk" | localize}}</label>
                                    <input id="change-risk" #changeRisk="ngModel" type="text" name="ChangeRisk"
                                        class="form-control" [(ngModel)]="gestaoMudancaRisco.risco" disabled>
                                </div>
                                <div class="form-group col-6">
                                    <label for="change-business-risk">{{"ChangeBusinessRisk" | localize}}</label>
                                    <input id="change-business-risk" #changeBusinessRisk="ngModel" type="text"
                                        name="ChangeBusinessRisk" class="form-control" [(ngModel)]="gestaoMudanca.risco"
                                        disabled>
                                </div>
                                <div class="form-group col-6">
                                    <label for="return-plan">{{"ReturnPlan" | localize}} *</label>
                                    <input id="return-plan" #returnPlan="ngModel" type="text" name="ReturnPlan"
                                        class="form-control" [(ngModel)]="gestaoMudanca.planoRetorno" disabled />
                                </div>
                                <div class="form-group col-6">
                                    <label for="test-plan">{{"TestPlan" | localize}}</label>
                                    <input id="test-plan" #testPlan="ngModel" type="text" name="TestPlan"
                                        class="form-control" [(ngModel)]="gestaoMudancaRisco.planoTeste" disabled>
                                </div>
                                <div class="form-group col-6">
                                    <label for="mitigation-plan">{{"MitigationPlan" | localize}}</label>
                                    <input id="mitigation-plan" #mitigationPlan="ngModel" type="text"
                                        name="MitigationPlan" class="form-control"
                                        [(ngModel)]="gestaoMudancaRisco.planoMitigacao" disabled>
                                </div>
                                <div class="form-group col-6">
                                    <label for="non-publication-effect">{{"NonPublicationEffect" | localize}}
                                    </label>
                                    <input id="non-publication-effect" #nonPublicationEffect="ngModel" type="text"
                                        name="NonPublicationEffect" class="form-control"
                                        [(ngModel)]="gestaoMudancaRisco.efeitoNaoPublicacao" disabled>
                                </div>
                                <div class="form-group col-6">
                                    <label for="change-plan">{{"ChangePlan" | localize}} *</label>
                                    <input id="change-plan" #changePlan="ngModel" type="text" name="ChangePlan"
                                        class="form-control" [(ngModel)]="gestaoMudancaRisco.planoMudanca" disabled />
                                </div>
                                <div class="form-group col-12">
                                    <label for="change-description">{{"ChangeDescription" | localize}}</label>
                                    <textarea id="change-description" #changeDescription="ngModel" type="text"
                                        name="ChangeDescription" class="form-control textarea"
                                        [(ngModel)]="gestaoMudanca.descricao" disabled></textarea>
                                </div>
                            </div>
                            <div class="gmud-native-content" *ngIf="changeManagerNativeTabView()">
                                <tabset>
                                    <tab heading="{{l('GeneralInfo')}}">
                                        <div class="row">
                                            <div class="form-group col-md-4">
                                                <label for="change-start">{{l("ChangeStart")}}</label>
                                                <input id="change-start" type="datetime" name="ChangeStart" class="form-control"
                                                    [value]="getLocaleDateHour(gestaoMudancaNativa.dataCriacaoMudanca)"
                                                    disabled />
                                            </div>                                
                                            <div class="form-group col-md-8">                              
                                                <label for="change-status">{{l("Status da GMUD")}}</label>
                                                <p-timeline [value]="statusGmud" layout="horizontal" styleClass="customized-timeline">
                                                    <ng-template pTemplate="marker" let-gmud>
                                                        <span class="custom-marker status-steps shadow-2" [style]="gestaoMudancaNativa?.status === gmud?.status 
                                                            ? setColorsBackgroundStatus(gmud?.status) : 'background-color: #607D8B;'">
                                                            <i [ngClass]="gmud?.icon"></i>
                                                        </span>                                   
                                                    </ng-template>
                                                    <ng-template pTemplate="content" let-gmud>
                                                        <span [class]="gestaoMudancaNativa?.status === gmud?.status ? 'text-bold-status text-size-status' : 'text-size-status'">
                                                            {{gmud?.status | uppercase}}
                                                        </span>
                                                    </ng-template>
                                                </p-timeline>                                               
                                            </div>  
                                        </div>
                                        <div class="form-group">
                                            <label for="change-requester">{{l("ChangeRequester")}}</label>
                                            <input id="change-requester" type="text" name="ChangeRequester" class="form-control"
                                                [(ngModel)]="gestaoMudancaNativa.solicitadoPor" disabled />
                                        </div>
                                        <div class="form-group">
                                            <label for="change-title">{{l("ChangeTitle")}}</label>
                                            <input id="change-title" #changeTitle="ngModel" type="text" name="ChangeTitle"
                                                class="form-control" [(ngModel)]="gestaoMudancaNativa.tituloDemanda" disabled />
                                        </div>
                                        <div class="form-group">
                                            <label for="change-description">{{l("ChangeDescription")}}</label>
                                            <p-editor class="p-editor-demand" id="change-description" [(ngModel)]="gestaoMudancaNativa.descricaoMudanca" 
                                                      name="change-description" [style]="{'height':'320px'}" readonly="false">
                                            </p-editor>
                                        </div>
                                        <div class="form-group">
                                            <label for="change-type">{{l("ChangeType")}}</label>
                                            <input id="change-type" type="text" name="ChangeType" class="form-control"
                                                [value]="getEnumTipoMudanca(gestaoMudancaNativa.tipoMudanca)" disabled />
                                        </div>
                                        <div class="form-group">
                                            <label for="change-risk">{{l("ChangeRisk")}}</label>
                                            <input id="change-risk" type="text" name="ChangeRisk" class="form-control"
                                                [value]="getEnumRisco(gestaoMudancaNativa.riscoMudanca)" disabled />
                                        </div>
                                        <div class="form-group">
                                            <label for="mitigation-plan">{{l("MitigationPlanNative")}}</label>
                                            <input id="mitigation-plan" #mitigationPlan="ngModel" type="text"
                                                name="MitigationPlan" class="form-control"
                                                [(ngModel)]="gestaoMudancaNativa.planoPrevencao" disabled />
                                        </div>
                                        <div class="form-group">
                                            <label for="return-plan">{{l("ReturnPlanNative")}}</label>
                                            <input id="return-plan" #returnPlan="ngModel" type="text" name="ReturnPlan"
                                                class="form-control" [(ngModel)]="gestaoMudancaNativa.planoRetorno" disabled />
                                        </div>
                                        <div class="form-group" *ngIf="gestaoMudancaNativa.tipoTransporte !== this.enumTipoMudanca.Manual">
                                            <label for="date-transp">{{l("Data e hora do transporte")}}</label>
                                            <input id="date-transp" type="text" name="DateTransp" class="form-control"
                                                [value]="getLocaleDateHour(gestaoMudancaNativa.dataHoraTransporte)" disabled />
                                        </div>
                                        <div class="form-group">
                                            <label for="states-transp">{{l("Status do transporte")}}</label>
                                            <input id="states-transp" type="text" name="StatesTransp" class="form-control"
                                                [(ngModel)]="gestaoMudancaNativa.statusTransporte" disabled />
                                        </div>
                                        <div class="form-group">
                                            <label for="type-transp">{{l("Tipo do transporte")}}</label>
                                            <input id="type-transp" type="text" name="TypeTransp" class="form-control"
                                            [value]="getEnumTipoMudanca(gestaoMudancaNativa.tipoMudanca)" disabled />
                                        </div>
                                    </tab>
                                    <tab heading="{{l('Approvers')}}">
                                        <div class="form-group">
                                            <label for="user-approval">{{l("Approvers")}}</label>
                                            <div class="departament-content" *ngFor="let departament of gestaoMudancaNativa.departamentos">                                    
                                                <p-panel [header]="'DEP: ' +  departament?.descricao | uppercase" [toggleable]="true" [collapsed]="true">
                                                    <div class="user-content" *ngFor="let user of departament.usuarios">
                                                        <span class="approver-name text-bold-status">{{ user?.nome}}  
                                                            <i class="far fa-comment-alt approve-status" *ngIf="user?.status !== l('WaitingForApproval')" 
                                                                [tooltip]="user?.justificativa != null ? 'Comentário: ' + user?.justificativa : ''">
                                                            </i>
                                                        </span> 
                                                        <br/>
                                                        <p-timeline [value]="statusAprovador" layout="horizontal" styleClass="customized-timeline">
                                                            <ng-template pTemplate="marker" let-aprovador>
                                                                <span class="custom-marker status-steps shadow-2" [style]="user?.status === aprovador?.status 
                                                                    ? setColorsBackgroundStatus(aprovador?.status) : 'background-color: #607D8B;'">
                                                                    <i [ngClass]="aprovador?.icon"></i>
                                                                </span>
                                                            </ng-template>
                                                            <ng-template pTemplate="content" let-aprovador>
                                                                <span [class]="user?.status === aprovador?.status ? 'text-bold-status text-size-status' : 'text-size-status'">
                                                                    {{aprovador?.status | uppercase}}
                                                                </span>
                                                                <br />
                                                                <span *ngIf="user?.status === aprovador?.status && user?.status !== 'Aguardando aprovação'">
                                                                    {{getLocaleDateHour(user?.dataAlteracao)}}
                                                                </span>
                                                            </ng-template>
                                                        </p-timeline>                                    
                                                    </div>
                                                </p-panel>
                                            </div>
                                        </div>
                                    </tab>
                                    <tab heading="{{l('Anexos')}}">
                                        <div class="form-group">
                                            <div id="anexos-gmud">
                                                <div class="list-head list-row">
                                                    <div class="list-item item"><span>Documento</span></div>
                                                    <div class="list-item item-action"><span>Ação</span></div>
                                                </div>
                                                <div class="list-body list-row" *ngFor="let anexo of gestaoMudancaNativa.anexos; let i = index">
                                                    <div class="list-item item"><span>{{anexo.nomeAnexo}}</span></div>
                                                    <div class="list-item item-action">
                                                        <span class="delete-item" (click)="downloadAnexo(anexo)" tooltip="{{l('Download do anexo')}}">
                                                            <i class="fa fa-download"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </tab>
                                    <tab heading="{{l('Pós implementação')}}">
                                        <div class="form-group">
                                            <label for="pos-implemention">{{l('Pós implementação')}}</label>
                                            <p-editor id="pos-implemention" [(ngModel)]="gestaoMudancaNativa.posImplementacao" 
                                                name="pos-implemention" [style]="{'height':'320px'}" readonly="false">
                                            </p-editor>
                                        </div>
                                    </tab>
                                </tabset>
                            </div>
                        </tab>
                        <tab heading="Histórico"  *ngIf="!add">
                            <app-timeline [demandaForEdit]="demandaForEdit"></app-timeline>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer" *ngIf="tabShow != '4'">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary blue" [buttonBusy]="saving"
                        [disabled]="(!podeSalvar && (demanda.status == enumStatusDemanda.Producao || demanda.status == enumStatusDemanda.Concluido)) || saving"
                        [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i>
                        <span>{{l("Save")}}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <app-codigo-sap #codigoSap></app-codigo-sap>
    <app-historico-avaliacao-codigo #historicoAvaliacaoCodigo></app-historico-avaliacao-codigo>
    <app-risk-status-modal #riskStatusModal (saved)="riskSaved()"></app-risk-status-modal>
    <demandaUserLookupTableModal #demandaUserLookupTableModal (modalSave)="getNewUsuarioResponsavelId()">
    </demandaUserLookupTableModal>
    <demandaModuloLookupTableModal #demandaModuloLookupTableModal (modalSave)="getNewModuleId()">
    </demandaModuloLookupTableModal>
    <app-create-request-modal #createRequestModal (detalhesAdicionado)="createRequest($event)">
    </app-create-request-modal>
    <app-documentacao-item-qualidade-modal #documentacaoModal></app-documentacao-item-qualidade-modal>
    <code-reviewer-comments-modal #codeReviewerCommentsModal></code-reviewer-comments-modal>
    <app-information-deploy-modal #informationDeployModal (modalSave)="finalizeDemand()"></app-information-deploy-modal>
    <create-change-manager-native-modal #createChangeManagerNative></create-change-manager-native-modal>
</div>